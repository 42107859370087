import { IconNames } from '@fe-monorepo/helper';
import useHeaderState from '@fe-web/hooks/useHeaderState';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ResponsiveIcon from '../../Atoms/Icon/ResponsiveIcon';
import SearchableDropDown from './DropDown/SearchableDropDown';

export type InputFieldStyle = {
  containerStyle?: string;
  labelStyle?: string;
  inputStyle?: string;
  underlineStyle?: string;
};

const Label = (props: { text: string; isFocused: boolean }) => {
  return (
    <p
      className={`
            text-bodyLarge 4xl:text-subtitle 5xl:text-bigTitle 8xl:text-LPTitle
            h-20 4xl:h-36 5xl:h-54 8xl:h-100
            w-full
            mb-4 4xl:mb-8 5xl:mb-12 8xl:mb-20
            ${props.isFocused ? 'text-sunset' : 'text-secondary/50'} font-regular`}
    >
      {props.text}
    </p>
  );
};

interface InputProps {
  className?: string;

  inputType?: string;
  value: string;
  isFocused: boolean;
  customPlaceholder?: string;

  setValue: (value: string) => void;
  setFocused: (val: boolean) => void;
  onClickEnter?: () => void;
}

const Input = (props: InputProps) => {
  const { t } = useTranslation();
  const [localFocus, setLocalFocus] = useState<boolean>(false);

  const setPlaceHolder = props.customPlaceholder ? props.customPlaceholder : '';

  return (
    <div className="relative grid items-center w-full h-fit">
      <input
        className={`z-1 w-full outline-0 bg-transparent ${props?.className}`}
        type="text"
        value={props.value}
        placeholder={localFocus ? '' + t('formPlaceholder_start_typing') : setPlaceHolder}
        onChange={e => props.setValue(e.target.value)}
        onFocus={() => {
          setLocalFocus(true);
          props.setFocused(true);
        }}
        onBlur={() => {
          setLocalFocus(false);
          props.setFocused(false);
        }}
        onKeyDown={event => {
          if (event.key === 'Enter' && props.onClickEnter) {
            props.onClickEnter();
          }
        }}
      />

      {props.value.length > 0 && (
        <div className="absolute cursor-pointer justify-self-end h-fit w-fit">
          <ResponsiveIcon
            className="fill-black100 z-[1] "
            name={IconNames.close_xbutton}
            baseWidth={10}
            baseHeight={10}
            onClick={() => props.setValue('')}
          />
        </div>
      )}
    </div>
  );
};

interface UnderlineProps {
  isFocused: boolean;
  isError: boolean;

  underlineStyle?: string;
}

const Underline = (props: UnderlineProps) => {
  return (
    <div
      className={`w-full ${props.isError ? 'bg-red' : props.isFocused ? 'bg-sunset' : 'bg-gray-200-zinc-700'} ${props?.underlineStyle}`}
    />
  );
};

interface FieldProps {
  style?: {
    containerStyle?: string;
    inputStyle?: string;
    underlineStyle?: string;
  };

  inputType?: string;
  hasFocused?: boolean;
  isFocused: boolean;
  isError: boolean;
  customPlaceholder?: string;
  inputDefaultValue?: string;

  setFocused: (value: boolean) => void;
  isUserAllowedToType: (value: string) => boolean;
  retrieveValue?: (value: any) => void;
  retrieveFocus?: (value: boolean) => void;
  retrieveTyped?: (value: boolean) => void;

  onClickEnter?: () => void;
}

const InputWrapper = (props: FieldProps) => {
  const { style, isError, isFocused, setFocused, isUserAllowedToType } = props;

  const [value, setValue] = useState<string>('');

  const changeValue = (newValue: string) => {
    const isNewValueShorter = newValue.length < value.length;

    if (!isNewValueShorter && !isUserAllowedToType(newValue)) {
      return;
    }

    if (props?.retrieveTyped) {
      props?.retrieveTyped(true);
    }

    setValue(newValue);
  };

  useEffect(() => {
    if (props?.retrieveValue) {
      props?.retrieveValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (props.inputDefaultValue) {
      setValue(props.inputDefaultValue);
    }
  }, [props.inputDefaultValue]);

  return (
    <div className={`${style?.containerStyle}`}>
      <Input
        className={style?.inputStyle}
        value={value}
        customPlaceholder={props.customPlaceholder}
        inputType={props.inputType}
        isFocused={isFocused}
        setFocused={setFocused}
        setValue={changeValue}
        onClickEnter={props.onClickEnter}
      />

      <Underline isError={isError} underlineStyle={style?.underlineStyle} isFocused={isFocused} />
    </div>
  );
};

interface CombinedInputProps {
  dir?: 'ltr' | 'rtl';
  style?: InputFieldStyle;

  label: string;
  customPlaceholder?: string;

  inputType?: string;
  errorMessage: string;
  inputDefaultValue?: string;

  hasFocused?: boolean;
  dropDown: {
    default: any;
    list: any[];
    dropdown_container_style?: string;
    dropdown_style?: string;

    search: (searchKey: string) => any[];
    getStringValue: (arrayElement: any) => string;
    getSearchValue: (arrayElement: any) => string;
  };

  retrieveValue?: (value: any) => void;
  retrieveFocus?: (value: boolean) => void;
  retrieveTyped?: (value: boolean) => void;
  isUserAllowedToType?: (value: string) => boolean;

  onClickEnter?: () => void;
}

const CombinedInputField = (props: CombinedInputProps) => {
  const { style, label, dropDown, errorMessage } = props;

  const elementRef = useRef<HTMLDivElement | null>();

  const [dropDownValue, setDropDownValue] = useState<any>(dropDown.default ? dropDown.default : {});
  const [inputValue, setInputValue] = useState<string>('');
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const { dir } = useHeaderState();

  const shouldShowError = !!errorMessage && !isFocused;

  useEffect(() => {
    setDropDownValue(dropDown.default);
  }, [dropDown.default]);

  useEffect(() => props?.retrieveValue && props.retrieveValue({ dropDownValue, inputValue }), [dropDownValue, inputValue]);

  useEffect(() => {
    if (!props.retrieveFocus || props?.hasFocused !== undefined) {
      return;
    }

    if (!props.hasFocused && isFocused) {
      props.retrieveFocus(isFocused);
    }
  }, [isFocused]);

  const isUserAllowedToType = (value: string) => {
    if (props?.isUserAllowedToType) {
      return props.isUserAllowedToType(value);
    }

    return true;
  };

  return (
    <div ref={ref => (elementRef.current = ref)} className={`relative ${style?.containerStyle}`}>
      <div className="flex flex-col w-full">
        {label && <Label text={label} isFocused={false} />}

        <div dir={props?.dir} className={`flex gap-16 4xl:gap-[28px] 5xl:gap-[42px] 8xl:gap-81 ${dir === 'rtl' && `justify-end`}`}>
          <SearchableDropDown
            style={{ containerStyle: 'w-[25%]', inputStyle: `${style?.inputStyle}`, underlineStyle: style?.underlineStyle }}
            dropdown_style={{
              dropdown_container_style: dropDown.dropdown_container_style,
              dropdown_style: dropDown.dropdown_style,
            }}
            parentRef={elementRef}
            isFocused={isFocused}
            isError={shouldShowError}
            currentValue={dropDownValue}
            list={dropDown.list}
            setFocused={setIsFocused}
            search={dropDown.search}
            getSearchValue={dropDown.getSearchValue}
            getStringValue={dropDown.getStringValue}
            retrieveValue={setDropDownValue}
            isCombined={true}
            onClickEnter={props.onClickEnter}
          />

          <InputWrapper
            style={{ containerStyle: 'w-[75%]', inputStyle: style?.inputStyle, underlineStyle: style?.underlineStyle }}
            isError={shouldShowError}
            customPlaceholder={props.customPlaceholder}
            inputType={props.inputType}
            isFocused={isFocused}
            isUserAllowedToType={isUserAllowedToType}
            retrieveTyped={props.retrieveTyped}
            retrieveValue={setInputValue}
            setFocused={setIsFocused}
            onClickEnter={props.onClickEnter}
            inputDefaultValue={props.inputDefaultValue}
          />
        </div>

        {shouldShowError && (
          <div className="h-20 mt-4 4xl:mt-8 5xl:mt-12 8xl:mt-20 4xl:h-36 5xl:h-54 8xl:h-100">
            <p className=" text-bodySmall 4xl:text-subtitle 5xl:text-bigTitle 8xl:text-huge font-regular text-red">{errorMessage}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CombinedInputField;
