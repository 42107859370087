export enum BrazeCustomEvents {
  ScreenView = 'screen_view',
  ClickedAppLink = 'clicked_app_link',
  SignUp = 'signup',
  FailedSignUp = 'failed_signup',
  ConfirmedOTP = 'confirmed_otp',
  FailedOTP = 'failed_otp',
  Login = 'login',
  FailedLogin = 'failed_login',
  Search = 'search',
  SubscribedNewsLetter = 'subscribed_newsletter',
  ViewShopItemsList = 'view_items_list',
  ViewShopItem = 'view_item',
  AddedItemToCart = 'add_to_cart',
  ViewedCart = 'view_cart',
  RemovedItemFromCart = 'remove_from_cart',
  BeginsCheckout = 'begin_checkout',
  AddedAddress = 'add_shipping_info',
  AddedPaymentDetails = 'add_payment_info',
  PurchaseSuccessful = 'purchase',
  PurchaseFailed = 'failed_purchase',
  RequestForReturn = 'refund',
  ErrorEncountered = 'encountered_error',
  ViewedTournament = 'viewed_tournament',
  JoinedTournament = 'joined_tournament',
  CompletedTournament = 'completed_tournament',
  StartedMatchmaking = 'started_matchmaking',
  FoundMatch = 'found_match',
  FailedMatchmaking = 'failed_matchmaking',
  SelectedMiniGame = 'selected_minigame',
  StartedMiniGame = 'started_minigame',
  EndedMiniGame = 'ended_minigame',
}
