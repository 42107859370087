  
  const stringRegex = /^[ A-Za-z0-9_@./#&+-=]*$/u;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  
  export const validateAddressName = (name: string) => {
    if(name.length === 0 || name.length > 20){
      return false;
    }
    const nameAddressRegexCheck = stringRegex;
    return nameAddressRegexCheck.test(name);
  };
  
  export const validateNameSaudiArabia = (name: string) => {
    if(name.length === 0){
      return false;
    }
    const nameRegexCheck = stringRegex;
    return nameRegexCheck.test(name);
  };
  
  export const validatePhoneNumberSaudiArabia = (phoneNumber: string) => {
    const phoneNumberRegex = /^(\+966|966|0)(\d{9})$/;
    return phoneNumberRegex.test(phoneNumber);
};
  
  export const validateEmailSaudiArabia = (email: string) => {
    const emailRegexCheck = emailRegex;
    return emailRegexCheck.test(email);
  };
  