import Challenge from '../components/Challenge/Challenge';
import Bottom from '../components/Layouts/Bottom';

const ChallengeDetail = () => {
  return (
    <>
      <div className="full-sec">
        <div className="px-20 md:px-64 lg:px-120 2K:px-160 4K:px-320 8K:px-655">
          <Challenge />
        </div>
      </div>
      <Bottom />
    </>
  );
};

export default ChallengeDetail;
