import { useUserProfile } from '@fe-monorepo/hooks';
import { NotificationMessageData } from '@fe-monorepo/models';
import { useNotificationProvider } from '@fe-web/hooks/useNotification';
import { t } from 'i18next';
import useVerifyDialogToggle from 'libs/hooks/src/useVerifyDialogToggle';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import NotificationCard from '../EcommerceAndChat/NotificationCard';
import VerifyUserNotification from './VerifyUserNotification';

interface Props {
  isMinimized?: boolean;
}

const SidebarNotificationList = ({ isMinimized = false }: Props) => {
  const location = useLocation();
  const { user } = useUserProfile();
  const { toggleVerifyDialog } = useVerifyDialogToggle();
  const isMaximized = location.pathname.includes('/notifications');
  const sentinelRef = useRef(null);
  const [showLoadingText, setShowLoadingText] = useState<boolean>(true);
  const [isMomentumReachEnd, setIsMomentumReachEnd] = useState<boolean>(false);
  const { notificationList, nextCursor, getAllNextList } = useNotificationProvider();
  const [stateList, setStateList] = useState<NotificationMessageData[]>([]);

  // Groups the notification on the list based on their unix epoch timestamp
  const groupByDate = (date: string) => {
    const today = Date.now();
    const unixDate = parseInt(date);
    const yesterday = today - 1 * 24 * 60 * 60 * 1000;
    const sevenDaysAgo = today - 7 * 24 * 60 * 60 * 1000;

    if (unixDate > yesterday) {
      return t('wallet_new_account');
    }

    if (unixDate > sevenDaysAgo) {
      return t('common_this_week');
    }

    return t('common_older');
  };

  useEffect(() => {
    setStateList(notificationList);
  }, [notificationList]);

  useEffect(() => {
    // loads new data if reach ref element
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting && !isMomentumReachEnd) {
            if (!nextCursor) {
              // stop load when end
              setIsMomentumReachEnd(true);
              setShowLoadingText(false);
            }
            if (nextCursor) {
              // sets last cursor for pagination then load more
              getAllNextList?.();
            }
          }
        });
      });

      observer.observe(sentinelRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [isMomentumReachEnd]);

  const isVerifyMotivationNeeded = !user?.email || !user?.is_email_verified || !user?.mobile || !user?.is_mobile_verified;

  return (
    <div className="flex flex-col w-full h-full gap-[1.25rem] overflow-y-scroll">
      {isVerifyMotivationNeeded && <VerifyUserNotification onClick={() => toggleVerifyDialog(true)} />}
      {stateList.map((data, i) => {
        const group = groupByDate(data.created as string);
        return (
          <div key={i}>
            {group !== groupByDate(stateList[i - 1]?.created as string) && (
              <div className={`pb-[1.25rem] font-regular text-fs-caption text-neutral-400-zinc-500 ${isMinimized && 'px-[1.25rem]'}`}>
                {group}
              </div>
            )}
            <div className={`${!isMaximized && 'px-[1.25rem]'}`}>
              <NotificationCard
                isMinimized={isMinimized}
                id={data?.id}
                title={data?.title}
                body_en={data?.body_en}
                body_ar={data?.bodyHTML_ar}
                bodyHTML_en={data?.bodyHTML_en}
                bodyHTML_ar={data?.bodyHTML_ar}
                image={data?.image}
                event_type={data.event_type}
                is_read={data.is_read}
                created={data.created}
                notification_type={data.notification_type}
                conversation_id={data.conversation_id}
                conversation_type={data?.conversation_type}
                type={data?.type}
                avatar_url={data?.avatar_url}
                conversation_name={data?.conversation_name}
                invoice_number={data?.invoice_number}
                return_number={data?.return_number}
                collection_code={data?.collection_code}
                product_code={data?.product_code}
                mini_game_code={data?.mini_game_code}
                game_code={data?.game_code}
                tournament_id={data?.tournament_id}
                username={data?.username}
                currentTime={data?.currentTime}
                order_id={data?.order_id}
                sender_username={data?.sender_username}
                media_url={data?.media_url}
                image_url={data?.image_url}
                follower_notification_type={data?.follower_notification_type}
                follower_display_name={data?.follower_display_name}
                follower_username={data?.follower_username}
                is_following={data?.is_following}
                title_ar={data?.title_ar}
                title_en={data?.title_en}
                message={data?.message}
                asset_id={data?.asset_id}
                description={data?.description}
                asset_type={data?.asset_type}
                comment_parent_id={data?.comment_parent_id}
                comment_reply_id={data?.comment_reply_id}
                video_thumbnail={data?.video_thumbnail}
              />
            </div>
          </div>
        );
      })}
      <div className="text-secondary w-full flex flex-col col-span-4 justify-center items-center" ref={sentinelRef}>
        {showLoadingText && <span className="text-fs-body-large font-regular">{t('common_loading') || 'Loading'}...</span>}
      </div>
    </div>
  );
};

export default SidebarNotificationList;
