import { RootState } from '@fe-monorepo/store';
import { CSSProperties, MutableRefObject, ReactElement, forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import Spinner from '../Load/Spinner';

interface buttonProps {
  ref?: MutableRefObject<HTMLButtonElement | null>;
  style?: string;
  normalStyle?: string;
  text?: string | ReactElement | null;
  disabled?: boolean;
  disabledStyle?: string;
  action?: Function;
  fullWidth?: boolean;
  isLoading?: boolean;
  onClick?: () => void | null | undefined;
  className?: string;
  loaderClassName?: string;
}

const Button = forwardRef<HTMLButtonElement, buttonProps>((props, ref) => {
  const location = useLocation();
  const prefs = useSelector((state: RootState) => state?.app);
  const darkStyle =
    prefs.themes === 'dark' && !location.pathname.includes('shop') && !location.pathname.includes('payment')
      ? 'text-[#5D5C5E]'
      : 'text-black30';
  const { isLoading, onClick = null } = props;
  const dir = prefs?.isRTL === false ? `ltr` : `rtl`;

  const action = () => {
    if (props.action) {
      props?.action();
    }
  };

  const widthStyle: CSSProperties = { width: props.fullWidth ? '100%' : undefined };
  const cssClassName = twMerge(
    `
    ${props?.style} ${
      props?.disabled ? `cursor-not-allowed ${props.disabledStyle} ${darkStyle} ` : props?.normalStyle
    } button-animation-${dir}
  `,
    props.className,
  );
  return (
    <button
      ref={ref}
      onClick={() => (onClick ? onClick() : action())}
      style={widthStyle}
      className={cssClassName}
      disabled={props?.disabled || isLoading}
    >
      {isLoading && <Spinner className={props.loaderClassName} />}
      {!isLoading && props?.text}
    </button>
  );
});

export default Button;
