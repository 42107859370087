import { IconNames } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import { MenuItem } from '@fe-monorepo/models';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import LazyImage from '@fe-web/Atoms/Media/LazyImage';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from '../../app.routes.enum';
import Icon from '../../components/Atoms/Icon/Icon';
import Container from '../../components/Templates/Container';
import usePageLayout from '../../hooks/usePageLayout';
import ShopMegaMenuAnimation from './ShopMegaMenuAnimation';

type ShopMegaMenuType = {
  megeMenuRef: any;
  isMegaMenuOpen: boolean;
  isDuration: boolean;
  category?: string;
  megaMenuList?: MenuItem;
  mainCategoryId?: string;

  isClickedOutSideMegaMenu: (isOutSide: boolean) => void;
  actionLeaveHover: () => void;
};

const ShopMegaMenu = (props: ShopMegaMenuType) => {
  const navigate = useNavigate();
  const { translate } = useTranslate();
  const [hoverItem, setHoverItem] = useState<any>('');

  const pageLayout = usePageLayout();
  const dir = pageLayout?.direction;

  const language = usePageLayout()?.language;

  return (
    <div className={`w-full fixed z-[9999] top-[7.5rem] 2K:top-[7rem] 4K:top-[7.2rem] 8K:top-[7.2rem]`}>
      <ShopMegaMenuAnimation
        isDuration={props?.isDuration}
        actionLeaveHover={props?.actionLeaveHover}
        megeMenuRef={props?.megeMenuRef}
        isMegaMenuOpen={props?.isMegaMenuOpen}
      >
        <Container className={`relative sm:flex`}>
          <div className={`w-full`}>
            <div className={`flex items-center w-fit mb-22 sm:hidden`} onClick={props?.actionLeaveHover}>
              <Icon
                name={IconNames?.arrow}
                iconClasses={`h-[15px] w-[20px] 2K:h-[25px] 2K:w-[25px] 4K:h-[50px] 4K:w-[50px] 8K:h-[90px] 8K:w-[90px] fill-secondary`}
                className={`duration-[0.3s] rtl:rotate-[0deg] rotate-[180deg]`}
              />
              <p className={`text-bodySmall`}>{translate(`action_back`)}</p>
            </div>
            {props?.mainCategoryId &&
              props?.megaMenuList?.menu_items?.map((label, index) => {
                return (
                  <motion.li
                    key={index}
                    onMouseLeave={() => setHoverItem('')}
                    onMouseEnter={() => setHoverItem(label?.menu_code)}
                    onClick={() => {
                      props?.actionLeaveHover();

                      navigate(`${AppRoutes?.shop}/m-${label?.menu_code}`);
                    }}
                    className={`relative flex items-center gap-4 2K:gap-12 4K:gap-16 8K:gap-32 w-fit bottom-0 cursor-pointer ${
                      index === 0 ? `` : `mt-22 2K:mt-40 4K:mt-64 8K:mt-160`
                    } `}
                    initial={{
                      bottom: props?.isMegaMenuOpen ? `-30px` : `auto`,
                      opacity: props?.isMegaMenuOpen ? 1 : 0,
                    }}
                    animate={{
                      bottom: props?.isMegaMenuOpen ? `0px` : `auto`,
                      opacity: props?.isMegaMenuOpen ? [0, 0.5, 1] : 0,
                    }}
                    exit={{
                      bottom: props?.isMegaMenuOpen ? `-30px` : `auto`,
                      opacity: props?.isMegaMenuOpen ? [1, 0.5, 0] : 0,
                    }}
                    transition={
                      props?.isDuration
                        ? { duration: 0.15 * (index + 1) }
                        : {
                            duration:
                              0.2 * ((props?.megaMenuList?.menu_items?.length ? props?.megaMenuList?.menu_items?.length : 1) - index),
                          }
                    }
                  >
                    <p
                      className={`${
                        hoverItem === label?.menu_code ? `text-sunset` : ``
                      } duration-[0.3s] whitespace-nowrap text-subtitle 2K:text-title 4K:text-mobileDynamic 8K:text-xlargeDynamic`}
                    >
                      {language === 'en' ? label?.name_en : label?.name_ar}
                    </p>
                    <motion.div
                      className={`relative`}
                      initial={
                        dir === 'ltr'
                          ? { left: props?.isMegaMenuOpen ? `-10px` : `-10px`, right: `auto` }
                          : { left: props?.isMegaMenuOpen ? `10px` : `10px` }
                      }
                      animate={
                        dir === 'ltr'
                          ? { left: props?.isMegaMenuOpen ? `auto` : `-10px`, right: `auto` }
                          : { left: props?.isMegaMenuOpen ? `auto` : `10px` }
                      }
                      exit={
                        dir === 'ltr'
                          ? { left: props?.isMegaMenuOpen ? [`0px`, `-10px`] : `0px`, right: `auto` }
                          : { left: props?.isMegaMenuOpen ? [`0px`, `10px`] : `0px` }
                      }
                      transition={
                        props?.isDuration
                          ? { duration: 0.15 * (index + 1) }
                          : {
                              duration:
                                0.1 * ((props?.megaMenuList?.menu_items?.length ? props?.megaMenuList?.menu_items?.length - 1 : 1) - index),
                            }
                      }
                    >
                      <Icon
                        name={IconNames?.arrow}
                        iconClasses={`h-[20px] w-[40px] 2K:h-[25px] 2K:w-[25px] 4K:h-[50px] 4K:w-[50px] 8K:h-[90px] 8K:w-[90px]`}
                        className={`${
                          !props?.isMegaMenuOpen ? ` fill-sunset ` : hoverItem === label?.menu_code ? `fill-sunset` : `fill-secondary/40`
                        } duration-[0.3s] rotate-[-45deg] rtl:rotate-[-135deg]`}
                      />
                    </motion.div>
                  </motion.li>
                );
              })}
          </div>
          {props?.megaMenuList?.media[0] ? (
            <LazyImage
              img={`${props?.megaMenuList?.media[0]}`}
              divStyle="flex justify-end w-full h-full"
              imgStyle={`object-contain object-right rtl:object-left w-full h-[280px] sm:w-[380px] sm:h-[200px] md:w-[400px] md:h-[220px] lg:w-[556px] lg:h-[280px] 2K:h-[350px] 2K:w-[850px] 4K:h-[560px] 4K:w-[1350px] 8K:h-[1360px] 8K:w-[2940px] mt-32 sm:mt-0`}
              fallback={
                <ResponsiveIcon
                  name={IconNames.megaMenuPlaceholder}
                  baseWidth={400}
                  baseHeight={220}
                  iconClasses={`w-full h-[280px] sm:w-[380px] sm:h-[200px] md:w-[400px] md:h-[220px] lg:w-[556px] lg:h-[280px] 2K:h-[350px] 2K:w-[850px] 4K:h-[560px] 4K:w-[1350px] 8K:h-[1360px] 8K:w-[2940px] mt-32 sm:mt-0`}
                />
              }
            />
          ) : (
            <ResponsiveIcon
              name={IconNames.megaMenuPlaceholder}
              baseWidth={400}
              baseHeight={220}
              iconClasses={`w-full h-[280px] sm:w-[380px] sm:h-[200px] md:w-[400px] md:h-[220px] lg:w-[556px] lg:h-[280px] 2K:h-[350px] 2K:w-[850px] 4K:h-[560px] 4K:w-[1350px] 8K:h-[1360px] 8K:w-[2940px] mt-32 sm:mt-0`}
            />
          )}
          {/* // <img src={``} className={`w-[556px] h-[280px] sm:w-[380px] sm:h-[200px] md:w-[400px] md:h-[220px] lg:w-[556px] lg:h-[280px] 2K:h-[350px] 2K:w-[850px] 4K:h-[560px] 4K:w-[1350px] 8K:h-[1360px] 8K:w-[2940px] mt-32 sm:mt-0`}/> */}
        </Container>
      </ShopMegaMenuAnimation>
    </div>
  );
};

export default ShopMegaMenu;
