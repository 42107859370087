import { IconNames } from '@fe-monorepo/helper';
import { RootState } from '@fe-monorepo/store';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import { AppRoutes } from 'apps/fe-web/src/app/app.routes.enum';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const useDetectCapsLock = () => {
  const [toggled, setToggled] = useState<boolean>(false);

  useEffect(() => {
    const toggleCapslock = (event: KeyboardEvent) => setToggled(pToggled => event.getModifierState?.('CapsLock') ?? pToggled);

    document.addEventListener('keyup', toggleCapslock);
    document.addEventListener('keydown', toggleCapslock);

    return () => {
      document.removeEventListener('keyup', toggleCapslock);
      document.addEventListener('keydown', toggleCapslock);
    };
  }, []);

  return toggled;
};

interface InputProps {
  className?: string;
  value: string;
  isSecret?: boolean;
  isNumber?: boolean;
  isDateTime?: boolean;
  isDate?: boolean;
  isFocused: boolean;
  maxLength?: number;
  formPlaceHolder?: string;
  minDate?: string;
  maxDate?: string;
  min?: string | number;
  max?: string | number;
  disableDefaultInputWidth?: boolean;
  isEditable?: boolean;
  setValue: (value: string) => void;
  setFocused: (val: boolean) => void;
  onClickEnter?: () => void;
  id?: string;
}

const Input = (props: InputProps) => {
  const { pathname } = useLocation();
  const [isHidden, setHidden] = useState<boolean>(props?.isSecret ? props?.isSecret : false);
  const { t } = useTranslation();
  const themes = useSelector((state: RootState) => state.app.themes);

  const isCapslockOn = useDetectCapsLock();

  useEffect(() => setHidden(props?.isSecret ? props?.isSecret : false), [props?.isSecret]);

  const toggleHidden = () => setHidden(value => !value);

  const clearValue = () => props.setValue('');

  const setInputType = () => {
    if (isHidden) {
      return 'password';
    } else {
      if (props.isNumber) {
        return 'number';
      } else if (props.isDateTime) {
        return 'datetime-local';
      } else if (props.isDate) {
        return 'date';
      } else {
        return 'text';
      }
    }
  };

  const setInputPlaceHolder = () => {
    if (props?.isFocused) {
      if (props?.formPlaceHolder) {
        return props?.formPlaceHolder;
      } else {
        return '' + t('formPlaceholder_start_typing');
      }
    } else {
      return '';
    }
  };

  useEffect(() => {
    if ((props.isDate || props.isDateTime) && props?.id) {
      const inputElement = document.getElementById(props.id);
      if (inputElement) {
        (inputElement as HTMLInputElement).addEventListener('focus', () => {
          (inputElement as HTMLInputElement).showPicker();
        });
      }
    }
  }, [props.isFocused]);

  return (
    <div className="relative grid items-center w-full h-fit">
      <input
        id={props?.id}
        className={`z-1 ${props.disableDefaultInputWidth ? '' : 'w-[93%]'} outline-0 bg-transparent ${
          props.isDateTime || (props.isDate && `hideDate`)
        } ${
          props.value || props.isFocused
            ? themes === 'dark' && !location.pathname.includes('payment') && !location.pathname.includes('shop')
              ? 'dateTimeShowLight'
              : 'dateTimeShowDark'
            : 'dateTimeHide'
        } ${props?.className} 
          focus:outline-none ${
            props.isNumber
              ? ` [appearance:textfield] [& ::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`
              : ` `
          }`}
        type={setInputType()}
        value={props.value}
        placeholder={setInputPlaceHolder()}
        onChange={e => props.setValue(e.target.value)}
        onFocus={() => props.setFocused(true)}
        onBlur={() => props.setFocused(false)}
        onKeyDown={event => {
          if (event.key === 'Enter' && props.onClickEnter) {
            props.onClickEnter();
          } else if (props.isNumber && (event.key === '-' || event.key === '+' || event.key === 'e')) {
            event.preventDefault();
          }
        }}
        maxLength={props.maxLength}
        min={setInputType() === 'number' ? props.min : props.minDate}
        max={setInputType() === 'number' ? props.max : props.maxDate}
        style={
          props.isDateTime ||
          (props.isDate &&
            themes === 'dark' &&
            !pathname.includes(AppRoutes.authSignIn) &&
            !pathname.includes(AppRoutes.authSignUp) &&
            !location.pathname.includes('payment') &&
            !location.pathname.includes('shop'))
            ? { colorScheme: 'dark' }
            : {}
        }
        disabled={props.isEditable}
      />

      {!props?.isEditable && (
        <div className={'absolute cursor-pointer justify-self-end h-fit w-fit' + (props.isDateTime || props.isDate ? ' mr-[1.5rem]' : ' ')}>
          {props?.isSecret ? (
            <div className="flex items-center gap-12 2K:gap-[21.33px] 4K:gap-32 8K:gap-64">
              {isCapslockOn && props.isFocused && (
                <ResponsiveIcon
                  className="z-[1] fill-secondary"
                  name={IconNames.capslock}
                  baseWidth={24}
                  baseHeight={24}
                  onClick={toggleHidden}
                />
              )}

              <ResponsiveIcon
                className={`${isHidden && 'stroke-secondary'} z-[1]`}
                iconClasses="fill-secondary"
                name={isHidden ? IconNames.eyeOutline : IconNames.eyeClosedOutline}
                baseWidth={24}
                baseHeight={24}
                onClick={toggleHidden}
              />
            </div>
          ) : (
            props.value.length > 0 && (
              <ResponsiveIcon
                className="defaultTheme:fill-black100 dark:fill-white100 z-[1] "
                name={IconNames.close_xbutton}
                baseWidth={10}
                baseHeight={10}
                onClick={clearValue}
              />
            )
          )}
        </div>
      )}
    </div>
  );
};

export default Input;
