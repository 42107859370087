import { gql } from '@apollo/client';

export const COMMUNITY_BLOCK_MUTATION = {
    crmCommunityBlock: gql`
        mutation crmCommunityBlock($details: BlockInput) {
            crmCommunityBlock(details: $details) {
            error_msg,
            is_successful,
            error_code
        }
        }
    `,

crmCommunityUnblock: gql`
    mutation crmCommunityUnblock($details: BlockInput) {
        crmCommunityUnblock(details: $details) {
        error_msg,
        is_successful,
        error_code
        }
    }
    `
}