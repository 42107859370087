import { EditFormFunction, ValidationParameters, ValidationRule, useInputValidation } from '@fe-monorepo/forms';
import { PHONE_NUMBER_LIMITS, Validator } from '@fe-monorepo/helper';
import { useGeo } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { authInputFieldStyleObj } from '../../../pages/AuthenticationScreen/commonStyles';
import CombinedInputField from './CombinedInputField';

type Props = {
  apiErrorMessage?: string;
  label?: string;
  customPlaceholder?: string;
  existingPhoneObj?: {
    dropDownValueCode: string;
    inputValue: string;
  };
  dropdown_style?: {
    dropdown_container_style?: string;
    dropdown_style?: string;
  };
  onPhoneNumberChange: EditFormFunction;
  onCountryCode: (e: any) => void;
  resetAPIError?: () => void;
  onClickEnter?: () => void;
  retrieveFocus?: (value: boolean) => void;
};

const PhoneNumberField = (props: Props) => {
  const prefs = useSelector((state: RootState) => state.app);

  const [phoneObj, setPhoneObj] = useState<any>({});
  const [validationRules, setValidationRules] = useState<ValidationRule[]>([]);
  const [defaultCountry, setDefaultCountry] = useState({});
  const [countryList, setCountryList] = useState<any[]>([]);
  const { countryData, getAllCountries } = useGeo();

  const validationParameters: ValidationParameters = {
    inputFieldKey: 'phoneNumber',
    isOptional: true,
    validationRules: validationRules,
    apiErrorMessage: props.apiErrorMessage,
    resetAPIError: props.resetAPIError,
    onChange: props.onPhoneNumberChange,
    dependencies: [phoneObj?.dropDownValue?.phone_code_iso, validationRules],
  };

  const { errorMessage, setValue, setFocused, setTyped } = useInputValidation(validationParameters);

  const search = (searchKey: string) => {
    const searchTerm = searchKey.replace('+', '');

    return countryList?.filter(country => {
      if (!('name_ar' in country && 'name_en' in country && 'phone_code_iso' in country && 'country_code_iso_2' in country)) {
        return false;
      }

      return (
        country[`name_${prefs.language}`]?.toLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
        country?.phone_code_iso?.includes(searchTerm)
      );
    });
  };

  const getStringValue = (arrayElement: any) => {
    if (arrayElement) {
      return `${arrayElement[`name_${prefs.language}`]} (+${arrayElement?.phone_code_iso})`.trim();
    } else {
      return '';
    }
  };

  const getSearchValue = (arrayElement: any) => {
    if (arrayElement) {
      return '' + (arrayElement?.phone_code_iso ? '+' + arrayElement?.phone_code_iso : '').replace(/(\r\n|\n|\r)/gm, ' ');
    } else {
      return '';
    }
  };

  const isUserAllowedToType = (value: string) => Validator.isDigitsOnly(value) && value?.length <= PHONE_NUMBER_LIMITS.max;

  useEffect(() => {
    getAllCountries();
  }, []);

  useEffect(() => {
    if (countryData && countryData?.length > 0) {
      const filterKeys = ['name_ar', 'name_en', 'phone_code_iso', 'country_code_iso_2'];

      const filteredList = countryData
        ?.filter(country => {
          for (const element of filterKeys) {
            const key = element;
            if (!(key in country)) {
              return false;
            }
          }

          return true;
        })
        .map(country => {
          const newCountry = {
            id: country.phone_code_iso,
            ...country,
          };

          return newCountry;
        });

      const defaultCount = filteredList?.filter(i => i.phone_code_iso === '966')[0];
      if (props?.existingPhoneObj?.dropDownValueCode) {
        const existingDefPhoneCode = filteredList?.filter(
          i => i.phone_code_iso === props.existingPhoneObj?.dropDownValueCode.toString(),
        )[0];
        setDefaultCountry(existingDefPhoneCode);
      } else {
        setDefaultCountry(defaultCount);
      }
      setCountryList(filteredList);
    }
  }, [countryData]);

  useEffect(() => {
    const countryCode = phoneObj?.dropDownValue?.phone_code_iso as string;
    const inputValue = phoneObj?.inputValue;

    if (countryCode + inputValue !== '') {
      props.onCountryCode(phoneObj?.dropDownValue);

      setValidationRules(oldRules => {
        const newRules: ValidationRule[] = [...oldRules];

        newRules[0] = {
          checkValidity: (value: string) =>
            !isNil(countryCode) && value?.length >= PHONE_NUMBER_LIMITS.min && value?.length <= PHONE_NUMBER_LIMITS.max,
          errorMessage: 'validation_invalid_mobile',
        };
        return newRules;
      });

      if (props?.existingPhoneObj?.inputValue) {
        if (inputValue && inputValue !== props.existingPhoneObj.inputValue) {
          setValue(inputValue);
        } else {
          setValue(props.existingPhoneObj.inputValue);
        }
      } else {
        setValue(inputValue);
      }
    } else {
      setValue('');
    }
  }, [phoneObj, prefs.language]);

  /**
     * [appearance:textfield]
          //           [&::-webkit-outer-spin-button]:appearance-none
          //           [&::-webkit-inner-spin-button]:appearance-none
     */

  return (
    <CombinedInputField
      dir="ltr"
      style={{
        ...authInputFieldStyleObj,
        containerStyle: '',
      }}
      label={props.label ? props.label : ''}
      customPlaceholder={props.customPlaceholder}
      inputType="number"
      errorMessage={errorMessage}
      dropDown={{
        default: defaultCountry,
        list: countryList,
        dropdown_container_style: props.dropdown_style?.dropdown_container_style,
        dropdown_style: props.dropdown_style?.dropdown_style,

        search: search,
        getSearchValue: getSearchValue,
        getStringValue: getStringValue,
      }}
      retrieveValue={setPhoneObj}
      retrieveTyped={setTyped}
      retrieveFocus={(value: boolean) => {
        setFocused(value);
        props?.retrieveFocus && props.retrieveFocus(value);
      }}
      isUserAllowedToType={isUserAllowedToType}
      onClickEnter={props.onClickEnter}
      inputDefaultValue={props.existingPhoneObj?.inputValue ?? ''}
    />
  );
};

export default PhoneNumberField;
