import { useTranslate } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import HoverText from '@fe-web/Atoms/Text/HoverText';
import useTheme from '@fe-web/hooks/useTheme';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from '../../../app.routes.enum';
import Image from '../../../components/Atoms/Media/Image';

interface brandSectionProps {
  name_en?: string;
  name_ar?: string;
  menu_items?: {
    menu_code: string;
    name_en: string;
    name_ar: string;
    media: string[];
    collection_code?: string;
  }[];
  menu_code?: string;
}

const BrandsSection = ({ name_en, name_ar, menu_items, menu_code }: brandSectionProps) => {
  const navigate = useNavigate();
  const { translate } = useTranslate();
  const prefs = useSelector((state: RootState) => state?.app);
  const dir = prefs?.language;
  const { isDarkTheme } = useTheme();

  const gotobrandpage = `${AppRoutes.shopSubCategory}/m-${menu_code}`;

  return (
    <div className="flex flex-col items-start gap-[2.5rem] px-20 md:px-64 lg:px-120 4xl:px-160 5xl:px-320 8xl:px-655">
      <div className="flex flex-start justify-between gap-[2.5rem] mt-[2.5rem] w-full">
        <p
          className="text-bigTitleSm SD:text-bigTitle 2K:text-huge 4K:text-eightKSubtitle 8K:text-fiveKDynamic font-medium text-secondary inline-flex gap-x-24 items-center
                "
        >
          {dir === 'en' ? name_en : name_ar}
        </p>
        <HoverText
          className={`
                            font-regular
                            text-sunset
                            text-body 2K:text-subtitle 4K:text-bigTitle 8K:text-LPTitle whitespace-nowrap hidden sm:flex
                            tracking-[-0.0125rem] leading-[1.25rem]
                            `}
          hover={{ color: 'text-sunset' }}
          alwaysShowUnderline={true}
          underlineStyle="bg-sunset
                        h-[1px] 2K:h-[1.77px] 4K:h-[2.66px] 8K:h-[5.33px] bottom-2"
          text={translate('common_see_all')?.toString() + ''}
          onClick={() => navigate(gotobrandpage)}
        />
      </div>
      <div className="flex flex-start gap-[2.5rem] mt-[2.5rem] w-full">
        {!!menu_items?.length &&
          menu_items?.map(brand => (
            <div
              className="flex flex-col items-start gap-[1.5rem] flex-[1_0_0%]"
              onClick={() => navigate(`${AppRoutes.shop}/m-${brand.menu_code}`)}
            >
              <Image
                img={brand.media[0]}
                divStyle="w-[10.57569rem] h-[4.17206rem] items-center flex shirnk-0 hover:cursor-pointer overflow-hidden hover:scale-productImage transition transition-all duration-300"
                imgStyle={`w-[7.76194rem]  shrink-0 relative ${
                  isDarkTheme ? 'drop-shadow-[12rem_0_0_#FFFFFF]' : 'drop-shadow-[12rem_0_0_#171619]'
                } left-[-12rem]`}
              />
            </div>
          ))}
      </div>
      <p
        className={
          'text-[#E95F2A] text-center text-[1rem] font-[400] leading-[1.25rem] tracking-[-0.0125rem] hover:cursor-pointer underline visible sm:invisible'
        }
        onClick={() => navigate(gotobrandpage)}
      >
        {translate('common_see_all')}
      </p>
    </div>
  );
};

export default BrandsSection;
