import { IconNames } from '@fe-monorepo/helper';
import useTheme from '@fe-web/hooks/useTheme';
import { MutableRefObject, useEffect, useState } from 'react';

import useOutsideClick from '../../../../hooks/useOutsideClick';
import ResponsiveIcon from '../../../Atoms/Icon/ResponsiveIcon';
import ToolTip from '../../../Atoms/ToolTip';
import DropDownList from './Components/DropDownList';

interface UnderlineProps {
  isFocused: boolean;
  isError: boolean;
  underlineStyle?: string;
  errorStyle?: string;
  errorMessage?: string;
}

const Underline = (props: UnderlineProps) => {
  return (
    <>
      <div
        className={`w-full ${props?.isError ? 'bg-red' : props.isFocused ? 'bg-sunset' : 'bg-gray-200-zinc-700'} ${props?.underlineStyle}`}
      />

      {!props.isFocused && props.isError && props.errorMessage && (
        <div className={`mt-4 2K:mt-8 4K:mt-12 8K:mt-22 h-20 4xl:h-36 5xl:h-54 8xl:h-100`}>
          <p
            className={`
          text-fs-body-small
          font-regular text-red ${props.errorStyle}`}
          >
            {props.errorMessage}
          </p>
        </div>
      )}
      {/* <div className={'mt-4 h-20'} /> */}
    </>
  );
};

interface InputProps {
  parentRef: MutableRefObject<HTMLDivElement | null | undefined>;
  className?: string;

  dropdown_style?: {
    dropdown_container_style?: string;
    dropdown_style?: string;
  };

  filteredList: any[];
  currentValue?: string;
  value: string;
  isFocused: boolean;
  isCombined?: boolean;

  getStringValue: (dropDownElement: any) => string;
  setValue: (value: string) => void;
  setFocused: (val: boolean) => void;
  selectOption: (dropDownElement: any) => void;

  onClickEnter?: () => void;
}

const Input = (props: InputProps) => {
  const { filteredList, getStringValue } = props;

  const [localFocus, setLocalFocus] = useState<boolean>(false);
  const [isToolTipOn, setToolTipOn] = useState<boolean>(false);
  const { isDarkTheme } = useTheme();

  const toggleToolTip = () => {
    if (props.isFocused) {
      props.setFocused(false);
      setToolTipOn(false);
      setLocalFocus(false);
    } else {
      setToolTipOn(value => !value);
    }
  };

  const selectValue = (dropDownElement: any) => {
    props.selectOption(dropDownElement);

    setTimeout(() => {
      setLocalFocus(false);
      setToolTipOn(false);
      props.setFocused(false);
    }, 10);
  };

  const elementRef = useOutsideClick(() => {
    setLocalFocus(false);
    props.setFocused(false);
    setToolTipOn(false);
  });

  elementRef.current = props.parentRef.current;

  const isToolTipShown = isToolTipOn || localFocus;

  return (
    <div className="relative grid items-center w-full h-fit">
      <input
        className={`z-1 w-full outline-0 bg-transparent ${props?.className}`}
        type={'text'}
        value={props.value}
        onChange={e => props.setValue(e.target.value)}
        onFocus={() => {
          setLocalFocus(true);
          props.setFocused(true);
        }}
        onKeyDown={event => {
          if (event.key === 'Enter' && props.onClickEnter) {
            props.onClickEnter();
          }
        }}
        onBlur={() => {
          if (props.isCombined) {
            setLocalFocus(false);
          }
        }}
      />

      <div className="absolute cursor-pointer justify-self-end h-fit w-fit">
        <ResponsiveIcon
          className={`z-[1] ${isDarkTheme ? 'fill-sunset' : 'fill-black100'} ${isToolTipShown && 'rotate-180 fill-sunset'}`}
          name={IconNames.chevron}
          baseHeight={24}
          baseWidth={24}
          onClick={toggleToolTip}
        />
      </div>

      {isToolTipShown && filteredList?.length > 0 && (
        <ToolTip
          className="absolute mt-4 4xl:mt-8 5xl:mt-12 8xl:mt-20"
          content={
            <DropDownList
              currentValue={props?.currentValue}
              options={filteredList}
              selectedIcon={
                <ResponsiveIcon
                  className="z-[1]"
                  name={IconNames.checkFill}
                  baseHeight={16.67}
                  baseWidth={16.67}
                  iconClasses={`${isDarkTheme ? 'fill-[#D1D0D1]' : ''}`}
                />
              }
              selectedIconPosition="start"
              selectValue={selectValue}
              getStringValue={getStringValue}
              dropdownClass={props.dropdown_style?.dropdown_style}
              containerClass={props.dropdown_style?.dropdown_container_style}
              setToolTipOn={setToolTipOn}
            />
          }
        />
      )}
    </div>
  );
};

export interface Props {
  style?: {
    containerStyle?: string;
    inputStyle?: string;
    underlineStyle?: string;
  };

  dropdown_style?: {
    dropdown_container_style?: string;
    dropdown_style?: string;
  };

  parentRef: MutableRefObject<HTMLDivElement | null | undefined>;

  isError: boolean;
  isFocused: boolean;
  currentValue?: string;
  list: string[];
  isCombined?: boolean;
  errorMessage?: string;

  search: (searchKey: string) => string[];
  getStringValue: (dropDownElement: any) => string;
  getSearchValue: (dropDownElement: any) => string;
  retrieveValue: (dropDownElement: any) => void;
  setFocused: (value: boolean) => void;

  onClickEnter?: () => void;
}

const SearchableDropDown = (props: Props) => {
  const {
    style,
    list,
    isFocused,
    isCombined,
    errorMessage,

    setFocused,
    getStringValue,
    getSearchValue,
    onClickEnter,
  } = props;

  const [value, setValue] = useState<string>(props?.currentValue ? getSearchValue(props?.currentValue) : '');
  const [filteredList, setFilteredList] = useState<string[]>(list);
  const [searchString, setSearchString] = useState<string>('');
  const [showError, setShowError] = useState<boolean>(false);
  const [prevSearchString, setPrevSearchString] = useState<string>('');

  const filterList = (value: string) => {
    const newFilterList = props.search(value || prevSearchString);
    setSearchString(value || prevSearchString);
    setFilteredList(newFilterList);
  };

  const selectOption = (dropDownElement: any) => {
    setValue(getSearchValue(dropDownElement));
    props.retrieveValue(dropDownElement);
  };

  useEffect(() => {
    setValue(getSearchValue(props?.currentValue));
  }, [props.currentValue]);

  useEffect(() => setFilteredList(list), [list]);

  useEffect(() => filterList(value), [value]);

  useEffect(() => {
    if (!isFocused && !!searchString) {
      if (filteredList.length === 1) {
        setValue(getSearchValue(filteredList[0]));
        props.retrieveValue(filteredList[0]);
        setShowError(false);
      } else {
        setShowError(true);
        setPrevSearchString(searchString);
      }
    } else {
      if (showError) {
        props.retrieveValue({});
        filterList(prevSearchString);
        setShowError(false);
      }
    }
  }, [isFocused]);

  return (
    <div className={`${style?.containerStyle}`}>
      <div className="flex w-full">
        <Input
          parentRef={props.parentRef}
          className={style?.inputStyle}
          value={value}
          currentValue={props?.currentValue}
          filteredList={filteredList}
          isFocused={isFocused}
          dropdown_style={{
            dropdown_container_style: props.dropdown_style?.dropdown_container_style,
            dropdown_style: props.dropdown_style?.dropdown_style,
          }}
          getStringValue={getStringValue}
          setValue={setValue}
          setFocused={setFocused}
          selectOption={selectOption}
          onClickEnter={onClickEnter}
          isCombined={isCombined}
        />
      </div>

      <Underline underlineStyle={style?.underlineStyle} isFocused={isFocused} isError={showError} errorMessage={errorMessage} />
    </div>
  );
};

export default SearchableDropDown;
