import { IconNames } from '@fe-monorepo/helper';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import { useConnectProvider } from '@fe-web/hooks/useConnect';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { AppRoutes } from '../../../app.routes.enum';
import { SidebarChatContainer } from '../../../pages/Connect/SideBarChatOverView';

const NavChat = () => {
  const location = useLocation();
  const [isChatOpen, setIsChatOpen] = useState<boolean>(false);
  const [isOptionOpened, setIsOptionOpened] = useState<boolean>(false);
  const { conversationList } = useConnectProvider();
  const ChatNum = conversationList ? conversationList?.filter(item => item.unreadMessageCount > 0).length : 0;

  const handleNavChatClick = () => {
    const isMaximized = location.pathname.includes(AppRoutes.messages);
    if (!isMaximized) {
      setIsChatOpen(!isChatOpen);
    }
  };

  const isOptionOpenedOnClick = (value: boolean) => {
    setIsOptionOpened(value);
  };

  const closeSidebar = () => {
    setIsChatOpen(false);
  };

  useEffect(() => {
    if (isChatOpen) {
      mixpanelHelper.trackPageView('CONNECT PAGE', 'connect_page', AppRoutes.messages, document.referrer);
    }
  }, [isChatOpen]);

  return (
    <div className="z-10">
      <div className="relative hover:cursor-pointer" onClick={handleNavChatClick}>
        <ResponsiveIcon
          name={IconNames.icon_header_chat}
          baseHeight={30}
          baseWidth={30}
          iconClasses={`${isChatOpen ? 'fill-secondary' : 'fill-secondary/50'} hover:fill-secondary`}
        />
        {ChatNum > 0 ? (
          <span
            className="flex justify-center
              absolute bottom-[0.75rem] left-[0.75rem]
              rounded-[1.875rem] 
              w-[1.5rem] text-fs-body-small
              bg-red text-secondary font-regular"
          >
            {ChatNum}
          </span>
        ) : null}
      </div>
      <SidebarChatContainer
        isChatOpen={isChatOpen}
        closeSidebar={closeSidebar}
        showFullViewIcon={true}
        fixedPosition={true}
        isOptionOpenedOnClick={isOptionOpenedOnClick}
      />
    </div>
  );
};

export default NavChat;
