import { gql } from '@apollo/client';

export const CURRENCY_QUERY = {
    getCurrency: gql`
       query fetchCurrency {
            Currency {
                is_successful,
                error_code,
                error_msg,
                app_code,
                data {
                    currency_name_en,
                    currency_name_ar,
                    currency_rate,
                    currency_code
                }
            }
        }
    `,
};
