import { gql } from '@apollo/client';

export const GIFTCARD_QUERY = {
    getGiftCardInfo: gql`
        query fetchGiftCardInfo($details: GiftCardParam) {
            getGiftCardInfo(details: $details) {
                error_msg
                error_code
                is_successful
                data {
                    amount
                    status_code
                    expiration_date
                }
            }
        }
    `,
};
