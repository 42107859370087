import { BANNER_QUERY } from '@fe-monorepo/data-access';
import { ShopBannerModel } from '@fe-monorepo/models';
import { setHeroBanner, useAppDispatch } from '@fe-monorepo/store';
import { useEffect, useState } from 'react';
import { useFetchQuery } from '../useFetchQuery';

export const useShopBanner = () => {
    const [isLoading, setIsLoading] = useState(false);
    const getAllBannersResponse = useFetchQuery<ShopBannerModel[]>(BANNER_QUERY.getAllBanners, []);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (getAllBannersResponse.data) {
            const response = getAllBannersResponse.data;
            if (response.length > 0) {
                dispatch(setHeroBanner(response));
            }
        }
        setIsLoading(false);
    }, [getAllBannersResponse.data]);

    const getAll = async () => {
        setIsLoading(true);
        return getAllBannersResponse.fetch();
    };

    return {
        getAll,
        isLoading,
        setIsLoading,
    };
};
