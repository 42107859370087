import { IconNames } from '@fe-monorepo/helper';
import { RootState } from '@fe-monorepo/store';
import { getStyle } from '@fe-monorepo/themes';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import Image from '@fe-web/Atoms/Media/Image';
import { ReactNode, Ref, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';

interface FileInputProps {
  type?: 'image';
  label?: ReactNode;
  showFileName?: boolean;
  imgContainerStyle?: string;
  imgStyle?: string;
  uploadContainerStyle?: string;
  displayContainerStyle?: string;
  resetButtonStyle?: string;
  iconStyle?: string;
  labelClassName?: string;
  buttonLabel?: ReactNode;
  resetIcon?: IconNames;
  customInput?: ReactNode;
  setValue?: (file: any) => void;
  setValueRender?: (fileRender: string | ArrayBuffer | null | undefined) => void;
  setReset?: (reset: boolean) => void;
  onDrop?: (<T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void) | undefined;
  onDropRejected?: ((fileRejections: FileRejection[], event: DropEvent) => void) | undefined;
}

const FileInput = (
  {
    type,
    label,
    buttonLabel,
    setValue,
    labelClassName,
    imgContainerStyle,
    imgStyle,
    uploadContainerStyle,
    displayContainerStyle,
    resetButtonStyle,
    iconStyle,
    showFileName = true,
    resetIcon,
    customInput,
    setReset,
    setValueRender,
    onDrop,
    onDropRejected,
  }: FileInputProps,
  ref: Ref<any>,
) => {
  const { themes } = useSelector((state: RootState) => state.app);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<string | ArrayBuffer | null | undefined>();
  const [file, setFile] = useState<File>();

  const handleOnDrop = useCallback((acceptedFiles: Array<File>) => {
    const file = new FileReader();

    file.onload = function () {
      setImage(file.result);
    };

    file.readAsDataURL(acceptedFiles[0]);
    setFile(acceptedFiles[0]);
  }, []);

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDrop ? onDrop : handleOnDrop,
    onDropRejected,
    accept:
      type === 'image'
        ? { 'image/jpeg': [], 'image/png': [], 'image/bmp': [], 'image/tiff': [], 'image/gif': [], 'image/webp': [] }
        : undefined,
  });

  const onReset = useCallback(() => {
    const current = fileInputRef.current;
    if (current) {
      current.value = '';
      setImage(null);
      setFile(undefined);
    }
    setReset && setReset(true);
  }, [fileInputRef]);

  const onClickInput = () => fileInputRef.current?.click();

  useImperativeHandle(ref, () => ({
    onReset,
  }));

  useEffect(() => {
    setValue?.(file);
  }, [file, setFile]);

  useEffect(() => {
    setValueRender?.(image);
  }, [image]);

  return (
    <>
      <div>
        {!!file && !customInput && (
          <div className={`${displayContainerStyle || ''} flex gap-[1.5rem] items-center`}>
            {type === 'image' && (
              <Image
                img={image}
                imgStyle={`${imgStyle || ''} w-full h-full`}
                divStyle={`${imgContainerStyle || ''} w-full h-full max-w-[6.75rem] max-h-[4rem] object-cover overflow-hidden`}
              />
            )}
            {showFileName && (
              <span
                style={{ color: getStyle(themes).textColor }}
                className="flex-1 font-regular text-fs-body-small text-left rtl:text-right"
              >
                {file?.name}
              </span>
            )}
            <button className={resetButtonStyle || ''} type="button" onClick={onReset}>
              <ResponsiveIcon
                name={resetIcon ? resetIcon : IconNames.deleteOutline}
                baseWidth={16}
                baseHeight={16}
                iconClasses={`${iconStyle || ''} fill-sunset`}
              />
            </button>
          </div>
        )}
        <div
          className={`${uploadContainerStyle || ''} ${
            !customInput
              ? `flex flex-col w-full items-center gap-[0.5rem] relative py-[1.5rem] rounded-[0.25rem] ${!!file ? 'hidden' : ''}`
              : ''
          }`}
          {...getRootProps()}
          onClick={onClickInput}
        >
          {label && <span className={`${labelClassName || ''} font-regular text-fs-body-small text-zinc-600`}>{label}</span>}
          <input
            {...getInputProps()}
            multiple={false}
            type="file"
            accept={type === 'image' ? 'image/jpeg, image/png, image/bmp, image/tiff, image/gif, image/webp' : undefined}
            ref={fileInputRef}
            className="absolute w-0 h-0 touch-events-none opacity-0 pointer-events-none"
          />
          <div className={`inline-flex justify-center`}>
            {customInput ? (
              customInput
            ) : (
              <>
                <button type="button" className="font-regular text-fs-body text-sunset">
                  {buttonLabel || 'Browse files'}
                </button>
                <div className="absolute top-0 w-full h-full">
                  <svg width="100%" height="100%" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect
                      className={`[stroke-dasharray:8px] 2K:[stroke-dasharray:14px] 4K:[stroke-dasharray:21.33px] 8k:[stroke-dasharray:42.67px]`}
                      x="0.0313rem"
                      y="0.0313rem"
                      width="99%"
                      height="99%"
                      rx="0.2188rem"
                      fill="transparent"
                      stroke="#5D5C5EB3"
                      stroke-width="0.0625rem"
                      stroke-linecap="square"
                    />
                  </svg>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default forwardRef(FileInput);
