import { gql } from '@apollo/client';

export const MATCHMAKING_COMMUNITY_MUTATION = {
    tmsMatchmakingCommunityFollow: gql`
        mutation tmsMatchmakingCommunityFollow($details: TmsMatchmakingCommunityFollowInput) {
            tmsMatchmakingCommunityFollow(details: $details) {
                error_msg
                is_successful
                error_code
            }
        }
    `,

    tmsMatchmakingCommunityUnfollow: gql`
        mutation tmsMatchmakingCommunityUnfollow($details: TmsMatchmakingCommunityUnfollowInput) {
            tmsMatchmakingCommunityUnfollow(details: $details) {
                error_msg
                is_successful
                error_code
            }
        }
    `,

    tmsMatchmakingCommunityBlockUser: gql`
        mutation tmsMatchmakingCommunityBlockUser($details: TmsMatchmakingCommunityBlockUserInput) {
            tmsMatchmakingCommunityBlockUser(details: $details) {
                error_msg
                is_successful
                error_code
            }
        }
    `,
};
