import { getUser } from '@braze/web-sdk';
import { IMAGES } from '@fe-monorepo/assets';
import { useForm } from '@fe-monorepo/forms';
import {
  IconNames,
  PAGE_DESCRIPTION,
  PAGE_KEYWORDS,
  PAGE_TITLE,
  TxKeyPath,
  convertGMTToIana,
  getCurrentUserTimeZone,
  maskPhoneNumber,
} from '@fe-monorepo/helper';
import { PREF_2FA_CHANNEL, useAccount, useTranslate } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import Button from '@fe-web/Atoms/Buttons/Button';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import LazyImage from '@fe-web/Atoms/Media/LazyImage';
import { toastWrapper } from '@fe-web/Atoms/Toast';
import GeneralError from '@fe-web/Molecules/ErrorScreens/GeneralError';
import PhoneNumberField from '@fe-web/Molecules/InputFields/PhoneNumberField';
import CustomHelmet from '@fe-web/Organisms/CustomHelmet/CustomHelmet';
import Modal from '@fe-web/Templates/Modal/Modal';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import usePageLayout from '@fe-web/hooks/usePageLayout';
import useTheme from '@fe-web/hooks/useTheme';
import { format, parseISO } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import arLocale from 'date-fns/locale/ar';
import enLocale from 'date-fns/locale/en-US';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { otpGTMConfirmed, otpGTMFailed } from '../../../../../helper/webAnalytics';
import { AppRoutes } from '../../../app.routes.enum';
import PhoneNumberEmpty from './PhoneNumberEmpty';
import PhoneNumberOTP from './PhoneNumberOTP';

const PhoneNumber = () => {
  const isRTL = useSelector((state: RootState) => state.app.isRTL);
  const User = useSelector((state: RootState) => state.user.userContext);
  const [isPhoneNumberChange, setIsPhoneNumberChange] = useState(false);
  const [isOTPPhase, setIsOTPPhase] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [phoneNumberCode, setPhoneNumberCode] = useState<string>('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [haserror, setHaserror] = useState(false);
  const [isHome, setIsHome] = useState(true);
  const [message, setMessage] = useState<string>('');
  const { isDarkTheme } = useTheme();
  const { translate } = useTranslate();
  const { btnClass } = usePageLayout();
  const { form, isFormValid, editForm } = useForm();
  const navigate = useNavigate();
  const { getUserProfile } = useAccount();
  const [existingPhoneNumber, setExistingPhoneNumber] = useState<string>('');

  const language = useSelector((state: RootState) => state.app.language);
  const preferred_timezone = useSelector((state: RootState) => state.user.userContext.preferred_timezone);

  const locale = language === 'ar' ? arLocale : enLocale;

  const updatedDate = User?.mobile_updated_at ? parseISO(User?.mobile_updated_at) : '';

  const finalTimezone = preferred_timezone ? convertGMTToIana(preferred_timezone) : getCurrentUserTimeZone();

  const zonedDate = toZonedTime(updatedDate, finalTimezone);

  const onButtonChangeClick = (value: boolean) => {
    //change ui to base view, set new mobile num or verfiy otp
    setIsPhoneNumberChange(value);
    setIsOTPPhase(false);
    setIsHome(!value);
  };

  const onCancelButton = () => {
    setIsPhoneNumberChange(false);
    setIsOTPPhase(false);
    setIsHome(true);
  };

  const closeModal = () => {
    //closing of modal
    setHaserror(false);
  };

  useEffect(() => {
    //fetches the updated userprofile upon otp verification
    if (isSuccess) {
      getUserProfile();
    }
  }, [isSuccess]);

  useEffect(() => {
    //toast modal
    if (!message) return;

    if (isSuccess) {
      mixpanelHelper.setUserProperties({
        'Is Mobile Verified': true,
      });

      getUser()?.setPhoneNumber(`+${form.phone_code_iso?.value}${form.phoneNumber?.value}`);
      getUser()?.setCustomUserAttribute('Is Mobile Verified', true);

      otpGTMConfirmed(PREF_2FA_CHANNEL.mobile);
      toastWrapper('success', message);
    } else {
      otpGTMFailed(PREF_2FA_CHANNEL.mobile, 'none', message);
      toastWrapper('error', message);
    }
  }, [isSuccess, message]);

  useEffect(() => {
    //set phone number and code
    setPhoneNumber(form.phoneNumber?.value);
    setPhoneNumberCode(form.phone_code_iso?.value);
  }, [form.phoneNumber?.value, form.phone_code_iso?.value]);

  useEffect(() => {
    //prompts user upon page leave
    if (isOTPPhase) {
      window.history.pushState(null, '', window.location.href);
      window.onpopstate = () => {
        window.history.go(1);
        setHaserror(true);
      };
    }
  }, [isOTPPhase]);

  useEffect(() => {
    if (User.mobile && User.mobile_code) {
      const parsedMobileNumber = User.mobile?.replace(User.mobile_code, '');
      setExistingPhoneNumber(parsedMobileNumber);
    }
  }, [User.mobile]);

  useEffect(() => {
    mixpanelHelper.trackPageView('SETTINGS - PHONE NUMBER PAGE', 'phone_number_page', window.location.pathname, document.referrer);
  }, []);

  const getMobileData = () => {
    let phNum = phoneNumber;
    let phCode = phoneNumberCode;
    if (User.mobile && User.mobile_code && (!isPhoneNumberChange || !phNum)) {
      phNum = User.mobile.replace(User.mobile_code, '');
      phCode = User?.mobile_code;
    }
    return { mobile: phNum, mobile_code: phCode };
  };

  const setOTPPhase = () => {
    setIsOTPPhase(true);
    if (User?.is_mobile_verified && !_.isNull(User?.mobile)) {
      setIsPhoneNumberChange(true);
    }
  };

  const verifyPhone = !User?.is_mobile_verified && isHome && !isOTPPhase && (
    <LazyImage img={IMAGES.VerifyPhone} alt="No fav" divStyle="" imgStyle="h-[11.25rem] object-cover" />
  );

  return (
    <>
      <CustomHelmet
        pageTitle={`${translate(PAGE_TITLE.settings as TxKeyPath) ?? ''} - ${translate(PAGE_TITLE.settings_account as TxKeyPath) ?? ''} - ${
          translate(PAGE_TITLE.settings_phone_number as TxKeyPath) ?? ''
        }`}
        metaDescription={translate(PAGE_DESCRIPTION.settings as TxKeyPath) ?? ''}
        metaKeyWords={translate(PAGE_KEYWORDS.settings as TxKeyPath) ?? ''}
      />
      {User.mobile || isPhoneNumberChange ? (
        <div
          className={`flex flex-col gap-[1.5rem] h-[80vh] SD:h-full SD:mt-0 SD:mr-[5rem] SD:w-[42.5rem] ${
            isPhoneNumberChange && !isOTPPhase ? 'justify-between SD:justify-normal' : ''
          }`}
        >
          <div className="flex flex-col gap-[1.5rem]">
            <div className="flex flex-row gap-[1rem]">
              <ResponsiveIcon
                name={IconNames.arrow}
                baseWidth={20}
                baseHeight={20}
                className="fill-sunset rotate-180 mb-[.30rem] SD:hidden"
                onClick={isHome ? () => navigate(AppRoutes.userProfile + '/settings') : () => onButtonChangeClick(false)}
              />
              {!isOTPPhase && (
                <p className="font-medium text-fs-subtitle text-secondary">
                  {isPhoneNumberChange
                    ? translate('settings.phone_number_settings.has_phone_number.new_phone_number')
                    : translate('settings.phone_number_settings.phonenumber_title')}
                </p>
              )}
            </div>
            {verifyPhone}
            <div>
              <div className="flex flex-col items-start gap-[1rem]">
                <div className="flex flex-col text-fs-body gap-[.5rem]">
                  <p
                    className={`${isPhoneNumberChange ? 'text-fs-body font-medium ' : 'font-regular'} ${
                      isOTPPhase ? 'hidden' : ''
                    } text-secondary`}
                  >
                    {isOTPPhase
                      ? translate('settings.phone_number_settings.verify_phonenumber_title')
                      : isPhoneNumberChange
                      ? translate('settings.phone_number_settings.has_phone_number.enter_new_phone_number')
                      : translate('settings.phone_number_settings.phonenumber_title')}
                  </p>
                  {isOTPPhase ? (
                    <PhoneNumberOTP
                      setIsSuccess={setIsSuccess}
                      setIsOTPSent={setIsOTPSent}
                      setIsOTPPhase={setIsOTPPhase}
                      setIsPhoneNumberChange={setIsPhoneNumberChange}
                      setMessage={setMessage}
                      setIsHome={setIsHome}
                      mobileData={getMobileData()}
                      title={
                        !User?.mobile || isPhoneNumberChange
                          ? translate('settings.phone_number_settings.no_phone_number.add_phone_number') ?? ''
                          : translate('settings.phone_number_settings.has_phone_number.verify_mobile_number') ?? ''
                      }
                    />
                  ) : isPhoneNumberChange ? (
                    <div className={`mt-[1rem] mb-[0rem] ${!isPhoneNumberChange ? 'hidden' : ''}`}>
                      <PhoneNumberField
                        onPhoneNumberChange={editForm}
                        onCountryCode={e => {
                          if (e?.country_code_iso_2) {
                            editForm('country_code_iso', { value: e.country_code_iso_2, isValid: true });
                          }
                          if (e?.phone_code_iso) {
                            editForm('phone_code_iso', { value: e.phone_code_iso, isValid: true });
                          }
                        }}
                        dropdown_style={{
                          dropdown_container_style: 'mt-[1.25rem]',
                          dropdown_style: 'w-full px-[1rem]',
                        }}
                        existingPhoneObj={{ dropDownValueCode: User.mobile_code || '', inputValue: existingPhoneNumber || '' }}
                      />
                    </div>
                  ) : (
                    User?.mobile && (
                      <h3 className="mb-[1rem] text-fs-body not-italic font-medium leading-6 text-secondary">
                        {maskPhoneNumber(User?.mobile)}
                      </h3>
                    )
                  )}
                </div>
                {User.mobile_updated_at && isHome && !isOTPPhase && (
                  <p className={`text-fs-caption font-regular ${isDarkTheme ? 'text-secondary' : 'text-black50'}`}>
                    {`${translate('settings.phone_number_settings.has_phone_number.last_change')}
                    ${format(zonedDate, language === 'ar' ? 'dd MMMM yyyy' : 'dd MMM yyyy', { locale })}
                    `}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div
            className={`flex flex-col-reverse gap-[1rem] justify-normal SD:flex-row 
              ${isPhoneNumberChange ? 'justify-start' : ''} 
              ${isOTPSent ? 'hidden' : ''}`}
          >
            <Button
              text={
                isPhoneNumberChange || isOTPPhase
                  ? translate('action.cancel')
                  : translate('settings.phone_number_settings.has_phone_number.change_phone_number')
              }
              className={`text-fs-body max-h-[2.5rem] w-full SD:w-[15rem] mt-[0.5rem] text-sunset font-medium border-[0.063rem] border-sunset rounded-sm px-[1.5rem] py-[0.5rem] ${
                !isRTL ? `after:bg-primary before:bg-sunset` : `before:bg-primary after:bg-sunset`
              } hover:text-white100`}
              onClick={() => {
                isOTPPhase ? onCancelButton() : onButtonChangeClick(!isPhoneNumberChange);
              }}
            />
            {((isPhoneNumberChange && !isOTPPhase) || (!User?.is_mobile_verified && !_.isNull(User?.mobile) && !isOTPPhase)) && (
              <Button
                text={isPhoneNumberChange && !isOTPPhase ? translate('action.continue') : translate('action.verify')}
                style="
                  text-base not-italic max-h-[2.5rem] font-medium gap-[0.5rem] mt-[0.5rem]
                  z-1 text-white border-sunset border-[0.063rem] mb-[0px] w-full SD:w-[15rem] px-[1rem] py-[0.5rem]
                  items-end rounded-[0.188rem]
                  bg-sunset
                  disabled:bg-secondary/[0.1]
                "
                disabled={isPhoneNumberChange && !isOTPPhase ? !isFormValid || !phoneNumber : false}
                disabledStyle={isDarkTheme ? 'bg-[#2E2D30] border-[#2E2D30]' : 'bg-secondary/[10%] border-secondary/[10%]'}
                normalStyle={`
                  ${isRTL ? 'before:bg-sunset after:bg-white100' : 'after:bg-sunset before:bg-white100'}
                  hover:border-sunset hover:text-sunset
                `}
                onClick={() => setOTPPhase()}
              />
            )}
          </div>
        </div>
      ) : (
        <PhoneNumberEmpty
          addPhoneNumber={onButtonChangeClick}
          iconSource={IconNames.phoneEmpty}
          headerTitle={translate('settings.phone_number_settings.phonenumber_title') ?? ''}
          headerMessage={translate('settings.phone_number_settings.no_phone_number.no_phone_number_text') ?? ''}
          message={`${translate('settings.phone_number_settings.no_phone_number.we_need_a_way')} 
                ${translate('settings.phone_number_settings.no_phone_number.we_wont_spam')}`}
        />
      )}
      <Modal open={haserror}>
        <GeneralError
          body_text={{
            error_text_header: translate('settings.error.error_header'),
            error_text: translate('settings.error.error_text'),
            haserroradvise: false,
            error_text_advise: '',
            error_text_advise_cta: {
              cta_nav: '',
              cta_text: '',
            },
          }}
          container_button_class="flex-col-reverse SD:flex-row"
          button_1={{
            button_text: translate('tournament_status_bar.leave'),
            style:
              'px-24 py-6 w-full mt-0  text-fs-body font-medium border-[1px] border-sunset text-sunset 4xl:h-[71px] 5xl:h-[95.55px] 4xl:text-[28px] 5xl:text-[38.22px] 8xl:text-[85.33px] 8xl:h-[213.33px]',
            onclick: () => navigate(AppRoutes.userProfile + '/settings'),
          }}
          button_2={{
            button_text: translate('settings.error.stay_continue'),
            style: 'px-8 py-8 w-full text-base font-regular text-white100',
            normalstyle: btnClass,
            classname: 'w-full mt-0 4xl:h-[71px] 5xl:h-[95.55px] 4xl:text-[28px] 5xl:text-[38.22px] 8xl:text-[85.33px] 8xl:h-[213.33px]',
            onclick: closeModal,
          }}
          icon={{
            iconid: IconNames.iconErrorFill,
            bh: 80,
            bw: 80,
            classname: 'hidden',
            container_classname: 'justify-end',
          }}
          close={closeModal}
        />
      </Modal>
    </>
  );
};

export default PhoneNumber;
