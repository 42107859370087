import { useEffect, useState } from 'react';

export type FormValue = { value: string; isValid: boolean };

export type EditFormFunction = (key: string, value: FormValue) => void;

export type FormData = { [index: string]: FormValue };

type InitialFormData = { [index: string]: string };

export const useForm = () => {
  const [initialForm, setInitialForm] = useState<InitialFormData>({});
  const [form, setForm] = useState<FormData>({});
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  const initializeForm = (key: string, value: string) => setInitialForm(oldForm => ({ ...oldForm, [key]: value }));

  const editForm = (key: string, value: FormValue) => setForm(oldForm => ({ ...oldForm, [key]: value }));

  useEffect(() => {
    const inputFields = Object.values(form);
    const isAllValid = inputFields.every(field => field.isValid);
    const hasChanged = Object.keys(form).some(key => form[key].value !== initialForm[key]);

    setIsFormValid(isAllValid && hasChanged && Object.keys(form).length > 0);
  }, [initialForm, form]);

  return {
    form,
    isFormValid,
    initializeForm,
    editForm,
  };
};
