
interface MapHighlightResultListProps {
    location?: string
    valueToHighlight?: string
}


const MapHighlightResultList = (props: MapHighlightResultListProps) => {
    const {location, valueToHighlight} = props;
    const escapeRegExp = (str = "") =>{
        var newStr = str.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");
        return newStr;
    }
    
    const regex = new RegExp(`(${escapeRegExp(valueToHighlight)})`, "gi");
    const parts = location?.split(regex);

    return (
        <span>
          {parts?.filter(part => part)
            .map((part, i) =>
              regex.test(part) ? (
                <span key={i} className="text-sunset text-body font-normal h-[24px]">{part}</span>
              ) : (
                <span key={i} className="text-body font-normal h-[24px]">{part}</span>
              )
            )}
        </span>
      );
}

export default MapHighlightResultList;