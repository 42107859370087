import { gql } from '@apollo/client';

export const IS_PRODUCT_AVAILABLE_QUERY = {
    isProductAvailable: gql`
        query fetchIsProductAvailable($details: ProductAvailabilityInput) {
            isProductAvailable(details: $details) {
                error_code
                error_msg
                is_successful
            }
        }
    `,
};
