import { IconNames } from '@fe-monorepo/helper';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import { StyledButtonPrimary } from './PlayerSelection.styled';
import { useTranslate } from '@fe-monorepo/hooks';

interface PlayerSelectionEmptyProps {
    onClick?: () => void;
}

const PlayerSelectionEmpty = ({ onClick }: PlayerSelectionEmptyProps) => {
    const { translate } = useTranslate();
    return (
        <div className="flex flex-col items-center gap-[2.5rem] text-secondary text-center SD:py-[7.5rem]">
            <div>
                <div className="bg-zinc-100-neutral-800 rounded-full w-[7.5rem] h-[7.5rem] grid items-center justify-center">
                    <ResponsiveIcon
                        name={IconNames.playerSelectEmpty}
                        baseWidth={64}
                        baseHeight={64}
                        className="fill-neutral-400-zinc-500"
                    />
                </div>
            </div>
            <div className="flex flex-col gap-[0.5rem]">
                <p className="font-medium text-fs-subtitle">{translate('play.matchmaking.player_select_empty.message')}</p>
                <p className="text-zinc-600-400 font-regular text-fs-body-small">
                    {translate('play.matchmaking.player_select_empty.caption')}
                </p>
            </div>

            {onClick && <StyledButtonPrimary onClick={onClick}>{translate('action_edit_preferences')}</StyledButtonPrimary>}
        </div>
    );
};

export default PlayerSelectionEmpty;
