import { IMAGES } from '@fe-monorepo/assets';
import { IMAGE_ALT_LABELS, TxKeyPath } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import ButtonFooter from '@fe-web/Atoms/Buttons/ButtonFooter';
import useHeaderState from '@fe-web/hooks/useHeaderState';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import usePageLayout from '../../hooks/usePageLayout';

interface ShopMediumBannerProps {
  bannerType?: string;
  graphQLData?: {};
  preTitle?: string;
  title?: string;
  subTitle?: string;
  image?: any;
  foregroundImage?: any;
  ctaTitle?: string;
  ctaLink?: string;
  isDisplayed?: boolean;
}

const ShopMediumBanner = (props: ShopMediumBannerProps) => {
  const isHeaderDark = useSelector((state: RootState) => state.app.headerColorChange);

  const { dir } = useHeaderState();
  const pageLayout = usePageLayout();
  const language = pageLayout?.language;
  const navigate = useNavigate();
  const { translate } = useTranslate();

  /**
   * To Do: When graphQL data is available, assign to the variable "bannerData"
   * These are the expected data from GraphQL. Atleast there should be these data results.
   */
  const bannerData = {
    bgImage: ``,
    imageIcon: ``,
    txtDescription_en: ``,
    txtTitle_en: ``,
    txtBody_en: ``,
    btnText_en: ``,
    txtDescription_ar: ``,
    txtTitle_ar: ``,
    txtBody_ar: ``,
    btnText_ar: ``,
  };

  switch (props.bannerType) {
    case 'SHOP_MED_BANNER_PREORDER':
      //Dummy data start
      bannerData.bgImage = IMAGES.StaticMediumBannerPreOrderNowBGImage.toString();
      bannerData.imageIcon = IMAGES.StaticMediumBannerPreOrderNowImage.toString();
      bannerData.txtDescription_en = `Coming soon`;
      bannerData.txtTitle_en = `Mass Effect 3 Remastered 4K`;
      bannerData.txtBody_en = `Prepare to discover the wonders of Normandy`;
      bannerData.btnText_en = `Pre-order now`;
      bannerData.txtDescription_ar = `قريباً`;
      bannerData.txtTitle_ar = `ماس إفكت 3 رمستر 4K`;
      bannerData.txtBody_ar = `قم بإحالة صديق نقطة وادفع بها لاحقا!`;
      bannerData.btnText_ar = `اطلب مسبقا الآن`;
      //Dummy data end
      break;
    case 'SHOP_MED_BANNER_REFERNOW':
      //Dummy data start
      bannerData.bgImage = ``;
      bannerData.imageIcon = IMAGES.StaticMediumBannerReferNowImage.toString();
      bannerData.txtDescription_en = ``;
      bannerData.txtTitle_en = `Refer a friend, get 100pts and pay with it later!`;
      bannerData.txtBody_en = `Prepare yourself for the new shopping experience!`;
      bannerData.btnText_en = `Refer now`;
      bannerData.txtDescription_ar = ``;
      bannerData.txtTitle_ar = `قم بإحالة صديق ، واحصل على 100 نقطة وادفع بها لاحقا!`;
      bannerData.txtBody_ar = `قم بإحالة صديق نقطة وادفع بها لاحقا!`;
      bannerData.btnText_ar = `الرجوع الآن`;
      //Dummy data end
      break;
  }

  //Navigation for CTA buttons
  const navigateToPage = () => {
    props.ctaLink && navigate(props.ctaLink);
  };

  /**
   * To Do: Awaiting specs for other screen sizes. Currently supported are w1440px & w375px
   */
  return (
    <div
      id={`MAIN_BANNER_CONTAINER`}
      className={`${[
        props.graphQLData && props.isDisplayed ? `` : `hidden `,
        ` overflow-hidden justify-center items-center w-[calc(100%-2.5rem)]
            h-[25.875rem] mx-[1.25rem] xsMax:h-[calc(100%-1.25rem)] xsMax:h-[25.625rem]
            xsMax:mx-[0px] xsMax:w-[100%]`,
      ]}`}
    >
      <div
        id={`INNER_BACKGROUND_CONTAINER`}
        className={`overflow-hidden justify-center items-center mt-[3.5625rem] w-[100%]
                    h-[22rem] xsMax:h-[19.25rem] xsMax:mt-[2.5rem]
                    `}
      >
        <div
          id={`FLOATING_IMAGE_CONTAINER`}
          className={`z-20 absolute ms-[20%] w-[75%] SD:w-auto SD:ms-[55%] mt-[0px]
                    rounded-[0.5rem]
                    `}
        >
          <LazyLoadImage
            className={`${[
              language === 'ar' && 'transform: scale-x-[-1] ',
              ` mt-[0] justify-center min-w-[17.75rem]
                            h-[19.25rem] w-[35.625rem] max-w-none SD:h-[22rem] SD:w-max
                            xsMax:flex-shrink-1 z-20 relative
                            `,
            ]}`}
            src={props.foregroundImage}
            title={'Shop Medium Banner Floating Image'}
            alt={translate(IMAGE_ALT_LABELS.shop_medium_banner_floating_image as TxKeyPath) || ''}
          />
          <div className="absolute h-[20rem] w-[50rem] bottom-0 right-0 z-10 overflow-hidden">
            <div
              className=" medium-banner-gradient 
                        rounded-full w-[50rem] h-[50rem] 
                        absolute top-[-50%] left-[3.125rem]
                        "
            />
          </div>
        </div>

        <div
          id={`PURPLE_BACKGROUND`}
          className={`overflow-hidden justify-center mt-[2.0938rem] w-[100%]
                    h-[22rem] bg-purple rounded-[0.5rem]
                    xsMax:mt-[2.5rem] xsMax:h-[16.75rem] xsMax:w-[calc(100%-2.5rem)] xsMax:ms-[1.25rem]
                    `}
        >
          <LazyLoadImage
            className={`h-[20rem] w-[calc(100%-2.5rem)] absolute z-10
                            xsMax:h-[16.75rem] xsMax:w-[calc(100%-2.5rem)]
                            rounded-[0.5rem]
                            ${[language === 'ar' && 'scale-x-[-1]', props.image === `` ? ` hidden` : ``]}`}
            src={props.image}
            title={'Shop Medium Banner Purple Background'}
            alt={translate(IMAGE_ALT_LABELS.shop_medium_banner_purple_background as TxKeyPath) || ''}
          />
          <div
            className={`h-[20rem] w-[calc(100%-2.5rem)] absolute object-cover z-10
                            xsMax:h-[16.75rem] xsMax:w-[calc(100%-2.5rem)]
                            rounded-[0.5rem] bg-black/70
                            ${[language === 'ar' && 'scale-x-[-1]', props.image === `` ? ` hidden` : ``]}`}
          ></div>

          <div
            className={`relative z-30 grid grid-cols-1 ms-[12.5rem] mt-[4rem] h-[9rem]
                        xsMax:ms-[1.5rem] xsMax:mt-[2rem] xsMax:h-[12.75rem] max-w-[36.375rem]
                        `}
          >
            <p
              className={`text-fs-body-large text-white font-regular xsMax:text-[1.5rem] xsMax:leading-[1.5rem] ${
                props.preTitle && 'pb-[1rem]'
              }`}
            >
              {' '}
              {props.preTitle}{' '}
            </p>

            <p className={`text-fs-big-title text-white font-medium xsMax:text-[2.25rem] xsMax:leading-[2.625rem]`}> {props.title} </p>

            <p
              className={`text-fs-body-large text-white font-regular xsMax:text-[2.25rem] xsMax:leading-[2.625rem] ${
                props.preTitle ? 'pt-[1rem]' : 'pt-[0.5rem]'
              }`}
            >
              {' '}
              {props.subTitle}{' '}
            </p>

            <div className="flex items-end pt-[1.5rem]">
              <ButtonFooter
                text={`${props.ctaTitle}`}
                action={navigateToPage}
                style={`text-fs-body max-h-[2.5rem]
                                    px-[1.5rem] py-[0.5rem] min-w-[8.6875rem] w-fit gap-[0.5rem] z-1
                                    text-white100 border-[0.0625rem] border-transparent mb-[0px]
                                    xsMax:mb-[0px] xsMax:w-[287px] items-end
                                    rounded-[0.175rem] font-medium
                                    ${
                                      isHeaderDark
                                        ? dir === 'ltr'
                                          ? `after:bg-sunset before:bg-white100`
                                          : `before:bg-sunset after:bg-white100`
                                        : `${dir === 'ltr' ? `after:bg-sunset before:bg-white100` : `before:bg-sunset after:bg-white100`}
                                        hover:border-[1px] hover:border-sunset`
                                    }
                                hover:text-sunsetText rounded-sm`}
                disabled={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopMediumBanner;
