import useHeaderState from '@fe-web/hooks/useHeaderState';
import { motion } from 'framer-motion';
import { useEffect } from 'react';

const Label = (props: { text: string; isFocused: boolean; valueLength: number; labelColor?: string }) => {
  const { innerWidth } = useHeaderState();

  // TODO: this needs to change. I only did this for Framer Motion
  const fontSizes = {
    normal: {
      initial: { size: '18px', height: '24px' },

      small: { size: '14px', height: '20px' },
    },

    '2k': {
      initial: { size: '32px', height: '42.66px' },

      small: { size: '25px', height: '36px' },
    },

    '4k': {
      initial: { size: '48px', height: '64px' },

      small: { size: '37.33px', height: '53px' },
    },

    '8k': {
      initial: { size: '96px', height: '128px' },

      small: { size: '75px', height: '106px' },
    },
  };

  let mode: 'normal' | '2k' | '4k' | '8k' = 'normal';

  if (innerWidth >= 7680) {
    mode = '8k';
  } else if (innerWidth >= 3840) {
    mode = '4k';
  } else if (innerWidth >= 2560) {
    mode = '2k';
  }

  return (
    <motion.p
      className={`absolute ${props.isFocused ? 'text-sunset' : props.labelColor ?? 'text-zinc-600-400'} font-regular z-0`}
      initial={{
        y: '25%',
        fontSize: fontSizes[mode].initial.size,
        lineHeight: fontSizes[mode].initial.height,
      }}
      animate={{
        y: props.isFocused || props.valueLength > 0 ? '-125%' : '25%',
        fontSize: props.isFocused || props.valueLength > 0 ? fontSizes[mode].small.size : fontSizes[mode].initial.size,
        lineHeight: props.isFocused || props.valueLength > 0 ? fontSizes[mode].small.height : fontSizes[mode].initial.height,
      }}
      transition={{ duration: 0.3 }}
    >
      {props.text}
    </motion.p>
  );
};

export default Label;
