import { useLazyQuery } from '@apollo/client';
import { RETURN_PRODUCT_QUERY } from '@fe-monorepo/data-access';
import { useEffect, useState } from 'react';
import { GetReasonDataResponse } from './type';

export function useGetReason() {
    const [isSuccessful, setStatus] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const [getReasonGql, { data: reasonDataResult }] = useLazyQuery<GetReasonDataResponse>(RETURN_PRODUCT_QUERY.omsReturnProdGetReason, {
        errorPolicy: 'all',
    });

    const getReason = async () => {
        const { data } = await getReasonGql();
        return data?.omsReturnProdGetReason;
    };

    useEffect(() => {
        if (reasonDataResult) {
            if (reasonDataResult?.omsReturnProdGetReason?.is_successful) {
                setStatus(reasonDataResult?.omsReturnProdGetReason?.is_successful);
            }
            if (reasonDataResult?.omsReturnProdGetReason?.error_msg) {
                setErrorMessage(reasonDataResult?.omsReturnProdGetReason?.error_msg);
            }
            setStatus(reasonDataResult?.omsReturnProdGetReason?.is_successful);
        }
        setIsLoading(false);
    }, [reasonDataResult]);

    return { getReason, getReasonData: reasonDataResult?.omsReturnProdGetReason, isSuccessful, isLoading, errorMessage };
}
