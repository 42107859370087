import { useTranslate } from '@fe-monorepo/hooks';
import { Link } from 'react-router-dom';

import Bottom from '../components/Layouts/Bottom';
import PostCreateStepTwo from '../components/Post/Create/StepTwo';

const PostCreateStepTwoPage = () => {
  const { translate } = useTranslate();
  return (
    <>
      <div className="full-sec">
        <div className="w-full h-full px-20 md:px-64 lg:px-120 2K:px-160 4K:px-320 8K:px-655 rounded-none bg-primary transition-all duration-300 flex items-center justify-between gap-[30px] 4xl:gap-54 5xl:gap-80 8xl:gap-160 animate__animated animate__fadeIn">
          <div className="full-box create-full-box">
            <div className="back-header">
              <h2>{translate('quickplay.text-createContent')}</h2>
              <Link to="/bits">{translate('quickplay.label-close')}</Link>
            </div>
            <PostCreateStepTwo />
          </div>
        </div>
      </div>
      <Bottom />
    </>
  );
};

export default PostCreateStepTwoPage;
