import React from "react";

function ShortsSdkVersion() {
  return (
    <div className="version">
      <p>Version v{"1.1.0"}</p>
    </div>
  );
}

export default ShortsSdkVersion;
