import { IconNames } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import { useState } from 'react';

interface SearchBarProps {
    searchQuery: string;
    setIsSearching: (value: boolean) => void;
    onSearch?: (searchQuery: string) => void;
    setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
    isRTL: boolean;
}

const SidebarChatSearch: React.FC<SearchBarProps> = ({ onSearch, searchQuery, setSearchQuery, setIsSearching, isRTL }) => {
    const { translate } = useTranslate();
    const [isSearch, setIsSearch] = useState<boolean>(false);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchItem = e.target.value;
        setSearchQuery(searchItem);
        if (searchQuery.length >= 3) {
            setIsSearching(true);
            setIsSearch(true);
        }
        if (onSearch) {
            onSearch(searchItem);
        }
    };

    const onClearSearch = () => {
        setIsSearch(false);
        setIsSearching(false);
        setSearchQuery('');
        searchQuery = '';
    };

    return (
        <div className={`flex flex-row w-full`}>
            {isSearch && (
                <ResponsiveIcon
                    className={`flex cursor-pointer relative dark:fill-white fill-sunset mix-blend-normal w-[7%] h-full items-center justify-center ${
                        isRTL ? '-rotate-90 right-[-.25rem]' : 'rotate-90 left-[-.25rem]'
                    }`}
                    name={IconNames.chevron}
                    baseWidth={30}
                    baseHeight={30}
                    onClick={onClearSearch}
                />
            )}
            <div className="flex items-center gap-[0.75rem] w-full bg-zinc-100-neutral-800 rounded-[.25rem] py-[.5rem] px-[0.75rem]">
                <ResponsiveIcon
                    className={`cursor-pointer relative fill-secondary/50 mix-blend-normal p-[.125rem]`}
                    name={IconNames.search1}
                    baseWidth={20}
                    baseHeight={20}
                />
                <input
                    onChange={handleSearch}
                    value={searchQuery}
                    className="border-none bg-transparent rounded-none focus:outline-none flex-1 w-full text-secondary mix-blend-normal font-regular text-fs-body-small"
                    placeholder={translate('chat.sidebar.search.placeholder') || undefined}
                    autoFocus={true}
                />
                {isSearch && (
                    <p className="text-fs-body-small font-regular text-sunset pl-[.5rem] hover:cursor-pointer" onClick={onClearSearch}>
                        {translate('action.clear')}
                    </p>
                )}
            </div>
        </div>
    );
};

export default SidebarChatSearch;
