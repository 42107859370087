import { gql } from "@apollo/client";

export const GAME_QUERY = {

    getAllGames: gql`
    query getAllGames {
        getAllGames {
            error_msg
            is_successful
            error_code
            data {
                last_cursor
                name_ar
                name_en
                name
                game_code
                game_image
                game_logo
                game_header
                tournament_available
            }
        }
    }
    `,

    getPlatform: gql`
    query getPlatform($details: PlatformInput) {
        getPlatform(details: $details) {
            error_msg
            is_successful
            error_code
            data {
                platform_code
                name
                name_ar
                name_en
            }
        }
    }
    `,
};