import { IMAGES } from '@fe-monorepo/assets';
import { Logger, formatDecimalAsPercentage } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import { OrderQueryResponse, ShopWishlistInput, WishlistData, WishlistMutationResponse } from '@fe-monorepo/models';
import { RootState } from '@fe-monorepo/store';
import WishList from '@fe-web/Atoms/Favorite/WishListFavorite';
import { useMemo, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector } from 'react-redux';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import BadgeTag from '../../../../../../../components/Atoms/ProductTag/BadgeTag';
import { StyledCarousel, StyledProductImagesContainer, StyledProductImagesList, StyledProductImagesListItem } from './ProductImages.styled';

export type ProductImagesProps = {
  isAvailable: boolean;
  name: string;
  media: string[];
  tag: string;
  sellingPrice: number;
  price: number;
  product_code: string;
  checkIsSuccess?: (errorMessage: string) => void;
  quantityErrorMessage?: string;
  wishlistData: WishlistData[] | undefined;
  addToWishList: (shopWishlistInput: ShopWishlistInput) => Promise<WishlistMutationResponse | null | undefined>;
  removeToWishList: (shopWishlistInput: ShopWishlistInput) => Promise<WishlistMutationResponse | null | undefined>;
  getAllWishlist: (getAllWishlistInput: { last_cursor: string; direction: string }) => Promise<OrderQueryResponse | undefined>;
  brand: string;
};

function ProductImages({
  isAvailable,
  name,
  media,
  tag,
  sellingPrice,
  price,
  product_code,
  checkIsSuccess,
  quantityErrorMessage,
  addToWishList,
  removeToWishList,
  getAllWishlist,
  wishlistData,
  brand,
}: ProductImagesProps) {
  const [selectedItem, setSelectedItem] = useState(0);
  const withDiscount = useMemo(() => sellingPrice < price, [sellingPrice, price]);
  const { isRTL } = useSelector((state: RootState) => state.app);
  const { translate } = useTranslate();
  const { log } = Logger();

  const discountPercentage = formatDecimalAsPercentage(sellingPrice, price).toString();
  const discountPercentageRtl = isRTL ? discountPercentage + '-' : '-' + discountPercentage;

  if (!media?.length) {
    media = [IMAGES.Product_Placeholder.toString()];
  }

  const isWishList = wishlistData ? (wishlistData.find(item => item.product_code === product_code) ? true : false) : false;

  return (
    <StyledProductImagesContainer className="relative">
      <StyledProductImagesList>
        {media?.map((source, index) => (
          <StyledProductImagesListItem key={index}>
            <a
              className={`inline-block border-b-2 hover:border-sunset transition transition-all duration-500 ${
                selectedItem === index ? 'border-sunset' : 'border-transparent'
              }`}
              href="#"
              onClick={e => {
                e.preventDefault();
                setSelectedItem(() => index);
              }}
            >
              <LazyLoadImage src={source} alt={(name + index).replace(/\s/g, '_')} className="object-contain aspect-square" />
            </a>
          </StyledProductImagesListItem>
        ))}
      </StyledProductImagesList>

      {product_code && (
        <WishList
          isWishlist={isWishList}
          product_code={product_code}
          className={`${!isRTL ? 'right-[5%] XGA:right-[3%]' : 'left-[5%] XGA:left-[3%]'} XGA:top-[3%] top-[5%]`}
          checkIsSuccess={checkIsSuccess}
          addToWishList={addToWishList}
          removeToWishList={removeToWishList}
          getAllWishlist={getAllWishlist}
          productName={name}
          brand={brand}
        />
      )}
      <StyledCarousel
        ref={ref => log('ref', ref)}
        showArrows={false}
        showStatus={false}
        showIndicators={media?.length > 1}
        selectedItem={selectedItem}
        className="relative z-[0] col-span-5"
        onChange={index => {
          setSelectedItem(() => index);
        }}
      >
        {media?.map((source, index) => (
          <LazyLoadImage
            src={source}
            alt={(name + index).replace(/\s/g, '_')}
            className="z-[0] w-full aspect-[243/350] object-contain bg-primary px-40 py-64"
          />
        ))}
      </StyledCarousel>

      {(withDiscount || (!isAvailable && quantityErrorMessage === '')) && (
        <BadgeTag
          className={`
                    z-[1] absolute

                    top-0
                    SD:ltr:left-[17.3%] SD:rtl:right-[17.3%]

                    ms-[0.516rem] SD:ms-[1.5rem] 
                    mt-[0.5625rem] SD:mt-[1.5rem] 
                `}
          text={!isAvailable && quantityErrorMessage === '' ? translate('shop.productPage.outOfStock.badge') : discountPercentageRtl}
        />
      )}
    </StyledProductImagesContainer>
  );
}

export default ProductImages;
