import { TxKeyPath, Validator } from '../../../helper/src';

type ValidationFunction = (value: string) => boolean;

export type ValidationRule = {
  checkValidity: ValidationFunction;
  errorMessage: TxKeyPath;
};

// TODO: this type may need to be refactored at some point
export type InputFieldDefinition = {
  key: string;

  type: 'password' | 'text' | 'number' | 'datetime-local' | 'date';

  range?: { min?: number; max: number };

  label: TxKeyPath;
  placeholder?: string;

  isSecret: boolean;
  isOptional: boolean;

  validationCriteria: ValidationRule[];
  isAllowedToTypeCriteria: ValidationFunction[];
};

export type OptionsFieldDefinition = {
  key: string;
  type: 'password' | 'text' | 'number' | 'datetime-local' | 'date';
  range?: { min?: number; max: number };
  label: TxKeyPath;
  placeholder?: string;
  isSecret: boolean;
  isOptional: boolean;
  validationCriteria: ValidationRule[];
  isAllowedToTypeCriteria: ValidationFunction[];
};

export const emailFieldSignup: InputFieldDefinition = {
  key: 'email',
  type: 'text',
  label: 'formAttribute_email',
  isSecret: false,
  isOptional: false,
  validationCriteria: [
    {
      checkValidity: Validator.isNotEmpty,
      errorMessage: 'validation_emailEmptyField',
    },
    {
      checkValidity: Validator.isValidEmail,
      errorMessage: 'validation_emailMustMatch',
    },
  ],
  isAllowedToTypeCriteria: [Validator.isValidEmailLength, (value: string) => !value.includes(' ')],
};

export const editEmailField: InputFieldDefinition = {
  key: 'editEmail',
  type: 'text',
  label: 'formAttribute_email',
  isSecret: false,
  isOptional: false,
  validationCriteria: [
    {
      checkValidity: Validator.isNotEmpty,
      errorMessage: 'validation_emailEmptyField',
    },
    {
      checkValidity: Validator.isValidEmail,
      errorMessage: 'validation_emailMustMatch',
    },
  ],
  isAllowedToTypeCriteria: [Validator.isValidEmailLength, (value: string) => !value.includes(' ')],
};

export const usernameFieldSignup: InputFieldDefinition = {
  key: 'username',
  label: 'formAttribute_username',
  type: 'text',
  isSecret: false,
  isOptional: false,
  validationCriteria: [
    {
      checkValidity: Validator.isNotEmpty,
      errorMessage: 'validation_usernameEmpty',
    },
    {
      checkValidity: Validator.isValidUsernameLength,
      errorMessage: 'validation_invalidUsernameLength',
    },
    {
      checkValidity: Validator.isValidUsername,
      errorMessage: 'validation_invalid_username',
    },
  ],
  isAllowedToTypeCriteria: [Validator.isValidUsernameMaxLength, (value: string) => !value.includes(' ')],
};

export const passwordFieldSignup: InputFieldDefinition = {
  key: 'password',
  label: 'formAttribute_password',
  type: 'text',
  isSecret: true,
  isOptional: false,

  validationCriteria: [
    {
      checkValidity: Validator.isNotEmpty,
      errorMessage: 'validation_passwordEmpty',
    },
    {
      checkValidity: Validator.isValidPasswordMinLength,
      errorMessage: 'validation_invalid_password',
    },
    {
      checkValidity: Validator.isValidPassword,
      errorMessage: 'validation_invalid_password',
    },
  ],
  isAllowedToTypeCriteria: [],
};

export const newPasswordFieldConfirm: InputFieldDefinition = {
  key: 'newPassword',
  label: 'formAttribute_confirmNewPassword',
  type: 'text',
  isSecret: true,
  isOptional: false,
  validationCriteria: [
    {
      checkValidity: Validator.isNotEmpty,
      errorMessage: 'validation_passwordEmpty',
    },
  ],
  isAllowedToTypeCriteria: [],
};

export const referralCodeField: InputFieldDefinition = {
  key: 'referralCode',
  type: 'text',
  label: 'common_referral_code_optional',
  isSecret: false,
  isOptional: true,
  validationCriteria: [],
  isAllowedToTypeCriteria: [],
};

export const emailFieldLogin: InputFieldDefinition = {
  key: 'email',
  type: 'text',
  label: 'formAttribute_loginIdentifier',
  isSecret: false,
  isOptional: false,
  validationCriteria: [
    {
      checkValidity: Validator.isNotEmpty,
      errorMessage: 'validation_emailEmptyField',
    },
  ],
  isAllowedToTypeCriteria: [Validator.isValidEmailLength, (value: string) => !value.includes(' ')],
};

export const passwordFieldLogin: InputFieldDefinition = {
  key: 'password',
  label: 'formAttribute_password',
  type: 'text',
  isSecret: true,
  isOptional: false,
  validationCriteria: [
    {
      checkValidity: Validator.isNotEmpty,
      errorMessage: 'validation_passwordEmpty',
    },
    {
      checkValidity: Validator.isValidPasswordMinLength,
      errorMessage: 'validation_invalid_password',
    },
  ],
  isAllowedToTypeCriteria: [],
};

export const oldPasswordFieldLogin: InputFieldDefinition = {
  key: 'password',
  label: 'account_password.old_password',
  type: 'text',
  isSecret: true,
  isOptional: false,
  validationCriteria: [
    {
      checkValidity: Validator.isNotEmpty,
      errorMessage: 'validation_passwordEmpty',
    },
    {
      checkValidity: Validator.isValidPasswordMinLength,
      errorMessage: 'validation_invalid_password',
    },
  ],
  isAllowedToTypeCriteria: [],
};

export const displayNameField: InputFieldDefinition = {
  key: 'displayName',
  label: 'profile.display_name',
  type: 'text',
  range: { min: 1, max: 64 },
  isSecret: false,
  isOptional: false,
  validationCriteria: [
    {
      checkValidity: Validator.isNotEmpty,
      errorMessage: 'settings.error.mandatory_field',
    },
  ],
  isAllowedToTypeCriteria: [Validator.isValidMaxDisplayLength],
};

export const bioField: InputFieldDefinition = {
  key: 'bio',
  label: 'profile.bio',
  type: 'text',
  range: { max: 124 },
  isSecret: false,
  isOptional: true,
  validationCriteria: [],
  isAllowedToTypeCriteria: [Validator.isValidMaxBioLength],
};

export const dobField: InputFieldDefinition = {
  key: 'dob',
  label: 'profile.date_of_birth',
  type: 'date',
  isSecret: false,
  isOptional: false,
  validationCriteria: [
    {
      checkValidity: (value: string) => value !== 'Invalid date',

      errorMessage: 'settings.error.mandatory_field',
    },
  ],
  isAllowedToTypeCriteria: [],
};

export type RadioOption<T> = { id: string; value: T };

export type OptionsInputFieldDefinition<T> = {
  key: string;
  label: TxKeyPath;
  defaultOptionID: string;
  options: RadioOption<T>[];
};

export const genderField: OptionsInputFieldDefinition<string> = {
  key: 'gender_code',
  label: 'common_gender',
  defaultOptionID: '',
  options: [
    { id: 'male', value: 'profile.gender.male' },

    { id: 'female', value: 'profile.gender.female' },

    { id: 'none', value: 'profile.gender.none' },
  ],
};

export const currencyField: OptionsInputFieldDefinition<string> = {
  key: 'currency_code',
  label: 'currency',
  defaultOptionID: '',
  options: [],
};

export const countryField: OptionsInputFieldDefinition<string> = {
  key: 'country_code',
  label: 'country',
  defaultOptionID: '',
  options: [],
};

export const timezoneField: OptionsInputFieldDefinition<{ timezone: string; place: string }> = {
  key: 'timezone_code',
  label: 'formAttribute_timezone',
  defaultOptionID: '',
  options: [],
};

export const languageField: OptionsInputFieldDefinition<string> = {
  key: 'language_code',
  label: 'language',
  defaultOptionID: 'en',
  options: [
    { id: 'en', value: 'English' },
    { id: 'ar', value: 'تصفح بالعربية' },
  ],
};

export const notificationFilterField: OptionsInputFieldDefinition<string> = {
  key: 'period_code',
  label: 'common_period',
  defaultOptionID: '',
  options: [
    { id: 'new', value: 'tabTitle_new' },
    { id: 'last7days', value: 'last_seven_days' },
    { id: 'last30days', value: 'last_thirty_days' },
    { id: 'older', value: 'older' },
  ],
};
