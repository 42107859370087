import { useSearchHistory } from '@fe-monorepo/hooks';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from '../../../../app.routes.enum';
import useSearchInput from '../../../../hooks/useSearchInput';
import Drawer from '../../../Atoms/Drawer';
import RecentSearchResult from './RecentSearchResult';

interface RecentSearchMobileProps {
  open: boolean;
}
const RecentSearchMobile: React.FC<RecentSearchMobileProps> = ({ open }) => {
  const { setValue } = useSearchInput();
  const { t } = useTranslation();
  const { searchHistoryData, clearAllHistory, getSearchHistory } = useSearchHistory();
  const navigate = useNavigate();
  useEffect(() => {
    getSearchHistory();
  }, []);

  useEffect(() => {
    if (open) {
      mixpanelHelper.trackPageView('GLOBAL SEARCH PAGE', 'global_search_page', window.location.pathname, document.referrer);
    }
  }, [open]);

  return (
    <Drawer className="mt-[30px]" open={open}>
      <section
        className="
                      h-[calc(100vh-35px)]
                      overflow-y-auto
                      px-[20px]
                      pt-[5px]
                      flex flex-col
                      w-full
                      gap-[12px]"
      >
        <div className="flex items-center justify-between w-full">
          <div className="text-lg font-medium text-secondary">{t('recent')}</div>
          {!!searchHistoryData?.length && (
            <div className="text-sm font-normal cursor-pointer text-btn-primary mix-blend-normal" onClick={() => clearAllHistory()}>
              {t('common_clearAll')}
            </div>
          )}
        </div>

        <RecentSearchResult
          setSearchInput={value => {
            setValue(value);
            navigate(AppRoutes.search + `?q=${value}`);
          }}
          getSearchHistory={getSearchHistory}
          searchHistoryData={searchHistoryData}
        />
      </section>
    </Drawer>
  );
};
export default RecentSearchMobile;
