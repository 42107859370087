import { gql } from '@apollo/client';

export const CANCEL_MUTATION = {
    cancelTournament: gql`
        mutation cancelTournament($details: CancelInput) {
            cancelTournament(details: $details) {
                is_successful
                error_msg
                error_code
            }
        }
    `,
};
