import { IconNames, MixPanelEvents } from '@fe-monorepo/helper';
import { useEwcProvider, useUserProfile } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import Button from '@fe-web/Atoms/Buttons/Button';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import useMobileDetect from '@fe-web/hooks/useMobileDetect';
import { getURLForRedirection, safelyParseJSONString } from 'apps/fe-web/src/lib/helper';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

interface TopBannerProps {
  CTASource: string;
}

const TopBanner = (props: TopBannerProps) => {
  const { CTASource } = props;

  const { isLoggedIn, user } = useUserProfile();
  const isMobile = useMobileDetect();
  const {
    getSkinnyBanner,
    skinnyBanners,
    predict: { setShowGuestModal },
  } = useEwcProvider();
  const isRTL = useSelector((state: RootState) => state.app.isRTL);

  const navigate = useNavigate();

  useEffect(() => {
    getSkinnyBanner();
  }, []);

  if (!skinnyBanners) {
    return null;
  }

  const handleClickCTA = (type: string, value: string, name: string) => {
    const { url, options } = getURLForRedirection(type, value, isLoggedIn, user, '', name);

    mixpanelHelper.trackEvent(MixPanelEvents.bannersButtonCTA, {
      'CTA Name': `Sticky Banner - ${skinnyBanners.name_en}`,
      Referrer: window.location.href,
      'CTA Source': CTASource,
      Username: isLoggedIn ? user?.username : 'Guest',
    });

    if (type === 'custom') {
      const parsedVal = safelyParseJSONString(value);
      if ((parsedVal?.custom_type === 'streaming' || parsedVal?.page_name === 'my-profile') && !isLoggedIn) {
        setShowGuestModal(true);
        return;
      }
    }
    if (url) {
      navigate(url, options);
    }
  };

  return (
    <div
      dir={isRTL ? 'rtl' : 'ltr'}
      className={`
        ${skinnyBanners?.reference_type && 'cursor-pointer'} h-[4rem] flex
        items-center justify-between
        bg-purple
        ${isMobile ? '' : 'flex'}
        gap-[0.5rem] ${isMobile ? 'px-[1rem]' : 'px-[7.5rem]'}
      `}
      onClick={() =>
        skinnyBanners?.reference_type &&
        handleClickCTA(skinnyBanners?.reference_type ?? '', skinnyBanners?.reference_value ?? '', skinnyBanners?.reference_id ?? '')
      }
    >
      <div className={isMobile ? '' : 'flex items-center justify-between flex-1'}>
        <span
          className={`text-white100 ${
            isMobile ? 'text-bodySmall' : 'text-bodyLarge 2K:text-[1.2rem] leading-[1.6rem]'
          } line-clamp-2 inline`}
        >
          {isRTL ? skinnyBanners.content_ar : skinnyBanners.content_en}
        </span>
        {isMobile ? (
          <span className="text-sunset cursor-pointer ml-[0.5rem] text-bodySmall underline">
            {isRTL ? skinnyBanners.cta_ar : skinnyBanners.cta_en}
          </span>
        ) : (
          <Button
            text={
              <div className="flex" dir={isRTL ? 'rtl' : 'ltr'}>
                <span>{isRTL ? skinnyBanners.cta_ar : skinnyBanners.cta_en}</span>
                <ResponsiveIcon
                  iconClasses="fill-current"
                  className={isRTL ? 'rotate-90' : 'rotate-[270deg]'}
                  name={IconNames.chevron}
                  baseHeight={24}
                  baseWidth={24}
                />
              </div>
            }
            style="
              text-base not-italic max-h-[2.5rem] font-medium gap-[0.5rem]
              z-1 text-white
              border-sunset border-[0.063rem] 
              mb-[0px] px-[1rem] py-[0.5rem] 
              items-end rounded-[0.188rem]
              bg-sunset
              disabled:bg-secondary/[0.1]
            "
            normalStyle={`
              ${!isRTL ? `after:bg-sunset before:bg-white100` : `before:bg-sunset after:bg-white100`}
              cursor-pointer
              hover:border-[0.0625rem]
              hover:border-sunset hover:text-sunsetText
              hover:text-sunsetText
            `}
          />
        )}
      </div>
    </div>
  );
};

export default TopBanner;
