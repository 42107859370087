import { useTranslate } from '@fe-monorepo/hooks';

import Bottom from '../components/Layouts/Bottom';
import PostCreateStepThree from '../components/Post/Create/StepThree';

const PostCreateStepThreePage = () => {
  const { translate } = useTranslate();
  return (
    <>
      <div className="full-sec">
        <div className="px-20 md:px-64 lg:px-120 2K:px-160 4K:px-320 8K:px-655">
          <div className="back-header">
            <h2>{translate('quickplay.text-createContent')}</h2>
          </div>
          <PostCreateStepThree />
        </div>
      </div>
      <Bottom />
    </>
  );
};

export default PostCreateStepThreePage;
