import { gql } from '@apollo/client';

export const CONVERSATION_MUTATION = {
    commGetToken: gql`
        mutation commGetToken {
            commGetToken {
                error_msg
                is_successful
                error_code
                data {
                    token
                }
            }
        }
    `,
};
