import useGetCurrentBreakPoint, { BreakPoints } from '@fe-web/hooks/useGetCurrentBreakPoint/useGetCurrentBreakPoint';
import { motion } from 'framer-motion';

interface Props {
  layout: 'grid' | 'list';
  src?: string;
  shallZoomImage: boolean;
  animationDuration: number;
  twoColMobile?: boolean;
  imageClassName?: string;
}

const ProductImage = (props: Props) => {
  const { currentBreakPoint } = useGetCurrentBreakPoint();
  const { layout, src, shallZoomImage, animationDuration } = props;

  const isMobile = currentBreakPoint === BreakPoints.mobile && layout === 'list';

  const zoomValue = 1.1;
  const scaleValue = shallZoomImage ? zoomValue : 1;

  return (
    <motion.img
      layout="preserve-aspect"
      className={`
        absolute
        object-contain
        rounded-[0.4375rem]
        gap-x-[3.875rem] gap-y-[3.8125rem]

        ${
          layout === 'grid'
            ? `w-full h-full 
          ${props.twoColMobile ? 'px-[1.25rem] py-[2rem] SD:px-40 SD:py-64' : 'px-40 py-64'}  ${props?.imageClassName ?? ''}`
            : 'w-[72%]'
        }
      `}
      src={src}
      animate={{ scale: scaleValue }}
      transition={{
        duration: 0.45,
        layout: { duration: animationDuration },
      }}
    />
  );
};

export default ProductImage;
