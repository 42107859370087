import { IconNames } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import HoverText from '@fe-web/Atoms/Text/HoverText';
import Footer from '@fe-web/Organisms/ShopFooter/Footer';
import { Link } from 'react-router-dom';

import ErrorPageLayout from './ErrorPageLayout';

export function InternalServerErrorPage() {
  const { translate } = useTranslate();
  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <ErrorPageLayout list={[{ label: translate('home') as string }, { label: '500' }]} footer={<Footer />}>
      <div className="flex-1 flex flex-col gap-[5.5rem] items-center text-center justify-center">
        <ResponsiveIcon
          name={IconNames.internalServerErrorLight}
          baseWidth={267.2}
          baseHeight={106}
          className="dark:hidden"
          iconClasses="max-w-screen max-h-screen w-[16.7rem] h-[6.625rem]"
        />
        <ResponsiveIcon
          name={IconNames.internalServerErrorDark}
          baseWidth={267.2}
          baseHeight={106}
          className=" hidden dark:block"
          iconClasses="max-w-screen max-h-screen w-[16.7rem] h-[6.625rem]"
        />
        <div className="flex flex-col gap-[2rem] md:gap-[2.5rem]">
          <div className="flex flex-col gap-[0.5rem] md:gap-[1rem] items-center">
            <p className="font-medium text-fs-title text-black-white max-w-[26rem]">{translate('error_internal_server')}</p>
            <p className="text-zinc-600-400 max-w-[15rem] font-regular text-fs-body-small">
              {translate('error_internal_server_description')}{' '}
              <Link to="/contact-us" className="text-sunset underline">
                {translate('common_contactUs')}
              </Link>
            </p>
          </div>
          <div className="flex flex-col gap-[1rem] md:gap-[2.5rem]">
            <HoverText
              className={`font-regular text-sunset text-fs-body whitespace-nowrap flex m-auto`}
              hover={{ color: 'text-sunset' }}
              alwaysShowUnderline={false}
              underlineStyle="bg-sunset h-[0.0625rem] bottom-2"
              text={translate('action_refresh') || 'Refresh'}
              onClick={refreshPage}
            />
          </div>
        </div>
      </div>
    </ErrorPageLayout>
  );
}

export default InternalServerErrorPage;
