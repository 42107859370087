import { ReactNode } from 'react';

interface Props {
    children: ReactNode;
}

const SectionTitle = ({ children }: Props) => (
    <div className={`font-medium text-fs-subtitle SD:text-fs-big-title text-secondary`}>{children}</div>
);

export default SectionTitle;
