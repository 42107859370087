import { MixPanelEvents, PAGE_DESCRIPTION, PAGE_KEYWORDS, PAGE_TITLE, TxKeyPath } from '@fe-monorepo/helper';
import { useMiniGames, useTranslate } from '@fe-monorepo/hooks';
import { TmsGetMiniGameInfoDetails } from '@fe-monorepo/models';
import { RootState } from '@fe-monorepo/store';
import BreadCrumbs from '@fe-web/Atoms/BreadCrumb';
import Spinner from '@fe-web/Atoms/Load/Spinner';
import CustomHelmet from '@fe-web/Organisms/CustomHelmet/CustomHelmet';
import Container from '@fe-web/Templates/Container';
import { MiniGameTracker, createMinigameEventsTracker } from 'apps/fe-web/helper/webAnalytics';
import mixpanelHelper from 'apps/fe-web/src/app/helpers/mixpanelHelper';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { AppRoutes } from '../../../../app.routes.enum';
import AllMiniGames from '../components/AllMiniGames';
import MiniGameIFrameModal from '../components/MiniGameIFrameModal';
import MiniGamesBanner from './components/MiniGamesBanner';
import MiniGamesDetailsDescription from './components/MiniGamesDetailsDescription';
import MiniGamesLeaderBoard from './components/MiniGamesLeaderBoards';

const MiniGamesDetailsContainer = () => {
  const { getAllMiniGames, getMiniGameInfo, miniGamesData, miniGameData, isLoading } = useMiniGames();
  const selectedLanguage = useSelector((state: RootState) => state.app.language);
  const { translate } = useTranslate();
  const { miniGameID } = useParams();
  const [miniGameDetails, setMiniGameDetails] = useState<TmsGetMiniGameInfoDetails>();
  const [isGameOpen, setIsGameOpen] = useState<boolean>(false);
  const [crumbTitle, setCrumbTitle] = useState<string>('');
  const navigate = useNavigate();

  const miniGameTracker = useRef<MiniGameTracker>();

  const Home = {
    pageName: translate('pageTitle_Home'),
    route: AppRoutes.home,
  };
  const Play = {
    pageName: translate('tabTitle_play'),
    route: '/play/games',
  };
  const Games = {
    pageName: translate('pageTitle_games'),
    route: '/play/games',
  };
  const CurrentGame = {
    pageName: crumbTitle,
    route: `/play/games/${miniGameID}`,
  };

  useEffect(() => {
    return () => {
      miniGameTracker.current?.end();
    };
  }, []);
  useEffect(() => {
    getAllMiniGames();
    if (miniGameID) {
      getMiniGameInfo({ mini_game_code: miniGameID?.toString() });
    }
  }, [miniGameID]);

  useEffect(() => {
    setMiniGameDetails(miniGameData?.data);
    setCrumbTitle(selectedLanguage === 'en' ? miniGameData?.data?.name_en || '' : miniGameData?.data?.name_ar || '');

    if (miniGameData) {
      const { data } = miniGameData;

      miniGameTracker.current = createMinigameEventsTracker(data.mini_game_code, data.name_en);

      miniGameTracker.current.select();
    }
  }, [miniGameData]);

  useEffect(() => {
    setMenuTrail([Home, Play, Games, CurrentGame]);
  }, [crumbTitle]);

  const [menuTrail, setMenuTrail] = useState([Home, Play, Games]);

  useEffect(() => {
    if (isGameOpen && miniGameID && miniGameData) {
      miniGameTracker.current?.start();
    }
  }, [isGameOpen, miniGameID, miniGameData]);

  useEffect(() => {
    mixpanelHelper.trackPageView('PLAY - GAME - GAME DETAILS PAGE', 'game_details_page', window.location.pathname, document.referrer);
  }, []);

  useEffect(() => {
    if (miniGameData) {
      const { data } = miniGameData;
      mixpanelHelper.trackEvent(MixPanelEvents.gameViewed, {
        'Game Name': data?.name_en,
      });
    }
  }, [miniGameData]);

  const handleClickPlay = () => {
    setIsGameOpen(true);
    mixpanelHelper.trackEvent(MixPanelEvents.gamePlayStarted, {
      'Game Name': miniGameData?.data?.name_en,
    });
  };

  const handleClosePlay = () => {
    setIsGameOpen(false);
    mixpanelHelper.trackEvent(MixPanelEvents.gamePlayClosed, {
      'Game Name': miniGameData?.data?.name_en,
    });
  };

  return (
    <>
      <CustomHelmet
        pageTitle={`${translate(PAGE_TITLE.games as TxKeyPath) ?? ''} ${
          miniGameDetails && miniGameDetails?.name_en.length > 0
            ? selectedLanguage === 'en'
              ? `- ${miniGameDetails?.name_en}` ?? ''
              : `- ${miniGameDetails?.name_ar}` ?? ''
            : ''
        }`}
        metaDescription={translate(PAGE_DESCRIPTION.games as TxKeyPath) ?? ''}
        metaKeyWords={translate(PAGE_KEYWORDS.games as TxKeyPath) ?? ''}
      />
      <div>
        <BreadCrumbs
          className="z-10 text-caption sticky top-[4.375rem] bg-primary text-sunset"
          list={menuTrail}
          getString={(link: any) => link.pageName}
          onClick={link => navigate(link.route)}
        />
        <Container className="my-[2rem]">
          <MiniGameIFrameModal gameID={miniGameID ?? ''} isOpen={isGameOpen} handleClose={handleClosePlay} />
          <div className="w-full inline-flex flex-col gap-[5.9375rem]">
            {isLoading ? (
              <span>
                <Spinner className="text-secondary" />
              </span>
            ) : (
              <div className={`inline-flex flex-col gap-[2.5rem]`}>
                <MiniGamesBanner
                  handleClickPlay={handleClickPlay}
                  sectionTitle={selectedLanguage === 'en' ? miniGameDetails?.name_en ?? '' : miniGameDetails?.name_ar ?? ''}
                  bannerImage={miniGameDetails?.img}
                />
                <MiniGamesDetailsDescription
                  content={selectedLanguage === 'en' ? miniGameDetails?.description_en ?? '' : miniGameDetails?.description_ar ?? ''}
                />
              </div>
            )}
            <MiniGamesLeaderBoard isGameOpen={isGameOpen} />

            <AllMiniGames
              handleLoadMore={(last_cursor: number) => getAllMiniGames({ last_cursor: last_cursor, direction: 'next' })}
              isLoading={isLoading}
              showCount={false}
              title={translate('mini_game.similar_games') ?? ''}
              miniGameList={miniGamesData}
              isSimilarGames
            />
          </div>
        </Container>
      </div>
    </>
  );
};

export default MiniGamesDetailsContainer;
