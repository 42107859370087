import { useMutation } from '@apollo/client';
import { RETURN_PRODUCT_MUTATION } from '@fe-monorepo/data-access';
import { useEffect, useState } from 'react';
import { AddDataParamsModel, AddDataResponse, UpdateDataParamsModel, UpdateDataResponse } from './type';

export function useReturnProduct() {
    const [isSuccessfulAdd, setStatusAdd] = useState<boolean>(false);
    const [isLoadingAdd, setIsLoadingAdd] = useState<boolean>(false);
    const [errorAdd, setErrorAdd] = useState<string>('');
    const [errorMessageAdd, setErrorMessageAdd] = useState<string>('');
    const [isSuccessfulUpdate, setStatusUpdate] = useState<boolean>(false);
    const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
    const [errorUpdate, setErrorUpdate] = useState<string>('');
    const [errorMessageUpdate, setErrorMessageUpdate] = useState<string>('');

    const [omsReturnProdAdd, { data: addData, loading: addLoading, error: addError }] = useMutation<AddDataResponse>(
        RETURN_PRODUCT_MUTATION.omsReturnProdAdd,
        {
            errorPolicy: 'all',
        },
    );
    const [omsReturnProdUpdate, { data: updateData, loading: updateLoading, error: updateError }] = useMutation<UpdateDataResponse>(
        RETURN_PRODUCT_MUTATION.omsReturnProdUpdate,
        {
            errorPolicy: 'all',
        },
    );

    useEffect(() => {
        if (addData) {
            if (addData.omsReturnProdAdd.is_successful) {
                setStatusAdd(addData.omsReturnProdAdd.is_successful);
            }
            if (addData.omsReturnProdAdd.error_msg) {
                setErrorMessageAdd(addData.omsReturnProdAdd.error_msg);
            }
            setStatusAdd(addData.omsReturnProdAdd.is_successful);
        }
        setIsLoadingAdd(false);
    }, [addData]);

    useEffect(() => {
        if (addError) {
            setErrorAdd(addError.message);
        }
        setIsLoadingAdd(false);
    }, [addError]);

    const createAddData = (params: AddDataParamsModel) => {
        omsReturnProdAdd({ variables: { details: params } });
        setIsLoadingAdd(true);
    };
    const createAddDataWithResult = async (params: AddDataParamsModel) => {
        setIsLoadingAdd(true);
        const result = await omsReturnProdAdd({ variables: { details: params } });
        setIsLoadingAdd(false);
        return result;
    };

    useEffect(() => {
        if (updateData) {
            if (updateData.omsReturnProdUpdate.is_successful) {
                setStatusUpdate(updateData.omsReturnProdUpdate.is_successful);
            }
            if (updateData.omsReturnProdUpdate.error_msg) {
                setErrorMessageUpdate(updateData.omsReturnProdUpdate.error_msg);
            }
            setStatusUpdate(updateData.omsReturnProdUpdate.is_successful);
        }
        setIsLoadingUpdate(false);
    }, [updateData]);

    useEffect(() => {
        if (updateError) {
            setErrorUpdate(updateError.message);
        }
        setIsLoadingUpdate(false);
    }, [updateError]);

    const createUpdateData = (params: UpdateDataParamsModel) => {
        omsReturnProdUpdate({ variables: { details: params } });
        setIsLoadingUpdate(true);
    };

    const createUpdateDataWithResult = async (params: UpdateDataParamsModel) => {
        setIsLoadingUpdate(true);
        const result = await omsReturnProdUpdate({ variables: { details: params } });
        setIsLoadingUpdate(false);
        return result;
    };

    return {
        createAddData,
        addData: addData?.omsReturnProdAdd?.data,
        isSuccessfulAdd,
        isLoadingAdd,
        errorAdd,
        errorMessageAdd,
        addLoading,
        createUpdateData,
        createAddDataWithResult,
        createUpdateDataWithResult,
        isSuccessfulUpdate,
        isLoadingUpdate,
        errorUpdate,
        errorMessageUpdate,
        updateLoading,
    };
}
