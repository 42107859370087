import { useEffect, useState } from "react"

const useClickOutSide = (ref:any,secondRef?:any)=>
{
    const [ isOutSide, setIsOutSide ] = useState<boolean>(false);

    const handleClickOutSide = (e:any)=>
    {   
        if(secondRef)
        {
            if(!ref?.current?.contains(e.target) && !secondRef?.current?.contains(e?.target))
            {
                setIsOutSide(true)
            }
            else
            {
                setIsOutSide(false)
            }
        }
        else
        {
            if(!ref?.current?.contains(e.target))
            {
                setIsOutSide(true)
            }
            else
            {
                setIsOutSide(false)
            }
        }
    }

    useEffect(()=>
    {
        document?.addEventListener("click", handleClickOutSide,true);
    },[])

    return { isOutSide, setIsOutSide }
}

export default useClickOutSide