import { IMAGES } from '@fe-monorepo/assets';
import { IconNames, formatDecimalAsPercentage } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import { OrderQueryResponse, ShopProductsModel, ShopWishlistInput, WishlistMutationResponse } from '@fe-monorepo/models';
import { RootState } from '@fe-monorepo/store';
import WishList from '@fe-web/Atoms/Favorite/WishListFavorite';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from '../../../app.routes.enum';
import ResponsiveIcon from '../../Atoms/Icon/ResponsiveIcon';
import BrandName from './ProductListingsComponents/BrandName';
import GradientContainer from './ProductListingsComponents/GradientContainer';
import PriceInfo from './ProductListingsComponents/PriceInfo';
import ProductDescription from './ProductListingsComponents/ProductDescription';
import ProductImage from './ProductListingsComponents/ProductImage';
import ProductName from './ProductListingsComponents/ProductName';
import { RatingType } from './ProductListingsComponents/RatingInfo';
import ShippingInfo from './ProductListingsComponents/ShippingInfo';

export type Range = { lowest: number; highest: number };

// TODO: this is a placeholder
export type ProductItem = {
  id: number | string;
  isPromoted: boolean;
  brand: string;
  name_en: string;
  name_ar: string;
  description_en: string;
  description_ar: string;
  feature_img: string;
  highest_selling_price: number;
  lowest_selling_price: number;
  highest_actual_cost: number;
  lowest_actual_cost: number;
  discount_rate: string;
  rating: RatingType;
  isAppOffer: boolean;
  product_code?: string;
};

const layoutDuration = 0.375;
interface ImageSectionProps {
  layout: 'grid' | 'list';
  id?: string | number;
  collectionCode?: any;
  featuredImage?: string;
  isPromoted?: boolean;
  discountPercentage?: any;
  isAppOffer?: boolean;
  isMobile?: boolean;
  twoColMobile?: boolean;
  isWishlist: boolean;
  isRecommendation?: boolean;
  checkIsSuccess?: (errorMessage: string) => void;
  discountBadgeBgColor?: string;
  productHeaderAspectRatioValue?: string;
  isDisplayProductHeaderBorder?: boolean;
  isDisplayProductHeaderBgColor?: boolean;
  imageClassName?: string;
  addToWishList: (shopWishlistInput: ShopWishlistInput) => Promise<WishlistMutationResponse | null | undefined>;
  removeToWishList: (shopWishlistInput: ShopWishlistInput) => Promise<WishlistMutationResponse | null | undefined>;
  getAllWishlist: (getAllWishlistInput: { last_cursor: string; direction: string }) => Promise<OrderQueryResponse | undefined>;
  productName: string;
  shopType?: string;
  brand: string;
}

// TODO: this badge should be generic
const DiscountBadge = (props: { percentage: string; isAppOffer?: boolean; isMobile?: boolean; bgColor?: string }) => {
  const { bgColor = 'bg-black100' } = props;
  const { translate } = useTranslate();
  return (
    <div
      className={`
          ${bgColor}

          flex items-center justify-center

          ${props.isMobile ? 'h-[18px]' : 'h-[28px]'} 2K:h-[49.77px] 4K:h-[74.7px] 8K:h-[149.33px]
          w-fit

          py-[2px] 2K:py-[3.55px] 4K:py-[5.33px] 8K:py-[10.66px]
          px-[6px] 2K:px-[10.66px] 4K:px-16 8K:px-32

          rounded-[2px] 2K:rounded-[4px] 4K:rounded-[8px] 8K:rounded-[16px]
        `}
    >
      <p
        className={`
            font-medium
            ${props.isMobile ? 'text-mobileText' : 'text-bodySmall'}  2K:text-subtitle 4K:text-fourKSubtitle 8K:text-LPTitle

            text-white100 lining-nums proportional-nums slashed-zero
          `}
      >
        {props.isAppOffer ? translate('shop.app_offer') : `${props.percentage}`}
      </p>
    </div>
  );
};

const ProductHeader = (props: ImageSectionProps) => {
  const {
    layout,
    collectionCode,
    id,
    isPromoted,
    discountPercentage,
    featuredImage,
    isAppOffer,
    isMobile,
    isWishlist,
    checkIsSuccess,
    discountBadgeBgColor,
    productHeaderAspectRatioValue = '[141/154]',
    isDisplayProductHeaderBorder = true,
    isDisplayProductHeaderBgColor = false,
    imageClassName,
    getAllWishlist,
    addToWishList,
    removeToWishList,
    productName,
    shopType,
    brand,
  } = props;
  const [isHover, setHover] = useState<boolean>(false);
  const roundedPercentage = parseFloat(discountPercentage);
  const roundedPercentageString = roundedPercentage.toString() + '%';
  const navigate = useNavigate();
  const { translate } = useTranslate();
  const { isRTL } = useSelector((state: RootState) => state.app);

  const handleClickImage = () => {
    let url = `${AppRoutes.shop}/${collectionCode}/${id}`;

    if (shopType) {
      url += `?shopType=${encodeURIComponent(shopType)}`;
    }

    navigate(url);
  };

  return (
    <motion.header
      layout
      className={`
          z-[2]
          relative
          flex items-center justify-center
          ${
            isPromoted
              ? `
              border-sunset
              border-t-[1px] 2K:border-t-[1.77px] 4K:border-t-[2.66px] 8K:border-t-[5.33px]
              bg-secondary/[6%]
            `
              : `${isDisplayProductHeaderBorder && 'border-[0.0625rem] border-secondary/20'} ${
                  isDisplayProductHeaderBgColor && 'bg-secondary/[6%]'
                }`
          }
          rounded-[4px] 2K:rounded-[7.11px] 4K:rounded-[10.66px] 8K:rounded-[21.33px]
          !transform-none
          ${
            layout === 'grid'
              ? `w-full aspect-${productHeaderAspectRatioValue}`
              : `
              aspect-square
              h-[73.5%] sm:h-full
            `
          }
        `}
      onHoverStart={() => setHover(true)}
      onHoverEnd={() => setHover(false)}
      onClick={handleClickImage}
    >
      <WishList
        isWishlist={isWishlist}
        product_code={props.id ? props.id.toString() : ''}
        checkIsSuccess={checkIsSuccess}
        addToWishList={addToWishList}
        removeToWishList={removeToWishList}
        getAllWishlist={getAllWishlist}
        productName={productName}
        brand={brand}
      />
      <ProductImage
        layout={layout}
        src={featuredImage}
        shallZoomImage={isHover}
        animationDuration={layoutDuration}
        twoColMobile={props.twoColMobile}
        imageClassName={imageClassName}
      />

      <GradientContainer layout={layout} className={``} animationDuration={layoutDuration} showGradience={isPromoted}>
        <header
          className={`
                    w-full flex ${isPromoted || roundedPercentage > 0 ? 'justify-between' : 'justify-end'}
                    ${layout === 'grid' ? `py-[12px] px-[10px]` : `p-4 sm:p-[12px]`}
                    2K:p-[21.33px] 4K:p-[31.99px] 8K:p-64
                `}
        >
          {isPromoted ? (
            <div
              className={`
                  w-fit
                  ${isMobile ? 'gap-[4px]' : 'gap-[10px]'} 2K:gap-[17.77px] 4K:gap-[26.66px] 8K:gap-[53.33px]
                  flex items-center
                `}
            >
              <ResponsiveIcon
                name={IconNames.promoted}
                baseHeight={20}
                baseWidth={20}
                mobileSize={
                  layout === 'list' && isMobile ? { width: 12, height: 12 } : layout === 'list' ? { width: 16, height: 16 } : undefined
                }
              />

              <p
                className={`
                    text-sunset
                    ${
                      isMobile ? 'text-mobilePromotedText' : 'text-caption'
                    } 2xl:text-bodySmall 2K:text-subtitle 4K:text-bigTitle 8K:text-huge
                    font-medium
                  `}
              >
                {translate('shop.promoted')}
              </p>
            </div>
          ) : (
            roundedPercentage > 0 && (
              <DiscountBadge
                bgColor={discountBadgeBgColor}
                percentage={isRTL ? roundedPercentageString + '-' : '-' + roundedPercentageString}
                isAppOffer={isAppOffer}
                isMobile={isMobile}
              />
            )
          )}
        </header>
      </GradientContainer>
    </motion.header>
  );
};

interface DescriptionProps {
  layout: 'grid' | 'list';
  collectionCode?: string;
  name?: any;
  description: any;
  price: ShopProductsModel;
  rating?: RatingType;
  isMobile?: boolean;
  id?: string | number;
  brand: string;
  brandNameFontSize?: string;
}

interface NamingProps {
  layout: 'grid' | 'list';
  collectionCode?: string;
  product?: string;
  brand: string;
  id?: string | number;
  brandNameFontSize?: string;
}

const Naming = (props: NamingProps) => {
  const { layout, collectionCode, product, brand, id, brandNameFontSize } = props;
  const navigate = useNavigate();

  const handleClickBrandName = () => {
    collectionCode && navigate(`${AppRoutes.shop}/c-${collectionCode}`);
  };

  const handleClickProductName = () => {
    navigate(`${AppRoutes.shop}/c-${collectionCode}/${id}`);
  };

  return (
    <p className={`${layout === 'grid' && 'flex flex-col gap-[0.25rem]'}`}>
      <BrandName text={brand} onClick={handleClickBrandName} brandNameFontSize={brandNameFontSize} />

      <ProductName text={product} onClick={handleClickProductName} brandNameFontSize={brandNameFontSize} />
    </p>
  );
};

const DescriptionSection = (props: DescriptionProps) => {
  const { layout, collectionCode, name, description, price, rating, isMobile, id, brand, brandNameFontSize } = props;

  const { language, isRTL } = useSelector((state: RootState) => state.app);

  const dir = isRTL ? 'rtl' : 'ltr';

  return (
    <motion.section
      layout="preserve-aspect"
      className={`
        relative
        flex flex-col
        w-full
        ${
          layout === 'grid'
            ? `
              
              my-[1rem]
              gap-[0.75rem] 
            `
            : `
              w-[67.66%]
              gap-8 sm:gap-4 2K:gap-[7.1px] 4K:gap-[10.66px] 8K:gap-[21.33px]
            `
        }
      `}
      transition={{ duration: layoutDuration }}
    >
      <div
        className={`
          ${
            layout === 'list' &&
            `
              flex flex-col
              gap-[0.5rem] SD:gap-[0.25rem]
            `
          }
        `}
      >
        <header className="flex flex-col gap-[0.75rem]">
          <Naming
            layout={layout}
            collectionCode={price?.brand?.collection_code}
            brand={brand}
            product={name}
            id={id}
            brandNameFontSize={brandNameFontSize}
          />
          {layout === 'grid' && (
            <footer
              className={`flex items-center 2K:gap-[42.66px] 4K:gap-64 8K:gap-[128px] ${isMobile ? 'grid grid-col-1 gap-12' : 'gap-24'}`}
            >
              <PriceInfo dir={dir} price={price} classNames={`${isMobile ? 'text-body' : 'text-bodyLarge'}`} />
            </footer>
          )}
        </header>

        <AnimatePresence>
          {layout === 'list' && (
            <>
              <motion.div
                className={`
                flex flex-col
                gap-[0.5rem]
                ${isMobile ? 'pb-[0.75rem]' : 'pb-[1.5rem]'}
              `}
                initial={{ opacity: 0 }}
                animate={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  opacity: [0, 1],
                  transition: { duration: 1 },
                }}
                exit={{
                  opacity: 0,
                  transition: { duration: 0 },
                }}
              >
                {/* <RatingInfo dir={dir} rating={rating} /> */}

                <ProductDescription
                  description={description}
                  className={`${isMobile ? 'line-clamp-1 text-caption' : 'line-clamp-2 text-body'}`}
                />
              </motion.div>
              <footer
                className={`flex items-center 2K:gap-[42.66px] 4K:gap-64 8K:gap-[128px] ${isMobile ? 'grid grid-col-1 gap-12' : 'gap-24'}`}
              >
                <PriceInfo dir={dir} price={price} classNames={`${isMobile ? 'text-body' : 'text-bodyLarge'}`} />
                <AnimatePresence>
                  {layout === 'list' && <ShippingInfo classNames={`${isMobile ? 'text-caption' : 'text-bodySmall'}`} />}
                </AnimatePresence>
              </footer>
            </>
          )}
        </AnimatePresence>
      </div>
    </motion.section>
  );
};

interface Props {
  layout: 'grid' | 'list';
  collectionCode?: any;
  product: ShopProductsModel;
  isMobile?: boolean;
  language: 'en' | 'ar';
  containerClass?: string;
  twoColMobile?: boolean;
  isWishlist?: boolean;
  isRecommendation?: boolean;
  checkIsSuccess?: (errorMessage: string) => void;
  discountBadgeBgColor?: string;
  productHeaderAspectRatioValue?: string;
  isDisplayProductHeaderBorder?: boolean;
  isDisplayProductHeaderBgColor?: boolean;
  brandNameFontSize?: string;
  imageClassName?: string;
  addToWishList: (shopWishlistInput: ShopWishlistInput) => Promise<WishlistMutationResponse | null | undefined>;
  removeToWishList: (shopWishlistInput: ShopWishlistInput) => Promise<WishlistMutationResponse | null | undefined>;
  getAllWishlist: (getAllWishlistInput: { last_cursor: string; direction: string }) => Promise<OrderQueryResponse | undefined>;
  shopType?: string;
}

const ProductListingCard = (props: Props) => {
  const {
    layout,
    collectionCode,
    product,
    isMobile,
    language,
    containerClass,
    isWishlist,
    isRecommendation,
    checkIsSuccess,
    discountBadgeBgColor,
    productHeaderAspectRatioValue,
    isDisplayProductHeaderBorder,
    isDisplayProductHeaderBgColor,
    brandNameFontSize,
    imageClassName,
    getAllWishlist,
    addToWishList,
    removeToWishList,
    shopType,
  } = props;

  // grid layout sm:aspect-[47/72]

  const discountPercentage = formatDecimalAsPercentage(product?.lowest_selling_price, product?.lowest_actual_cost);
  const name = product[`name_${language}`];
  const description = product[`description_${language}`];
  const brand = product?.brand?.[`name_${language}`];
  const featured_img = product?.featured_img || IMAGES.Product_Placeholder.toString();

  return (
    <article
      className={`
              cursor-default
              ${
                layout === 'grid'
                  ? `
                    aspect-[141/154]
                    w-full
                    h-fit ${containerClass}
                  `
                  : `
                    flex

                    sm:aspect-[149/30]
                    h-[136px] sm:h-[180px] 2K:h-[320px] 4K:h-[480px] 8K:h-[960px]
                    min-w-[335px] w-full sm:w-full
                    gap-24 2K:gap-[42.66px] 4K:gap-64 8K:gap-[128px]
                  `
              }
          `}
    >
      <ProductHeader
        layout={layout}
        collectionCode={collectionCode}
        id={product?.product_code}
        featuredImage={featured_img}
        isPromoted={product?.isPromoted}
        discountPercentage={discountPercentage}
        isAppOffer={product?.isAppOffer}
        isMobile={isMobile}
        twoColMobile={props.twoColMobile}
        isWishlist={isWishlist!}
        isRecommendation={isRecommendation}
        checkIsSuccess={checkIsSuccess}
        discountBadgeBgColor={discountBadgeBgColor}
        productHeaderAspectRatioValue={productHeaderAspectRatioValue}
        isDisplayProductHeaderBorder={isDisplayProductHeaderBorder}
        isDisplayProductHeaderBgColor={isDisplayProductHeaderBgColor}
        imageClassName={imageClassName}
        addToWishList={addToWishList}
        removeToWishList={removeToWishList}
        getAllWishlist={getAllWishlist}
        productName={product.name_en}
        shopType={shopType}
        brand={product.brand.name_en}
      />

      <DescriptionSection
        layout={layout}
        collectionCode={collectionCode}
        name={name}
        description={description}
        price={product}
        rating={product?.rating}
        isMobile={isMobile}
        id={product?.product_code}
        brand={brand as string}
        brandNameFontSize={brandNameFontSize}
      />
    </article>
  );
};

export default ProductListingCard;
