import { IconNames } from '@fe-monorepo/helper';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import { useRef } from 'react';

interface RadioProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {}

const Radio = (props: RadioProps) => {
    const elementRef = useRef<HTMLInputElement>(null);

    return (
        <div className="inline-flex">
            <button onClick={() => elementRef?.current?.click()}>
                <ResponsiveIcon
                    name={elementRef?.current?.checked ? IconNames.radioCheck : IconNames.radioUncheck}
                    baseWidth={24}
                    baseHeight={24}
                    iconClasses={elementRef?.current?.checked ? 'fill-sunset' : 'fill-otherGray'}
                />
            </button>
            <input {...props} type="radio" ref={elementRef} className="absolute h-0 w-0 pointer-events-none opacity-0" />
        </div>
    );
};

export default Radio;
