import { useFetchQuery } from "../useFetchQuery"
import { CATEGORY_QUERY, PRODUCT_QUERY } from "@fe-monorepo/data-access";

import { ProductInput } from "@fe-monorepo/models";
import { ShopCategoryDataModel, ShopProductDataModel } from "@fe-monorepo/models";


export const useShop = () =>
{
  const categories = useFetchQuery<ShopCategoryDataModel[]>(CATEGORY_QUERY.getAllCategory, []);

  const products = useFetchQuery<ShopProductDataModel[], ProductInput>(PRODUCT_QUERY.getAllProduct, []);

  return { categories, products }
}
