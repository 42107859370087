import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddressModel } from '../../CartPage/components/RecipientsDetails/types';
import InputField from '@fe-web/Molecules/InputFields/InputField';
import { authInputFieldStyleObj } from '../commonStyles';
import { DeliveryAddressModel } from '@fe-monorepo/models';

interface AddAddressListProps {
    addressList?: DeliveryAddressModel[];
    isAddNewAddress?: boolean;
    addressForUpdate?: DeliveryAddressModel;
    onClickExistingAddressButton: (id: number) => void;
    onClickAddAddressNameButton: () => void;
}

const AddAddressList = (props: AddAddressListProps) => {
    const { t } = useTranslation();

    return (
        <div className='flex flex-wrap gap-[0.5rem]'>
            {props?.addressList?.map((address, index) => {
                return (
                    address && (
                        <div>
                            <button
                                className={`cursor-pointer text-secondary/70 p-[0.375rem] border-[0.063rem] 
                                rounded whitespace-nowrap text-fs-body-small font-regular ${
                                    !props.isAddNewAddress && props.addressForUpdate?.user_address_id === address.user_address_id
                                        ? `border-sunset bg-sunset/10`
                                        : `border-secondary/20`
                                }`}
                                onClick={() => props?.onClickExistingAddressButton(address.user_address_id || -1)}
                            >
                                {address.label}
                            </button>
                        </div>
                    )
                );
            })}
            <button
                className={`cursor-pointer text-secondary/70  
              p-[0.375rem] border-[0.063rem] rounded whitespace-nowrap text-fs-body-small font-regular
              ${props.isAddNewAddress ? `border-sunset bg-sunset/10` : `border-secondary/20`}`}
                onClick={() => props?.onClickAddAddressNameButton()}
            >
                {t('shop.cart.shop_add_address_modal_page.placeholder_add_address_name')}
            </button>
        </div>
    );
};

export default AddAddressList;
