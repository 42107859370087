import { useMutation } from '@apollo/client';
import { PAYMENT_MUTATION } from '@fe-monorepo/data-access';
import { PaymentInput, PaymentMutationResponse, PaymentVerificationInput } from '@fe-monorepo/models';
import { RootState } from '@fe-monorepo/store';
import { useSelector } from 'react-redux';

import { useAppState } from '../useAppState/useAppState';

const usePayment = () => {
  //const { createInvoice } = useInvoice(true);
  const lmd_code = useSelector((state: RootState) => state.app.lmdCode);
  const { changeLoadingState } = useAppState();
  const [updatePaymentMethodGql, { data: UpdateData }] = useMutation<PaymentMutationResponse>(PAYMENT_MUTATION.updatePaymentMethod, {
    errorPolicy: 'all',
  });

  const [createPaymentGql, { data: CreateData }] = useMutation<PaymentMutationResponse>(PAYMENT_MUTATION.createPayment, {
    errorPolicy: 'all',
  });

  const [verifyStcpayPaymentGql, { data: verifyStcpayPaymentData }] = useMutation<PaymentMutationResponse>(
    PAYMENT_MUTATION.verifyStcpayPayment,
    {
      errorPolicy: 'all',
    },
  );

  const updatePaymentMethod = async (updatePaymentInput: PaymentInput, isDisableLoading?: boolean) => {
    if (!isDisableLoading) {
      changeLoadingState(true);
    }
    const { data } = await updatePaymentMethodGql({ variables: { details: updatePaymentInput } });
    if (!isDisableLoading) {
      changeLoadingState(false);
    }
    return data?.updatePaymentMethod;
  };

  const createPayment = async (createPaymentInput: PaymentInput, isDisableLoading?: boolean) => {
    if (!isDisableLoading) {
      changeLoadingState(true);
    }
    const { data } = await createPaymentGql({ variables: { details: createPaymentInput } });
    if (!isDisableLoading) {
      changeLoadingState(false);
    }
    return data?.createPayment;
  };

  const verifyOtp = async (verifyOtp: PaymentVerificationInput) => {
    const { data } = await verifyStcpayPaymentGql({ variables: { details: verifyOtp } });
    return data?.verifyStcpayPayment;
  };

  // useEffect(() => {
  //     if (verifyStcpayPaymentData?.verifyStcpayPayment?.is_successful && lmd_code.lmd_code) {
  //         createInvoice({
  //             lmd_code: lmd_code.lmd_code
  //         })
  //     }
  // }, [verifyStcpayPaymentData])

  return {
    usePayment: UpdateData?.updatePaymentMethod,
    updatePaymentMethod,
    createPayment,
    verifyOtp,
  };
};

export { usePayment };
