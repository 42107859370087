import { IconNames } from '@fe-monorepo/helper';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import { GetChatInfoDataModel, useTranslate } from '@fe-monorepo/hooks';
import { useEffect, useState } from 'react';

interface ConnectSearchBarProps {
    selectedItems?: GetChatInfoDataModel[];
    isError?: boolean;
    onRemoveItem?: (id: string | undefined) => void;
    getIsFocus?: (isFocus: boolean) => void;
    value: string | undefined;
    setValue: (value: string | undefined) => void;
}

const ConnectSearchBar = ({ selectedItems, isError, onRemoveItem, getIsFocus, value, setValue }: ConnectSearchBarProps) => {
    const { translate } = useTranslate();
    const [isFocus, setIsFocus] = useState<boolean>();

    useEffect(() => {
        const newFocus = !!isFocus;
        let delay = newFocus ? 0 : 100;
        setTimeout(() => getIsFocus && getIsFocus(newFocus), delay);
    }, [isFocus]);

    return (
        <>
            <div
                className="flex gap-[0.75rem] h-[2.375rem] w-full font-regular text-fs-body-small text-secondary px-[0.75rem] py-[0.5rem] 
            bg-zinc-100-neutral-800 border border-gray-200-zinc-700 rounded-[0.25rem]"
            >
                <ResponsiveIcon name={IconNames.search1} baseWidth={20} baseHeight={20} className="fill-secondary" />
                {selectedItems &&
                    selectedItems?.length < 2 &&
                    selectedItems?.map(selectedItem => (
                        <div className="flex gap-[0.5rem] px-[0.5rem] py-[0.125rem] bg-sunset/[0.12] font-regular text-fs-body-small text-sunset rounded-[0.125rem]">
                            <span>{selectedItem.username}</span>
                            <button
                                onClick={() => {
                                    onRemoveItem && onRemoveItem(selectedItem.username);
                                }}
                            >
                                <ResponsiveIcon name={IconNames.close} baseWidth={12} baseHeight={12} className="fill-sunset" />
                            </button>
                        </div>
                    ))}
                <input
                    value={value}
                    className="w-[inherit] text-ellipsis flex-1 caret-sunset bg-transparent focus:outline-0 placeholder:color-gray20"
                    placeholder={translate('chat.search.placeholder') as string}
                    onChange={e => setValue(e.target.value)}
                    onFocus={() => setIsFocus(true)}
                    onBlur={() => setIsFocus(false)}
                ></input>
                {value && (
                    <div onClick={() => setValue('')} className={`font-regular text-fs-body-small text-sunset hover:cursor-pointer`}>
                        {translate('action.clear')}
                    </div>
                )}
            </div>
            {selectedItems && selectedItems?.length >= 2 && (
                <div className="flex gap-[0.5rem] mt-[0.25rem] overflow-auto">
                    {selectedItems?.map((selectedItem, key) => (
                        <div
                            className="flex gap-[0.5rem] px-[0.5rem] py-[0.125rem] bg-sunset/[0.12] font-regular text-fs-body-small text-sunset rounded-[0.125rem]"
                            key={key}
                        >
                            <span>{selectedItem.username}</span>
                            <button
                                onClick={() => {
                                    onRemoveItem && onRemoveItem(selectedItem.username);
                                }}
                            >
                                <ResponsiveIcon name={IconNames.close} baseWidth={12} baseHeight={12} className="fill-sunset" />
                            </button>
                        </div>
                    ))}
                </div>
            )}
            {isError && (
                <div className="flex gap-[0.5rem] text-yellow font-regular text-fs-caption">
                    <ResponsiveIcon name={IconNames.error} className="fill-yellow" baseWidth={16} baseHeight={16} />
                    <span>{translate('error_max_participant_100')}</span>
                </div>
            )}
        </>
    );
};

export default ConnectSearchBar;
