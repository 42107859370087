// import InputField from "@fe-web/Molecules/InputFields/InputField";
import { Logger } from '@fe-monorepo/helper';
import { DeliveryAddressInput } from '@fe-monorepo/models';
import CityDropdownField from '@fe-web/Molecules/InputFields/CustomDropdowns/CityDropdownField';
import DistrictDropdownField from '@fe-web/Molecules/InputFields/CustomDropdowns/DistrictDropdownField';
import InputField from '@fe-web/Molecules/InputFields/InputField/InputField';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GeocodeResult, getGeocode, getLatLng } from 'use-places-autocomplete';

import { authInputFieldStyleObj } from '../commonStyles';

interface AddAddressFullAddressProps {
  funcHandleDataSavePerSection: (results: GeocodeResult[], lat: number, lng: number) => void;
  saveLocationDetails?: DeliveryAddressInput;
}

const AddAddressFullAddress = ({ funcHandleDataSavePerSection, saveLocationDetails }: AddAddressFullAddressProps) => {
  const { t } = useTranslation();
  const [streetName, setStreetName] = useState<string>('');
  const [zipCode, setZipCode] = useState<string>('');
  const [districtName, setDistrictName] = useState<string>('');
  const [cityName, setCityName] = useState<string>('');
  const { error } = Logger();

  useEffect(() => {
    //Street name is optional, District Name - required, City Name - required, Zip Code optional
    if (cityName && districtName) {
      const addressToSearch = streetName + ' ' + cityName + ' ' + districtName + ' ' + zipCode;
      handleOnLocationSave(addressToSearch);
    }
  }, [streetName, zipCode, districtName, cityName]);

  const handleOnLocationSave = async (address: string) => {
    await getGeocode({ address })
      .then(response => {
        const { lat, lng } = getLatLng(response[0]);

        //Call function to set location data to save
        funcHandleDataSavePerSection(response, lat, lng);
      })
      .catch(err => {
        error('ERROR in fetching map location!', err);
      });
  };

  return (
    <div className="flex flex-col items-start justify-start w-[100%] gap-8">
      {/* street name field with placeholder*/}
      <div className="mt-0">
        <InputField
          label={t('shop.cart.shop_add_address_modal_page.placeholder_street_name')}
          isSecret={false}
          hasFocused={false}
          errorMessage={''}
          isUserAllowedToType={(value: string) => true}
          retrieveValue={(value: any) => setStreetName(value)}
          style={authInputFieldStyleObj}
          disableDefaultInputWidth={true}
          existingValue={saveLocationDetails?.street || ''}
        />
      </div>

      {/* district field with placeholder */}
      <div className="mt-[1.5rem]">
        <DistrictDropdownField
          containerStyle="SD:w-[23.938rem]"
          label={t('shop.cart.shop_add_address_modal_page.placeholder_district').toString()}
          apiErrorMessage={''}
          onDistrictDropdownChange={e => {
            if (e?.name) {
              setDistrictName(e.name);
            } else {
              setDistrictName('');
            }
          }}
        />
      </div>

      {/* city field with placeholder*/}
      <div className="mt-0">
        <CityDropdownField
          containerStyle="SD:w-[23.938rem]"
          label={t('formPlaceholder_city').toString()}
          onCityDropdownChange={e => {
            if (e?.name) {
              setCityName(e.name);
            } else {
              setCityName('');
            }
          }}
        />
      </div>

      {/* zip code field with placeholder*/}
      <div className="mt-0">
        <InputField
          label={t('shop.cart.shop_add_address_modal_page.plcaeholder_zip_code').toString()}
          isNumber={true}
          isSecret={false}
          hasFocused={false}
          errorMessage={''}
          isUserAllowedToType={(value: string) => true}
          retrieveValue={(value: any) => setZipCode(value)}
          min={0}
          style={authInputFieldStyleObj}
          disableDefaultInputWidth={true}
          existingValue={saveLocationDetails?.zip_code?.toString() ?? ''}
        />
      </div>
    </div>
  );
};

export default AddAddressFullAddress;
