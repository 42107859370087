import { convertGMTToIana, getCurrentUserTimeZone } from '@fe-monorepo/helper';
import { useCountdownDay, useTranslate } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import { useSelector } from 'react-redux';

const TimeSeparator = (props: { isTheme?: boolean }) => (
  <span className={`flex items-center w-[0.125rem] h-[2.25rem] ${props.isTheme ? 'text-secondary/20' : 'text-white100/70'}`}>:</span>
);

interface TimerItemProps {
  label: string | null;
  value: string | null;
  isTheme?: boolean;
}

const TimerItem = ({ label, value, isTheme }: TimerItemProps) => (
  <div className="flex w-[3.75rem] flex-col items-center gap-[.625rem] flex-[1_0_0%] ">
    <p
      className={`font-medium text-fs-title tracking-[-0.02] 
    ${isTheme ? 'text-secondary/70' : 'text-white100/90'}`}
    >
      {value}
    </p>
    <p
      className={`text-fs-caption font-regular 
    ${isTheme ? 'text-secondary/50' : 'text-white100/50'}`}
    >
      {label}
    </p>
  </div>
);

export const TimerSection = (props: { timer: string; isTheme?: boolean }) => {
  const { translate } = useTranslate();
  const preferred_timezone = useSelector((state: RootState) => state.user.userContext.preferred_timezone);

  const finalTimezone = preferred_timezone ? convertGMTToIana(preferred_timezone) : getCurrentUserTimeZone();

  const { remainingDay } = useCountdownDay(props.timer, finalTimezone);

  return (
    <div className="flex items-end gap-[.5rem] w-full h-full z-[1] mb-[1rem]">
      <div className="flex items-start gap-2">
        <TimerItem value={remainingDay?.days} label={translate('post_poll_selectDay')} isTheme={props.isTheme} />

        <TimeSeparator isTheme={props.isTheme} />

        <TimerItem value={remainingDay?.hours} label={translate('post_poll_selectHour')} isTheme={props.isTheme} />

        <TimeSeparator isTheme={props.isTheme} />

        <TimerItem value={remainingDay?.minutes} label={translate('post_poll_selectMinutes')} isTheme={props.isTheme} />

        <TimeSeparator isTheme={props.isTheme} />

        <TimerItem value={remainingDay?.seconds} label={translate('post_poll_selectSeconds')} isTheme={props.isTheme} />
      </div>
    </div>
  );
};
