import React from 'react';
import { IconNames } from '@fe-monorepo/helper';
import ResponsiveIcon from '../../../../../../components/Atoms/Icon/ResponsiveIcon';
import { useTranslate } from '@fe-monorepo/hooks';

interface SearchBarProps {
    onSearch?: (searchQuery: string) => void;
    searchQuery: string;
    setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
}

const SearchBar: React.FC<SearchBarProps> = ({ onSearch, searchQuery, setSearchQuery }) => {
    const { translate } = useTranslate();

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchItem = e.target.value;
        setSearchQuery(searchItem);
        if (onSearch) {
            onSearch(searchItem);
        }
    };

    return (
        <div className={`flex w-full SD:w-[16rem] FHD:w-[18rem] self-stretch mix-blend-normal bg-secondary/[0.06] p-[.5rem] rounded-[4px] 4xl:rounded-[7.11px] 5xl:rounded-[10.66px] 8xl:rounded-[21.33px]`}>
            <div className="flex gap-12 4xl:gap-22 5xl:gap-32 8xl:gap-64 text-body 4xl:text-subtitle 5xl:text-bigTitle 8xl:text-LPTitle items-center w-full">
                <input
                    onChange={handleSearch}
                    value={searchQuery}
                    className="border-none bg-transparent rounded-none focus:outline-none flex-1 w-full text-secondary mix-blend-normal text-fs-body-small"
                    placeholder={translate('form.placeholder.search_by_product_ID') || undefined}
                    autoFocus={true}
                />
                <ResponsiveIcon
                    className={`cursor-pointer relative fill-secondary/50 mix-blend-normal px-2 4xl:px-[3.55px] 5xl:px-[5.33px] 8xl:px-[10.66px]`}
                    name={IconNames.search1}
                    baseWidth={20}
                    baseHeight={20}
                />
            </div>
        </div>
    );
};

export default SearchBar;
