import { EventObject, IMAGE_ALT_LABELS, IconNames, MixPanelEvents, TxKeyPath } from '@fe-monorepo/helper';
import { MATCH_EVENTS, useMatchmakingProvider, useTranslate, useUserProfile } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import Image from '@fe-web/Atoms/Media/Image';
import PlayerSelectionCard from '@fe-web/Molecules/PlayerSelectionCard';
import Loading from '@fe-web/Templates/Loading';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { webAnalytics } from '../../../../../../../helper/webAnalytics';
import GradientBackground, { BACKGROUNDS } from './GradientBackground';
import { StyledBody, StyledButton, StyledContainer, StyledH1, StyledHeader } from './PlayerSelection.styled';
import PlayerSelectionEmpty from './PlayerSelectionEmpty';
import PlayerSelectionItem from './PlayerSelectionItem';

interface PlayerSelectionProps {
  goBack: (reset: boolean) => void;
}

const foundMatchEvent = (gameType: string, gamePlatform: string, gameLanguage: string) => {
  const foundMatchEvent: EventObject = {
    name: 'found_match',
    properties: {
      game_type: gameType,
      game_platform: gamePlatform,
      game_language: gameLanguage,
    },
  };

  webAnalytics(foundMatchEvent);
};

const failedMatchMaking = (gameType: string, gamePlatform: string, gameLanguage: string, errorCode: string, errorMessage: string) => {
  const failedMatchEvent: EventObject = {
    name: 'failed_matchmaking',
    properties: {
      game_type: gameType,
      game_platform: gamePlatform,
      game_language: gameLanguage,
      error_code: errorCode,
      error_message: errorMessage,
    },
  };

  webAnalytics(failedMatchEvent);
};

const PlayerSelection = ({ goBack }: PlayerSelectionProps) => {
  const isRtl = useSelector((state: RootState) => state.app.isRTL);
  const { user } = useUserProfile();
  const { translate } = useTranslate();
  const [isReject, setIsReject] = useState(false);
  const [isAccept, setIsAccept] = useState(true);
  const [isRejectHover, setIsRejectHover] = useState(false);
  const [isAcceptHover, setIsAcceptHover] = useState(false);
  const [isRejectHoverEnd, setIsRejectHoverEnd] = useState(false);
  const [isAcceptHoverEnd, setIsAcceptHoverEnd] = useState(false);
  const [currentAnimation, setCurrentAnimation] = useState('initial');
  const {
    userPreferences,
    searchParticipantsFiltered,
    recentlyMatched,
    platform,
    game,
    referenceLang,
    isPageLoading,
    isActionDisabled,
    searchingData,
    follow,
    quit,
    reject,
  } = useMatchmakingProvider();

  const onQuit = () => {
    quit && quit();
    goBack(true);
  };

  const onEdit = () => {
    quit && quit();
    goBack(false);
  };

  useEffect(() => {
    setIsReject(false);
    setIsAccept(false);
    setCurrentAnimation('initial');
  }, [searchParticipantsFiltered]);

  useEffect(() => {
    if (!searchingData) {
      return;
    }
    if (searchingData.is_successful) {
      const { reference_game_code, reference_lang_code, reference_platform_code } = searchingData.data.user_preferences;
      if (searchingData.data.search_participants.length > 0) {
        foundMatchEvent(reference_game_code, reference_platform_code, reference_lang_code);
      } else {
        failedMatchMaking(reference_game_code, reference_platform_code, reference_lang_code, '', 'no match found');
      }
    } else if (searchingData.error_msg) {
      if (game && platform && referenceLang) {
        failedMatchMaking(game, platform, referenceLang, '' + searchingData.error_code, searchingData.error_msg);
      }
    }
  }, [searchingData]);

  return (
    <StyledContainer>
      <StyledHeader>
        <StyledH1>{translate('play.matchmaking.player_select.title')} 🕹️️</StyledH1>
        <StyledButton className="hidden SD:block" type="button" onClick={onQuit}>
          {translate('play.matchmaking.player_select.quit_matchmaking')}
        </StyledButton>
      </StyledHeader>
      <StyledBody>
        <div className="relative col-span-2 flex flex-col items-center gap-[5.625rem] pt-[3rem]">
          {searchParticipantsFiltered && searchParticipantsFiltered?.length > 0 ? (
            <motion.div initial animate={{ opacity: searchParticipantsFiltered && searchParticipantsFiltered?.length > 0 ? [0, 1] : 1 }}>
              <div className="flex items-center justify-center gap-[1.5rem]">
                <div className="absolute aspect-[535/450] w-[33.44rem] blur-lg">
                  <GradientBackground
                    activeBackground={BACKGROUNDS.REJECT}
                    isActive={isReject}
                    isHover={isRejectHover}
                    isOpposite={isAccept}
                    isOppositeHover={isAcceptHover}
                    isOppositeHoverEnd={isAcceptHoverEnd}
                    onAnimationComplete={() => {
                      setIsRejectHoverEnd(false);
                    }}
                    position="left"
                  />
                  <GradientBackground
                    activeBackground={BACKGROUNDS.ACCEPT}
                    isActive={isAccept}
                    isHover={isAcceptHover}
                    isOpposite={isReject}
                    isOppositeHover={isRejectHover}
                    isOppositeHoverEnd={isRejectHoverEnd}
                    onAnimationComplete={() => {
                      setIsAcceptHoverEnd(false);
                    }}
                    position="right"
                  />
                </div>
                <button
                  className={`${isAccept ? 'SD:fill-gray20 SD:text-gray20' : 'fill-secondary'} ${
                    isReject ? 'border-red bg-red/[0.1]' : 'border-transparent'
                  } border z-10 absolute left-0 SD:static
                  flex flex-col gap-[0.5rem]
                  items-center font-medium text-fs-body-small
                  py-[0.75rem] px-[1rem] bg-gray-200-zinc-600 SD:bg-transparent rounded-r-[0.5rem]
                  SD:rounded-[0.5rem] SD:hover:border-red SD:hover:bg-red/[0.1]
                  transition transition-all duration-1000 SD:disabled:fill-gray20 SD:disabled:text-gray20`}
                  type="button"
                  onClick={() => {
                    reject && reject(searchParticipantsFiltered[0].username, 1000);
                    setIsReject(true);
                    setCurrentAnimation('reject');
                    mixpanelHelper.trackEvent(MixPanelEvents.matchedRejected, {
                      'Username (of matchmaking initiated)': user?.username,
                      'Username (of matched player)': searchParticipantsFiltered[0].username,
                    });
                  }}
                  onMouseEnter={() => {
                    setIsRejectHover(true);
                    setIsRejectHoverEnd(false);
                  }}
                  onMouseLeave={() => {
                    setIsRejectHover(false);
                    setIsRejectHoverEnd(true);
                  }}
                  disabled={isActionDisabled}
                >
                  <ResponsiveIcon name={IconNames.thumbOutline} baseWidth={24} baseHeight={24} iconClasses="rotate-180" />
                  <span>{translate('action_reject')}</span>
                </button>
                <div className="relative flex items-center justify-center gap-[1.5rem] z-1 pb-[1rem]">
                  <div className="relative">
                    {searchParticipantsFiltered?.[1] && (
                      <motion.div
                        initial={false}
                        variants={{
                          reject: {
                            scale: [0.8, 1, 1, 1],
                            filter: ['blur(16px)', 'blur(0)', 'none'],
                            top: [0, 16],
                          },
                          accept: {
                            scale: [0.8, 1, 1, 1],
                            filter: ['blur(16px)', 'blur(0)', 'none'],
                            top: [0, 16],
                          },
                          initial: {
                            scale: 0.8,
                            filter: 'blur(16px)',
                            top: 0,
                          },
                        }}
                        animate={currentAnimation}
                        transition={{ duration: currentAnimation === 'initial' ? 0.0001 : 1.2, ease: 'linear' }}
                        className="origin-bottom relative"
                      >
                        <PlayerSelectionCard
                          headerImage={searchParticipantsFiltered[1].cover_banner_url}
                          avatar={searchParticipantsFiltered[1].avatar_url}
                          username={searchParticipantsFiltered[1].username}
                          displayName={searchParticipantsFiltered[1].display_name}
                          className="absolute top-0 origin-top"
                          bio={searchParticipantsFiltered[1].bio}
                          following={searchParticipantsFiltered[1].total_following}
                          followers={searchParticipantsFiltered[1].total_followers}
                          isVerified={!!searchParticipantsFiltered[1]?.is_official_account}
                        />
                      </motion.div>
                    )}
                    {searchParticipantsFiltered?.[0] && (
                      <motion.div
                        initial={false}
                        variants={{
                          reject: {
                            translateX: isRtl ? ['0%', '0%', '50%'] : ['0%', '0%', '-50%'],
                            rotate: isRtl ? ['0deg', '-5deg', '15deg'] : ['0deg', '5deg', '-15deg'],
                            opacity: [1, 1, 1, 1, 0.5, 0],
                          },
                          accept: {
                            translateX: isRtl ? ['0%', '0%', '-50%'] : ['0%', '0%', '50%'],
                            rotate: isRtl ? ['0deg', '5deg', '-15deg'] : ['0deg', '-5deg', '15deg'],
                            opacity: [1, 1, 1, 1, 0.5, 0],
                          },
                          initial: {
                            translateX: '0%',
                            rotate: '0deg',
                            opacity: 1,
                          },
                        }}
                        animate={currentAnimation}
                        transition={{
                          duration: currentAnimation === 'initial' ? 0.0001 : 0.8,
                          ease: 'linear',
                        }}
                        className="origin-bottom"
                      >
                        <PlayerSelectionCard
                          headerImage={searchParticipantsFiltered[0].cover_banner_url}
                          avatar={searchParticipantsFiltered[0].avatar_url}
                          username={searchParticipantsFiltered[0].username}
                          displayName={searchParticipantsFiltered[0].display_name}
                          className="relative top-[1rem] drop-shadow-xl"
                          bio={searchParticipantsFiltered[0].bio}
                          following={searchParticipantsFiltered[0].total_following}
                          followers={searchParticipantsFiltered[0].total_followers}
                          isVerified={!!searchParticipantsFiltered[0]?.is_official_account}
                        />
                      </motion.div>
                    )}
                  </div>
                </div>
                <button
                  className={`${isReject ? 'SD:fill-gray20 SD:text-gray20' : 'fill-secondary'} ${
                    isAccept ? 'border-green bg-green/[0.1]' : 'border-transparent'
                  } border z-10 absolute right-0 SD:static
                  flex flex-col gap-[0.5rem] items-center
                  font-medium text-fs-body-small
                  py-[0.75rem] px-[1rem]
                  bg-gray-200-zinc-600 SD:bg-transparent rounded-l-[0.5rem]
                  SD:rounded-[0.5rem] SD:hover:border-green SD:hover:bg-green/[0.1]
                  transition transition-all duration-1000 SD:disabled:fill-gray20 SD:disabled:text-gray20`}
                  type="button"
                  onClick={() => {
                    follow &&
                      follow(
                        searchParticipantsFiltered[0].username,
                        searchParticipantsFiltered[0].matchmaking_gamer_id,
                        searchParticipantsFiltered[0].avatar_url,
                        800,
                      );
                    setIsAccept(true);
                    setCurrentAnimation('accept');

                    mixpanelHelper.trackEvent(MixPanelEvents.matchedGamer, {
                      'Username (of matchmaking initiated)': user?.username,
                      'Username (of matched player)': searchParticipantsFiltered[0].username,
                    });
                  }}
                  onMouseEnter={() => {
                    setIsAcceptHover(true);
                    setIsAcceptHoverEnd(false);
                  }}
                  onMouseLeave={() => {
                    setIsAcceptHover(false);
                    setIsAcceptHoverEnd(true);
                  }}
                  disabled={isActionDisabled}
                >
                  <ResponsiveIcon name={IconNames.thumbOutline} baseWidth={24} baseHeight={24} />
                  <span>{translate('action_match')}</span>
                </button>
              </div>
            </motion.div>
          ) : (
            <motion.div animate={{ opacity: [0, 1] }} transition={{ duration: 0.6 }}>
              <PlayerSelectionEmpty onClick={onEdit} />
            </motion.div>
          )}
        </div>
        <div className="flex flex-col divide-y gap-[2.5rem] pt-[2.25rem] py-[2.5rem] px-[1.25rem] SD:px-0 SD:pl-[1.5rem] SD:min-h-[42.5rem] SD:border-l border-gray-200-zinc-700">
          <div className="flex flex-col">
            <StyledButton className="SD:hidden mb-[2.25rem]" type="button" onClick={onQuit}>
              {translate('play.matchmaking.player_select.quit_matchmaking')}
            </StyledButton>
            <div className="flex justify-between">
              <h3 className="font-medium text-fs-body-large">{translate('play.matchmaking.player_select.your_preferences')}</h3>
              <button
                className="text-sunset inline-flex items-center gap-[0.5rem] font-regular text-fs-body"
                type="button"
                onClick={onEdit}
              >
                <ResponsiveIcon name={IconNames.editOutline2} baseHeight={20} baseWidth={20} className="fill-sunset" />
                <span>{translate('action_edit')}</span>
              </button>
            </div>
            <div className="flex flex-col gap-[1rem] mt-[1.5rem]">
              <div className="flex gap-[1rem] items-center">
                <div className="h-40 w-40 bg-gray-200-zinc-700 border border-gray-200-zinc-700 rounded-full overflow-hidden">
                  <Image
                    img={user?.avatar_url}
                    alt={translate(IMAGE_ALT_LABELS.matchmaking_logged_user_avatar as TxKeyPath) ?? ''}
                    divStyle="h-40 w-40 bg-black/[0.2] border border-gray-200-zinc-700 rounded-full overflow-hidden"
                    imgStyle=""
                    fallback={
                      <ResponsiveIcon
                        name={IconNames.avatar}
                        baseHeight={16}
                        baseWidth={16}
                        iconClasses="h-full w-full p-[0.5rem] fill-gray"
                      />
                    }
                  />
                </div>
                <span className="font-regular text-fs-body-small text-zinc-600-400">@{user?.username}</span>
              </div>
              {userPreferences?.description && (
                <span className="font-medium text-fs-body-small break-words rounded-[0.625rem] rounded-tl-none bg-zinc-100-neutral-800 py-[0.625rem] px-[0.75rem]">
                  {userPreferences?.description}
                </span>
              )}
              {game && platform && (
                <div className="flex flex-col gap-[0.5rem]">
                  <span className="font-regular text-fs-body-small text-neutral-400-zinc-500">
                    {translate('play.matchmaking.player_select.looking_for_a_gamer_based_on')}
                  </span>
                  <span className="font-medium text-fs-body-small text-zinc-600-400">
                    {game} <span className="font-regular text-neutral-400-zinc-500">{translate('play.matchmaking.player_select.on')}</span>{' '}
                    {platform}
                  </span>
                </div>
              )}
            </div>
          </div>
          {recentlyMatched && recentlyMatched?.length > 0 && (
            <div className="flex flex-col pt-[2.5rem] gap-[1.25rem] border-gray-200-zinc-700">
              <h3 className="font-medium text-fs-body-large">{translate('play.matchmaking.player_select.recent_followed_gamers')}</h3>
              <div className="flex flex-col SD:min-h-[34rem]">
                {recentlyMatched?.map(({ avatar_url, username, matchmaking_gamer_id, event }, key) => (
                  <PlayerSelectionItem
                    key={key}
                    avatar_url={avatar_url}
                    username={username}
                    matchmaking_gamer_id={matchmaking_gamer_id}
                    animation={event ?? MATCH_EVENTS.INITIAL}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </StyledBody>
      {isPageLoading && <Loading />}
    </StyledContainer>
  );
};

export default PlayerSelection;
