interface SectionHeaderContainerProps {
  children: React.ReactNode;
  title: string | null;
  className?: string;
}

const SectionHeaderContainer = ({ children, title, className }: SectionHeaderContainerProps) => {
  return (
    <div className={`${className}`}>
      <p className="text-fs-big-title not-italic font-medium text-secondary tracking-[-0.0675rem] mb-[2rem]">{title}</p>
      <div className={`flex gap-[1.5rem] scroll-smooth overflow-x-scroll`}>{children}</div>
    </div>
  );
};

export default SectionHeaderContainer;
