import { IconNames, TxKeyPath } from '@fe-monorepo/helper';
import { useTranslate, useUserProfile } from '@fe-monorepo/hooks';
import { RootState, setFollowersFollowing, useAppDispatch } from '@fe-monorepo/store';
import SuperPlus from '@fe-web/Molecules/SuperPlus/SuperPlus';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { AppRoutes } from '../../../app.routes.enum';
import useDrawerToggle from '../../../hooks/useDrawerToggle';
import useMobileDetect from '../../../hooks/useMobileDetect';
import useRecentSearch from '../../../hooks/useRecentSearch';
import useToggleSearch from '../../../hooks/useToggleSeach';
import FollowersFollowing from '../../../pages/Connect/components/FollowersFollowing/FollowersFollowing';
import Icon from '../../Atoms/Icon/Icon';
import ResponsiveIcon from '../../Atoms/Icon/ResponsiveIcon';
import Container from '../../Templates/Container';
import MiniCart from '../MiniCart/MiniCart';
import MobileDrawer from './MobileDrawer';
import NavChat from './NavChat';
import NavLinks from './NavLinks';
import NavNotification from './NavNotifications';
import NavProfile from './NavProfile';
import NavWishList from './NavWishList';
import RecentSearchMobile from './Search/RecentSearchMobile';
import Search from './Search/Search';
import SearchInput from './Search/SearchInput';

export const RECENT_SEARCH_ID = 'header-recent-search';
interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const { translate } = useTranslate();
  const { isOpen } = useRecentSearch();
  const { showSearchInput } = useToggleSearch();
  const { isOpen: open, toggleDrawer } = useDrawerToggle();
  const isMobile = useMobileDetect();
  const navigate = useNavigate();
  const { user, isLoggedIn } = useUserProfile();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const mobileSearchInput = useMemo(() => {
    if (isMobile) {
      toggleDrawer(false);
      return showSearchInput;
    }
    return false;
  }, [showSearchInput]);
  const isfollowersFollowingModalOpen = useSelector((state: RootState) => state.connect.followersFollowing);

  const handleClose = () => {
    dispatch(
      setFollowersFollowing({
        isFollowersFollowingOpen: false,
        initialTab: 'following',
      }),
    );
  };

  useEffect(() => {
    if (!isMobile && open) {
      toggleDrawer(false);
    }
  }, [isMobile]);

  return (
    <header
      className="
        sticky
        min-h-[73px]
        flex items-center
        border-b-[1px] 4xl:border-b-[1.77px] 5xl:border-b-[2.66px] 8xl:border-b-[5.33px]
        border-solid border-secondary/20
        bg-primary
        top-0
        transition-all duration-300
        py-[15px] 4xl:py-[26.66px] 5xl:py-40 8xl:py-80
        z-[2000]
      "
    >
      <Container
        className={`
          ${mobileSearchInput ? 'overflow-clip' : ''}
          rounded-none bg-primary transition-all duration-300
          flex items-center justify-between
          gap-[30px] 4xl:gap-54 5xl:gap-80 8xl:gap-160
          animate__animated animate__fadeIn`}
      >
        {!mobileSearchInput && (
          <>
            <ResponsiveIcon
              className="cursor-pointer fill-brand-primary animate__animated animate__fadeIn"
              name={IconNames.stcPlay}
              baseWidth={110}
              baseHeight={30}
              onClick={() => navigate(AppRoutes.home)}
              srAltText={(translate('SEO.page_image_alt.home') as TxKeyPath) ?? ''}
            />
            <div
              className={`${
                showSearchInput ? 'animate__animated animate__fadeOut hidden' : 'flex-1 animate__animated animate__fadeIn block'
              }`}
            >
              <NavLinks />
            </div>

            <div className={`flex items-center gap-20 4xl:gap-36 5xl:gap-54 8xl:gap-100 ${showSearchInput ? 'flex-1' : ''}`}>
              {location.pathname !== '/play/tournaments/create' && (
                <>
                  {!open && <Search />}
                  <MiniCart />
                  <NavWishList />
                  {!isMobile && isLoggedIn && (
                    <>
                      <NavNotification />
                      <NavChat />
                    </>
                  )}
                  {isLoggedIn && !isMobile && <SuperPlus />}
                </>
              )}
              <NavProfile />

              {isMobile && (
                <div className="h-fit" onClick={() => toggleDrawer(!open)}>
                  {open ? (
                    <Icon className="cursor-pointer fill-secondary" name={IconNames.close1} height={20} width={20} />
                  ) : (
                    <Icon className="cursor-pointer fill-secondary" name={IconNames.menu} height={20} width={20} />
                  )}
                </div>
              )}
            </div>
          </>
        )}

        {mobileSearchInput && showSearchInput && (
          <div className="w-full">
            <SearchInput />
          </div>
        )}
      </Container>

      <MobileDrawer />

      {isMobile && user?.token && isOpen && <RecentSearchMobile open={isOpen && isMobile} />}
      {isMobile && user?.token && user?.username && isfollowersFollowingModalOpen?.isFollowersFollowingOpen ? (
        <div className="absolute bottom-0 left-0 h-screen w-full z-20 top-[4.35rem]">
          <FollowersFollowing handleClose={handleClose} activeTab={isfollowersFollowingModalOpen.initialTab} />
        </div>
      ) : null}

      {isOpen && (
        <div
          className={`xsMax:hidden sm:hidden xs:hidden md:hidden lg:block animate__animated
            ${isOpen ? 'animate__fadeIn block' : 'animate__fadeOut hidden'}
            -z-[50] fixed top-64 4xl:top-[113.77px] 5xl:top-[170.66px] 8xl:top-[341.33px]
            bg-black100/80 h-screen w-full inset-0 backdrop-blur-sm drop-shadow-sm`}
        />
      )}
    </header>
  );
};

export default Header;
