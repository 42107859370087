import { IconNames } from '@fe-monorepo/helper';
import useMobileDetect from '@fe-web/hooks/useMobileDetect';
import { useRef, useState } from 'react';

import useOutsideClick from '../../../../../app/hooks/useOutsideClick';
import ResponsiveIcon from '../../../Atoms/Icon/ResponsiveIcon';
import ToolTip from '../../../Atoms/ToolTip';
import DropDownList from './Components/DropDownList';

interface Props<T> {
  style?: {
    container: string;
    text: string;
    option?: string;
    wrapper?: string;
    tooltip?: string;
    tooltipContainer?: string;
    listContainer?: string;
  };

  selectedOption: T;
  selectedIcon: JSX.Element;
  selectedIconPosition: 'start' | 'end';
  options: T[];

  retreiveSelection: (option: T) => void;
  getStringValue: (option: T) => string;
}

const DropDown = <T extends { id: number | string }>(props: Props<T>) => {
  const {
    style,
    selectedOption,
    selectedIcon,
    selectedIconPosition,
    options,

    getStringValue,
    retreiveSelection,
  } = props;

  const ref = useRef<HTMLDivElement>(null);
  const [isToolTipOn, setToolTipOn] = useState<boolean>(false);

  const toggleToolTip = () => setToolTipOn(value => !value);

  const elementRef = useOutsideClick(() => setToolTipOn(false));

  const isMobile = useMobileDetect();

  elementRef.current = ref.current;

  return (
    <div ref={ref} onClick={toggleToolTip} className={`${style?.wrapper || ''}`}>
      <div
        className={`
          mdMaxLl:text-fs-caption
          relative
          flex items-center
          cursor-pointer
          justify-start
          select-none
          gap-[6px] 4xl:gap-[10.66px] 5xl:gap-16 8xl:gap-32
          ${style?.container}
        `}
      >
        <p
          className={`
            h-full
            ${style?.text}
          `}
        >
          {getStringValue(selectedOption)}
        </p>

        <ResponsiveIcon
          className={`
            z-[1]
            justify-self-end
            cursor-pointer
            fill-secondary  ${isToolTipOn && 'rotate-180 fill-sunset'}
          `}
          name={IconNames.chevron}
          baseHeight={isMobile ? 16 : 24}
          baseWidth={isMobile ? 16 : 24}
        />
      </div>

      {isToolTipOn && options?.length > 0 && (
        <ToolTip
          containerClassname={`${style?.tooltipContainer || ''}`}
          className={`
            ${style?.tooltip || ''}
            absolute
            z-[60]
            mt-[8px] 2K:mt-8 4K:mt-12 8K:mt-20
          `}
          content={
            <DropDownList
              currentValue={selectedOption}
              options={options}
              optionClassName={props.style?.option}
              selectedIcon={selectedIcon}
              selectedIconPosition={selectedIconPosition}
              containerClass={`${style?.listContainer || ''}`}
              selectValue={retreiveSelection}
              getStringValue={getStringValue}
            />
          }
        />
      )}
    </div>
  );
};

export default DropDown;
