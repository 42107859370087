import { IconNames } from '@fe-monorepo/helper';
import useMobileDetect from '@fe-web/hooks/useMobileDetect';
import { useRef, useState } from 'react';

import useOutsideClick from '../../../../../app/hooks/useOutsideClick';
import ResponsiveIcon from '../../../Atoms/Icon/ResponsiveIcon';
import ToolTip from '../../../Atoms/ToolTip';
import Label from '../InputField/Components/Label';
import DropDownList from './Components/DropDownList';

interface Props<T> {
  style?: {
    container: string;
    text: string;
    icon?: string;
    baseHeight?: number;
    baseWidth?: number;
  };

  selectedOption: T;
  selectedIconPosition: 'start' | 'end';
  options: T[];
  selectedLabel?: JSX.Element | null;
  label?: string;
  retreiveSelection: (option: T) => void;
  getStringValue: (option: T) => string;
  disabled?: boolean;
  hiddenIndices?: number[];
  bgColor?: string;
  hasBGColor?: boolean;
}

const DropDown = <T extends { id: number | string }>(props: Props<T>) => {
  const {
    style,
    selectedOption,
    selectedIconPosition,
    selectedLabel,
    options,
    hiddenIndices,
    getStringValue,
    retreiveSelection,
    disabled,
    bgColor,
    hasBGColor,
  } = props;

  const ref = useRef<HTMLDivElement>(null);
  const [isToolTipOn, setToolTipOn] = useState<boolean>(false);

  const toggleToolTip = () => !disabled && setToolTipOn(value => !value);

  const elementRef = useOutsideClick(() => setToolTipOn(false));
  const isMobile = useMobileDetect();

  elementRef.current = ref.current;

  return (
    <div ref={ref} onClick={toggleToolTip}>
      <div
        className={`
                    relative
                    flex items-center
                    cursor-pointer
                    select-none
                    gap-[6px] 4xl:gap-[10.66px] 5xl:gap-16 8xl:gap-32
                    ${isToolTipOn && '!border-[#E95F2A]'}
                    ${style?.container}
                `}
      >
        <Label text={props.label || ''} valueLength={getStringValue(selectedOption).length} isFocused={isToolTipOn} />
        <p
          className={`
                        h-full
                        ${style?.text}
                    `}
        >
          {selectedLabel ? selectedLabel : getStringValue(selectedOption)}
        </p>

        <ResponsiveIcon
          className={`
                        z-[1]
                        justify-self-end
                        cursor-pointer
                        fill-secondary  ${isToolTipOn && 'rotate-180 fill-sunset'}
                        ${style?.icon}
                    `}
          name={IconNames.chevron}
          baseHeight={style?.baseHeight || 24}
          baseWidth={style?.baseWidth || 24}
        />
      </div>

      {/* ltr:left-[-60px] rtl:right-[60px] */}
      {isToolTipOn && options?.length > 0 && (
        <ToolTip
          className={`
                    absolute
                    z-[60]
                    mt-[8px] 2K:mt-8 4K:mt-12 8K:mt-20
                    ${isMobile ? 'w-[20rem]' : ''}`}
          content={
            <DropDownList
              currentValue={selectedOption}
              options={options}
              selectedIconPosition={selectedIconPosition}
              selectValue={retreiveSelection}
              getStringValue={getStringValue}
              hiddenIndices={hiddenIndices}
              bgColor={bgColor}
              hasBGColor={hasBGColor}
            />
          }
        />
      )}
    </div>
  );
};

export default DropDown;
