import { useGeo, useTranslate } from '@fe-monorepo/hooks';
import { CurrencyData } from '@fe-monorepo/models';
import { setCountry, setIsUserSettingsOpen } from '@fe-monorepo/store';
import useHeaderState from '@fe-web/hooks/useHeaderState';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import DropDown from '../../components/Molecules/InputFields/DropDownv2/DropDown';
import usePageLayout from '../../hooks/usePageLayout';

type OptionSort = { id: string; label: string };

interface Props {
  currencyWeb: {
    selectedCurrency: string;
    currencyList: CurrencyData[];
    selectCurrency: (code: string) => void;
  };
}
const ShopSettingsMenu = (props: Props) => {
  const { t } = useTranslation();
  const { translate } = useTranslate();
  const { currencyWeb } = props;
  const dispatch = useDispatch();
  const { dir } = useHeaderState();
  const { getAllCountries, countryData } = useGeo();
  const language = usePageLayout();
  const [countrySort, setCountrySort] = useState<OptionSort[]>([]);

  //create a useEffect to check if the countryData is empty or not
  useEffect(() => {
    getAllCountries();
  }, []);

  //create a useEffect that will set the setCountrySort if the countryData is not empty
  useEffect(() => {
    if (countryData) {
      setCountrySort(
        countryData.map((country: any) => {
          return { id: country.country_code_iso_2, label: country.name_en };
        }),
      );
    }
  }, [countryData]);

  // const currencyWeb = useCurrencyWeb();

  const currencySort2: OptionSort[] = [
    { id: 'eu', label: 'EUR (Euro)' },
    { id: 'ar', label: 'SAR (Saudi Riyal)' },
    { id: 'us', label: 'USD (American Dollar)' },
  ];

  // const currencySort: OptionSort[] = [
  //     { id: 'sar', label: 'SAR Saudi Riyal' },
  //     { id: 'aed', label: 'AED Emirates Dirham' },
  //     { id: 'bhd', label: 'BHD Bahraini Dinar' },
  //     { id: 'kwd', label: 'KWD Kuwaiti Dinar' },
  //     { id: 'omr', label: 'OMR Omani Riyal' },
  //     { id: 'usd', label: 'USD American Dollar' },
  //     { id: 'eur', label: 'EUR Euro' },
  // ];

  //create a useEffect that will set the selectedSort to the first index of the countrySort
  useEffect(() => {
    setSelectedSort(countrySort[148]);
  }, [countrySort]);

  const [selectedSort, setSelectedSort] = useState<OptionSort>(countrySort[1]);
  const [currencySort, setCurrencySort] = useState<OptionSort[]>([]);
  const [selectedCurrency, setSelectedCurrency] = useState<OptionSort>({ id: '', label: '' });

  useEffect(() => {
    const currencyList = currencyWeb.currencyList;

    if (currencyList.length > 0) {
      const newCurrencyList: OptionSort[] = currencyList.map(currency => {
        return {
          id: currency.currency_code,
          label: currency[`currency_name_${language.language}`],
        };
      });

      setCurrencySort(newCurrencyList);
    }
  }, [currencyWeb.currencyList, language.language]);

  useEffect(() => {
    if (currencySort.length) {
      const selectedCurrency = currencySort.find(currency => currency.id === currencyWeb.selectedCurrency);

      if (selectedCurrency) {
        setSelectedCurrency(selectedCurrency);
      }
    }
  }, [currencySort, currencyWeb.selectedCurrency]);

  //create a type script function to handleSubmit
  const handleSubmit = () => {
    // if (selectedSort.id === 'en') {
    //     handleLanguageToggle('en');
    // } else if (selectedSort.id === 'ar') {
    //     handleLanguageToggle('ar');
    // } else {
    //     handleLanguageToggle('en');
    // }

    if (selectedCurrency && selectedCurrency.id) {
      currencyWeb.selectCurrency(selectedCurrency.id);
      // localStorage.setItem('selectedCurrency', selectedCurrency.id);
      // dispatch(setShopCurrency(selectedCurrency.id));
      localStorage.setItem('selectedCountry', selectedSort.id);

      dispatch(setCountry(selectedSort.id));
      // updateUserPreference({ preferred_country: selectedSort.id.toLocaleLowerCase(), preferred_currency: selectedCurrency.id });
      dispatch(setIsUserSettingsOpen(false));
    }
  };

  return (
    <div className="flex justify-center items-center h-[20rem] xl:h-[4.75rem] lg:h-[15rem] md:h-[10rem] sm:h-[10rem] 2K:h-[6rem] w-full bg-[#351362] z-[5000] relative">
      <div className="flex flex-col sm:flex-col xl:flex-row gap-[1rem] lg:gap-[2rem] md:gap-[1rem] sm:gap-[1rem] xl:gap-[2rem] justify-center items-center bg-[#351362] mx-[7.5rem] relative">
        {/* div for the initial text */}
        <div>
          <div className="text-[color:var(--white-white-100,#FFF)] slashed-zero text-sm not-italic font-regular leading- 2K:text-subtitle 4K:text-bigTitle 8K:text-LPTitle w-full">
            <p className="font-medium">{translate('shop_your_location')}</p>
            <p>{translate('shop_confirm_location')}</p>
          </div>
        </div>
        {/* div for the drop down */}
        <div className="flex justify-center items-center gap-[1.5rem] flex-col sm:flex-row md:flex-row lg:flex-row xl:flex-row 2xl:flex-row 2xl:flex 2xl:justify-center 2xl:items-center 2xl:gap-[1.5rem]">
          <div>
            {/* Country */}
            <DropDown<OptionSort>
              style={{
                container: `
                justify-between
                
                py-[6px] 2K:py-[10.66px] 4K:py-16 8K:py-32
                bg-[#351362]
                border-solid border-b-[0.063rem] border-white/20 hover:border-[#E95F2A]
              `,

                text: `
                relative
                font-regular
                text-white
                text-fs-body-large
                flex flex-col
                justify-center
                gap-[8px]
                min-w-[172px]
                4K:w-[465px]
                8K:w-[880px]
                items-start
                z-[10000]
              `,

                icon: `
                !fill-white
              `,
              }}
              selectedOption={selectedSort}
              selectedIconPosition="start"
              options={countrySort}
              getStringValue={(option: OptionSort) => t(option?.label)}
              retreiveSelection={(option: OptionSort) => setSelectedSort(option)}
            />
          </div>
          <div>
            {/* currency */}
            <DropDown<OptionSort>
              style={{
                container: `
                justify-between

                
                py-[6px] 2K:py-[10.66px] 4K:py-16 8K:py-32
                bg-[#351362]
                border-solid border-b-[0.063rem] border-white/20 hover:border-[#E95F2A]
              `,

                text: `                
                relative
                font-regular
                text-white
                text-fs-body-large
                flex flex-col
                justify-center
                gap-[8px]
                min-w-[172px]
                4K:w-[465px]
                8K:w-[880px]
                items-start
                z-[10000]
              `,

                icon: `
                !fill-white
              `,
              }}
              selectedOption={selectedCurrency}
              selectedIconPosition="start"
              options={currencySort}
              getStringValue={(option: OptionSort) => t(option.label)}
              retreiveSelection={(option: OptionSort) => setSelectedCurrency(option)}
            />
          </div>
          <div>
            {/* button */}
            <button
              type="submit"
              className={`border-solid border-[0.063rem] rounded-[0.175rem] border-sunset button-animation-${dir}
                            text-sunsetText items-end ${
                              dir === `ltr` ? `after:bg-transparent before:bg-sunset` : `before:bg-[#351362] after:bg-sunset`
                            } hover:text-white100 rounded-[0.188rem]`}
              onClick={() => {
                handleSubmit();
              }}
            >
              <p className="slashed-zero text-fs-body not-italic font-medium mx-[1.5rem] my-[0.5rem]">{translate('shop_confirm')}</p>
            </button>
          </div>
        </div>
        {/* div for the close button */}
        <div className="cursor-pointer" onClick={() => dispatch(setIsUserSettingsOpen(false))}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-[20px] 2K:h-[32px] 4K:h-[48px] 8K:h-[96px]" viewBox="0 0 20 20" fill="none">
            <path
              d="M15.8346 5.3415L14.6596 4.1665L10.0013 8.82484L5.34297 4.1665L4.16797 5.3415L8.8263 9.99984L4.16797 14.6582L5.34297 15.8332L10.0013 11.1748L14.6596 15.8332L15.8346 14.6582L11.1763 9.99984L15.8346 5.3415Z"
              fill="white"
              fill-opacity="0.7"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default ShopSettingsMenu;
