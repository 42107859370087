import { ReactNode } from 'react';

// typescript interface for children props
interface MiniCardContainerProps {
    children: ReactNode;
}

const MiniCardContainer = ({ children }: MiniCardContainerProps) => {
    return (
        // <div className="w-[11.25rem] h-[12.3125rem] bg-gray-100 relative flex justify-center items-center shrink-0 4xl:w-[15rem] 4xl:h-[16rem] 5xl:w-[20rem] 5xl:h-[21rem] 8xl:w-[50rem] 8xl:h-[51rem]">
        <div className="w-[11.25rem] h-[12.3125rem] border-[0.063rem] border-secondary/20 rounded-[0.25rem] relative flex justify-center items-center shrink-0">
            {children}
        </div>
    );
};

export default MiniCardContainer;
