import ProductTag from "./ProductTag"

const BadgeTag = (props: { className?: string, text?: string | null }) =>
{
  return (
    <ProductTag
      className={props.className}
      text={props.text}
    />
  )
}


export default BadgeTag;
