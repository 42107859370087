import Bottom from '../components/Layouts/Bottom';
import CreateContentSidebar from '../components/Layouts/Sidebar/Content';
import CreatePostStepTwo from '../components/Post/CreatePostStepTwo';

const CreateContentStepTwo = () => {
  return (
    <>
      <div className="full-sec">
        <div className="left-sec">
          <CreateContentSidebar />
        </div>

        <div className="right-sec creat-right-sec">
          <CreatePostStepTwo />
        </div>
      </div>
      <Bottom />
    </>
  );
};

export default CreateContentStepTwo;
