import { IMAGE_ALT_LABELS, TxKeyPath } from '@fe-monorepo/helper';
import { useTranslate, useUserProfile } from '@fe-monorepo/hooks';
import { OrderQueryResponse, ShopWishlistInput, WishlistData, WishlistMutationResponse } from '@fe-monorepo/models';
import WishList from '@fe-web/Atoms/Favorite/WishListFavorite';
import { useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from '../../../app.routes.enum';
import MiniCardContainer from './MiniCardContainer';

interface MiniCardProps {
  image: string;
  productId: string;
  sectionId?: string;
  productName: string;
  brand: string;
  wishlistData: WishlistData[] | undefined;
  addToWishList: (shopWishlistInput: ShopWishlistInput) => Promise<WishlistMutationResponse | null | undefined>;
  removeToWishList: (shopWishlistInput: ShopWishlistInput) => Promise<WishlistMutationResponse | null | undefined>;
  getAllWishlist: (getAllWishlistInput: { last_cursor: string; direction: string }) => Promise<OrderQueryResponse | undefined>;
  checkIsSuccess?: (errorMessage: string) => void;
}

const MiniCard = ({
  image,
  productId,
  sectionId,
  checkIsSuccess,
  wishlistData,
  getAllWishlist,
  addToWishList,
  removeToWishList,
  productName,
  brand,
}: MiniCardProps) => {
  const navigate = useNavigate();
  const { translate } = useTranslate();
  const { isLoggedIn } = useUserProfile();

  useEffect(() => {
    isLoggedIn && getAllWishlist({ direction: '', last_cursor: '' });
  }, [isLoggedIn]);

  const isWishList = wishlistData ? (wishlistData.find(item => item.product_code === productId) ? true : false) : false;

  return (
    <MiniCardContainer>
      <div
        className="flex justify-center items-center w-full h-full p-[1rem]"
        onClick={() => navigate(`${AppRoutes.shop}/${sectionId}/${productId}`)}
      >
        <WishList
          isWishlist={isWishList}
          product_code={productId}
          checkIsSuccess={checkIsSuccess}
          addToWishList={addToWishList}
          removeToWishList={removeToWishList}
          getAllWishlist={getAllWishlist}
          productName={productName}
          brand={brand}
        />
        <LazyLoadImage
          src={image}
          alt={translate(IMAGE_ALT_LABELS.recently_viewed_image as TxKeyPath) || ''}
          className="object-cover w-4/5 h-4/5"
        />
      </div>
    </MiniCardContainer>
  );
};

export default MiniCard;
