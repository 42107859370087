import { motion } from 'framer-motion';
import { t } from 'i18next';
import { useState } from 'react';

type TabID = number | string;

export type TabType<T extends TabID> = {
  id: T;
  label: string;
};

export interface TabBarProps<T extends TabID> {
  tabs: TabType<T>[];
  activeTab: T;
  selectTab: (id: T) => void;
}

const Tab = (props: { label: string; isSelected: boolean; selectTab: () => void }) => {
  const { label, isSelected, selectTab } = props;

  const [isHover, setHover] = useState<boolean>(false);

  return (
    <motion.li
      className={`
          relative
          group h-full cursor-pointer
          flex md:items-center
          border-transparent

          px-2 2K:px-[3.55px] 4K:px-[5.33px] 8K:px-[10.66px]

          pb-8 2K:pb-[14.22px] 4K:pb-[21.33px] 8K:pb-[42.66px]
          ${isSelected || isHover ? 'border-solid border-y-secondary' : ''}
      `}
      onClick={selectTab}
      onHoverStart={() => setHover(true)}
      onHoverEnd={() => setHover(false)}
    >
      <p
        className={`
          relative
          sm:h-fit
          whitespace-nowrap
          ${isSelected ? 'text-secondary' : ` ${isHover ? 'text-secondary' : 'text-secondary/30'}`}
        `}
      >
        {t(label?.replace('(0)', '') ?? '')}
      </p>

      <motion.div
        className={`
          absolute
          w-full
          grow
          bottom-0

          bg-secondary
          h-2 2K:h-[3.55px] 4K:h-[5.33px] 8K:h-[10.66px]
        `}
        initial={{ width: isSelected || isHover ? '100%' : '0%' }}
        animate={{ width: isSelected || isHover ? '100%' : '0%' }}
        transition={{ duration: 0.375 }}
      />
    </motion.li>
  );
};

const RegularTabBar = <T extends TabID>(props: { classname?: string; info: TabBarProps<T> }) => {
  const { tabs, activeTab, selectTab } = props.info;
  return (
    <div className={`relative flex flex-col flex-1 justify-end items-center `}>
      <ul
        className={`
          flex w-full list-none

          overflow-x-scroll
          relative items-end
          responsive-text-body
          border-transparent
          font-regular
          gap-16 2K:gap-[28px] 4K:gap-[42px] 8K:gap-81

          ${props.classname}
        `}
      >
        {tabs.map(tab => (
          <Tab key={tab.id} label={tab.label} isSelected={activeTab === tab.id} selectTab={() => selectTab(tab.id)} />
        ))}
      </ul>

      <div className={`absolute w-full ${props.classname} overflow-hidden`}>
        <div
          className={`
            relative
            min-w-screen SD:w-full
            grow
            bottom-0
            bg-secondary/20
            h-[1px] 2K:h-[1.77px] 4K:h-[2.66px] 8K:h-[5.33px]
          `}
        />
      </div>
    </div>
  );
};

export default RegularTabBar;
