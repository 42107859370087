import { useState } from 'react';

interface imageProps {
    img: any;
    divStyle: string;
    imgStyle: string;
    children?: JSX.Element;
    onClick?: () => void;
    fallback?: JSX.Element;
    alt?: string;
    onLoad?: (isLoad: boolean) => void;
}

const Image = (props: imageProps) => {
    const { fallback = null } = props;
    const [isBroken, setIsBroken] = useState(false);
    const src = props?.img?.name ? URL.createObjectURL(props?.img) : props?.img;

    function handleError() {
        setIsBroken(true);
    }

    if (!!fallback && (isBroken || !src)) {
        return fallback;
    }

    return (
        <div className={`${props?.divStyle}`}>
            {props?.children}
            <img
                src={src}
                className={`${props?.imgStyle}`}
                onClick={() => {
                    if (props.onClick) {
                        props.onClick();
                    }
                }}
                onError={handleError}
                alt={props.alt}
                onLoad={() => {
                    if (props.onLoad) {
                        props.onLoad(true);
                    }
                }}
            />
        </div>
    );
};

export default Image;
