import { useMutation } from '@apollo/client';
import { CONVERSATION_MUTATION } from '@fe-monorepo/data-access';

interface TokenData {
    token: string;
}

interface GenerateTokenResponse {
    commGetToken: {
        is_successful: boolean;
        error_msg: string;
        error_code: string;
        data: TokenData;
    };
}

export const useConnect = () => {
    const [generateTokenGql, { data: generateTokenData }] = useMutation<GenerateTokenResponse>(CONVERSATION_MUTATION.commGetToken, {
        errorPolicy: 'all',
    });

    const generateZegoToken = async () => {
        const { data } = await generateTokenGql();
        return data?.commGetToken;
    };

    return {
        generateZegoToken,
    };
};
