import { ContentModel } from '@fe-monorepo/models';
import { RootState } from '@fe-monorepo/store';
import Bit from '@fe-web/Atoms/Bit/Bit';
import LoadMore from '@fe-web/Atoms/Load/LoadMore';
import { UserVideo } from '@fe-web/types/bitTypes';
import { getLocalizedBitDetails } from 'apps/fe-web/src/lib/helper';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from '../../../app.routes.enum';
import { SearchTab } from '../../Search';
import SearchSectionResult from '../Components/SearchSectionResult';

type SearchBitResultProps = {
  //   bitData?: SearchData<BitsModel> ;
  bitData?: UserVideo[];
  isLoading: boolean;
  tabData?: [ContentModel];
  selectedTab: string;
  loadMore: () => void;
  setSelectedTab: (key: SearchTab) => void;
  totalBitsVideo: number;
};

const SearchBitResult = (props: SearchBitResultProps) => {
  const prefs = useSelector((state: RootState) => state.app);

  const language = useSelector((state: RootState) => state.app.language);

  const dir = prefs?.isRTL ? 'rtl' : 'ltr';
  const isBitsTab = props?.selectedTab === 'bit';

  const navigate = useNavigate();

  const bitsToShow = props.selectedTab === 'all' ? props.bitData?.slice(0, 6) : props.bitData;

  const navigateToContent = (videoId: string, contentType: string) => {
    navigate(`${AppRoutes.bitsPlay}/content/${videoId}?contentType=${contentType}`);
  };

  const SectionData = () => (
    <>
      <div className="grid grid-cols-12 gap-y-[2rem] gap-x-[1rem] py-[0.5rem]">
        {bitsToShow?.map(bit => {
          const { title, description } = getLocalizedBitDetails(language, bit);

          return (
            <div key={bit._id} className="col-span-6 lg:col-span-2">
              <Bit
                img={bit.thumbnailUrl}
                count={bit.viewsCount}
                likeCount={bit.likeCount}
                title={title}
                description={description}
                status={bit.status}
                userAvatarUrl={bit.user.profileImageUrl}
                username={bit.user.userName}
                showPlayBadge
                showHeartBadge
                showUserInfo
                onClick={() => navigateToContent(bit._id, bit.contentType)}
              />
            </div>
          );
        })}
      </div>

      {isBitsTab && <LoadMore isLoading={props?.isLoading} loadMore={props?.loadMore} />}
    </>
  );

  return (
    <SearchSectionResult
      dir={dir}
      selectedTab={props.selectedTab}
      isLoading={props.isLoading}
      sectionTitle={t('top_tab_bits')}
      totalResults={props.totalBitsVideo}
      SectionData={<SectionData />}
      selectTab={() => props?.setSelectedTab('bit')}
    />
  );
};

export default SearchBitResult;
