import { IconNames } from '@fe-monorepo/helper';
import { useTournament, useTranslate } from '@fe-monorepo/hooks';
import { GamesResponse } from '@fe-monorepo/models';
import { RootState } from '@fe-monorepo/store';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import useOutsideClick from '@fe-web/hooks/useOutsideClick';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

interface Props {
  setGame: (val: string) => void;
  setSelectedGame: (val: string) => void;
  game?: string;
  selectedGame?: string;
}

const Search = (props: Props) => {
  const { translate } = useTranslate();
  const prefs = useSelector((state: RootState) => state.app);
  const [value, setValue] = useState<string>(props.selectedGame || '');
  const [isTooltipOn, setIsTooltipOn] = useState<boolean>(false);
  const [gameData, setGameData] = useState<GamesResponse[]>();
  const [gameList, setGameList] = useState<any[]>([]);
  const { getAllGames, games, getPlatform, platform } = useTournament();

  const elementRef = useOutsideClick(() => {
    setIsTooltipOn(false);
  });

  // filters the game results based on the entered value of the search bar
  const filterResult = gameData?.filter(game => {
    if (prefs.language === 'ar') {
      return value && game.name_ar.toLocaleLowerCase().includes(value.toLowerCase());
    } else return value && game.name_en.toLocaleLowerCase().includes(value.toLowerCase());
  });

  // gets the games list
  useEffect(() => {
    getAllGames();
  }, []);

  // sets the selected game if user decided edit their matchmaking preference
  useEffect(() => {
    setGameData(games?.getAllGames.data);
  }, [games]);

  useEffect(() => {
    gameData && setGameList(gameData);
  }, [gameData]);

  useEffect(() => {
    if (gameData) {
      if (value) {
        filterResult && setGameList(filterResult);
      } else setGameList(gameData);
    }
    if (!value) {
      props.setGame('');
      props.setSelectedGame('');
    }
  }, [value]);

  return (
    <div className="flex flex-col gap-[1rem] relative">
      <h2 className="font-medium text-secondary text-fs-body-large">{translate('matchmaking.section_titles.game_type')}</h2>
      <div className="relative" ref={ref => (elementRef.current = ref)}>
        <div
          className={`
                bg-secondary/10

                rounded-[4px] 4xl:rounded-[7.11px] 5xl:rounded-[10.66px] 8xl:rounded-[21.33px]
                flex items-center m-0

                py-8 4xl:py-[14px] 5xl:py-22 8xl:py-[42.66px]
                px-12 4xl:px-22 5xl:px-32 8xl:px-64
                gap-12 4xl:gap-22 5xl:gap-32 8xl:gap-64
                mix-blend-normal w-full`}
        >
          <div
            className="
                    flex w-full items-center
                    gap-12 4xl:gap-22 5xl:gap-32 8xl:gap-64

                    text-body 4xl:text-subtitle 5xl:text-bigTitle 8xl:text-LPTitle
                    "
          >
            <ResponsiveIcon
              className={`
                        cursor-pointer relative fill-secondary/50 mix-blend-normal
                        px-2 4xl:px-[3.55px] 5xl:px-[5.33px] 8xl:px-[10.66px]`}
              name={IconNames.search1}
              baseWidth={20}
              baseHeight={20}
            />

            <input
              value={value}
              onChange={({ target }) => setValue(target?.value)}
              className="
                        border-none bg-transparent
                        rounded-none
                        focus:outline-none
                        flex-1

                        w-full text-secondary
                        mix-blend-normal"
              placeholder={translate('play.matchmaking.search') || ''}
              autoFocus={false}
              onClick={() => {
                setIsTooltipOn(true);
              }}
            />
            {value && (
              <span
                className="
                            animate__animated
                            animate__fadeIn
                            text-btn-primary
                            cursor-pointer
                        "
                onClick={() => {
                  setValue('');
                  setIsTooltipOn(false);
                  props.setGame('');
                  props.setSelectedGame('');
                }}
              >
                {translate('common_clear')}
              </span>
            )}
          </div>
        </div>
        {isTooltipOn && (
          <div className="flex flex-col bg-primary absolute top-[2.8125rem] w-full max-h-[9rem] border-[0.0625rem] rounded-sm border-zinc-800-gray-200 overflow-y-scroll gap-[0.5rem]">
            {gameList?.map((data, index) => {
              return (
                <button
                  className="flex justify-start px-[1rem] py-[0.5rem] text-secondary font-regular text-fs-body hover:text-sunset hover:bg-sunset/[0.1]"
                  onClick={() => {
                    setValue(data.name_en);
                    setIsTooltipOn(false);
                    props.setGame(data.game_code);
                    props.setSelectedGame(data.name_en);
                  }}
                  key={index}
                >
                  {data.name_en}
                </button>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Search;
