import { IconNames } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import Icon from '@fe-web/Atoms/Icon/Icon';
import PopoverAbstract from '@fe-web/Molecules/Popover/PopoverAbstract';
import { ReactNode } from 'react';

interface MoreOptionsProps {
  onClickMoreOption?: () => void;
  isIconDot?: boolean;
  cta?: ReactNode;
  onDelete: () => void;
}

const BitActions = ({ onClickMoreOption, onDelete, isIconDot = false, cta }: MoreOptionsProps) => {
  const toggleMore = () => {};
  const { translate } = useTranslate();

  return (
    <PopoverAbstract
      onClickMoreOption={() => onClickMoreOption && onClickMoreOption()}
      isIconDot={isIconDot}
      isOpenCall={toggleMore}
      position={['bottom', 'right']}
      cta={cta}
      containerClassName="flex flex-row gap-[0.25rem] max-w-full"
    >
      <div className="py-[0.5rem] inline-flex flex-col gap-[0.25rem]">
        <div
          id="delete-bit"
          className="
            flex gap-[0.25rem]
            items-center
            text-secondary
            py-[0.5rem] px-[1rem]
            cursor-pointer hover:text-sunset hover:bg-sunset/10
            transition-all"
          onClick={e => {
            e.stopPropagation();
            onDelete();
          }}
        >
          <Icon name={IconNames.deleteOutline} iconClasses="fill-red" width={18} height={18} />
          <p className="font-regular text-fs-body text-red">{translate('quickplay.btn-delete-bits')}</p>
        </div>
      </div>
    </PopoverAbstract>
  );
};
export default BitActions;
