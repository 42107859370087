import { IconNames } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import Button from '@fe-web/Atoms/Buttons/Button';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import Image from '@fe-web/Atoms/Media/Image';
import usePageLayout from '@fe-web/hooks/usePageLayout';

interface productDetailsProps {
  voucher_code: string;
  pin_code: string;
  validity?: Date;
  product_name?: string;
  price?: string;
  media?: string;
  openmodal?: boolean;
  isRTL?: boolean;
  closeModal: () => void;
}

const ProductDetails = ({ voucher_code, pin_code, validity, product_name, price, media, isRTL, closeModal }: productDetailsProps) => {
  const { btnClass } = usePageLayout();
  const { translate } = useTranslate();
  const copyText = (isPinCode: boolean) => {
    navigator.clipboard.writeText(isPinCode ? pin_code : voucher_code);
  };

  return (
    <div className="absolute flex p-[1.5rem] flex-col justify-center items-end gap-[1.5rem] bg-primary rounded-[.5rem] top-[3.5rem] w-[39.75rem] SD:w-[30rem] mdxs:w-[7rem] mdMaxxS:w-[25rem] border border-neutral-300-zinc-700">
      <div className="flex flex-start gap-[.625rem] self-stretch">
        <p className="w-full text-fs-subtitle font-medium text-secondary">{translate('shop.cart.cart_items_type.digitalproductdetail')}</p>
        <ResponsiveIcon
          name={IconNames.close}
          baseWidth={10}
          baseHeight={10}
          className={`absolute top-[1.7rem] ${isRTL ? 'left-[2rem]' : 'right-[2rem]'} fill-secondary/50 pt-[.4rem] hover:cursor-pointer`}
          onClick={closeModal}
        />
      </div>
      <div className="flex flex-col py-[1.5rem] justify-center items-center gap-[.625rem] self-stretch">
        <Image
          img={media}
          divStyle="flex bg-secondary/[0.06] rounded-[3px] h-[16.5rem]
                    "
          imgStyle="w-full h-full object-contain bg-white"
        />
      </div>
      <div className="flex flex-col justify-center items-start gap-[1rem] self-stretch">
        <div className="flex w-9/12 gap-[.25rem] text-fs-body-large font-medium leading-[1.5rem] text-secondary-100">
          <p className="truncate ">{product_name}</p>
          <p className="whitespace-nowrap">{price}</p>
        </div>
        {/* Not specified on the US*/}
        {/* <div className="flex flex-col items-start gap-[.25rem] flex-[1_0_0%]">
                    <p className="text-black/50 text-fs-caption font-regular text-secondary/50">{translate('shop.cart.cart_items_type.validuntil')}</p>
                    <p className="text-fs-body font-regular text-secondary">{dateFormat(validity,'d mmm yyyy')}</p>
                </div> */}
        <div className="flex gap-[1.5rem] self-stretch">
          <div className="flex flex-col items-start gap-[.25rem] flex-[1_0_0%]">
            <p className="text-fs-caption font-regular text-secondary/50">{translate('shop.cart.cart_items_type.vouchercode')}</p>
            <div className="flex">
              <p className="text-fs-body font-regular pr-[.2rem] truncate text-secondary">{voucher_code}</p>
              {voucher_code ? (
                <ResponsiveIcon
                  name={IconNames.iconcopy}
                  baseWidth={20}
                  baseHeight={20}
                  className="w-[1.25rem] h-[1.25rem] shrink-0 hover:cursor-pointer"
                  iconClasses="fill-sunset"
                  onClick={() => copyText(false)}
                />
              ) : null}
            </div>
          </div>
          {/** 04-30-2023 Added the pin code **/}
          <div className="flex flex-col items-start gap-[.25rem] flex-[1_0_0%]">
            <p className="text-fs-caption font-regular text-secondary/50">{translate('shop.cart.cart_items_type.pincode')}</p>
            <div className="flex items-start gap-[.25rem] self-stretch">
              <p className="text-fs-body font-regular truncate text-secondary">{pin_code}</p>
              {pin_code && (
                <ResponsiveIcon
                  name={IconNames.iconcopy}
                  baseWidth={20}
                  baseHeight={20}
                  className="w-[1.25rem] h-[1.25rem] shrink-0 hover:cursor-pointer"
                  iconClasses="fill-sunset"
                  onClick={() => copyText(true)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end items-center gap-[1rem] self-stretch">
        <Button
          text={translate('common_ok')}
          style={`px-24 py-8 w-full text-base font-regular text-white100
                    `}
          normalStyle={btnClass}
          className="w-[18rem] mt-0 4xl:h-[71px] 5xl:h-[95.55px] 4xl:text-[28px] 5xl:text-[38.22px] 8xl:text-[85.33px] 8xl:h-[213.33px]"
          onClick={closeModal}
        />
      </div>
    </div>
  );
};

export default ProductDetails;
