import { ContentType, useMediaUpload, useReturnProduct, useToastMessage, useTranslate } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import Button from '@fe-web/Atoms/Buttons/Button';
import ToastMessage from '@fe-web/Atoms/ToastMessage';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import useHeaderState from '@fe-web/hooks/useHeaderState';
import useMobileDetect from '@fe-web/hooks/useMobileDetect';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import LoadingScreen from './LoadingScreen';
import { OptionSort2 } from './Model/InvoiceProduct';
import Photos from './Photos';
import './styles/ReturnsPhoto.css';

interface Props {
  returnData: { [key: string]: OptionSort2 };
  setReturnData: (value: { [key: string]: OptionSort2 }) => void;
  handleBackButton: () => void;
  handleContinue: (data: { [key: string]: OptionSort2 }) => void;
  returnNumber?: string;
  children: React.ReactNode;
}

type addtoFileParamsObject = {
  product_code: string;
  file: File | undefined;
  optionIndex: number;
  index: number;
};

const ReturnsPhoto = ({ returnData, setReturnData, handleBackButton, handleContinue, returnNumber, children }: Props) => {
  const isHeaderDark = useSelector((state: RootState) => state.app.headerColorChange);
  const { dir } = useHeaderState();
  const isRTL = useSelector((state: RootState) => state?.app.isRTL);
  const [isContinueButtonEnabled, setIsContinueButtonEnabled] = useState<boolean>(false);
  const { translate } = useTranslate();
  const isMobile = useMobileDetect();
  const [returnDataWithPhoto, setReturnDataWithPhoto] = useState<{ [key: string]: OptionSort2 }>({});
  const [isLoading, setIsLoading] = useState(false);
  const imageUpload = useMediaUpload();
  const { successToastMessage, errorToastMessage } = useToastMessage();
  const prevMediaLinksLengthRef = useRef(imageUpload.mediaLinks.length);
  const [fileParams, setFileParams] = useState<addtoFileParamsObject>({
    product_code: '',
    file: undefined,
    optionIndex: 0,
    index: 0,
  });
  const { createAddDataWithResult, addData, createUpdateDataWithResult } = useReturnProduct();

  useEffect(() => {
    const updatedReturnData = { ...returnData }; // Create a copy of returnData

    Object.entries(updatedReturnData).forEach(([key, product]) => {
      if (product.isSelected) {
        product.option = product.option.map(option => {
          if (option.imageFiles === undefined) {
            option.imageFiles = [undefined, undefined, undefined, undefined];
          }
          return option;
        });
      } else {
        delete updatedReturnData[key]; // Remove the key from updatedReturnData if isSelected is false
      }
    });
    setReturnDataWithPhoto(updatedReturnData);
  }, []);

  function getBaseUrl(urlString: string) {
    const url = new URL(urlString);
    return url.origin + url.pathname;
  }

  useEffect(() => {
    const updatedReturnData = _.cloneDeep(returnDataWithPhoto[fileParams.product_code]); // Create a copy of returnDataWithPhoto
    if (fileParams.file === undefined) return;
    const lastIndexImageURL = imageUpload.mediaLinks[imageUpload.mediaLinks.length - 1];
    if (imageUpload.mediaLinks.length > prevMediaLinksLengthRef.current) {
      const imageFiles = updatedReturnData.option[fileParams.optionIndex].imageFiles;
      setIsLoading(true);
      if (imageFiles) {
        const id = uuidv4();
        try {
          if (imageFiles[fileParams.index] !== undefined) {
            imageFiles[fileParams.index] = {
              id,
              file: fileParams.file,
              url: lastIndexImageURL,
            }; // Replace the file at the particular index
          } else {
            const undefinedIndex = imageFiles.findIndex(file => file === undefined);
            if (undefinedIndex !== -1) {
              imageFiles[undefinedIndex] = {
                id,
                file: fileParams.file,
                url: lastIndexImageURL,
              }; // Add a unique ID and URL to the file
            } else {
              console.error(`No available slot for the file in product option ${fileParams.optionIndex}.`);
            }
          }
          // If all values are not undefined anymore, add an undefined value at the end
          if (imageFiles.every(file => file !== undefined)) {
            imageFiles.push(undefined);
          }
        } catch (error) {
          console.error(error);
          errorToastMessage({
            message: <ToastMessage type="error">{translate('error.please_try_again')}</ToastMessage>,
          });
        }
      } else {
        console.error(`ImageFiles array not found in product option ${fileParams.optionIndex}.`);
      }
      setReturnDataWithPhoto(prevState => ({ ...prevState, [fileParams.product_code]: updatedReturnData }));
      setIsLoading(false);
    }
    // Update the previous length for the next render
    prevMediaLinksLengthRef.current = imageUpload.mediaLinks.length;
  }, [imageUpload.mediaLinks, isLoading]);

  function addFileToProduct(
    reset: boolean,
    product_code: string,
    file: File,
    optionIndex: number,
    index: number,
    trackingNumber: string | undefined,
  ) {
    if (file) {
      setIsLoading(true);
      setFileParams({
        product_code,
        file: file,
        optionIndex,
        index,
      });

      createAddDataWithResult({
        return_number: returnNumber as string,
        tracking_number: trackingNumber as string,
      })
        .then(result => {
          try {
            if (result.data?.omsReturnProdAdd?.is_successful === true) {
              const returnProductId = String(result.data?.omsReturnProdAdd.data?.return_product_id) || Date.now().toString();
              setIsLoading(true);
              imageUpload.getGeneratedLinks([{ file: file, path: '' }], returnProductId, ContentType.return);
            } else {
              errorToastMessage({
                message: <ToastMessage type="error">{result.data?.omsReturnProdAdd?.error_msg}</ToastMessage>,
              });
              setIsLoading(false);
            }
          } catch (error) {
            console.error('An error occurred during image upload:', error);
            errorToastMessage({
              message: <ToastMessage type="error">{translate('error.please_try_again')}</ToastMessage>,
            });
            setIsLoading(false);
          }
        })
        .catch(error => {
          console.error('An error occurred during image upload:', error);
          errorToastMessage({
            message: <ToastMessage type="error">{translate('error.please_try_again')}</ToastMessage>,
          });
          setIsLoading(false);
        });
    }
  }

  function deleteFileById(activate: boolean, product_code: string, optionIndex: number, fileId: string) {
    if (!activate) return;

    const updatedReturnData = { ...returnDataWithPhoto[product_code] };
    let imageFiles = updatedReturnData.option[optionIndex].imageFiles;

    if (imageFiles) {
      const fileIndex = imageFiles.findIndex(file => file !== undefined && file.id === fileId);

      if (fileIndex !== -1) {
        imageFiles[fileIndex] = undefined;
        // Filter out undefined values and push them to the end of the array
        const definedFiles = imageFiles.filter(file => file !== undefined);
        const undefinedFiles = imageFiles.filter(file => file === undefined);
        imageFiles = [...definedFiles, ...undefinedFiles];
        // If imageFiles length is greater than 4, remove the last index which is undefined
        if (imageFiles.length > 4) {
          imageFiles.pop();
        }
      } else {
        console.error(`File with ID ${fileId} not found in product option ${optionIndex}.`);
      }
    } else {
      console.error(`ImageFiles array not found in product option ${optionIndex}.`);
    }
    updatedReturnData.option[optionIndex].imageFiles = imageFiles;

    setReturnDataWithPhoto(prevState => ({ ...prevState, [product_code]: updatedReturnData }));
  }
  //create a a useEffect that will monitor the returnDataWithPhoto for each object keys check the quantity first, if quantity is 1 then check the imageFiles array of index 0 in option
  //check each image files if it has 4 not undefined values
  // if quantity is 2 then it will check imageFiles array for index 0 and index 1 on option object
  //if quantity is 3 then it will check imageFiles array for index 0 and index 1 and index 2 on option object
  // it will be dynamic based from the quantity
  // if all imageFiles has 4 not undefined values then enable the Continue button
  // if not then disable the Continue button
  useEffect(() => {
    const updatedReturnData = { ...returnDataWithPhoto };
    Object.entries(updatedReturnData).forEach(([key, product]) => {
      if (product.isSelected) {
        const imageFiles = product.option.map(option => {
          return option.imageFiles;
        });
        const count = imageFiles.filter(file => {
          // if file is undefined then it will return false
          if (file === undefined) {
            return false;
          }
          return file?.filter(file => file !== undefined).length >= 4;
        }).length;
        if (count === product.quantity) {
          setIsContinueButtonEnabled(true);
        } else {
          setIsContinueButtonEnabled(false);
        }
      }
    });
  }, [returnDataWithPhoto]);

  //create a useEffect that will call setReturnData and replace the returnData with returnDataWithPhoto
  useEffect(() => {
    setReturnData(returnDataWithPhoto);
  }, [returnDataWithPhoto]);

  useEffect(() => {
    mixpanelHelper.trackPageView(
      'ORDER DETAILS PAGE - RETURN ORDER PAGE - RETURN PRODUCT IMAGE UPLOAD PAGE',
      'my_orders_order_tab_odp_return_order_upload_images_page',
      window.location.pathname,
      document.referrer,
    );
  }, []);

  return (
    <div className={`SD:max-w-[43.125rem] col-span-2 inline-flex flex-col 2K:gap-[2.5rem]`}>
      <div className={`font-medium text-fs-subtitle text-static-secondary mdxs:mb-[2.5rem] SD:mb-[2.5rem] XGA:mb-[2.5rem] 2K:mb-[2.5rem] `}>
        {translate('shop.cart.returns_or_exchange.show_us_why')}
      </div>
      {/* ITEMS */}
      {Object.keys(returnDataWithPhoto).map(key => {
        const item = returnDataWithPhoto[key];

        return (
          item.isSelected &&
          [...Array(item.quantity)].map((_, index) => (
            <Photos
              key={index + key}
              imgUrl={item.imageURL}
              name={item.name}
              reason={item.option[index].label}
              imageFiles={item.option[index].imageFiles}
              code={key}
              index={index}
              deleteFileById={deleteFileById}
              addFileToProduct={addFileToProduct}
              cols={5}
              showLowerBorder={true}
              trackingNumber={item.option[index]?.tracking_number}
            />
            // <div className={`inline-flex flex-col gap-[1.5rem]`}>
            //     <div className={`inline-flex flex-row gap-[1rem]`}>
            //         <div className={``}>
            //             <Image
            //                 img={item.imageURL}
            //                 divStyle={`rounded-[3px] inline-flex justify-center items-center h-[6rem] w-[6rem] bg-neutral-900-white/5`}
            //                 imgStyle={``}
            //             />
            //         </div>
            //         <div className={`pt-[0.25rem] inline-flex flex-col gap-[1rem]`}>
            //             <div
            //                 className={`${isMobile ? 'ellipsis-multiline' : ''} font-medium text-fs-body-large text-secondary`}
            //             >
            //                 {item.name}
            //             </div>
            //             <div className={`font-regular text-fs-body text-secondary`}>
            //                 <span className={`font-medium`}>{translate('shop.cart.returns_or_exchange.reason')}: </span>
            //                 {item.option[index].label}
            //             </div>
            //         </div>
            //     </div>
            //     <div className={`SD:ml-[7rem] SD:mt-[-2.25rem] inline-flex flex-col gap-[1rem]`}>
            //         <div className={`grid SD:grid-cols-5 grid-cols-4 gap-x-[1rem] gap-y-[2rem]`}>
            //             {item.option[index].imageFiles?.map((fileInput, i) => {
            //                 return (
            //                     <div id={fileInput?.id} key={i + index}>
            //                         <FileInput
            //                             initFile={fileInput?.file}
            //                             key={i + index}
            //                             type={'image'}
            //                             buttonLabel={
            //                                 <ResponsiveIcon name={IconNames.uploadImageSunset} baseHeight={24} baseWidth={24} />
            //                             }
            //                             setValue={file => addFileToProduct(false, key, file, index, i)}
            //                             imgContainerStyle={`SD:!w-[5rem] SD:!h-[5rem] !w-[4.4844rem] !h-[4.75rem] !overflow-visible`}
            //                             imgStyle={`SD:!w-[5rem] SD:!h-[5rem] !w-[4.4844rem] !h-[4.75rem]`}
            //                             uploadContainerStyle={`SD:!w-[5rem] SD:!h-[5rem] !w-[4.4844rem] !h-[4.75rem]`}
            //                             displayContainerStyle={`relative`}
            //                             resetButtonStyle={`absolute text-white top-[-0.4375rem] right-[-0.4375rem] w-[1rem] h-[1rem] bg-black`}
            //                             iconStyle={`!fill-primary`}
            //                             showFileName={false}
            //                             resetIcon={IconNames.close1}
            //                             setReset={reset => {
            //                                 deleteFileById(reset, key, index, fileInput?.id);
            //                             }}
            //                         />
            //                     </div>
            //                 );
            //             })}
            //         </div>
            //         <div className={`font-regular text-fs-body-small text-zinc-600-400`}>
            //             {translate('shop.cart.returns_or_exchange.upload_minimum')}
            //         </div>
            //     </div>
            //     <hr className="w-full text-secondary/10 bg-secondary/10 border-[none] mb-[1.5rem]" />
            // </div>
          ))
        );
      })}
      <div className="mdxs:flex SD:justify-center SD:flex XGA:hidden 2K:hidden">{children}</div>
      {/* CONTINUE BUTTON */}
      <div className="mdxs:w-full SD:w-full 2K:w-[43rem] flex mdxs:flex-col gap-[1.25rem] justify-end mt-[2.5rem] mb-[20rem]">
        {/* Back Button Here */}
        <Button
          text={translate('shop.cart.returns_or_exchange.back')}
          style={`
                    mdxs:order-2 mdxs:w-full mdMaxxS:w-full SD:w-[7.75rem] text-btn-primary text-fs-body not-italic font-medium leading-6 px-[1.5rem] py-[0.5rem] rounded border border-[color:var(--brand-sunset,#E95F2A)] border-solid gap-[8px] z-1
                                  text-white100 text-sunsetText mb-[0px]
                                  xsMax:mb-[0px] items-end
                                  rounded-sm ${
                                    isHeaderDark
                                      ? dir === 'ltr'
                                        ? `after:bg-primary before:bg-sunset`
                                        : `before:bg-white100 after:bg-sunset`
                                      : `${dir === 'ltr' ? `after:bg-white100 before:bg-sunset` : `before:bg-white100 after:bg-sunset`}`
                                  }
                                  hover:text-white100 rounded-sm`}
          disabled={false}
          onClick={() => handleBackButton()}
        />

        {isContinueButtonEnabled && (
          <Button
            text={translate('shop.cart.returns_or_exchange.continue')}
            style={`flex items-center justify-center mdxs:w-full mdMaxxS:w-full text-fs-body not-italic font-medium leading-6 px-[1.5rem] py-[0.5rem] text-white100 border-[1px] border-transparent rounded-sm ${
              isHeaderDark
                ? dir === 'ltr'
                  ? `after:bg-sunset before:bg-white100`
                  : `before:bg-sunset after:bg-white100`
                : `${dir === 'ltr' ? `after:bg-sunset before:bg-white100` : `before:bg-sunset after:bg-white100`}
hover:-border[1px] hover:border-sunset`
            } hover:text-sunsetText rounded-sm`}
            disabled={false}
            onClick={() => handleContinue(returnDataWithPhoto)}
          />
        )}

        {!isContinueButtonEnabled && (
          <div className="mdxs:w-full cursor-not-allowed flex items-center justify-center text-fs-body not-italic font-medium leading-6 text-secondary/30 bg-secondary/10 py-[0.5rem] px-[1.5rem]">
            {translate('shop.cart.returns_or_exchange.continue')}
          </div>
        )}
      </div>
      <LoadingScreen isLoading={isLoading} />
    </div>
  );
};

export default ReturnsPhoto;
