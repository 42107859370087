import { AddAddressValues } from '../ShopMainAddAddress';
import AddAddressMapView from './AddAddressMapView';
import AddAddressShortAddress from './AddAddressShortAddress';
import AddAddressFullAddress from './AddAddressFullAddress';
import { DeliveryAddressModel, DeliveryAddressInput } from '@fe-monorepo/models';
import { GeocodeResult } from 'use-places-autocomplete';
import { MainAddAddressProps } from '../ShopMainAddAddress';

interface AddAddressViewSectionProps {
    chosenSection: AddAddressValues;
    funcHandleDataSavePerSection: (results: GeocodeResult[], lat: number, lng: number) => void;
    saveLocationDetails?: DeliveryAddressInput;
    mainAddAddressClassStyles?: MainAddAddressProps;
}

const AddAddressViewSection = (props: AddAddressViewSectionProps) => {
    const { chosenSection, funcHandleDataSavePerSection, saveLocationDetails, mainAddAddressClassStyles } = props;

    const AddAddressSections = {
        mapView: (
            <AddAddressMapView
                saveLocationDetails={saveLocationDetails}
                funcHandleDataSavePerSection={funcHandleDataSavePerSection}
                mainAddAddressClassStyles={mainAddAddressClassStyles}
            />
        ),
        // shortAddress: <AddAddressShortAddress saveLocationDetails={saveLocationDetails}  funcHandleDataSavePerSection={funcHandleDataSavePerSection}/>,
        fullAddress: (
            <AddAddressFullAddress saveLocationDetails={saveLocationDetails} funcHandleDataSavePerSection={funcHandleDataSavePerSection} />
        ),
    };

    return <div>{AddAddressSections[chosenSection]}</div>;
};

export default AddAddressViewSection;
