import { useTranslate, useUserProfile } from '@fe-monorepo/hooks';
import quickplay from 'quickplay-shorts-js';
import { useEffect, useState } from 'react';

import { useLoginModalContext } from '../../../../contexts/LoginModal';
import useAnalytics from '../../../../hooks/useAnalytics';

const authModule = new quickplay.QuickPlayAuthModule();
const userModule = new quickplay.QuickPlayUserModule();

function EmptyFeedFollowList({ followingItem, followingClicked }: any) {
  const [isFollowing, setIsFollowing] = useState(false);
  const { trackEvent } = useAnalytics();
  const { setShowLoginModal } = useLoginModalContext();
  const userData = JSON.parse(localStorage.getItem('userData') || '{}');
  const { translate } = useTranslate();
  const { isLoggedIn } = useUserProfile();

  const user = {
    followersCount: followingItem.followersCount,
    followingCount: followingItem.followingCount,
    fullName: followingItem.fullName,
    profileImageUrl: followingItem.profileImageUrl,
    userId: followingItem.userId,
    userName: followingItem.userName,
  };

  const isLoggedin = async () => {
    const accessToken = await new quickplay.QuickPlayAuthModule().getAccessToken();
    if (accessToken) {
      return true;
    } else {
      return false;
    }
  };
  const isUserLoggedIn: any = isLoggedin();

  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      let followingList: any = localStorage.getItem('following');
      followingList = JSON.parse(followingList);
      if (followingList.includes(followingItem.userId)) {
        setIsFollowing(true);
      } else {
        setIsFollowing(false);
      }
    }
  }, []);

  // Follow & Unfollow Handler //
  const handleFollowEvent = async (event: any, userId: any, followStatus: boolean) => {
    // trackEvent({
    //   event: isFollowing ? EVENTS.USER_UNFOLLOW : EVENTS.USER_FOLLOW,
    //   content: { user },
    // });
    try {
      if (!isLoggedIn) {
        setShowLoginModal(true);
        return;
      }
      const accessToken = await authModule.getAccessToken();
      if (!accessToken) {
        setShowLoginModal(true);
        return;
      }
      let followingList: any = localStorage.getItem('following');
      followingList = JSON.parse(followingList);
      // eslint-disable-next-line no-var
      var formData = {
        followingId: userId,
        isFollow: followStatus,
      };
      userModule.followUser(formData);
      if (followStatus) {
        followingList.push(userId);
        localStorage.setItem('following', JSON.stringify(followingList));
        setIsFollowing(true);
      } else {
        const newFollowing = followingList.filter((id: any) => id !== userId);
        localStorage.setItem('following', JSON.stringify(newFollowing));
        setIsFollowing(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <li className="emptyFeed-lists-item">
      <div className="userProfile">
        <img src={followingItem.profileImageUrl} alt={followingItem.fullName} />
        <div className="userProfile-name">
          <h4>{followingItem.fullName}</h4>
          <p>{followingItem.userName}</p>
        </div>
      </div>

      <div className="userProfile-row">
        <div className="userProfile-info">
          <div className="userProfile-info-item">
            {followingItem.videoCount} <span>{translate('quickplay.label-bits')}</span>
          </div>
          <div className="userProfile-info-divider"></div>
          <div className="userProfile-info-item">
            {followingItem.followersCount} <span>{translate('quickplay.label-followers')}</span>
          </div>
        </div>

        {userData?.userId !== followingItem.userId && !isFollowing && (
          <button
            className="userProfile-btn userProfile-follow"
            onClick={() => handleFollowEvent(followingItem, followingItem.userId, true)}
          >
            {translate('quickplay.label-follow')}
          </button>
        )}
        {userData?.userId !== followingItem.userId && isFollowing && (
          <p className="userProfile-btn userProfile-following">{translate('quickplay.label-following')}</p>
        )}
      </div>
    </li>
  );
}

export default EmptyFeedFollowList;
