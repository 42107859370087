import AuthImgDesktop from '../../assets/img/auth-logo-desktop.png';
import CustomModal from '../common/CustomModal/CustomModal';
import { useTranslate } from '@fe-monorepo/hooks';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

const LoginModal = ({
  show,
  handleClose,
}: {
  show: boolean;
  handleClose: () => void;
}) => {
  const { translate } = useTranslate();
  const { pathname } = useLocation();

  return (
    <CustomModal isOpen={show} close={handleClose} classes={'auth-modal'}>
      <img src={AuthImgDesktop} alt='Auth' className='auth-image' />
      <h2 className='modal-title'>{translate('quickplay.Stanger-text')}</h2>
      <p className='text'>
        {translate('quickplay.login-title1')} <br />{' '}
        {translate('quickplay.login-title2')}
      </p>
      <div className='button-group'>
        <button className='close-btn' onClick={handleClose}>
          {translate('quickplay.not-now')}
        </button>
        <Link
          to={`/auth/sign-in?redirect_url=${pathname}`}
          className='signIn-btn'
          onClick={handleClose}>
          {translate('quickplay.SIGNIN')}
        </Link>
      </div>
    </CustomModal>
  );
};

LoginModal.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default LoginModal;
