import { Logger } from '@fe-monorepo/helper';
import { useFirebase, useNotificationMessage, useRegisterDevice, useRemoveDevice, useTranslate } from '@fe-monorepo/hooks';
import {
  EventType,
  FirebaseSettingsModel,
  NotificationMessageData,
  NotificationMessageGetAllInput,
  NotificationMessageSendInput,
  NotificationMessageUpdateInput,
  RegisterDeviceParams,
  RemoveDeviceParams,
  ThemeConfigType,
} from '@fe-monorepo/models';
import {
  GENG_CONFIG_TYPE,
  PageErrorTypes,
  RootState,
  setFirebaseMessagingToken,
  setGenGConfigs,
  setIsEWCSectionHidden,
  setIsEWCStayTunedSectionHidden,
  setIsPasswordSettingsHidden,
  setMarketingConfig,
  setRedirectToEWC,
  setShowApplePay,
  setThemeConfig,
  useAppDispatch,
} from '@fe-monorepo/store';
import { toastWrapper } from '@fe-web/Atoms/Toast';
import { NotificationFilterType } from '@fe-web/types/notificationFilterTypes';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { getDatabase, onValue, ref } from 'firebase/database';
import { MessagePayload, NextFn, getToken, onMessage } from 'firebase/messaging';
import { getEnvironment } from 'libs/data-access/src/graphql/apiEnvironments';
import { isNil } from 'lodash';
import moment from 'moment';
import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useEffect, useRef, useState } from 'react';
import { browserName, engineName, isDesktop, osName, osVersion } from 'react-device-detect';
import { shallowEqual, useSelector } from 'react-redux';
import { v4 as uuidV4 } from 'uuid';

import { useAppProvider } from '../../app';
import { useFirebaseMessaging } from '../useFirebaseMessaging';

const NOTIFICATION_PAGE_SIZE = 50;

interface UseNotificationProvider {
  notificationList: NotificationMessageData[];
  getAllNotification: (details: NotificationMessageGetAllInput) => Promise<void>;
  updateNotification: (details: NotificationMessageUpdateInput) => Promise<any>;
  removeAllNotification: () => Promise<void>;
  removeNotification: (message_id: string) => Promise<any>;
  markReadAllNotification: () => Promise<void>;
  registerDeviceNotification: (params: RegisterDeviceParams) => Promise<any>;
  removeDeviceNotification: () => Promise<void>;
  sendNotification: (params: NotificationMessageSendInput) => Promise<any>;
  isNotificationEmpty?: boolean;
  setSelectedFilters?: Dispatch<SetStateAction<NotificationFilterType[]>>;
  selectedFilters?: NotificationFilterType[];
  setNotificationLength?: Dispatch<SetStateAction<number>>;
  notificationLength?: number;
  getAllNextList?: () => Promise<void>;
  nextCursor?: string;
  setFilterValues?: Dispatch<SetStateAction<SelectedFilters>>;
  filterValues?: SelectedFilters;
  firebaseToken?: string;
  setSavedStatus?: Dispatch<SetStateAction<NotificationFilterType[]>>;
  savedStatus?: NotificationFilterType[];
  setSavedFeature?: Dispatch<SetStateAction<NotificationFilterType[]>>;
  savedFeature?: NotificationFilterType[];
  setPeriodCode?: Dispatch<SetStateAction<string>>;
  periodCode?: string;
  setIsModalOpen?: Dispatch<SetStateAction<boolean>>;
  isModalOpen?: boolean;
}

const NotificationContext = createContext<UseNotificationProvider>({
  notificationList: [],
  getAllNotification: async () => void 0,
  updateNotification: async () => void 0,
  removeAllNotification: async () => void 0,
  removeNotification: async () => void 0,
  markReadAllNotification: async () => void 0,
  registerDeviceNotification: async () => void 0,
  removeDeviceNotification: async () => void 0,
  sendNotification: async () => void 0,
  getAllNextList: async () => void 0,
});

interface NotificationProviderProps {
  children: ReactNode;
}

interface SelectedFilters {
  is_read?: boolean;
  event_type?: string;
  event_group_type?: string;
  period_start?: number;
  period_end?: number;
}

export const NotificationProvider = ({ children }: NotificationProviderProps) => {
  const [isNotificationEmpty, setIsNotificationEmpty] = useState<boolean>(false);

  // State for the filter values
  const [selectedFilters, setSelectedFilters] = useState<NotificationFilterType[]>([]);
  const [savedStatus, setSavedStatus] = useState<NotificationFilterType[]>([]);
  const [savedFeature, setSavedFeature] = useState<NotificationFilterType[]>([]);
  const [filterValues, setFilterValues] = useState<SelectedFilters>({});

  const [periodCode, setPeriodCode] = useState<string>('none');

  // State for the unread notification number
  const [notificationLength, setNotificationLength] = useState<number>(0);

  const [nextCursor, setNextCursor] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  //Overall State for Notification
  const [notificationList, setNotificationList] = useState<NotificationMessageData[]>([]);

  const { translate } = useTranslate();

  const {
    notificationMessageGetAll,
    notificationMessageUpdate,
    notificationMessageRemoveAll,
    notificationMessageMarkReadAll,
    notificationMessageRemove,
    notificationMessageSend,
  } = useNotificationMessage();
  const { registerDevice } = useRegisterDevice();
  const { removeDevice } = useRemoveDevice();
  const { firebaseConfig } = getEnvironment();
  const [isInitialize, setIsInitialize] = useState<boolean>(false);
  const user = useSelector((state: RootState) => state.user.userContext, shallowEqual);
  const token = useSelector((state: RootState) => state.user.userContext.token, shallowEqual);
  const firebaseMessagingToken = useSelector((state: RootState) => state.app.firebaseMessagingToken);
  const [firebaseToken, setFirebaseToken] = useState<string>('');
  const dispatch = useAppDispatch();
  const { firebaseResponse, initializeFirebase } = useFirebase();
  const { setPageError } = useAppProvider();
  const { log, error } = Logger();
  const { messaging } = useFirebaseMessaging();
  const cursorRef = useRef<any>(null);

  useEffect(() => {
    initializeFirebase();
  }, []);

  useEffect(() => {
    if (!isNil(cursorRef.current)) {
      setNextCursor(cursorRef.current);
    }
  }, [cursorRef.current]);

  useEffect(() => {
    const init = async () => {
      const auth = getAuth();
      const token = firebaseResponse?.generateFirebaseToken.data.token;
      if (!token || token?.length <= 0) return;
      await signInWithCustomToken(auth, token);
      const db = getDatabase(auth.app);
      const configRef = ref(db, '/app_config');

      onValue(configRef, snapshot => {
        const data = snapshot.val() as FirebaseSettingsModel;
        const genGConfigs: GENG_CONFIG_TYPE = {};
        let themeConfig: ThemeConfigType = {};
        if (data?.hide_gen_g_banner) {
          genGConfigs.hide_gen_g_banner = data.hide_gen_g_banner;
        }
        if (data?.geng_mobile_banner) {
          genGConfigs.geng_mobile_banner = data.geng_mobile_banner;
        }
        if (data?.geng_web_banner) {
          genGConfigs.geng_web_banner = data.geng_web_banner;
        }
        if (data?.geng_redirect_url) {
          genGConfigs.geng_redirect_url = data.geng_redirect_url;
        }
        if (data?.custom_theme && data?.theme_config) {
          themeConfig = data?.theme_config;
          dispatch(setThemeConfig(themeConfig));
        } else {
          dispatch(setThemeConfig(null));
        }
        dispatch(setMarketingConfig(data?.marketing_config ?? null));
        dispatch(setGenGConfigs(genGConfigs));
        dispatch(setShowApplePay(data?.show_apple_pay === 1));
        dispatch(setIsEWCSectionHidden(data?.isEWCSectionHidden ?? false));
        dispatch(setIsEWCStayTunedSectionHidden(data?.isEWCStayTunedSectionHidden ?? false));
        dispatch(setIsPasswordSettingsHidden(data?.is_password_settings_hidden ?? false));
        dispatch(setRedirectToEWC(data?.redirect_to_EWC ?? true));

        if (data.is_offline || data.is_under_maintenance) {
          setPageError?.(PageErrorTypes.MAINTENANCE);
        }
      });
    };
    init();
  }, [firebaseResponse]);

  const onMessageNextOrSubscriber: NextFn<MessagePayload> = payload => {
    log('notification messaging >>>', payload);
    if (payload.data) {
      setNotificationList(prevState => {
        const existingNotification = prevState.find(
          notification => typeof notification.id === 'string' && notification.id === payload.data?.msg_id,
        );
        const { msg_id: id, ...rest } = payload.data as { msg_id: string; [key: string]: string };
        const updatedObject = { id, ...rest };
        const newData: NotificationMessageData = {
          ...updatedObject,
          created: moment(new Date()).valueOf().toString(),
          id: payload?.data?.msg_id,
          is_read: 'false',
        };
        if (payload.data?.event_type === EventType.minigamesUpdate || payload.data?.event_type === EventType.minigamesNewGames) {
          newData['bodyHTML_ar'] = payload.notification?.body;
          newData['bodyHTML_en'] = payload.notification?.body;
          newData.id = `temp-${uuidV4()}`;
        }
        const newList = [...prevState];

        log('newData', newData);
        if (!existingNotification) {
          newList.unshift(newData);
        }
        log('newList', newList);
        return newList;
      });
      setNotificationLength(prevState => {
        const newCount = prevState + 1;
        return newCount;
      });
    }
  };

  messaging && onMessage(messaging, onMessageNextOrSubscriber);

  const requestPermission = async () => {
    if (!messaging) return;
    try {
      log('request permission !!!!');
      if (!('Notification' in window)) {
        //Browser Does Not Support Notification
        log('Notification not supported');
        return;
      }
      log('notification permission >>>>', window.Notification.permission);
      switch (window.Notification.permission) {
        case 'default':
          // Request Notification permission
          const result = await window.Notification.requestPermission();
          if (result != 'granted') return;
          break;
        case 'denied':
          setIsInitialize(true);
          return;
        case 'granted':
          break;
        default:
          return;
      }
      log('get firebase token !!!!');
      const generatedToken = await getToken(messaging, {
        vapidKey: firebaseConfig?.vapidKey,
      });
      log('token generated: ', generatedToken);
      setFirebaseToken(generatedToken);
    } catch (err) {
      error('requestPermission error >>>>', err);
    }
  };

  useEffect(() => {
    log('useeffect register device >>>>', firebaseToken);
    if (firebaseToken && firebaseToken != firebaseMessagingToken) {
      registerDevice({
        details: {
          device_token: firebaseToken,
          os_version: osVersion,
          os_name: osName,
          device_brand: browserName,
          device_model: engineName,
          device_name: isDesktop ? 'pc' : 'mobile',
        },
      }).then(response => {
        log('response register >>>', response);
        if (response?.is_successful) {
          setIsInitialize(true);
          dispatch(setFirebaseMessagingToken(firebaseToken));
        }
      });
    } else if (firebaseMessagingToken) {
      setIsInitialize(true);
    }
  }, [firebaseToken, firebaseMessagingToken]);

  useEffect(() => {
    const initNotification = () => {
      try {
        if (!messaging) return;
        if (token && user?.username) {
          getAllNotification({ limit: NOTIFICATION_PAGE_SIZE });
          getUnreadCount();
          requestPermission();
        }
        if (!token && firebaseToken) {
          dispatch(setFirebaseMessagingToken(''));
          setFirebaseToken('');
          setIsInitialize(false);
        }
      } catch (error) {
        console.error('Notification initialization error', error);
      }
    };
    initNotification();
  }, [token, user?.username, messaging]);

  // Function to get all notification
  const getAllNotification = async (details: NotificationMessageGetAllInput) => {
    try {
      const result = await notificationMessageGetAll(details);
      if (result?.notificationMessageGetAll.data?.data) {
        setNotificationList(prevNotificationList => [
          ...prevNotificationList.filter(
            notification =>
              notification?.event_type === EventType.minigamesUpdate || notification.event_type === EventType.minigamesNewGames,
          ),
          ...(result?.notificationMessageGetAll?.data?.data || []),
        ]);
      }
      if (result?.notificationMessageGetAll.data.cursor) {
        cursorRef.current = result.notificationMessageGetAll.data.cursor ?? '';
        setNextCursor(result.notificationMessageGetAll.data.cursor ?? '');
      }
    } catch (error) {
      // TODO: Uncomment this when notification API is fixed on prod
      // toastWrapper('error', translate('error_network_something_went_wrong') || '');
    }
  };

  // Function to get the next set of notification list
  const getAllNextList = async () => {
    try {
      const result = await notificationMessageGetAll({ cursor: cursorRef.current, limit: NOTIFICATION_PAGE_SIZE });
      if (result?.notificationMessageGetAll.data?.data) {
        setNotificationList(notificationList => [...notificationList, ...result?.notificationMessageGetAll?.data?.data]);
        cursorRef.current = result.notificationMessageGetAll.data.cursor ?? '';
        setNextCursor(result.notificationMessageGetAll.data.cursor ?? '');
      }
    } catch (error) {
      toastWrapper('error', translate('error_network_something_went_wrong') ?? '');
    }
  };

  // Function to update notification
  const updateNotification = async (details: NotificationMessageUpdateInput) => {
    try {
      const result = await notificationMessageUpdate(details);
      if (result?.is_successful && result?.data) {
        setNotificationList(prevState => {
          const newList = prevState.map(notif => {
            const newNotif = {
              ...notif,
            };
            if (newNotif.id === result.data.id) {
              newNotif.is_read = result.data.is_read;
            }
            return newNotif;
          });
          return newList;
        });
        if (details.is_read === 'false') {
          setNotificationLength(prevLength => prevLength + 1);
        } else if (details.is_read === 'true' && notificationLength > 0) {
          setNotificationLength(prevLength => prevLength - 1);
        }
      }
      return result;
    } catch (error) {
      toastWrapper('error', translate('error_network_something_went_wrong') ?? '');
    }
  };

  // Function to remove all notification
  const removeAllNotification = async () => {
    try {
      const result = await notificationMessageRemoveAll();
      if (result?.notificationMessageRemoveAll?.is_successful) {
        setNotificationList([]);
        setNotificationLength(0);
      }
    } catch (error) {
      toastWrapper('error', translate('error_network_something_went_wrong') || '');
    }
  };

  // Function to remove one notification by message_id
  const removeNotification = async (message_id: string) => {
    try {
      const result = await notificationMessageRemove({ message_id });
      if (result?.is_successful) {
        //TODO: Verify the right id that should be used here based on the response
        const removedNotif = notificationList.find(data => data.id === message_id);
        removedNotif?.is_read === 'false' && setNotificationLength(notificationLength => notificationLength - 1);
        setNotificationList(prevState => prevState.filter(item => item.id !== message_id));
      }
      return result;
    } catch (error) {
      toastWrapper('error', translate('error_network_something_went_wrong') || '');
    }
  };
  // Function to mark all notification as read
  const markReadAllNotification = async () => {
    try {
      const result = await notificationMessageMarkReadAll();
      if (result?.notificationMessageMarkReadAll?.is_successful) {
        setNotificationList(prevState =>
          prevState.map(item => {
            item.is_read = 'true';
            return item;
          }),
        );
        setNotificationLength(0);
      }
    } catch (error) {
      toastWrapper('error', translate('error_network_something_went_wrong') || '');
    }
  };

  //NOTE: Anytime there is a new token event in firebase, the registerDeviceNotification (device/register API) needs to be called
  const registerDeviceNotification = async (params: RegisterDeviceParams) => {
    try {
      const result = await registerDevice({ details: params });
      return result;
    } catch (error) {
      toastWrapper('error', translate('error_network_something_went_wrong') || '');
    }
  };

  const removeDeviceNotification = async () => {
    const params: RemoveDeviceParams = {
      device_token: firebaseToken,
    };
    try {
      const result = await removeDevice({ details: params });
      log('remove register device >>>', result);
    } catch (error) {
      toastWrapper('error', translate('error_network_something_went_wrong') || '');
    }
  };

  const sendNotification = async (params: NotificationMessageSendInput) => {
    try {
      const result = await notificationMessageSend({ ...params });
      return result;
    } catch (error) {
      toastWrapper('error', translate('error_network_something_went_wrong') || '');
    }
  };

  // Function that gets the number of unread message by using the getAll API with a payload of is_read: false then checks the total from the response
  const getUnreadCount = () => {
    notificationMessageGetAll({ is_read: 'false', limit: 1 }).then(response => {
      if (response?.notificationMessageGetAll?.data?.total) {
        setNotificationLength(response?.notificationMessageGetAll.data.total as number);
      }
    });
  };

  // Checks if notification list is empty
  useEffect(() => {
    setIsNotificationEmpty(notificationList.length === 0);
  }, [notificationList]);

  return (
    <NotificationContext.Provider
      value={{
        notificationList,
        getAllNotification,
        updateNotification,
        removeAllNotification,
        removeNotification,
        markReadAllNotification,
        registerDeviceNotification,
        removeDeviceNotification,
        sendNotification,
        isNotificationEmpty,
        setSelectedFilters,
        selectedFilters,
        setNotificationLength,
        notificationLength,
        getAllNextList,
        nextCursor,
        setFilterValues,
        filterValues,
        firebaseToken,
        setSavedStatus,
        savedStatus,
        setSavedFeature,
        savedFeature,
        setPeriodCode,
        periodCode,
        setIsModalOpen,
        isModalOpen,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotificationProvider = (): UseNotificationProvider => {
  const context = useContext(NotificationContext);

  if (!context) throw 'Please use a provider to use this hook';

  return context;
};
