import { motion } from 'framer-motion';

export enum BACKGROUNDS {
    INITIAL = 'radial-gradient(50% 50% at 50% 50%, #9A53DA 0%, rgba(154, 83, 218, 0.36) 53.88%, rgba(154, 83, 218, 0.00) 100%)',
    NO_BACKGROUND = 'radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0) 0%)',
    ACCEPT = 'radial-gradient(50% 50% at 50% 50%, #108715 0%, rgba(16, 135, 21, 0.32) 53.88%, rgba(16, 135, 21, 0.00) 100%)',
    REJECT = 'radial-gradient(50% 50% at 50% 50%, #E03030 0%, rgba(224, 48, 48, 0.32) 53.88%, rgba(224, 48, 48, 0.00) 100%)',
}

interface GradientBackgroundProps {
    activeBackground?: BACKGROUNDS;
    isActive?: boolean;
    isHover?: boolean;
    isOpposite?: boolean;
    isOppositeHover?: boolean;
    isOppositeHoverEnd?: boolean;
    position: 'left' | 'right';
    onAnimationComplete?: () => void;
}

const GradientBackground = ({
    activeBackground,
    isActive,
    isHover,
    isOpposite,
    isOppositeHover,
    isOppositeHoverEnd,
    position,
    onAnimationComplete,
}: GradientBackgroundProps) => {
    return (
        <motion.div
            animate={{
                scale:
                    isActive || isHover || isOpposite
                        ? [1, 0.8, 0.6, 0.25, 0.6, 0.8, 1]
                        : isOppositeHover
                        ? [1, 0.8, 0.7, 0.6, 0.5, 0.25, 0]
                        : isOppositeHoverEnd
                        ? [0, 0.25, 0.5, 0.6, 0.7, 0.8, 1]
                        : [1, 0.9, 0.8, 0.6, 0.25, 0.6, 0.8, 0.9, 1],
            }}
            transition={{ duration: 0.6, ease: 'linear' }}
            className={`absolute top-0 ${
                position === 'left' ? 'left-0 rtl:right-0 rtl:left-auto' : 'right-0 rtl:left-0 rtl:right-auto'
            } h-full w-[50%] overflow-hidden`}
        >
            <motion.div
                initial={{
                    background: BACKGROUNDS.INITIAL,
                }}
                animate={{
                    background:
                        isActive || isHover
                            ? [BACKGROUNDS.INITIAL, BACKGROUNDS.NO_BACKGROUND, activeBackground]
                            : isOppositeHover || isOpposite
                            ? [BACKGROUNDS.INITIAL, BACKGROUNDS.NO_BACKGROUND, BACKGROUNDS.INITIAL]
                            : [BACKGROUNDS.INITIAL, BACKGROUNDS.NO_BACKGROUND, BACKGROUNDS.INITIAL],
                }}
                transition={{ duration: 0.6, ease: 'linear' }}
                onAnimationComplete={() => onAnimationComplete && onAnimationComplete()}
                className={`absolute w-[200%] h-full top-0 ${
                    position === 'left' ? 'left-0 rtl:right-0 rtl:left-auto' : 'right-0 rtl:left-0 rtl:right-auto'
                } transition-all duration-1000 z-0`}
            ></motion.div>
        </motion.div>
    );
};
export default GradientBackground;
