import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';

import usePageLayout from '../../hooks/usePageLayout';

/** READ ME!
 * 1. Pass id props so that animation will not cause an issue when multiple
 * toggle switches are displayed in a page.
 * 2. Make sure that the id provided is unique. If id is not unique, this will cause
 * animation issue for the toggle switch.
 */

interface ToggleSwitchProps {
  onChange?: (value: boolean) => void;
  value?: boolean;
  id?: string;
}
const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ onChange = () => {}, value, id }) => {
  const { direction } = usePageLayout();
  const [toggle, setToggle] = useState(value ?? false);

  useEffect(() => {
    onChange(toggle);
  }, [toggle]);

  return (
    <motion.div
      id={id}
      className={`flex ${
        toggle ? 'bg-sunset' : 'bg-[#C4C4C4]'
      } relative items-center aspect-[9/5] h-20 4xl:h-36 5xl:h-54 8xl:h-100 cursor-pointer rounded-full px-[3px] 4xl:px-[5.33px] 5xl:px-8 8xl:px-16 border-transparent`}
      onClick={() => setToggle(!toggle)}
      style={{ justifyContent: toggle ? 'flex-end' : 'flex-start' }}
    >
      <motion.span
        id={id + '_SPAN'}
        layout
        layoutId={id + direction + 'Toggle'}
        transition={{ duration: 0.45, type: 'spring' }}
        aria-hidden="true"
        className="aspect-square h-16 4xl:h-[28.44px] 5xl:h-[42.66px] 8xl:h-[85.33px] rounded-full bg-white shadow-lg"
      />
    </motion.div>
  );
};

export default ToggleSwitch;
