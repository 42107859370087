import { IMAGE_ALT_LABELS, IconNames, TxKeyPath, formatNumberWithCommas } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import Image from '@fe-web/Atoms/Media/Image';

interface PlayerSelectionCardProps {
  headerImage?: string;
  avatar?: string;
  username: string;
  displayName: string;
  bio?: string;
  following?: number;
  followers?: number;
  isVerified?: boolean;
  className?: string;
}

const PlayerSelectionCard = ({
  headerImage,
  avatar,
  displayName,
  username,
  bio,
  following,
  followers,
  isVerified,
  className,
}: PlayerSelectionCardProps) => {
  const { translate } = useTranslate();
  return (
    <div className={`${className || ''}`}>
      <div
        className={`relative overflow-hidden max-w-full w-[17.625rem] border border-gray-200-zinc-700 bg-primary rounded-[0.5rem] p-[1.5rem] pt-[3.75rem]`}
      >
        <div className="absolute top-0 left-0 w-full h-[8.5rem] z-0 overflow-hidden">
          <div className="absolute h-full w-full bg-gradient-to-t from-primary to-primary/[0]"></div>
          <Image
            img={headerImage}
            alt={translate(IMAGE_ALT_LABELS.matched_user_profile_banner as TxKeyPath) || ''}
            divStyle="w-full h-full"
            imgStyle="w-full h-full object-cover"
            fallback={<div className="w-full h-full bg-zinc-800-gray-200"></div>}
          />
        </div>

        <div className="relative flex flex-col items-center gap-[1.5rem] z-1">
          <div className="h-[7.5rem] w-[7.5rem] bg-gray-200-zinc-700 border border-gray-200-zinc-700 rounded-full overflow-hidden">
            <Image
              img={avatar}
              alt={translate(IMAGE_ALT_LABELS.matched_user_profile_avatar as TxKeyPath) || ''}
              divStyle={`h-full w-full`}
              imgStyle="h-full w-full object-cover"
              fallback={
                <ResponsiveIcon name={IconNames.avatar} baseHeight={72} baseWidth={72} iconClasses="h-full w-full p-[1.5rem] fill-gray" />
              }
            />
          </div>
          <div className="flex flex-col gap-[0.25rem] items-center">
            {displayName && (
              <div className="flex gap-[0.5rem] items-center text-center">
                <span className="font-medium text-fs-subtitle">{displayName}</span>
                {isVerified && <ResponsiveIcon name={IconNames.verified3} baseWidth={20} baseHeight={20} className="fill-blue" />}
              </div>
            )}
            {username && <p className="font-regular text-fs-caption text-zinc-600-400">@{username}</p>}
          </div>

          <p className="w-full font-regular text-fs-body-small break-words text-center min-h-[2.5rem]">{bio}</p>

          <div className="flex text-center w-full">
            <div className="flex-1 flex flex-col gap-[0.25rem]">
              <p className="font-medium text-fs-body">{formatNumberWithCommas(following || 0)}</p>
              <p className="font-regular text-fs-caption text-neutral-400-zinc-500">{translate('profile.following')}</p>
            </div>
            <div className="flex-1 flex flex-col gap-[0.25rem] border-gray-200-zinc-700 border-l rtl:border-l-0 rtl:border-r">
              <p className="font-medium text-fs-body">{formatNumberWithCommas(followers || 0)}</p>
              <p className="font-regular text-fs-caption text-neutral-400-zinc-500">{translate('profile.followers')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayerSelectionCard;
