const ProductName = (props: { text?: string; onClick: () => void; brandNameFontSize?: string }) => {
  const { brandNameFontSize = 'text-fs-body-small' } = props;

  return (
    <span
      className={`
        w-full
        line-clamp-1
        font-medium
        text-secondary
        cursor-pointer
      ${brandNameFontSize}
      `}
      onClick={props.onClick}
    >
      {props.text}
    </span>
  );
};

export default ProductName;
