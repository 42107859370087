import { IMAGES } from '@fe-monorepo/assets';
import { IconNames } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import Button from '@fe-web/Atoms/Buttons/Button';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import Modal from '@fe-web/Templates/Modal/Modal';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import useHeaderState from '@fe-web/hooks/useHeaderState';
import usePageLayout from '@fe-web/hooks/usePageLayout';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from '../../../app.routes.enum';
import Confirmation from '../CartPage/components/MainSections/Confirmation';

const ReturnConfirmation = () => {
  const navigate = useNavigate();
  const { translate } = useTranslate();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { dir } = useHeaderState();
  const { isRtl } = usePageLayout();

  useEffect(() => {
    mixpanelHelper.trackPageView(
      'ORDER DETAILS PAGE - RETURN ORDER PAGE - RETURN REQUEST SUMMARY PAGE',
      'my_orders_order_tab_odp_return_order_summary_page',
      window.location.pathname,
      document.referrer,
    );
  }, []);

  return (
    <>
      <div className="grid my-[1.5rem] SD:my-[2.5rem] SD:mb-[7.5rem] px-[1.25rem] SD:px-[7.5rem]">
        <div className="flex flex-row justify-between col-span-4">
          <h1 className="font-medium text-fs-title text-secondary col-span-4 mb-[2.5rem] SD:mb-[7.13rem] 2K:mb-[7.13rem]">
            {translate('shop.cart.returns_or_exchange.return_items')}
          </h1>
          <button className="mdMaxxS:items-center mdMaxxS:mb-[2.5rem] flex flex-row gap-[0.5rem]" onClick={() => setIsModalOpen(true)}>
            <p className="mdMaxxS:hidden text-fs-body font-regular text-secondary/70">{translate('action_close')}</p>
            <ResponsiveIcon
              onClick={() => setIsModalOpen(true)}
              className={`cursor-pointer fill-secondary/70 pt-[0.25rem]`}
              name={IconNames.close}
              baseHeight={16}
              baseWidth={16}
            />
          </button>
        </div>
        <Confirmation
          img={IMAGES.ReturnConfirmation.toString()}
          header={translate('shop.returns_page.request_sent')}
          message={translate('shop.returns_page.request_message')}
          button={translate('shop.cart.confirmation_go_to_orders')}
          imageClass="w-[13.02rem] h-[5.43rem]"
        />
      </div>

      <Modal backdropClassName="bg-black/[0.25] items-center" open={isModalOpen}>
        <div
          className={`
            flex flex-col justify-start items-start bg-primary border border-neutral-300-zinc-700
            rounded-lg p-[1.5rem] SD:p-[3rem]
            max-h-[100vh] overflow-y-auto fixed top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]
            w-[22.5rem] SD:w-[27rem]
            gap-[2rem]
        `}
        >
          <div className={`flex flex-col gap-[2rem]`}>
            <div className={`flex flex-row gap-[0.5rem] font-medium text-fs-title text-secondary`}>
              <h1 className="truncate whitespace-normal">{translate('shop.cart.returns_or_exchange.are_you_sure_leave')}</h1>
              <ResponsiveIcon
                className={`
                        cursor-pointer
                        ${
                          dir === 'ltr'
                            ? 'right-[2.313rem] SD:right-[3.25rem] 2K:right-[2.75rem] justify-items-end'
                            : 'left-[2.313rem] SD:left-[3.25rem] 2K:left-[2.75rem] justify-items-end'
                        }
                        top-[2rem] SD:top-[3.2625rem] 2K:top-[2.9626rem] 8K:top-[3.5626rem]`}
                name={IconNames.icon_close_20}
                iconClasses={`fill-secondary`}
                baseHeight={20}
                baseWidth={20}
                onClick={() => setIsModalOpen(false)}
              />
            </div>
          </div>
          <div className={`font-regular text-fs-body text-zinc-600-400`}>
            {translate('shop.cart.returns_or_exchange.any_changes_not_be_saved')}
          </div>
          <div className={`flex flex-col SD:flex-row w-full gap-[1rem]`}>
            <Button
              className={`flex-1 text-fs-body text-sunset font-medium border-[0.063rem] border-sunset rounded-sm px-[1.5rem] py-[0.5rem] ${
                !isRtl ? `after:bg-primary before:bg-sunset` : `before:bg-primary after:bg-sunset`
              } hover:text-white100`}
              text={translate('action_cancel')}
              onClick={() => setIsModalOpen(false)}
            />
            <Button
              className={`flex-1 text-fs-body font-medium rounded-sm px-[1.5rem] py-[0.5rem] ${`text-white border-[0.063rem] border-sunset hover:text-sunset ${
                !isRtl ? 'before:bg-white after:bg-sunset' : 'after:bg-white before:bg-sunset'
              }`}`}
              text={translate('shop.cart.returns_or_exchange.yes_leave')}
              onClick={() => navigate(AppRoutes.myorders)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ReturnConfirmation;
