import { ChatType } from '@fe-monorepo/models';
import { RootState, setFollowersFollowing } from '@fe-monorepo/store';
import { ConnectConversationProvider, useConnectProvider } from '@fe-web/hooks/useConnect';
import useGetCurrentBreakPoint from '@fe-web/hooks/useGetCurrentBreakPoint/useGetCurrentBreakPoint';
import { motion } from 'framer-motion';
import { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PrivateMessage from './FullViewMessage/components/PrivateMessage';
import FollowersFollowing from './components/FollowersFollowing/FollowersFollowing';
import GroupPreferenceMinimized from './components/GroupPreference/GroupPreferenceMinimized';
import NewChat from './components/NewChat';
import SideBarChat from './components/SideBarChat/SideBarChat';
import SideBarChatEmpty from './components/SideBarChat/SideBarChatEmpty';
import SideBarChatHeader from './components/SideBarChat/SideBarChatHeader';
import SidebarChatSearch from './components/SideBarChat/SidebarChatSearch';

interface SideBarChatOverViewProps {
  showFullViewIcon: boolean;
  fixedPosition: boolean;
  isFullView?: boolean; //Chat side bar from full view chat
  isOptionOpenedOnClick?: (value: boolean) => void;
  onClickNewChat?: () => void;
  onClickChat?: (conversationID: string, conversationType: number) => void;
  isChatOpen?: boolean;
  closeSidebar?: () => void;
  setIsInviteUser?: (value: boolean) => void;
  setInviteFromMiniSideBar?: (value: boolean) => void;
  onRefreshClick?: () => void;
}

interface ChatContainerProps {
  children: ReactNode;
  isChatMinimized?: any;
  isMinimizedPreference?: any;
  conversationID?: string;
}
const ChatContainer = ({ children, isChatMinimized, conversationID, isMinimizedPreference }: ChatContainerProps) => {
  return (
    <div
      className={`max-w-screen ${isChatMinimized[conversationID || ''] || isMinimizedPreference[conversationID || ''] ? '' : 'h-[22.125rem]'
        } w-[17.625rem] bg-primary rounded-[0.5rem] border border-neutral-300-zinc-700 z-2`}
    >
      {children}
    </div>
  );
};

enum SidebarChatContainerAnimationType {
  show = 'show',
  hide = 'hide',
}

const SidebarChatContainer: React.FC<SideBarChatOverViewProps> = props => {
  const containerClasses = `fixed z-20 p-[1rem] pt-0 bottom-0 left-0 w-full h-0 flex flex-row-reverse items-end gap-[0.5rem]`;
  const dispatch = useDispatch();
  const isfollowersFollowingModalOpen = useSelector((state: RootState) => state.connect.followersFollowing);
  const { openChats, openChat, closeChat, notificationSidebarStatus, setNotificationSidebarStatus, refreshLogin } = useConnectProvider();

  const [isMinimizedPreference, setIsMinimizedPreference] = useState<any>({});
  const [InvitedUsers, setInvitedUsers] = useState<any>({});
  const [currentAnimation, setCurrentAnimation] = useState<SidebarChatContainerAnimationType>();
  const [currentAnimation2, setCurrentAnimation2] = useState<SidebarChatContainerAnimationType>();
  const [isChatMinimized, setIsChatMinimized] = useState<any>({});
  const [isChatWindowModalOpen, setIsChatWindowModalOpen] = useState<boolean>(false);
  const [inviteFromMiniSideBar, setInviteFromMiniSideBar] = useState<boolean>(false);
  const { currentBreakPoint } = useGetCurrentBreakPoint();

  const higherScreens = ['2k', '4k', '8k', 'Ll', 'normal'];
  const widthChecker = higherScreens.includes(currentBreakPoint) ? '25%' : '20%';

  const handleClose = () => {
    dispatch(
      setFollowersFollowing({
        isFollowersFollowingOpen: false,
        initialTab: 'following',
      }),
    );
  };
  const onClickNewChat = () => {
    //filter chat list if there is already opened new chat window and existing chat conversation
    if (openChat && !openChats?.some(item => item.type === ChatType.NEW)) {
      openChat({ type: ChatType.NEW, conversationID: 'newchat' });
    }
  };
  const onClickChat = (conversationID: string, conversationType: number) => {
    openChat?.({ type: ChatType.EXISTING, conversationID, conversationType });
    setIsMinimizedPreference({
      ...isMinimizedPreference,
      [conversationID]: false,
    });
    setIsChatMinimized({
      ...isChatMinimized,
      [conversationID]: false,
    });
  };

  const onClose = (id: string) => {
    closeChat && closeChat(id);
  };
  useEffect(() => {
    setCurrentAnimation(
      props.isChatOpen || notificationSidebarStatus ? SidebarChatContainerAnimationType.show : SidebarChatContainerAnimationType.hide,
    );
  }, [props.isChatOpen, notificationSidebarStatus]);

  useEffect(() => {
    setCurrentAnimation2(notificationSidebarStatus ? SidebarChatContainerAnimationType.show : SidebarChatContainerAnimationType.hide);
  }, [notificationSidebarStatus]);

  const handleKeyDown = (event: any) => {
    if (event.key === 'Escape') {
      props.closeSidebar && props.closeSidebar();
    }
  };

  useEffect(() => {
    if (props.isChatOpen) {
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
    }

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isChatOpen]);

  return (
    <div className={containerClasses}>
      {(props.isChatOpen || notificationSidebarStatus) && (
        <>
          <div
            className="absolute bottom-0 left-0 h-screen w-full z-0"
            onClick={() => {
              props.closeSidebar && props.closeSidebar();
              setNotificationSidebarStatus?.(false);
            }}
          />
          <motion.div
            initial={false}
            variants={{
              [SidebarChatContainerAnimationType.show]: {
                opacity: [0, 1, 1],
                left: ['100%', '0%'],
                width: ['0%', widthChecker],
              },
              [SidebarChatContainerAnimationType.hide]: {
                opacity: [1, 1, 0],
                left: ['0%', '100%'],
                width: [widthChecker, '0%'],
              },
            }}
            transition={{
              duration: 0.5,
              ease: 'easeInOut',
            }}
            animate={currentAnimation}
            className={`relative w-[20%] h-[90vh] XGA:w-[30%] HD:w-[20%] FHD:w-[17%] 2K:w-[25%] 2K:h-[87vh] ${isChatWindowModalOpen ? 'z-[1]' : 'z-[11]'
              }`}
          >
            <div
              className={`absolute top-0 flex justify-start w-full h-full ${!notificationSidebarStatus && 'bg-primary border-[.1rem] rounded-[.5rem] border-secondary/25'
                }`}
            >
              {!notificationSidebarStatus && (
                <SideBarChatOverView
                  {...props}
                  setInviteFromMiniSideBar={value => setInviteFromMiniSideBar(value)}
                  onClickNewChat={onClickNewChat}
                  onClickChat={onClickChat}
                  onRefreshClick={refreshLogin}
                />
              )}
            </div>
          </motion.div>
        </>
      )}
      {isfollowersFollowingModalOpen?.isFollowersFollowingOpen && (
        <FollowersFollowing handleClose={handleClose} activeTab={isfollowersFollowingModalOpen.initialTab} />
      )}
      {openChats?.map((item, index) => (
        <ConnectConversationProvider conversationID={item?.conversationID} conversationType={item?.conversationType}>
          <ChatContainer
            conversationID={item?.conversationID}
            isChatMinimized={isChatMinimized}
            isMinimizedPreference={isMinimizedPreference}
          >
            {item.type === ChatType.NEW ? (
              <NewChat onClose={() => onClose(item.conversationID || '')} />
            ) : item.conversationID && isMinimizedPreference[item.conversationID] ? (
              <GroupPreferenceMinimized
                onClose={() => onClose(item.conversationID || '')}
                onClickBack={minimize => {
                  if (item.conversationID) {
                    if (!minimize) {
                      setIsMinimizedPreference({
                        ...isMinimizedPreference,
                        [item.conversationID]: !isMinimizedPreference[item.conversationID],
                      });
                      setIsChatMinimized({
                        ...isChatMinimized,
                        [item.conversationID]: false,
                      });
                    } else {
                      setIsMinimizedPreference({
                        ...isMinimizedPreference,
                        [item.conversationID]: !isMinimizedPreference[item.conversationID],
                      });
                      setIsChatMinimized({
                        ...isChatMinimized,
                        [item.conversationID]: true,
                      });
                    }
                  }
                }}
              />
            ) : item.conversationID && InvitedUsers[item.conversationID] ? (
              <NewChat
                onClose={() => onClose(item.conversationID || '')}
                isInviteUsers={true}
                onCloseInvite={() => {
                  if (item.conversationID) {
                    setInvitedUsers({
                      ...InvitedUsers,
                      [item.conversationID]: !InvitedUsers[item.conversationID],
                    });
                    setInviteFromMiniSideBar(false);
                  }
                }}
              />
            ) : (
              item.type === ChatType.EXISTING && (
                <PrivateMessage
                  setIsChatWindowModalOpen={chatWindowModalOpen => setIsChatWindowModalOpen(chatWindowModalOpen)}
                  goToMinimizedPreference={() => {
                    if (item.conversationID) {
                      setIsMinimizedPreference({
                        ...isMinimizedPreference,
                        [item.conversationID]: !isMinimizedPreference[item.conversationID],
                      });
                    }
                  }}
                  inviteUsers={() => {
                    if (item.conversationID) {
                      setInvitedUsers({
                        ...InvitedUsers,
                        [item.conversationID]: !InvitedUsers[item.conversationID],
                      });
                    }
                  }}
                  isMinimized
                  onClose={() => onClose(item.conversationID || '')}
                  handleChatMinimized={() => {
                    if (item.conversationID) {
                      setIsChatMinimized({
                        ...isChatMinimized,
                        [item.conversationID]: !isChatMinimized[item.conversationID],
                      });
                    }
                  }}
                  isChatMinimized={isChatMinimized}
                  inviteFromMiniSideBar={inviteFromMiniSideBar}
                />
              )
            )}
          </ChatContainer>
        </ConnectConversationProvider>
      ))}
    </div>
  );
};

const SideBarChatOverView: React.FC<SideBarChatOverViewProps> = ({
  showFullViewIcon,
  fixedPosition,
  isFullView = false,
  isOptionOpenedOnClick,
  onClickNewChat,
  onClickChat,
  setIsInviteUser,
  setInviteFromMiniSideBar,
  onRefreshClick,
  closeSidebar
}) => {
  const [isChatEmpty, setIsChatEmpty] = useState<boolean>(false); // change value to test
  const [searchQuery, setSearchQuery] = useState('');
  const [onSearch, setOnSearch] = useState<boolean>(false);
  const isRtl = useSelector((state: RootState) => state.app.isRTL);
  const { conversationListModified, hasError } = useConnectProvider();

  const onSearching = (value: boolean) => {
    setOnSearch(value);
  };

  const containerClasses = fixedPosition ? 'h-full' : 'h-[80vh]';

  useEffect(() => {
    if (conversationListModified && conversationListModified?.length > 0 && !hasError) {
      setIsChatEmpty(false);
    } else {
      setIsChatEmpty(true);
    }
  }, [conversationListModified, hasError]);

  return (
    <div className={`${containerClasses} flex flex-col w-full`}>
      <div className="flex flex-col w-full gap-[1.25rem] p-[1.25rem]">
        {!isChatEmpty && (
          <SidebarChatSearch searchQuery={searchQuery} setSearchQuery={setSearchQuery} setIsSearching={onSearching} isRTL={isRtl} />
        )}
        {!onSearch && (
          <SideBarChatHeader isChatEmpty={isChatEmpty} showFullViewIcon={showFullViewIcon} isRTL={isRtl} onClickNewChat={onClickNewChat} closeSidebar={closeSidebar} />
        )}
      </div>
      {isChatEmpty ? (
        <SideBarChatEmpty onClickNewChat={onClickNewChat} hasError={hasError} onRefreshClick={onRefreshClick} />
      ) : (
        <SideBarChat
          isFullView={isFullView}
          onSearch={onSearch}
          isOptionOpenedOnClick={isOptionOpenedOnClick}
          isRTL={isRtl}
          searchQuery={searchQuery}
          chatHistoryList={conversationListModified || []}
          onClickChat={onClickChat}
          setIsInviteUser={setIsInviteUser}
          inviteFromMiniSideBar={setInviteFromMiniSideBar}
        />
      )}
    </div>
  );
};

export default SideBarChatOverView;
export { SidebarChatContainer };
