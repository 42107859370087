import { IMAGES } from '@fe-monorepo/assets';
import { OrderQueryResponse, ShopWishlistInput, WishlistMutationResponse } from '@fe-monorepo/models';
import WishList from '@fe-web/Atoms/Favorite/WishListFavorite';
import LazyImage from '@fe-web/Atoms/Media/LazyImage';
import CurrencyText from '@fe-web/Atoms/Text/CurrencyText';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { StyledProductCard2Brand, StyledProductCard2Container } from './ProductCard2.styled';

export interface ProductCard2Props {
  brand?: string;
  name: string;
  description?: string;
  price: number;
  priceFormatted?: string;
  sellingPrice: number;
  sellingPriceFormatted?: string;
  discount?: string;
  ratings?: number;
  image?: string;
  ctaLabel?: string;
  className?: string;
  colorVariants?: string[];
  tag?: string;
  isRTL?: boolean;
  link?: string;
  brandLink?: string;
  isWishlist?: boolean;
  product_code?: string;
  imgClassName?: string;
  imgDivClassName?: string;
  isWishListHide?: boolean;
  containerClassName?: string;
  tagClassName?: string;
  checkIsSuccess?: (errorMessage: string) => void;
  addToWishList: (shopWishlistInput: ShopWishlistInput) => Promise<WishlistMutationResponse | null | undefined>;
  removeToWishList: (shopWishlistInput: ShopWishlistInput) => Promise<WishlistMutationResponse | null | undefined>;
  getAllWishlist: (getAllWishlistInput: { last_cursor: string; direction: string }) => Promise<OrderQueryResponse | undefined>;
  productBrand: string;
}

const ProductCard2: React.FC<ProductCard2Props> = ({
  brand,
  name,
  price,
  sellingPrice,
  image,
  colorVariants,
  tag,
  isRTL,
  link,
  brandLink,
  isWishlist,
  product_code,
  className,
  imgClassName,
  imgDivClassName,
  isWishListHide,
  containerClassName,
  checkIsSuccess,
  tagClassName,
  getAllWishlist,
  addToWishList,
  removeToWishList,
  productBrand,
}) => {
  const hasDiscount = useMemo(() => sellingPrice < price, [sellingPrice, price]);
  const discountDirection = isRTL ? tag + '-' : '-' + tag;
  const navigate = useNavigate();

  const onBrandLinkClick = (event: any) => {
    event.stopPropagation();
    navigate(brandLink || '');
  };

  const shownPrice = hasDiscount ? sellingPrice : price;

  return (
    <StyledProductCard2Container className={`${containerClassName} group hover:cursor-pointer`} onClick={() => navigate(link ? link : '')}>
      <div className={className ? className : 'relative aspect-[141/154] rounded-[0.25rem] border-[0.0625rem] border-secondary/20'}>
        {tag && (
          <span
            className={`${tagClassName} absolute 2K:py-[7.11px] 4K:py-[10.66px] 8K:py-[21.33px] 2K:text-subtitle 4K:text-fourKSubtitle 8K:text-LPTitle top-0
              ms-10 3xl:ms-[15px] 2K:ms-[20px] 4K:ms-[35px] 8K:ms-[70px]
              mt-10 3xl:mt-[15px] 2K:mt-[20px] 4K:mt-[35px]  8K:mt-[70px]
              2K:px-[14.22px] 4K:px-[21.33px] 8K:px-[42.66px] 
            bg-secondary text-primary rounded-[2px] px-8 py-4 font-medium text-bodySmall z-10`}
          >
            {discountDirection}
          </span>
        )}
        {!isWishListHide && (
          <WishList
            isWishlist={isWishlist}
            product_code={product_code ? product_code : ''}
            checkIsSuccess={checkIsSuccess}
            addToWishList={addToWishList}
            removeToWishList={removeToWishList}
            getAllWishlist={getAllWishlist}
            productName={name}
            brand={productBrand}
          />
        )}
        <LazyImage
          img={image}
          alt={name?.replace(/\s/g, '_') || 'product_thumbnail'}
          divStyle={`${imgClassName} w-full h-full object-contain overflow-hidden`}
          imgStyle={`${imgDivClassName} object-contain group-hover:scale-productImage transition transition-all duration-300 w-full h-full px-[2.5rem] py-[4rem]`}
          fallback={
            <LazyImage
              img={IMAGES.Product_Placeholder}
              alt={name?.replace(/\s/g, '_') || 'product_thumbnail'}
              divStyle={`${imgClassName} w-full h-full object-contain overflow-hidden`}
              imgStyle={`${imgDivClassName} object-contain group-hover:scale-productImage transition transition-all duration-300 w-full h-full px-[2.5rem] py-[4rem]`}
            />
          }
        />
        {colorVariants?.length && (
          <ul className="absolute flex justify-center gap-x-8 bottom-0 pb-[21px] w-full opacity-0 group-hover:opacity-100 transition-all duration-300">
            {colorVariants?.map((colorVariant, key) => (
              <li key={key}>
                <span className="z-10 inline-block w-12 h-12" style={{ backgroundColor: colorVariant }}></span>
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="py-[1rem]">
        {brand && (
          <StyledProductCard2Brand $rtl={isRTL} onClick={onBrandLinkClick}>
            {brand}
          </StyledProductCard2Brand>
        )}
        <p className="mb-[0.75rem] font-medium text-secondary text-fs-body-small truncate">{name}</p>
        <p className="flex text-secondary text-fs-body-large">
          <CurrencyText className={`font-medium ${hasDiscount ? 'text-red' : ''}`} currency={shownPrice} />

          {hasDiscount && <CurrencyText className="mx-[0.75rem] font-regular line-through text-secondary/[0.5]" currency={price} />}
        </p>
      </div>
    </StyledProductCard2Container>
  );
};

export default ProductCard2;
