import { IMAGE_ALT_LABELS, IconNames, Logger, TxKeyPath } from '@fe-monorepo/helper';
import { useBlock, useTranslate } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import Button from '@fe-web/Atoms/Buttons/Button';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import { toastWrapper } from '@fe-web/Atoms/Toast';
import PlayerAvatar from '@fe-web/Molecules/PlayerAvatar';
import Modal from '@fe-web/Templates/Modal/Modal';
import { useConnectConversationProvider, useConnectProvider } from '@fe-web/hooks/useConnect';
import useHeaderState from '@fe-web/hooks/useHeaderState';
import { ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ZIMGroupMemberInfo } from 'zego-zim-web';

import { AppRoutes } from '../../../../app.routes.enum';

interface Props {
  type: 'clear' | 'delete' | 'block' | 'leave' | 'dismiss' | 'report';
  isOpen: boolean;
  isClose: () => void;
  handleAction?: () => void;
  hasAdmin?: boolean;
  userName?: string;
  isGroupChat: boolean;
  isAdminUser?: boolean;
  alreadyLeftTheGroup?: boolean;
  conversationID?: string;
  setIsChatEmpty?: () => void;
  isMinimized?: boolean;
  setIsBlocking?: () => void;
  userID?: string;
}

const OptionsActionModal = ({
  type,
  isOpen,
  isClose,
  handleAction,
  hasAdmin = false,
  userName,
  isGroupChat,
  isAdminUser,
  alreadyLeftTheGroup,
  conversationID,
  setIsChatEmpty,
  isMinimized,
  setIsBlocking,
  userID,
}: Props) => {
  const isRTL = useSelector((state: RootState) => state?.app.isRTL);
  const [title, setTitle] = useState<string>('');
  const [content, setContent] = useState<string | ReactNode>('');
  const [leftBtnLabel, setLeftBtnLabel] = useState<string>('');
  const [rightBtnLabel, setRightBtnLabel] = useState<string>('');
  const { dir } = useHeaderState();
  const { translate } = useTranslate();
  const { deleteConversation, deleteAllMessage, leaveGroup, dismissGroup, closeChat } = useConnectProvider();
  const { groupMembersList, groupMemberCount, userAdmin, setGroupOwner, groupInfo } = useConnectConversationProvider();
  const userState = useSelector((state: RootState) => state.user.userContext);
  const [groupAdminCount, setGroupAdminCount] = useState<number>();
  const [currentAdmin, setCurrentAdmin] = useState<string>('');
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const navigate = useNavigate();
  const { crmCommunityBlock } = useBlock();
  const { log } = Logger();

  const checkTypeTranslation = (type: string) => {
    let translatedType = '';
    switch (type) {
      case 'block':
        return (translatedType = translate('chat.actions.block') || 'block');
      case 'delete':
        return (translatedType = translate('chat.actions.delete') || 'delete');
      case 'clear':
        return (translatedType = translate('chat.actions.clear') || 'clear');
      case 'leave':
        return (translatedType = translate('chat.actions.leave') || 'leave');
      case 'dismiss':
        return (translatedType = translate('chat.actions.dismiss') || 'dismiss');
    }
  };

  useEffect(() => {
    if (type === 'leave') {
      const temp_content = (
        <>
          {translate('connect.leave_group_modal_desc')}
          {userAdmin === 1 && groupAdminCount && groupAdminCount < 2 && groupMembersList && groupMembersList.length > 0 && (
            <>
              <br />
              <br /> <span>{translate('connect.admin_leave_group_desc')}</span>
            </>
          )}
        </>
      );

      setContent(temp_content);
    }
  }, [type, userName, groupAdminCount, currentAdmin]);

  useEffect(() => {
    if (type) {
      let temp_title = `${translate('chat.actions.are_you_sure')} ${checkTypeTranslation(type)}`;
      let temp_content;
      const leftLabel = translate('common_cancel') || 'Cancel';
      let rightLabel = translate('chat.clear.yes_btn') || 'Yes, delete';
      type !== 'leave' && setIsButtonDisabled(false);
      switch (type) {
        case 'block':
          temp_title = `${temp_title} ${userName}?`;
          temp_content = translate('chat.actions.block_text_username', { username: userName });
          rightLabel = translate('chat.actions.yes_block') || 'Yes, block';
          break;
        case 'delete':
          // if (!isAdminUser && alreadyLeftTheGroup) {
          // temp_title =
          //     translate('chat.delete.header_title_after_leaving', { name: userName }) ||
          //     `${temp_title} chat with [${userName}]?`;
          // } else if (!isAdminUser && !alreadyLeftTheGroup) {
          if (isGroupChat) {
            temp_title =
              translate('chat.delete.header_title_before_leaving', { name: userName }) ||
              `${temp_title} ${translate('chat.actions.chat_with')} [${userName}]?`;
            temp_content = translate('chat.delete.before_leaving_message');
          } else {
            temp_title =
              translate('chat.delete.header_title_after_leaving', { name: userName }) ||
              `${temp_title} ${translate('chat.actions.chat_with')} [${userName}]?`;
          }
          rightLabel = translate('connect.yes_delete') || 'Yes, delete';
          break;
        case 'clear':
          temp_title =
            translate('chat.clear.header_title', { name: userName }) ||
            `${temp_title} ${translate('chat.actions.chat_with')} [${userName}]?`;
          break;
        case 'leave':
          temp_title = translate('connect.leave_group') || `${temp_title}`;
          if (type === 'leave') {
            if (conversationID && groupMembersList && groupMemberCount) {
              const admins = groupMembersList?.filter(user => user.memberRole === 1);
              setGroupAdminCount(userAdmin === 1 ? admins.length + 1 : admins.length);
              setCurrentAdmin(admins && admins.length > 0 ? admins[0]?.userID : userState.username);
            }
          }
          rightLabel = translate('connect.yes_leave') || 'Yes, leave';
          break;
        case 'dismiss':
          temp_title = translate('chat.remove_all_users.header_title') || `Are you sure you want to dismiss chat?`;
          temp_content = translate('chat.remove_all_users.message') || `The chat will be deleted for you and all chat participants.`;
          rightLabel = translate('chat.remove_all_users.yes_btn') || `Yes, dismiss`;
          break;
      }
      setTitle(temp_title);
      setContent(temp_content);
      setRightBtnLabel(rightLabel);
      setLeftBtnLabel(leftLabel);
    }
  }, [type, userName]);

  useEffect(() => {
    if (
      currentAdmin === userState.username &&
      userAdmin === 1 &&
      groupAdminCount &&
      groupAdminCount < 2 &&
      groupMemberCount &&
      groupMemberCount > 1
    ) {
      setIsButtonDisabled(true);
    }
  }, [groupAdminCount, groupMemberCount, currentAdmin]);

  const transferThenLeave = async (toUserID: string, displayName: string) => {
    try {
      if (!conversationID) return;
      if (groupMembersList && groupMembersList?.length > 0) await setGroupOwner?.(toUserID, displayName); //transfer admin

      const leaveCurrentGroup = await leaveGroup?.(conversationID); // leave group
      if (leaveCurrentGroup?.groupID) {
        const deleteConvo = await deleteConversation?.(conversationID, isGroupChat ? 2 : 0, {
          isAlsoDeleteServerConversation: true,
        }); // delete conversation
        if (deleteConvo?.conversationID) {
          toastWrapper('error', translate('connect.you_left_the_chat') ?? '');
          isMinimized ? closeChat?.(conversationID) : navigate(AppRoutes.messages);
        }
      }
    } catch (err) {
      console.error('transferThenLeave >>>>>', err);
      toastWrapper('error', translate('msg_failed_generic_try_again') ?? '');
    }
  };

  const regularLeave = async () => {
    try {
      if (!conversationID) return;
      const leaveCurrentGroup = await leaveGroup?.(conversationID); // leave group
      if (leaveCurrentGroup?.groupID) {
        const deleteConvo = await deleteConversation?.(conversationID, isGroupChat ? 2 : 0, {
          isAlsoDeleteServerConversation: true,
        }); // delete conversation
        if (deleteConvo?.conversationID) {
          toastWrapper('error', translate('connect.you_left_the_chat') ?? '');
          isMinimized ? closeChat?.(conversationID) : navigate(AppRoutes.messages);
        }
      }
    } catch (err) {
      console.error('regularLeave >>>>>', err);
      toastWrapper('error', translate('msg_failed_generic_try_again') ?? '');
    }
  };

  const clearChat = async (conversationID: string, chatType: number) => {
    const clearChatRes = await deleteAllMessage?.(conversationID, chatType, { isAlsoDeleteServerMessage: true });
    if (clearChatRes?.conversationID && clearChatRes.conversationType.toString()) {
      setIsChatEmpty && setIsChatEmpty();

      toastWrapper('success', translate('msg_success_chat_cleared') ?? '');
    } else toastWrapper('error', translate('msg_failed_generic_try_again') ?? '');
  };

  const deleteChat = async (conversationID: string, chatType: number) => {
    if (!isGroupChat) {
      const clearChatRes = await deleteAllMessage?.(conversationID, chatType, { isAlsoDeleteServerMessage: true });
      if (clearChatRes?.conversationID && clearChatRes.conversationType.toString()) {
        const deleteConversationRes = await deleteConversation?.(conversationID, isGroupChat ? 2 : 0, {
          isAlsoDeleteServerConversation: true,
        });
        if (deleteConversationRes?.conversationID && deleteConversationRes?.conversationType.toString()) {
          toastWrapper('success', translate('msg_success_chat_deleted') ?? '');
          isMinimized ? closeChat?.(conversationID) : navigate(AppRoutes.messages);
        } else toastWrapper('error', translate('msg_failed_generic_try_again') ?? '');
      }
    } else {
      const deleteConversationRes = await deleteConversation?.(conversationID, isGroupChat ? 2 : 0, {
        isAlsoDeleteServerConversation: true,
      });
      if (deleteConversationRes?.conversationID && deleteConversationRes?.conversationType.toString()) {
        toastWrapper('success', translate('msg_success_chat_deleted') ?? '');
        isMinimized ? closeChat?.(conversationID) : navigate(AppRoutes.messages);
      } else toastWrapper('error', translate('msg_failed_generic_try_again') ?? '');
    }
  };

  const onClickAction = () => {
    if (conversationID) {
      switch (type) {
        case 'clear':
          clearChat(conversationID, isGroupChat ? 2 : 0);
          break;
        case 'delete':
          deleteChat(conversationID, isGroupChat ? 2 : 0);
          break;
        case 'leave':
          if (userAdmin && userAdmin === 1) {
            const displayName = groupMembersList?.filter(user => user.userID === currentAdmin);
            transferThenLeave(currentAdmin, displayName ? displayName[0]?.userName : currentAdmin);
          } else {
            //REGULAR LEAVE
            regularLeave();
          }
          break;
        case 'dismiss': //remove all users of the group
          dismissGroup?.(conversationID)
            .then(response => {
              if (response?.groupID) {
                // successfull ungroup and need to delete chat on the list for admin
                deleteConversation?.(conversationID, isGroupChat ? 2 : 0, { isAlsoDeleteServerConversation: true })
                  .then(res => {
                    isMinimized ? closeChat?.(conversationID) : navigate(AppRoutes.messages);
                  })
                  .catch(err => {});
              } else {
              }
            })
            .catch(err => {});
          break;
        case 'block':
          if (userID) {
            crmCommunityBlock({ username: userID }).then(res => {
              if (res?.crmCommunityBlock.is_successful) {
                toastWrapper('success', translate('msg_success_user_blocked', { username: userName }) ?? '');
                setIsBlocking && setIsBlocking();
              } else {
                toastWrapper('error', translate('msg_failed_generic_try_again') ?? '');
              }
            });
          }
          break;
      }
    }
    handleAction && handleAction();
  };

  const handleClose = () => {
    if (type === 'leave' && userAdmin === 1) {
      setCurrentAdmin(userState.username);
    }
    isClose();
  };

  const setAdmin = (memberRole: number, userID: string) => {
    if (memberRole === 1 && groupAdminCount && groupAdminCount <= 1) {
      log('Group cannot have 0 admin');
    } else {
      if (conversationID) {
        if (currentAdmin !== userID) {
          setCurrentAdmin(userID);
          setIsButtonDisabled(false);
        } else {
          setCurrentAdmin(userState.username);
          setIsButtonDisabled(true);
        }
      }
    }
  };

  return (
    <Modal backdropClassName="bg-black/[0.25] items-center" open={isOpen}>
      <div
        className="flex flex-col justify-start items-center bg-primary border border-neutral-300-zinc-700
          rounded-lg p-[3rem] SD:p-[48px] 2K:p-[80px] 4K:p-[96px] 8K:p-[196px]
          max-h-[100vh] overflow-y-auto fixed top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]
          w-[370px] SD:w-[27rem] 2K:w-[796px] 4K:w-[1146px] 8K:w-[2296px]
          gap-[2rem]"
      >
        <div className="flex flex-col gap-[2rem]">
          <div className="flex flex-row gap-[0.5rem] font-medium text-fs-title text-secondary">
            <h1 className="max-w-[19.375rem] w-[19.375rem] 2K:max-w-[21.375rem] 2K:w-[21.375rem] truncate whitespace-normal">{title}</h1>
            <ResponsiveIcon
              className={`
                cursor-pointer
                ${
                  dir === 'ltr'
                    ? 'right-[2.313rem] SD:right-[3.25rem] 2K:right-[2.75rem] justify-items-end'
                    : 'left-[2.313rem] SD:left-[3.25rem] 2K:left-[2.75rem] justify-items-end'
                }
                top-[2rem] SD:top-[3.2625rem] 2K:top-[2.9626rem] 8K:top-[3.5626rem]`}
              name={IconNames.icon_close_20}
              iconClasses="fill-secondary"
              baseHeight={20}
              baseWidth={20}
              onClick={handleClose}
            />
          </div>
          {content && <div className="font-regular text-fs-body text-zinc-600-400">{content}</div>}
          {type === 'leave' &&
            userAdmin === 1 &&
            groupAdminCount &&
            groupAdminCount < 2 && // change to 2
            groupMembersList &&
            groupMembersList.length > 0 && (
              <div className="custom-scroll h-[17.6875rem] overflow-y-auto w-full ltr:pr-[1rem] rtl:pl-[1rem] pb-[1rem] inline-flex flex-col gap-[0.5rem]">
                {groupMembersList
                  ?.filter(member => member.userID !== userState.username)
                  .map((user: ZIMGroupMemberInfo) => (
                    <PlayerAvatar
                      altLabel={translate(IMAGE_ALT_LABELS.group_member_avatar as TxKeyPath) ?? ''}
                      containerStyle="w-full py-[0.5rem] gap-[0.5rem]"
                      displayNameStyle="font-regular text-fs-body text-secondary"
                      userIdStyle="font-regular text-fs-body-small text-secondary/50"
                      displayName={user.userName}
                      userId={user.userID}
                      avatarUrl={user.memberAvatarUrl}
                      avatarSize={3}
                      dir="row"
                      isAdmin={user.memberRole === 1}
                      customCTA={
                        <div
                          onClick={() => setAdmin(user.memberRole, user.userID)}
                          className="font-regular text-fs-body text-sunset cursor-pointer hover:text-sunset/80"
                        >
                          {user.userID === currentAdmin
                            ? translate('connect.more_options.remove')
                            : translate('connect.more_options.set_as_admin')}
                        </div>
                      }
                      isAdminLabelBottom
                    />
                  ))}
              </div>
            )}
        </div>
        <div className="flex w-full gap-[1rem]">
          <Button
            className={`flex-1 text-fs-body text-sunset font-medium border-[0.063rem] border-sunset rounded-sm px-[1.5rem] py-[0.5rem] ${
              !isRTL ? `after:bg-primary before:bg-sunset` : `before:bg-primary after:bg-sunset`
            } hover:text-white100`}
            text={leftBtnLabel}
            onClick={handleClose}
          />
          <Button
            className={`flex-1 text-fs-body font-medium rounded-sm px-[1.5rem] py-[0.5rem] ${
              !isButtonDisabled
                ? `text-white border-[0.063rem] border-sunset hover:text-sunset ${
                    !isRTL ? 'before:bg-white after:bg-sunset' : 'after:bg-white before:bg-sunset'
                  }`
                : 'bg-secondary/[0.1] cursor-not-allowed text-zinc-600'
            }`}
            text={rightBtnLabel}
            onClick={onClickAction}
            disabled={type === 'leave' && isButtonDisabled}
          />
        </div>
      </div>
    </Modal>
  );
};

export default OptionsActionModal;
