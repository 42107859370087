import ImagePickerModal from '@fe-web/Organisms/Modals/ImagePickerModal';
import { ContentType, useMediaUpload } from '@fe-monorepo/hooks';
import { t } from 'i18next';
import { useEffect } from 'react';

interface Props {
    close: () => void;
    changeImageFE: (url: string) => void;
    avatarType?: ContentType;
}

const GroupAvatarModal = (props: Props) => {
    const imageUpload = useMediaUpload();

    const save = async (imageFile: File, path: string) => {
        let imageUrl = undefined;
        if (imageFile) {
            imageUpload.getGeneratedLinks([{ file: imageFile, path: path }], Date.now().toString(), ContentType.group_avatar);
        }
    };

    useEffect(() => {
        if (imageUpload.mediaLinks[0]) {
            props.changeImageFE(imageUpload.mediaLinks[0]);
            props.close();
        }
    }, [imageUpload.mediaLinks])

    return (
        <ImagePickerModal
            isLoading={imageUpload.isLoading}
            imageShape={'circle'}
            title={t('profile.avatar.title')}
            subtitle={t('profile.avatar.description')}
            close={props.close}
            save={save}
        />
    );
};

export default GroupAvatarModal;
