import Modal from '@fe-web/Templates/Modal/Modal';
import { Oval } from 'react-loader-spinner';

interface LoadingIndicatorProps {
    open: boolean;
    height: number;
    width: number;
    strokeWidth: number;
    color: string;
}

const LoadingIndicator = ({open, height, width, strokeWidth, color} : LoadingIndicatorProps) => {
    return(
        <Modal open={open}>
            <div className={`
                flex flex-col items-center
                fixed top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]
            `}
            >
                <div className={'w-full'}>
                    <Oval
                        height={height}
                        width={width}
                        color={color}
                        secondaryColor={color}
                        visible={true}
                        ariaLabel="oval-loading"
                        strokeWidth={strokeWidth}
                        strokeWidthSecondary={strokeWidth}
                    />
                </div>
            </div>
            
        </Modal>
    )
}

export default LoadingIndicator;