export const WalletTransactionList = [
  {
    transaction_type_desc_en: 'Recharged balance',
    transaction_type_desc_ar: 'الرصيد المعاد شحنه',
    transaction_type_en: 'credit',
    transaction_type_ar: 'top-up',
    amount: 1,
    gateway_code: 'stcpay',
    posted_on: '2023-09-19 05:29:12',
    last_cursor: '2023-09-19T02:29:12.000Z',
    transaction_type_desc_code: 'top-up',
    wallet_type: 'individual',
    status_code: 'processed',
    transaction_type_code: 'credit',
  },
  {
    transaction_type_desc_en: 'tournament',
    transaction_type_desc_ar: 'بطولة',
    transaction_type_en: 'top_up',
    transaction_type_ar: 'tournament',
    amount: 1,
    gateway_code: 'stcpay',
    posted_on: '2023-09-11 12:43:31',
    last_cursor: '2023-09-11T09:43:31.000Z',
    transaction_type_desc_code: 'tournament',
    wallet_type: 'individual',
    status_code: 'processed',
    transaction_type_code: 'top_up',
  },
  {
    transaction_type_desc_en: 'Paid for 3 items in Shop',
    transaction_type_desc_ar: 'تم الدفع مقابل 3 عناصر في المتجر',
    transaction_type_en: 'debit',
    transaction_type_ar: 'top-up',
    amount: -1,
    gateway_code: 'stcpay',
    posted_on: '2023-08-01 05:29:12',
    last_cursor: '2023-08-01T02:29:12.000Z',
    transaction_type_desc_code: 'top-up',
    wallet_type: 'individual',
    status_code: 'processed',
    transaction_type_code: 'debit',
  },
  {
    transaction_type_desc_en: 'purchase_by_tabby',
    transaction_type_desc_ar: 'شراء عن طريق tabby',
    transaction_type_en: 'used',
    transaction_type_ar: 'purchase_by_tabby',
    amount: -1380,
    gateway_code: 'tabby',
    posted_on: '2023-05-18 09:00:16',
    last_cursor: '2023-05-18T06:00:16.000Z',
    transaction_type_desc_code: 'purchase_by_tabby',
    wallet_type: 'individual',
    status_code: 'processed',
    transaction_type_code: 'used',
  },
  {
    transaction_type_desc_en: 'purchase_by_tabby',
    transaction_type_desc_ar: 'شراء عن طريق tabby',
    transaction_type_en: 'top_up',
    transaction_type_ar: 'purchase_by_tabby',
    amount: 1380,
    gateway_code: 'tabby',
    posted_on: '2023-04-18 09:00:16',
    last_cursor: '2023-04-18T06:00:16.000Z',
    transaction_type_desc_code: 'purchase_by_tabby',
    wallet_type: 'individual',
    status_code: 'processed',
    transaction_type_code: 'top_up',
  },
  {
    transaction_type_desc_en: 'purchase_by_stc_play',
    transaction_type_desc_ar: 'دفع من محفظة stcplay',
    transaction_type_en: 'used',
    transaction_type_ar: 'purchase_by_stc_play',
    amount: -1380,
    gateway_code: 'stcplay',
    posted_on: '2023-03-15 12:40:17',
    last_cursor: '2023-03-15T09:40:17.000Z',
    transaction_type_desc_code: 'purchase_by_stc_play',
    wallet_type: 'individual',
    status_code: 'processed',
    transaction_type_code: 'used',
  },
  {
    transaction_type_desc_en: 'purchase_by_stc_play',
    transaction_type_desc_ar: 'دفع من محفظة stcplay',
    transaction_type_en: 'used',
    transaction_type_ar: 'purchase_by_stc_play',
    amount: -50,
    gateway_code: 'stcplay',
    posted_on: '2023-02-15 12:37:58',
    last_cursor: '2023-02-15T09:37:58.000Z',
    transaction_type_desc_code: 'purchase_by_stc_play',
    wallet_type: 'individual',
    status_code: 'processed',
    transaction_type_code: 'used',
  },
  {
    transaction_type_desc_en: 'Gift from stc play',
    transaction_type_desc_ar: 'هدية من stc play',
    transaction_type_en: 'top_up',
    transaction_type_ar: 'recharge_by_giftcard',
    amount: 50,
    gateway_code: 'stcplay',
    posted_on: '2023-02-15 12:19:29',
    last_cursor: '2023-02-15T09:19:29.000Z',
    transaction_type_desc_code: 'recharge_by_giftcard',
    wallet_type: 'individual',
    status_code: 'processed',
    transaction_type_code: 'top_up',
  },
  {
    transaction_type_desc_en: 'purchase_by_stc_play',
    transaction_type_desc_ar: 'دفع من محفظة stcplay',
    transaction_type_en: 'used',
    transaction_type_ar: 'purchase_by_stc_play',
    amount: -1380,
    gateway_code: 'stcplay',
    posted_on: '2022-12-11 07:12:07',
    last_cursor: '2022-12-11T04:12:07.000Z',
    transaction_type_desc_code: 'purchase_by_stc_play',
    wallet_type: 'individual',
    status_code: 'processed',
    transaction_type_code: 'used',
  },
  {
    transaction_type_desc_en: 'purchase_by_stc_play',
    transaction_type_desc_ar: 'دفع من محفظة stcplay',
    transaction_type_en: 'used',
    transaction_type_ar: 'purchase_by_stc_play',
    amount: -1380,
    gateway_code: 'stcplay',
    posted_on: '2022-12-11 06:56:54',
    last_cursor: '2022-12-11T03:56:54.000Z',
    transaction_type_desc_code: 'purchase_by_stc_play',
    wallet_type: 'individual',
    status_code: 'processed',
    transaction_type_code: 'used',
  },
];

export interface WalletTransactionListModel {
  transaction_type_code?: string;
  transaction_type_en?: string;
  transaction_type_ar?: string;
  transaction_type_desc_code?: string;
  transaction_type_desc_en?: string;
  transaction_type_desc_ar?: string;
  amount?: number;
  gateway_code?: string;
  posted_on?: string;
  wallet_type?: string;
  status_code?: string;
  last_cursor?: string;
}

interface IUser {
  userName: string;
  userID: string;
  avatar: string;
}

export interface IParticipantsMockData {
  id: number;
  user: IUser;
  gameID: string;
  fullName: string;
}

export interface TimezoneEntry {
  id: number;
  key: string;
  timezone: string;
  place: Place;
}

type Place =
  | 'Midway / Niue'
  | 'Riyadh / Amman / Kuwait'
  | 'French Polynesia'
  | 'Alaska'
  | 'British Columbia'
  | 'Pacific Standard Time'
  | 'Central Standard Time'
  | 'Eastern Standard Time'
  | 'Caracas'
  | 'Atlantic Standard Time'
  | 'Newfoundland'
  | 'Buenos Aires / Greenland'
  | 'Mid-Atlantic Standard Time'
  | 'Azores Standard Time'
  | 'GMT Standard Time'
  | 'Amsterdam / Berlin / Rome / Stockholm'
  | 'Cairo / Beirut / Athens'
  | 'Abu Dhabi / Muscat'
  | 'Kabul'
  | 'Maldives'
  | 'India Standard Time'
  | 'Dhaka'
  | 'Yangon'
  | 'Jakarta / Bangkok'
  | 'Hong Kong / Beijing / Singapore'
  | 'Tokyo / Seoul'
  | 'Melbourne / Sydney'
  | 'Lord Howe'
  | 'Vladivostok'
  | 'Auckland';

export const PARTICIPANTS_MOCK_DATA: Array<IParticipantsMockData> = [
  {
    id: 1,
    user: {
      userName: 'Khaled',
      userID: '@jhsbd',
      avatar: 'https://via.placeholder.com/40x40',
    },
    gameID: 'GameID',
    fullName: 'Gamer One',
  },
  {
    id: 2,
    user: {
      userName: 'Aadd',
      userID: '@jhsbd',
      avatar: 'https://via.placeholder.com/40x40',
    },
    gameID: 'GameID',
    fullName: 'Gamer Two',
  },
  {
    id: 3,
    user: {
      userName: 'Sk8rB0y',
      userID: '@sk8rb0y',
      avatar: 'https://via.placeholder.com/40x40',
    },
    gameID: 'GameID',
    fullName: 'Gamer Three',
  },
  {
    id: 4,
    user: {
      userName: 'Pewdiepie',
      userID: '@poods',
      avatar: 'https://via.placeholder.com/40x40',
    },
    gameID: 'GameID',
    fullName: 'Felix Kjellberg',
  },
];

export const timezoneData: TimezoneEntry[] = [
  { id: 0, key: 'GMT-11:00', timezone: 'GMT-11:00 (Midway / Niue)', place: 'Midway / Niue' },
  { id: 1, key: 'GMT+03:00', timezone: 'GMT+03:00 (Riyadh / Amman / Kuwait)', place: 'Riyadh / Amman / Kuwait' },
  { id: 2, key: 'GMT-09:30', timezone: 'GMT-09:30 (French Polynesia)', place: 'French Polynesia' },
  { id: 3, key: 'GMT-09:00', timezone: 'GMT-09:00 (Alaska)', place: 'Alaska' },
  { id: 4, key: 'GMT-08:00', timezone: 'GMT-08:00 (British Columbia)', place: 'British Columbia' },
  { id: 5, key: 'GMT-07:00', timezone: 'GMT-07:00 (Pacific Standard Time)', place: 'Pacific Standard Time' },
  { id: 6, key: 'GMT-06:00', timezone: 'GMT-06:00 (Central Standard Time)', place: 'Central Standard Time' },
  { id: 7, key: 'GMT-05:00', timezone: 'GMT-05:00 (Eastern Standard Time)', place: 'Eastern Standard Time' },
  { id: 8, key: 'GMT-04:30', timezone: 'GMT-04:30 (Caracas)', place: 'Caracas' },
  { id: 9, key: 'GMT-04:00', timezone: 'GMT-04:00 (Atlantic Standard Time)', place: 'Atlantic Standard Time' },
  { id: 10, key: 'GMT-03:30', timezone: 'GMT-03:30 (Newfoundland)', place: 'Newfoundland' },
  { id: 11, key: 'GMT-03:00', timezone: 'GMT-03:00 (Buenos Aires / Greenland)', place: 'Buenos Aires / Greenland' },
  { id: 12, key: 'GMT-02:00', timezone: 'GMT-02:00 (Mid-Atlantic Standard Time)', place: 'Mid-Atlantic Standard Time' },
  { id: 13, key: 'GMT-01:00', timezone: 'GMT-01:00 (Azores Standard Time)', place: 'Azores Standard Time' },
  { id: 14, key: 'GMT+00:00', timezone: 'GMT+00:00 (GMT Standard Time)', place: 'GMT Standard Time' },
  {
    id: 15,
    key: 'GMT+01:00',
    timezone: 'GMT+01:00 (Amsterdam / Berlin / Rome / Stockholm)',
    place: 'Amsterdam / Berlin / Rome / Stockholm',
  },
  { id: 16, key: 'GMT+02:00', timezone: 'GMT+02:00 (Cairo / Beirut / Athens)', place: 'Cairo / Beirut / Athens' },
  { id: 17, key: 'GMT+04:00', timezone: 'GMT+04:00 (Abu Dhabi / Muscat)', place: 'Abu Dhabi / Muscat' },
  { id: 18, key: 'GMT+04:30', timezone: 'GMT+04:30 (Kabul)', place: 'Kabul' },
  { id: 19, key: 'GMT+05:00', timezone: 'GMT+05:00 (Maldives)', place: 'Maldives' },
  { id: 20, key: 'GMT+05:30', timezone: 'GMT+05:30 (India Standard Time)', place: 'India Standard Time' },
  { id: 21, key: 'GMT+06:00', timezone: 'GMT+06:00 (Dhaka)', place: 'Dhaka' },
  { id: 22, key: 'GMT+06:30', timezone: 'GMT+06:30 (Yangon)', place: 'Yangon' },
  { id: 23, key: 'GMT+07:00', timezone: 'GMT+07:00 (Jakarta / Bangkok)', place: 'Jakarta / Bangkok' },
  { id: 24, key: 'GMT+08:00', timezone: 'GMT+08:00 (Hong Kong / Beijing / Singapore)', place: 'Hong Kong / Beijing / Singapore' },
  { id: 25, key: 'GMT+09:00', timezone: 'GMT+09:00 (Tokyo / Seoul)', place: 'Tokyo / Seoul' },
  { id: 26, key: 'GMT+10:00', timezone: 'GMT+10:00 (Melbourne / Sydney)', place: 'Melbourne / Sydney' },
  { id: 27, key: 'GMT+10:30', timezone: 'GMT+10:30 (Lord Howe)', place: 'Lord Howe' },
  { id: 28, key: 'GMT+11:00', timezone: 'GMT+11:00 (Vladivostok)', place: 'Vladivostok' },
  { id: 29, key: 'GMT+12:00', timezone: 'GMT+12:00 (Auckland)', place: 'Auckland' },
];

export const placeToIanaMapping: { [P in Place]: string[] } = {
  'Midway / Niue': ['Pacific/Midway', 'Pacific/Niue'],
  'Riyadh / Amman / Kuwait': ['Asia/Riyadh', 'Asia/Amman', 'Asia/Kuwait'],
  'French Polynesia': ['Pacific/Marquesas'],
  Alaska: ['America/Anchorage'],
  'British Columbia': ['America/Vancouver'],
  'Pacific Standard Time': ['America/Los_Angeles'],
  'Central Standard Time': ['America/Chicago'],
  'Eastern Standard Time': ['America/New_York'],
  Caracas: ['America/Caracas'],
  'Atlantic Standard Time': ['America/Halifax'],
  Newfoundland: ['America/St_Johns'],
  'Buenos Aires / Greenland': ['America/Argentina/Buenos_Aires', 'America/Godthab'],
  'Mid-Atlantic Standard Time': ['America/Noronha'],
  'Azores Standard Time': ['Atlantic/Azores'],
  'GMT Standard Time': ['Etc/UTC'],
  'Amsterdam / Berlin / Rome / Stockholm': ['Europe/Amsterdam', 'Europe/Berlin', 'Europe/Rome', 'Europe/Stockholm'],
  'Cairo / Beirut / Athens': ['Africa/Cairo', 'Asia/Beirut', 'Europe/Athens'],
  'Abu Dhabi / Muscat': ['Asia/Dubai', 'Asia/Muscat'],
  Kabul: ['Asia/Kabul'],
  Maldives: ['Indian/Maldives'],
  'India Standard Time': ['Asia/Calcutta'],
  Dhaka: ['Asia/Dhaka'],
  Yangon: ['Asia/Yangon'],
  'Jakarta / Bangkok': ['Asia/Jakarta', 'Asia/Bangkok'],
  'Hong Kong / Beijing / Singapore': ['Asia/Hong_Kong', 'Asia/Shanghai', 'Asia/Singapore'],
  'Tokyo / Seoul': ['Asia/Tokyo', 'Asia/Seoul'],
  'Melbourne / Sydney': ['Australia/Melbourne', 'Australia/Sydney'],
  'Lord Howe': ['Australia/Lord_Howe'],
  Vladivostok: ['Asia/Vladivostok'],
  Auckland: ['Pacific/Auckland'],
};

export const languageMapping: Record<string, string> = {
  en: 'ENGLISH',
  ar: 'ARABIC',
};

export interface PageMapping {
  pageName: string;
  pageId: string;
}

export type HelpMenuOptionToPageMapping = {
  [key: string]: PageMapping;
};

export const helpMenuOptionToPageMapping: HelpMenuOptionToPageMapping = {
  'Join stc play': { pageName: 'SETTINGS - HELP - JOIN STC PLAY PAGE', pageId: 'help_join_stcplay_page' },
  Profile: { pageName: 'SETTINGS - HELP - PROFILE PAGE', pageId: 'help_profile_page' },
  Settings: { pageName: 'SETTINGS - HELP - SETTINGS PAGE', pageId: 'help_settings_page' },
  Bits: { pageName: 'SETTINGS - HELP - BITS PAGE', pageId: 'help_bits_page' },
  Play: { pageName: 'SETTINGS - HELP - PLAY PAGE', pageId: 'help_play_page' },
  'Shopping & delivery': { pageName: 'SETTINGS - HELP - SHOPPING AND DELIVERY PAGE', pageId: 'help_shopping_and_delivery_page' },
  'Exchange & returns': { pageName: 'SETTINGS - HELP - EXCHANGE AND RETURN PAGE', pageId: 'help_exchange_and_return_page' },
  Chat: { pageName: 'SETTINGS - HELP - CHAT PAGE', pageId: 'help_chat_page' },
  Other: { pageName: 'SETTINGS - HELP - OTHER PAGE', pageId: 'help_other_page' },
  'Privacy policy': { pageName: 'SETTINGS - HELP - PRIVACY POLICY PAGE', pageId: 'help_privacy_policy_page' },
  'Terms & Conditions': { pageName: 'SETTINGS - HELP - TERMS AND CONDITION PAGE', pageId: 'help_terms_and_condition_page' },
};

export const searchResultsTabEventMapping: Record<string, { pageName: string; pageID: string }> = {
  all: { pageName: 'GLOBAL SEARCH - SEARCH RESULT PAGE - ALL TAB', pageID: 'search_result_all_tab' },
  bit: { pageName: 'GLOBAL SEARCH - SEARCH RESULT PAGE - BITS TAB', pageID: 'search_result_bits_tab' },
  shop: { pageName: 'GLOBAL SEARCH - SEARCH RESULT PAGE - SHOP TAB', pageID: 'search_result_shop_tab' },
  user: { pageName: 'GLOBAL SEARCH - SEARCH RESULT PAGE - USERS TAB', pageID: 'search_result_users_tab' },
  tournament: { pageName: 'GLOBAL SEARCH - SEARCH RESULT PAGE - TOURNAMENT TAB', pageID: 'search_result_tournament_tab' },
  stream: { pageName: 'GLOBAL SEARCH - SEARCH RESULT PAGE - STREAM TAB', pageID: 'search_result_stream_tab' },
};

export const genderMapping: Record<string, string> = {
  male: 'm',
  female: 'f',
  none: 'p',
};
