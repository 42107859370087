import { useTranslate } from '@fe-monorepo/hooks';
import { RootHashtag } from '@fe-web/types/bitTypes';
import { AppRoutes } from 'apps/fe-web/src/app/app.routes.enum';
import { useNavigate } from 'react-router-dom';

export type TagType = {
  id: string | number;
  name?: string;
  label: string;
};

const HashtagResult = (props: { hashtags: RootHashtag[] }) => {
  const { hashtags } = props;
  const navigate = useNavigate();
  const { translate } = useTranslate();

  const Item = (props: {
    label: string;
    onClick: (label: string, hashtagChallenge: number) => void;
    videoCount: number;
    hashtagChallenge: number;
  }) => {
    const { label, onClick, videoCount, hashtagChallenge } = props;
    if (videoCount <= 0) {
      return null;
    }
    return (
      <li
        className="
          select-none cursor-pointer
          font-regular
          flex
          gap-[0.75rem]
          whitespace-nowrap
          responsive-text-bodySmall
          py-4 2K:py-8 4K:py-12 8K:py-20
          px-8 2K:px-12 4K:px-20 8K:px-44
          rounded-[2px] 2K:rounded-[3.55px] 4K:rounded-[5.33px] 8K:rounded-[10.66px]
          "
        onClick={() => onClick(label, hashtagChallenge)}
      >
        <div
          className="w-[2rem] h-[2rem]
            rounded-full text-bodyLarge flex items-center
            justify-center bg-secondary/40 
            "
        >
          #
        </div>
        <div className="flex flex-col">
          <div className="text-moonlight">{`#${label.charAt(0).toUpperCase() + label.substring(1)}`}</div>
          <div className="text-secondary text-caption">{`${videoCount} ${translate('quickplay.search-video')}`}</div>
        </div>
      </li>
    );
  };

  const hashtagClick = (tagParam: string, hashtagChallenge: number) => {
    // navigate(`${AppRoutes.bitsPlay}/content/${tagParam}?contentType=hashtag`);
    if (hashtagChallenge === 1) {
      navigate(`${AppRoutes.bits}/challenge/${tagParam}`);
    } else {
      navigate(`${AppRoutes.bits}/hashtag/${tagParam}`);
    }
  };

  return (
    <ul
      className={`
          flex
          w-full
          gap-[1.5rem] 2K:gap-[28px] 4K:gap-[42px] 8K:gap-81
          overflow-x-scroll
        `}
    >
      {hashtags.map(hashtag => {
        return (
          <Item
            key={hashtag._id}
            label={hashtag.title}
            onClick={hashtagClick}
            videoCount={hashtag.videoCount}
            hashtagChallenge={hashtag.isChallenge}
          />
        );
      })}
    </ul>
  );
};

export default HashtagResult;
