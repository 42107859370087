import { useMutation } from '@apollo/client';
import { OTP_MUTATION } from '@fe-monorepo/data-access';
import { OtpModel } from '@fe-monorepo/models';
import { useEffect, useState } from 'react';

import { useAppState, useMutateQuery, useTranslate } from '../index';

/*** OTP ***/
type OtpResponse = {
  requestOTP: {
    is_successful: boolean;
    error_code: string;
    error_msg: string;
    data: OtpData;
  };
};

type VerifiedOtpResponse = {
  verifyOTP: {
    is_successful: boolean;
    error_code: string;
    error_msg: string;
    data: OtpData;
  };
};

type OtpParams = {
  details: OtpModel;
};

type OtpData = {
  token: string;
  email: string;
};

export const useOtp = () => {
  const [isRequestStatus, setRequestStatus] = useState<boolean>();
  const [isVerifiedStatus, setVerifiedStatus] = useState<boolean>();
  const [requestOTPErrorMsg, setRequestOTPErrorMsg] = useState<string>('');
  const [verifyOTPErrorMsg, setVerifyOTPErrorMsg] = useState<string>('');
  const [verifiedErrorMsg, setVerifiedErrorMsg] = useState<string>('');
  const [verifiedErrorCode, setVerifiedErrorCode] = useState<string>('');
  const [tempToken, setTempToken] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [isServerError, setServerErrorStatus] = useState<boolean>(false);
  const [isOTPLocked, setOTPLockedStatus] = useState<boolean>(false);

  const { changeLoadingState } = useAppState();
  const { translate } = useTranslate();

  const [requestOTPGql, { data: requestOTPResponse, error: requestOTPError, loading: requestOTPLoading }] = useMutation<
    OtpResponse,
    OtpParams
  >(OTP_MUTATION.requestOTP, {
    onError: error => {
      changeLoadingState(false);
      // This is required to prevent unhandled promise errors due to network and invalid token errors
    },
  });

  const [verifyOTPGql, { data: verifyOTPResponse, error: verifyOTPError, loading }] = useMutation<VerifiedOtpResponse, OtpParams>(
    OTP_MUTATION.verifyOTP,
    {
      onError: error => {
        changeLoadingState(false);
        setServerErrorStatus(true);
        // This is required to prevent unhandled promise errors due to network and invalid token errors
      },
    },
  );

  const deleteOTPError = () => {
    setVerifiedErrorMsg('');
    setVerifiedErrorCode('');
    setOTPLockedStatus(false);
  };

  const requestOTP = async (model: OtpModel, disableLoadingState?: boolean) => {
    !disableLoadingState && changeLoadingState(true);
    setOTPLockedStatus(false);
    return requestOTPGql({
      variables: { details: model },
    });
  };

  const verifyOTP = async (model: OtpModel, disableLoadingState?: boolean) => {
    !disableLoadingState && changeLoadingState(true);
    setServerErrorStatus(false);
    deleteOTPError();
    return verifyOTPGql({
      variables: { details: model },
    });
  };

  useEffect(() => {
    if (requestOTPError) {
      setRequestOTPErrorMsg(requestOTPError?.message ? requestOTPError?.message : '');
      setRequestStatus(false);
    }
  }, [requestOTPError]);

  useEffect(() => {
    if (verifyOTPError) {
      setVerifyOTPErrorMsg(verifyOTPError?.message ? verifyOTPError?.message : '');
      setVerifiedStatus(false);
    }
  }, [verifyOTPError]);

  useEffect(() => {
    if (requestOTPResponse) {
      const response = requestOTPResponse.requestOTP;
      setRequestOTPErrorMsg(response?.error_msg);
      setRequestStatus(response?.is_successful);
      setTempToken(response?.data?.token);
      setEmail(response?.data?.email);
      changeLoadingState(loading);
      setRequestStatus(true);
    }
  }, [requestOTPResponse]);

  useEffect(() => {
    if (verifyOTPResponse) {
      const response = verifyOTPResponse.verifyOTP;

      if (response) {
        if (response.error_code === '429') {
          const errorMessage = translate('account_security.two_auth.too_many') ?? '';

          setVerifiedErrorMsg(errorMessage);
          setVerifiedErrorCode(response.error_code);
          setOTPLockedStatus(true);
        } else {
          setVerifiedErrorCode(response.error_code);
          setVerifiedErrorMsg(response.error_msg);
        }
        setVerifiedStatus(response.is_successful);
        changeLoadingState(false);
      }
    }
  }, [verifyOTPResponse]);

  useEffect(() => {
    if (requestOTPError || verifyOTPError) {
      // ADD CODE HERE
      changeLoadingState(loading);
    }
  }, [requestOTPError, verifyOTPError]);

  return {
    requestOTP,
    verifyOTP,
    deleteOTPError,

    tempToken,
    email,
    isRequestStatus,
    isVerifiedStatus,
    requestOTPErrorMsg,
    requestOTPError,
    verifyOTPErrorMsg,
    verifyOTPError,
    verifiedErrorMsg,
    verifiedErrorCode,
    isServerError,
    isOTPLocked,
    requestOTPLoading,
  };
};

const defaultValue: OtpData = {
  token: '',
  email: '',
};

export const useOTPNew = () => {
  const OTPRequest = useMutateQuery<OtpData, OtpModel>(OTP_MUTATION.requestOTP, defaultValue);

  const OTPVerification = useMutateQuery<OtpData, OtpModel>(OTP_MUTATION.verifyOTP, defaultValue);

  return {
    OTPRequest,

    OTPVerification,
  };
};
