// services/ConvivaService.ts
import { ConvivaDeviceMetadata, ConvivaInitializationData, ConvivaMetadata, ConvivaSession, createConvivaSession } from 'fl-conviva';
import { MediaType, Player } from 'fl-player-interface';
import { getEnvironment } from 'libs/data-access/src/graphql/apiEnvironments';
import { RootState } from 'libs/store/src/lib/store';
import { useEffect, useState } from 'react';
import { isIOS, isMacOs, isMobile, mobileModel, osName, osVersion } from 'react-device-detect';
import { useSelector } from 'react-redux';

const useConvivaService = (
  player: any,
  mediaUrl: string,
  contentTitle: string,
  metadataType?: string,
  streamId?: string,
  resource?: any,
  adUrl?: string,
) => {
  let convivaMetadata: any;

  const [convivaSession, setConvivaSession] = useState<ConvivaSession | null>(null);
  const userContext = useSelector((state: RootState) => state.user.userContext);

  const deviceMetaData: ConvivaDeviceMetadata = {
    //@ts-ignore
    [Conviva.Constants.DeviceMetadata.CATEGORY]: Conviva.Constants.DeviceCategory.WEB,
    DeviceBrand: isMacOs || isIOS ? 'Apple' : 'Windows',
    DeviceManufacturer: isMacOs || isIOS ? 'Apple' : 'Windows',
    DeviceModel: isMobile ? mobileModel : osVersion,
    OperatingSystemName: osName,
    OperatingSystemVersion: osVersion,
    // ScreenWidth: 1200,
    // ScreenHeight: 700,
  };
  const initializeConvivaSession = () => {
    const config = getEnvironment();
    const { convivaAppTracker } = config;

    const initializationData: ConvivaInitializationData = {
      customerKey: convivaAppTracker.customerKey,
      debug: true,
      gatewayUrl: 'https://intigral-stc-play-test.testonly.conviva.com',
    };

    try {
      var flConvivaSession = createConvivaSession(initializationData, deviceMetaData);

      if (!flConvivaSession.isInitialized()) {
        flConvivaSession.initialize();
      } else {
        flConvivaSession.destroy();
        flConvivaSession.initialize();
      }
      flConvivaSession.attachPlayer(player);
      setConvivaMetaData(player, flConvivaSession, mediaUrl);
      reportPlaybackRequested(flConvivaSession, convivaMetadata);
    } catch (error) {
      console.error('Conviva initialization error', error);
    }
  };

  useEffect(() => {
    return () => {
      convivaSession?.destroy();
      setConvivaSession(null);
    };
  }, [player, resource, metadataType, streamId, mediaUrl, adUrl]);

  const setConvivaMetaData = (
    player: Player,
    session: ConvivaSession,
    mediaUrl?: string,
    // streamId?: string,
    // metadataType?: string,
    // resource?: any,
  ) => {
    convivaMetadata = {
      'Conviva.streamUrl': mediaUrl || ' ',
      'Conviva.applicationName': 'STC Web',
      'Conviva.assetName': contentTitle, //resource?.seasonNumber ? `${resource.seriesTitle} ; ${resource.name}` : resource?.name || resource?.title,
      'Conviva.duration': 0,
      'Conviva.viewerId': userContext?.uuid || userContext?.username || 'NA',
      'c3.cm.id': 'NA', //resource?.id
      'Conviva.streamType': player.isLive ? 'LIVE' : 'VOD',
      'c3.cm.contentType': MediaType.HLS,
      'c3.cm.channel': 'NA', //resource?.title
      'c3.cm.brand': 'Brand', //resource?.network
      'c3.cm.affiliate': 'NA',
      'c3.cm.categoryType': 'NA', //resource?.contentType
      'c3.cm.name': 'Web', //resource?.providerName
      'c3.cm.seriesName': 'NA', //resource?.contentType === 'tvepisode' ? resource.seriesTitle : 'NA'
      'c3.cm.seasonNumber': 'NA', //resource?.seasonNumber ? resource.seasonNumber.toString() : 'NA'
      'c3.cm.showTitle': 'NA',
      'c3.cm.episodeNumber': 'NA', //resource?.episodeNumber ? resource.episodeNumber.toString() : 'NA'
      'c3.cm.genre': 'NA', // resource?.contentGenre?.[0] || 'NA'
      'c3.cm.genreList': 'NA', //resource?.contentGenre?.toString() || 'NA'
      'c3.cm.utmTrackingUrl': 'NA',
      'Conviva.frameworkName': 'fl-platform-player',
      'Conviva.frameworkVersion': '7.0.93',
    };
    session.setContentMetadata(convivaMetadata);
  };
  const reportPlaybackRequested = (session: ConvivaSession, convivaMetadata: ConvivaMetadata) => {
    session.reportPlaybackRequested(convivaMetadata);
  };

  return {
    initialize: initializeConvivaSession,
    reportBitrate: (bitrate: number) => {
      convivaSession?.reportPlaybackMetricEvent('BITRATE', bitrate);
    },
    reportFramerate: () => {
      convivaSession?.reportPlaybackMetricEvent('RENDERED_FRAMERATE', 30);
    },
    reportPlayback: () => {
      convivaSession?.reportPlaybackMetricEvent('PLAYER_STATE', 'PLAYING');
    },
    reportError: (error: any) => {
      convivaSession?.reportError(error);
    },
    changeDuration: (duration: number) => {
      if (convivaSession) {
        convivaSession.setContentMetadata({ 'Conviva.duration': duration as any });
      }
    },
    destroy: () => {
      convivaSession?.destroy();
    },
  };
};

export default useConvivaService;
