import { IconNames } from "@fe-monorepo/helper";
import ResponsiveIcon from "@fe-web/Atoms/Icon/ResponsiveIcon";
import HoverText from "@fe-web/Atoms/Text/HoverText";
import { useNavigate } from "react-router-dom";

interface Props {
    message: string;
    hoverText?: string;
    navigateUrl?: string;
    messageStyle?: string;
    hoverTextStyle?: string;
    icon?: React.ReactNode;
    containerClassName?: string;
}

const YellowMessage = (props: Props) => {
    const navigate = useNavigate();

    return (
        <div className={`flex flex-col bg-[#FFC52F33] col-span-3 gap-[0.5rem] px-[1rem] py-[0.75rem] mb-[2rem] SD:mb-[1rem] ${props.containerClassName}`}>
            <div className="flex">
                {props.icon && props.icon}
                <p className={`${props.messageStyle} font-regular text-fs-body text-secondary`}>{props.message}</p>
            </div>
            { props.hoverText &&
                <HoverText
                className={`${props.hoverTextStyle}
                        font-regular
                        text-sunset
                        text-fs-body whitespace-nowrap flex
                        tracking-[-0.0125rem]
                        `}
                hover={{ color: 'text-sunset' }}
                alwaysShowUnderline={false}
                underlineStyle="bg-sunset
                    h-[0.0625rem] bottom-[0.125rem]"
                text={props.hoverText || ''}
                onClick={() => navigate(props.navigateUrl || '')}/>
            }
        </div>
    )
}

export default YellowMessage;