import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { REMOVE_DEVICE_MUTATION } from '@fe-monorepo/data-access';
import { RemoveDeviceParams, RemoveDeviceResponse } from './type';

export const useRemoveDevice = () => {
    const [isSuccessful, setStatus] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');

    const [removeDeviceParams, { data: removeDeviceParamsResponse, error: errorDevice }] = useMutation<RemoveDeviceResponse>(
        REMOVE_DEVICE_MUTATION.removeDevice,
        {
            errorPolicy: 'all',
        },
    );

    useEffect(() => {
        if (removeDeviceParamsResponse) {
            if (removeDeviceParamsResponse.removeDevice.is_successful) {
                setStatus(removeDeviceParamsResponse.removeDevice.is_successful);
            }
            if (removeDeviceParamsResponse.removeDevice.error_msg) {
                setErrorMessage(removeDeviceParamsResponse.removeDevice.error_msg);
            }
            setStatus(removeDeviceParamsResponse.removeDevice.is_successful);
        }
        setIsLoading(false);
    }, [removeDeviceParamsResponse]);

    useEffect(() => {
        if (errorDevice) {
            setError(errorDevice.message);
        }
        setIsLoading(false);
    }, [errorDevice]);

    const removeDevice = async (params: RemoveDeviceParams) => {
        setIsLoading(true);
        const { data } = await removeDeviceParams({ variables: { ...params } });
        setIsLoading(false);
        return data?.removeDevice;
    };

    return {
        removeDevice,
        isSuccessful,
        isLoading,
        error,
        errorMessage,
    };
};
