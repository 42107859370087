import { gql } from '@apollo/client';

export const SHIPMENT_MUTATION = {
    createShipment: gql`
    mutation createShipment($details: OrderShipmentInput) {
        createShipment(details: $details) {
            error_msg
            is_successful
            error_code
            data {
                shipment_number
            }
        }
    }
`,
}