import { gql } from '@apollo/client';

export const CONTACT_MUTATION = {
    commContactSend: gql`
        mutation commContactSend($details: CommContactSendInput) {
            commContactSend(details: $details) {
                error_msg
                is_successful
                error_code
                data {
                    ticket {
                        id
                    }
                }
            }
        }
    `,
};
