import { gql } from '@apollo/client';

export const CLOSEREG_MUTATION = {
    closeRegTournament: gql`
        mutation closeRegTournament($details: CloseRegInput) {
            closeRegTournament(details: $details) {
                is_successful
                error_msg
                error_code
            }
        }
    `,
};
