import { IconNames } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ResponsiveIcon from '../../../../../../components/Atoms/Icon/ResponsiveIcon';
import DropDown from '../../../../../../components/Molecules/InputFields/DropDown/DropDown';
import usePageLayout from '../../../../../../hooks/usePageLayout';

type ProductSort = { id: string; label: any; value: string };

interface DropdownProps {
  label: string;
  onStatusChange: (statusId: string) => void;
  shopSort: ProductSort[];
}

const Dropdown: React.FC<DropdownProps> = ({ label, onStatusChange, shopSort }) => {
  const [selectedStatus, setSelectedStatus] = useState<string>('0');

  const handleStatusChange = (statusId: string, statusvalue: string) => {
    setSelectedStatus(statusId);
    onStatusChange(statusvalue);
  };

  const { t, i18n } = useTranslation();
  const { translate } = useTranslate();
  const { language } = usePageLayout();

  const [selectedSort, setSelectedSort] = useState<ProductSort | null>(() => {
    return shopSort.find(item => item.id === '0') || null;
  });

  useEffect(() => {
    setSelectedSort(shopSort.find(item => item.id === '0') || null);
  }, [i18n.language, shopSort]);

  return (
    <div className={`flex flex-row items-center w-full self-stretch rounded-[4px] gap-[1rem] mix-blend-normal`}>
      <label className="text-secondary">
        <span className="text-secondary font-medium text-fs-body-small">{translate('common_status')}</span>
      </label>
      <div
        className={`rounded-[2px] py-[3px] border 2K:border-2 4k:border-4 8K:border-8 border-neutral-300-zinc-700 w-full self-stretch SD:w-[12rem] FHD:w-[14rem]`}
      >
        <DropDown<ProductSort>
          style={{
            container: 'justify-between',

            text: 'SD:flex relative text-secondary text-fs-body-small flex flex-col justify-start gap-[0.5rem] items-end px-3 py-[6px] font-regular',
            wrapper: 'relative',
            tooltip: 'w-full',
            tooltipContainer: 'w-full',
            listContainer: `w-full absolute mdMaxLl:text-fs-caption ${language === 'en' ? 'SD:right-0 left-0' : 'SD:left-0 right-0'}`,
          }}
          selectedOption={selectedSort ?? shopSort[0]}
          selectedIcon={
            <ResponsiveIcon
              className={`z-[1] absolute ${language === 'en' ? 'right-[0.625rem]' : 'left-[0.625rem]'}`}
              name={IconNames.checkFill}
              baseHeight={18.67}
              baseWidth={16.67}
            />
          }
          selectedIconPosition="end"
          options={shopSort}
          getStringValue={(option: ProductSort) => t(option.label)}
          retreiveSelection={(option: ProductSort) => {
            setSelectedSort(option);
            handleStatusChange(option.id, option.value);
          }}
        />
      </div>
    </div>
  );
};

export default Dropdown;
