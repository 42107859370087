import {
  IconNames,
  Logger,
  PAGE_DESCRIPTION,
  PAGE_KEYWORDS,
  PAGE_TITLE,
  TxKeyPath,
  ZEGO_CLOUD_CONFIG,
  generateGroupId,
} from '@fe-monorepo/helper';
import { GetChatInfoDataModel, useTranslate } from '@fe-monorepo/hooks';
import { ChatEventType, ChatType, EventType, NotificationMessageSendInput } from '@fe-monorepo/models';
import { RootState } from '@fe-monorepo/store';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import ConnectSearchBar from '@fe-web/Molecules/ConnectSearchBar';
import CustomHelmet from '@fe-web/Organisms/CustomHelmet/CustomHelmet';
import UserList from '@fe-web/Organisms/UserList';
import { useConnectConversationProvider, useConnectProvider } from '@fe-web/hooks/useConnect';
import { useNotificationProvider } from '@fe-web/hooks/useNotification';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ZIMPushConfig } from 'zego-zim-web';

import { AppRoutes } from '../../../../app.routes.enum';
import MessageContent from '../../FullViewMessage/components/MessageContent';
import { ChatInput } from '../../FullViewMessage/components/PrivateMessage';

interface NewChatProps {
  onClose?: () => void;
  isFullView?: boolean;
  isInviteUsers?: boolean;
  onCloseInvite?: () => void;
}

const NewChat = ({ onClose, isFullView, isInviteUsers, onCloseInvite }: NewChatProps) => {
  const { translate } = useTranslate();
  const {
    suggestions,
    filteredUsers,
    selectedUsers,
    search,
    setSelectedUsers,
    onRemoveSelectedUser,
    isSearching,
    conversationID,
    onSendMessage,
  } = useConnectConversationProvider();
  const [isFocus, setIsFocus] = useState<boolean>();
  const [value, setValue] = useState<string>();
  const { sendMessage, createGroup, openChat, inviteUsersIntoGroup, queryGroupMemberList } = useConnectProvider();
  const userContext = useSelector((state: RootState) => state.user.userContext);
  const isRTL = useSelector((state: RootState) => state.app.isRTL);
  const navigate = useNavigate();
  const location = useLocation();
  const [isMaxedOut, setIsMaxedOut] = useState<boolean>(false);
  const [filteredGroupMemberList, setFilteredGroupMemberList] = useState<GetChatInfoDataModel[] | undefined>();
  const [suggestedGroupMemberList, setSuggestedGroupMemberList] = useState<GetChatInfoDataModel[] | undefined>();
  const { GROUP_MEMBER_COUNT } = ZEGO_CLOUD_CONFIG;
  const { sendNotification } = useNotificationProvider();
  const { log, error } = Logger();

  const getAllFilteredGroupMembers = () => {
    conversationID &&
      queryGroupMemberList &&
      queryGroupMemberList(conversationID, { count: GROUP_MEMBER_COUNT, nextFlag: 0 }).then(res => {
        if (res?.userList && filteredUsers) {
          const groupMemberList = res.userList.map(item => item.userName && item.userID);
          const filteredGroupMembers = filteredUsers.filter(item => !groupMemberList.includes(item.username));
          setFilteredGroupMemberList(filteredGroupMembers);
        }
        if (res?.userList && suggestions) {
          const suggestedMemberList = res.userList.map(item => item.userID && item.userID);
          const suggestedGroupmembers = suggestions.filter(item => !suggestedMemberList.includes(item.username));
          setSuggestedGroupMemberList(suggestedGroupmembers);
        }
      });
  };

  const onClickUser = (user: GetChatInfoDataModel) => {
    setSelectedUsers && setSelectedUsers([...(selectedUsers || []), user]);
    setTimeout(() => setValue(''), 200);
  };

  const inviteMessage = async (message: string, args?: object) => {
    const extendedDataAction = { action: ChatEventType.NEW_JOINED, ...args };
    onSendMessage && (await onSendMessage({ message: message, type: 1, extendedData: JSON.stringify(extendedDataAction) }));
  };

  useEffect(() => {
    if (isInviteUsers) {
      const invitedUsers = selectedUsers?.map(item => item.username.toString());
      if (invitedUsers && invitedUsers?.length > 0 && conversationID) {
        inviteUsersIntoGroup &&
          inviteUsersIntoGroup(invitedUsers, conversationID)
            .then(res => {
              if (res?.errorUserList && res?.errorUserList.length <= 0) {
                inviteMessage(`connect.event_message.joined_the_chat|${invitedUsers.join(inviteMessage.length > 1 ? ',' : '')}`, {
                  message: 'connect.event_message.joined_the_chat',
                  data: invitedUsers.join(inviteMessage.length > 1 ? ',' : ''),
                }).then(response => {
                  log('group message response >>>', response);
                });
                onCloseInvite && onCloseInvite();
                setSelectedUsers && setSelectedUsers([]);
              }
            })
            .catch(err => {
              error('private message error >>>', err);
            });
      }
      getAllFilteredGroupMembers();
    }
    if (selectedUsers && selectedUsers?.length + 1 > 100) {
      //+1 is the group creator;
      setIsMaxedOut(true);
    } else {
      setIsMaxedOut(false);
    }
  }, [selectedUsers]);

  useEffect(() => {
    getAllFilteredGroupMembers();
  }, [filteredUsers, suggestions]);

  useEffect(() => {
    const delay = setTimeout(() => search && search(value), 200);
    return () => clearTimeout(delay);
  }, [value]);

  const handleSendMessage = (message: string) => {
    if (selectedUsers && selectedUsers.length > 0) {
      if (selectedUsers.length === 1) {
        const messageObj = {
          type: 1,
          message: message,
          extendedData: '',
        };
        let conversationType = 0;
        const toConversationID = selectedUsers[0].username;

        let pushTitle = translate('chatOfflinePushNotification.privateTitle', { displayName: userContext?.display_name }) || '';
        let pushContent = message;

        const pushConfig: ZIMPushConfig = {
          title: pushTitle,
          content: pushContent,
        };

        const messageConfig = {
          priority: 1,
          hasReceipt: true,
          pushConfig: pushConfig,
        };

        sendMessage &&
          sendMessage(messageObj, toConversationID, conversationType, messageConfig)
            .then(response => {
              log('private message response >>>', response);
              if (response?.message) {
                //TODO: Handle successful create private chat
                if (
                  location.pathname.includes(AppRoutes.messages) &&
                  location.pathname !== AppRoutes.messages + `/${response?.message?.conversationID}/${response?.message?.conversationType}`
                ) {
                  navigate(AppRoutes.messages + `/${response?.message?.conversationID}/${response?.message?.conversationType}`);
                  return;
                }
                openChat &&
                  openChat(
                    {
                      conversationID: response?.message?.conversationID,
                      conversationType: response?.message?.conversationType,
                      type: ChatType.EXISTING,
                    },
                    true,
                  );
                let recipients: any = [toConversationID];
                let avatarURL = userContext?.avatar_url || '';
                const body = isRTL
                  ? `${t('chatMobilePushNotification.privateTitle', {
                      message: response.message.message.toString(),
                      lng: 'ar',
                    })}`
                  : `${t('chatMobilePushNotification.privateTitle', {
                      message: response.message.message.toString(),
                      lng: 'en',
                    })}`;

                const messageNotificationInput: NotificationMessageSendInput = {
                  event_type: EventType.chatPrivateTextSent,
                  title: userContext?.username,
                  body: body,
                  body_en: `${t(response.message.message.toString() || '', { lng: 'en' })}`,
                  body_ar: `${t(response.message.message.toString() || '', { lng: 'ar' })}`,
                  bodyHTML: `${t(response.message.message.toString() || '', { lng: 'en' })}`,
                  bodyHTML_en: `${t(response.message.message.toString() || '', { lng: 'en' })}`,
                  bodyHTML_ar: `${t(response.message.message.toString() || '', { lng: 'ar' })}`,
                  recipients: JSON.stringify(recipients),
                  avatar_url: avatarURL,
                  conversation_id: userContext?.username,
                  conversation_type: 'private',
                  conversation_name: userContext?.username,
                  sender_username: userContext?.username || '',
                  type: 'text',
                };
                sendNotification?.(messageNotificationInput);
              }
            })
            .catch(err => {
              error('private message error >>>', err);
              // TODO: Handle failed creation of private chat
            });
      } else {
        const userNames = selectedUsers.map(item => item.display_name);
        let userIds: string[] = selectedUsers.map(item => item.username.toString());
        const groupNames = (userNames.toString() + ',' + userContext.display_name).substring(0, 25);
        const groupid = generateGroupId(15, 'stcgroupchat');
        var groupInfo = { groupID: groupid, groupName: groupNames, groupAvatarUrl: '' };
        var config = {
          groupNotice: '',
          groupAttributes: { key1: 'value1', key2: 'value2' },
        };
        createGroup &&
          createGroup(groupInfo, userIds, config)
            .then(response => {
              log('create group response >>>', {
                groupInfo: response?.groupInfo,
                errorUserList: response?.errorUserList,
                userList: response?.userList,
              });
              let conversationType = 2;
              const messageObj = {
                type: 1,
                message: message,
                extendedData: '',
              };
              let pushTitle =
                translate('chatOfflinePushNotification.groupTitle', {
                  displayName: userContext?.display_name,
                  groupName: groupInfo?.groupName,
                }) || '';
              let pushContent = `${userContext?.display_name}: ${message}`;

              const pushConfig: ZIMPushConfig = {
                title: pushTitle,
                content: pushContent,
              };

              const messageConfig = {
                priority: 1,
                hasReceipt: true,
                pushConfig: pushConfig,
              };

              if (response?.groupInfo) {
                sendMessage &&
                  sendMessage(messageObj, groupid, conversationType, messageConfig)
                    .then(resp => {
                      log('create group sendMessage response>>>', resp);
                      if (resp?.message && resp.message.conversationID) {
                        //TODO: Handle successful send message on create group chat
                        if (
                          location.pathname.includes(AppRoutes.messages) &&
                          location.pathname !== AppRoutes.messages + `/${resp?.message?.conversationID}/${resp?.message?.conversationType}`
                        ) {
                          navigate(AppRoutes.messages + `/${resp?.message?.conversationID}/${resp?.message?.conversationType}`);
                          return;
                        }
                        openChat &&
                          openChat(
                            {
                              conversationID: resp?.message?.conversationID,
                              conversationType: resp?.message?.conversationType,
                              type: ChatType.EXISTING,
                            },
                            true,
                          );

                        let recipients: any = [];
                        let avatarURL = `${userContext?.avatar_url || ''},`;
                        recipients = selectedUsers.map(member => member.username);
                        const body = isRTL
                          ? `${resp.message.message.toString()} :${userContext?.username}` || ''
                          : `${userContext?.username}: ${resp.message.message.toString()}` || '';
                        const messageNotificationInput: NotificationMessageSendInput = {
                          event_type: conversationType === 2 ? EventType.chatGroupTextSent : EventType.chatPrivateTextSent,
                          title: response.groupInfo?.baseInfo.groupName || '',
                          body: body,
                          body_en: `${t(resp.message.message.toString() || '', { lng: 'en' })}`,
                          body_ar: `${t(resp.message.message.toString() || '', { lng: 'ar' })}`,
                          bodyHTML: `${t(resp.message.message.toString() || '', { lng: 'en' })}`,
                          bodyHTML_en: `${t(resp.message.message.toString() || '', { lng: 'en' })}`,
                          bodyHTML_ar: `${t(resp.message.message.toString() || '', { lng: 'ar' })}`,
                          recipients: JSON.stringify(recipients),
                          avatar_url: avatarURL,
                          conversation_id: response.groupInfo.baseInfo.groupID,
                          conversation_type: 'group',
                          conversation_name: response.groupInfo.baseInfo.groupName || '',
                          sender_username: userContext?.username || '',
                          type: 'text',
                        };
                        sendNotification?.(messageNotificationInput);
                      }
                    })
                    .catch(err => {
                      error('create group sendMessage error>>>', err);
                      // TODO: Need to delete group on Send Message error
                    });
              }
            })
            .catch(err => {
              error('create group error >>>', err);
              // TODO: Handle for failed creation of group
            });
      }
    }
  };

  return (
    <>
      {isFullView && (
        <CustomHelmet
          pageTitle={translate(PAGE_TITLE.connect as TxKeyPath) ?? ''}
          metaDescription={translate(PAGE_DESCRIPTION.connect as TxKeyPath) ?? ''}
          metaKeyWords={translate(PAGE_KEYWORDS.connect as TxKeyPath) ?? ''}
        />
      )}
      <div
        className={`${!isFullView ? 'h-[21.5rem]' : 'h-full'} relative flex flex-col divide-y divide-neutral-300-zinc-700 text-secondary`}
      >
        <div className="flex flex-col p-[1rem] gap-[0.75rem]">
          <div className="flex items-center justify-between gap-[0.5rem]">
            <span className="font-regular text-fs-body">
              {isInviteUsers
                ? translate('connect.more_options.invite_users')
                : selectedUsers && selectedUsers?.length > 1
                ? translate('chat.new_group_chat')
                : translate('chat.new_chat')}
            </span>
            {isInviteUsers ? (
              <button
                onClick={() => {
                  isInviteUsers ? onCloseInvite && onCloseInvite() : onClose && onClose();
                }}
              >
                <ResponsiveIcon name={IconNames.close} baseWidth={12} baseHeight={12} className="fill-secondary" />
              </button>
            ) : (
              <>
                {!isFullView && (
                  <button onClick={() => onClose && onClose()}>
                    <ResponsiveIcon name={IconNames.close1} baseWidth={20} baseHeight={20} iconClasses="fill-secondary" />
                  </button>
                )}
              </>
            )}
          </div>
          <ConnectSearchBar
            selectedItems={selectedUsers}
            getIsFocus={(isFocus: boolean) => {
              setTimeout(() => {
                setIsFocus(isFocus);
              }, 200);
            }}
            onRemoveItem={onRemoveSelectedUser}
            setValue={(value: string | undefined) => setValue(value)}
            value={value}
            isError={isMaxedOut}
          />
        </div>
        {isFocus || (selectedUsers && selectedUsers?.length <= 0) ? (
          <div className="flex-1">
            <UserList
              caption={value && value?.length > 2 ? '' : (translate('chat.suggestions') as string)}
              items={
                value && value?.length > 2
                  ? isInviteUsers
                    ? filteredGroupMemberList
                    : filteredUsers
                  : isInviteUsers
                  ? suggestedGroupMemberList
                  : suggestions
              }
              onClickUser={onClickUser}
              className={!isFullView ? 'h-[12.4rem]' : ''}
              searchValue={value}
              isLoading={isSearching}
            />
          </div>
        ) : (
          <div className="flex-1 pt-[1rem] flex flex-col gap-[1rem]">
            <MessageContent users={selectedUsers} isInviteUsers={isInviteUsers} />
            {!isInviteUsers && <ChatInput handleSendMessage={handleSendMessage} isMaxedOut={isMaxedOut} />}
          </div>
        )}
      </div>
    </>
  );
};

export default NewChat;
