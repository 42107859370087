import { gql } from '@apollo/client';

export const INVOICE_MUTATION = {
    createInvoice: gql`
        mutation createInvoice($details: OrderInvoiceInput) {
            createInvoice(details: $details) {
                error_msg
                is_successful
                error_code
                data {
                    invoice_number
                }
            }
        }
    `,

    clearInvoice: gql`
        mutation clearInvoice($details: ClearInvoiceInput) {
            clearInvoice(details: $details) {
                error_msg
                is_successful
                error_code
            }
        }
    `,
};
