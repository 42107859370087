import { gql } from '@apollo/client';

export const MATCHMAKING_DATA_QUERY = {
    getTypes: gql`
        query getTypes {
            getTypes {
                error_msg
                error_code
                is_successful
                data {
                    platforms {
                        platform_name_en
                        platform_name_ar
                        platform_code
                    }
                    language {
                        name_en
                        name_ar
                        lang_code
                    }
                }
            }
        }
    `,
    tmsMatchmakingDataSearching: gql`
        query tmsMatchmakingDataSearching {
            tmsMatchmakingDataSearching {
                error_msg
                error_code
                is_successful
                data {
                    user_preferences {
                        matchmaking_participant_id
                        reference_game_code
                        reference_platform_code
                        reference_lang_code
                        description
                        is_deleted
                        created_at
                    }
                    search_participants {
                        avatar_url
                        cover_banner_url
                        display_name
                        is_official_account
                        username
                        bio
                        total_followers
                        total_following
                        matchmaking_gamer_id
                        last_cursor
                    }
                    recently_matched {
                        avatar_url
                        username
                        matchmaking_gamer_id
                    }
                }
            }
        }
    `,
};
