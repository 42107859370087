import { ThemeConfigType } from '@fe-monorepo/models';
import { RootState } from '@fe-monorepo/store';
import { useSelector } from 'react-redux';

export enum BGType {
  profile = 'profile',
  signIn = 'sign_in',
  landing = 'landing',
  shop = 'shop',
}

const getMobileBg = (type: BGType, themeConfig: ThemeConfigType, isRTL: boolean, isDark: boolean): string => {
  switch (type) {
    case BGType.profile:
      return getMobileProfileBg(themeConfig, isRTL, isDark);
    case BGType.signIn:
      return getMobileSignInBg(themeConfig, isRTL, isDark);
    case BGType.shop:
      return getMobileLandingBg(themeConfig, isRTL, isDark);
    default:
      return getMobileLandingBg(themeConfig, isRTL, isDark);
  }
};
const getMobileProfileBg = (themeConfig: ThemeConfigType, isRTL: boolean, isDark: boolean): string => {
  if (isRTL) {
    return isDark ? themeConfig.profile_mobile_dark_ar ?? '' : themeConfig.profile_mobile_light_ar ?? '';
  } else {
    return isDark ? themeConfig.profile_mobile_dark_en ?? '' : themeConfig.profile_mobile_light_en ?? '';
  }
};
const getMobileSignInBg = (themeConfig: ThemeConfigType, isRTL: boolean, isDark: boolean): string => {
  // No bg image needed for mobile
  return '';
};
const getMobileLandingBg = (themeConfig: ThemeConfigType, isRTL: boolean, isDark: boolean): string => {
  if (isRTL) {
    return isDark ? themeConfig.mobile_bg_dark_ar ?? '' : themeConfig.mobile_bg_light_ar ?? '';
  } else {
    return isDark ? themeConfig.mobile_bg_dark_en ?? '' : themeConfig.mobile_bg_light_en ?? '';
  }
};
const getDesktopBg = (type: BGType, themeConfig: ThemeConfigType, isRTL: boolean, isDark: boolean): string => {
  switch (type) {
    case BGType.profile:
      return getDesktopProfileBg(themeConfig, isRTL, isDark);
    case BGType.signIn:
      return getDesktopSignInBg(themeConfig, isRTL, isDark);
    case BGType.shop:
      return getDesktopShopBg(themeConfig, isRTL, isDark);
    default:
      return getDesktopLandingBg(themeConfig, isRTL, isDark);
  }
};

const getDesktopProfileBg = (themeConfig: ThemeConfigType, isRTL: boolean, isDark: boolean): string => {
  if (isRTL) {
    return isDark ? themeConfig.profile_desktop_dark_ar ?? '' : themeConfig.profile_desktop_light_ar ?? '';
  } else {
    return isDark ? themeConfig.profile_desktop_dark_en ?? '' : themeConfig.profile_desktop_light_en ?? '';
  }
};
const getDesktopSignInBg = (themeConfig: ThemeConfigType, isRTL: boolean, isDark: boolean): string => {
  if (isRTL) {
    return isDark ? themeConfig.sign_in_desktop_bg_dark_ar ?? '' : themeConfig.sign_in_desktop_bg_light_ar ?? '';
  } else {
    return isDark ? themeConfig.sign_in_desktop_bg_dark_en ?? '' : themeConfig.sign_in_desktop_bg_light_en ?? '';
  }
};
const getDesktopLandingBg = (themeConfig: ThemeConfigType, isRTL: boolean, isDark: boolean): string => {
  if (isRTL) {
    return isDark ? themeConfig.desktop_bg_dark_ar ?? '' : themeConfig.desktop_bg_light_ar ?? '';
  } else {
    return isDark ? themeConfig.desktop_bg_dark_en ?? '' : themeConfig.desktop_bg_light_en ?? '';
  }
};
const getDesktopShopBg = (themeConfig: ThemeConfigType, isRTL: boolean, isDark: boolean): string => {
  if (isRTL) {
    return isDark ? themeConfig.shop_desktop_bg_dark_ar ?? '' : themeConfig.shop_desktop_bg_light_ar ?? '';
  } else {
    return isDark ? themeConfig.shop_desktop_bg_dark_en ?? '' : themeConfig.shop_desktop_bg_light_en ?? '';
  }
};

export const useThemedBackground = (type: BGType, isMobile: boolean) => {
  const { themeConfig, isRTL, themes } = useSelector((state: RootState) => state.app);
  let backgroundImage = '';
  if (themeConfig) {
    if (isMobile) {
      backgroundImage = getMobileBg(type, themeConfig, isRTL, themes === 'dark');
    } else {
      backgroundImage = getDesktopBg(type, themeConfig, isRTL, themes === 'dark');
    }
  }

  return {
    backgroundImage,
  };
};
