import { gql } from '@apollo/client';

export const TOURNAMENT_MUTATION = {
    createTournament: gql`
    mutation createTournament($details: TmsDataCreateInput) {
        createTournament(details: $details) {
            error_msg
            is_successful
            error_code
            data {
                tournament_id
            }
        }
    }
    `,

    updateTournament: gql`
    mutation updateTournament($details: TmsDataUpdateInput, $file: Upload) {
        updateTournament(details: $details, file: $file) {
            error_msg
            is_successful
            error_code
        }
    }
    `
}