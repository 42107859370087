import { gql } from '@apollo/client';

const TMS_MATCHES_QUERY = {
    getMatches: gql`
        query tmsMatchesGetMatches($details: TmsMatchesGetMatchesInput) {
            tmsMatchesGetMatches(details: $details) {
                is_successful
                error_code
                error_msg
                data {
                    list {
                        match_id
                        match_winner
                        seq_id
                        status_code
                        last_cursor
                        opponent_a_username
                        opponent_a_display_name
                        opponent_a_avatar_url
                        opponent_a_is_official_account
                        opponent_a_score
                        opponent_a_auto_qualified
                        is_opponent_a_done_updating
                        opponent_a_game_id
                        screenshot_reported_by_a
                        comment_reported_by_a
                        opponent_b_username
                        opponent_b_display_name
                        opponent_b_avatar_url
                        opponent_b_is_official_account
                        opponent_b_score
                        opponent_b_auto_qualified
                        is_opponent_b_done_updating
                        opponent_b_game_id
                        screenshot_reported_by_b
                        comment_reported_by_b
                        opponent_a_score_reported_by_a
                        opponent_b_score_reported_by_a
                        opponent_a_score_reported_by_b
                        opponent_b_score_reported_by_b
                    }
                }
            }
        }
    `,
};

export { TMS_MATCHES_QUERY };
