import { IconNames } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import useHeaderState from '@fe-web/hooks/useHeaderState';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ResponsiveIcon from '../../Atoms/Icon/ResponsiveIcon';

export type InputFieldStyle = {
  containerStyle?: string;
  labelStyle?: string;
  inputStyle?: string;
  underlineStyle?: string;
};

interface FieldProps {
  style: InputFieldStyle;

  label: string;
  isSecret?: boolean;
  hasFocused?: boolean;
  errorMessage?: string;
  showCounter?: boolean;
  maxLenght?: number;

  defaultValue?: string;

  // TODO: this props needs a better name
  isUserAllowedToType: (value: string) => boolean;
  retrieveValue?: (value: any) => void;
  retrieveFocus?: (value: boolean) => void;
  retrieveTyped?: (value: boolean) => void;

  onClickEnter?: () => void;

  shouldReset?: boolean;
  setShouldReset?: (shouldReset: boolean) => void;

  errorMessageClassName?: string;
}

interface UnderlineProps {
  isFocused: boolean;
  errorMessage?: string;

  underlineStyle?: string;

  showCounter?: boolean;
  errorMessageClassName?: string;
}

const Underline = (props: UnderlineProps) => {
  const shouldShowError = props?.errorMessage && !props.isFocused;

  return (
    <>
      <div
        className={`w-full ${shouldShowError ? 'bg-red' : props.isFocused ? 'bg-sunset' : 'bg-gray-200-zinc-700'} ${props?.underlineStyle}`}
      />

      {props.showCounter ? (
        ''
      ) : (
        <div
          className={`
         mt-4 4xl:mt-8 5xl:mt-12 8xl:mt-22
        h-20 4xl:h-36 5xl:h-54 8xl:h-100 ${props?.errorMessageClassName ?? ''}
      `}
        >
          {shouldShowError && (
            <p className=" text-bodySmall 4xl:text-subtitle 5xl:text-bigTitle 8xl:text-huge font-regular text-red">{props.errorMessage}</p>
          )}
        </div>
      )}
    </>
  );
};

const Label = (props: { text: string; isFocused: boolean; valueLength: number }) => {
  const { innerWidth } = useHeaderState();

  // TODO: this needs to change. I only did this for Framer Motion
  const fontSizes = {
    normal: {
      initial: { size: '18px', height: '24px' },

      small: { size: '14px', height: '20px' },
    },

    '2k': {
      initial: { size: '32px', height: '42.66px' },

      small: { size: '25px', height: '36px' },
    },

    '4k': {
      initial: { size: '48px', height: '64px' },

      small: { size: '37.33px', height: '53px' },
    },

    '8k': {
      initial: { size: '96px', height: '128px' },

      small: { size: '75px', height: '106px' },
    },
  };

  let mode: 'normal' | '2k' | '4k' | '8k' = 'normal';

  if (innerWidth >= 7680) {
    mode = '8k';
  } else if (innerWidth >= 3840) {
    mode = '4k';
  } else if (innerWidth >= 2560) {
    mode = '2k';
  }

  return (
    <motion.p
      className={`absolute ${props.isFocused ? 'text-sunset' : 'text-secondary/50'} font-regular z-0`}
      initial={{
        y: '25%',
        fontSize: fontSizes[mode].initial.size,
        lineHeight: fontSizes[mode].initial.height,
      }}
      animate={{
        y: props.isFocused || props.valueLength > 0 ? '-125%' : '25%',
        fontSize: props.isFocused || props.valueLength > 0 ? fontSizes[mode].small.size : fontSizes[mode].initial.size,
        lineHeight: props.isFocused || props.valueLength > 0 ? fontSizes[mode].small.height : fontSizes[mode].initial.height,
      }}
      transition={{ duration: 0.3 }}
    >
      {props.text}
    </motion.p>
  );
};

interface InputProps {
  className?: string;

  value: string;
  isSecret?: boolean;
  isFocused: boolean;
  maxLength?: number;

  setValue: (value: string) => void;
  setFocused: (val: boolean) => void;
  onClickEnter?: () => void;
}

const Input = (props: InputProps) => {
  const [isHidden, setHidden] = useState<boolean>(props?.isSecret ? props?.isSecret : false);
  const { t } = useTranslation();

  useEffect(() => setHidden(props?.isSecret ? props?.isSecret : false), [props?.isSecret]);

  const toggleHidden = () => setHidden(value => !value);

  const clearValue = () => props.setValue('');

  return (
    <div className="relative grid items-center w-full h-fit">
      <input
        className={`z-1 w-full outline-0 bg-transparent ${props?.className} focus:outline-none`}
        type={!isHidden ? 'text' : 'password'}
        value={props.value}
        placeholder={props.isFocused ? '' + t('formPlaceholder_start_typing') : ''}
        onChange={e => props.setValue(e.target.value)}
        onFocus={() => props.setFocused(true)}
        onBlur={() => props.setFocused(false)}
        onKeyDown={event => {
          if (event.key === 'Enter' && props.onClickEnter) {
            props.onClickEnter();
          }
        }}
        maxLength={props.maxLength}
      />

      <div className="absolute cursor-pointer justify-self-end h-fit w-fit">
        {props?.isSecret ? (
          <ResponsiveIcon
            className="stroke-black100 z-[1]"
            name={isHidden ? IconNames.eyeOutline : IconNames.eyeClosedOutline}
            baseWidth={20}
            baseHeight={20}
            onClick={toggleHidden}
          />
        ) : (
          props.value.length > 0 && (
            <ResponsiveIcon
              className="defaultTheme:fill-black100 dark:fill-white100 z-[1]"
              name={IconNames.close_xbutton}
              baseWidth={14}
              baseHeight={14}
              onClick={clearValue}
            />
          )
        )}
      </div>
    </div>
  );
};

// TODO: We must make this accessible (https://www.w3.org/WAI/tutorials/forms/labels/)
const InputField = (props: FieldProps) => {
  const [value, setValue] = useState<string>(props.defaultValue ?? '');
  const [isFocused, setFocused] = useState<boolean>(false);
  const { translate } = useTranslate();

  const { containerStyle, inputStyle, underlineStyle } = props.style;

  const changeValue = (value: string) => {
    if (!props.isUserAllowedToType(value)) {
      return;
    }

    if (props?.retrieveTyped) {
      props?.retrieveTyped(true);
    }

    setValue(value);
  };

  useEffect(() => {
    if (props?.retrieveValue) {
      props?.retrieveValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (!props.retrieveFocus || props?.hasFocused !== undefined) {
      return;
    }

    if (!props.hasFocused && isFocused) {
      props.retrieveFocus(isFocused);
    }
  }, [isFocused]);

  useEffect(() => {
    if (props.shouldReset) {
      setValue('');

      props.setShouldReset && props.setShouldReset(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.shouldReset]);

  return (
    <div className={`relative ${containerStyle}`}>
      <div className="w-full h-20 mb-4 4xl:h-36 5xl:h-54 8xl:h-100 4xl:mb-8 5xl:mb-12 8xl:mb-22" />

      <div className="flex w-full">
        <Label text={props.label} valueLength={value.length} isFocused={isFocused} />

        <Input
          className={inputStyle}
          isSecret={props?.isSecret}
          value={value}
          setValue={changeValue}
          isFocused={isFocused}
          setFocused={setFocused}
          onClickEnter={props?.onClickEnter}
          maxLength={props.maxLenght}
        />
      </div>

      <Underline
        underlineStyle={underlineStyle}
        isFocused={isFocused}
        errorMessage={props?.errorMessage}
        showCounter={props.showCounter}
        errorMessageClassName={props?.errorMessageClassName}
      />
      {props.showCounter && (
        <p className="flex justify-end text-fs-caption text-secondary/50">
          {value.length + '/' + props.maxLenght + ' ' + translate('shop.cart.order_main.characters')}
        </p>
      )}
    </div>
  );
};

export default InputField;
