import { gql } from '@apollo/client';

export const WISHLIST_MUTATION ={
    shopWishlistAdd: gql`
        mutation shopWishlistAdd($details: ShopWishlistInput) {
            shopWishlistAdd(details: $details) {
                is_successful
                error_code
                error_msg
            }
        }
    `,

    shopWishlistRemove: gql`
        mutation shopWishlistRemove($details: ShopWishlistInput) {
            shopWishlistRemove(details: $details) {
                is_successful
                error_code
                error_msg
            }
        }
    `,
}