import { IMAGE_ALT_LABELS, TxKeyPath } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';

interface Props {
  avatarStyle?: string;
  avatarSize?: string;
  dualAvatarSize?: string;
  avatarUrl: string;
  avatarUrl2?: string;
}

const NotificationGroupAvatar = ({ avatarStyle, avatarSize, avatarUrl, avatarUrl2, dualAvatarSize }: Props) => {
  const { translate } = useTranslate();
  return (
    <div className="relative w-[2.5rem] h-[2.5rem]">
      {avatarUrl2 ? (
        <>
          <img
            className={`${avatarStyle} w-[${dualAvatarSize}] h-[${dualAvatarSize}] rounded-full border-[0.0625rem] border-neutral-300-zinc-700 object-cover`}
            src={avatarUrl}
            alt={translate(IMAGE_ALT_LABELS.notification_group_avatar_1 as TxKeyPath) || ''}
          />
          <img
            className={`${avatarStyle} w-[${dualAvatarSize}] h-[${dualAvatarSize}] absolute bottom-0 right-0 rounded-full border-[0.0625rem] border-neutral-300-zinc-700 object-cover`}
            src={avatarUrl2}
            alt={translate(IMAGE_ALT_LABELS.notification_group_avatar_2 as TxKeyPath) || ''}
          />
        </>
      ) : (
        <img
          className={`${avatarStyle} w-[${avatarSize}] h-[${avatarSize}] rounded-full border-[0.0625rem] border-neutral-300-zinc-700 object-cover`}
          src={avatarUrl}
          alt={translate(IMAGE_ALT_LABELS.notification_group_avatar_1 as TxKeyPath) || ''}
        />
      )}
    </div>
  );
};

export default NotificationGroupAvatar;
