import { IconNames } from '@fe-monorepo/helper';

import ResponsiveIcon from './Icon/ResponsiveIcon';
import HoverText from './Text/HoverText';

interface Props<T> {
  className?: string;
  list: T[];
  getString: (item: T) => string;
  onClick: (item: T) => void;
  lastItemClassName?: string;
}

const BreadCrumbs = <T,>(props: Props<T>) => {
  const { className, getString, onClick, lastItemClassName } = props;

  return (
    <header
      className={`h-[39px] 2K:h-[69.33px] 4K:h-[103.99px] 8K:h-[207.99px] w-full flex items-center 
          gap-4 2K:gap-[7.1px] 4K:gap-[10.66px] 8K:gap-[21.33px] px-20 md:px-64 lg:px-120 4xl:px-160 5xl:px-320 8xl:px-655 border-b-[1px] 2K:border-b-[1.77px] 4K:border-b-[2.66px] 8K:border-b-[5.33px] border-secondary/20 overflow-x-scroll whitespace-nowrap ${className}`}
    >
      {props.list.length === 1 ? (
        <>
          <ResponsiveIcon
            className="rtl:rotate-[270deg] ltr:rotate-90 fill-secondary"
            name={IconNames.chevron}
            baseHeight={20}
            baseWidth={20}
          />

          <HoverText
            className={`
              text-sunset font-regular
              text-caption 2K:text-mobileSubtitle 4K:text-title 8K:text-huge
            `}
            hover={{ color: 'text-sunset' }}
            alwaysShowUnderline={false}
            underlineStyle="z-1 static bg-sunset h-[1px] 2K:h-[1.77px] 4K:h-[2.66px] 8K:h-[5.33px]"
            text={getString(props.list[0])}
            onClick={() => onClick(props.list[0])}
          />
        </>
      ) : (
        props.list.map((item, index) => {
          const isLastItem = index === props.list.length - 1;

          return (
            <>
              <HoverText
                className={`${
                  isLastItem ? (lastItemClassName ? lastItemClassName : `text-secondary/[0.7]`) : `text-otherGray`
                } font-regular text-caption 2K:text-mobileSubtitle 4K:text-title 8K:text-huge`}
                hover={isLastItem ? undefined : { color: 'text-sunset' }}
                alwaysShowUnderline={false}
                underlineStyle="z-1 static bg-sunset h-[1px] 2K:h-[1.77px] 4K:h-[2.66px] 8K:h-[5.33px]"
                text={getString(item)}
                onClick={() => {
                  if (!isLastItem) {
                    onClick(item);
                  }
                }}
              />

              {!isLastItem && (
                <ResponsiveIcon
                  className="rtl:rotate-90 ltr:rotate-[270deg] fill-secondary/[0.5]"
                  name={IconNames.chevron}
                  baseHeight={20}
                  baseWidth={20}
                />
              )}
            </>
          );
        })
      )}
    </header>
  );
};

export default BreadCrumbs;
