import { RootState } from "@fe-monorepo/store";
import { t } from "i18next";
import { useSelector } from "react-redux";
import LookSimilarButton from "./LookSimiliarButton";

interface Props
{
  navigateToSimilarProducts: () => void
}
const OutOfStockSection = (props: Props) =>
{
  const isRTL = useSelector((state: RootState) => state.app.isRTL);

  return (
    <div
      className=
      {`
        w-full

        SD:mt-[65px] 2K:mt-[115.55px] 4K:mt-[173.33px] 8K:mt-[346.66px]
      `}
    >
      <p
        className="text-secondary font-medium responsive-text-subtitle"
      >
        {t("shop.productPage.outOfStock.message")}
      </p>

      <p
        className=
        {`
          text-secondary
          font-regular
          responsive-text-body

          mt-16 2K:mt-[28.44px] 4K:mt-[42.66px] 8K:mt-[85.33px]
        `}
      >
        {t("shop.productPage.outOfStock.notAvailableInYourArea")}
      </p>

      {/* <OutlineButton
        className=
        {`
          mt-24 2K:mt-[42.66px] 4K:mt-64 8K:mt-[128px]

          w-full SD:w-fit
          px-40 2K:px-[71.11px] 4K:px-[106.66px] 8K:px-[213.33px]
          py-8 2K:py-16 4K:py-20 8K:py-44
        `}
        dir={!isRTL? "rtl":"ltr"}
        text={t("shop.productPage.outOfStock.lookSimiliarButton")}
        action={() => {}}
      /> */}

      <LookSimilarButton
         dir={!isRTL? "rtl":"ltr"}
         action={props.navigateToSimilarProducts}
      />
    </div>
  )
}

export default OutOfStockSection;
