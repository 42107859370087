import { RootState, setShowSearchInput } from '@fe-monorepo/store';
import { useDispatch, useSelector } from 'react-redux';

const useSearchInput = () => {
  const dispatch = useDispatch();
  const showSearchInput = useSelector((state: RootState) => state.app.showSearchInput);

  return {
    showSearchInput,
    setShowSearchInput: () => dispatch(setShowSearchInput()),
  };
};

export default useSearchInput;
