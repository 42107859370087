import { useLazyQuery, useMutation } from '@apollo/client';
import { NOTIFICATION_MESSAGE_MUTATION, NOTIFICATION_MESSAGE_QUERY } from '@fe-monorepo/data-access';
import {
    NotificationMessageData,
    NotificationMessageGetAllInput,
    NotificationMessageGetAllResponse,
    NotificationMessageUpdateInput,
    NotificationMessageUpdateResponse,
    NotificationMessageSendInput,
    NotificationMessageSendResponse,
    NotificationMessageMarkReadAllResponse,
    NotificationMessageRemoveAllResponse,
    RemoveInput,
    ResponseModel,
    NotificationRemoveResponse
} from '@fe-monorepo/models';

export const useNotificationMessage = () => {
    const [notificationMessageGetAllGql, { data: getAllResponse }] = useLazyQuery<NotificationMessageGetAllResponse>(
        NOTIFICATION_MESSAGE_QUERY.notificationMessageGetAll,
        {
            errorPolicy: 'all',
        },
    );

    const [notificationMessageUpdateGql, { data: updateResponse }] = useMutation<NotificationMessageUpdateResponse>(
        NOTIFICATION_MESSAGE_MUTATION.notificationMessageUpdate,
        {
            errorPolicy: 'all',
        },
    );

    const [notificationMessageSendGql, { data: SendResponse }] = useMutation<NotificationMessageSendResponse>(
        NOTIFICATION_MESSAGE_MUTATION.notificationMessageSend,
        {
            errorPolicy: 'all',
        },
    );

    const [notificationMessageRemoveAllGql, { data: RemoveAllResponse }] = useMutation<NotificationMessageRemoveAllResponse>(
        NOTIFICATION_MESSAGE_MUTATION.notificationMessageRemoveAll,
        {
            errorPolicy: 'all',
        },
    );

    const [notificationMessageMarkReadAllGql, { data: MarkReadAllResponse }] = useMutation<NotificationMessageMarkReadAllResponse>(
        NOTIFICATION_MESSAGE_MUTATION.notificationMessageMarkReadAll,
        {
            errorPolicy: 'all',
        },
    );

    const [notificationMessageRemoveGql, { data: RemoveResponse, error: errorRemoveDevice }] = useMutation<NotificationRemoveResponse>(
        NOTIFICATION_MESSAGE_MUTATION.notificationMessageRemove,
        {
            errorPolicy: 'all',
        },
    );

    const notificationMessageGetAll = async (details: NotificationMessageGetAllInput) => {
        const { data } = await notificationMessageGetAllGql({ variables: { details } });
        return data;
    };

    const notificationMessageUpdate = async (details: NotificationMessageUpdateInput) => {
        const { data } = await notificationMessageUpdateGql({ variables: { details: details } });
        return data?.notificationMessageUpdate;
    };

    const notificationMessageSend = async (details: NotificationMessageSendInput) => {
        const { data } = await notificationMessageSendGql({ variables: { details: details } });
        return data?.notificationMessageSend;
    };

    const notificationMessageRemoveAll = async () => {
        const { data } = await notificationMessageRemoveAllGql();
        return data;
    };

    const notificationMessageMarkReadAll = async () => {
        const { data } = await notificationMessageMarkReadAllGql();
        return data;
    };

    const notificationMessageRemove = async (details: RemoveInput) => {
        const { data } = await notificationMessageRemoveGql({ variables: { details: details } });
        return data?.notificationMessageRemove;
    };

    return {
        notificationMessageGetAll,
        notificationMessageUpdate,
        getAllResponse: getAllResponse?.notificationMessageGetAll,
        updateResponse: updateResponse?.notificationMessageUpdate,
        notificationMessageSend,
        notificationMessageRemoveAll,
        RemoveAllResponse,
        notificationMessageMarkReadAll,
        MarkReadAllResponse,
        notificationMessageRemove,
        RemoveResponse,
    };
};
