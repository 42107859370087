import { gql } from '@apollo/client';

export const NEWSLETTER_MUTATION = {
    subscribeNewsLetter: gql`
        mutation subscribeNewsLetter($details: SubscribeInput) {
            subscribeNewsLetter(details: $details) {
                is_successful
                error_msg
                error_code
            }
        }
    `,
};
