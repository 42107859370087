import { TxKeyPath } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import { toastWrapper } from '@fe-web/Atoms/Toast';
import PropTypes from 'prop-types';
import quickplay from 'quickplay-shorts-js';
import { useEffect, useState } from 'react';

import { useNotification } from '../../contexts/Notification';
import CustomModal from '../common/CustomModal/CustomModal';
import LoaderDark from '../common/LoaderDark/LoaderDark';

const feedModuleObj = new quickplay.QuickPlayFeedModule();

function Report({
  show,
  onHide,
  type,
  payloadData,
  handleClose,
  classes,
  title,
}: {
  show: boolean;
  onHide: () => void;
  type: string;
  payloadData: any;
  handleClose: () => void;
  classes?: string;
  title: string;
}) {
  const { translate } = useTranslate();
  const [selectedReason, setselectedReason] = useState(null);
  const [reportReasons, setReportReasons] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isReporting, setIsReporting] = useState(true);
  const defaultLanguage = localStorage.getItem('i18nextLng');

  const { showNotification } = useNotification();

  const handleReasonChange = (e: any) => {
    setselectedReason(e.target.value);
    setIsReporting(false);
  };

  const handleReport = async () => {
    if (!selectedReason) {
      toastWrapper('error', '' + translate('quickplay.report-reason-required'));
      // showNotification({
      //   title: 'Report',
      //   subTitle: 'Please select a reason',
      // });
      return;
    }
    setIsReporting(true);
    const userData: any = JSON.parse(localStorage.getItem('userData') || '{}');
    const { userId = '', assetId = '', postId = '' } = payloadData || {};
    const payload = {
      userId: userId,
      actingUserId: userData.userId,
      assetId: assetId,
      reason: selectedReason,
      event: 'report',
      type: type,
      postId: postId, // postId will only go when we are reporting a comment
    };
    const response = await feedModuleObj.Report(payload);
    if (response.status === 201) {
      setIsReporting(false);
      onHide();
      toastWrapper('success', '' + translate('quickplay.report-success'));
      // showNotification({
      //   title: 'Report',
      //   subTitle: 'Reported submitted successfully',
      // });
    }
  };

  const translateData = (type: any) => {
    const reasons: any = {
      video: [
        'label-its-spam',
        'label-nudity',
        'label-hate-speech-symbols',
        'label-violence-dangerous',
        'label-sale',
        'label-bullying',
        'label-intellectual',
        'label-suicide',
        'label-scam-fraud',
        'label-false',
      ],

      comment: [
        'label-its-spam',
        'label-nudity',
        'label-hate-speech-symbols',
        'label-violence-dangerous',
        'label-sale',
        'label-bullying',
        'label-intellectual',
        'label-suicide',
        'label-scam-fraud',
        'label-false',
      ],
    };

    return reasons[type].map((item: any) => translate(`quickplay.${item}` as TxKeyPath) || item);
  };

  async function getReportReasons({ type }: any) {
    try {
      //   const response = await feedModuleObj.ReportReason();
      //   const originalData = response.data.result[type];
      //   console.log('originalData', originalData);
      const translatedData = translateData(type);
      setReportReasons(translatedData);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getReportReasons({ type });
  }, [type]);

  return (
    <CustomModal isOpen={show} close={handleClose} classes={classes}>
      <h2 className="modal-title">{title}</h2>
      <p className="modal-subtitle">{translate('quickplay.label-report-bit-description')}</p>
      {isLoading && <LoaderDark />}

      {!isLoading && reportReasons.length === 0 && <p>No Result!</p>}

      {!isLoading &&
        reportReasons.length > 0 &&
        reportReasons.map((reason: any) => {
          return (
            <div key={reason} className="radio-inputfield">
              {/* <label htmlFor={reason.replace(' ', '_')}>{reason}</label>
              <input
                type="radio"
                id={reason.replace(' ', '_')}
                name="report_option"
                value={reason}
                onChange={handleReasonChange}
                style={{ backgroundColor: 'red', color: 'white' }}
              /> */}
              <label htmlFor={reason.replace(' ', '_')} className="radio-container">
                <input
                  type="radio"
                  id={reason.replace(' ', '_')}
                  name="report_option"
                  value={reason}
                  onChange={handleReasonChange}
                  style={{ backgroundColor: 'red', color: 'white' }}
                />
                <span className="checkmark"></span>
                {reason}
              </label>
            </div>
          );
        })}

      <div className="bottom-button-group">
        <button onClick={handleReport} disabled={isReporting} className="report flex items-center justify-center gap-2">
          {translate('quickplay.label-confirm')}
        </button>
        <button className="close" onClick={handleClose}>
          {translate('quickplay.btn-cancel')}
        </button>
      </div>
    </CustomModal>
  );
}

Report.propTypes = {
  isOpen: PropTypes.bool,
  onHide: PropTypes.func,
  type: PropTypes.string,
  payloadData: PropTypes.object,
  classes: PropTypes.string,
  title: PropTypes.string,
};

export default Report;
