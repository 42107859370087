import { useMutation } from '@apollo/client';
import { PAYMENT_MUTATION } from '@fe-monorepo/data-access';
import { ApplePaySessionResponse } from '@fe-monorepo/models';

interface AppleSessionParam {
    url: string
}

const useApplePaySession = () => {
    const [appleSessionGql, { data: UpdateData }] = useMutation<ApplePaySessionResponse>(PAYMENT_MUTATION.startSession, {
        errorPolicy: 'all',
    });

    const startApplePaySession = async (params: AppleSessionParam) => {
        const { data } = await appleSessionGql({variables: {details: params}});
        return data?.startSession;
    };

    return {
        startApplePaySession
    };
};

export { useApplePaySession };
