import { IMAGES } from '@fe-monorepo/assets';
import { TmsGetAllMiniGamesDetails, TmsGetMiniGameHighlightedDetails } from '@fe-monorepo/models';
import { RootState } from '@fe-monorepo/store';
import LazyImage from '@fe-web/Atoms/Media/LazyImage';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from '../../../../app.routes.enum';

interface Props {
  gameData: TmsGetAllMiniGamesDetails | TmsGetMiniGameHighlightedDetails;
  containerClassName?: string;
  isHighlighted?: boolean;
}

const MiniGameCard = ({ gameData, containerClassName, isHighlighted = false }: Props) => {
  const navigate = useNavigate();
  const selectedLanguage = useSelector((state: RootState) => state.app.language);
  const gameName = selectedLanguage === 'en' ? gameData?.name_en : gameData?.name_ar;

  return (
    <div
      onClick={() => navigate(`${AppRoutes.games}/${gameData?.mini_game_code}`)}
      className={`${containerClassName || `h-[14.9375rem] w-[11.25rem]`} relative bg-black100 cursor-pointer 
                ${!gameData?.img ? 'border-[0.0625rem] border-gray-200-zinc-700' : ''}
                rounded-[0.5rem]`}
    >
      <div
        className={`
                    inline-flex items-end justify-center
                    font-medium text-fs-${gameName?.length > 18 ? 'subtitle' : 'title'} text-white pb-[1rem] px-[1rem]
                    absolute rounded-[0.5rem] h-full w-full ${!isHighlighted ? 'bg-gradient-to-t from-black to-50%' : ''}`}
      >
        <p className={`text-center`}>{gameName || ''}</p>
      </div>
      <LazyImage
        alt={gameData?.name_en?.replace(/\s/g, '_')}
        img={gameData?.img}
        imgStyle={`rounded-[0.5rem] w-full h-full object-cover`}
        divStyle="h-full"
        fallback={
          <LazyImage
            alt={gameData?.name_en?.replace(/\s/g, '_')}
            img={IMAGES.MiniGameCardFallBack.toString()}
            imgStyle={`absolute top-[0.625rem] w-full`}
            divStyle=""
          />
        }
      />
    </div>
  );
};

export default MiniGameCard;
