import { IconNames } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import Button from '@fe-web/Atoms/Buttons/Button';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import Modal from '@fe-web/Templates/Modal/Modal';
import useHeaderState from '@fe-web/hooks/useHeaderState';
import usePageLayout from '@fe-web/hooks/usePageLayout';
import { AppRoutes } from '../../../../app.routes.enum';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ReturnHeader = () => {
    const { translate } = useTranslate();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const { dir } = useHeaderState();
    const { isRtl } = usePageLayout();
    const navigate = useNavigate();
    return (
        <>
            <div className="my-[2.5rem]">
                <div className="flex flex-row justify-between">
                    <h1 className="text-fs-title font-medium text-secondary">{translate('shop.cart.returns_or_exchange.return_items')}</h1>
                    <button className="mdMaxxS:items-center flex flex-row gap-[0.5rem]" onClick={() => setIsModalOpen(true)}>
                        <p className="mdMaxxS:hidden text-fs-body font-regular text-secondary/70">{translate('action_close')}</p>
                        <ResponsiveIcon
                            onClick={() => setIsModalOpen(true)}
                            className={`cursor-pointer fill-secondary/70 pt-[0.25rem]`}
                            name={IconNames.close}
                            baseHeight={16}
                            baseWidth={16}
                        />
                    </button>
                </div>
                <h2 className="text-zinc-600-400 text-fs-body font-regular">{translate('shop.cart.returns_or_exchange.only_items')}</h2>
            </div>

            <Modal backdropClassName="bg-black/[0.25] items-center" open={isModalOpen}>
                <div
                    className={`
                    flex flex-col justify-start items-start bg-primary border border-neutral-300-zinc-700
                    rounded-lg p-[1.5rem] SD:p-[3rem]
                    max-h-[100vh] overflow-y-auto fixed top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]
                    w-[22.5rem] SD:w-[27rem]
                    gap-[2rem]
                `}
                >
                    <div className={`flex flex-col gap-[2rem]`}>
                        <div className={`flex flex-row gap-[0.5rem] font-medium text-fs-title text-secondary`}>
                            <h1 className="truncate whitespace-normal">{translate('shop.cart.returns_or_exchange.are_you_sure_leave')}</h1>
                            <ResponsiveIcon
                                className={`
                                cursor-pointer
                                ${
                                    dir === 'ltr'
                                        ? 'right-[2.313rem] SD:right-[3.25rem] 2K:right-[2.75rem] justify-items-end'
                                        : 'left-[2.313rem] SD:left-[3.25rem] 2K:left-[2.75rem] justify-items-end'
                                }
                                top-[2rem] SD:top-[3.2625rem] 2K:top-[2.9626rem] 8K:top-[3.5626rem]`}
                                name={IconNames.icon_close_20}
                                iconClasses={`fill-secondary`}
                                baseHeight={20}
                                baseWidth={20}
                                onClick={() => setIsModalOpen(false)}
                            />
                        </div>
                    </div>
                    <div className={`font-regular text-fs-body text-zinc-600-400`}>
                        {translate('shop.cart.returns_or_exchange.any_changes_not_be_saved')}
                    </div>
                    <div className={`flex flex-col SD:flex-row w-full gap-[1rem]`}>
                        <Button
                            className={`flex-1 text-fs-body text-sunset font-medium border-[0.063rem] border-sunset rounded-sm px-[1.5rem] py-[0.5rem] ${
                                !isRtl ? `after:bg-primary before:bg-sunset` : `before:bg-primary after:bg-sunset`
                            } hover:text-white100`}
                            text={translate('action_cancel')}
                            onClick={() => setIsModalOpen(false)}
                        />
                        <Button
                            className={`flex-1 text-fs-body font-medium rounded-sm px-[1.5rem] py-[0.5rem] ${`text-white border-[0.063rem] border-sunset hover:text-sunset ${
                                !isRtl ? 'before:bg-white after:bg-sunset' : 'after:bg-white before:bg-sunset'
                            }`}`}
                            text={translate('shop.cart.returns_or_exchange.yes_leave')}
                            onClick={() => navigate(AppRoutes.myorders)}
                        />
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ReturnHeader;
