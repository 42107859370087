import { gql } from '@apollo/client';

const TMS_MANAGE_MUTATION = {
    seedAutomatically: gql`
        mutation tmsManageSeedAutomatically($details: TmsManageSeedAutomaticallyInput) {
            tmsManageSeedAutomatically(details: $details) {
                is_successful
                error_code
                error_msg
            }
        }
    `,

    seedManually: gql`
        mutation tmsManageSeedManually($details: TmsManageSeedManuallyInput) {
            tmsManageSeedManually(details: $details) {
                is_successful
                error_code
                error_msg
            }
        }
    `,

    removeParticipant: gql`
        mutation tmsManageRemoveParticipant($details: TmsManageRemoveParticipantInput) {
            tmsManageRemoveParticipant(details: $details) {
                is_successful
                error_code
                error_msg
            }
        }
    `,

    approve: gql`
        mutation tmsManageApprove($details: TmsManageApproveInput) {
            tmsManageApprove(details: $details) {
                is_successful
                error_code
                error_msg
            }
        }
    `,

    sortParticipants: gql`
        mutation tmsManageSortParticipants($details: TmsManageSortParticipantsInput) {
            tmsManageSortParticipants(details: $details) {
                is_successful
                error_code
                error_msg
            }
        }
    `,

    reject: gql`
        mutation tmsManageReject($details: TmsManageRejectInput) {
            tmsManageReject(details: $details) {
                is_successful
                error_code
                error_msg
            }
        }
    `,
};

export { TMS_MANAGE_MUTATION };
