import { useTranslate } from '@fe-monorepo/hooks';
import { InvoiceProduct } from '@fe-monorepo/models';
import Products from '@fe-web/Organisms/OrderProduct/OrderProduct';
import { SHOP_PAGES } from '@fe-web/constant/constants';

interface PhysicalProducts {
  products: InvoiceProduct[];
  language: 'en' | 'ar';
  isRTL: boolean;
  packagenum?: number;
  currpackagenum: number;
  user: string;
  statuslabel?: string;
  itemstatuslabel?: string;
  index?: number;
  hasShipments?: boolean;
}

const PhysicalProducts = ({
  products,
  language,
  isRTL,
  packagenum,
  currpackagenum,
  user,
  statuslabel,
  itemstatuslabel,
  index,
  hasShipments,
}: PhysicalProducts) => {
  const { translate } = useTranslate();
  const showSendAsGiftAndDetails = SHOP_PAGES.myorders === window.location.pathname;
  return (
    <div>
      <div
        className={`flex font-medium text-fs-subtitle text-secondary-100 gap-x-[0.5rem] items-center mt-[1.75rem] ${
          hasShipments && index !== 0 ? 'hidden' : ''
        }`}
      >
        {((hasShipments && index === 0) || !hasShipments) && <p>{translate('shop.cart.order_main.physical_products')}</p>}
        {hasShipments && (
          <p
            className={`
                            text-fs-body-small font-medium rounded-[0.125rem] border py-[0.125rem] px-[0.5rem] truncate
                            ${packagenum && packagenum > 1 && statuslabel === 'Delivered' ? 'hidden' : ''}
                            ${
                              itemstatuslabel === 'shipment delivered'
                                ? 'border-[#108715]/[0.4] bg-[#108715]/[0.08] text-[#108715]'
                                : 'border-moonlight/[0.4] bg-moonlight/[0.08] text-moonlight'
                            }
                        `}
          >
            {statuslabel}
          </p>
        )}
      </div>
      {hasShipments && (
        <div className={`flex gap-[.5rem] mt-[2rem] font-regular ${packagenum === 1 ? 'hidden' : ''}`}>
          <p className={`text-secondary opacity-[0.70]`}>
            {translate('shop.cart.order_details_page.package')} {currpackagenum + 1}/{packagenum}
          </p>
          <p
            className={`
                        text-fs-body-small font-medium rounded-[0.125rem] border py-[0.125rem] px-[0.5rem] truncate
                        ${
                          itemstatuslabel === 'shipment delivered'
                            ? 'border-[#108715]/[0.4] bg-[#108715]/[0.08] text-[#108715]'
                            : 'border-moonlight/[0.4] bg-moonlight/[0.08] text-moonlight'
                        }
                    `}
          >
            {itemstatuslabel && itemstatuslabel?.charAt(0).toUpperCase() + itemstatuslabel?.substring(1)}
          </p>
        </div>
      )}
      {user !== '' && (
        <p className="block text-fs-body font-regular mb-[1.25rem]">
          {translate('shop.cart.cart_items_type.from')} <span className="text-sunset text-fs-body-large font-regular">{user}</span>
        </p>
      )}
      <Products
        products={products}
        language={language}
        showCaption={true}
        showDigitalProductTag={true}
        isRTL={isRTL}
        isImageClickable={true}
        isNameClickable={true}
        showSendAsGiftAndDetails={showSendAsGiftAndDetails}
      />
    </div>
  );
};

export default PhysicalProducts;
