import { useState } from "react";

export type Quantity =
{
  value: number,

  isMin: boolean,
  isMax: boolean,

  increment: () => void,
  decrement: () => void
}

const useQuantity = (defaultQuantity = 1, maxQuantity = 5): Quantity =>
{
  const [value, setValue] = useState<number>(defaultQuantity);

  const increment = () =>
  {
    if (value < maxQuantity)
    {
      setValue(value => value + 1)
    }
  }

  const decrement = () =>
  {
    if (value > 1)
    {
      setValue(value => value - 1)
    }
  }

  return {
    value,

    isMax: value === maxQuantity,
    isMin: value === 1,

    increment, decrement
  }
}

export default useQuantity;
