import { gql } from '@apollo/client';

export const MATCHMAKING_DATA_MUTATION = {
    tmsMatchmakingDataCreate: gql`
        mutation tmsMatchmakingDataCreate($details: TmsMatchmakingDataCreateInput) {
            tmsMatchmakingDataCreate(details: $details) {
                error_msg
                is_successful
                error_code
            }
        }
    `,

    tmsMatchmakingDataQuit: gql`
        mutation tmsMatchmakingDataQuit($details: TmsMatchmakingDataQuitInput) {
            tmsMatchmakingDataQuit(details: $details) {
                error_msg
                is_successful
                error_code
            }
        }
    `,
};
