import { EventObject, IconNames } from '@fe-monorepo/helper';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';

import { webAnalytics } from '../../../../../../helper/webAnalytics';

type Store = {
  icon: IconNames;
  store_name: 'Google' | 'Apple';
  link: string;
  baseDimensions: { height: number; width: number };
};

const Stores: { [index: string]: Store } = {
  Apple: {
    icon: IconNames.appleStore,
    store_name: 'Apple',
    link: 'https://apps.apple.com/us/app/stc-play/id1558331986',

    baseDimensions: { height: 40, width: 120 },
  },

  Google: {
    icon: IconNames.googlePlay,
    store_name: 'Google',
    link: 'https://play.google.com/store/apps/details?id=com.stc.xplay&hl=en&gl=US',

    baseDimensions: { height: 40, width: 135 },
  },
};

interface Props {
  store: 'Apple' | 'Google';
}

const AppStoreButton = (props: Props) => {
  const store = Stores[props.store];

  const onClick = () => {
    const event: EventObject = {
      name: 'clicked_app_link',
      properties: { store_name: store.store_name },
    };

    webAnalytics(event);

    window.open(store.link, '_blank', 'noopener,noreferrer');
  };

  return (
    <ResponsiveIcon
      className="cursor-pointer"
      name={store.icon}
      baseHeight={store.baseDimensions.height}
      baseWidth={store.baseDimensions.width}
      onClick={onClick}
    />
  );
};

export default AppStoreButton;
