
interface Props
{
  className?: string, content:  React.ReactNode,
  containerClassname?: string;
  children?: React.ReactNode
}

const ToolTip = (props: Props) =>
{
  return (
    <div className={`absolute ${props?.containerClassname || ''} z-[50] cursor-pointer bg-blue`}>
      <div className={`absolute ${props?.className} z-[50]`}>
        {props.content}
      </div>
    </div>
  )
}

export default ToolTip;
