import { RootState } from '@fe-monorepo/store';
import { useSelector } from 'react-redux';

const SeekBar = ({ handleChange, minutesValue, value }: any) => {
  const isRTL = useSelector((state: RootState) => state.app.isRTL);

  return (
    <div className={`w-full flex gap-10 items-center justify-center ${isRTL && 'flex-row-reverse'}`}>
      <input
        id="seekbar"
        type="range"
        min={0}
        max={100}
        step="5"
        value={100}
        className="w-full seekbar"
        // onChange={handleChange}
        style={{ background: `linear-gradient(${isRTL ? 'to left' : 'to right'} , #fc5a03 ${value}%, white ${value}%)` }}
      />
      {/* <p className="text-white">{minutesValue}</p> */}
    </div>
  );
};

export default SeekBar;
