import { IconNames } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import GroupPreference from './GroupPreference';

interface Props {
    onClose?: () => void;
    onClickBack?: (minimize: boolean) => void;
}

const GroupPreferenceMinimized = ({ onClose, onClickBack }: Props) => {
    const { translate } = useTranslate();

    return (
        <div>
            <div className="h-full flex flex-col divide-y divide-neutral-300-zinc-700 text-secondary">
                <div className="flex flex-col p-[1rem] gap-[0.75rem]">
                    <div className="flex items-center gap-[0.5rem]">
                        <ResponsiveIcon
                            name={IconNames.chevron}
                            baseWidth={16}
                            baseHeight={16}
                            onClick={() => {
                                onClickBack && onClickBack(false);
                            }}
                            className="transition-all fill-neutral-400-zinc-500 hover:fill-secondary hover:cursor-pointer rtl:rotate-[270deg] ltr:rotate-90"
                        />
                        <div className={`font-regular text-fs-body-small text-secondary`}>{translate('connect.group_info_label')}</div>
                        <ResponsiveIcon
                            name={IconNames.icon_chat_minimize_underline}
                            baseWidth={12}
                            baseHeight={12}
                            onClick={() => {
                                onClickBack && onClickBack(true);
                            }}
                            className="ltr:ml-auto rtl:mr-auto transition-all fill-neutral-400-zinc-500 hover:fill-secondary flex flex-col justify-end hover:cursor-pointer"
                        />

                        <ResponsiveIcon
                            onClick={() => {
                                onClose && onClose();
                            }}
                            name={IconNames.close}
                            baseWidth={12}
                            baseHeight={12}
                            className="transition-all fill-neutral-400-zinc-500 hover:fill-secondary hover:cursor-pointer pb-[0.375rem]"
                        />
                    </div>
                </div>
                <div>
                    <GroupPreference isMinimized />
                </div>
            </div>
        </div>
    );
};

export default GroupPreferenceMinimized;
