import { useComponentIsAppear } from '@fe-web/hooks/useComponentIsAppear/useComponentIsAppear';
import { useEffect, useRef } from 'react';

import Spinner from './Spinner';

type LoadMoreProps = {
  isLoading: boolean;
  loadMore: () => void;
};

const LoadMore = (props: LoadMoreProps) => {
  const { isLoading, loadMore } = props;

  const ref = useRef<HTMLDivElement>(null);
  const { isIntersecting } = useComponentIsAppear(ref);

  useEffect(() => {
    if (isIntersecting && !isLoading) {
      loadMore();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIntersecting]);

  return (
    <div ref={ref} className="flex items-center justify-center w-full p-0 m-0">
      {isLoading && <Spinner className="h-[50px] my-20 w-[50px]" />}
    </div>
  );
};

export default LoadMore;
