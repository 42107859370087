import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';

import _ from 'lodash';

import { RootState, useAppSelector } from '@fe-monorepo/store';
import { useTournament } from '../useTournament/useTournament';
import { useTmsMatchmakingCommunity } from './useMatchmakingCommunity';
import {
    TmsMatchmakingDataCreateInput,
    TmsMatchmakingDataQuitResponse,
    TmsMatchmakingDataSearchingParticipantData,
    TmsMatchmakingDataSearchingResponse,
    TmsMatchmakingDataSearchingUserPreferencesData,
    useTmsMatchmakingData,
} from './useMatchmakingData';

export enum MATCH_EVENTS {
    INITIAL = 'initial',
    FOLLOW = 'follow',
    UNFOLLOW = 'unfollow',
    BLOCK_USER = 'blockUser',
}

interface TmsMatchmakingDataSearchingParticipantDataAnimated extends TmsMatchmakingDataSearchingParticipantData {
    event?: MATCH_EVENTS;
}

interface UseMatchmakingProvider {
    userPreferences?: TmsMatchmakingDataSearchingUserPreferencesData;
    searchParticipants?: TmsMatchmakingDataSearchingParticipantDataAnimated[];
    searchParticipantsFiltered?: TmsMatchmakingDataSearchingParticipantDataAnimated[];
    recentlyMatched?: Partial<TmsMatchmakingDataSearchingParticipantDataAnimated>[];
    quitData?: TmsMatchmakingDataQuitResponse;
    platform?: string;
    game?: string;
    referenceLang?: string,
    isPageLoading?: boolean;
    isQuitLoading?: boolean;
    isActionDisabled?: boolean;
    searchingData?: TmsMatchmakingDataSearchingResponse,
    follow?: (username?: string, matchmakingGamerId?: number, avatar?: string, delay?: number) => void;
    unfollow?: (username?: string, matchmakingGamerId?: number, delay?: number) => void;
    block?: (username?: string, matchmakingGamerId?: number, delay?: number) => void;
    quit?: () => void;
    reject?: (username?: string, delay?: number) => void;
}

const MatchmakingContext = createContext<UseMatchmakingProvider>({});

interface MatchmakingProviderProps {
    children: ReactNode;
    createInput: TmsMatchmakingDataCreateInput;
}

export const MatchmakingProvider = ({ children, createInput }: MatchmakingProviderProps) => {
    const { getAllGames, games } = useTournament();
    const {
        searchingData,
        createData,
        quitData,
        types,
        isCreateLoading,
        isSearchingLoading,
        isQuitLoading,
        getTypes,
        searching,
        create,
        quit: quitFn,
    } = useTmsMatchmakingData();
    const {
        isFollowLoading,
        isUnfollowLoading,
        isBlockUserLoading,
        follow: followFn,
        unfollow: unfollowFn,
        blockUser: blockUserFn,
    } = useTmsMatchmakingCommunity();
    const [userPreferences, setUserPreferences] = useState<TmsMatchmakingDataSearchingUserPreferencesData>();
    const [searchParticipants, setSearchParticipants] = useState<TmsMatchmakingDataSearchingParticipantDataAnimated[]>();
    const [recentlyMatched, setRecentlyMatched] = useState<Partial<TmsMatchmakingDataSearchingParticipantDataAnimated>[]>();
    const language = useAppSelector((state: RootState) => state.app.language);
    const [platform, setPlatform] = useState<string>();
    const [game, setGame] = useState<string>();
    const [rejectedUsers, setRejectedUsers] = useState<string[]>();
    const [searchParticipantsFiltered, setSearchParticipantsFiltered] = useState<TmsMatchmakingDataSearchingParticipantDataAnimated[]>();
    const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
    const isActionDisabled = useMemo<boolean>(() => {
        return isFollowLoading || isUnfollowLoading || isBlockUserLoading || isCreateLoading || isSearchingLoading || isQuitLoading;
    }, [isFollowLoading, isUnfollowLoading, isBlockUserLoading, isCreateLoading, isSearchingLoading, isQuitLoading]);

    const setRecentlyMatchedWithLimit = (
        newRecentlyMatched: Partial<TmsMatchmakingDataSearchingParticipantDataAnimated>[] | undefined,
        limit = 10,
    ) => {
        setRecentlyMatched([...(newRecentlyMatched || [])].slice(0, limit));
    };

    const follow = (username?: string, matchmakingGamerId?: number, avatar?: string, delay = 0) => {
        if (userPreferences?.matchmaking_participant_id && username && matchmakingGamerId) {
            setRecentlyMatchedWithLimit([
                { username, matchmaking_gamer_id: matchmakingGamerId, avatar_url: avatar || '', event: MATCH_EVENTS.FOLLOW },
                ...(recentlyMatched || []),
            ]);
            setTimeout(() => {
                setSearchParticipantsFiltered(
                    searchParticipantsFiltered?.filter(({ username: currentUsername }) => currentUsername != username),
                );
                followFn({
                    matchmaking_participant_id: userPreferences?.matchmaking_participant_id,
                    username,
                    matchmaking_gamer_id: matchmakingGamerId,
                });
            }, delay);
        }
    };

    const unfollow = (username?: string, matchmakingGamerId?: number, delay = 0) => {
        if (userPreferences?.matchmaking_participant_id && username && matchmakingGamerId) {
            setTimeout(() => {
                setRecentlyMatchedWithLimit(recentlyMatched?.filter(({ username: currentUsername }) => currentUsername != username));
                unfollowFn({
                    matchmaking_participant_id: userPreferences?.matchmaking_participant_id,
                    username,
                    matchmaking_gamer_id: matchmakingGamerId,
                });
            }, delay);
        }
    };

    const block = (username?: string, matchmakingGamerId?: number, delay = 0) => {
        if (userPreferences?.matchmaking_participant_id && username && matchmakingGamerId) {
            setTimeout(() => {
                setRecentlyMatchedWithLimit(recentlyMatched?.filter(({ username: currentUsername }) => currentUsername != username));
                blockUserFn({
                    matchmaking_participant_id: userPreferences?.matchmaking_participant_id,
                    username,
                    matchmaking_gamer_id: matchmakingGamerId,
                });
            }, delay);
        }
    };

    const quit = () => {
        if (userPreferences?.matchmaking_participant_id) {
            quitFn({ matchmaking_participant_id: userPreferences?.matchmaking_participant_id });
        }
    };

    const reject = (username?: string, delay = 0) => {
        if (!username) return;
        setTimeout(() => {
            const newRejectedUsers = Array.from(new Set([...(rejectedUsers || []), username]));
            setRejectedUsers(newRejectedUsers);
        }, delay);
    };

    useEffect(() => {
        create(createInput);
        getTypes();
        getAllGames();
    }, []);

    useEffect(() => {
        if (!(isCreateLoading || isFollowLoading || isUnfollowLoading || isBlockUserLoading)) {
            searching({});
        }
    }, [isCreateLoading, isFollowLoading, isUnfollowLoading, isBlockUserLoading]);

    useEffect(() => {        
        if (searchingData && searchingData?.is_successful && searchingData.data && !isSearchingLoading) {
            
            const { user_preferences, search_participants, recently_matched } = searchingData.data;
            setUserPreferences(user_preferences);
            setSearchParticipants(search_participants);
            setRecentlyMatchedWithLimit(_.reverse(recently_matched));
            if (isPageLoading) {
                setIsPageLoading(false);
            }
        }
    }, [searchingData, isSearchingLoading]);

    useEffect(() => {
        const platforms = types?.getTypes.data.platforms;
        const currentPlatform = userPreferences?.reference_platform_code;
        if (platforms && currentPlatform) {
            const platformName = types?.getTypes.data.platforms?.find(({ platform_code }) => platform_code === currentPlatform);
            setPlatform(platformName && platformName[`platform_name_${language}`]);
        }
    }, [types, userPreferences]);

    useEffect(() => {
        const allGames = games?.getAllGames.data;
        const currentGame = userPreferences?.reference_game_code;
        if (allGames && currentGame) {
            setGame(allGames.find(({ game_code }) => game_code === currentGame)?.name_en);
        }
    }, [games, userPreferences]);

    useEffect(() => {
        setSearchParticipantsFiltered(searchParticipants?.filter(({ username }) => !rejectedUsers?.includes(username)));
    }, [rejectedUsers, searchParticipants]);

    return (
        <MatchmakingContext.Provider
            value={{
                userPreferences,
                searchParticipants,
                searchParticipantsFiltered,
                recentlyMatched,
                quitData,
                platform,
                game,
                referenceLang: createInput.reference_lang_code,
                isPageLoading,
                isQuitLoading,
                isActionDisabled,
                searchingData,
                follow,
                unfollow,
                block,
                quit,
                reject,
            }}
        >
            {children}
        </MatchmakingContext.Provider>
    );
};

export const useMatchmakingProvider = (): UseMatchmakingProvider => {
    const context = useContext(MatchmakingContext);

    if (!context) throw 'Please use a provider to use this hook';

    return context;
};
