import { IconNames } from '@fe-monorepo/helper';
import { ReactNode, useEffect, useRef, useState } from 'react';

import usePageLayout from '../../../hooks/usePageLayout';
import ResponsiveIcon from '../../Atoms/Icon/ResponsiveIcon';
import './ScrollBar.scss';

interface ScrollBarProps {
  children?: ReactNode;
  containerClassName?: string;
  leftArrowClassName?: string;
  rightArrowClassName?: string;
  leftArrowClassNameOnReachEnd?: string;
  rightArrowClassNameOnReachEnd?: string;
  iconSize?: number;
  iconClassName?: string;
}

const ScrollBar = (props: ScrollBarProps) => {
  const listRef = useRef<any>(null);

  const pageLayout = usePageLayout();

  const [isReachedToRight, setIsReachedToRight] = useState<boolean>(pageLayout?.language === 'en' ? false : true);
  const [isReachedToLeft, setIsReachedToLeft] = useState<boolean>(pageLayout?.language === 'en' ? true : false);

  const slideLeft = () => {
    listRef.current.scrollLeft = listRef?.current.scrollLeft - 1000;
  };

  const slideRight = () => {
    listRef.current.scrollLeft = listRef?.current?.scrollLeft + 1000;
  };

  const handleScroll = () => {
    const isRight =
      pageLayout?.language === 'en'
        ? listRef?.current?.scrollWidth - listRef?.current?.scrollLeft === listRef?.current?.clientWidth
        : listRef?.current?.scrollLeft === 0;
    const isLeft =
      pageLayout?.language === 'en'
        ? listRef?.current?.scrollLeft === 0
        : listRef?.current?.scrollLeft + listRef?.current?.scrollWidth === listRef?.current?.clientWidth;

    setIsReachedToRight(isRight);
    setIsReachedToLeft(isLeft);
  };

  useEffect(() => {
    setTimeout(() => {
      handleScroll();
    }, 5000);
  }, [pageLayout?.language]);

  return (
    <div className="relative flex items-center content-center w-full">
      <span
        className={`flex items-center absolute left-0 z-[10] ${
          isReachedToLeft ? `cursor-not-allowed ${props?.leftArrowClassNameOnReachEnd}` : `cursor-pointer`
        } ${props?.leftArrowClassName}`}
        onClick={slideLeft}
      >
        <ResponsiveIcon
          className={`rotate-180 ${
            isReachedToLeft
              ? props?.iconClassName
                ? props?.iconClassName
                : `fill-secondary/40 ${props?.leftArrowClassNameOnReachEnd}`
              : props?.iconClassName
              ? props?.iconClassName
              : `fill-white`
          }`}
          name={IconNames.chevronRight}
          baseHeight={props?.iconSize ? props?.iconSize : 20}
          baseWidth={props?.iconSize ? props?.iconSize : 20}
        />
      </span>
      <div
        ref={listRef}
        onScroll={handleScroll}
        className={`${props?.containerClassName} w-fit flex gap-22 m-0 overflow-x-scroll whitespace-nowrap scroll-smooth`}
      >
        {props?.children}
      </div>
      <span
        className={`flex items-center absolute right-0 z-[10] ${
          isReachedToRight ? `cursor-not-allowed ${props?.rightArrowClassNameOnReachEnd}` : `cursor-pointer`
        } ${props?.rightArrowClassName}`}
        onClick={slideRight}
      >
        <ResponsiveIcon
          className={`${
            isReachedToRight
              ? props?.iconClassName
                ? props?.iconClassName
                : `fill-secondary/40`
              : props?.iconClassName
              ? props?.iconClassName
              : `fill-white`
          }`}
          name={IconNames.chevronRight}
          baseHeight={props?.iconSize ? props?.iconSize : 20}
          baseWidth={props?.iconSize ? props?.iconSize : 20}
        />
      </span>
    </div>
  );
};

export default ScrollBar;
