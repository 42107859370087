const DropDownText = (props: { isSelected: boolean; text: string; selectedIconPosition: 'start' | 'end' }) => {
  return (
    <p
      className={`
        px-[1rem]
        whitespace-nowrap
        text-fs-body
        py-[0.5rem]
        font-regular
        truncate
        ${props.isSelected && props.selectedIconPosition === 'start' ? `ps-[1rem]` : `ps-[1rem]`}

        pe-16 2K:pe-[28px] 4K:pe-40 8K:pe-81
      `}
    >
      {props.text}
    </p>
  );
};

// TODO: change 'any' to T
interface Props<T> {
  currentValue?: T;
  options: T[];
  selectedIconPosition: 'start' | 'end';
  selectValue: (option: T) => void;
  getStringValue: (option: T) => string;
  hiddenIndices?: number[];
  bgColor?: string;
  hasBGColor?: boolean;
}

const DropDownList = <T extends { id: string | number }>(props: Props<T>) => {
  const { currentValue, options, selectValue, getStringValue, selectedIconPosition, hiddenIndices, hasBGColor, bgColor } = props;

  return (
    <div
      className={`
          bg-primary
          overflow-y-scroll

          max-h-[7.688rem]
          border border-secondary/20
          border-[0.063rem]
          rounded-[0.25rem]
          min-w-[14rem]
        `}
    >
      {options?.map((option, index) => {
        const isSelected = option.id === currentValue?.id;
        const isHidden = hiddenIndices?.includes(index);
        if (isHidden) {
          return null;
        } else if (isSelected) {
          return (
            <div
              className={`
                relative
                flex items-center
                text-sunset
                pointer-normal cursor-default
                w-full ${hasBGColor ? bgColor : ''}
              `}
            >
              {/* { selectedIconPosition === "start" && selectedIcon } */}
              <DropDownText isSelected={true} selectedIconPosition={selectedIconPosition} text={getStringValue(currentValue)} />
              {/* { selectedIconPosition === "end" && selectedIcon } */}
            </div>
          );
        } else {
          return (
            <div
              key={index}
              className="text-secondary flex items-center hover:bg-sunset/[0.1] hover:text-sunset cursor-pointer w-full"
              onClick={() => selectValue(option)}
            >
              <DropDownText isSelected={false} selectedIconPosition={selectedIconPosition} text={getStringValue(option)} />
            </div>
          );
        }
      })}
    </div>
  );
};

export default DropDownList;
