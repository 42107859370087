import { TxKeyPath } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import { ProductSpecifications, SpecificationsResponse } from '@fe-monorepo/models';
import Container from '@fe-web/Templates/Container';
import usePageLayout from '@fe-web/hooks/usePageLayout';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { DescriptionValues } from '../ProductDetailedPage';
import ProductDetails, { ProductDetailsProps } from './MainSection/Components/ProductDetails/ProductDetails';

interface Props {
  chosenSection: DescriptionValues;
  details: ProductDetailsProps;
  specifications: ProductSpecifications;
}

const ListItem = (props: { text: string }) => {
  // TODO: fix this
  return (
    <li className="flex gap-[0.8rem] responsive-text-body">
      <p className="select-none text-[5px] 2K:text-[8.88px] 4K:text-[13.33px] 8K:text-[26.66px] text-secondary/[0.5]">●</p>
      {/* STCACN-4891 onyx color */}
      <p className="font-regular text-fs-body text-secondary/[0.5] not-italic leading-6 text-onyx">{props.text}</p>
    </li>
  );
};
const DeliveryAndReturns = () => {
  const { translate } = useTranslate();

  const items: string[] = [
    'delivery_and_returns.rule1',
    'delivery_and_returns.rule2',
    'delivery_and_returns.rule3',
    'delivery_and_returns.rule4',
    'delivery_and_returns.rule5',
    'delivery_and_returns.rule6',
  ];

  return (
    <div
      className={`
        relative
        flex flex-col
        gap-32 SD:gap-24 2K:gap-[42.66px] 4K:gap-64 8K:gap-[128px]
        mt-[40px] 2K:mt-[60px] 4K:mt-[80px] 8K:mt-[160px]
      `}
    >
      <h1
        className={`
          text-secondary
          w-full
          font-medium
          text-fs-big-title
        `}
      >
        {translate('shop.productPage.deliveryReturns')}
      </h1>

      <ul
        className={`
          min-w-[335px] SD:w-[893px] 2K:w-[1587.55px] 4K:w-[2381.33px] 8K:w-[4762.66px]
          list-disc list-inside
        `}
      >
        {items.map((item, index) => (
          <ListItem key={index} text={translate(item as TxKeyPath) as string} />
        ))}
      </ul>
    </div>
  );
};

interface SpecificationProps {
  specifications: ProductSpecifications;
}

const Specification = (props: SpecificationProps) => {
  const [productType, setProductType] = useState<string>('');
  const { translate } = useTranslate();
  //TODO: use this to fetch the data from the API using the productID
  const { productID } = useParams<{ productID: string }>();
  const [platform, setPlatform] = useState<SpecificationsResponse>();
  const [releaseDate, setReleaseDate] = useState<SpecificationsResponse>();
  const [compatible, setCompatible] = useState<SpecificationsResponse>();
  const [dimension, setDimension] = useState<SpecificationsResponse>();
  const [genre, setGenre] = useState<SpecificationsResponse>();

  //TODO: use this to transition the language from the API
  const { language, direction } = usePageLayout();
  //create a useEffect that will set the product type base from the API response
  //TODO: edit this to fetch the data from the API
  useEffect(() => {
    setProductType('physical');
    const platforms = props.specifications.specifications.find(data => {
      return data.name_en === 'platform';
    });
    const releaseDates = props.specifications.specifications.find(data => {
      return data.name_en === 'release date';
    });
    const compatibleDevices = props.specifications.specifications.find(data => {
      return data.name_en === 'compatible device';
    });
    const dimensions = props.specifications.specifications.find(data => {
      return data.name_en === 'dimension';
    });
    const genres = props.specifications.specifications.find(data => {
      return data.name_en === 'genre';
    });
    setPlatform(platforms);
    setReleaseDate(releaseDates);
    setCompatible(compatibleDevices);
    setDimension(dimensions);
    setGenre(genres);
  }, [props.specifications]);

  const getNames = (data: SpecificationsResponse) => {
    const names = data.values.map(name => {
      return name[`name_${language}`];
    });
    return names.join(', ');
  };

  return (
    <div>
      <h1 className="text-fs-big-title not-italic font-medium leading-[3rem] tracking-[-0.075rem] mt-[2.5rem] text-secondary">
        {translate('shop.specificationPage.specification')}
      </h1>
      {/* container for the specification */}
      <div className="flex flex-col justify-center gap-[1.5rem] SD:gap-[2rem] mt-[2.5rem]">
        {/* SKU */}
        {productType === 'physical' && props.specifications?.sku && (
          <div className="flex items-center flex-col SD:flex-row gap-0 SD:gap-[1.5rem]">
            <div className="w-full SD:w-[11.25rem] text-fs-body not-italic font-medium leading-6 text-secondary">
              {translate('shop.specificationPage.SKU')}
            </div>
            <div className="w-full SD:w-[43.0625rem] text-secondary/50 text-fs-body not-italic font-normal leading-6">
              {props?.specifications?.sku}
            </div>
          </div>
        )}
        {/* Platform */}
        {productType === 'physical' && platform && (
          <div className="flex items-center flex-col SD:flex-row gap-0 SD:gap-[1.5rem]">
            <div className="w-full SD:w-[11.25rem] text-fs-body not-italic font-medium leading-6 text-secondary">
              {translate('shop.specificationPage.platform')}
            </div>
            <div className="w-full SD:w-[43.0625rem] text-secondary/50 text-fs-body not-italic font-normal leading-6">
              {getNames(platform)}
            </div>
          </div>
        )}
        {/* Release */}
        {productType === 'physical' && releaseDate?.values && (
          <div className="flex items-center flex-col SD:flex-row gap-0 SD:gap-[1.5rem]">
            <div className="w-full SD:w-[11.25rem] text-fs-body not-italic font-medium leading-6 text-secondary">
              {translate('shop.specificationPage.release')}
            </div>
            <div className="w-full SD:w-[43.0625rem] text-secondary/50 text-fs-body not-italic font-normal leading-6">
              {moment(releaseDate?.values[0]?.name_en).format('MMM D YYYY')}
            </div>
          </div>
        )}
        {/* Compatible Device */}
        {productType === 'physical' && compatible?.values && (
          <div className="flex items-center flex-col SD:flex-row gap-0 SD:gap-[1.5rem]">
            <div className="w-full SD:w-[11.25rem] text-fs-body not-italic font-medium leading-6 text-secondary">
              {translate('shop.specificationPage.compatible_device')}
            </div>
            <div className="w-full SD:w-[43.0625rem] text-secondary/50 text-fs-body not-italic font-normal leading-6">
              {getNames(compatible)}
            </div>
          </div>
        )}
        {/* Assembled and Dimensions */}
        {productType === 'physical' && dimension?.values && (
          <div className="flex items-center flex-col SD:flex-row gap-0 SD:gap-[1.5rem]">
            <div className="w-full SD:w-[11.25rem]">
              <div className="text-fs-body not-italic font-medium leading-6 text-secondary">
                {translate('shop.specificationPage.assembled_product')}
              </div>
              <div className="text-fs-body not-italic font-medium leading-6 text-secondary">
                {translate('shop.specificationPage.dimensions')}
              </div>
            </div>
            <div className="w-full SD:w-[43.0625rem] text-secondary/50 text-fs-body not-italic font-normal leading-6">
              {getNames(dimension)}
            </div>
          </div>
        )}
        {/* Action */}
        {productType === 'physical' && genre?.values && (
          <div className="flex items-center flex-col SD:flex-row gap-0 SD:gap-[1.5rem]">
            <div className="w-full SD:w-[11.25rem] text-fs-body not-italic font-medium leading-6 text-secondary">
              {translate('shop.specificationPage.genre')}
            </div>
            <div className="w-full SD:w-[43.0625rem] text-secondary/50 text-fs-body not-italic font-normal leading-6">
              {getNames(genre)}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const DescriptionSection = (props: Props) => {
  const { chosenSection, details, specifications } = props;
  const [isPassedContainer, setIsPassedContainer] = useState<boolean>(false);

  const Sections = {
    delivery: <DeliveryAndReturns />,
    specification: <Specification specifications={specifications} />,
    details: <ProductDetails {...details} />,
  };

  return (
    <section id="sectionContainer">
      <Container>{Sections[chosenSection]}</Container>
    </section>
  );
};

export default DescriptionSection;
