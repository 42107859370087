import styled from 'styled-components';
import tw from 'twin.macro';

const StyledMainSection = styled.div`
    ${tw`SD:col-span-3 flex flex-col gap-y-[2rem] SD:gap-y-[2.5rem] divide-y 8K:mr-[1.5rem] divide-black10`}
`;

const StyledSideSection = styled.div`
    ${tw`SD:col-span-1`}
`;

const StyledH2 = styled.h2`
    ${tw`font-medium text-fs-body-large SD:text-fs-title text-secondary`}
`;

export { StyledMainSection, StyledSideSection, StyledH2 };
