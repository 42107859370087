import { IMAGES, LottieIconAnimations } from '@fe-monorepo/assets';
import { IMAGE_ALT_LABELS, IconNames, TxKeyPath } from '@fe-monorepo/helper';
import { BGType, useAccount, useThemedBackground, useTranslate, useWallet } from '@fe-monorepo/hooks';
import { RootState, clearCart, clearDeliveryAddress, clearInvoice, setFollowersFollowing, useAppDispatch } from '@fe-monorepo/store';
import Button from '@fe-web/Atoms/Buttons/Button';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import CurrencyText from '@fe-web/Atoms/Text/CurrencyText';
import PlayerAvatar from '@fe-web/Molecules/PlayerAvatar';
import convivaHelper from '@fe-web/helpers/convivaHelper';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import { useBitsProvider } from '@fe-web/hooks/useBits/BitsProvider';
import useMobileDetect from '@fe-web/hooks/useMobileDetect';
import { useNotificationProvider } from '@fe-web/hooks/useNotification/useNotificationProvider';
import useOutsideClick from '@fe-web/hooks/useOutsideClick';
import { t } from 'i18next';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import { useEffect, useRef, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { AppRoutes } from '../../../app.routes.enum';
import ThemeToggle from './ThemeToggle';

interface IMenuItems {
  label: string;
  path: string;
  icon: IconNames;
}

const MenuItems: Array<IMenuItems> = [
  {
    label: 'pageTitle_myOrders',
    path: AppRoutes.myorders,
    icon: IconNames.orders,
  },
  {
    label: 'profile.settings',
    path: AppRoutes.settings,
    icon: IconNames.gearIcon,
  },
  {
    label: 'profile.help',
    path: AppRoutes.help,
    icon: IconNames.support,
  },
];

const BackgroundImage = (props: { src: string }) => (
  <div
    className="
          z-[0]
          absolute
          top-0
          overflow-hidden
          h-full w-full"
  >
    {props?.src && (
      <>
        <LazyLoadImage className="absolute w-full h-full" src={props.src} title="Theme BG" alt="Theme BG" />
        <div className="absolute w-full h-full" />
        <div className="absolute w-full h-full" />
      </>
    )}
  </div>
);

const UserProfile = () => {
  const dispatch = useAppDispatch();
  const [toggleModal, setToggleModal] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMobileDetect();
  const { translate } = useTranslate();
  const { logoutFromQP } = useBitsProvider();
  const { logout } = useAccount();
  const cover_banner_url = useSelector((state: RootState) => state.user.userContext.cover_banner_url);
  const total_balance = useSelector((state: RootState) => state.wallet.userWallet.total_balance);
  const user = useSelector((state: RootState) => state.user.userContext);
  const themeConfig = useSelector((state: RootState) => state.app.themeConfig);
  const isRTL = useSelector((state: RootState) => state.app.isRTL);
  const { removeDeviceNotification } = useNotificationProvider();
  const { backgroundImage: backgroundProfileImage } = useThemedBackground(BGType.profile, true);

  const lottieRef = useRef<LottieRefCurrentProps>(null);
  const { getUserWallet } = useWallet();

  const elementRef = useOutsideClick(() => {
    setToggleModal(false);
  });

  //Followers Following
  const openFollowersModal = (initialTab: string) => {
    setToggleModal(false);
    dispatch(
      setFollowersFollowing({
        isFollowersFollowingOpen: true,
        initialTab: initialTab,
      }),
    );
  };

  //Call the getUserWallet function to update the user wallet current balance
  useEffect(() => {
    getUserWallet();
  }, []);

  useEffect(() => {
    if (toggleModal) {
      mixpanelHelper.trackPageView('PROFILE LANDING PAGE', 'profile_landing_page', window.location.pathname, document.referrer);
    }
  }, [toggleModal]);

  return (
    <>
      {!isMobile && (
        <>
          <div className="gap-24 4xl:gap-[42.66px]  5xl:gap-64 8xl:gap-[128px] flex items-center animate__animated animate__fadeIn">
            <div
              className="divider border-[1px] 4xl:border-[1.77px] 5xl:border-[2.66px]
                8xl:border-[5.33px] border-solid
                border-secondary/20 h-18 4xl:h-32 5xl:h-48 8xl:h-[96px]"
            />
            <div
              onClick={() => {
                setToggleModal(prevState => !prevState);
              }}
              className="flex flex-row shrink-0 gap-[0.5rem]"
            >
              <PlayerAvatar
                avatarStyle="cursor-pointer"
                avatarUrl={user?.avatar_url ?? ''}
                avatarSize={2.5}
                dir="row"
                hasLabels={false}
                altLabel={translate(IMAGE_ALT_LABELS.nav_bar_user_avatar as TxKeyPath) ?? ''}
              />

              <div
                className="flex
                  gap-[0.625rem]
                  items-center cursor-pointer"
              >
                <ResponsiveIcon
                  className=" fill-secondary/50 animate__animated animate__fadeIn"
                  name={IconNames.chevron}
                  baseWidth={20}
                  baseHeight={20}
                />
              </div>
            </div>
          </div>
          {/* MODAL */}
          {toggleModal && (
            <div className="fixed inset-0 flex justify-end z-[9999] my-[1rem] ltr:mr-[1rem] rtl:ml-[1rem]">
              <div className="fixed inset-0 bg-black modal-overlay opacity-30"></div>
              <div
                ref={ref => (elementRef.current = ref)}
                className="
                  modal-container
                  bg-primary rounded-lg w-[24.125rem]
                  shadow-lg z-50
                  inline-flex flex-col
                  relative
                  overflow-y-scroll
                  justify-start items-center"
              >
                <button
                  className="absolute ltr:right-0 rtl:left-0 z-[15] bg-primary p-[0.25rem] rounded-sm  
                    mt-[1rem] ltr:mr-[0.938rem] rtl:ml-[0.938rem]"
                  onClick={() => setToggleModal(false)}
                >
                  <ResponsiveIcon className="fill-secondary" name={IconNames.close1} baseHeight={20} baseWidth={20} />
                </button>
                <div
                  className="w-[24.125rem] h-[15rem] z-10 absolute
                    before:absolute before:inset-0
                    before:block before:z-[-5] before:bg-gradient-to-t from-primary"
                >
                  <div className="relative">
                    <img
                      className="w-[24.125rem] h-[15rem] z-[-10] relative object-cover"
                      src={cover_banner_url ?? IMAGES.UserProfile_Header_Placeholder.toString()}
                      alt={translate(IMAGE_ALT_LABELS.user_cover_banner as TxKeyPath) ?? ''}
                    />
                    {themeConfig && backgroundProfileImage && <BackgroundImage src={backgroundProfileImage ?? ''} />}
                  </div>
                </div>
                <div className="h-full inline-flex flex-col items-center pb-[3.5rem] pt-[5rem] px-[1.5rem] z-10">
                  <PlayerAvatar
                    containerStyle="px-[1.5rem]"
                    avatarStyle="object-cover 2K:mb-[0.75rem] mb-[1.5rem] w-[7.5rem] h-[7.5rem]"
                    avatarUrl={user?.avatar_url ?? ''}
                    displayName={`${user?.display_name}`}
                    userId={user?.username.charAt(0) !== '@' ? `@${user?.username}` : `${user?.username}`}
                    avatarSize={7.5}
                    dir="col"
                    hasVerified
                    labelContainerStyle="mb-[0.25rem] gap-[0.5rem]"
                    displayNameStyle="webkit-truncate-2 text-center text-secondary text-fs-subtitle font-medium"
                    userIdStyle="2K:mb-[0.5rem] mb-[1rem] text-center text-zinc-600-400 text-fs-body-small font-regular"
                    altLabel={translate(IMAGE_ALT_LABELS.user_profile_picture as TxKeyPath) ?? ''}
                  />
                  <div className="2K:mb-[0.75rem] mb-[1.5rem]">
                    <p className="text-center text-secondary text-fs-body-small webkit-truncate-2 font-regular">{user?.bio}</p>
                  </div>
                  <div className="2K:mb-[0.75rem] mb-[1.5rem] justify-center items-center gap-[1rem] inline-flex">
                    <Button
                      className={`text-fs-body text-sunset font-medium border-[0.063rem] border-sunset rounded-sm px-[1.5rem] py-[0.5rem] ${
                        !isRTL ? `after:bg-primary before:bg-sunset` : `before:bg-primary after:bg-sunset`
                      } hover:text-white100`}
                      text={translate('profile.view_profile')}
                      onClick={() => {
                        navigate(AppRoutes.users + `/${user?.username}`);
                        setToggleModal(false);
                      }}
                    />
                  </div>
                  {/* FOLLOWER SECTION */}
                  <div className="2K:mb-[0.75rem] mb-[1.5rem] self-stretch justify-between items-start inline-flex">
                    <button
                      className="flex-col justify-start items-start gap-[0.25rem] inline-flex"
                      onClick={() => openFollowersModal('following')}
                    >
                      <div className="w-[10.421rem] text-center text-secondary font-medium font-['STC Forward'] text-fs-body">
                        {user?.total_following > 0 ? user?.total_following : 0}
                      </div>
                      <div className="w-[10.421rem] text-center text-zinc-600-400 font-regular text-fs-caption">
                        {translate('profile.following')}
                      </div>
                    </button>
                    <div className="w-[0.0625rem] self-stretch bg-gray-200-zinc-700 rounded-sm" />
                    <button
                      className="flex-col justify-start items-start gap-[0.25rem] inline-flex"
                      onClick={() => openFollowersModal('followers')}
                    >
                      <div className="w-[10.515rem] text-center text-secondary font-medium text-fs-body">
                        {user?.total_followers > 0 ? user?.total_followers : 0}
                      </div>
                      <div className="w-[10.515rem] text-center text-zinc-600-400 font-regular text-fs-caption">
                        {translate('profile.followers')}
                      </div>
                    </button>
                  </div>
                  {/* WALLET SECTION */}
                  <div className="w-[21.125rem] h-[5.5rem] relative 2K:mb-[0.75rem] mb-[1.5rem] border rounded border-purple40">
                    <Lottie
                      animationData={LottieIconAnimations.Icon_Wallet}
                      loop={true}
                      style={{
                        height: `${'5.5rem'}`,
                        width: `${'21.125rem'}`,
                      }}
                      autoPlay={false}
                      lottieRef={lottieRef}
                      onMouseEnter={lottieRef?.current?.play}
                      onMouseLeave={lottieRef.current?.pause}
                    />
                    <img className="w-[21.125rem] h-[5.5rem] left-0 top-0 absolute rounded" src={IMAGES.WalletNoise.toString()} />
                    <div className="w-[1.5rem] h-[1.5rem] top-0 ltr:right-0 rtl:left-0 my-[2rem] ltr:mr-[1rem] rtl:ml-[1rem] absolute z-[10]">
                      <Link to={AppRoutes.walletSettings}>
                        <ResponsiveIcon
                          className="
                            fill-white100 cursor-pointer ltr:rotate-[270deg] rtl:rotate-90
                            animate__animated animate__fadeIn"
                          name={IconNames.chevron}
                          baseHeight={24}
                          baseWidth={24}
                        />
                      </Link>
                    </div>
                    <div
                      className="w-[21.125rem] h-[5.5rem]
                        left-0 top-0 absolute rounded border border-moonlight"
                    />
                    <div className="w-[19.107rem] h-14 ltr:left-[1.009rem] rtl:right-[1.009rem] top-[1rem] absolute">
                      <div className="w-[17.594rem] ltr:left-0 rtl:right-0 top-[1.5rem] absolute text-white100 font-medium text-fs-subtitle">
                        <CurrencyText currency={Number(total_balance)} />
                      </div>
                      <div className="w-[17.594rem] ltr:left-0 rtl:right-0 top-0 absolute text-white100 font-regular text-fs-caption">
                        {translate('pageTitle_wallet')}
                      </div>
                    </div>
                  </div>
                  {/* MENU SECTION */}
                  <div className="h-fit pb-[2.5rem]">
                    <div className="2K:mb-[0rem] mb-[1.5rem] w-[21.125rem] h-[8.5rem] flex-col justify-start 2K:gap-[1rem] gap-[2rem] inline-flex">
                      {MenuItems.map((menu, key) => (
                        <Link to={menu.path}>
                          <div key={key} className="w-full self-stretch justify-start items-center gap-[0.75rem] inline-flex">
                            <ResponsiveIcon className={`fill-secondary`} name={menu.icon} baseHeight={20} baseWidth={20} />
                            <div className="grow shrink basis-0 text-secondary text-fs-body font-regular">{t(menu.label)}</div>
                            <ResponsiveIcon
                              className="
                                fill-secondary cursor-pointer ltr:rotate-[270deg] rtl:rotate-90
                                animate__animated animate__fadeIn"
                              name={IconNames.chevron}
                              baseHeight={16}
                              baseWidth={16}
                            />
                          </div>
                        </Link>
                      ))}
                    </div>
                    {/* DIVIDER */}
                    <div className="mb-[1.5rem] w-[21.125rem] h-[0px] border-[0.063rem] border-gray-200-zinc-700"></div>
                    {/* THEME & SIGN OUT */}
                    <div className="w-[21.125rem] flex-col justify-start items-center gap-[1rem] inline-flex">
                      <ThemeToggle iconSize={20} text={t('dark_mode')} />
                      <div className="flex flex-col items-start self-stretch justify-start">
                        <div
                          onClick={() => {
                            removeDeviceNotification();
                            dispatch(clearCart());
                            dispatch(clearInvoice());
                            dispatch(clearDeliveryAddress());
                            logout();
                            mixpanelHelper.reset();
                            convivaHelper.setIdentity('GUEST');
                            logoutFromQP();
                            localStorage.removeItem('selectedTimezone');
                            localStorage.removeItem('selectedCurrency');
                            localStorage.removeItem('selectedCountry');
                          }}
                          className="w-[14.438rem] justify-start items-center gap-[0.5rem] inline-flex cursor-pointer"
                        >
                          <ResponsiveIcon
                            className="fill-secondary rtl:rotate-[180deg]"
                            name={IconNames.logoutNew}
                            baseHeight={20}
                            baseWidth={20}
                          />
                          <div className="grow shrink basis-0 text-error text-fs-body font-regular">{translate('common_sign_out')}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default UserProfile;
