type ReasonCode =
    | 'changed_their_mind'
    | 'damaged_item'
    | 'missing_parts_or_accessories'
    | 'different_order'
    | 'item_not_as_described'
    | 'defective_item';

type ReturnReason = {
    return_reason_code: ReasonCode;
    return_reason_ar: string;
    return_reason_en: string;
};

export const ReturnReasons: ReturnReason[] = [
    {
        return_reason_code: 'changed_their_mind',
        return_reason_ar: 'قمت بتغيير رأيي',
        return_reason_en: 'I changed my mind',
    },
    {
        return_reason_code: 'damaged_item',
        return_reason_ar: 'استلمت منتج تالف',
        return_reason_en: 'I received a damaged item',
    },
    {
        return_reason_code: 'missing_parts_or_accessories',
        return_reason_ar: 'استلمت منتج يحتوي على أجزاء أو ملحقات مفقودة',
        return_reason_en: 'I received an item with missing parts or accessories',
    },
    {
        return_reason_code: 'different_order',
        return_reason_ar: 'استلمت منتج مختلف عما تم طلبه',
        return_reason_en: 'I received an item different from what was ordered',
    },
    {
        return_reason_code: 'item_not_as_described',
        return_reason_ar: 'استلمت منتج يختلف عما هو مذكور في وصف المنتج',
        return_reason_en: 'I received an item that is not as described',
    },
    {
        return_reason_code: 'defective_item',
        return_reason_ar: 'استلمت منتج وبه خلل',
        return_reason_en: 'I received a defective item',
    },
];
