import { gql } from '@apollo/client';

export const WALLET_QUERY = {
    getWalletHistory: gql`
        query fetchWalletHistory($details: WalletParams) {
            getWalletHistory(details: $details) {
                error_msg
                error_code
                is_successful
                data {
                    transaction_type_en
                    transaction_type_ar
                    transaction_type_desc_en
                    transaction_type_desc_ar
                    amount
                    posted_on
                    last_cursor
                }
            }
        }
    `,
    getWallet: gql`
        query fetchWallet {
            getWallet {
                error_msg
                error_code
                is_successful
                data {
                    entity_type
                    wallet_number
                    total_balance
                    created_at
                }
            }
        }
    `,
};
