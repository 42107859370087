/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery, useMutation } from '@apollo/client';
import { ACCOUNT_MUTATION, LOGIN_MUTATION, TOURNAMENT_QUERY, USER_QUERY } from '@fe-monorepo/data-access';
import {
  AccountModel,
  AvatarURL,
  CoverBannerInput,
  GetAllNetworkInput,
  LockDurationModel,
  ProfileSocialMedia,
  UserModel,
  UserTournaments,
  UserTournamentsInput,
} from '@fe-monorepo/models';
import {
  AppStateSliceState,
  PageErrorTypes,
  RootState,
  clearCart,
  clearDeliveryAddress,
  clearInvoice,
  clearPreferences,
  clearUser,
  clearWallet,
  setFirebaseMessagingToken,
  setIsGuestUser,
  setMatchmakingPreference,
  setPersona,
  setPreferences,
  setUser,
  setValidateAccount,
  useAppDispatch,
} from '@fe-monorepo/store';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useAppState, useCheckProductAvailability, useMutateQuery, useRecentlyViewed } from '../index';
import { useFetchQuery } from '../useFetchQuery';
import {
  AcceptConsentModel,
  AcceptConsentResponse,
  AccountParams,
  EditAccountResponse,
  LockDurationParams,
  LockDurationResponse,
  LogoutResponse,
  ProfileParams,
  ProfileParamsType,
  ResetPasswordResponse,
  ResponseData,
  UpdateAccountResponse,
  UpdatePasswordResponse,
  UpdateProfileResponse,
  UserProfileResponse,
  UserProfileResponseSSoLogin,
  ValidPasswordResponse,
} from './type';

export const useAccount = () => {
  const [userProfileInfo, setUserProfileInfo] = useState<UserModel>();
  const [lockDuration, setLockDuration] = useState<number>();
  const [isUpdatedAccount, setUpdatedAccount] = useState<boolean>();
  const [isUpdatedpassword, setUpdatedPassword] = useState<boolean>();
  const [isSuccessful, setIsSuccessfull] = useState<boolean>();
  const [isValidAccount, setValidAccount] = useState<boolean>();
  const [isLogout, setLogout] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [errorCode, setErrorCode] = useState<string>('');
  const { changeLoadingState } = useAppState();
  const { clearAllRecentlyViewed } = useRecentlyViewed();
  const dispatch = useAppDispatch();
  const { getAllLmd } = useCheckProductAvailability();
  const invoiceInfo = useSelector((state: RootState) => state.cart.invoice);

  // const getUserProfileInfoQuery = useFetchQuery<any, any>(USER_QUERY.getUserProfileInfo);

  const lockActionDuration = useFetchQuery<LockDurationResponse[], LockDurationParams>(USER_QUERY.lockDuration, []);

  const [lockDurationGql, { data: lockDurationResponse, error: lockDurationError }] = useLazyQuery<
    LockDurationResponse,
    LockDurationParams
  >(USER_QUERY.lockDuration, {
    errorPolicy: 'all',
  });

  const [getUserProfileInfo, { data: userProfile, error: userProfileError }] = useLazyQuery(USER_QUERY.getUserProfileInfo, {
    errorPolicy: 'all',
  });

  const [getUserProfileInfoSSoLogin, { data: userProfileSSoLogin, error: userInfoProfileSoLoginError }] = useLazyQuery<
    UserProfileResponseSSoLogin,
    ProfileParams
  >(USER_QUERY.getUserProfileInfoSSoLogin, {
    errorPolicy: 'all',
  });

  const [getInfoByUsername, { data: userInfoProfile, error: userInfoProfileError }] = useLazyQuery<UserProfileResponse, AccountParams>(
    USER_QUERY.getInfoByUsername,
    {
      errorPolicy: 'all',
    },
  );

  const [updateUsername, { data: updateUsernameData, error: updateUsernameError }] = useMutation<UpdateAccountResponse, AccountParams>(
    ACCOUNT_MUTATION.updateUsername,
    {
      onError: e => {
        changeLoadingState(false);
      },
    },
  );

  const [acceptConsent, { data: acceptConsentData, error: acceptConsentError }] = useMutation<AcceptConsentResponse>(
    ACCOUNT_MUTATION.AcceptConsent,
    {
      onError: e => {
        changeLoadingState(false);
      },
    },
  );

  const [updatePassword, { data: passwordData, error: passwordError }] = useMutation<UpdatePasswordResponse, AccountParams>(
    ACCOUNT_MUTATION.updatePassword,
    { onError: e => changeLoadingState(false) },
  );

  const [iaValidPassword, { data: validPasswordData, error: validPasswordError }] = useMutation<ValidPasswordResponse, AccountParams>(
    LOGIN_MUTATION.isValidCurrentPassword,
    { onError: e => changeLoadingState(false) },
  );

  const [editAccount, { data: accountData, error: accountError }] = useMutation<EditAccountResponse, AccountParams>(
    ACCOUNT_MUTATION.editAccount,
    {
      onError: e => {
        changeLoadingState(false);
      },
    },
  );

  const [updateProfileGql, { data: profileData, error: profileError }] = useMutation<UpdateProfileResponse, AccountParams>(
    ACCOUNT_MUTATION.updateProfile,
    {
      onError: e => {
        setErrorMessage(e.message);
        changeLoadingState(false);
      },
    },
  );

  const [resetPasswordgql, { data: resetPasswordData, error: resetPasswordError }] = useMutation<ResetPasswordResponse, AccountParams>(
    ACCOUNT_MUTATION.resetPassword,
    {
      onError: e => {
        changeLoadingState(false);
      },
    },
  );

  const [logoutgql, { data: logoutData, error: logoutError }] = useMutation<LogoutResponse>(ACCOUNT_MUTATION.logout, {
    onError: e => {
      changeLoadingState(false);
    },
  });

  const logout = async () => {
    changeLoadingState(true);
    clearAllRecentlyViewed();
    dispatch(clearWallet());

    dispatch(clearDeliveryAddress()); // fix - guest user not able to add address (exceeded address limit eg 3)
    dispatch(clearCart()); // fix - when user logsout the cart items of previous signed in user shown for guest user
    dispatch(clearInvoice()); // fix - clear cart icon badge on logout (else it will show the count of previous signed in user)

    dispatch(setMatchmakingPreference(undefined));
    dispatch(setFirebaseMessagingToken(''));
    dispatch(setIsGuestUser(false));
    logoutgql();
  };

  const getAccessLockDuration = (model: LockDurationModel) => {
    setErrorCode('');
    changeLoadingState(true);
    lockDurationGql({
      variables: { details: model },
    });
  };

  const update = async (model: AccountModel) => {
    setErrorCode('');
    changeLoadingState(true);
    editAccount({
      variables: { details: model },
    });
  };

  const updateAccount = async (model: AccountModel): Promise<any> => {
    setErrorCode('');
    changeLoadingState(true);
    return editAccount({
      variables: { details: model },
    });
  };

  const updateProfile = async (model: AccountModel) => {
    setErrorCode('');
    // changeLoadingState(true);
    updateProfileGql({
      variables: { details: model },
    });
  };
  const updateReturnResponse = async (model: AccountModel) => {
    setErrorCode('');
    changeLoadingState(true);
    return await editAccount({
      variables: { details: model },
    });
  };

  const updateUsernameAndEmail = async (model: AccountModel) => {
    setErrorCode('');
    changeLoadingState(true);
    updateUsername({
      variables: { details: model },
    });
  };
  const updateUsernameReturnResponse = async (model: AccountModel) => {
    setErrorCode('');
    changeLoadingState(true);
    return await updateUsername({
      variables: { details: model },
    });
  };

  const resetPassword = async (model: AccountModel) => {
    setErrorCode('');
    changeLoadingState(true);
    resetPasswordgql({
      variables: { details: model },
    });
  };

  const getUserProfile = async () => {
    return await getUserProfileInfo();
  };

  const getUserProfileSSoLogin = async (params: ProfileParamsType) => {
    return await getUserProfileInfoSSoLogin({
      variables: { details: params },
    });
  };

  const getUserInfoByUsername = async (model: AccountModel) => {
    setUserProfileInfo(undefined);
    return await getInfoByUsername({
      variables: { details: model },
    });
  };

  const acceptUserConsent = async (params: AcceptConsentModel) => {
    return await acceptConsent({
      variables: { details: params },
    });
  };

  const updateAccountPassword = async (model: AccountModel) => {
    setUpdatedPassword(undefined);
    setErrorMessage('');
    setErrorCode('');
    changeLoadingState(true);
    updatePassword({
      variables: { details: model },
    });
  };

  const isAccountPasswordValid = async (model: AccountModel) => {
    changeLoadingState(true);
    iaValidPassword({
      variables: { details: model },
    });
  };

  useEffect(() => {
    if (lockDurationResponse) {
      const data = lockDurationResponse.lockDuration;
      setLockDuration(data.data.time_remaining);
      setIsSuccessfull(data.is_successful);
      setErrorMessage(data.error_msg);
      setErrorCode(data.error_code);
      changeLoadingState(false);
    }
  }, [lockDurationResponse]);

  useEffect(() => {
    if (passwordData) {
      const data = passwordData.updatePassword;
      setUpdatedPassword(data.is_successful);
      setErrorMessage(data.error_msg);
      setErrorCode(data.error_code);
      changeLoadingState(false);
    }
  }, [passwordData]);

  useEffect(() => {
    if (validPasswordData) {
      const data = validPasswordData.isValidCurrentPassword;
      setValidAccount(data.is_successful);
      setErrorMessage(data.error_msg);
      setErrorCode(data.error_code);
      changeLoadingState(false);
    }
  }, [validPasswordData]);

  useEffect(() => {
    if (logoutData) {
      const data = logoutData.logout;
      if (data.error_code === '1010') {
        //session has expired
        setLogout(true);
        dispatch(clearUser());
        dispatch(setValidateAccount(false));
        dispatch(clearPreferences());
      } else {
        setLogout(data.is_successful);
        dispatch(clearUser());
        dispatch(setValidateAccount(false));
        dispatch(clearPreferences());
      }
      changeLoadingState(false);
      dispatch(setIsGuestUser(false));
      dispatch(setPersona('guest'));
    }
  }, [logoutData]);

  useEffect(() => {
    if (logoutError) {
      setLogout(false);
      changeLoadingState(false);
    }
  }, [logoutError]);

  useEffect(() => {
    if (resetPasswordData) {
      const data = resetPasswordData.resetPassword;
      setUpdatedAccount(data.is_successful);
      setErrorMessage(data.error_msg);
      changeLoadingState(false);
    }
  }, [resetPasswordData]);

  useEffect(() => {
    if (accountData) {
      const data = accountData.editAccount;
      setUpdatedAccount(data.is_successful);
      setErrorMessage(data.error_msg);
      changeLoadingState(false);
    }
  }, [accountData]);

  useEffect(() => {
    if (updateUsernameData) {
      const data = updateUsernameData.updateUsername;
      getUserProfile();
      setUpdatedAccount(data.is_successful);
      setErrorMessage(data.error_msg);
      setErrorCode(data.error_code);
      changeLoadingState(false);
    }
  }, [updateUsernameData]);

  useEffect(() => {
    if (userProfile) {
      const { data, error_code } = userProfile.getUserProfileInfo;
      //NOTE - prevent the login flow if the error returns session expired as that means the users token in no longer valid
      if (error_code === PageErrorTypes.SESSION_EXPIRED) {
        //logout();
        return;
      }
      if (data) {
        dispatch(
          setUser({
            uuid: data.uuid,
            display_name: data.display_name,
            username: data.username,
            email: data.email,
            mobile_code: data.mobile_code,
            mobile: data.mobile,
            gamer_type: data.gamer_type,
            avatar_url: data.avatar_url,
            cover_banner_url: data.cover_banner_url,
            bio: data.bio,
            provider: data.provider,
            gender_code: data.gender_code,
            points: data.points ?? 0,
            is_official_account: data.is_official_account ?? 0,
            is_email_verified: data.is_email_verified ?? 0,
            is_mobile_verified: data.is_mobile_verified ?? 0,
            is_2FA_required: data.is_2FA_required ?? 0,
            preferred_2FA_channel: data.preferred_2FA_channel ?? 'email',
            referral_code: data.referral_code,
            country_code: data.country_code,
            reference_wallet_id: data.reference_wallet_id,
            total_followers: data.total_followers ?? 0,
            total_following: data.total_following ?? 0,
            password_updated_at: data.password_updated_at,
            mobile_updated_at: data.mobile_updated_at,
            email_updated_at: data.email_updated_at,
            birthdate: data.birthdate,
            created_at: data.created_at,
            preferred_private_chat_code: data.preferred_private_chat_code,
            preferred_private_chat_name_ar: data.preferred_private_chat_name_ar,
            preferred_private_chat_name_en: data.preferred_private_chat_name_en,
            preferred_group_chat_code: data.preferred_group_chat_code,
            preferred_group_chat_name_ar: data.preferred_group_chat_name_ar,
            preferred_group_chat_name_en: data.preferred_group_chat_name_en,
            is_read_status_enabled: data.is_read_status_enabled,
            preferred_timezone: data.preferred_timezone === 'UTC' ? 'GMT+03:00' : data.preferred_timezone,
            preferred_language: data.preferred_language,
            privacy_consent: data.privacy_consent,
            marketing_consent: data.marketing_consent,
          } as UserModel),
        );
        dispatch(
          setPreferences({
            themes: data.preferred_theme,
            country: data.country_code,
            timezone: data.preferred_timezone === 'UTC' ? 'GMT+03:00' : data.preferred_timezone,
          } as AppStateSliceState),
        );

        // guest user has a null value
        if (data?.username) {
          dispatch(setIsGuestUser(false));
          dispatch(setPersona('existing'));
        }

        if (!invoiceInfo) {
          getAllLmd();
        }
        // if (user.zegoToken === undefined || user.zegoToken === '') {
        //     generateZegoToken().then(response => {
        //         if (response?.is_successful && response.data.token) {
        //             dispatch(
        //                 setUser({
        //                     zegoToken:  response.data.token
        //                 } as UserModel),
        //             );
        //         }
        //     });
        // }
      }
      changeLoadingState(false);
    }
  }, [userProfile]);

  // useEffect(() => {
  //   if (lmd_data.length > 0 && user.username && token) {
  //     if (selectedCity?.lmd_code === '' && !invoiceInfo) {
  //       const city = lmd_data?.find(city => city.city === 'Riyadh');
  //       if (city) {
  //         createInvoice({
  //           lmd_code: city.lmd_code,
  //         });
  //       }
  //     } else if (invoiceInfo === undefined || invoiceInfo === null) {
  //       createInvoice({
  //         lmd_code: selectedCity?.lmd_code,
  //       });
  //     }
  //   }
  // }, [lmd_data]);

  useEffect(() => {
    if (accountError || resetPasswordError) {
      const { data } = userProfile.getUserProfileInfo;
      if (data) {
        dispatch(
          setUser({
            display_name: data.display_name,
            username: data.username,
            email: data.email,
            mobile_code: data.mobile,
            mobile: data.mobile,
            gamer_type: data.gamer_type,
            avatar_url: data.avatar_url ?? '',
            cover_banner_url: data.cover_banner_url ?? '',
          } as UserModel),
        );
        dispatch(
          setPreferences({
            themes: data.preferred_theme,
            country: data.country_code,
            timezone: data.preferred_timezone,
          } as AppStateSliceState),
        );
      }
      changeLoadingState(false);
    }
  }, [accountError, resetPasswordError]);

  useEffect(() => {
    if (profileData) {
      const response = profileData?.updateProfile;
      setErrorMessage(response?.error_msg);
      changeLoadingState(false);
    }
  }, [profileData]);

  useEffect(() => {
    if (userInfoProfile) {
      const response = userInfoProfile?.getInfoByUsername;

      setUserProfileInfo(response?.data);
      setErrorMessage(response?.error_msg);
      setErrorCode(response?.error_code);
      changeLoadingState(false);
    }
  }, [userInfoProfile]);

  useEffect(() => {
    if (userProfileError || userInfoProfileError) {
      changeLoadingState(false);
    }
  }, [userProfileError, userInfoProfileError]);

  useEffect(() => {
    if (updateUsernameError || passwordError || validPasswordError) {
      changeLoadingState(false);
    }
  }, [updateUsernameError, passwordError, validPasswordError]);

  useEffect(() => {
    if (accountError || lockDurationError) {
      changeLoadingState(false);
    }
  }, [accountError, lockDurationError]);

  return {
    isLogout,
    isUpdatedAccount,
    isValidAccount,
    isUpdatedpassword,
    isSuccessful,
    lockDuration,
    errorMessage,
    errorCode,
    profileData,
    userProfileInfo,
    userProfile,
    getUserProfile,
    getUserInfoByUsername,
    updateReturnResponse,
    updateUsernameReturnResponse,
    updateUsernameAndEmail,
    resetPassword,
    updateAccountPassword,
    isAccountPasswordValid,
    getAccessLockDuration,
    update,
    logout,
    updateProfile,
    updateAccount,
    acceptUserConsent,
    getUserProfileSSoLogin,
    setLogout,
  };
};

const defaultValue: ResponseData = {
  token: '',
};

const socialMediaDefault: ProfileSocialMedia = {
  social: [],
  gaming: [],
};

const userTournamentDefault: UserTournaments = {
  acitve_tournamanets: [],
  ended_tournaments: [],
};

export const useAccountNew = () => {
  const passwordResetByOTP = useMutateQuery<ResponseData, AccountModel>(ACCOUNT_MUTATION.resetPassword, defaultValue);

  const publicUserProfile = useFetchQuery<UserModel | null, AccountModel>(USER_QUERY.getInfoByUsername, null);

  const privateUserProfile = useFetchQuery<UserModel | null, AccountModel>(USER_QUERY.getUserProfileInfo, null);

  const profileSocialMedia = useFetchQuery<ProfileSocialMedia, GetAllNetworkInput>(USER_QUERY.getAllNetworkUser, socialMediaDefault);

  const avatarUpdate = useMutateQuery<null, AvatarURL>(ACCOUNT_MUTATION.updateAvatar, null);

  const profileUpdate = useMutateQuery<null, AccountModel>(ACCOUNT_MUTATION.updateProfile, null);

  const coverBannerUpdate = useMutateQuery<null, CoverBannerInput>(ACCOUNT_MUTATION.updateCoverBanner, null);

  const userTournaments = useFetchQuery<UserTournaments, UserTournamentsInput>(TOURNAMENT_QUERY.getUserTournaments, userTournamentDefault);

  return {
    publicUserProfile,
    privateUserProfile,
    passwordResetByOTP,
    profileSocialMedia,
    avatarUpdate,
    coverBannerUpdate,
    userTournaments,

    profileUpdate,
  };
};
