import { GetChatInfoDataModel, useTranslate } from '@fe-monorepo/hooks';
import UserListItem from '@fe-web/Molecules/UserListItem';
import { Oval } from 'react-loader-spinner';

interface UserListProps {
    caption?: string;
    items?: GetChatInfoDataModel[];
    className?: string;
    onClickUser?: (user: GetChatInfoDataModel) => void;
    searchValue?: string;
    isLoading?: boolean;
}

const UserList = ({ caption, items, onClickUser, className, searchValue, isLoading }: UserListProps) => {
    const { translate } = useTranslate();
    return (
        <div className="mt-[1rem] pb-[0.5rem] px-[1rem]">
            {caption && <span className="font-regular text-fs-caption text-otherGray">{caption}</span>}
            <div className={`${className || ''} overflow-auto`}>
                {items && items?.length > 0 ? (
                    <ul className="">
                        {items?.map(item => (
                            <li className="py-[0.5rem]">
                                <button className="w-full" onClick={() => onClickUser && onClickUser(item)}>
                                    <UserListItem
                                        avatar={item?.avatar_url}
                                        username={item?.username}
                                        displayName={item?.display_name}
                                        searchValue={searchValue}
                                    />
                                </button>
                            </li>
                        ))}
                    </ul>
                ) : (
                    !isLoading && <p className="text-center font-regular text-fs-caption text-otherGray">{translate('validation_no_results_found')}</p>
                )}
                {isLoading && (
                    <div className={`w-full inline-flex justify-center`}>
                        <Oval
                            height={40}
                            width={40}
                            color="#E95F2A"
                            secondaryColor="#E95F2A"
                            visible={true}
                            ariaLabel="oval-loading"
                            strokeWidth={2}
                            strokeWidthSecondary={2}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default UserList;
