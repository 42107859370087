import { gql } from '@apollo/client';

export const PARTICIPANT_MUTATION = {
    leaveParticipant: gql`
        mutation tmsParticipantLeave($details: TmsParticipantLeaveInput) {
            tmsParticipantLeave(details: $details) {
                is_successful
                error_msg
                error_code
            }
        }
    `,

    joinTournament: gql`
        mutation joinTournament($details: JoinInput) {
            joinTournament(details: $details) {
                is_successful
                error_msg
                error_code
                app_code
            }
        }
    `,
};
