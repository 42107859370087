import { IconNames } from '@fe-monorepo/helper';
import { useGetFollowInfo, useUserProfile } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import RegularTabBar, { TabType } from '@fe-web/Molecules/TabBar/RegularTabBar';
import useMobileDetect from '@fe-web/hooks/useMobileDetect';
import mixpanelHelper from 'apps/fe-web/src/app/helpers/mixpanelHelper';
import { t } from 'i18next';
import { GetInfoDataModel } from 'libs/hooks/src/useGetFollowInfo/types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import FollowList from './FollowList';

type ProfileTab = 'following' | 'followers';

interface Props {
  handleClose: () => void;
  activeTab: string;
}

const FollowersFollowing = (props: Props) => {
  const displayName = useSelector((state: RootState) => state?.user.userContext.username);
  const totalFollowing = useSelector((state: RootState) => state?.user.userContext.total_following);
  const totalFollowers = useSelector((state: RootState) => state?.user.userContext.total_followers);
  const [value, setValue] = useState<string>();
  const isRTL = useSelector((state: RootState) => state?.app.isRTL);
  const { user } = useUserProfile();
  const { getFollowersData, getFollowingsData, lookup, getFollowers, getFollowings } = useGetFollowInfo();

  // Tab labels
  const profileTabs: TabType<ProfileTab>[] = [
    {
      id: 'following',
      label: `${t('common_following')} (${totalFollowing})`,
    },
    {
      id: 'followers',
      label: `${t('menu_followers')} (${totalFollowers})`,
    },
  ];

  const [activeTab, setActiveTab] = useState<ProfileTab>(profileTabs[0].id);
  const [followers, setFollowers] = useState<GetInfoDataModel[]>([]);
  const [following, setFollowing] = useState<GetInfoDataModel[]>([]);
  const [filteredFollowers, setFilteredFollowers] = useState<GetInfoDataModel[]>([]);
  const [filteredFollowing, setFilteredFollowing] = useState<GetInfoDataModel[]>([]);
  const [filteredSearch, setFilteredSearch] = useState<GetInfoDataModel[]>([]);
  const [searchLastCursor, setSearchLastCursor] = useState<string>('');
  const [followersLastCursor, setFollowersLastCursor] = useState<string>('');
  const [followingLastCursor, setFollowingLastCursor] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const isMobile = useMobileDetect();

  const valueCheck = value && value?.length > 2;

  // removes the user from following list or changes the status of the viewer_is_following_user if they are followers
  const followedStatus = (prevCursor: string) => {
    if (activeTab === 'following' && !valueCheck) {
      const newStatus = filteredFollowing.filter(data => data.last_cursor !== prevCursor);
      setFilteredFollowing(newStatus);
    } else if (activeTab === 'followers' && !valueCheck) {
      const newStatus = filteredFollowers.map(data =>
        data.last_cursor === prevCursor ? { ...data, viewer_is_following_user: data.viewer_is_following_user === '1' ? '0' : '1' } : data,
      );
      setFilteredFollowers(newStatus);
    } else {
      const newStatus = filteredSearch.map(data =>
        data.last_cursor === prevCursor ? { ...data, viewer_is_following_user: data.viewer_is_following_user === '1' ? '0' : '1' } : data,
      );
      setFilteredSearch(newStatus);
    }
  };

  // gets the following list
  const getFollowingsTemp = (initial?: boolean) =>
    getFollowings({
      username: user?.username as string,
      last_cursor: initial ? '' : followingLastCursor,
      direction: 'next',
    }).then(response => {
      if (!valueCheck) {
        if (response?.data?.length && response.data.length > 0) {
          setFollowingLastCursor(response?.data[response?.data.length - 1]?.last_cursor || '');
          setFollowing(getFollowingsData?.data || []);
          if (initial || followingLastCursor === '') {
            setFilteredFollowing(response.data);
          } else setFilteredFollowing(filteredFollowing => [...filteredFollowing, ...response.data]);
        }
      }
      setIsLoading(false);
    });

  // gets the followers list
  const getFollowersTemp = (initial?: boolean) =>
    getFollowers({
      username: user?.username as string,
      last_cursor: followersLastCursor,
      direction: 'next',
    }).then(response => {
      if (!valueCheck) {
        if (response?.data?.length && response.data.length > 0) {
          setFollowersLastCursor(response?.data[response?.data.length - 1]?.last_cursor || '');
          setFollowers(getFollowersData?.data || []);
          if (initial || followersLastCursor === '') {
            setFilteredFollowers(response.data);
          } else setFilteredFollowers(filteredFollowers => [...filteredFollowers, ...response.data]);
        }
      }
      setIsLoading(false);
    });

  // gets the result of the search bar
  const getSearchTemp = (initial?: boolean) =>
    lookup({
      username: user?.username as string,
      last_cursor: searchLastCursor,
      direction: 'next',
      identifier: value,
    }).then(res => {
      if (!valueCheck) {
        if (res?.data?.length && res.data.length > 0) {
          setSearchLastCursor(res?.data[res?.data.length - 1]?.last_cursor || '');
          setFollowers(getFollowersData?.data || []);
          if (initial) {
            setFilteredSearch(res.data);
          } else setFilteredSearch(filteredSearch => [...filteredSearch, ...res.data]);
        }
      }
      setIsLoading(false);
    });

  // resets the last cursor
  const callLookup = () => {
    setFollowingLastCursor('');
    setFollowersLastCursor('');
    lookup({
      username: user?.username as string,
      identifier: value,
    }).then(res => {
      setIsLoading(false);
      if (res?.data) {
        setSearchLastCursor(res?.data[res?.data.length - 1]?.last_cursor || '');
        setFilteredSearch(res?.data);
      }
    });
  };

  const toggleNewCall = (initial?: boolean) => {
    setIsLoading(true);
    if (activeTab === 'following') {
      getFollowingsTemp(initial);
    } else getFollowersTemp(initial);
  };

  const toggleLastCursor = () => {
    if (activeTab === 'following' && !valueCheck && followingLastCursor) {
      setIsLoading(true);
      getFollowingsTemp();
    } else if (activeTab === 'followers' && !valueCheck && followersLastCursor) {
      setIsLoading(true);
      getFollowersTemp();
    } else if (searchLastCursor) {
      setIsLoading(true);
      getSearchTemp();
    }
  };

  useEffect(() => {
    setActiveTab(props.activeTab === 'following' ? profileTabs[0].id : profileTabs[1].id);
  }, [props.activeTab]);

  useEffect(() => {
    mixpanelHelper.trackPageView(
      'PROFILE LANDING PAGE - FOLLOWING AND FOLLOWERS PAGE',
      'profile_following_followers',
      window.location.pathname,
      document.referrer,
    );
  }, []);

  useEffect(() => {
    toggleNewCall(true);
  }, [user?.username]);

  useEffect(() => {
    setIsLoading(true);
    setFilteredFollowers([]);
    setFilteredFollowing([]);
    setFollowersLastCursor('');
    setFollowingLastCursor('');
    toggleNewCall(true);
  }, [activeTab]);

  useEffect(() => {
    if (valueCheck) {
      callLookup();
      setIsLoading(true);
    } else if (value?.length === 0) {
      setFilteredSearch([]);
      setSearchLastCursor('');
      setFollowersLastCursor('');
      setFollowingLastCursor('');
      setFilteredFollowers([]);
      setFilteredFollowing([]);
      setTimeout(() => toggleNewCall(), 100);
    }
  }, [value]);

  return (
    <div
      className={`flex flex-col bg-primary z-[15]
        w-full SD:w-[33.3125rem] SD:h-[48.75rem] 4K:h-[83vh] p-[1.25rem] 
        gap-[2rem] border-[0.0625rem] border-secondary/25 ${!isMobile ? 'h-full rounded-[0.5rem] max-h-[calc(100vh-5rem)]' : 'h-[90vh]'}`}
    >
      <header className="flex flex-row justify-between">
        <h1 className="font-medium truncate text-secondary text-fs-title">{displayName}</h1>
        <ResponsiveIcon
          name={IconNames.close}
          baseWidth={15}
          baseHeight={15}
          className="z-20 flex items-center justify-end fill-secondary hover:cursor-pointer "
          onClick={props.handleClose}
        />
      </header>
      <div className="relative">
        <div
          className={`
                bg-secondary/10

                rounded-[4px] 4xl:rounded-[7.11px] 5xl:rounded-[10.66px] 8xl:rounded-[21.33px]
                flex items-center m-0

                py-8 4xl:py-[14px] 5xl:py-22 8xl:py-[42.66px]
                px-12 4xl:px-22 5xl:px-32 8xl:px-64
                gap-12 4xl:gap-22 5xl:gap-32 8xl:gap-64
                mix-blend-normal w-full`}
        >
          <div
            className="flex items-center w-full gap-12  4xl:gap-22 5xl:gap-32 8xl:gap-64 text-body 4xl:text-subtitle 5xl:text-bigTitle 8xl:text-LPTitle"
          >
            <ResponsiveIcon
              className={`
                        cursor-pointer relative fill-secondary/50 mix-blend-normal
                        px-2 4xl:px-[3.55px] 5xl:px-[5.33px] 8xl:px-[10.66px]`}
              name={IconNames.search1}
              baseWidth={20}
              baseHeight={20}
            />

            <input
              value={value}
              onChange={({ target }) => setValue(target?.value)}
              className="flex-1 w-full bg-transparent border-none rounded-none  focus:outline-none text-secondary mix-blend-normal font-regular"
              placeholder={t('action_search') || ''}
              // onClick={() => {
              //     setIsTooltipOn(true);
              // }}
            />
          </div>
        </div>
      </div>
      <div className="flex w-full overflow-hidden">
        <div className="flex flex-col w-full gap-[1.5rem]">
          {valueCheck ? (
            <div>
              <FollowList
                isFollowers
                list={filteredSearch}
                isRTL={isRTL}
                toggleNewCall={followedStatus}
                setLastCursor={toggleLastCursor}
                lastCursor={searchLastCursor}
                isLoading={isLoading}
                handleClose={() => props.handleClose()}
              />
            </div>
          ) : (
            <>
              <div>
                <RegularTabBar<ProfileTab>
                  info={{
                    tabs: profileTabs,
                    activeTab: activeTab,
                    selectTab: (index: ProfileTab) => setActiveTab(index),
                  }}
                />
              </div>
              {/* TAB CONTENT */}
              <div className="overflow-y-scroll">
                {activeTab === 'following' ? (
                  <FollowList
                    list={filteredFollowing}
                    isRTL={isRTL}
                    toggleNewCall={followedStatus}
                    setLastCursor={toggleLastCursor}
                    lastCursor={followingLastCursor}
                    isLoading={isLoading}
                    handleClose={() => props.handleClose()}
                  />
                ) : (
                  <FollowList
                    isFollowers
                    list={filteredFollowers}
                    isRTL={isRTL}
                    toggleNewCall={followedStatus}
                    setLastCursor={toggleLastCursor}
                    lastCursor={followersLastCursor}
                    isLoading={isLoading}
                    handleClose={() => props.handleClose()}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FollowersFollowing;
