import { IMAGES } from '@fe-monorepo/assets';
import { IMAGE_ALT_LABELS, IconNames, TxKeyPath, convertPriceWithSymbol } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import { InvoiceProduct, ShipmentsModel } from '@fe-monorepo/models';
import Icon from '@fe-web/Atoms/Icon/Icon';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import Image from '@fe-web/Atoms/Media/Image';
import QuantityField from '@fe-web/Atoms/QuantityField';
import CurrencyText from '@fe-web/Atoms/Text/CurrencyText';
import HoverText from '@fe-web/Atoms/Text/HoverText';
import Checkbox from '@fe-web/Molecules/InputFields/Checkbox';
import LoaderIconAnimated from '@fe-web/Molecules/LoaderIconAnimated';
import Modal from '@fe-web/Templates/Modal/Modal';
import { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from '../../../app.routes.enum';
import ProductDetails from '../../../pages/ShopPages/OrderPage/component/ProductDetails';
import SendGift from '../../../pages/ShopPages/OrderPage/component/SendGiftModal/SendGiftModal';

interface CaptionProps {
  qty: number;
  value: number;
}
const Caption = (props: CaptionProps) => {
  return (
    <>
      <span>{props.qty}</span>
      <span> x </span>
      <CurrencyText currency={props.value} hideSymbol={true} />
    </>
  );
};

interface LabelProps {
  text: string;
  icon?: IconNames;
  label: string;
  classNames?: {
    label?: string;
    text?: string;
    icon?: string;
  };
  onClick?: () => void;
  hasAnimation?: boolean;
}

const Label = ({ text, icon, label, classNames, onClick, hasAnimation }: LabelProps) => {
  return (
    <div className={`flex gap-x-8`}>
      {icon && (
        <ResponsiveIcon name={icon} baseWidth={18} baseHeight={18} iconClasses={`relative`} className={`${classNames?.icon ?? ''}`} />
      )}
      <p className={`${classNames?.label}`}>{label}</p>
      {hasAnimation ? (
        <HoverText
          className={`${classNames?.text}`}
          textClassName="line-clamp-1"
          hover={{ color: 'sunset' }}
          alwaysShowUnderline={false}
          underlineStyle="z-1 static bg-sunset h-[1px] 2K:h-[1.77px] 4K:h-[2.66px] 8K:h-[5.33px]"
          text={text}
          onClick={onClick}
        />
      ) : (
        <p className={`${classNames?.text}`} onClick={onClick}>
          {text}
        </p>
      )}
    </div>
  );
};

interface PriceQuantitySectionProps {
  showIsAvailable?: boolean;
  isAvailable?: boolean;
  totalPrice?: string;
  isRTL?: boolean;
  product: InvoiceProduct;
  incrementItem?: () => void;
  decrement?: (productCode: string, variantId: number) => void;
  showQuantity?: boolean;
  classNames?: string;
  isMobile?: boolean;
  caption?: ReactNode;
  showRightCaption?: boolean;
  hideTotalPrice?: boolean;
  removeToCart?: (productCode: string, variantId: number, qty: number) => void;
  unavailableProductCodes?: string[];
  showQuantityError?: boolean;
}

const PriceQuantitySection = (props: PriceQuantitySectionProps) => {
  const {
    showIsAvailable,
    isAvailable,
    showQuantity,
    isRTL,
    product,
    decrement,
    incrementItem,
    classNames,
    isMobile,
    caption,
    showRightCaption,
    hideTotalPrice,
    removeToCart,
    unavailableProductCodes,
    showQuantityError,
  } = props;
  const { translate } = useTranslate();

  const [isLoading, setIsLoading] = useState<boolean>();

  // * VAT is fixed for all products at 15%
  // ? In the future, this should be fetched dynamically from the API as no field currently returns it.
  const VAT_PERCENTAGE = 0.15;

  const totalPriceIncludingVAT = (product?.subtotal ?? 0) + (product?.total_tax ?? 0);

  let promoPriceIncludingVAT = 0;

  if (product?.promo_applied_price) {
    if (product.taxable) {
      promoPriceIncludingVAT = product.promo_applied_price + product.promo_applied_price * VAT_PERCENTAGE; // Apply VAT if taxable
    } else {
      promoPriceIncludingVAT = product.promo_applied_price; // No VAT if not taxable
    }
  }

  useEffect(() => {
    if (typeof product?.qty === 'number') {
      setIsLoading(false);
    }
  }, [product?.qty]);

  return (
    <div className={`${classNames} 8K:gap-y-[.5rem]`}>
      <div className="flex flex-col gap-y-4 8K:gap-y-[.5rem]">
        {showRightCaption && isMobile && product.is_digital !== 1 && product?.qty > 0 && isAvailable && caption}
        {!hideTotalPrice && (
          <div className="flex gap-[0.5rem]">
            <CurrencyText
              className={`
              text-fs-body-large font-medium text-secondary
              ${product.promo_applied_price ? 'line-through' : ''}
              ${isRTL && showQuantity ? 'text-left' : !isRTL && showQuantity ? 'text-right' : ''} ${
                showIsAvailable && !isAvailable && 'opacity-40'
              }`}
              currency={totalPriceIncludingVAT}
            />
            {product?.promo_applied_price && (
              <CurrencyText
                className={`
                  text-fs-body-large font-medium
                text-greenB
                  ${isRTL && showQuantity ? 'text-left' : !isRTL && showQuantity ? 'text-right' : ''} ${
                  showIsAvailable && !isAvailable && 'opacity-40'
                }`}
                currency={promoPriceIncludingVAT}
              />
            )}
          </div>
        )}
        {showRightCaption && !isMobile && product.is_digital !== 1 && product?.qty > 0 && isAvailable && caption}
        {!isMobile && !isAvailable && showRightCaption && (
          <p
            className="text-fs-body-small font-regular text-secondary text-sunset hover:underline hover:cursor-pointer"
            onClick={() => {
              if (removeToCart) {
                removeToCart(product.product_code, product.variant_id, product.qty);
              }
            }}
          >
            {translate('shop.cart.remove_from_cart')}
          </p>
        )}
      </div>
      {showQuantity && isAvailable && (
        <>
          {isLoading ? (
            <button disabled className="bg-secondary/5 p-[0.25rem] min-w-[5.75rem]">
              <LoaderIconAnimated size={{ width: 24, height: 24 }} variant="secondary" />
            </button>
          ) : (
            <>
              {product.is_digital !== 1 ? (
                <div className="self-end ltr:ml-[2rem] rtl:mr-[2rem] inline-flex flex-col SD:items-end gap-[0.5rem]">
                  <QuantityField
                    value={product?.qty || 1}
                    classNames={{
                      container: `gap-10 w-fit items-center`,
                      decrementButton: ``,
                      incrementButton: `h-[1.5rem] w-[1rem]`,
                    }}
                    decrementButton={
                      product?.qty === 1 ? (
                        <button
                          disabled={false}
                          onClick={() => {
                            if (decrement) {
                              decrement(product.product_code, product.variant_id);
                              setIsLoading(true);
                            }
                          }}
                        >
                          <Icon name={IconNames.cartDelete} iconClasses={`w-[1rem] h-[1rem]`} />
                        </button>
                      ) : (
                        <button
                          className={`text-secondary items-center justify-items-center self-center w-[1rem] h-[1.5rem] 8K:w-[1rem] 8K:h-[1.5rem]`}
                          disabled={false}
                          onClick={() => {
                            if (decrement) {
                              decrement(product.product_code, product.variant_id);
                              setIsLoading(true);
                            }
                          }}
                        >
                          -
                        </button>
                      )
                    }
                    increment={() => {
                      incrementItem?.();
                      setIsLoading(true);
                    }}
                    isIncrementDisabled={product?.qty === 5}
                  />
                  {showQuantityError && unavailableProductCodes?.includes(product.product_code) && (
                    <div className="text-red font-regular text-fs-caption">
                      <p>{translate('shop.productPage.quantity_error_message')}</p>
                    </div>
                  )}
                </div>
              ) : (
                <button onClick={() => decrement?.(product.product_code, product.variant_id)}>
                  <ResponsiveIcon
                    name={IconNames.cartDelete}
                    baseWidth={8}
                    baseHeight={8}
                    iconClasses="h-[1rem] w-[1rem]"
                    className="p-[1rem] ml-[2rem] rtl:ml-auto rtl:mr-[2rem] SD:mx-auto SD:p-[0.5rem] inline-block bg-secondary/[0.05]"
                  />
                </button>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

const ProductImageLayered = ({
  product,
  handleClickPDP,
  isImageClickable,
}: {
  product: InvoiceProduct;
  handleClickPDP: (action_from: 'image' | 'name') => void;
  isImageClickable: boolean | undefined;
}) => {
  const numLayers = product.qty;
  const numRender = numLayers > 2 ? 2 : numLayers > 1 ? 1 : 0;
  const multiplier = 10;
  const { translate } = useTranslate();
  return (
    <div className={`relative shrink-0 w-[6rem] h-[6rem] 8K:h-[5rem] 8K:w-[5rem]`}>
      <div className="pt-[100%]">
        <div
          className={`bg-primary 
                absolute
                top-0
                left-0
                z-[2]
                rounded-[3px]
                overflow-hidden
                ${
                  product?.qty > 2
                    ? 'w-[calc(100%-20px)] h-[calc(100%-20px)] drop-shadow-lg'
                    : product?.qty === 2
                    ? 'w-[calc(100%-10px)] h-[calc(100%-10px)] drop-shadow-lg'
                    : 'w-full h-full'
                }
                ${isImageClickable ? 'hover:cursor-pointer' : ''}`}
        >
          <Image
            img={product?.media?.length > 0 ? product?.media[0] : IMAGES.Product_Placeholder}
            alt={translate(IMAGE_ALT_LABELS.order_product_thumbnail as TxKeyPath) ?? ''}
            divStyle={`
                            bg-secondary/[0.06]  
                            py-[1rem] px-[1rem]
                            w-full
                            h-full
                        
                        `}
            imgStyle="w-full h-full object-contain"
            onClick={() => {
              handleClickPDP('image');
            }}
          />
        </div>
        {Array.from(Array(numRender))?.map((_, index) => {
          const subtractor = numRender * multiplier;
          const position = (index + 1) * multiplier;
          return (
            <div
              className={`absolute bg-primary z-[${1 - index}] rounded-[3px] ${
                index + 1 < numRender ? 'drop-shadow-lg' : ''
              } w-[calc(100%-${subtractor}px)] h-[calc(100%-${subtractor}px)]`}
              style={{ top: position, left: position }}
            >
              <div className="bg-secondary/[0.06] w-full h-full"></div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

type ProductsProps = {
  products: InvoiceProduct[];
  isCart?: boolean;
  language: 'en' | 'ar';
  showDigitalProductTag?: boolean;
  showCaption?: boolean;
  showSeller?: boolean;
  showDelivery?: boolean;
  showQuantity?: boolean;
  increment?: (productCode: string, variantId: number) => void;
  decrement?: (productCode: string, variantId: number) => void;
  removeToCart?: (productCode: string, variantId: number, qty: number) => void;
  hasCheckbox?: boolean;
  handleClickCheckbox?: (code: string, variantId: number) => void;
  selectedProducts?: string[];
  isRTL?: boolean;
  showIsAvailable?: boolean;
  isMobile?: boolean;
  isImageClickable?: boolean;
  isBrandNameClickable?: boolean;
  isNameClickable?: boolean;
  showRightCaption?: boolean;
  showSendAsGiftAndDetails?: boolean;
  digitalShipments?: ShipmentsModel[];
  checkIfHasNoAvailItem?: () => void;
  resend?: () => void;
  orderStatus?: string;
  hideTotalPrice?: boolean;
  showCaptionForUnavailableProduct?: boolean;
  unavailableProductCodes?: string[];
  showQuantityError?: boolean;
};

const Products = (props: ProductsProps) => {
  const {
    products,
    language,
    showDigitalProductTag,
    showCaption,
    showSeller,
    showDelivery,
    showQuantity,
    decrement,
    increment,
    removeToCart,
    hasCheckbox,
    handleClickCheckbox,
    selectedProducts,
    isRTL,
    showIsAvailable,
    isMobile,
    isImageClickable,
    isBrandNameClickable,
    isNameClickable,
    showRightCaption,
    showSendAsGiftAndDetails,
    digitalShipments,
    checkIfHasNoAvailItem,
    resend,
    orderStatus,
    hideTotalPrice,
    showCaptionForUnavailableProduct,
    unavailableProductCodes,
    showQuantityError,
  } = props;
  const { translate } = useTranslate();
  const navigate = useNavigate();
  const [isOpenModalDetails, setisOpenModalDetails] = useState(false);
  const [isOpenModalGift, setisOpenModalGift] = useState(false);
  const closeModal = () => {
    setisOpenModalDetails(false);
    setisOpenModalGift(false);
    setDigitalProductVoucherCode('');
    setDigitalProductPinCode('');
    setcurrentproduct(null);
    setcurrenttotalprice('');
  };
  const [currentproduct, setcurrentproduct] = useState<InvoiceProduct | null>(null);
  const [currenttotalprice, setcurrenttotalprice] = useState('');
  const [digitalProductVoucherCode, setDigitalProductVoucherCode] = useState<string>('');
  const [digitalProductPinCode, setDigitalProductPinCode] = useState<string>('');
  const selectedproduct = (product: InvoiceProduct, totalprice: string) => {
    if (digitalShipments && digitalShipments.length > 0) {
      const shipment = digitalShipments?.find(shipment => shipment.sku === product?.sku);
      if (shipment && shipment.products.length > 0 && shipment.products[0].serial_number) {
        setDigitalProductVoucherCode(shipment.products[0].serial_number);
      }
      if (shipment && shipment.products?.length > 0 && shipment.products[0]?.reference_serial_value) {
        setDigitalProductPinCode(shipment.products[0]?.reference_serial_value);
      }
    }
    setcurrentproduct(product);
    setcurrenttotalprice(totalprice);
    setisOpenModalDetails(true);
  };

  return (
    <div
      className={`flex flex-col ${isMobile ? 'border-b-[0.0625rem] border-black10 pb-[1.5rem]' : ''} ${
        !showSendAsGiftAndDetails ? 'divide-y gap-y-[1.5rem] 8K:gap-y-[2rem] 8K:divide-y-[.1rem] divide-secondary/10' : ''
      }`}
    >
      {products?.map(product => {
        const isMultiple = product?.media?.length > 1;
        const name = product['product_name'];
        const isDigital = product.is_digital === 0 ? false : true;
        // const { discount, selling_price, actual_cost } = getProductDiscountAndPrices(
        //     product?.lowest_selling_price,
        //     product?.lowest_actual_cost,
        // );
        // const caption = `${product.item_quantity} x ${translate('currency_SR')?.replace(
        //     '[balance]',
        //     '' + discount ? selling_price : actual_cost,
        // )}`;
        // const totalPrice = formatNumberWithCommasAndDecimal(
        //     (discount ? product?.lowest_selling_price : product?.lowest_actual_cost) * product.item_quantity,
        // );

        const captionValue = product.grand_total / product.qty; //product?.discount_price ? product?.unit_price - product?.discount_price / product.qty : product?.unit_price;

        // ${translate('currency_SR')?.replace(
        //         '[balance]',
        //         '' + (product?.discount_price ? formatNumberWithCommasAndDecimal(product?.unit_price - (product?.discount_price/product.qty)) : formatNumberWithCommasAndDecimal(product?.unit_price)),
        // )}`
        const totalPrice = '' + convertPriceWithSymbol(product.grand_total, 'commaDecimal');

        // formatNumberWithCommasAndDecimal(product.subtotal);
        const incrementItem = () => {
          if (increment) {
            increment(product.product_code, product.variant_id);
          }
        };
        const handleClickPDP = (action_from: 'image' | 'name') => {
          if ((action_from === 'image' && isImageClickable) || (action_from === 'name' && isNameClickable)) {
            navigate(`${AppRoutes.shop}/c-${product?.brand?.collection_code}/${product?.product_code}`);
          }
        };
        const handleClickPLP = () => {
          if (isBrandNameClickable) {
            navigate(`${AppRoutes.shop}/c-${product?.brand?.collection_code}`);
          }
        };
        const is_available = product?.in_stock > 0;
        if (!is_available) {
          checkIfHasNoAvailItem?.();
        }
        let existInShipments = false;
        let digitalProductShipment = null;
        if (product?.is_digital === 1) {
          const shipment = digitalShipments?.find(shipment => shipment.sku === product?.sku);
          if (shipment !== undefined) {
            existInShipments = true;
            digitalProductShipment = shipment;
          }
        }
        // const isAvailable = product?.product_code !== "1896";
        return (
          <div
            key={product.product_code}
            className={`flex flex-col gap-y-[1.25rem] ${
              showSendAsGiftAndDetails ? 'border-b-[0.0625rem] border-secondary/10 py-[1.5rem]' : ''
            }`}
          >
            <div
              className={`flex gap-x-12 items-center SD:gap-x-[1.5rem] 8K:gap-x-[1.5rem] ${
                !showSendAsGiftAndDetails ? 'pt-[1.5rem] 8K:pt-[2rem]' : ''
              }`}
            >
              {hasCheckbox && (
                <div>
                  <Checkbox
                    isChecked={selectedProducts?.includes(product.product_code + '-' + product.variant_id) && is_available}
                    classNames="w-[1.125rem] h-[1.125rem] accent-sunset"
                    disabled={showIsAvailable && !is_available}
                    onClick={() => {
                      if (handleClickCheckbox) {
                        handleClickCheckbox(product.product_code, product.variant_id);
                      }
                    }}
                    width={1.125}
                    height={1.125}
                  />
                </div>
              )}
              <ProductImageLayered product={product} handleClickPDP={handleClickPDP} isImageClickable={isImageClickable} />
              <div className="flex flex-col w-full gap-y-20 SD:flex-row">
                <div className="flex flex-col SD:flex-1 gap-y-10">
                  <p
                    className={`text-fs-body-small SD:text-fs-body-large ${
                      hasCheckbox ? `mdMaxxS:max-w-[12rem]` : `mdMaxxS:max-w-[15rem]`
                    } font-medium truncate text-secondary ${showIsAvailable && !is_available && 'opacity-40'} ${
                      isNameClickable ? 'hover:cursor-pointer' : ''
                    }`}
                    onClick={() => {
                      handleClickPDP('image');
                    }}
                  >
                    {name}
                  </p>
                  <div className="flex gap-x-8 items-center 8K:mt-[.5rem]">
                    {showCaption && (is_available || showCaptionForUnavailableProduct) && (
                      <p className="text-fs-caption font-regular text-secondary/[0.7]">
                        <Caption qty={product.qty} value={captionValue} />
                      </p>
                    )}
                    {(showSeller || showDelivery || showDigitalProductTag) && is_available && (
                      <div className={`flex ${isMobile ? 'grid grid-cols-1 gap-4' : 'gap-x-16'} items-center`}>
                        {showSeller && (
                          <Label
                            label={translate('shop.cart.cart_brand') ?? ''}
                            text={product?.brand[`name_${language}`]}
                            icon={IconNames.boxOutline}
                            classNames={{
                              text: 'text-sunset text-fs-caption font-regular',
                              label: 'text-fs-caption font-medium text-secondary',
                              icon: 'fill-secondary-100',
                            }}
                            onClick={handleClickPLP}
                            hasAnimation={true}
                          />
                        )}
                        {!isMobile && showSeller && showDelivery && <p className="text-fs-caption font-regular">•</p>}
                        {showDelivery && (
                          <Label
                            label={translate('tailored_section.delivery.delivery_header') ?? ''}
                            text={translate(`${product.is_digital !== 1 ? 'shop.cart.delivery_range' : 'shop.cart.immediately'}`) ?? ''}
                            icon={IconNames.deliveryTruck}
                            classNames={{
                              text: 'text-secondary text-fs-caption font-regular',
                              label: 'text-fs-caption font-medium text-secondary',
                              icon: 'fill-secondary-100',
                            }}
                          />
                        )}
                        {showDigitalProductTag && isDigital && is_available && (
                          <div className="flex gap-x-8">
                            <p className="text-fs-caption font-regular rounded-[2px] border border-moonlight/[0.4] bg-moonlight/[0.08] text-moonlight py-2 px-8">
                              {translate('shop.cart.digital')}
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                    {showIsAvailable && !is_available && (
                      <div className={`flex grid grid-cols-1 gap-4 items-center`}>
                        <div className={`flex ${!isMobile && 'gap-x-8'}`}>
                          <p className={`text-fs-body-small font-medium text-secondary`}>{translate('shop.cart.no_available_message')}</p>
                        </div>
                        <div className={`flex ${!isMobile && 'gap-x-8'}`}>
                          <p className={`text-fs-caption font-medium text-zinc-600-400`}>
                            {translate('shop.productPage.outOfStock.changeStoreLoc')}
                          </p>
                        </div>
                        {isMobile && (
                          <div className="flex">
                            <p
                              className={`text-fs-body-small font-regular text-secondary text-sunset hover:underline hover:cursor-pointer`}
                              onClick={() => {
                                if (removeToCart) {
                                  removeToCart(product.product_code, product.variant_id, product.qty);
                                }
                              }}
                            >
                              {translate('shop.cart.remove_from_cart')}
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className={`${showQuantity ? 'hidden SD:block' : ''}`}>
                  <PriceQuantitySection
                    product={product}
                    showIsAvailable={showIsAvailable}
                    isAvailable={is_available}
                    totalPrice={totalPrice}
                    isRTL={isRTL}
                    incrementItem={incrementItem}
                    decrement={decrement}
                    showQuantity={showQuantity}
                    classNames="flex-1 flex flex-col gap-y-16 items-end"
                    isMobile={isMobile}
                    caption={
                      <p
                        className={`text-fs-caption font-regular text-secondary/[0.7] ${
                          isRTL && showQuantity ? 'text-left' : !isRTL && showQuantity ? 'text-right' : ''
                        }`}
                      >
                        <Caption qty={product.qty} value={captionValue} />
                      </p>
                    }
                    showRightCaption={showRightCaption}
                    hideTotalPrice={hideTotalPrice}
                    unavailableProductCodes={unavailableProductCodes}
                    showQuantityError={showQuantityError}
                    removeToCart={removeToCart}
                  />
                </div>
              </div>
            </div>
            {isDigital && showSendAsGiftAndDetails ? (
              <div className="flex flex-col SD:flex-row gap-[1.25rem] border-none">
                {existInShipments && digitalProductShipment && orderStatus === 'complete' && (
                  <div className="flex items-center gap-[.5rem] hover:cursor-pointer">
                    <p>
                      <span className="pr-2 text-fs-body-small font-regular text-secondary">{`${translate(
                        'shop.cart.order_details_page.sent_to',
                      )}:`}</span>
                      <span className="font-medium text-fs-body-small text-secondary">{digitalProductShipment?.sent_to}</span>
                    </p>
                  </div>
                )}
                <div className="flex gap-[1.25rem]">
                  {existInShipments && digitalProductShipment && orderStatus === 'complete' && (
                    <div className="flex flex-row gap-[0.5rem] items-center">
                      <ResponsiveIcon name={IconNames.iconRedeem} baseWidth={20} baseHeight={20} className="fill-sunset" />
                      <p
                        className="text-fs-caption text-sunset"
                        onClick={() => {
                          if (resend) {
                            resend();
                          }
                        }}
                      >
                        {translate('email_resend')}
                      </p>
                    </div>
                  )}
                  <div className="flex items-center gap-[.5rem] hover:cursor-pointer" onClick={() => selectedproduct(product, totalPrice)}>
                    <ResponsiveIcon name={IconNames.iconExpandCircleRight} baseWidth={20} baseHeight={20} className="fill-sunset" />
                    <p className="text-fs-caption text-sunset">{translate('shop.cart.cart_items_type.details')}</p>
                  </div>
                </div>
              </div>
            ) : null}
            {showQuantity && (
              <div className="block SD:hidden">
                <PriceQuantitySection
                  product={product}
                  showIsAvailable={showIsAvailable}
                  isAvailable={is_available}
                  totalPrice={totalPrice}
                  isRTL={isRTL}
                  incrementItem={incrementItem}
                  decrement={decrement}
                  showQuantity={showQuantity}
                  classNames="flex-1 flex gap-y-16 items-end justify-between flex-row-reverse"
                  isMobile={isMobile}
                  caption={
                    <p className={`text-fs-caption font-regular text-secondary/[0.7] ${isRTL ? 'text-left' : 'text-right'}`}>
                      <Caption qty={product.qty} value={captionValue} />
                    </p>
                  }
                  showRightCaption={showRightCaption}
                  hideTotalPrice={hideTotalPrice}
                  unavailableProductCodes={unavailableProductCodes}
                  showQuantityError={showQuantityError}
                  removeToCart={removeToCart}
                />
              </div>
            )}
          </div>
        );
      })}
      <Modal open={isOpenModalDetails}>
        <ProductDetails
          voucher_code={digitalProductVoucherCode}
          pin_code={digitalProductPinCode}
          validity={new Date('08/18/2023')}
          product_name={currentproduct?.product_name ?? translate('form.placeholder.product_name')!}
          price={currenttotalprice}
          media={
            currentproduct?.media && currentproduct?.media?.length > 0 ? currentproduct?.media[0] : (IMAGES.Product_Placeholder as string)
          }
          closeModal={closeModal}
          isRTL={isRTL}
        />
      </Modal>
      <Modal open={isOpenModalGift}>
        <SendGift
          closeModal={closeModal}
          modalHeader={`${translate(`shop.cart.cart_items_type.send_as_a_gift`)}`}
          buttonLeft={`${translate(`action.cancel`)}`}
          buttonRight={`${translate(`action.save`)}`}
          buttonLast={`${translate(`common_ok`)}`}
          fullName={`${translate(`shop.cart.shop_add_address_modal_page.placeholder_full_name`)}`}
          email={`${translate(`shop.cart.order_main.email`)}`}
          mobileNumber={`${translate(`form.attribute.mobile`)}`}
          comments={`${translate(`shop.cart.order_details_page.additional_information_optional`)}`}
          maxCharLimit={250}
          voucher={`${translate(`shop.cart.order_details_page.voucher`)}`}
        />
      </Modal>
    </div>
  );
};

export default Products;
