import { Genders, getUser } from '@braze/web-sdk';
import { FormData, genderField, useForm } from '@fe-monorepo/forms';
import { IconNames, PAGE_DESCRIPTION, PAGE_KEYWORDS, PAGE_TITLE, TxKeyPath, genderMapping } from '@fe-monorepo/helper';
import { useAccountNew, useTranslate } from '@fe-monorepo/hooks';
import { UserModel } from '@fe-monorepo/models';
import { RootState, updateUser, useAppDispatch, useAppSelector } from '@fe-monorepo/store';
import PrimaryButton from '@fe-web/Atoms/Buttons/PrimaryButton';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import { toastWrapper } from '@fe-web/Atoms/Toast';
import OptionText from '@fe-web/Molecules/InputFields/Radio/Options/OptionText';
import RadioButtons from '@fe-web/Molecules/InputFields/Radio/RadioGroup';
import LoaderIconAnimated from '@fe-web/Molecules/LoaderIconAnimated';
import CustomHelmet from '@fe-web/Organisms/CustomHelmet/CustomHelmet';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import { motion } from 'framer-motion';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// genderField

const GenderSettings = () => {
  const navigate = useNavigate();
  const { translate } = useTranslate();

  const { profileUpdate } = useAccountNew();

  const { form, isFormValid, initializeForm, editForm } = useForm();

  const userState = useAppSelector((state: RootState) => state.user);
  const isLoading = useSelector((state: RootState) => state.app.isLoading);

  const user = useMemo(() => {
    return userState;
  }, [userState]);
  const dispatch = useAppDispatch();

  const handleSubmit = (form: FormData) => {
    profileUpdate.mutate({
      gender_code: form.gender_code.value,
    });
  };

  useEffect(() => {
    if (profileUpdate.isValid) {
      dispatch(
        updateUser({
          gender_code: form.gender_code.value,
        } as UserModel),
      );

      getUser()?.setGender(genderMapping[form.gender_code.value] as Genders);

      toastWrapper('success', translate('profile.update_profile_success') as string);

      profileUpdate.reset();
    } else if (profileUpdate.error?.message) {
      toastWrapper('error', profileUpdate.error?.message);

      profileUpdate.reset();
    }
  }, [profileUpdate.isValid, profileUpdate.error?.message]);

  useEffect(() => {
    mixpanelHelper.trackPageView('SETTINGS - GENDER PAGE', 'gender_page', window.location.pathname, document.referrer);
  }, []);

  return (
    <>
      <CustomHelmet
        pageTitle={`${translate(PAGE_TITLE.settings as TxKeyPath) ?? ''} - ${translate(PAGE_TITLE.settings_account as TxKeyPath) ?? ''} - ${
          translate(PAGE_TITLE.settings_gender as TxKeyPath) ?? ''
        }`}
        metaDescription={translate(PAGE_DESCRIPTION.settings as TxKeyPath) ?? ''}
        metaKeyWords={translate(PAGE_KEYWORDS.settings as TxKeyPath) ?? ''}
      />
      <div className="flex flex-col h-full w-full gap-24 2K:gap-[42.66px] 4K:gap-64 8K:gap-[128px]">
        <div className="flex items-center gap-12 SD:gap-0">
          <ResponsiveIcon
            className="fill-sunset ltr:rotate-180 SD:hidden"
            name={IconNames.arrow}
            baseHeight={24}
            baseWidth={24}
            onClick={() => navigate(-1)}
          />

          <h1 className="font-medium text-bodyLarge XGA:responsive-text-subtitle text-secondary">{translate(genderField.label)}</h1>
        </div>
        {isLoading ? (
          <LoaderIconAnimated variant="secondary" />
        ) : (
          <motion.div
            animate={{
              opacity: [0, 1],
              transition: { duration: 1 },
            }}
            className="flex flex-col gap-[1.5rem] SD:gap-0"
          >
            <RadioButtons<string>
              className="h-fit"
              showDivider={true}
              initialSelectionID={user.userContext.gender_code ?? 'none'}
              initializeForm={initializeForm}
              fieldDefinition={genderField}
              OptionComponent={OptionText}
              onChange={editForm}
            />

            <PrimaryButton
              className="self-end w-full XGA:w-fit
              py-8 2K:py-[14.22px] 4K:py-[21.33px] 8K:py-[42.66px]
              px-24 2K:px-[42.66px] 4K:px-64 8K:px-[128px]
              rounded-[2px] 2K:rounded-[3.55px] 4K:rounded-[5.33px] 8K:rounded-[10.66px]"
              text={translate('profile.save_changes') as string}
              action={() => handleSubmit(form)}
              isDisabled={!isFormValid}
            />
          </motion.div>
        )}
      </div>
    </>
  );
};

export default GenderSettings;
