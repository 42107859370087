import { emailFieldSignup, useForm } from "@fe-monorepo/forms";
import { IconNames } from "@fe-monorepo/helper";
import { useAuth, useOtp, useTranslate, useUser } from "@fe-monorepo/hooks";
import { CONTINUE_AS_GUEST_PHASES } from "@fe-monorepo/models";
import Button from "@fe-web/Atoms/Buttons/Button";
import ResponsiveIcon from "@fe-web/Atoms/Icon/ResponsiveIcon";
import GenericInputField from "@fe-web/Molecules/InputFields/GenericInputField";
import { authInputFieldStyleObj } from "../../../AuthenticationScreen/commonStyles";
import { useEffect, useState } from "react";
import { APIErrorType } from "@fe-web/hooks/useAPIError";
import OTPPhase from "../../../AuthenticationScreen/OTPPhase";
import { FormData } from '@fe-monorepo/forms';
import { useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "apps/fe-web/src/app/app.routes.enum";

interface ContinueAsGuestProps {
    isHeaderDark?: boolean;
    dir: string;
    handleClose: () => void;
    processCreateInvoiceForGuest: () => void;
}

const ContinueAsGuest = (props: ContinueAsGuestProps) => {
    const {
        isHeaderDark,
        dir,
        handleClose,
        processCreateInvoiceForGuest,
    } = props;
    const { translate } = useTranslate();
    const {form, isFormValid, editForm} = useForm();
    const navigate = useNavigate();
    const { verifyOTP } = useOtp();
    const [ apiError, setAPIError] = useState<APIErrorType>({email: '', otp: ''});
    const [ otpAttempt, setOtpAttempt] = useState(0);
    const [ guestUserEmail, setGuestUserEmail] = useState('');
    const { validateUserData, errorMessage, isValidUser} = useUser();
    const { registerAsGuestUser } = useAuth();
    const [ registeredGuestToken, setRegisteredGuestToken] = useState('');
    const [ currentPhase, setContinueAsGuestPhase] = useState<string>(CONTINUE_AS_GUEST_PHASES.EMAIL);
    const [ otp, setOtp] = useState('');
    const [isEmailValidationError, setIsEmailValidationError] = useState<boolean>();
    const { pathname } = useLocation();

    const handleChangeOtp = (otp: string) => {
        if (otp.length === 6) {
            verifyOTP({
                type: 'guest',
                otp: otp
            }).then(response => {
                if (!response.data?.verifyOTP?.is_successful && response?.data?.verifyOTP?.error_msg) {
                    setAPIError({
                        ...apiError,
                        otp: response.data.verifyOTP.error_msg
                    })
                    let temp_otp_try = otpAttempt + 1;
                    setOtpAttempt(temp_otp_try);
                } else if(response.data?.verifyOTP?.is_successful) {
                    processCreateInvoiceForGuest();
                }
            })
        }
        setOtp(otp);
    }

    const handleVerifyOtp = (form: FormData) => {}

    const handleAction = (action: string) => {
        if (action === "email") {
            validateUserData({
                email: guestUserEmail
            },"");
        } else {

        }
    }

    const resetOtpAttempt = (count: number) => {
        setOtpAttempt(count);
        setAPIError({
            ...apiError,
            otp: ""
        })
    }

    const handlePopupClose = () => {
        setOtp('');
        setGuestUserEmail(''),
        setAPIError({
            email: '',
            otp: ''
        });
        setOtpAttempt(0);
        handleClose();
    }


    useEffect(() => {
        if (isValidUser === false) {
            registerAsGuestUser({
                details: {
                    email: guestUserEmail,
                    endpoint: 'web'
                }
            }).then(response => {
                if (response?.is_successful && response.data.token) {
                    setRegisteredGuestToken(response.data.token);
                    setContinueAsGuestPhase(CONTINUE_AS_GUEST_PHASES.OTP);
                } else if(response?.error_msg) {
                    setAPIError({
                        ...apiError,
                        email: response.error_msg
                    })
                    setIsEmailValidationError(true);
                }
            })
        } else if (errorMessage) {
            setAPIError({
                ...apiError,
                email: errorMessage,
            })
        } else if (isValidUser) {
            setAPIError({
                ...apiError,
                email: "Email address already exists, please sign in to continue.",
            })
            setIsEmailValidationError(true);
        }
    }, [isValidUser, errorMessage]);
    
    useEffect(() => {
        if (form?.email){
            setGuestUserEmail(form.email.value);
            setAPIError({
                ...apiError,
                email: ''
            })
            if(isEmailValidationError){
                setIsEmailValidationError(false);
            }
        }
    }, [form?.email])

    return(
        <div className={`
                flex flex-col mt-[20px] justify-start items-center bg-white
                rounded-lg p-[25px] SD:p-[48px] 2K:p-[80px] 4K:p-[96px] 8K:p-[196px] gap-[20px] max-h-[100vh] overflow-y-auto
                fixed top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] w-[370px] SD:w-[448px]
                2K:w-[796px] 4K:w-[1146px] 8K:w-[2296px]
            `}
        >
            <div className={'w-full'}>
                <ResponsiveIcon
                  className="absolute right-[37px] SD:right-[52px] 4K:right-[76px] 4K:right-[94px] 8K:right-[172px] justify-items-end"
                  name={IconNames.close_xbutton}
                  baseHeight={12}
                  baseWidth={12}
                  onClick={handlePopupClose}
                />
            </div>
            <div className="w-full pt-[40px] 2K:pt-[1.5rem] 4K:pt-[2.5rem] 8K:pt-[3.5rem]">
                <p className={`text-fs-big-title font-medium text-secondary`}>
                    {translate(currentPhase === CONTINUE_AS_GUEST_PHASES.EMAIL ? "shop.cart.continue_as_guest.email_title": "shop.cart.continue_as_guest.otp_title")}
                </p>
            </div>
            {currentPhase === CONTINUE_AS_GUEST_PHASES.EMAIL &&
                <div className="w-full flex flex-col items-start gap-y-[1.5rem]">
                    <div className="">
                        <p className={`text-fs-body font-regular text-secondary`}>
                            {translate("common_have_an_account")}
                            <span 
                                className={`text-fs-body font-regular text-sunset hover:underline pl-4 hover:cursor-pointer`}
                                onClick={() => navigate(`${AppRoutes.authSignIn}${pathname ? `?redirect_url=${pathname}` : ''}`)}
                            >
                                {translate("common_signin")}
                            </span>
                        </p>
                        <p className={`text-fs-body font-regular text-secondary pt-[1.5rem]`}>
                            {translate("shop.cart.continue_as_guest.email_body")}
                        </p>
                    </div>
                    <div className="w-full">
                        <GenericInputField
                            key={emailFieldSignup.key}
                            style={authInputFieldStyleObj}
                            inputFieldInfo={emailFieldSignup}
                            onChange={editForm}
                            apiError={apiError}
                        />
                    </div>
                    <div className="w-full">
                        <Button
                            text={translate('shop.cart.continue_as_guest.button_label') || ""}
                            fullWidth={true}
                            style={`
                                text-fs-body font-medium mt-8 2K:py-[0.675rem] 2K:mt-[0.775rem] 8K:mt-[1rem]
                                py-[8px]
                                w-full gap-[8px] z-1 
                                text-white100 border-[1px] border-transparent mb-[0px] 
                                xsMax:mb-[0px] items-end
                                rounded-sm ${
                                    (!isFormValid || isEmailValidationError) ?
                                        `disabled:bg-secondary/[0.1]`
                                        :
                                        isHeaderDark
                                            ? dir === 'ltr'
                                                ? `after:bg-sunset before:bg-white100 hover:border-[1px] hover:border-sunset hover:text-sunsetText`
                                                : `before:bg-sunset after:bg-white100 hover:border-[1px] hover:border-sunset hover:text-sunsetText`
                                            : `${
                                                    dir === 'ltr'
                                                        ? `after:bg-sunset before:bg-white100`
                                                        : `before:bg-sunset after:bg-white100`
                                                }
                                            hover:border-[1px] hover:border-sunset hover:text-sunsetText`
                                }
                                rounded-sm`}
                            disabled={!isFormValid || isEmailValidationError}
                            onClick={() => { handleAction('email')}}
                        />
                    </div>
                </div>
            }
            {currentPhase === CONTINUE_AS_GUEST_PHASES.OTP &&
                <div className="w-full flex flex-col items-start gap-y-[1.5rem]">
                    <div className="w-full">
                        <p className={`text-fs-body font-regular text-secondary pt-[1.5rem]`}>
                            {translate('message.otp_sent')}
                        </p>
                        <span className="font-medium pl-4">
                            {guestUserEmail}
                        </span>
                        <span className="text-sunset hover:underline hover:cursor-pointer pl-4" onClick={() => setContinueAsGuestPhase(CONTINUE_AS_GUEST_PHASES.EMAIL)}>
                            {translate('shop.cart.continue_as_guest.edit_email')}
                        </span>
                    </div>
                    <OTPPhase
                        dir={dir}
                        otpType={"guest"}
                        APIError={apiError}
                        setOtp={handleChangeOtp}
                        goNext={handleVerifyOtp}
                        resetAPIError={(key: string) => {
                            setAPIError({
                                ...apiError,
                                otp: ''
                            })
                        }}
                        requestOTPPayload={{type: 'guest', email: guestUserEmail}}
                        hideNoEmailSection={true}
                        resendCodeCls="text-fs-body-large text-regular"
                        attempt={otpAttempt}
                        resetAttempt={resetOtpAttempt}
                    />
                </div>
            }
        </div>
    )
}

export default ContinueAsGuest;