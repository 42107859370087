import Bottom from '../components/Layouts/Bottom';
import LeaderBoard from '../components/LeaderBoard/LeaderBoard';

const LeaderboardDetail = () => {
  return (
    <>
      <div className="full-sec">
        <div className="px-20 md:px-64 lg:px-120 2K:px-160 4K:px-320 8K:px-655">
          <LeaderBoard />
        </div>
      </div>
      <Bottom />
    </>
  );
};

export default LeaderboardDetail;
