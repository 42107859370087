import { BIT_QUERY } from '@fe-monorepo/data-access'

import { useFetchQuery } from "../useFetchQuery";

import {  BitCategory, BitData } from '@fe-monorepo/models'

type AllBitsModel =
{
  timestamp?: string,
  last_cursor?: string,
  categories?: string[],
  direction?: "next" | "previous"
}

export const useBits = () =>
{
  const genres = useFetchQuery<BitCategory[]>(BIT_QUERY.getBitCategories, []);
  const allBits = useFetchQuery<BitData[], AllBitsModel>(BIT_QUERY.getAllBits, []);
  
  return { allBits, genres }
}
