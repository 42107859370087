import { useTranslate } from '@fe-monorepo/hooks';
import React, { useState } from 'react';

interface EcommerceRecipientProps {
  sellerName?: string;
  sellerEmail?: string;
  paymentMethod?: any;
  shippingName?: string;
  shippingAddress?: string;
  shippingNumber?: string;
  shippingPostalCode?: string;
  shippingCountryCode?: string;
  shippingCity?: string;
  onResendEmail: () => void;
  hasDigitalProducts?: boolean;
  orderStatus: string;
}

const RecipientsDetails: React.FC<EcommerceRecipientProps> = ({
  sellerName,
  sellerEmail,
  paymentMethod,
  shippingName,
  shippingAddress,
  shippingNumber,
  shippingCity,
  shippingCountryCode,
  shippingPostalCode,
  onResendEmail,
  hasDigitalProducts,
  orderStatus
}) => {
  const [emailResent, setEmailResent] = useState(false);
  const { translate } = useTranslate();
  const handleResendEmail = () => {
    //setEmailResent(true);
    onResendEmail();
  };

  return (
    <>
      <div className="text-black dark:text-white font-medium text-fs-subtitle text-medium mt-[2rem] mb-[1.25rem]">
        <p>{translate('shop.cart.order_main.recipeint')}</p>
      </div>
      <div className="flex-row justify-start mb-[1rem] gap-4 md:flex md:space-x-6 md:gap-[24px]">
        <div>
          <h2 className="text-secondary/50 text-fs-caption not-italic font-regular leading-4">{translate('common_name')}</h2>
          <p className="text-sunsetText font-medium">{sellerName}</p>
        </div>
        <div className="md:block hidden bg-secondary/10 w-[1px] h-[48px]"></div>
        <div>
          <h2 className="text-secondary/50 text-fs-caption not-italic font-regular leading-4">
            {hasDigitalProducts
              ? translate('shop.cart.order_main.digi_delivery_sent_to')
              : translate('shop.cart.order_main.invoice_sent_to')}
          </h2>
          <p className="text-secondary font-medium">{sellerEmail}</p>
        </div>
        { orderStatus === 'complete' && sellerEmail && hasDigitalProducts ?
          <div className={`${!sellerEmail || !hasDigitalProducts ? 'hidden' : ''}`}>
            {emailResent ? (
              <p className="text-sunsetText">{translate('email_sent')}</p>
            ) : (
              <button className="text-sunsetText font-medium mt-[1rem]" onClick={handleResendEmail}>
                {translate('shop.cart.order_main.resend')}
              </button>
            )}
          
          </div> : null
        }
        <div className="md:block hidden bg-secondary/10 w-[1px] h-[48px]"></div>
        <div className="payment-method">
          <h2 className="text-secondary/50 text-fs-caption not-italic font-regular leading-4">
            {translate('shop.cart.payment_method_title')}
          </h2>
          <p className="text-secondary font-medium">{paymentMethod}</p>
        </div>
      </div>
      <div className="justify-start flex-col">
        <h2 className="text-secondary/50 text-fs-caption not-italic font-regular leading-4">
          {translate('shop.cart.order_main.shipping')}
        </h2>
        <div className="flex-row justify-start gap-4 md:flex md:space-x-6 md:gap-[24px]">
          <p className={`text-secondary font-medium ${!shippingName ? 'hidden' : ''}`}>{shippingName} &nbsp; &bull; </p>
          <p className={`text-secondary font-medium ${!shippingNumber ? 'hidden' : ''}`}>{shippingNumber} &nbsp; &bull; </p>
          <p className={`text-secondary font-medium ${!shippingAddress ? 'hidden' : ''}`}>
            {shippingAddress} - {shippingCity}
          </p>
        </div>
      </div>
    </>
  );
};

export default RecipientsDetails;
