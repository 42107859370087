import { IMAGES } from '@fe-monorepo/assets';
import { useTranslate } from '@fe-monorepo/hooks';
import usePageLayout from '@fe-web/hooks/usePageLayout';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface FooterContentProps {
  setStatus: (value: boolean) => void;
}

const FooterGreet = ({ setStatus }: FooterContentProps) => {
  const { translate } = useTranslate();
  const handleClose = () => {
    setStatus(false);
  };
  const languages = usePageLayout();
  const isEnglish = languages?.language === 'en';

  return (
    <div className="fixed inset-0 flex items-center justify-center z-[9999]">
      <div className="modal-overlay fixed inset-0 bg-black opacity-30"></div>
      {/* w-11/12 md:max-w-md mx-auto*/}
      <div className="modal-container bg-moonlight rounded shadow-lg z-50 relative mdMaxxSD:mx-[1rem]">
        <div className="modal-content py-[1rem] text-left px-[2.25rem] pb-[4rem]">
          <div className="flex justify-end items-center pb-[2rem]">
            <div onClick={handleClose} className="modal-close cursor-pointer">
              <svg className="w-[1.5rem] h-[1.5rem]" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                  fill="#171619"
                />
              </svg>
            </div>
          </div>
          <div className="flex justify-center items-center w-full">
            <div>
              <LazyLoadImage src={IMAGES.FooterGreet.toString()} alt="Need to Sign In" className="w-[16.25rem] h-[4.783rem]" />
            </div>
          </div>
          <h1 className="mt-[2rem] text-fs-big-title not-italic font-medium leading-[3rem] tracking-[-0.075rem] text-center mb-[1.5rem]">
            {translate('greet_subscribe')}
          </h1>
          <p className="text-center not-italic font-regular leading-6 text-static-primary">{translate('greet_one')}</p>
          <p className="text-center not-italic font-regular leading-6 text-static-primary">{translate('greet_two')}</p>
        </div>
      </div>
    </div>
  );
};

export default FooterGreet;
