export enum TOURNAMENT_STATUS {
  PENDING = 'pending',
  ACCEPTING_REGISTRATION = 'accepting_registration',
  READY_TO_START = 'ready_to_start',
  STARTED = 'started',
  ENDED = 'ended',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  RUNNING = 'running',
}

export enum RoundStatus {
  happeningNow = 'happeningNow',
  completed = 'completed',
  inTheMaking = 'inTheMaking',
}
