import mixpanel, { OverridedMixpanel } from 'mixpanel-browser';

const mixpanelToken = process.env.NX_MIXPANEL_TOKEN || '';
export let mixpanelInstance: OverridedMixpanel;

const isDebug = process.env.NX_APP_ENVIRONMENT === 'development' || process.env.NX_APP_ENVIRONMENT === 'localhost';

if (mixpanelToken) {
  mixpanel.init(mixpanelToken, { debug: isDebug, persistence: 'localStorage' });
  mixpanelInstance = mixpanel;
}

const mixpanelHelper = {
  trackPageView: (pageName: any, pageId: any, urlPath: any, referrer: any) => {
    mixpanel.track('Page View', {
      'Page Name': pageName,
      'Page ID': pageId,
      'URL Path': urlPath,
      Referrer: referrer,
    });
  },

  trackEvent: (eventName: string, data: any) => {
    mixpanel.track(eventName, data);
  },

  setIdentity: (userId: string) => {
    mixpanel.identify(userId);
  },

  setUserProperties: (properties: any) => {
    mixpanel.people.set(properties);
  },

  reset: () => {
    mixpanel.reset();
  },
};

export default mixpanelHelper;
