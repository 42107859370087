import { useSelector } from 'react-redux';
import { useState } from 'react';
import { RootState } from '@fe-monorepo/store';
import { CSSProperties, MutableRefObject, forwardRef } from 'react';
import Spinner from '../Load/Spinner';
import { twMerge } from 'tailwind-merge';

interface ButtonProps {
    ref?: MutableRefObject<HTMLButtonElement | null>;
    style?: string;
    normalStyle?: string;
    text: string | null;
    disabled?: boolean;
    action?: Function;
    fullWidth?: boolean;
    isLoading?: boolean;
    onClick?: () => void | null | undefined;
    className?: string;
    iconColor?: string;
    iconColorHover?: string;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    const [currentColor, setCurrentColor] = useState<string>(props.iconColor || 'fill-[#FFFFFF]');
    const prefs = useSelector((state: RootState) => state?.app);
    const { isLoading, onClick = null } = props;
    const dir = prefs?.language === 'en' ? `ltr` : `rtl`;

    const action = () => {
        if (props.action) {
            props?.action();
            alert('hello');
        }
    };

    const widthStyle: CSSProperties = { width: props.fullWidth ? '100%' : undefined };
    const cssClassName = twMerge(
        `
    ${props?.style} ${props?.disabled ? `cursor-not-allowed bg-black10 text-black30` : props?.normalStyle} button-animation-${dir}
  `,
        props.className,
    );

    return (
        <button
            type="submit"
            onClick={() => (onClick ? onClick() : action())}
            ref={ref}
            style={widthStyle}
            className={cssClassName}
            disabled={props?.disabled || isLoading}
            onMouseEnter={() => setCurrentColor(props.iconColorHover || 'fill-[#E95F2A]')}
            onMouseLeave={() => setCurrentColor(props.iconColor || 'fill-[#FFFFFF]')}
        >
            {isLoading && <Spinner />}
            <div className="flex gap-[0.5rem] justify-center items-center">
                <span className="text-fs-body">{!isLoading && props?.text} </span>
                <svg
                    className={`2K:h-[42px] 2K:w-[42px] 4K:h-[64px] 4K:w-[64px] 8K:h-[127px] 8K:w-[127px] ${currentColor}`}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    transform={`${dir === 'rtl' ? 'rotate(180)' : ''}`}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z" fill="" />
                </svg>
            </div>
        </button>
    );
});

export default Button;
