import { IMAGES } from '@fe-monorepo/assets';
import { RootState, useAppSelector } from '@fe-monorepo/store';

export const AEMSampleShopResponse = () => {
    const language = useAppSelector((state: RootState) => state.app.language);
    const aemResponse = {
        data: {
            bannerModelList: {
                items: [
                    {
                        pretitle: null,
                        title: 'Stay connected with your friends anytime. Anywhere. ',
                        bannerTitle: ' ',
                        subtitle: ' ',
                        body: {
                            html: null,
                        },
                        image: {
                            _path: '/content/dam/stcplay/en/general/images/banners/News and Article Landing Page.png',
                            mimeType: 'image/png',
                        },
                        foregroundImages: [],
                        ctaTitle: 'Engage with your friends',
                        ctaLink: null,
                        pageTag: ['stcplay:page/news-and-article'],
                        bannerTypeTag: ['stcplay:banner/article'],
                        _path: '/content/dam/stcplay/en/general/content-fragments/articles/news-and-article-landing-page',
                        sortId: null,
                    },
                    {
                        pretitle: null,
                        title: 'Test Banner 4',
                        bannerTitle: 'Test Banner 4',
                        subtitle: ' ',
                        body: {
                            html: null,
                        },
                        image: {
                            _path: '/content/dam/stcplay/en/general/images/banners/TestBanner4.jpeg',
                            mimeType: 'image/png',
                        },
                        foregroundImages: [],
                        ctaTitle: null,
                        ctaLink: 'https://develop.d24svb28mimwq7.amplifyapp.com/compete/6',
                        pageTag: ['stcplay:page/shop-landing'],
                        bannerTypeTag: ['stcplay:banner/hero'],
                        _path: '/content/dam/stcplay/en/general/content-fragments/banners/hero-banner-4',
                        sortId: 4,
                    },
                    {
                        pretitle: null,
                        title: 'Test Banner 1',
                        bannerTitle: 'Test Banner 1',
                        subtitle: ' ',
                        body: {
                            html: null,
                        },
                        image: {
                            _path: '/content/dam/stcplay/en/general/images/banners/TestBanner1.jpeg',
                            mimeType: 'image/jpeg',
                        },
                        foregroundImages: [],
                        ctaTitle: null,
                        ctaLink: 'https://develop.d24svb28mimwq7.amplifyapp.com/compete/4',
                        pageTag: ['stcplay:page/shop-landing'],
                        bannerTypeTag: ['stcplay:banner/hero'],
                        _path: '/content/dam/stcplay/en/general/content-fragments/banners/home-banner-1',
                        sortId: 1,
                    },
                    {
                        pretitle: null,
                        title: 'Test Banner 2',
                        bannerTitle: 'Test Banner 2',
                        subtitle: ' ',
                        body: {
                            html: null,
                        },
                        image: {
                            _path: '/content/dam/stcplay/en/general/images/banners/TestBanner2.jpeg',
                            mimeType: 'image/png',
                        },
                        foregroundImages: [],
                        ctaTitle: null,
                        ctaLink: 'https://develop.d24svb28mimwq7.amplifyapp.com/compete/8',
                        pageTag: ['stcplay:page/shop-landing'],
                        bannerTypeTag: ['stcplay:banner/hero'],
                        _path: '/content/dam/stcplay/en/general/content-fragments/banners/home-banner-2',
                        sortId: 2,
                    },
                    {
                        pretitle: null,
                        title: 'Test Banner 3',
                        bannerTitle: 'Test Banner 3',
                        subtitle: ' ',
                        body: {
                            html: null,
                        },
                        image: {
                            _path: '/content/dam/stcplay/en/general/images/banners/TestBanner3.jpeg',
                            mimeType: 'image/png',
                        },
                        foregroundImages: [],
                        ctaTitle: null,
                        ctaLink: 'https://develop.d24svb28mimwq7.amplifyapp.com/compete/7',
                        pageTag: ['stcplay:page/shop-landing'],
                        bannerTypeTag: ['stcplay:banner/hero'],
                        _path: '/content/dam/stcplay/en/general/content-fragments/banners/home-banner-3',
                        sortId: 3,
                    },
                    {
                        pretitle: null,
                        title: 'Test Banner 5',
                        bannerTitle: 'Test Banner 5',
                        subtitle: ' ',
                        body: {
                            html: null,
                        },
                        image: {
                            _path: '/content/dam/stcplay/en/general/images/banners/TestBanner5.jpeg',
                            mimeType: 'image/png',
                        },
                        foregroundImages: [],
                        ctaTitle: null,
                        ctaLink: 'https://develop.d24svb28mimwq7.amplifyapp.com/compete/5',
                        pageTag: ['stcplay:page/shop-landing'],
                        bannerTypeTag: ['stcplay:banner/hero'],
                        _path: '/content/dam/stcplay/en/general/content-fragments/banners/home-banner-5',
                        sortId: 5,
                    },
                    {
                        pretitle: language === 'en' ? 'Coming soon' : `قريباً`,
                        title: language === 'en' ? 'Mass Effect 3 Remastered 4K' : `ماس إفكت 3 رمستر 4K`,
                        bannerTitle: ' ',
                        subtitle:
                            language === 'en' ? 'Prepare to discover the wonders of Normandy' : `قم بإحالة صديق نقطة وادفع بها لاحقا!`,
                        body: {
                            html: null,
                        },
                        image: {
                            _path: IMAGES.StaticMediumBannerPreOrderNowBGImage.toString(),
                            mimeType: 'image/png',
                        },
                        foregroundImages: [
                            {
                                image: {
                                    _path: IMAGES.StaticMediumBannerPreOrderNowImage.toString(),
                                    mimeType: 'image/png',
                                },
                                sortId: null,
                            },
                        ],
                        ctaTitle: language === 'en' ? 'Pre-order now' : `اطلب مسبقا الآن`,
                        ctaLink: null,
                        pageTag: ['stcplay:page/shop-landing'],
                        bannerTypeTag: ['stcplay:banner/medium'],
                        _path: '/content/dam/stcplay/en/general/content-fragments/banners/mass-effect',
                        sortId: 6,
                    },
                    {
                        pretitle: null,
                        title: 'Build your own PlayStation® bundle',
                        bannerTitle: ' ',
                        subtitle: 'Use this code BUNDLE for 15% off any 3 Sony products in cart',
                        body: {
                            html: null,
                        },
                        image: {
                            _path: '/content/dam/stcplay/en/general/images/banners/Play Station.png',
                            mimeType: 'image/png',
                        },
                        foregroundImages: [],
                        ctaTitle: null,
                        ctaLink: null,
                        pageTag: ['stcplay:page/product-listing'],
                        bannerTypeTag: ['stcplay:banner/plp'],
                        _path: '/content/dam/stcplay/en/general/content-fragments/banners/plp-banner',
                        sortId: null,
                    },
                    {
                        pretitle: null,
                        title:
                            language === 'en'
                                ? 'Refer a friend, get 100pts and pay with it later!'
                                : `قم بإحالة صديق ، واحصل على 100 نقطة وادفع بها لاحقا!`,
                        bannerTitle: ' ',
                        subtitle:
                            language === 'en'
                                ? 'Prepare yourself for the new shopping experience!'
                                : `قم بإحالة صديق نقطة وادفع بها لاحقا!`,
                        body: {
                            html: null,
                        },
                        image: {
                            _path: null,
                            mimeType: 'image/png',
                        },
                        foregroundImages: [
                            {
                                image: {
                                    _path: IMAGES.StaticMediumBannerReferNowImage.toString(),
                                    mimeType: 'image/png',
                                },
                                sortId: null,
                            },
                        ],
                        ctaTitle: language === 'en' ? 'Refer now' : `الرجوع الآن`,
                        ctaLink: null,
                        pageTag: ['stcplay:page/shop-landing'],
                        bannerTypeTag: ['stcplay:banner/medium'],
                        _path: '/content/dam/stcplay/en/general/content-fragments/banners/refer-a-friend',
                        sortId: 7,
                    },
                ],
            },
            coverBannerModelList: {
                items: [
                    {
                        pretitle: null,
                        title: language === 'en' ? 'New in stock' : 'وفرناها من جديد',
                        subTitle: language === 'en' ? 'You’ll prob like this' : 'ممكن تعجبك',
                        linkIcon: null,
                        linkUrl: '/shop/newly_created',
                        image: {
                            _path: IMAGES.Card_Diamond.toString(),
                            mimeType: 'image/png',
                        },
                        launchDate: null,
                        backgroundColor: null,
                        priceField: {
                            html: null,
                        },
                        section: {
                            title: 'Hot stuff from stc play',
                            image: null,
                            sortId: null,
                        },
                        pageTag: ['stcplay:page/shop-landing'],
                        bannerType: ['stcplay:banner/cover-banner/small-gif-banner'],
                        _path: '/content/dam/stcplay/en/commerce/content-fragments/products/product-1',
                        _metadata: {
                            stringMetadata: [
                                {
                                    name: 'title',
                                    value: 'New Stock',
                                },
                                {
                                    name: 'description',
                                    value: '',
                                },
                            ],
                        },
                        sortId: 1,
                    },
                    {
                        pretitle: null,
                        title: language === 'en' ? 'Scan and go' : 'امسح و انتقل',
                        subTitle: language === 'en' ? 'Hunt for app deals' : 'انضم لمهمة صيد لعروض التطبيق الرهيبة',
                        linkIcon: null,
                        linkUrl: '/shop/limitedappoffers',
                        image: {
                            _path: IMAGES.Card_QRCode.toString(),
                            mimeType: 'image/png',
                        },
                        launchDate: null,
                        backgroundColor: null,
                        priceField: {
                            html: null,
                        },
                        section: {
                            title: 'Limited app offers',
                            image: null,
                            sortId: null,
                        },
                        pageTag: ['stcplay:page/shop-landing'],
                        bannerType: ['stcplay:banner/cover-banner/banner-with-photo'],
                        _path: '/content/dam/stcplay/en/commerce/content-fragments/products/product-2',
                        _metadata: {
                            stringMetadata: [
                                {
                                    name: 'title',
                                    value: 'Scan and go',
                                },
                                {
                                    name: 'description',
                                    value: '',
                                },
                            ],
                        },
                        sortId: 2,
                    },
                    {
                        pretitle: null,
                        title: ' ',
                        subTitle: null,
                        linkIcon: null,
                        linkUrl: '/shop/digital_cards',
                        image: null,
                        launchDate: '2023-12-25T20:37:00.000+08:00',
                        backgroundColor: null,
                        priceField: {
                            html: null,
                        },
                        section: {
                            title: "Get it before it's too late",
                            image: null,
                            sortId: null,
                        },
                        pageTag: ['stcplay:page/shop-landing'],
                        bannerType: ['stcplay:banner/cover-banner/product-banner'],
                        _path: '/content/dam/stcplay/en/commerce/content-fragments/products/product-3',
                        _metadata: {
                            stringMetadata: [
                                {
                                    name: 'title',
                                    value: 'Launch Product',
                                },
                                {
                                    name: 'description',
                                    value: '',
                                },
                            ],
                        },
                        sortId: 3,
                    },
                ],
            },
        },
    };
    return aemResponse;
};
