import { useTranslate } from '@fe-monorepo/hooks';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import format from 'string-format';

import { LabelProps, OrderTrackingBarProps, StepProps } from './types';

const Label = ({ text, icon, isDone, containerClass }: LabelProps) => {
  return (
    <div className={`flex gap-x-8 8K:mb-[.5rem] ${containerClass || ''}`}>
      {icon && <ResponsiveIcon name={icon} baseWidth={20} baseHeight={20} className={isDone ? 'fill-sunset' : 'fill-secondary/[0.42]'} />}
      <p className={`text-fs-body-small font-regular 8K:ml-[.2rem] ${isDone ? 'text-sunset' : 'text-secondary/[0.42]'}`}>{text}</p>
    </div>
  );
};

const Step = ({ label, labelArgs, isDone, icon, onClick }: StepProps) => {
  const { translate } = useTranslate();
  return (
    <button
      className="relative pb-[10px]"
      disabled={!isDone}
      onClick={() => {
        onClick && onClick();
      }}
    >
      <Label
        text={format(translate(label) || '', ...(labelArgs || [])) || ''}
        icon={icon}
        isDone={isDone}
        containerClass="hidden SD:flex"
      />
      <span
        className={`absolute bottom-0 left-0 w-full 8K:h-[.1rem] ${isDone ? 'h-[2px] bg-sunset' : 'h-[0.0625rem] bg-secondary/30'}`}
      ></span>
    </button>
  );
};

const TrackingBar = ({ steps, currentStep }: OrderTrackingBarProps) => {
  const { translate } = useTranslate();
  const step = steps[currentStep];
  const label = format(translate(step?.label) || '', ...(step?.labelArgs || [])) || '';
  const icon = step?.icon;
  return (
    <div className="grid grid-cols-3 gap-x-8 SD:gap-x-24 8K:mb-[2rem] 8K:mt-[1.5rem] 8K:gap-x-[1rem] ">
      <div className="flex justify-between col-span-3 SD:hidden">
        <Label text={label} icon={icon} isDone />
        <span className="text-fs-body-small text-secondary/[0.42] font-regular">
          {translate('shop.cart.order_status_step')} {currentStep + 1}/{steps?.length}
        </span>
      </div>
      {steps?.map(({ label, labelArgs, icon, onClick }, key) => (
        <Step key={key} label={label} labelArgs={labelArgs} icon={icon} isDone={key <= currentStep} onClick={onClick} />
      ))}
    </div>
  );
};

export default TrackingBar;
