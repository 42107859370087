import { t } from 'i18next';

interface OptionStringProps {
  className?: string;
  value: string;
}

const OptionText = (props: OptionStringProps) => {
  return <p className={`responsive-text-body text-secondary ${props.className}`}>{t(props.value)}</p>;
};

export default OptionText;
