import { useTranslate } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import CurrencyText from '@fe-web/Atoms/Text/CurrencyText';
import useHeaderState from '@fe-web/hooks/useHeaderState';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector } from 'react-redux';

import Button from '../../../../components/Atoms/Buttons/Button';
import HoverText from '../../../../components/Atoms/Text/HoverText';
import Container from '../../../../components/Templates/Container';
import usePageLayout from '../../../../hooks/usePageLayout';

export type TabName = {
  id: number;
  name: string;
  categoryDescriptionCode: string;
};

interface Props {
  productImage: string;
  price: number;
  isPhysical?: boolean;
  isAvailable: boolean;
  changeBar: boolean;

  switchToDelivery: () => void;
  switchToDetails: () => void;
  switchToSpecification: () => void;
  addToCart: () => void;
  isButtonDisabled?: boolean;
}

const BarSection = (props: Props) => {
  {
    /**
     * It is expected that the response from graphQL will have the translations.
     */
  }

  const isHeaderDark = useSelector((state: RootState) => state.app.headerColorChange);
  const { t } = useTranslation();
  const { translate } = useTranslate();
  const { dir } = useHeaderState();
  const prefs = useSelector((state: RootState) => state.app);
  const [hoveredTab, setHoveredTab] = useState<string>('');
  const [chosenTab, setChosenTab] = useState<string>('Product details');
  const ColorAnimation = `#171619`; //useTheme()?.isDarkTheme ? `#FFFFFF` : `#171619`;

  // translate('currency_SR')?.replace('[balance]', '' + formatNumberWithCommasAndDecimal(props.price));

  const barSectionDummyData = {
    barSectionHeader: [
      {
        id: 1,
        name: translate('shop.productPage.productDetails') || '',
        categoryDescriptionCode: 'productDetails_',
      },
      {
        id: 2,
        name: translate('shop.specificationPage.specification') || '',
        categoryDescriptionCode: 'specification_',
      },
      {
        id: 3,
        name: translate('shop.productPage.deliveryReturns') || '',
        categoryDescriptionCode: 'deliverAndReturns_',
      },
    ],
  };

  const pageLayout = usePageLayout();

  const language = pageLayout?.language;

  const actionTabHover = (currentTab: TabName) => {
    if (chosenTab !== currentTab.name) {
      setHoveredTab(currentTab?.name);
    }
  };

  const actionLeaveHover = () => {
    setHoveredTab('');
  };

  const onTabClick = (currentTab: TabName) => {
    setChosenTab(currentTab?.name);
    if (currentTab && currentTab.categoryDescriptionCode) {
      switch (currentTab.categoryDescriptionCode) {
        case 'productDetails_':
          props.switchToDetails();
          break;
        case 'deliverAndReturns_':
          props.switchToDelivery();
          break;
        case 'specification_':
          props.switchToSpecification();
          break;
      }
    }
  };

  const navigateToDetails = () => {
    /*
     * navigation to product details and delivery and returns
     * small image, price, and add to cart
     */

    const elem = document.getElementById('DetailsScroll');
    elem?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <header
      onMouseLeave={() => actionLeaveHover()}
      className={`h-[3.75rem] sticky top-[4.542rem] SD:top-[4.417rem] 2K:top-[4rem] bg-primary z-[3]`}
    >
      <div className="border-t-[0.063rem] z-[1] border-secondary/20 " />
      <Container className={`flex items-center justify-between`}>
        <div
          className={`flex gap-64 overflow-x-scroll py-12
            4xl:gap-140 4xl:py-44 8xl:py-81 8xl:gap-280`}
        >
          {barSectionDummyData?.barSectionHeader
            ?.filter(
              barSection =>
                props?.isPhysical ||
                (!props?.isPhysical &&
                  barSection.categoryDescriptionCode !== 'deliverAndReturns_' &&
                  barSection.categoryDescriptionCode !== 'specification_'),
            )
            ?.sort((a, b) => a.id - b.id)
            ?.map((tab, index) => {
              return (
                <div
                  onMouseLeave={() => actionLeaveHover()}
                  className={`relative`}
                  key={index}
                  onMouseEnter={() => actionTabHover(tab)}
                  onClick={() => {
                    navigateToDetails();
                    onTabClick(tab);
                  }}
                >
                  <HoverText
                    className={`
                                        whitespace-nowrap text-fs-body
                                        font-regular text-secondary ${chosenTab !== tab?.name && 'opacity-[0.7]'}
                                        `}
                    hover={{ color: 'text-secondary' }}
                    alwaysShowUnderline={false}
                    underlineStyle=""
                    text={tab.name}
                  />
                  <motion.div
                    initial={{ width: `0px` }}
                    animate={{
                      backgroundColor: hoveredTab === tab?.name ? [`#171619`, `#171619`, ColorAnimation] : ColorAnimation,
                      width: hoveredTab === tab?.name ? `100%` : `0%`,
                    }}
                    transition={{ duration: 0.3 }}
                    className={`relative bg-secondary h-[0.125rem] w-fullduration-[0.3]`}
                  />

                  <div
                    className={`${
                      chosenTab === tab?.name
                        ? 'mt-[-2px] 2K:mt-[-3.56px] 4K:mt-[-5.3px] 8K:mt-[-10.67px] relative bg-secondary h-[0.125rem] w-full'
                        : 'hidden'
                    }`}
                  ></div>
                </div>
              );
            })}
        </div>

        <div
          className={`flex gap-[20px] xsMax:hidden xs:hidden
                              2K:gap-[35.56px] 4K:gap-[53.33px] 8K:gap-[106.67px]`}
        >
          <LazyLoadImage
            className={`
                  ${props.productImage ? ' ' : ' hidden '},
                  ${[
                    prefs.language === 'ar' && ' scale-x-[-1] ',
                    `relative w-[40px] h-[40px] rounded
                      2K:w-[71.11px] 4K:w-[106.67px] 8K:w-[213.33px]
                      2K:h-[71.11px] 4K:h-[106.67px] 8K:h-[213.33px]`,
                  ]}

                  `}
            src={props.productImage?.toString()}
            title={'image'}
            alt="image"
          />

          <CurrencyText
            className="flex items-center justify-between h-[2.5rem] text-fs-subtitle text-secondary mdMaxxSS:text-fs-body font-medium"
            currency={props.price}
          />

          {props.isAvailable && (
            <Button
              text={`${t(`action_AddToCart`)}`}
              //action={}
              style={`text-base not-italic font-medium text-fs-body max-h-[2.5rem]
                      min-w-[8.688rem] px-[1.5rem] py-[0.5rem] w-fit gap-[0.5rem] z-1
                      text-white100 border-[0.063rem] rounded-[0.188rem]
                      rounded-sm`}
              disabledStyle={`bg-black10 border-0`}
              normalStyle={pageLayout.btnClass}
              disabled={props.isButtonDisabled}
              onClick={props.addToCart}
            />
          )}
        </div>
      </Container>
      <div className="border-t-[0.063rem] z-[1] border-secondary/20 " />
    </header>
  );
};

export default BarSection;
