// eslint-disable-next-line @typescript-eslint/no-unused-vars
// import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloProvider } from '@apollo/client';
import { automaticallyShowInAppMessages, initialize, isPushSupported, openSession, requestPushPermission } from '@braze/web-sdk';
import { convivaAppTracker } from '@convivainc/conviva-js-appanalytics';
import {
  LinkClickTrackingPlugin,
  enableButtonClickTracking,
  enableLinkClickTracking,
} from '@convivainc/conviva-js-appanalytics-click-tracking';
import { ErrorTrackingPlugin, enableErrorTracking } from '@convivainc/conviva-js-appanalytics-error-tracking';
import { PerformanceTimingPlugin } from '@convivainc/conviva-js-appanalytics-performance-timing';
import { MixPanelEvents, Translation, languageMapping } from '@fe-monorepo/helper';
import { useAccount } from '@fe-monorepo/hooks';
import {
  PageErrorTypes,
  clearCart,
  clearDeliveryAddress,
  clearInvoice,
  clearPreferences,
  clearUser,
  persistor,
  setIsGuestUser,
  setPersona,
  setValidateAccount,
  store,
  useAppDispatch,
} from '@fe-monorepo/store';
import { apolloClientUpload } from 'libs/data-access/src/graphql/api';
import React, { ReactElement, createContext, useCallback, useContext, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter, useLocation, useNavigate } from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';

import { LoginModalProvider } from '../quickplay-app/contexts/LoginModal';
import '../styles.scss';
import AnimatedRoutes from './AnimatedRoutes';
import { AppRoutes } from './app.routes.enum';
import convivaHelper from './helpers/convivaHelper';
import mixpanelHelper from './helpers/mixpanelHelper';
import { BitsProvider } from './hooks/useBits/BitsProvider';
import { ConnectProvider } from './hooks/useConnect';
import { NotificationProvider } from './hooks/useNotification';
import ErrorPageWrapper from './pages/ErrorPages/ErrorPageWrapper';

interface SessionManagerProps {
  children: React.ReactNode;
}

const SessionManager = (props: SessionManagerProps) => {
  const { children } = props;

  const dispatch = useAppDispatch();
  const navigation = useNavigate();
  const { pathname } = useLocation();

  const { pageError, setPageError } = useAppProvider();

  const { setLogout } = useAccount();

  useEffect(() => {
    if (pageError === PageErrorTypes.SESSION_EXPIRED) {
      setPageError?.(undefined);

      dispatch(clearCart());
      dispatch(clearInvoice());
      dispatch(clearDeliveryAddress());
      setLogout(true);
      dispatch(clearUser());
      dispatch(setValidateAccount(false));
      dispatch(clearPreferences());
      dispatch(setIsGuestUser(false));
      dispatch(setPersona('guest'));

      mixpanelHelper.reset();
      convivaHelper.setIdentity('GUEST');

      localStorage.removeItem('selectedTimezone');
      localStorage.removeItem('selectedCurrency');
      localStorage.removeItem('selectedCountry');

      navigation(`${AppRoutes.authSignIn}?sessionExpired=true&redirect_url=${pathname}`, { replace: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageError]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

const tagManagerArgs = {
  gtmId: 'GTM-MQP2JWZ',
};

TagManager.initialize(tagManagerArgs);

convivaAppTracker({
  appId: 'STCPLAY_WEB',
  convivaCustomerKey: process.env.NX_CONVIVA_CUSTOMER_KEY,
  appVersion: '1.1.0',
  contexts: { performanceTiming: true },
  plugins: [PerformanceTimingPlugin(), ErrorTrackingPlugin(), LinkClickTrackingPlugin()],
});

enableLinkClickTracking();
enableButtonClickTracking();
enableErrorTracking();

const isDebug =
  process.env.NX_APP_ENVIRONMENT === 'development' ||
  process.env.NX_APP_ENVIRONMENT === 'localhost' ||
  process.env.NX_APP_ENVIRONMENT === 'staging';

initialize(process.env.NX_BRAZE_WEB_API_KEY || '', {
  enableLogging: isDebug,
  baseUrl: process.env.NX_BRAZE_WEB_BASE_URL || '',
  safariWebsitePushId: process.env.NX_BRAZE_WEB_SAFARI_WEBSITE_PUSH_ID || '',
});
automaticallyShowInAppMessages();
openSession();
requestPushPermission();
isPushSupported();
// toggleLogging();

interface PageErrorProviderModel {
  pageError?: PageErrorTypes;
  setPageError?: React.Dispatch<React.SetStateAction<PageErrorTypes | undefined>>;
}

export const AppContext = createContext<PageErrorProviderModel>({});

interface CustomProviderProps {
  children: ReactElement;
}

export function CustomApolloProvider({ children }: CustomProviderProps) {
  const { pageError, setPageError } = useContext(AppContext);
  const onPageError = useCallback(
    (error: PageErrorTypes, isNetworkError?: boolean, isOnline?: boolean) => {
      if (typeof isOnline !== 'undefined' && !isOnline) {
        setPageError?.(PageErrorTypes.OFFLINE);
        return;
      }
      if (isNetworkError && isOnline) {
        setPageError?.(PageErrorTypes.SERVER_DOWN);
        return;
      }
      if (!pageError) {
        setPageError?.(error);
      }
    },
    [pageError],
  );
  return <ApolloProvider client={apolloClientUpload(onPageError)}>{children}</ApolloProvider>;
}

export const useAppProvider = () => {
  const context = useContext(AppContext);

  if (!context) throw 'Please use a provider to use this hook';

  return context;
};

interface AppProviderProps {
  children: ReactElement;
}

export function AppProvider({ children }: AppProviderProps) {
  const [pageError, setPageError] = useState<PageErrorTypes>();
  return <AppContext.Provider value={{ pageError, setPageError }}>{children}</AppContext.Provider>;
}

export function App() {
  const language = store.getState().app.language;
  const [lang, setLanguage] = useState(store.getState().app.language);

  useEffect(() => {
    Translation(language);
  }, [language]);

  useEffect(() => {
    mixpanelHelper.trackEvent(MixPanelEvents.appOpen, {
      'Session ID': `session_${new Date().getTime()}`,
      Language: languageMapping[language],
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      setLanguage(store.getState().app.language);
    });

    // Clean up the subscription when the component unmounts
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <StoreProvider store={store}>
      <ToastContainer toastClassName="my-toast-container" rtl={lang === 'ar'} />
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter basename="/">
          <AppProvider>
            <LoginModalProvider>
              <CustomApolloProvider>
                <BitsProvider showToastMessage={console.log}>
                  <ParallaxProvider>
                    <NotificationProvider>
                      <ConnectProvider>
                        <SessionManager>
                          <HelmetProvider>
                            <ErrorPageWrapper>
                              <AnimatedRoutes />
                            </ErrorPageWrapper>
                          </HelmetProvider>
                        </SessionManager>
                      </ConnectProvider>
                    </NotificationProvider>
                  </ParallaxProvider>
                </BitsProvider>
              </CustomApolloProvider>
            </LoginModalProvider>
          </AppProvider>
        </BrowserRouter>
      </PersistGate>
    </StoreProvider>
  );
}

export default App;
