import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { START_MUTATION, CANCEL_MUTATION, OPENREG_MUTATION, CLOSEREG_MUTATION } from '@fe-monorepo/data-access';
import {
    StartTournamentParamsModel,
    CancelTournamentParamsModel,
    OpenRegTournamentParamsModel,
    CloseRegTournamentParamsModel,
} from './type';

//START TOURNAMENT
const usePlayStart = () => {
    const [isStartSuccessful, setStartStatus] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [startErrorMessage, setStartErrorMessage] = useState<string>('');

    const [startTournament, { data: startTournamentResponse, error: errorStartTournament }] = useMutation(START_MUTATION.startTournament, {
        errorPolicy: 'all',
    });

    useEffect(() => {
        if (startTournamentResponse) {
            if (startTournamentResponse.startTournament.is_successful) {
                setStartStatus(startTournamentResponse.startTournament.is_successful);
            }
            if (startTournamentResponse.startTournament.error_msg) {
                setStartErrorMessage(startTournamentResponse.startTournament.error_msg);
            }
            setStartStatus(startTournamentResponse.startTournament.is_successful);
        }
        setIsLoading(false);
    }, [startTournamentResponse]);

    useEffect(() => {
        if (errorStartTournament) {
            setError(errorStartTournament.message);
        }
        setIsLoading(false);
    }, [errorStartTournament]);

    const beginTournament = (params: StartTournamentParamsModel) => {
        startTournament({ variables: { details: params } });
        setIsLoading(true);
    };

    return {
        isLoading,
        error,
        beginTournament,
        isStartSuccessful,
        startErrorMessage,
    };
};

//CANCEL TOURNAMENT
const usePlayCancel = () => {
    const [isCancelSuccessful, setCancelStatus] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [cancelErrorMessage, setCancelErrorMessage] = useState<string>('');

    const [cancelTournament, { data: cancelTournamentResponse, error: errorCancelTournament }] = useMutation(
        CANCEL_MUTATION.cancelTournament,
        {
            errorPolicy: 'all',
        },
    );

    useEffect(() => {
        if (cancelTournamentResponse) {
            if (cancelTournamentResponse.cancelTournament.is_successful) {
                setCancelStatus(cancelTournamentResponse.cancelTournament.is_successful);
            }
            if (cancelTournamentResponse.cancelTournament.error_msg) {
                setCancelErrorMessage(cancelTournamentResponse.cancelTournament.error_msg);
            }
            setCancelStatus(cancelTournamentResponse.cancelTournament.is_successful);
        }
        setIsLoading(false);
    }, [cancelTournamentResponse]);

    useEffect(() => {
        if (errorCancelTournament) {
            setError(errorCancelTournament.message);
        }
        setIsLoading(false);
    }, [errorCancelTournament]);

    const cancelTheTournament = async (params: CancelTournamentParamsModel) => {
        await cancelTournament({ variables: { details: params } });
        setIsLoading(true);
    };

    return {
        isLoading,
        error,
        cancelTheTournament,
        isCancelSuccessful,
        cancelErrorMessage,
    };
};

// OPEN REGISTRATION
const usePlayOpenReg = () => {
    const [isOpenRegSuccessful, setOpenRegStatus] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [openRegErrorMessage, setOpenRegErrorMessage] = useState<string>('');

    const [openRegTournament, { data: openRegTournamentResponse, error: errorOpenRegTournament }] = useMutation(
        OPENREG_MUTATION.openRegTournament,
        {
            errorPolicy: 'all',
        },
    );

    useEffect(() => {
        if (openRegTournamentResponse) {
            if (openRegTournamentResponse.openRegTournament.is_successful) {
                setOpenRegStatus(openRegTournamentResponse.openRegTournament.is_successful);
            }
            if (openRegTournamentResponse.openRegTournament.error_msg) {
                setOpenRegErrorMessage(openRegTournamentResponse.openRegTournament.error_msg);
            }
            setOpenRegStatus(openRegTournamentResponse.openRegTournament.is_successful);
        }
        setIsLoading(false);
    }, [openRegTournamentResponse]);

    useEffect(() => {
        if (errorOpenRegTournament) {
            setError(errorOpenRegTournament.message);
        }
        setIsLoading(false);
    }, [errorOpenRegTournament]);

    const openRegTheTournament = (params: OpenRegTournamentParamsModel) => {
        setIsLoading(true);
        openRegTournament({ variables: { details: params } });
    };

    return {
        isLoading,
        error,
        openRegTheTournament,
        isOpenRegSuccessful,
        openRegErrorMessage,
    };
};

// CLOSE REG

const usePlayCloseReg = () => {
    const [isCloseRegSuccessful, setCloseRegStatus] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [closeRegErrorMessage, setCloseRegErrorMessage] = useState<string>('');

    const [closeRegTournament, { data: closeRegTournamentResponse, error: errorCloseRegTournament }] = useMutation(
        CLOSEREG_MUTATION.closeRegTournament,
        {
            errorPolicy: 'all',
        },
    );

    useEffect(() => {
        if (closeRegTournamentResponse) {
            if (closeRegTournamentResponse.closeRegTournament.is_successful) {
                setCloseRegStatus(closeRegTournamentResponse.closeRegTournament.is_successful);
            }
            if (closeRegTournamentResponse.closeRegTournament.error_msg) {
                setCloseRegErrorMessage(closeRegTournamentResponse.closeRegTournament.error_msg);
            }
            setCloseRegStatus(closeRegTournamentResponse.closeRegTournament.is_successful);
        }
        setIsLoading(false);
    }, [closeRegTournamentResponse]);

    useEffect(() => {
        if (errorCloseRegTournament) {
            setError(errorCloseRegTournament.message);
        }
        setIsLoading(false);
    }, [errorCloseRegTournament]);

    const closeRegTheTournament = async (params: CloseRegTournamentParamsModel) => {
        setIsLoading(true);
        await closeRegTournament({ variables: { details: params } });
    };

    return {
        isLoading,
        error,
        closeRegTheTournament,
        isCloseRegSuccessful,
        closeRegErrorMessage,
    };
};

export { usePlayStart, usePlayCancel, usePlayOpenReg, usePlayCloseReg };
