import { useTranslation } from 'react-i18next';

import { InputFieldDefinition, useInputValidation, ValidationParameters } from '@fe-monorepo/forms'

import { APIErrorType } from '@fe-web/hooks/useAPIError';

import InputField, { InputFieldStyle } from './InputField/InputField';
import { useEffect } from 'react';

type Props =
{
  initialValue?: string,
  style: InputFieldStyle,
  inputFieldInfo: InputFieldDefinition,

  onChange: (key: string, e: { value: string; isValid: boolean }) => void;

  initializeForm?: (key: string, value: string) => void,
  apiError?: APIErrorType,
  resetAPIError?: (key: string) => void;

  onClickEnter?: () => void,
}

const GenericInputField = (props: Props) =>
{
  const { style, inputFieldInfo } = props;

  const { t } = useTranslation();

  const validationParameters: ValidationParameters =
  {
    inputFieldKey: inputFieldInfo.key,
    initialValue: props.initialValue,
    isOptional:   inputFieldInfo.isOptional,
    validationRules: inputFieldInfo.validationCriteria,
    apiErrorMessage: props.apiError?.[inputFieldInfo.key],
    resetAPIError: props.resetAPIError,
    onChange: props.onChange,
    dependencies: []
  }

  const{ errorMessage, setValue, setFocused, setTyped } = useInputValidation(validationParameters);

  const isUserAllowedToType = (value: string) => inputFieldInfo.isAllowedToTypeCriteria.every(validate => validate(value));

  useEffect(() => 
  {
    if (props.initializeForm && props.initialValue) 
    {
      props.initializeForm(inputFieldInfo.key, props.initialValue)
    }
  }, [props.initialValue])
  
  return (
    <InputField
      style={{ ...style, containerStyle: '' }}

      existingValue={props.initialValue?? ""}
      isSecret={inputFieldInfo.isSecret}
      label={t(inputFieldInfo.label)}
      errorMessage={errorMessage}

      isUserAllowedToType={isUserAllowedToType}
      retrieveValue={setValue}
      retrieveFocus={setFocused}
      retrieveTyped={setTyped}

      onClickEnter={props.onClickEnter}
    />
  );
};

export default GenericInputField;
