import { IconNames } from '@fe-monorepo/helper';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';

interface EmptyCartProps {
  image: string;
  text?: string | null;
  isMiniCart?: boolean;
}

const EmptyCart = (props: EmptyCartProps) => {
  return (
    <>
      {props.isMiniCart ? (
        <div className="grid gap-20">
          <div className={`flex-nowrap font-medium text-fs-body-large text-secondary`}>{props.text}</div>
          <div className={`content-center object-cover border-t-[0.0625rem] border-secondary/10 pt-20`}>
            <ResponsiveIcon
              className={'flex items-center justify-center w-full h-full'}
              name={IconNames.shopEmpty}
              baseHeight={120}
              baseWidth={120}
            />
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center bg-primary w-full sm:gap-y-[24px] pt-[20%] sm:pt-[13%] pb-[13%]">
          <ResponsiveIcon
            className={'flex items-center justify-center w-full h-full'}
            name={IconNames.shopEmpty}
            baseHeight={160}
            baseWidth={160}
          />
          <p className=" justify-center text-fs-title font-medium text-secondary inline-flex gap-x-24 items-center">{props.text}</p>
        </div>
      )}
    </>
  );
};

export default EmptyCart;
