import { useState, useEffect } from "react"
import { useSelector } from 'react-redux';
import { RootState } from "@fe-monorepo/store";

import { ValidationRule } from "./inputField";
import { t } from "i18next";
import { EditFormFunction } from "./useForm";

type CompositeValue = { value: string; isValid: boolean; };

export type ValidationParameters =
{
  inputFieldKey: string,
  isOptional: boolean,

  initialValue?: string,

  validationRules: ValidationRule[],
  apiErrorMessage?: string,

  resetAPIError?: (key: string) => void,
  onChange: EditFormFunction,
  dependencies: any[]
}

export const useInputValidation = (parameters: ValidationParameters) =>
{
  const { inputFieldKey, isOptional, validationRules, apiErrorMessage, onChange } = parameters;

  const prefs = useSelector((state: RootState) => state?.app);

  const [value, setValue] = useState<string>(parameters.initialValue?? "");
  const [compositeValue, setCompositeValue] = useState<CompositeValue>({ value: parameters.initialValue?? "", isValid: isOptional });
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [hasFocused, setFocused] = useState<boolean>(false);
  const [hasTyped, setTyped] = useState<boolean>(false);
  
  const resetAPIError = (): void =>
    {
      if (parameters?.resetAPIError)
      {
        parameters.resetAPIError(inputFieldKey);
      }
  }

  const validateInput = (value: string) =>
  {
    let isValid = true;

    let errorMessage = '';

    if (!isOptional || value.length > 0)
    {
      const rules = validationRules;

      for (let i = 0; i < rules.length; i++)
      {
        const rule = rules[i];

        if (!rule.checkValidity(value))
        {
          isValid = false;
          errorMessage = t(rule.errorMessage);

          break;
        }
      }
    }

    return { isValid, errorMessage };
  };

  useEffect(() => resetAPIError(), [prefs.language])

  useEffect(() =>
  {
    if (apiErrorMessage !== undefined)
    {
      if (apiErrorMessage?.length > 0)
      {
        setErrorMessage(apiErrorMessage);
      }
    }
  }, [apiErrorMessage]);

  useEffect(() =>
  {
    const hasInteractedWithInputField = hasFocused && hasTyped;
    
    if (!hasInteractedWithInputField && !parameters.initialValue)
    {
      return;
    }

    const { isValid, errorMessage } = validateInput(value);

    resetAPIError();

    setErrorMessage(errorMessage);

    const newCompositeValue = { value: value, isValid: isValid }

    // This prevents the 
    if (JSON.stringify(compositeValue) === JSON.stringify(newCompositeValue))
    {
      return
    }

    setCompositeValue(newCompositeValue);
  }, [...parameters.dependencies, value, hasFocused, prefs.language]);


  useEffect(() =>
  {
    onChange(inputFieldKey, compositeValue)
  }, [inputFieldKey, compositeValue]);

  return { setValue, setFocused, setTyped, errorMessage }
}
