import { PAGE_DESCRIPTION, PAGE_KEYWORDS, PAGE_TITLE, TxKeyPath } from '@fe-monorepo/helper';
import { BGType, useMiniGames, useThemedBackground, useTranslate } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import CustomHelmet from '@fe-web/Organisms/CustomHelmet/CustomHelmet';
import Container from '@fe-web/Templates/Container';
import useMobileDetect from '@fe-web/hooks/useMobileDetect';
import mixpanelHelper from 'apps/fe-web/src/app/helpers/mixpanelHelper';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import SubNavBar from '../../../PlayPages/PlayLandingPage/components/SubNavBar/SubNavBar';
import AllMiniGames from '../components/AllMiniGames';
import HighlightedMiniGame from './components/HighlightedMiniGame';

const MiniGamesLandingPage = () => {
  const { getAllMiniGames, getMiniGameHighlighted, miniGamesData, highlightedMiniGameData, isLoading } = useMiniGames();
  const { translate } = useTranslate();
  const isMobile = useMobileDetect();
  const { themeConfig } = useSelector((state: RootState) => state.app);
  const { backgroundImage } = useThemedBackground(BGType.landing, isMobile);

  useEffect(() => {
    getAllMiniGames();
    getMiniGameHighlighted();
    mixpanelHelper.trackPageView('PLAY - GAMES TAB', 'play_page_games_tab', window.location.pathname, document.referrer);
  }, []);

  return (
    <>
      <CustomHelmet
        pageTitle={`${translate(PAGE_TITLE.games as TxKeyPath) ?? ''} - ${translate(PAGE_TITLE.games_more_than as TxKeyPath) ?? ''}`}
        metaDescription={translate(PAGE_DESCRIPTION.games as TxKeyPath) ?? ''}
        metaKeyWords={translate(PAGE_KEYWORDS.games as TxKeyPath) ?? ''}
      />
      <SubNavBar CTASource="Play Page - Games Tab" />
      <Container
        className="my-[2.5rem] inline-flex flex-col gap-[5rem] bg-no-repeat"
        style={themeConfig ? { backgroundImage: `url(${backgroundImage})`, backgroundSize: 'contain' } : {}}
      >
        <HighlightedMiniGame data={highlightedMiniGameData?.data} isLoading={isLoading} />
        <AllMiniGames
          handleLoadMore={(last_cursor: number) => getAllMiniGames({ last_cursor: last_cursor, direction: 'next' })}
          isLoading={isLoading}
          title={translate('mini_game.all_games') ?? ''}
          miniGameList={miniGamesData}
        />
      </Container>
    </>
  );
};

export default MiniGamesLandingPage;
