import { motion } from "framer-motion"
import { ReactNode } from "react"

interface Props
{
  layout: "grid" | "list",
  className: string,
  showGradience?: boolean,
  animationDuration: number,

  children?: ReactNode
}

const VerticalBorder = (props: { className?: string, side: "left"| "right"}) =>
{
  return (
    <div
      className=
      {`
        ${props.className}
        absolute
        ${props.side === "left"? `left-[0.02%]` : `right-[-0.02%]`}


        h-full
        w-[1px] 2K:w-[1.77px] 4K:w-[2.66px] 8K:w-[5.33px]
        bg-gradient-to-b from-sunset via-sunset/[0.4]
      `}
    />
  )
}

const SideBorders = (props: { className: string }) =>
{
  return (
    <div className="h-full">
      <VerticalBorder
        className={props.className}
        side={"left"}
      />

      <VerticalBorder
        className={props.className}
        side={"right"}
      />
    </div>
  )
}

const GradientContainer = (props: Props) =>
{
  const
  {
    layout,
    className,
    showGradience,
    animationDuration,
    children
  } = props;

  return (
    <>
      {
        showGradience
      &&
        <SideBorders
          className={`${layout === "grid"? "top-[0.25%]": "top-[0.525%]"}`}
        />
      }

      <motion.div
        layout="preserve-aspect"
        className=
        {`
          ${className}
          z-[1]
          relative
          h-full w-full
          object-contain

          ${showGradience && "bg-gradient-to-b from-sunset05 via-sunset00"}
        `}

        transition={{ duration: animationDuration }}
      >

        {children}
      </motion.div>
    </>
  )
}


export default GradientContainer
