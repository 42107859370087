import { useTranslate } from '@fe-monorepo/hooks';
import { AppRoutes } from 'apps/fe-web/src/app/app.routes.enum';
import quickplay from 'quickplay-shorts-js';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import VideoLikeIcon from '../../assets/icons/VideoLikeIcon';
import VideoViewsIcon from '../../assets/icons/VideoViewsIcon';
import { CONTENT_TYPE, EVENTS } from '../../constants';
import useAnalytics from '../../hooks/useAnalytics';
import { formatLargeNumber } from '../VerticalPlayer/helpers';
import Loader from '../common/Loader';

function Challenge() {
  const [challengeDetail, setChallengeDetail]: any = useState(null);
  const [challengeList, setChallengeList]: any = useState(null);
  const [challenge, setChallenge]: any = useState(null);
  const { challengeName } = useParams();
  const navigate = useNavigate();
  const { trackEvent } = useAnalytics();
  const { translate } = useTranslate();

  const challengeModule = new quickplay.QuickPlayChallenges();

  useEffect(() => {
    async function fetchChallengeListData() {
      try {
        const challengeListResponse = await challengeModule.getChallengesList('');
        if (challengeListResponse?.status === 200) setChallengeList(challengeListResponse.data.result);
      } catch (error) {
        console.error(error);
      }
    }
    fetchChallengeListData();
  }, []);

  useEffect(() => {
    async function fetchChallengeData() {
      const challengeData = challengeList?.find((challenge: any) => challenge?.title.toLowerCase() === challengeName?.toLowerCase());
      setChallenge(challengeData);
      try {
        let limit = 6;
        let offset = 1;
        const challengeResponse = await challengeModule.getChallengeDetails({
          limit: limit,
          offset: offset,
          hashtagId: challengeData?.hashtagId,
        });
        if (challengeResponse?.status === 200) {
          setChallengeDetail(challengeResponse.data.result.videos);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchChallengeData();
  }, [challengeList]);

  if (!challengeDetail) return <Loader />;

  const handleContentDetail = (contentInfo: any, index: number) => {
    trackEvent({
      event: EVENTS.THUMBNAIL_CLICK,
      content: { ...contentInfo, horizontalIndex: index, verticalIndex: 0 },
    });
    navigate(
      `${AppRoutes.bitsPlay}/content/${challenge?.hashtagId}?contentType=${CONTENT_TYPE.CHALLENGE}&clickedVideoId=${contentInfo._id}`,
    );
  };

  const handleChallengeLeaderBoard = () => {
    navigate(`${AppRoutes.bits}/challengeleaderboard/${challenge?.hashtagId}`);
  };

  return (
    <section className="main-section">
      {/* Section Title Start */}
      <div className="section-head">
        <div className="section-head-title">
          <h2>#{challenge?.title}</h2>
          <p>
            <span>{challenge?.videoCount} Bits &nbsp;</span> {translate('quickplay.label-use-hashtag-description')}
          </p>
        </div>

        <button
          onClick={handleChallengeLeaderBoard}
          className="text-base not-italic max-h-[2.5rem] font-medium gap-[0.5rem] z-1 text-white border-sunset border-[0.063rem] mb-[0px] px-[1rem] py-[0.5rem] items-end rounded-[0.188rem] bg-sunset disabled:bg-secondary/[0.1] after:bg-sunset before:bg-white100 cursor-pointer hover:border-[0.0625rem] hover:border-sunset hover:text-sunsetText button-animation-ltr"
        >
          {translate('quickplay.label-view-leaderboard')}
        </button>
      </div>
      {/* Section Title End */}

      {/* Video List - Grid View Start */}
      <div className="flex flex-col gap-y-[2rem] justify-center items-center">
        <div className="grid grid-cols-12 gap-y-[2rem] gap-x-[1rem] w-[100%]" id="tab1">
          {challengeDetail?.map((video: any, index: number) => {
            let thumbnailUrls = video.thumbnailUrls ? video.thumbnailUrls[0] : video.thumbnailUrl;
            return (
              <div className="col-span-6 lg:col-span-2" key={video.videoId}>
                <div className="relative cursor-pointer" id={video.videoId}>
                  {/* User Profile */}
                  <Link to={`/users/${video.user.userId}`} className="video_profile">
                    <img src={video.user.profileImageUrl} alt={video.user.userName} className="video_profile--image" />
                    <p className="video_profile--name">{video.user.userName}</p>
                  </Link>

                  {/* Video Image */}
                  <img
                    src={thumbnailUrls}
                    alt=""
                    className="w-full h-full aspect-[9/16] object-cover object-center rounded-md"
                    onClick={() => handleContentDetail(video, index)}
                  />

                  {/* Video Views & Like Count */}
                  <div className="video_detail">
                    <div className="video_info videoViews">
                      <VideoViewsIcon />
                      <span className="video_info--count">{video?.viewsCount ? formatLargeNumber(video.viewsCount) : 0}</span>
                    </div>

                    <div className="video_info videoLike">
                      <VideoLikeIcon />
                      <span className="video_info--count">{video?.likeCount ? formatLargeNumber(video.likeCount) : 0}</span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* Video List - Grid View End */}
    </section>
  );
}

export default Challenge;
