import { IconNames, PAGE_DESCRIPTION, PAGE_KEYWORDS, PAGE_TITLE, TxKeyPath } from '@fe-monorepo/helper';
import { useGetAllReturnsInfo, useTranslate } from '@fe-monorepo/hooks';
import { OrderReturnModel } from '@fe-monorepo/models';
import { RootState, addReturnsTotal } from '@fe-monorepo/store';
import LoadingIndicator from '@fe-web/Molecules/LoadingIndicator/LoadingIndicator';
import CustomHelmet from '@fe-web/Organisms/CustomHelmet/CustomHelmet';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import useMobileDetect from '@fe-web/hooks/useMobileDetect';
import usePageLayout from '@fe-web/hooks/usePageLayout';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ResponsiveIcon from '../../../../components/Atoms/Icon/ResponsiveIcon';
import { StyledHeaderContainer } from '../Orders/OrderPage.styled';
import ProductDetailsHeader from '../component/ProductLabel';
import DateDropDown from '../component/SeachDetails/Component/DateDropDown';
import PriceRangePicker from '../component/SeachDetails/Component/PriceRange';
import SearchBar from '../component/SeachDetails/Component/SearchBar';
import StatusDropDown from '../component/SeachDetails/Component/StatusDropDown';
import ReturnData from './ReturnData';

const statusToNumber: { [key: string]: number } = {
  'Action required': 11,
  Rejected: 6,
  Closed: 6,
  Refunded: 4,
  Approved: 43,
  'In Transit': 2,
};

const statusToNumberPartial: { [key: string]: number } = {
  'Action required': 11,
  Rejected: 8,
  Closed: 8,
  Refunded: 4,
  Approved: 26,
  'In Transit': 2,
};

const statusPriorities = ['Action required', 'Rejected', 'Closed', 'Refunded', 'Approved', 'In Transit'];

function getMainStatus(items: string[]): number | undefined {
  const statusCounts = items.reduce((counts, item) => {
    counts[item] = (counts[item] || 0) + 1;
    return counts;
  }, {} as Record<string, number>);

  if (Object.keys(statusCounts).length === 1) {
    return statusToNumber[items[0]];
  }

  for (const status of statusPriorities) {
    if (statusCounts[status]) {
      return statusToNumberPartial[status];
    }
  }

  return undefined;
}

interface ReturnPageProps {
  setFilteredReturnsLength: (value: number) => void;
}

const ReturnPage: React.FC<ReturnPageProps> = ({ setFilteredReturnsLength }) => {
  const { language } = usePageLayout();
  const isMobile = useMobileDetect();
  const { translate } = useTranslate();
  const [shouldReload, setShouldReload] = useState(true);
  const isHeaderDark = useSelector((state: RootState) => state.app.headerColorChange);
  const [showMoreFilters, setShowOtherFilters] = useState(false);
  const sentinelRef = useRef(null);
  const [showLoadingText, setShowLoadingText] = useState<boolean>(true);
  const [isMomentumReachEnd, setMomentumReachEndStatus] = useState<boolean>(false);

  useEffect(() => {
    if (isMobile && shouldReload) {
      setShouldReload(false);
    }
  }, [isMobile, shouldReload]);

  const showDetails = (invoice_number: string) => {};

  /* ================================ ALL FILTERS LOGIC HERE ================================ */
  const [searchQuery, setSearchQuery] = useState('');
  const [returnOrders, setReturnOrders] = useState<OrderReturnModel[]>([]);
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(new Date(Date.now() - 6 * 30.44 * 24 * 60 * 60 * 1000));
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(new Date(Date.now() + 24 * 60 * 60 * 1000));
  const [selectedStatus, setSelectedStatus] = useState('');
  const [isIDAscending, setIsIDAscending] = useState(false);
  const [isDateAscending, setIsDateAscending] = useState(false);
  const [sortType, setSortType] = useState('return_number');
  const [sortValue, setSortValue] = useState('DESC');
  const [lastCursor, setLastCursor] = useState<number>(0);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(Infinity);

  const { getAllReturnsInfo, getAllReturnsInfoData, isLoading } = useGetAllReturnsInfo();

  useEffect(() => {
    if (getAllReturnsInfoData?.data) {
      if (getAllReturnsInfoData?.data.length > 0) {
        setReturnOrders(returnItems => [...returnItems, ...(getAllReturnsInfoData.data || [])]);
      } else if (returnOrders.length > 0 && getAllReturnsInfoData.data.length === 0) {
        setLastCursor(returnOrders[returnOrders.length - 1]?.last_cursor);
        setMomentumReachEndStatus(true);
      }
    }
  }, [getAllReturnsInfoData]);

  //Fetch new returns when there are changes on the filters sorting and search

  const toggleSorting = (isAscending: boolean, isID: boolean) => {
    if (isID) {
      setIsDateAscending(true);
      setIsIDAscending(isAscending);
    } else {
      setIsIDAscending(true);
      setIsDateAscending(isAscending);
    }
    setSortValue(isAscending ? 'ASC' : 'DESC');
    setSortType(isID ? 'return_number' : 'created_at');
  };

  const handleLoadMore = (last_cursor: number) => {
    getReturnList(last_cursor);
  };

  const getReturnList = (last_cursor: number) => {
    getAllReturnsInfo({
      return_number: searchQuery,
      min: String(minPrice),
      max: maxPrice !== Infinity ? String(maxPrice) : '',
      date_from: selectedStartDate ? moment(selectedStartDate).format('YYYY-MM-DD') : '',
      date_to: selectedEndDate ? moment(selectedEndDate).format('YYYY-MM-DD') : '',
      status_code: selectedStatus ? selectedStatus : '',
      last_cursor: last_cursor,
      direction: 'next',
      sort_type: sortType ? sortType : '',
      sort_value: sortValue ? sortValue : '',
    });
  };

  useEffect(() => {
    // loads new data if reach ref element
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting && !isMomentumReachEnd) {
            setShowLoadingText(true);
            if (lastCursor !== 0 && lastCursor === returnOrders[returnOrders.length - 1]?.last_cursor) {
              //stop load when end
              setMomentumReachEndStatus(true);
              setShowLoadingText(false);
            }
            if (returnOrders && returnOrders.length > 0 && lastCursor !== returnOrders[returnOrders.length - 1]?.last_cursor) {
              //sets last cursor for pagination then load more
              setLastCursor(returnOrders[returnOrders.length - 1]?.last_cursor);
              handleLoadMore(returnOrders[returnOrders.length - 1]?.last_cursor);
            }
          }
        });
      });

      observer.observe(sentinelRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [isMomentumReachEnd, returnOrders]);

  useEffect(() => {
    //removes loading text
    setTimeout(() => {
      setShowLoadingText(false);
    }, 3000);
  }, [showLoadingText]);

  const filteredReturnsLength = returnOrders?.length ? returnOrders?.length : 0;

  const dispatch = useDispatch();

  useEffect(() => {
    if (filteredReturnsLength > 0) {
      dispatch(addReturnsTotal(filteredReturnsLength));
    }
  }, [filteredReturnsLength, dispatch]);

  const handleDateFilter = (filterId: string) => {
    const now = new Date();
    const tomorrow = moment().add(1, 'day').endOf('day');
    setSelectedEndDate(tomorrow.toDate());

    switch (filterId) {
      case 'anytime':
        setSelectedStartDate(null);
        break;
      case 'last_seven_days':
        setSelectedStartDate(new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000));
        break;
      case 'last_thirty_days':
        setSelectedStartDate(new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000));
        break;
      case 'last_three_months':
        setSelectedStartDate(new Date(now.getTime() - 3 * 30.44 * 24 * 60 * 60 * 1000));
        break;
      case 'last_six_months':
        setSelectedStartDate(new Date(now.getTime() - 6 * 30.44 * 24 * 60 * 60 * 1000));
        break;
      case 'older_one_year':
        setSelectedStartDate(new Date(now.getTime() - 365.25 * 24 * 60 * 60 * 1000));
        break;
      case '2023':
        setSelectedStartDate(new Date('2023-01-01'));
        setSelectedEndDate(new Date('2023-12-31'));
        break;
      case '2022':
        setSelectedStartDate(new Date('2022-01-01'));
        setSelectedEndDate(new Date('2022-12-31'));
        break;
      case '2021':
        setSelectedStartDate(new Date('2021-01-01'));
        setSelectedEndDate(new Date('2021-12-31'));
        break;
      case '2020':
        setSelectedStartDate(new Date('2020-01-01'));
        setSelectedEndDate(new Date('2020-12-31'));
        break;
      default:
        setSelectedStartDate(null);
    }
  };

  const shopSort = useMemo(
    () => [
      { id: '0', label: translate('wallet.all'), value: '' },
      { id: '1', label: translate('shop.cart.stepper.under_process'), value: 'open' },
      { id: '2', label: translate('shop.cart.stepper.action_required'), value: 'action_required' },
      { id: '3', label: translate('order_returnStatus_completed'), value: 'completed' },
      // { id: '4', label: translate('shop.cart.stepper.requested'), value: 'requested' },
      // { id: '5', label: translate('shop.cart.stepper.under_process'), value: 'under_process' },
      // { id: '6', label: translate('shop.cart.stepper.in_transit'), value: 'in_transit' },
      // { id: '8', label: translate('shop.cart.stepper.accepted'), value: 'accepted' },
      // { id: '9', label: translate('shop.cart.stepper.rejected2'), value: 'rejected' },
    ],
    [language],
  );

  const dateShopSort = useMemo(
    () => [
      // { id: '0', label: translate('anytime'), value: 'anytime' },
      // { id: '1', label: translate('last_thirty_days'), value: 'last_thirty_days' },
      { id: '0', label: translate('last_three_months'), value: 'last_three_months' },
      { id: '1', label: translate('last_six_months'), value: 'last_six_months' },
      { id: '2', label: '2023', value: '2023' },
      { id: '3', label: '2022', value: '2022' },
      { id: '4', label: '2021', value: '2021' },
      { id: '5', label: '2020', value: '2020' },
    ],
    [language],
  );

  const handleDateFilterChange = (filterValue: string) => {
    handleDateFilter(filterValue);
  };

  const handlePriceRangeChange = (newMinPrice: number, newMaxPrice: number) => {
    if (newMinPrice === -1 || newMaxPrice === -1) {
      setMinPrice(0);
      setMaxPrice(Infinity);
    } else {
      setMinPrice(newMinPrice);
      setMaxPrice(newMaxPrice);
    }
  };

  type Status = 'All' | 'requested' | 'under_process' | 'accepted' | 'rejected';

  useEffect(() => {
    //load when filter change; resets last cursor
    setMomentumReachEndStatus(false);
    setReturnOrders([]);
    setLastCursor(0);
    setShowLoadingText(true);
    getReturnList(0);
  }, [selectedStatus, searchQuery, selectedStartDate, selectedEndDate, sortType, minPrice, maxPrice, sortValue]);

  useEffect(() => {
    mixpanelHelper.trackPageView('MY ORDERS PAGE - RETURN TAB', 'my_orders_page_return_tab', window.location.pathname, document.referrer);
  }, []);

  return (
    <>
      <CustomHelmet
        pageTitle={`${translate(PAGE_TITLE.settings as TxKeyPath) || ''} - ${translate(PAGE_TITLE.settings_account as TxKeyPath) || ''} - ${
          translate(PAGE_TITLE.returns as TxKeyPath) || ''
        }`}
        metaDescription={translate(PAGE_DESCRIPTION.settings as TxKeyPath) || ''}
        metaKeyWords={translate(PAGE_KEYWORDS.settings as TxKeyPath) || ''}
      />
      <div className={`SD:grid SD:grid-cols-4 gap-x-[1.5rem] 4K:gap-x-[0rem]  gap-y-[0rem] SD:gap-y-[0rem]`}>
        <StyledHeaderContainer>
          {/* <div className={`flex-row justify-between flex-wrap hidden mt-[1rem] gap-y-[1rem] SD:flex 2K:gap-x-[1rem]`}>
                    <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
                    <DateDropDown label="Dropdown Label" onDateFilterChange={handleDateFilterChange} dateShopSort={dateShopSort} />
                    <PriceRangePicker
                        min={minPrice}
                        max={maxPrice}
                        onRangeChange={(min, max) => {
                            setMinPrice(min);
                            setMaxPrice(max);
                        }}
                    />
                    <StatusDropDown label="Dropdown Label" onStatusChange={setSelectedStatus} shopSort={shopSort} /> status
                </div> */}

          {/* MOBILE VIEW */}
          <div
            className={`flex flex-row justify-between SD:justify-start gap-[1rem] self-stretch mt-[1.5rem] ${isMobile ? 'mb-[2rem]' : ''}`}
          >
            <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
            <div
              className="flex flex-row gap-[12px] items-center self-stretch cursor-pointer"
              onClick={() => setShowOtherFilters(!showMoreFilters)}
            >
              <div>
                {showMoreFilters ? (
                  <ResponsiveIcon name={IconNames.close1} className="fill-sunset" baseHeight={20} baseWidth={20} />
                ) : (
                  <ResponsiveIcon name={IconNames.filterList} className="fill-sunset" baseHeight={20} baseWidth={20} />
                )}
              </div>
              <span className="font-medium text-fs-body-small text-sunset">{translate('filters')}</span>
            </div>
          </div>
          {showMoreFilters && (
            <div className={`flex flex-col mdMaxMm:flex-col XGA:flex-row items-center gap-[1rem] SD:gap-[1.5rem] my-[1rem]`}>
              <DateDropDown
                label="Dropdown Label"
                onDateFilterChange={handleDateFilterChange}
                dateShopSort={dateShopSort}
                defaultSelectedId="1"
              />
              <PriceRangePicker min={minPrice} max={maxPrice} onRangeChange={handlePriceRangeChange} />
              <StatusDropDown label="Dropdown Label" onStatusChange={setSelectedStatus} shopSort={shopSort} />
            </div>
          )}
        </StyledHeaderContainer>
        <StyledHeaderContainer className={`productLabel__head block ${isMobile ? 'hidden' : ''}`}>
          <ProductDetailsHeader
            dateTitleKey={translate('return_date')}
            totalTitleKey={translate('common_total')}
            statusTitleKey={translate('common_status')}
            productTitleKey={translate('shop.returns_page.return_preview')}
            productCountKey={translate('returns_products')}
            createdAtTitleKey={translate('common_createdAt')}
            updatedAtTitleKey={translate('common_updatedAt')}
            returnsSpan={true}
            toggleSorting={toggleSorting}
            IsDateAscending={isDateAscending}
            IsIDAscending={isIDAscending}
          />
        </StyledHeaderContainer>
        {isLoading ? (
          <span>
            <LoadingIndicator width={80} height={80} strokeWidth={2} color="#E95F2A" open={true} />
          </span>
        ) : !isLoading && returnOrders && returnOrders.length > 0 ? (
          returnOrders.map((order, id) => {
            return (
              <ReturnData
                key={id}
                order={order}
                isHeaderDark={isHeaderDark}
                showDetails={showDetails}
                language={language}
                id={getMainStatus(order.items.map(item => item.status_en)) || 0}
              />
            );
          })
        ) : (
          <p className="font-medium text-secondary text-fs-body-large">{translate('no_results_found')}</p>
        )}
        {returnOrders && returnOrders?.length > 0 && (
          <div className="flex flex-col items-center justify-center w-full col-span-4 text-secondary" ref={sentinelRef}>
            {/* {showLoadingText && (
                        <span className="text-fs-body-large font-regular">{translate('common_loading') || 'Loading'}...</span>
                    )} */}
          </div>
        )}
      </div>
    </>
  );
};

export default ReturnPage;
