import quickplay from 'quickplay-shorts-js';
import { useEffect, useState } from 'react';

import HashtagRail from '../components/HashtagRail/HashtagRail';
import Bottom from '../components/Layouts/Bottom';
import UserRail from '../components/UserRail/UserRail';
import VideoRail from '../components/VideoRail/VideoRail';
import Loader from '../components/common/Loader';
import { useLoginModalContext } from '../contexts/LoginModal';

const curatedContentModule = new quickplay.QuickPlayCuratedContentModule();

const Rail = ({ rail }: any) => {
  const rails: any = {
    video: <VideoRail key={rail.id} data={rail} />,
    user: <UserRail data={rail} />,
    hashtag: <HashtagRail key={rail.id} data={rail} />,
  };
  return rails[rail.contentType];
};

const Discover = (props: any) => {
  const [data, setData]: any = useState(null);

  const { setShowLoginModal } = useLoginModalContext();

  useEffect(() => {
    async function fetchData() {
      try {
        const curatedContentModuleResponse = await curatedContentModule.getCuratedContentList('discover');
        if (curatedContentModuleResponse.status === 200) {
          setData(curatedContentModuleResponse.data.result || []);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  if (data && data.length === 0) return null;

  if (!data) return <Loader />;

  const loginModalHandler = () => {
    setShowLoginModal(true);
  };

  return (
    <>
      <div className="full-sec">
        <div className="right-sec profile-content-list discover-top">
          {data.map((rail: any) => (
            <Rail key={rail.id} rail={rail} />
          ))}
        </div>
      </div>
      <Bottom />
    </>
  );
};

export default Discover;
