import { PAGE_DESCRIPTION, PAGE_KEYWORDS, PAGE_TITLE, TxKeyPath } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import CustomHelmet from '@fe-web/Organisms/CustomHelmet/CustomHelmet';
import { ConnectConversationProvider, useConnectProvider } from '@fe-web/hooks/useConnect';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { AppRoutes } from '../../../app.routes.enum';
import SideBarChatOverView from '../SideBarChatOverView';
import NewChat from '../components/NewChat';
import EmptyMessage from './components/EmptyMessage';

const FullViewEmpty = () => {
  const { closeAllChat } = useConnectProvider();
  const [isInviteUser, setIsInviteUser] = useState<boolean>(false);
  const { conversationId, conversationType } = useParams();
  const convoType = conversationType && parseInt(conversationType);
  const navigate = useNavigate();
  const { translate } = useTranslate();
  const onClickNewChat = () => {
    navigate(AppRoutes.newMessage);
  };

  const onCloseInvite = () => {
    setIsInviteUser(!isInviteUser);
  };

  useEffect(() => {
    closeAllChat && closeAllChat();
  }, []);

  return (
    <>
      <CustomHelmet
        pageTitle={translate(PAGE_TITLE.connect as TxKeyPath) || ''}
        metaDescription={translate(PAGE_DESCRIPTION.connect as TxKeyPath) || ''}
        metaKeyWords={translate(PAGE_KEYWORDS.connect as TxKeyPath) || ''}
      />
      <div className="w-full px-20 md:px-64 lg:px-120 2K:px-160 4K:px-320 8K:px-655">
        <div className="grid grid-cols-5 HD:grid-cols-4 h-full gap-[1rem]">
          <div className="col-span-2 HD:col-span-1">
            <SideBarChatOverView
              isFullView
              showFullViewIcon={false}
              fixedPosition={false}
              onClickNewChat={onClickNewChat}
              setIsInviteUser={setIsInviteUser}
            />
          </div>
          <div className="col-span-3 HD:col-span-3 pt-[1.25rem] h-full pb-[2.5rem]">
            {isInviteUser ? (
              <ConnectConversationProvider conversationID={conversationId} conversationType={convoType || 0}>
                <div className="border rounded-[.5rem] border-secondary/25 h-full">
                  <NewChat isFullView isInviteUsers={isInviteUser} onCloseInvite={onCloseInvite} />
                </div>
              </ConnectConversationProvider>
            ) : (
              <EmptyMessage />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FullViewEmpty;
