import { LottieIconAnimations } from '@fe-monorepo/assets';
import Lottie from 'lottie-react';
import { Oval } from 'react-loader-spinner';

interface LoaderIconAnimatedProps {
  variant?: 'primary' | 'secondary';
  size?: {
    width: number;
    height: number;
  };
}
const LoaderIconAnimated = ({ variant, size }: LoaderIconAnimatedProps) => {
  return variant === 'secondary' ? (
    <div className="w-full h-full grid items-center justify-center">
      <div className="w-full inline-flex justify-center">
        <Oval
          height={size?.width || 40}
          width={size?.height || 40}
          color="#E95F2A"
          secondaryColor="#E95F2A"
          visible={true}
          ariaLabel="oval-loading"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      </div>
    </div>
  ) : (
    <Lottie animationData={LottieIconAnimations.Icon_Loader} loop className="h-[10rem] w-[30rem]" />
  );
};

export default LoaderIconAnimated;
