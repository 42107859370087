import { RootState } from "@fe-monorepo/store";
import quickplay from 'quickplay-shorts-js';
import { useEffect } from "react";
import { useSelector } from "react-redux";

export default function useAutoLogin() {
    const token = useSelector((state: RootState) => state.user.userContext.token);
    const userData = localStorage.getItem('userData');
    useEffect(() => {
        const loginBitsSdk = async () => {
            if (!token) {
                localStorage.removeItem("userData");
                localStorage.removeItem("userDetails");
                return;
            }
            if (!userData) {

                const formData = {
                    accessToken: token,
                    env: 'dev',
                };

                const userModuleObj = new quickplay.QuickPlayAuthModule();
                const response = await userModuleObj.clientSignIn(formData);
                if (response.status == 200) {
                    if (response.data.statusCode == 2001) {
                        const userModule = new quickplay.QuickPlayUserModule();
                        const userConfigResponse = await userModule.getUserMetaIds('');
                        if (userConfigResponse.status == 200 || userConfigResponse.status == 201) {
                            const following = userConfigResponse.data.result.following || [];
                            localStorage.setItem('following', JSON.stringify(following));
                        }
                    } else {
                        console.error({ loginRespError: response.data.statusMessage });
                    }
                } else {
                    console.error({ loginRespError: response.data.statusMessage });
                }
            } else {
                const userModule = new quickplay.QuickPlayUserModule();
                const userConfigResponse = await userModule.getUserMetaIds('');
                if (userConfigResponse.status == 200 || userConfigResponse.status == 201) {
                    const following = userConfigResponse.data.result.following || [];
                    localStorage.setItem('following', JSON.stringify(following));
                }
            }
        };

        loginBitsSdk();
    }, [token]);

    return {
        token
    };
}











