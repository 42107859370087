import useOnlineDetector from '@fe-web/hooks/useOnlineDetector';
import { ReactElement, useContext } from 'react';
import { PageErrorTypes } from '@fe-monorepo/store';
import { AppContext } from '../../app';
import InternalServerErrorPage from './InternalServerErrorPage';
import MaintenancePage from './MaintenancePage';
import NotFoundPage from './NotFoundPage';
import NoInternetPage from './NoInternetPage';
import ServerDownPage from './ServerDownPage';

interface ErrorPageWrapperProps {
    children: ReactElement;
}

const ErrorPageWrapper = ({ children }: ErrorPageWrapperProps) => {
    const { pageError } = useContext(AppContext);
    const isOnline = useOnlineDetector();

    switch (pageError) {
        case PageErrorTypes.OFFLINE:
            return <NoInternetPage />;
        case PageErrorTypes.NOT_FOUND:
            return <NotFoundPage />;
        case PageErrorTypes.SERVER_ERROR:
            return <InternalServerErrorPage />;
        case PageErrorTypes.MAINTENANCE:
            return <MaintenancePage />;
        case PageErrorTypes.RELOAD_REQUIRED:
            return <ServerDownPage />;
        case PageErrorTypes.SERVER_DOWN:
            return <ServerDownPage />;
        default:
            return !isOnline ? <NoInternetPage /> : children;
    }
};

export default ErrorPageWrapper;
