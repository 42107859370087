import { gql } from '@apollo/client';

export const FEATURE_FLAG_QUERY = {
    getFeatureFlags: gql`
        query fetchFeatureFlags {
            getFeatureFlags {
                error_msg
                error_code
                is_successful
                data {
                    key
                    value
                }
            }
        }
    `,
};
