import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

const ProductDescription = (props: { className?: string; description: string }) => {
  const htmlDescription = DOMPurify.sanitize(props.description);
  return (
    <p
      className={`
        ${props.className}
        font-regular
        sm:text-body 4xl:text-subtitle 5xl:text-fourKSubtitle 8xl:text-eightKSubtitle
        text-secondary/70
      `}
    >
      {parse(htmlDescription)}
    </p>
  );
};

export default ProductDescription;
