import { EditFormFunction, ValidationRule } from '@fe-monorepo/forms';

import { ValidationParameters, useInputValidation } from '@fe-monorepo/forms';
import { useTranslation } from 'react-i18next';
import CustomDropdownField from './Components/CustomDropdownField';
import { useEffect, useState } from 'react';
import { authInputFieldStyleObj } from '../../../../../app/pages/AuthenticationScreen/commonStyles';
import { RootState } from '@fe-monorepo/store';
import { useSelector } from 'react-redux';

const DISTRICT_MOCKDATA = [
    {
      district_id: 1,
      name: 'Al-Bat\'ha'
    },
    {
      district_id: 2,
      name: 'Al-\'Olayya'
    },
    {
      district_id: 3,
      name: 'Nemar'
    },
    {
      district_id: 4,
      name: 'Irqah'
    },
    {
      district_id: 5,
      name: 'Al-Shemaysi'
    },
    {
      district_id: 6,
      name: 'Al-Ma\'athar'
    },
    {
      district_id: 7,
      name: 'Al-Ha\'ir'
    }
];

type Props =
{
  apiErrorMessage?: string;
  label?: string;
  containerStyle?: string;

  onDistrictDropdownChange: (e: any) => void;
  resetAPIError?: () => void;
  onClickEnter?: () => void,
}

const DistrictDropdownField = (props: Props) =>
{
    const { t } = useTranslation();

    const prefs = useSelector((state: RootState) => state.app);
    const [districtObj, setDistrictObj] = useState<any>({})
    const [validationRules, setValidationRules] = useState<ValidationRule[]>([])
    const [defaultCountry, setDefaultCountry] = useState({})
    const [districtList, setDistrictList] = useState<any[]>([])
    const districtData = DISTRICT_MOCKDATA;

    // const validationParameters: ValidationParameters =
    // {
    //   inputFieldKey: "districtName",
    //   isOptional: true,
    //   validationRules: validationRules,
    //   apiErrorMessage: props.apiErrorMessage,
    //   resetAPIError: props.resetAPIError,
    //   onChange: props.onDistrictChange,
    //   dependencies: [districtObj?.dropDownValue?.name, validationRules]
    // }

    // const { errorMessage, setValue } = useInputValidation(validationParameters);

    const search = (searchKey: string) =>
    {
        return districtList?.filter(district =>
        {
          if (!(
                  "name" in district
              ))
          {
            return false;
          }

          return district["name"]?.toLowerCase().includes(searchKey.toLocaleLowerCase());
        })
    }

    const getStringValue = (arrayElement: any) =>
    {
      if (arrayElement)
      {

        return (`${arrayElement["name"]}`).trim();
      }
      else
      {
        return ""
      }
    }

    const getSearchValue = (arrayElement: any) =>
    {
      if (arrayElement)
      {
        return ""+(arrayElement?.name? arrayElement?.name: "").replace(/(\r\n|\n|\r)/gm," ")
      }
      else
      {
        return ""
      }
    }

    useEffect(() =>
    {
      if (districtData && districtData?.length > 0)
      {
        const filterKeys = ["name"];

        const filteredList = districtData?.filter(district =>
        {
            for (let i = 0; i < filterKeys.length; i++) {
              const key = filterKeys[i];

              if (!(key in district))
              {
                return false;
              }
            }

          return true;
        }).map(district =>
        {
          const newCountry =
          {
            id:  district.district_id,
            ...district
          }

          return newCountry
        })


        const defaultCount = "";
        setDefaultCountry(defaultCount)
        setDistrictList(filteredList)
      }
    }, [districtData])

    useEffect(() =>
    {
      const countryCode = districtObj?.dropDownValue?.name as string;

      if (countryCode !== "")
      {
        props.onDistrictDropdownChange(districtObj?.dropDownValue);
      }
    }, [districtObj, prefs.language])

    return (
      <CustomDropdownField
          dir="ltr"

          style={{
                ...authInputFieldStyleObj,
                containerStyle: props.containerStyle,
            }}

          label={props.label? props.label:t("formPlaceholder_mobile")}
          inputType="text"
          // errorMessage={errorMessage}
          errorMessage=''
          dropDown=
          {{
            default: defaultCountry,
            list: districtList,

            search: search,
            getSearchValue: getSearchValue,
            getStringValue: getStringValue
          }}

          retrieveValue={setDistrictObj}
          onClickEnter={props.onClickEnter}
      />
    );
};

export default DistrictDropdownField;
