import Checkbox from '@fe-web/Molecules/InputFields/Checkbox';
import { NotificationFilterType } from '@fe-web/types/notificationFilterTypes';
import { useEffect, useState } from 'react';
import { t } from 'i18next';

interface Props {
    filterOptions: NotificationFilterType[];
    selectedFilterOptions: NotificationFilterType[];
    header: string;
    selectedOptions: (options: NotificationFilterType[]) => void;
}

const FilterOptions = ({ filterOptions, selectedFilterOptions, header, selectedOptions }: Props) => {
    const [isChecked, setIsChecked] = useState(new Array(filterOptions?.length).fill(false));

    const handleCheckboxClick = (index: number) => {
        const newIsChecked = [...isChecked];
        newIsChecked[index] = !newIsChecked[index];
        setIsChecked(newIsChecked);

        const selected = filterOptions.filter((_, i) => newIsChecked[i]);
        selectedOptions(selected);
    };

    useEffect(() => {
        const updatedIsChecked = filterOptions.map(option => selectedFilterOptions.includes(option));
            setIsChecked(updatedIsChecked);
    }, [selectedFilterOptions]);
    
    return (
        <div className="flex flex-col gap-[1rem]">
            <h2 className="font-medium text-fs-body-small text-secondary">{header}</h2>
            {filterOptions.map((data, i) => {
                return (
                    <div key={data.id} className="flex flex-row gap-[1rem]">
                        <Checkbox
                            isChecked={selectedFilterOptions.includes(data)}
                            classNames="!w-[1.125rem] h-[1.125rem] accent-sunset"
                            disabled={false}
                            onClick={() => handleCheckboxClick(i)}
                            width={selectedFilterOptions.includes(data) ? 1.3 : 1.125}
                            height={selectedFilterOptions.includes(data) ? 1.3 : 1.125}
                            defaultClassName="mr-2 flex items-center justify-center"
                            imageWidth="1.125rem"
                            imageHeight="1.125rem"
                            boxBorderStyle="border-transparent"
                        />
                        <p
                            className={`font-regular ${
                                selectedFilterOptions.includes(data) ? 'text-secondary' : 'text-zinc-600-400'
                            } text-fs-body-small cursor-pointer`}
                            onClick={() => handleCheckboxClick(i)}
                        >
                            {t(data.title)}
                        </p>
                    </div>
                );
            })}
        </div>
    );
};

export default FilterOptions;
