import { IconNames } from '@fe-monorepo/helper';
import { BannerItemsData } from '@fe-monorepo/models';
import { RootState } from '@fe-monorepo/store';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import ResponsiveIcon from '../../../components/Atoms/Icon/ResponsiveIcon';
import HoverText from '../../../components/Atoms/Text/HoverText';
import Container from '../../../components/Templates/Container';
import useSlidingWindow from '../../../hooks/useSlidingWindow';
import SliderCard from './SliderCard';

interface SliderProps {
  isTransparent?: boolean;
  list: BannerItemsData[];
  language: 'en' | 'ar';
}
const Slider = (props: SliderProps) => {
  const translate = useTranslation();
  const language = translate.i18n.language;
  const [currentLanguage, setCurrentLanguage] = useState(language);
  const {
    processedList: list,
    shiftLeft,
    shiftRight,
  } = useSlidingWindow(props.list, props.list.length > 3 ? 3 : props.list.length, 0, false);
  const prefs = useSelector((state: RootState) => state?.app);
  const dir = prefs?.language === 'en' ? `ltr` : `rtl`;

  const chevronClassNames = `absolute hidden md:flex z-[1] ${
    list.length > 1 ? 'fill-secondary/70 cursor-pointer' : 'fill-secondary/40 cursor-not-allowed'
  }  w-fit`;
  const isArrowDisabled = list.length < 2;

  const goPrevious = () => {
    if (dir === 'ltr') {
      shiftLeft();
    } else {
      shiftRight();
    }
  };

  const goNext = () => {
    if (dir === 'ltr') {
      shiftRight();
    } else {
      shiftLeft();
    }
  };

  useEffect(() => {
    setCurrentLanguage(language);
  }, [language]);

  // Other hooks and functions...

  const handleNavItemClick = (identifier: string) => {
    const targetIndex = list.findIndex(item => item.name_en === identifier); // Find index based on identifier
    if (targetIndex !== -1) {
      const middleIndex = Math.floor(list.length / 2);
      const distanceFromMiddle = targetIndex - middleIndex;

      if (distanceFromMiddle > 0) {
        for (let i = 0; i < distanceFromMiddle; i++) {
          goNext(); // Shift the carousel right
        }
      } else if (distanceFromMiddle < 0) {
        for (let i = 0; i < Math.abs(distanceFromMiddle); i++) {
          goPrevious(); // Shift the carousel left
        }
      }
    }
  };

  return (
    <div className="flex flex-col gap-y-[0.5rem]">
      <Container className={`flex ${props?.isTransparent ? 'bg-transparent' : 'bg-primary'}`}>
        <div
          className="
            relative
            flex items-center
            w-full
            h-[18.75rem]
          "
        >
          <button onClick={goPrevious} disabled={isArrowDisabled}>
            <ResponsiveIcon
              className={`-left-[2.5rem] rotate-180 ${chevronClassNames}`}
              name={IconNames.chevronRight}
              baseWidth={20}
              baseHeight={20}
            />
          </button>

          <motion.ul
            layout
            // dir="ltr"
            className="
                  list-none
                  h-full w-full

                  flex sm:justify-center gap-[2%]
                  overflow-x-scroll sm:overflow-x-hidden
                  snap-x snap-mandatory sm:snap-none
                "
          >
            {list.map((item, index) => {
              const distanceFromMiddle = Math.floor(list.length / 2) - index;
              return (
                <SliderCard
                  key={index}
                  data={item}
                  absoluteDistanceFromMiddle={Math.abs(distanceFromMiddle)}
                  shiftCard={() => {
                    if (distanceFromMiddle === 1) {
                      shiftLeft();
                    } else if (distanceFromMiddle === -1) {
                      shiftRight();
                    }
                  }}
                  language={props.language}
                />
              );
            })}
          </motion.ul>

          <button disabled={isArrowDisabled} onClick={goNext}>
            <ResponsiveIcon
              className={`-right-[2.5rem] ${chevronClassNames}`}
              name={IconNames.chevronRight}
              baseWidth={20}
              baseHeight={20}
            />
          </button>
        </div>
      </Container>
      <Container className="flex items-center justify-center px-0 bg-primary md:flex">
        <div className="flex items-center justify-center h-full">
          <nav className={`md:gap-[1.875rem] flex mt-0 SD:mt-[0.5rem]`}>
            {list.map((item, index) => {
              const distanceFromMiddle = Math.floor(list.length / 2) - index;
              const isMiddleItem = distanceFromMiddle === 0;
              return (
                <Link
                  key={index}
                  to=""
                  className={`group flex flex-col mdMaxxSD:w-[11.25rem] ${
                    isMiddleItem ? 'text-secondary font-medium' : ' text-secondary/50 '
                  } p-0 my-0 SD:mx-[2rem] SD:px-[2rem] items-center justify-center hover:cursor-pointer font-regular`}
                >
                  <HoverText
                    className={`
                                        font-regular
                                        text-fs-body
                                        ${isMiddleItem ? 'text-secondary font-medium' : 'text-secondary/50'}
                                        `}
                    hover={{ color: '' }}
                    alwaysShowUnderline={true}
                    underlineStyle={`${
                      isMiddleItem ? 'text-secondary z-1 static bg-secondary top-[0.625rem] h-[0.125rem]' : 'text-secondary/50'
                    }`}
                    text={item[`name_${props.language}`]}
                    onClick={() => {
                      handleNavItemClick(item.name_en);
                    }}
                  />
                </Link>
              );
            })}
          </nav>
        </div>
      </Container>
    </div>
  );
};

export default Slider;
