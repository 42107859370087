import { MixPanelEvents } from '@fe-monorepo/helper';
import { useUserProfile } from '@fe-monorepo/hooks';
import { BannerItemsData } from '@fe-monorepo/models';
import { RootState } from '@fe-monorepo/store';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import useGetCurrentBreakPoint from '@fe-web/hooks/useGetCurrentBreakPoint/useGetCurrentBreakPoint';
import { getURLForRedirection } from 'apps/fe-web/src/lib/helper';
import { motion } from 'framer-motion';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

interface CardBodyProps {
  name: string;
}

const CardBody = (props: CardBodyProps) => {
  return (
    <section
      className="
        relative
        h-full w-full
        font-medium
        flex
        flex-col sm:flex-row
        sm:items-end
        justify-end sm:justify-between
        p-12 sm:p-24 4xl:p-[42.66px] 5xl:p-[90px] 8xl:p-180

        gap-16 sm:gap-0
        "
    >
      <p
        className="
          text-white100
          text-bodyLarge sm:text-title 4xl:text-mobileDynamic 5xl:text-LPTitle 8xl:text-fiveKDynamic
          sm:w-[62%]
      "
      >
        {props.name}
      </p>
    </section>
  );
};

const SliderCard = (props: { data: BannerItemsData; absoluteDistanceFromMiddle: number; shiftCard: () => void; language: 'en' | 'ar' }) => {
  const { currentBreakPoint } = useGetCurrentBreakPoint();

  const { data, absoluteDistanceFromMiddle: distanceFromMiddle, shiftCard, language } = props;

  const prefs = useSelector((state: RootState) => state?.app);
  const dir = prefs?.language === 'en' ? `ltr` : `rtl`;

  const isMiddle = distanceFromMiddle === 0;
  const isMobile = currentBreakPoint === 'mobile';
  const navigate = useNavigate();
  const { user, isLoggedIn } = useUserProfile();

  const handleClickOnBanner = () => {
    const { url, options } = getURLForRedirection(data.reference_type, data.reference_value, isLoggedIn, user);

    mixpanelHelper.trackEvent(MixPanelEvents.bannersButtonCTA, {
      'CTA Name': `Hero Banner - ${data.name_en}`,
      Referrer: window.location.href,
      'CTA Source': 'Shop Page',
      Username: isLoggedIn ? user?.username : 'Guest',
    });

    if (url) {
      navigate(url, options);
    }
  };

  const cardWidth = isMobile
    ? distanceFromMiddle === 0
      ? '100%'
      : '0%'
    : distanceFromMiddle === 0
    ? '76%'
    : distanceFromMiddle === 1
    ? '6.66%'
    : '1%';
  return (
    <motion.li
      key={dir}
      layout="position"
      className={`
            relative
            h-[18.75rem]
            flex flex-col
            gap-16
            snap-center
            flex-none
            ${(distanceFromMiddle === 0 || isMobile) && data.reference_type && 'cursor-pointer'}
            ${isMobile && distanceFromMiddle != 0 ? 'hidden' : ''}
        `}
      initial={{ width: cardWidth }}
      animate={{ width: cardWidth }}
      transition={{ duration: 0.5 }}
      onClick={data.reference_type ? handleClickOnBanner : undefined}
    >
      <section
        className="
                relative
                w-full
                h-[18.75rem] sm:h-full
                rounded-[8px] 4xl:rounded-[14.22px] 5xl:rounded-[20px] 8xl:rounded-[43px]
                overflow-hidden
          "
      >
        {/* TODO: figure out different image sizes for different breakpoints */}
        <LazyLoadImage
          className={`z-0 absolute h-full w-full select-none object-cover ${!isMiddle ? 'sm:grayscale' : ''}`}
          src={isMobile ? data[`mobile_media_url_${language}`] : data[`web_media_url_${language}`]}
          title={data[`name_${language}`]}
          alt={data[`name_${language}`].replace(/\s/g, '_')}
        />

        <motion.div
          layout
          className={`
                z-0
                absolute
                bottom-0
                h-full w-full
                  ${
                    // OLD SLIDER CARD GRADIENT CSS
                    // isMobile
                    //   ? 'bg-gradient-to-t from-purple via-[#1F0439]/[0.50] to-[#000000]/[0.0]'
                    //   : isMiddle
                    //   ? 'sm:bg-gradient-to-t sm:from-purple sm:via-[#1F0439]/[0.50] sm:to-[#000000]/[0.0]'
                    //   : 'sm:bg-black/80'
                    // NEW SLIDER CARD CSS FOR NOT IN MIDDLE IMAGES ONLY
                    !isMiddle && 'sm:bg-black/80'
                  }
                `}
        />

        <section
          className={`${isMiddle ? 'flex' : ' flex sm:hidden'} absolute flex-col justify-between h-full w-full z-[10] ${
            data.reference_type && 'cursor-pointer'
          }`}
        >
          {/* SHOP BANNER SLIDER CARD TITLE */}
          {/* <CardBody name={data[`name_${language}`]} /> */}
        </section>
      </section>
    </motion.li>
  );
};

export default SliderCard;
