import { useEffect } from "react";
import Container from "../../../components/Templates/Container";
import mixpanelHelper from "../../../helpers/mixpanelHelper";
import CountryDropDown from "../ShopCountryDropDown";
import Content from "./Components/Content";



const ShopListing = () => {
  useEffect(() => {
    mixpanelHelper.trackPageView('SHOP - PRODUCT LISTING PAGE', 'shop_plp', window.location.pathname, document.referrer);
  }, [])
  return (
    <>
      <CountryDropDown classNames='z-[3] mt-[2rem]' />
      <div className="mt-[1.5rem] mb-40 SD:mb-[7.5rem]">
        <Container className="flex justify-between">
          {/* <Filter /> */}

          <Content />
        </Container>
      </div>
    </>
  );
}

export default ShopListing
