import Button from '@fe-web/Atoms/Buttons/Button';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import { IconNames } from '@fe-monorepo/helper';
import { ReactNode } from 'react';

interface Props {
    children?: ReactNode;
    description?: string;
    caption?: string;
}

const EmptySection = ({ children, description, caption }: Props) => {
    return (
        <div className={`w-full flex-col justify-start items-center inline-flex my-[2.5rem]`}>
            <div className={`w-[7.5rem] h-[7.5rem] relative bg-zinc-100-neutral-800 rounded-full mb-[2.5rem]`}>
                <div className={`left-[1.7306rem] top-[1.7306rem] absolute`}>
                    <ResponsiveIcon name={IconNames.iconsportsesports} baseWidth={64.3} baseHeight={64.3} />
                </div>
            </div>
            <div className={`flex-col justify-start items-center flex mb-[2.5rem]`}>
                <div className={`text-center text-secondary text-fs-subtitle font-medium mb-[0.5rem]`}>{description}</div>
                <div className={`text-center text-zinc-600-400 text-fs-body-small font-regular`}>{caption}</div>
            </div>
            {children}
        </div>
    );
};

export default EmptySection;
