import { useTranslate } from '@fe-monorepo/hooks';
import quickplay from 'quickplay-shorts-js';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Loader from '../common/Loader';
import './LeaderBoard.css';

const challengeModule = new quickplay.QuickPlayChallenges();

function LeaderBoard() {
  const [leaderboard, setLeaderboard] = useState([]);
  const { challengeId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const { translate } = useTranslate();

  useEffect(() => {
    async function fetchLeaderBoardData() {
      setIsLoading(true);
      try {
        const leaderboardResponse = await challengeModule.getLeaderBoard({
          hashtagId: challengeId,
        });
        if (leaderboardResponse?.status === 200) {
          setLeaderboard(leaderboardResponse.data.result);
          console.log('leaderboard', leaderboardResponse.data.result);
        }
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    }
    fetchLeaderBoardData();
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      <section className="main-section">
        {/* Section Title Start */}
        <div className="section-head">
          <div className="section-head-title">
            <h2>{translate('quickplay.label-leaderboard')}</h2>
          </div>
        </div>
        {/* Section Title End */}

        {/* LeaderBoard List - Start */}
        {leaderboard?.length > 0 && (
          <div className="main-leaderboard">
            <ul className="leaderboard-list">
              {leaderboard?.map((leaderboardItem: any, index: number) => {
                return (
                  <li className="leaderboard-list-item">
                    <div className="leaderboard-user">
                      <div className="leaderboard-user-rank">{index + 1}</div>
                      <img src={leaderboardItem?.user?.profileImageUrl} alt="" width="40" height="40" className="rounded" />
                      <h4>{leaderboardItem?.user?.userId}</h4>
                    </div>
                    <div className="leaderboard-thumbnail">
                      <img src={leaderboardItem?.thumbnailUrl} alt="" width="40" height="40" />
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}

        {leaderboard?.length === 0 && <p>{translate('quickplay.label-no-result-found')}</p>}

        {/* LeaderBoard List - End */}
      </section>
    </>
  );
}

export default LeaderBoard;
