import { IconNames } from '@fe-monorepo/helper';

import useMobileDetect from '../../../../hooks/useMobileDetect';
import useToggleSearch from '../../../../hooks/useToggleSeach';
import ResponsiveIcon from '../../../Atoms/Icon/ResponsiveIcon';
import SearchInput from './SearchInput';

const Search = () => {
  const isMobile = useMobileDetect();
  const { showSearchInput, setShowSearchInput } = useToggleSearch();

  return (
    <>
      {!isMobile && (
        <div className={` h-full flex ${showSearchInput ? 'flex-1' : 'items-center'}`}>
          {showSearchInput && <SearchInput />}

          {!showSearchInput && (
            <ResponsiveIcon
              className={`
                            relative
                            cursor-pointer
                            fill-secondary/50
                            hover:fill-secondary/100
                          `}
              name={IconNames.search1}
              baseWidth={24}
              baseHeight={24}
              onClick={() => setShowSearchInput()}
            />
          )}
        </div>
      )}

      {isMobile && (
        <ResponsiveIcon
          className={`cursor-pointer relative fill-secondary/70`}
          name={IconNames.search1}
          baseWidth={20}
          baseHeight={20}
          onClick={() => setShowSearchInput()}
        />
      )}
    </>
  );
};

export default Search;
