import { gql } from '@apollo/client';

export const OPENREG_MUTATION = {
    openRegTournament: gql`
        mutation openRegTournament($details: OpenRegInput) {
            openRegTournament(details: $details) {
                is_successful
                error_msg
                error_code
            }
        }
    `,
};
