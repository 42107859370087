import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export enum theme {
  dark = 'dark',
  light = 'defaultTheme',
}

const useTheme = () => {
  const location = useLocation();
  const setTheme = (value: theme) => {
    window.localStorage.setItem('theme', value);
    window.document.body.className = '';
    if (
      (location.pathname.includes('shop') && !location.pathname.includes('wishlist') && !location.pathname.includes('shopping')) ||
      location.pathname.includes('payment')
    ) {
      window.document.body.classList.add('light');
    } else {
      window.document.body.classList.add(value);
    }
  };
  useEffect(() => {
    setTheme((window.localStorage.getItem('theme') as theme) ?? theme.light);
  }, []);
  return {
    setTheme,
    theme: (window.localStorage.getItem('theme') as theme) ?? theme.light,
    isDarkTheme: (window.localStorage.getItem('theme') as theme) === theme.dark,
  };
};

export default useTheme;
