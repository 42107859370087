import { gql } from '@apollo/client';

export const CHECKIN_MUTATION = {
    checkInTournament: gql`
        mutation checkInTournament($details: CheckInInput) {
            checkInTournament(details: $details) {
                is_successful
                error_msg
                error_code
                app_code
            }
        }
    `,
};
