interface UnderlineProps
{
  isFocused: boolean;
  hideFooter?: boolean;
  errorMessage?: string;
  showCounter?: boolean;

  underlineStyle?: string;
  errorStyle?: string;
}

const Underline = (props: UnderlineProps) =>
{
  const shouldShowError =
      props?.errorMessage
    &&
      !props.isFocused

  return (
    <>
      <div className={`w-full ${shouldShowError ? 'bg-red' : props.isFocused ? 'bg-sunset' : 'bg-gray-200-zinc-700'} ${props?.underlineStyle}`} />
      {
        !props.hideFooter && shouldShowError &&
        <div className= {`mt-4 2K:mt-8 4K:mt-12 8K:mt-22 h-20 4xl:h-36 5xl:h-54 8xl:h-100`}>
            <p className={`
                  text-fs-body-small
                  font-regular text-red ${props.errorStyle}`}
            >
              {props.errorMessage}
            </p>
        </div>
      }
    </>
  );
};

export default Underline
