interface Props {
  notificationIcon?: React.ReactNode;
  content?: React.ReactNode;
  endContent?: React.ReactNode;
  handleShowMore?: (show: boolean) => void;
  mousePointerAndRedirectEnabled?: boolean;
  markAsReadAndRedirect?: () => void;
}

// used by notificationCard.tsx to display the notification where the contents of are passed as props
const NotificationContent = ({
  notificationIcon,
  content,
  endContent,
  handleShowMore,
  mousePointerAndRedirectEnabled,
  markAsReadAndRedirect,
}: Props) => {
  return (
    <div
      className={`w-full flex items-center justify-between gap-[0.5rem]`}
      onMouseEnter={() => handleShowMore?.(true)}
      onMouseLeave={() => handleShowMore?.(false)}
    >
      <div
        onClick={() => mousePointerAndRedirectEnabled && markAsReadAndRedirect && markAsReadAndRedirect()}
        className={`w-full flex items-center justify-start gap-[0.5rem] ${mousePointerAndRedirectEnabled && 'cursor-pointer'}`}
      >
        <div className="w-fit self-start">{notificationIcon}</div>
        <div className={`inline-flex flex-col self-start`}>{content}</div>
      </div>
      {endContent}
    </div>
  );
};

export default NotificationContent;
