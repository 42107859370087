import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { JOIN_MUTATION } from '@fe-monorepo/data-access';
import { JoinTournamentParamsModel, JoinTournamentParamsModelV2 } from './type';
import { useMutateQuery } from '../useMutateQuery';

export const useJoinTournament = () => {
    const [isSuccessful, setStatus] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [errorMsg, setErrorMsg] = useState<string>('');

    const [joinTournament, { data: joinTournamentResponse, error: errorJoinTournament }] = useMutation(JOIN_MUTATION.joinTournament, {
        errorPolicy: 'all',
    });

    useEffect(() => {
        if (joinTournamentResponse) {
            if (joinTournamentResponse.joinTournament?.is_successful) {
                setStatus(joinTournamentResponse.joinTournament?.is_successful);
            }
            if (joinTournamentResponse.joinTournament?.error_msg) {
                setErrorMsg(joinTournamentResponse.joinTournament?.error_msg);
            }
            setStatus(joinTournamentResponse.joinTournament?.is_successful);
        }
        setIsLoading(false);
    }, [joinTournamentResponse]);

    useEffect(() => {
        if (errorJoinTournament) {
            setError(errorJoinTournament.message);
        }
        setIsLoading(false);
    }, [errorJoinTournament]);

    const registerTournament = async (params: JoinTournamentParamsModelV2) => {
        setIsLoading(true);
        const { data, errors } = await joinTournament({ variables: { details: params } });
        setIsLoading(false);
        return { data, errors };
    };

    return {
        registerTournament,
        isSuccessful,
        isLoading,
        error,
        errorMsg,
    };
};