import { gql } from '@apollo/client';

export const COMMUNITY_BLOCK_QUERIES = {
    getListOfBlockedUsers: gql`
        query getListOfBlockedUsers($details: GetBlockedUsersInput) {
            getListOfBlockedUsers(details: $details) {
                is_successful
                error_msg
                error_code
                data {
                    username
                    last_cursor
                    display_name
                    avatar_url
                    is_official_account
                }
            }
        }
    `,
};
