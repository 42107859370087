/*Patterned from Dropdownlist.tsx */

const DropDownText = (props: { isSelected: boolean; text: string; selectedIconPosition: 'start' | 'end' }) => {
    return (
        <p
            className={`
        px-16 2K:px-[28px] 4K:px-40 8K:px-81
        whitespace-nowrap
        text-body 2K:text-title 4K:text-fourKSubtitle 8K:text-eightKSubtitle
        py-8 2K:py-12 4K:py-20 8K:py-44
        font-regular

        ${
            props.isSelected && props.selectedIconPosition === 'start'
                ? `ps-16 2K:ps-[14.22px] 4K:ps-[37.16px] 8K:ps-[42.66px]`
                : `ps-16 2K:ps-[28px] 4K:ps-40 8K:ps-81`
        }

        pe-16 2K:pe-[28px] 4K:pe-40 8K:pe-81
      `}
        >
            {props.text}
        </p>
    );
};

// TODO: change 'any' to T
interface Props<T> {
    currentValue?: T;
    options: T[];
    selectedIconPosition: 'start' | 'end';

    selectValue: (option: T) => void;
    getStringValue: (option: T) => string;
}

const CustomDropDownList = <T extends { id: string | number }>(props: Props<T>) => {
    const { currentValue, options, selectValue, getStringValue, selectedIconPosition } = props;

    return (
        <div
            className={`
          bg-primary
          overflow-y-scroll
          mt-[1.25rem]
          max-h-[144px] 4xl:max-h-[256px] 5xl:max-h-[384px] 8xl:max-h-[768px]
          border border-secondary/20
          border-[1px] 2K:border-[1.77px] 4K:border-[2.66px] 8K:border-[5.33px]
          rounded-[4px] 2K:rounded-[7.11px] 4K:rounded-[10.66px] 8K:rounded-[21.33px]
          w-[23.9375rem]
        `}
        >
            {options?.map((option, index) => {
                const isSelected = option.id === currentValue?.id;

                if (isSelected) {
                    return (
                        <div
                            className={`
                relative
                flex items-center
                text-sunset
                pointer-normal cursor-default
                w-full
              `}
                        >
                            {/* { selectedIconPosition === "start" && selectedIcon } */}
                            <DropDownText
                                isSelected={true}
                                selectedIconPosition={selectedIconPosition}
                                text={getStringValue(currentValue)}
                            />
                            {/* { selectedIconPosition === "end" && selectedIcon } */}
                        </div>
                    );
                } else {
                    return (
                        <div
                            key={index}
                            className="text-secondary flex items-center hover:bg-sunset/[0.1] hover:text-sunset cursor-pointer w-full"
                            onClick={() => selectValue(option)}
                        >
                            <DropDownText isSelected={false} selectedIconPosition={selectedIconPosition} text={getStringValue(option)} />
                        </div>
                    );
                }
            })}
        </div>
    );
};

export default CustomDropDownList;
