import { Platform } from 'react-native';



import { replaceString } from './function';


const environment = process.env.NX_APP_ENVIRONMENT ? process.env.NX_APP_ENVIRONMENT : 'production';

export interface STCConfig {
  bffBaseURL: string;
  websiteBaseURL: string;
  websiteURL: string;
  environment: string;
  apiWebKey: string;
  apiAndroidKey: string;
  apiIOSKey: string;
  firebaseConfig: FirebaseConfig;
  mixPanelToken: string;
  convivaAppTracker: {
    appname: string;
    customerKey: string;
  };
  tournamentsURL: string;
  quickplayAPIKey: string;
  quickplaySecretKey: string;
  quickplayBaseURL?: string;
}

export interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId?: string;
  vapidKey?: string;
}

export const firebaseIOSConfig: FirebaseConfig = {
  apiKey: 'AIzaSyCruNScWozL8c56MmExmqpgikFeTl7m2bs',
  authDomain: 'xplay-dev.firebaseapp.com',
  databaseURL: 'https://xplay-dev-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'xplay-dev',
  storageBucket: 'xplay-dev.appspot.com',
  messagingSenderId: '534490693632',
  appId: '1:534490693632:web:7778e15b91ac71915798e5',
  measurementId: 'G-S3LTFDRW5F',
  vapidKey: 'BGk9Ukk85hW_-9EAWIAQu64nnfLOfSVey7f0tVpdFY30F5wODWr4iLP8Y4vhTWEKq4gI7gssOmpPHR-aMD85j-Q',
};

export const firebaseAndroidConfig: FirebaseConfig = {
  apiKey: 'AIzaSyAH-SC0M0J3yqIBSU99bxK3ii-vzDiz1jI',
  authDomain: 'xplay-dev.firebaseapp.com',
  databaseURL: 'https://xplay-dev-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'xplay-dev',
  storageBucket: 'xplay-dev.appspot.com',
  messagingSenderId: '534490693632',
  appId: '1:534490693632:web:7778e15b91ac71915798e5',
  measurementId: 'G-0H2XV5Q12L',
  vapidKey: 'BGk9Ukk85hW_-9EAWIAQu64nnfLOfSVey7f0tVpdFY30F5wODWr4iLP8Y4vhTWEKq4gI7gssOmpPHR-aMD85j-Q',
};

export const GOOGLE_CONFIG = {
  googleMapMobileAPIKey: Platform.OS === 'ios' ? 'AIzaSyAGvglNQYL0zxbOOWmtlUuz-256YTfnMks' : 'AIzaSyByF6IeVXUOXB3WjRYRPVi9x6hrP3vg7mE',
  googlePlaceAPIKey: 'AIzaSyBJvJw2gUTkZhrnGFStI3ZXddTaUJcI1r8',
  googleAPIGeoEncoding: 'https://maps.googleapis.com/maps/api/geocode/json?latlng=',
  googleAPIPlusCode: 'https://plus.codes/api?address=',
};

export const ASSETS_LINK = {
  stcLink: 'https://assets-{env}.stcplay.gg/',
  stcPlayInvoice: 'invoices/{invoice_number}.pdf',
  stcPlayVATCertificate: 'vat/registration_certificate.pdf',
};

export const PAYMENT_LINK = {
  tabbySuccessfulRedirection: 'payment/tabby/successful/',
  successfulRedirection: 'payment/payfort/successful/',
  failedRedirection: 'payment/payfort/failed/',
  tabbyFailedSuccessful: 'payment/tabby/failed/',
  tabbyFailureSuccessful: 'payment/tabby/failure',
};

export const SOCIAL_MEDIA_LINKS = {
  facebook: 'https://www.facebook.com/stcplay.hub/',
  twitter: 'https://twitter.com/stcplay',
  instagram: 'https://www.instagram.com/stcplay/',
  youtube: 'https://www.youtube.com/channel/UCy4ZzP7JnLJvWf5W1L0R3Pw',
  linkedin: 'https://www.linkedin.com/company/stc-play/',
};

export enum AUTH_TYPES {
  FACE_BOOK = 'facebook',
  TWITTER = 'twitter',
  GOOGLE = 'google',
  APPLE = 'apple',
}

export enum AUTH_SCREENS {
  SIGN_IN = 'signin',
  SIGN_UP = 'signup',
}

export enum OTP_TYPES_SCREENS {
  SIGN_IN_EMAIL_OTP = 'login',
  SIGN_IN_NEW_EMAIL = 'login_new_email',
  SIGN_IN_NEW_MOBILE= 'login_new_mobile',
  SIGN_UP_EMAIL_OTP = 'signup',
  SIGN_UP_NEW_EMAIL = 'signup_new_email',
  SIGN_UP_NEW_MOBILE = 'signup_new_mobile',
  EDIT_EMAIL_OTP = 'editEmail',
  RESET_PASSWORD = 'reset_password',
  ADDITIONAL_FIELDS = 'additional_fields',
  SOCIAL_MEDIA = 'socialMedia',
  CHANGE_MOBILE = 'update_mobile',
  VERIFY_MOBILE = 'verify_mobile',
  VERIFY_EMAIL = 'verify_email',
  CHANGE_EMAIL = 'update_email',
  CHANGE_PASSWORD = 'login',
  TWO_FA_EMAIL = '2FA_email',
  TWO_FA_MOBILE = '2FA_mobile',
  TWO_FA_EMAIL_DISABLE = '2FA_email_disable',
  TWO_FA_MOBILE_DISABLE = '2FA_mobile_disable',
}
export type OtpTypesScreenType  = keyof typeof OTP_TYPES_SCREENS;

export enum Duration_lock {
  LOGIN_ATTEMPT = 'login_attempt',
}

export enum SOC_MED_TYPES {
  GOOGLE_SIGN_IN = 'googleSignin',
  GOOGLE_SIGN_UP = 'googleSignup',
  FACEBOOK_SIGN_IN = 'facebookSignin',
  FACEBOOK_SIGN_UP = 'facebookSignup',
  TWITTER_SIGN_UP = 'twitterSignup',
  TWITTER_SIGN_IN = 'twitterSignin',
  Apple_SIGN_UP = 'appleSignup',
  Apple_SIGN_IN = 'appleSignin',
}

export enum TOURNAMENT_TYPES {
  FEATURED = 'approved',
}

export enum PERMISSION_STATUS {
  GRANTED = 'granted',
}

export enum CHANNEL {
  SMS = 'sms',
  EMAIL = 'email',
  //TODO ADD CHANNEL HERE
}

export const APPLE_PAY_CONFIG = {
  displayName: 'STC Play Store',
  merchantCapabilities: ['supports3DS'],
  supportedNetworks: ['mada', 'visa', 'mastercard'],
};

export const KeyCenter = {
  appID: 802530180,
  appSign: 'dde8162972126431e91cb6fbfcf369d7591920a050597d68c72fe7125184dcbc',
};

export enum MCI_REGULATIONS {
  CR = '1010150269',
}

export enum VAT_CERT {
  VAT = '300000157210003',
}

export const STC_EMAIL = {
  SUPPORT: 'Support@stcplay.gg',
};

export const APP_STORE_LINKS = {
  iosXplay: 'https://apps.apple.com/us/app/stc-play/id1558331986',
  androidXplay: 'https://play.google.com/store/apps/details?id=com.stc.xplay&hl=en&gl=US',
};

export const ZEGO_CLOUD_CONFIG = {
  APP_ID: 1585539704,
  GROUP_MEMBER_COUNT: 100,
  APP_SIGN: 'd5b0a3f5bda147ad0a2a0bae6fc8f62b9a34113ac22d2a54066ff2dba2b47d87',
};

export const APP_DEEP_LINKS = {
  xPlayChat: 'stcplayapp://SignInScreen',
  xPlayChat_Android: 'intent://APP_HOST/#Intent;scheme=APP_SCHEME;package=APP_PACKAGE;end',
  xPlayChat_Android_APP_HOST: '.stcplay.gg', //dev add env
  xPlayChat_Android_APP_PACKAGE: 'com.stc.xplay',
  xPlayChat_Android_APP_SCHEME: 'https',
};

export const MINI_GAME_LINKS = {
  miniGameBaseURL: `${environment === 'production' ? 'https://stcplay.gago.games' : 'https://stagingstcplay.gago.games'}`,
};

export enum MINI_GAME_USER_PROFILES {
  GUEST = 'GUPlay',
}

export const PAGE_TITLE = {
  stcPlay: 'SEO.page_title.stcPlay',
  //AUTH
  sign_up: 'SEO.page_title.sign_up',
  sign_in: 'SEO.page_title.sign_in',
  forget_password: 'screen.forgot_password',
  //HOME
  home: 'SEO.page_title.home',
  //DISCOVER
  discover: 'Discover',
  bits: 'SEO.page_title.bits',
  //GAMES
  tournaments: 'SEO.page_title.tournaments', //INCLUDE FOR SPECIFIC TOURNAMENT AS WELL
  games: 'SEO.page_title.games', //INCLUDE FOR SPECIFIC GAMES AS WELL
  games_more_than: 'SEO.page_title.games_more_than', //INCLUDE FOR SPECIFIC GAMES AS WELL
  matchmaking: 'SEO.page_title.matchmaking',
  //SHOP
  shop: 'SEO.page_title.shop', //INCLUDE FOR CATEGORY PAGE, SPECIFIC PRODUCT PAGE,
  cart: 'SEO.page_title.cart',
  checkout: 'Checkout',
  wishlist: 'SEO.page_title.wishlist',
  //CONNECT
  connect: 'SEO.page_title.connect',
  new_chat: 'New chat',
  //USER PROFILE
  user_profile: 'My profile',
  profile: 'SEO.page_title.profile',
  //SETTINGS
  settings: 'SEO.page_title.settings',
  settings_account: 'settings.setting_section.account', //ACCOUNT
  returns: 'footer_returns',
  settings_profile: 'settings.my_profile',
  settings_gender: 'settings.gender',
  settings_phone_number: 'settings.phone_number',
  settings_email: 'footer_email',
  settings_password: 'settings.password',
  settings_my_wallet: 'profile.my_wallet',
  settings_my_orders: 'profile.myorder_title',
  settings_payment_methods: 'Payment methods',
  settings_saved_addresses: 'settings.saved_addresses',
  settings_chat_preferences: 'chat_preference_page.chat_preferences',
  settings_location_display: 'settings.setting_section.location_display', //LOCATION & DISPLAY
  settings_language: 'settings.changeLanguage',
  settings_country: 'settings.changeCountry',
  settings_time_zone: 'settings.time_zone',
  settings_currency: 'settings.changeCurrency',
  settings_security: 'settings.setting_section.security', //SECURITY
  settings_account_privacy: 'Account privacy',
  settings_blocked_accounts: 'settings.blocked_accounts',
  settings_2FA: 'settings.two_factor_authentication',
  settings_privacy_policy: 'Privacy policy',
  //SUPPORT
  support: 'Help & support',
  //NOTIFICATION
  notifications: 'SEO.page_title.notifications',
  //SEARCH
  search: 'SEO.page_title.search',
  //FAQ
  faq: 'SEO.page_title.help',
  //CONTACT US,
  contact_us: 'SEO.page_title.contact_us',
  // ESPORTS WORLD CUP
  esports_world_cup: 'SEO.page_title.esports_world_cup',
};

export const PAGE_DESCRIPTION = {
  // stcPlay: '| stc play',
  //AUTH
  sign_up: 'SEO.page_description.sign_up',
  sign_in: 'SEO.page_description.sign_in',
  forget_password: 'STC Play Forget password Page',
  //HOME
  home: 'SEO.page_description.home',
  //DISCOVER
  discover: 'STC Play Discover Page',
  bits: 'SEO.page_description.bits',
  //GAMES
  tournaments: 'SEO.page_description.tournaments', //INCLUDE FOR SPECIFIC TOURNAMENT AS WELL
  games: 'SEO.page_description.games', //INCLUDE FOR SPECIFIC GAMES AS WELL
  matchmaking: 'SEO.page_description.matchmaking',
  //SHOP
  shop: 'SEO.page_description.shop', //INCLUDE FOR CATEGORY PAGE, SPECIFIC PRODUCT PAGE,
  cart: 'SEO.page_description.cart',
  checkout: 'STC Play Checkout Page',
  wishlist: 'SEO.page_description.wishlist',
  //CONNECT
  connect: 'SEO.page_description.connect',
  new_chat: 'STC Play New chat Page',
  //USER PROFILE
  user_profile: 'STC Play User profile Page',
  profile: 'SEO.page_description.profile',
  //SETTINGS
  settings: 'SEO.page_description.settings',
  settings_account: 'STC Play Account Settings Page', //ACCOUNT
  returns: 'STC Play Returns Page',
  settings_profile: 'STC Play My profile Settings Page',
  settings_gender: 'STC Play Gender Settings Page',
  settings_phone_number: 'STC Play Phone number Settings Page',
  settings_email: 'STC Play Email Settings Page',
  settings_password: 'STC Play Password Settings Page',
  settings_my_wallet: 'STC Play My wallet Page',
  settings_my_orders: 'STC Play My orders Page',
  settings_payment_methods: 'STC Play Payment methods Settings Page',
  settings_saved_addresses: 'STC Play Saved addresses Settings Page',
  settings_chat_preferences: 'STC Play Chat preferences Settings Page',
  settings_location_display: 'STC Play Location & display Settings Page', //LOCATION & DISPLAY
  settings_language: 'STC Play Language Settings Page',
  settings_country: 'STC Play Country Settings',
  settings_time_zone: 'STC Play Time zone Settings Page',
  settings_currency: 'STC Play Currency Settings Page',
  settings_security: 'STC Play Security Settings Page', //SECURITY
  settings_account_privacy: 'STC Play Account privacy Settings Page',
  settings_blocked_accounts: 'STC Play Blocked accounts Settings Page',
  settings_2FA: 'STC Play Two-factor authentication Settings Page',
  settings_privacy_policy: 'STC Play Privacy policy Settings Page',
  //SUPPORT
  support: 'STC Play Help & support Page',
  //NOTIFICATION
  notifications: 'SEO.page_description.notifications',
  //SEARCH
  search: 'SEO.page_description.search',
  //FAQ
  faq: 'SEO.page_description.help',
  //CONTACT US,
  contact_us: 'SEO.page_description.contact_us',
  // ESPORTS WORLD CUP
  esports_world_cup: 'SEO.page_description.esports_world_cup',
};

export const PAGE_KEYWORDS = {
  // stcPlay: '| stc play',
  //AUTH
  sign_up: 'SEO.page_key_words.sign_up',
  sign_in: 'SEO.page_key_words.sign_in',
  forget_password: 'Forget password',
  //HOME
  home: 'SEO.page_key_words.home',
  //DISCOVER
  discover: 'Discover',
  bits: 'SEO.page_key_words.bits',
  //GAMES
  tournaments: 'SEO.page_key_words.tournaments', //INCLUDE FOR SPECIFIC TOURNAMENT AS WELL
  games: 'SEO.page_key_words.games', //INCLUDE FOR SPECIFIC GAMES AS WELL
  matchmaking: 'SEO.page_key_words.matchmaking',
  //SHOP
  shop: 'SEO.page_key_words.shop', //INCLUDE FOR CATEGORY PAGE, SPECIFIC PRODUCT PAGE,
  cart: 'SEO.page_key_words.cart',
  checkout: 'Checkout',
  wishlist: 'SEO.page_key_words.wishlist',
  //CONNECT
  connect: 'SEO.page_key_words.connect',
  new_chat: 'New chat',
  //USER PROFILE
  user_profile: 'User profile',
  profile: 'SEO.page_key_words.profile',
  //SETTINGS
  settings: 'SEO.page_key_words.settings',
  settings_account: 'Account', //ACCOUNT
  returns: 'Returns',
  settings_profile: 'My profile',
  settings_gender: 'Gender',
  settings_phone_number: 'Phone number',
  settings_email: 'Email',
  settings_password: 'Password',
  settings_my_wallet: 'My wallet',
  settings_my_orders: 'My orders',
  settings_payment_methods: 'Payment methods',
  settings_saved_addresses: 'Saved addresses',
  settings_chat_preferences: 'Chat preferences',
  settings_location_display: 'Location & display', //LOCATION & DISPLAY
  settings_language: 'Language',
  settings_country: 'Country',
  settings_time_zone: 'Timezone',
  settings_currency: 'Currency',
  settings_security: 'Security', //SECURITY
  settings_account_privacy: 'Account privacy',
  settings_blocked_accounts: 'Blocked accounts',
  settings_2FA: 'Two-factor authentication',
  settings_privacy_policy: 'Privacy policy',
  //SUPPORT
  support: 'Help & support',
  //NOTIFICATION
  notifications: 'SEO.page_key_words.notifications',
  //SEARCH
  search: 'SEO.page_key_words.search',
  //FAQ
  faq: 'SEO.page_key_words.help',
  //CONTACT US,
  contact_us: 'SEO.page_key_words.contact_us',
  // ESPORTS WORLD CUP
  esports_world_cup: 'SEO.page_key_words.esports_world_cup',
};

export enum FAQS_DEFAULT_MENUS {
  privacy_Policy = 'privacy-policy',
  terms_And_Condition = 'terms-and-conditions',
  shopping_Delivery = 'shopping-delivery',
  exchange_And_Returns = 'exchange-returns',
}

export const DELETED_BY_ADMIN_IDENTIFIER = 'DELETED,admin';

export enum IMAGE_ALT_LABELS {
  user_profile_picture = 'SEO.page_image_alt_custom.user_profile_picture',
  follower_user_avatar = 'SEO.page_image_alt_custom.follower_user_avatar',
  following_user_avatar = 'SEO.page_image_alt_custom.following_user_avatar',
  preference_group_avatar = 'SEO.page_image_alt_custom.preference_group_avatar',
  preference_group_member_avatar = 'SEO.page_image_alt_custom.preference_group_member_avatar',
  group_member_avatar = 'SEO.page_image_alt_custom.group_member_avatar',
  notification_user_avatar = 'SEO.page_image_alt_custom.notification_user_avatar',
  matched_user_profile_banner = 'SEO.page_image_alt_custom.matched_user_profile_banner',
  matched_user_profile_avatar = 'SEO.page_image_alt_custom.matched_user_profile_avatar',
  qr_code = 'SEO.page_image_alt_custom.QR_CODE',
  user_cover_banner = 'SEO.page_image_alt_custom.user_cover_banner',
  recently_viewed_image = 'SEO.page_image_alt_custom.recently_viewed_image',
  need_to_sign_in = 'SEO.page_image_alt_custom.Need_to_Sign_In',
  auth_background_sign_in = 'SEO.page_image_alt_custom.auth_background_sign_in',
  auth_background_sign_up = 'SEO.page_image_alt_custom.auth_background_sign_up',
  conversation_list_chat_avatar = 'SEO.page_image_alt_custom.conversation_list_chat_avatar',
  conversation_avatar = 'SEO.page_image_alt_custom.conversation_avatar',
  chat_user_avatar = 'SEO.page_image_alt_custom.chat_user_avatar',
  chat_window_avatar = 'SEO.page_image_alt_custom.chat_window_avatar',
  home_banner = 'SEO.page_image_alt_custom.home_banner',
  home_matchmaking_banner_image_1 = 'SEO.page_image_alt_custom.home_matchmaking_banner_image_1',
  home_matchmaking_banner_image_2 = 'SEO.page_image_alt_custom.home_matchmaking_banner_image_2',
  home_matchmaking_banner_image_3 = 'SEO.page_image_alt_custom.home_matchmaking_banner_image_3',
  notification_product_thumbnail = 'SEO.page_image_alt_custom.notification_product_thumbnail',
  notification_group_avatar = 'SEO.page_image_alt_custom.notification_group_avatar',
  notification_chat_image_thumbnail = 'SEO.page_image_alt_custom.notification_chat_image_thumbnail',
  notification_group_avatar_1 = 'SEO.page_image_alt_custom.notification_group_avatar_1',
  notification_group_avatar_2 = 'SEO.page_image_alt_custom.notification_group_avatar_2',
  create_tournament_background = 'SEO.page_image_alt_custom.create_tournament_background',
  matchmaking_ellipse_glow = 'SEO.page_image_alt_custom.matchmaking_ellipse_glow',
  matchmaking_image_side_banner = 'SEO.page_image_alt_custom.matchmaking_image_side_banner',
  matchmaking_logged_user_avatar = 'SEO.page_image_alt_custom.matchmaking_logged_user_avatar',
  matchmaking_recently_followed_avatar = 'SEO.page_image_alt_custom.matchmaking_recently_followed_avatar',
  shop_medium_banner_floating_image = 'SEO.page_image_alt_custom.shop_medium_banner_floating_image',
  shop_medium_banner_purple_background = 'SEO.page_image_alt_custom.shop_medium_banner_purple_background',
  settings_empty_saved_addresses = 'SEO.page_image_alt_custom.settings_empty_saved_addresses',
  mini_game_banner_fallback_layer_1 = 'SEO.page_image_alt_custom.mini_game_banner_fallback_layer_1',
  mini_game_banner_fallback_layer_2 = 'SEO.page_image_alt_custom.mini_game_banner_fallback_layer_2',
  mini_game_banner_glow_effect = 'SEO.page_image_alt_custom.mini_game_banner_glow_effect',
  nav_bar_user_avatar = 'SEO.page_image_alt_custom.nav_bar_user_avatar',
  order_product_thumbnail = 'SEO.page_image_alt_custom.order_product_thumbnail',
  stc_pay_image = 'SEO.page_image_alt_custom.stc_pay_image',
  visa_master_card_image = 'SEO.page_image_alt_custom.visa_master_card_image',
  tabby_image = 'SEO.page_image_alt_custom.tabby_image',
  apple_pay_image = 'SEO.page_image_alt_custom.apple_pay_image',
  ordered_product_thumbnail = 'SEO.page_image_alt_custom.ordered_product_thumbnail',
  return_product_thumbnail = 'SEO.page_image_alt_custom.return_product_thumbnail',
  return_product_layered_thumbnail = 'SEO.page_image_alt_custom.return_product_layered_thumbnail',
  total_refund_product_thumbnail = 'SEO.page_image_alt_custom.total_refund_product_thumbnail',
  return_photos_product_image = 'SEO.page_image_alt_custom.return_photos_product_image',
  return_refund_product_image = 'SEO.page_image_alt_custom.return_refund_product_image',
  return_product_uploaded_image = 'SEO.page_image_alt_custom.return_product_uploaded_image',
  shop_tailored_delivery_icon = 'SEO.page_image_alt_custom.shop_tailored_delivery_icon',
  shop_tailored_stc_points_icon = 'SEO.page_image_alt_custom.shop_tailored_stc_points_icon',
  shop_tailored_installments = 'SEO.page_image_alt_custom.shop_tailored_installments',
  shop_tailored_returns = 'SEO.page_image_alt_custom.shop_tailored_returns',
}

export enum REFERENCE_TYPE {
  TOURNAMENT = 'tournament',
  BANNER = 'banner',
  COLLECTION = 'collection',
  MENU = 'menu',
  PRODUCT = 'product',
  CHAT = 'chat',
  SHOP = 'shop',
  RETURNS = 'return',
  PROFILE = 'profile',
  MATCHMAKING = 'matchmaking',
  MINIGAMES = 'minigames',
  CUSTOM = 'custom',
  EXTERNAL = 'external',
  BIT = 'bit',
  EWC = 'ewc',
  VERIFY = 'verify',
  BITS_MODERATION = "BitsModeration"
}

export enum SECTION_TYPE {
  SLIDER = 'slider',
  CAROUSEL = 'carousel',
  STATIC_BANNER = 'static_banner',
  SECTION = 'section',
}

//RIYADH DEFAULT COORDINATES
export const DEFAULT_LOCATION = {
  longitude: 46.6882735,
  latitude: 24.7508048,
};

export const getAssetsLink = (envCode: string) => {
  const replacedString = replaceString(ASSETS_LINK.stcLink, {
    env: envCode,
  });
  return replacedString;
};