import React from "react";

interface OrderPageSummaryProps {
    step?: number;
    subtotal?: number;
    delivery_fee?: number;
    buttons: JSX.Element;
    summaryDisclaimerText?: string | null;
}

const OrderPageSummary = (props: OrderPageSummaryProps) => {
    const { buttons, summaryDisclaimerText }  = props;
    return (
        <div className={`flex flex-col gap-[1.25rem]`}>
            {buttons}
            <div>
                <p className="text-fs-caption font-regular text-secondary/[0.5]">
                    {summaryDisclaimerText}
                </p>
            </div>
        </div>
    )
}

export default OrderPageSummary