import { useTranslate } from '@fe-monorepo/hooks';
import { InvoiceProduct } from '@fe-monorepo/models';
import Checkbox from '@fe-web/Molecules/InputFields/Checkbox';
import Products from '@fe-web/Organisms/OrderProduct/OrderProduct';
import Modal from '@fe-web/Templates/Modal/Modal';
import { useState } from 'react';

import RemoveAll from '../RemoveAll';

interface CartProps {
  products: InvoiceProduct[];
  selectedProducts: string[];
  checkAllSelected?: boolean;
  handleCheckAll?: () => void;
  language: 'en' | 'ar';
  increment: (productCode: string, variantId: number) => void;
  decrement: (productCode: string, variantId: number) => void;
  handleClickCheckbox: (code: string, variantId: number) => void;
  isRTL?: boolean;
  clearItems?: () => void;
  isHeaderDark?: boolean;
  dir?: string;
  isMobile?: boolean;
  remove: (productCode: string, variantId: number, qty: number) => void;
  checkIfHasNoAvailItem?: () => void;
  unavailableProductCodes?: string[];
  currentStep: number;
}

const Cart = (props: CartProps) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const { translate } = useTranslate();
  const {
    products,
    language,
    increment,
    decrement,
    selectedProducts,
    checkAllSelected,
    handleCheckAll,
    handleClickCheckbox,
    isRTL,
    isHeaderDark,
    dir,
    clearItems,
    isMobile,
    remove,
    checkIfHasNoAvailItem,
    unavailableProductCodes,
  } = props;

  const handleOpenModal = () => {
    setIsOpenModal(true);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const handleConfirm = (action: string) => {
    if (action === 'yes' && clearItems) {
      clearItems();
    }
    setIsOpenModal(false);
  };

  return (
    <>
      <div className="">
        <div className={`flex flex-col divide-y gap-y-24 ${!isMobile ? 'border-b border-secondary/10 pb-[24px]' : ''}`}>
          <div className="flex gap-x-12 items-center 8K:gap-x-[1rem] SD:gap-x-24">
            <div>
              <Checkbox
                isChecked={checkAllSelected}
                classNames="w-[1.125rem] h-[1.125rem] accent-sunset"
                onClick={handleCheckAll}
                width={1.125}
                height={1.125}
              />
            </div>
            <div
              className={`
                            flex flex-col gap-y-20 SD:flex-row w-full
                            ${isMobile && 'grid grid-cols-2'}
                        `}
            >
              <div className={`${!isMobile && 'flex-1 flex flex-col gap-y-10'}`}>
                <p className={`text-fs-body-small font-medium text-secondary`}>{translate('shop.cart.entire_cart')}</p>
              </div>
              {checkAllSelected && (
                <div className={`${!isMobile && 'flex-1 flex flex-col gap-y-10'}`} onClick={handleOpenModal}>
                  <p
                    className={`text-sunset ${
                      isRTL ? 'text-left' : 'text-right'
                    } text-fs-body-small font-regular hover:underline hover:cursor-pointer`}
                  >
                    {translate('shop.cart.remove_all')}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="SD:h-[32.5rem] overflow-y-auto 8K:h-[40rem]">
          <Products
            products={products}
            language={language}
            showSeller={true}
            showDelivery={true}
            showQuantity={true}
            increment={increment}
            decrement={decrement}
            hasCheckbox={true}
            handleClickCheckbox={handleClickCheckbox}
            selectedProducts={selectedProducts}
            isRTL={isRTL}
            showIsAvailable={true}
            isMobile={isMobile}
            isBrandNameClickable={true}
            isImageClickable={true}
            isNameClickable={true}
            showRightCaption={true}
            removeToCart={remove}
            checkIfHasNoAvailItem={checkIfHasNoAvailItem}
            unavailableProductCodes={unavailableProductCodes}
            showQuantityError={true}
            showDigitalProductTag={true}
            // checkAll={
            //     checkBoxLabel: "Entire cart",
            //     removeAllLaber: "Remove all"
            // }
            // hasCheckedBox={true}
            // onCheckboxClick=(handleCheckbox)
          />
        </div>
      </div>
      <Modal open={isOpenModal}>
        <RemoveAll isHeaderDark={isHeaderDark} dir={dir} handleAction={handleConfirm} handleClose={handleCloseModal} />
      </Modal>
    </>
  );
};

export default Cart;
