import { gql } from '@apollo/client';

export const RETURN_PRODUCT_MUTATION = {
    //5. Retrieve Delivered Physical Products in the Invoice: 
    omsReturnProdAdd: gql`
        mutation omsReturnProdAdd($details: OmsReturnProdAddInput) {
            omsReturnProdAdd(details: $details) {
                is_successful
                error_code
                error_msg
                data {
                    return_product_id
                }
            }
        }
    `,
    omsReturnProdUpdate: gql`
        mutation omsReturnProdUpdate($details: OmsReturnProdUpdateInput) {
            omsReturnProdUpdate(details: $details) {
                is_successful
                error_code
                error_msg
            }
        }
    `,
}