import { IMAGES } from '@fe-monorepo/assets';
import { IconNames, formatNumberWithCommasAndDecimal } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import { InvoiceProduct } from '@fe-monorepo/models';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import QuantityField from '@fe-web/Atoms/QuantityField';
import CurrencyText from '@fe-web/Atoms/Text/CurrencyText';
import { motion } from 'framer-motion';

import { getProductDiscountAndPrices } from '../../../../lib/helper';

// import { useCartState } from '@fe-monorepo/hooks';

interface CartItemProps {
  product: InvoiceProduct;
  language: 'en' | 'ar';
  incrementItem: (productCode: string, variantId?: number) => void;
  decrementItem: (productCode: string, variantId?: number) => void;
  removeItem: (productCode: string, qty?: number, variantId?: number) => void;
  isRTL?: boolean;
}

const CartItemComponent = (props: CartItemProps) => {
  const { translate } = useTranslate();

  const { product, incrementItem, decrementItem, removeItem, isRTL } = props;
  const { discount } = getProductDiscountAndPrices(product?.grand_total, product?.unit_price_grand_total);
  const price = formatNumberWithCommasAndDecimal(product?.grand_total);
  const discounted_totalprice = product?.grand_total;

  // formatNumberWithCommasAndDecimal((discount ? product?.subtotal : product?.unit_price) * product.qty);
  const total_actual_cost = product?.unit_price_grand_total;

  // formatNumberWithCommasAndDecimal(product?.unit_price * product.qty);

  const handleIncrement = () => {
    incrementItem(product.product_code, product.variant_id);
  };

  const handleDecrement = () => {
    decrementItem(product.product_code, product.variant_id);
  };

  const handleDelete = () => {
    removeItem(product.product_code, product.variant_id, product.qty);
  };

  const media = product && product.media && product.media.length > 0 ? product.media[0] : IMAGES.Product_Placeholder.toString();

  const product_name = product?.product_name;

  const qty = product.qty;
  // const onChangeQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
  //     changeQuantity({
  //         id: props.id,
  //         quantity: parseInt(e.target.value)
  //     })
  // }

  return (
    <div className="justify-start flex gap-12 8K:gap-[48px] w-full border-b-[0.0625rem] border-secondary/10 pb-[1rem]">
      <div
        className={`
                    flex
                    w-[3.25rem] h-[3.25rem]
                    2K:h-[4.25rem] 2K:w-[4.25rem]
                    4K:h-[4.256rem] 4K:w-[4.256rem]
                    8K:h-[4.65rem] 8K:w-[4.65rem]
                    py-4 px-6
                    2K:py-10 px-12
                    4K:py-8 px-10
                    items-center justify-center border-[0.0625rem] border-secondary/20 rounded-[0.125rem]
                `}
      >
        <motion.img
          layout="preserve-aspect"
          className={``}
          src={media}
          animate={{ scale: 1.1 }}
          transition={{
            duration: 0.45,
            layout: { duration: 0.375 },
          }}
        />
      </div>
      <div className="flex flex-col w-full cols-span-2 gap-4 2K:gap-[1.175rem]">
        <div className="w-full">
          <span className="line-clamp-1 text-fs-caption text-secondary font-medium">{product_name}</span>
        </div>
        <div className="flex flex-col grid grid-cols-2">
          <div>
            {product.is_digital !== 1 ? (
              <QuantityField
                value={qty || 1}
                classNames={{
                  decrementButton: ``,
                  incrementButton: `text-fs-body-small`,
                }}
                decrementButton={
                  qty === 1 ? (
                    <button onClick={handleDelete} className="flex items-center">
                      <ResponsiveIcon
                        name={IconNames.cartDelete}
                        baseWidth={8}
                        baseHeight={8}
                        iconClasses="h-[1rem] w-[1rem]"
                        className="inline-block"
                      />
                    </button>
                  ) : (
                    <button
                      className={`text-fs-body-small text-secondary items-center justify-items-center self-center`}
                      disabled={false}
                      onClick={handleDecrement}
                    >
                      -
                    </button>
                  )
                }
                increment={handleIncrement}
                isIncrementDisabled={qty >= 5}
                // onChange={onChangeQuantity}
              />
            ) : (
              <button onClick={handleDelete}>
                <ResponsiveIcon
                  name={IconNames.cartDelete}
                  baseWidth={8}
                  baseHeight={8}
                  iconClasses="h-[1rem] w-[1rem]"
                  className="p-[0.5rem] inline-block bg-secondary/[0.05]"
                />
              </button>
            )}
          </div>
          <div className={`${isRTL ? 'text-left' : 'text-right'} text-fs-caption font-medium self-center`}>
            <div>
              <CurrencyText className={`${discount ? 'text-red' : 'text-secondary'}`} currency={discounted_totalprice} />
            </div>
            {discount && (
              <div>
                <CurrencyText className={`line-through text-secondary`} currency={total_actual_cost} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartItemComponent;
