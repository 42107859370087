/* eslint-disable no-useless-escape */
export enum MixPanelEvents {
  'appOpen' = 'App Open',
  'pageView' = 'Page View',
  'deleteAccount' = 'Delete User Account',
  'genGSignIn' = 'Gen. G Sign-In',
  'genGFormSubmit' = 'Gen. G Form Submitted',
  'genGBannerClick' = 'Banner Click',
  'watchNow' = 'Watch Now - Stream Tab',
  'streamPlay' = 'Stream Play',
  'productViewed' = 'Product Viewed',
  'productBuyNow' = 'Product Buy Now',
  'productAddedToCart' = 'Product Added To Cart',
  'cartViewed' = 'Cart Viewed',
  'cartError' = 'Cart Error',
  'deliveryAndPayment' = 'Delivery & Payment',
  'productAddedToWishlist' = 'Product Added To Wishlist',
  'addressAdded' = 'Address On Delivery & Payment Page',
  'orderPlaced' = 'Order Placed',
  'bannersButtonCTA' = 'Banners / Button CTA',
  'gameViewed' = 'Game Viewed',
  'gamePlayStarted' = 'Game Play Started',
  'gamePlayClosed' = 'Game Play Closed',
  'getMatched' = 'Get Matched',
  'matchedGamer' = 'Matched Gamer',
  'matchedRejected' = 'Matched Rejected',
  'consentPopupWindowViewed' = 'Consent Popup Window Viewed',
  'acceptAndContinueButton' = 'Accept And Continue Button',
  'consentRejected' = 'Consent Rejected',
  'signupstarted' = 'Sign Up Started',
  'signupMethodSubmitted' = 'Signup Method Submitted',
  'signUpUsernameSubmitted' = 'Sign Up Username Submitted',
  'otpSubmitted' = 'OTP Submitted',
  'loginStarted' = 'Login Started',
  'loginSubmitted' = 'Login Submitted',
}
