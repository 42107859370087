import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { PASSWORD_MUTATION } from '@fe-monorepo/data-access';
import { ChangePasswordParamsModel, ChangePasswordResponse } from './type';

export const useChangePassword = () => {
    const [isSuccessful, setStatus] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [errorMsg, setErrorMsg] = useState<string>('');

    const [updatePasswordGql, { data: updatePasswordData }] = useMutation<ChangePasswordResponse>(PASSWORD_MUTATION.updatePassword, {
        errorPolicy: 'all',
    });

    useEffect(() => {
        if (updatePasswordData && updatePasswordData.updatePassword) {
            if (updatePasswordData.updatePassword.is_successful) {
                setStatus(updatePasswordData.updatePassword.is_successful);
            }
            if (updatePasswordData.updatePassword.error_msg) {
                setErrorMsg(updatePasswordData.updatePassword.error_msg);
            }
            setStatus(updatePasswordData.updatePassword.is_successful);
        }
        setIsLoading(false);
    }, [updatePasswordData?.updatePassword]);

    // useEffect(() => {
    //     if (errorChangePassword) {
    //         setError(errorChangePassword.message);
    //     }
    //     setIsLoading(false);
    // }, [errorChangePassword]);

    const updateThePassword = async (params: ChangePasswordParamsModel) => {
        setIsLoading(true);
        const { data } = await updatePasswordGql({ variables: { details: params } });
        return data?.updatePassword;
    };

    return {
        updateThePassword,
        isSuccessful,
        isLoading,
        error,
        errorMsg,
    };
};
