import { IconNames } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import PopoverAbstract from '@fe-web/Molecules/Popover/PopoverAbstract';
import useMobileDetect from '@fe-web/hooks/useMobileDetect';
import { useNotificationProvider } from '@fe-web/hooks/useNotification';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from '../../../../app.routes.enum';

interface SideBarHeaderProps {
  isNotificationEmpty: boolean;
  showFullViewIcon: boolean;
  isRTL: boolean;
  openFilter?: () => void;
}

const SidebarNotificationHeader = ({ showFullViewIcon, openFilter }: SideBarHeaderProps) => {
  const { translate } = useTranslate();
  const { notificationLength, markReadAllNotification, removeAllNotification } = useNotificationProvider();
  const navigate = useNavigate();
  const isMobile = useMobileDetect();

  // redirect to notification dedicated page
  const onMaximizeNotificationClick = () => {
    navigate(AppRoutes.notifications);
  };

  // Marks all notification as read
  const markAllAsRead = () => {
    markReadAllNotification();
  };

  // removes all notification
  const clearAllNotification = () => {
    removeAllNotification();
  };

  return (
    <div className="flex flex-row justify-between">
      <p className="text-fs-body-large text-secondary font-medium">
        {translate('common_notification_count', {
          value: `${notificationLength && notificationLength > 0 ? `(${notificationLength})` : ``}`,
        })}
      </p>
      <div className="flex flex-row gap-[1rem] hover:cursor-pointer">
        {showFullViewIcon && (
          <ResponsiveIcon
            name={IconNames.icon_chat_maximize}
            baseHeight={20}
            baseWidth={20}
            iconClasses="fill-secondary hover:fill-zinc-400 dark:hover:fill-zinc-200"
            onClick={onMaximizeNotificationClick}
            className="self-center"
          />
        )}
        <PopoverAbstract
          iconBaseHeight={20}
          iconBaseWidth={20}
          position={['right', 'bottom']}
          align={showFullViewIcon || isMobile ? 'end' : 'start'}
          padding={showFullViewIcon ? 'mt-[0.75rem]' : 'mt-[0.75rem] SD:mt-0 SD:ltr:ml-[2rem] SD:rtl:mr-[2rem] SD:!top-[-1.25rem]'}
          iconClassName="!fill-secondary"
        >
          {showFullViewIcon && (
            <div
              onClick={onMaximizeNotificationClick}
              className="text-secondary
                        py-[0.5rem] px-[1rem] cursor-pointer hover:text-sunset hover:bg-sunset/10 transition-all"
            >
              <p className="font-regular text-fs-body">{translate('connect.more_options.open_full_window')}</p>
            </div>
          )}
          <div
            onClick={markAllAsRead}
            className="text-secondary
                        py-[0.5rem] px-[1rem] cursor-pointer hover:text-sunset hover:bg-sunset/10 transition-all"
          >
            <p className="font-regular text-fs-body">{translate('notification.mark_all_as_read')}</p>
          </div>
          <div
            onClick={clearAllNotification}
            className="text-secondary
                        py-[0.5rem] px-[1rem] cursor-pointer hover:text-sunset hover:bg-sunset/10 transition-all"
          >
            <p className="font-regular text-fs-body">{translate('common_notification_clearAll')}</p>
          </div>
          <div
            onClick={() => openFilter?.()}
            className="text-secondary
                        py-[0.5rem] px-[1rem] cursor-pointer hover:text-sunset hover:bg-sunset/10 transition-all"
          >
            <p className="font-regular text-fs-body">{translate('common_notification_filter')}</p>
          </div>
        </PopoverAbstract>
      </div>
    </div>
  );
};

export default SidebarNotificationHeader;
