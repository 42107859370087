import { IMAGE_ALT_LABELS, TxKeyPath, convertPriceWithSymbol } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { IndividualReturnInfo, InvoiceProduct, OptionSort2 } from './Model/InvoiceProduct';

interface TotalRefundProps {
  products: InvoiceProduct[] | undefined;
  order_id: string | undefined;
  order_date: string | undefined;
  returnData: { [key: string]: OptionSort2 };
}

const TotalRefund = ({ products, order_id, order_date, returnData }: TotalRefundProps) => {
  const { translate } = useTranslate();
  // const { preferred_currency } = useSelector((state: RootState) => state.user.userContext);
  const preferred_currency = useSelector((state: RootState) => state.app.currency);
  const language = useSelector((state: RootState) => state.app.language);
  const [RefundData, setRefundData] = useState<IndividualReturnInfo[]>([]); //this will be the data that will be sent to the backend

  //create a useEffect that will check the returnData object and will loop through it
  //For each object key, look into the option array
  //loop into the option array and only loop through where id is not equal to 0
  //create a new array of objects that will be sent to the backend
  useEffect(() => {
    const newRefundData: IndividualReturnInfo[] = [];
    Object.keys(returnData).map(key => {
      const product = returnData[key];
      product.option.map(option => {
        if (option.id !== '0') {
          newRefundData.push({
            product_code: key,
            reason: option.id,
            serial: option.serial as string,
            imageFiles: option.imageFiles as any[],
            TotalRefund: product.price,
          });
        }
      });
    });
    setRefundData(newRefundData);
  }, [returnData]);

  //using the result of the function convertDate, create a function that will return the date in the format of Aug 18th, 2023
  function convertDate(date: string | undefined) {
    if (!date) return null;
    const newDate = new Date(date);
    const month = newDate.toLocaleString('en-us', { month: 'short' });
    const day = newDate.toLocaleString('en-us', { day: 'numeric' });
    const year = newDate.toLocaleString('en-us', { year: 'numeric' });
    return `${month} ${day}th, ${year}`;
  }

  //create a function that will return the total refund base from the isSelected is true and loop based on the quantity in the return data
  function getTotalRefund() {
    let total = 0;
    Object.values(returnData).map(
      product =>
        product.isSelected &&
        [...Array(product.quantity)].map((_, index) => {
          total += product.price;
        }),
    );
    return total;
  }

  return (
    <div className="mdxs:w-[20.9375rem] SD:w-full">
      <div className="flex items-center gap-[1rem] w-full">
        <div className="w-[11.5rem]">
          <div className="text-secondary/70 text-fs-caption not-italic font-regular leading-4">
            {translate('shop.cart.returns_or_exchange.order_id')}
          </div>
          <div className="text-static-secondary text-fs-body-small not-italic font-regular leading-5">{order_id}</div>
        </div>
        <div>
          <div className="text-secondary/70 text-fs-caption not-italic font-regular leading-4">
            {translate('shop.cart.returns_or_exchange.order_date')}
          </div>
          <div className="text-static-secondary text-fs-body-small not-italic font-regular leading-5">{convertDate(order_date)}</div>
        </div>
      </div>
      <div className="my-[1.25rem] py-[1.25rem px-[1rem] py-[1.25rem] border border-secondary/20 rounded-lg">
        {/* Container for the products to be refunded  */}
        {Object.values(returnData).map(
          product =>
            product.isSelected &&
            [...Array(product.quantity)].map((_, index) => (
              <>
                <div className="flex gap-[0.75rem]" key={index}>
                  <div className="w-[3.25rem]">
                    <div
                      className="
                    bg-secondary/[0.06]  
                    py-[1rem] px-[1rem]
                    w-[3.25rem]
                    h-[3.25rem]
                "
                    >
                      <img
                        src={product.imageURL}
                        alt={translate(IMAGE_ALT_LABELS.total_refund_product_thumbnail as TxKeyPath) || ''}
                        className="w-full h-full object-contain"
                      />
                    </div>
                  </div>

                  <div className="flex flex-col grow">
                    <div>
                      <div className="text-fs-body-small text-static-secondary not-italic font-medium leading-5 mb-[0.5rem]">
                        {product.name}
                      </div>
                      <div className="flex justify-between">
                        <div className="text-fs-caption text-static-secondary not-italic font-regular leading-4">
                          1 x {convertPriceWithSymbol(product?.price, 'commaDecimal')}
                        </div>
                        <div className="text-fs-caption text-static-secondary not-italic font-medium leading-4">
                          {convertPriceWithSymbol(product?.price, 'commaDecimal')}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="w-full text-surfaceGray bg-secondary/10 mdxs:w-[18.9375rem] border-[none] my-[1.5rem]" />
              </>
            )),
        )}
        {/* Total for refund */}

        <div className="flex items-center justify-between">
          <div className="text-secondary text-fs-body-small not-italic font-medium leading-5">
            {translate('shop.cart.returns_or_exchange.total_for')}
          </div>
          <div className="text-secondary text-fs-body-large not-italic font-medium leading-6">
            {convertPriceWithSymbol(Number(getTotalRefund()), 'commaDecimal')}
          </div>
        </div>
      </div>
      <div className="text-secondary text-opacity-[42%] text-fs-caption font-regular">
        {translate('shop.cart.returns_or_exchange.note_that')}
      </div>
    </div>
  );
};

export default TotalRefund;
