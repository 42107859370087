import { IconNames } from '@fe-monorepo/helper';
import { useUserProfile } from '@fe-monorepo/hooks';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from '../../../app.routes.enum';
import VerifyPhoneModal from './VerifyPhoneModal';

const SuperPlus = () => {
  const [isVerifyOpen, setIsVerifyOpen] = useState<boolean>(false);
  const { user } = useUserProfile();
  const navigate = useNavigate();

  const isPhoneNotAdded = !user?.mobile;
  const isPhoneNotVerified = user?.mobile && !user?.is_mobile_verified;

  const handlePlusClick = () => {
    if (isPhoneNotAdded || isPhoneNotVerified) {
      setIsVerifyOpen(true);
      return;
    }
    navigate(`${AppRoutes.bits}/create-post/step-1`);
  };

  return (
    <>
      <div
        role="button"
        className="
          flex justify-center items-center
          h-[1.8125rem] w-[1.8125rem]
          bg-sunset
          text-white100
          cursor-pointer rounded-[0.125rem]
        "
        onClick={handlePlusClick}
      >
        <ResponsiveIcon name={IconNames.addPlus} baseHeight={28} baseWidth={28} />
      </div>
      <VerifyPhoneModal
        type={isPhoneNotAdded ? 'add' : isPhoneNotVerified ? 'verify' : null}
        open={isVerifyOpen}
        onClose={() => setIsVerifyOpen(false)}
      />
    </>
  );
};

export default SuperPlus;
