import { IconNames } from '@fe-monorepo/helper';
import { useUserProfile } from '@fe-monorepo/hooks';
import { setThemes } from '@fe-monorepo/store';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import React from 'react';
import { useDispatch } from 'react-redux';

import useTheme, { theme } from '../../../hooks/useTheme';
import ResponsiveIcon from '../../Atoms/Icon/ResponsiveIcon';
import ToggleSwitch from '../../Atoms/ToggleSwitch';

interface ThemeTogglerProps {
  textCssClassName?: string;
  cssClassName?: string;
  text?: any;
  iconSize?: number;
}
const ThemeToggle: React.FC<ThemeTogglerProps> = ({ textCssClassName, cssClassName, text, iconSize = 24 }) => {
  const { setTheme, isDarkTheme } = useTheme();
  const { isLoggedIn } = useUserProfile();

  const handleToggleChange = (value: boolean) => {
    setTheme(value ? theme.dark : theme.light);
    setThemesOption(value ? 'dark' : 'light');

    if (isLoggedIn) {
      mixpanelHelper.setUserProperties({
        'Screen Mode': value ? 'Dark' : 'Light',
      });
    }
  };

  const dispatch = useDispatch();
  const setThemesOption = (value: string) => {
    dispatch(setThemes(value));
  };

  return (
    <div className={`flex items-center justify-between w-full ${cssClassName}`}>
      <div className={`flex items-center gap-8 4xl:gap-12 5xl:gap-20 8xl:gap-44`}>
        <ResponsiveIcon
          className="fill-secondary animate__animated animate__fadeIn"
          name={IconNames.moon}
          baseWidth={iconSize}
          baseHeight={iconSize}
          onClick={() => handleToggleChange(isDarkTheme)}
        />
        <span className={`text-secondary text-body 4xl:text-subtitle 5xl:text-bigTitle 8xl:text-LPTitle ${textCssClassName || ''}`}>
          {text}
        </span>
      </div>
      <div className="flex items-center gap-20 4xl:gap-36 5xl:gap-54 8xl:gap-100">
        <ToggleSwitch onChange={handleToggleChange} value={isDarkTheme} />
      </div>
    </div>
  );
};

export default ThemeToggle;
