import { useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { ADDRESS_MUTATION, LMD_QUERY } from '@fe-monorepo/data-access';
import {
    DigitalFulfillmentInput,
    ShippingAddressInput,
    BillingAddressInput,
    AddressMutationResponse,
    CheckStoreDistanceResponse,
    CheckStoreDistanceInput,
} from '@fe-monorepo/models';
import { useInvoice } from './useInvoice';

const useAddress = () => {
    const { getInvoice, invoiceInfo } = useInvoice(true);
    const [updateDigitalFulfillmentGql, { data: digitalFulfillmentData }] = useMutation<AddressMutationResponse>(
        ADDRESS_MUTATION.updateDigitalFulfillmentMethod,
        {
            errorPolicy: 'all',
        },
    );

    const [updateBillingAddressGql, { data: billingAddressData, error: errorBillingAddress }] = useMutation<AddressMutationResponse>(
        ADDRESS_MUTATION.updateBillingAddress,
        {
            errorPolicy: 'all',
        },
    );

    const [updateShippingAddressGql, { data: shippingAddressData, error: errorShippingAddress }] = useMutation<AddressMutationResponse>(
        ADDRESS_MUTATION.updateShippingAddress,
        {
            errorPolicy: 'all',
        },
    );

    const [checkStoreDistanceInfo, { data: checkStoreDistanceData }] = useLazyQuery<CheckStoreDistanceResponse, CheckStoreDistanceInput>(
        LMD_QUERY.checkStoreDistance,
        {
            errorPolicy: 'all',
        },
    );

    const updateDigitalFulfillment = async (digitalFulfillmentInput: DigitalFulfillmentInput) => {
        updateDigitalFulfillmentGql({ variables: { details: digitalFulfillmentInput } });
    };

    const updateBillingAddress = async (billingAddressInput: BillingAddressInput) => {
        updateBillingAddressGql({ variables: { details: billingAddressInput } });
    };

    const updateShippingAddress = async (shippingAddressInput: ShippingAddressInput) => {
        const { data } = await updateShippingAddressGql({ variables: { details: shippingAddressInput } });
        return data?.updateShippingAddress;
    };

    const checkStoreDistance = async (checkStoreInput: CheckStoreDistanceInput) => {
        const { data } = await checkStoreDistanceInfo({
            variables: {
                ...checkStoreInput,
            },
        });

        return data?.checkStoreDistance;
    };

    useEffect(() => {
        if (shippingAddressData?.updateShippingAddress?.is_successful && invoiceInfo && invoiceInfo?.invoice_number !== "") {
            getInvoice({
                details: {
                    invoice_number: invoiceInfo.invoice_number,
                },
            });
        }
    }, [shippingAddressData]);

    return {
        digitalFulfillmentData,
        billingAddressData,
        shippingAddressData,
        updateDigitalFulfillment,
        updateBillingAddress,
        updateShippingAddress,
        checkStoreDistance,
    };
};

export { useAddress };
