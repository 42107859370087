import BreadCrumbs from '@fe-web/Atoms/BreadCrumb';
import Header from '@fe-web/Organisms/Header/Header';
import { ReactElement } from 'react';

export interface BreadLink {
  label: string;
}

interface ErrorPageLayoutProps {
  children: ReactElement | ReactElement[];
  list?: BreadLink[];
  footer?: ReactElement | ReactElement[];
  hasHeader?: boolean;
  hasFooter?: boolean;
}

const ErrorPageLayout = ({ children, list, footer, hasHeader = true, hasFooter = true }: ErrorPageLayoutProps) => {
  return (
    <div>
      <div className="w-screen h-screen flex flex-col bg-primary">
        {hasHeader && <Header />}
        {list && (
          <BreadCrumbs<BreadLink>
            onClick={(item: BreadLink) => {}}
            getString={(item: BreadLink) => item.label}
            list={list}
            className="md:hidden"
          />
        )}
        {children}
      </div>
      {hasFooter && footer}
    </div>
  );
};

export default ErrorPageLayout;
