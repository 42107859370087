import { IMAGES } from '@fe-monorepo/assets';
import { IMAGE_ALT_LABELS, TxKeyPath } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import Image from '@fe-web/Atoms/Media/Image';

import { InvoiceProduct, ReturnProducts } from '../Model/InvoiceProduct';

const ProductImageLayered = ({
  product,
  handleClickPDP,
  isImageClickable,
  isDisabled,
}: {
  product: ReturnProducts;
  handleClickPDP?: (action_from: 'image' | 'name') => void;
  isImageClickable: boolean | undefined;
  isDisabled?: boolean;
}) => {
  const numLayers = Number(product.qty);
  const numRender = numLayers > 2 ? 2 : numLayers > 1 ? 1 : 0;
  const multiplier = 10;
  const { translate } = useTranslate();

  return (
    <div className={`relative shrink-0 w-[6rem] h-[6rem] 8K:h-[5rem] 8K:w-[5rem]`}>
      <div className="pt-[100%]">
        <div
          className={`bg-primary 
                absolute
                top-0
                left-0
                z-[2]
                rounded-[3px]
                overflow-hidden
                ${
                  numLayers > 2
                    ? 'w-[calc(100%-20px)] h-[calc(100%-20px)] drop-shadow-lg'
                    : numLayers === 2
                    ? 'w-[calc(100%-10px)] h-[calc(100%-10px)] drop-shadow-lg'
                    : 'w-full h-full'
                }
                ${isImageClickable ? 'hover:cursor-pointer' : ''}`}
        >
          <Image
            img={product?.media.length > 0 ? product?.media : IMAGES.Product_Placeholder}
            alt={translate(IMAGE_ALT_LABELS.return_product_layered_thumbnail as TxKeyPath) || ''}
            divStyle={`
                            ${isDisabled ? 'bg-gray-500 opacity-50' : ''}
                            bg-secondary/[0.06]  
                            py-[1rem] px-[1rem]
                            w-full
                            h-full
                        
                        `}
            imgStyle="w-full h-full object-contain"
          />
        </div>
        {Array.from(Array(numRender))?.map((_, index) => {
          const subtractor = numRender * multiplier;
          const position = (index + 1) * multiplier;
          return (
            <div
              key={index}
              className={`absolute bg-primary z-[${1 - index}] rounded-[3px] ${
                index + 1 < numRender ? 'drop-shadow-lg' : ''
              } w-[calc(100%-${subtractor}px)] h-[calc(100%-${subtractor}px)]`}
              style={{ top: position, left: position }}
            >
              <div className="bg-secondary/[0.06] w-full h-full"></div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProductImageLayered;
