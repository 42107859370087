import { useAppState } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const usePageLayout = () => {
  const { changeLanguage } = useAppState();
  const prefs = useSelector((state: RootState) => state.app);
  const [direction, setDirection] = useState('ltr');
  const { language, headerColorChange } = prefs;
  const [isRtl, setIsRtl] = useState<boolean>(language === 'en' ? false : true);
  const btnClass =
    direction === 'ltr'
      ? `border-sunset hover:border-sunset hover:text-sunset rounded-[0.175rem] after:bg-sunset before:bg-white100`
      : `border-sunset hover:border-sunset hover:text-sunset rounded-[0.175rem] before:bg-sunset after:bg-white100`;
  const btnClass2 = headerColorChange
    ? direction === 'ltr'
      ? `after:bg-primary before:bg-sunset`
      : `before:bg-primary after:bg-sunset`
    : direction === 'ltr'
    ? `after:bg-white100 before:bg-sunset`
    : `before:bg-white100 after:bg-sunset`;
  const btnClass3 = direction === 'ltr' ? `after:bg-secondary before:bg-sunset` : `before:bg-secondary after:bg-sunset`;
  const handleLanguageToggle = (lang: 'en' | 'ar' = language) => {
    changeLanguage(lang);
  };
  useEffect(() => {
    setDirection(language === 'en' ? 'ltr' : 'rtl');
    setIsRtl(language === 'en' ? false : true);
  }, [language]);

  return {
    direction,
    language,
    handleLanguageToggle,
    btnClass,
    btnClass2,
    btnClass3,
    isRtl,
  };
};

export default usePageLayout;
