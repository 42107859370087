import { useProduct, useUserProfile } from '@fe-monorepo/hooks';
import { ContentModel, SearchData, ShopModel } from '@fe-monorepo/models';
import { RootState } from '@fe-monorepo/store';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import LoadMore from '../../../components/Atoms/Load/LoadMore';
import ProductCardSearch from '../../../components/Organisms/ProductCardSearch/ProductCardSearch';
import { SearchTab } from '../../Search';
import SearchSectionResult from '../Components/SearchSectionResult';
import ProductResult from './ProductResult';

type SearchShopResultProps = {
  shopData?: SearchData<ShopModel>;
  isLoading: boolean;
  tabData?: ContentModel[];
  selectedTab: string;
  loadMore: () => void;
  setSelectedTab: (key: SearchTab) => void;
  isMobile?: boolean;
  searchValue: string;
};

const SearchShopResult = (props: SearchShopResultProps) => {
  const prefs = useSelector((state: RootState) => state.app);
  const dir = prefs?.isRTL ? 'rtl' : 'ltr';

  const SectionData = () => {
    const isShopTab = props?.selectedTab === 'shop';
    const productsList = isShopTab ? props?.tabData : props?.shopData?.result;
    const { wishlistData, getAllWishlist, addToWishList, removeToWishList } = useProduct();
    const isRTL = useSelector((state: RootState) => state.app.isRTL);
    const { isLoggedIn } = useUserProfile();

    useEffect(() => {
      isLoggedIn && getAllWishlist({ direction: '', last_cursor: '' });
    }, [isLoggedIn]);

    return (
      <>
        <div
          className={`${
            props.isMobile
              ? 'divide-y divide-black10 dark:divide-white10'
              : props.selectedTab === 'all'
              ? ''
              : 'grid grid-cols-4 gap-[1.5rem]'
          }`}
        >
          {productsList &&
            productsList.slice(0, props.selectedTab === 'all' ? 5 : productsList.length).map((product, index) => {
              const isWishList = wishlistData ? (wishlistData.find(item => item.product_code === product.id) ? true : false) : false;
              return props.isMobile || props.selectedTab === 'all' ? (
                <ProductResult key={index} product={product as ShopModel} searchValue={props.searchValue} isRTL={isRTL} />
              ) : (
                <ProductCardSearch
                  key={index}
                  product={product as ShopModel}
                  isMobile={props.isMobile}
                  isWishList={isWishList}
                  isRTL={isRTL}
                  addToWishList={addToWishList}
                  removeToWishList={removeToWishList}
                  getAllWishlist={getAllWishlist}
                />
              );
            })}
        </div>
        {isShopTab && <LoadMore isLoading={props?.isLoading} loadMore={props?.loadMore} />}
      </>
    );
  };

  return (
    <SearchSectionResult
      dir={dir}
      selectedTab={props.selectedTab}
      isLoading={props.isLoading}
      sectionTitle={t('shop.value')}
      totalResults={props?.shopData?.total}
      SectionData={<SectionData />}
      selectTab={() => props?.setSelectedTab('shop')}
      isMobile={props.isMobile}
    />
  );
};

export default SearchShopResult;
