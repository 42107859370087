import { IMAGE_ALT_LABELS, TxKeyPath, convertPriceWithSymbol } from '@fe-monorepo/helper';
import { useReturnProduct, useToastMessage, useTranslate } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import Button from '@fe-web/Atoms/Buttons/Button';
import ToastMessage from '@fe-web/Atoms/ToastMessage';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import useHeaderState from '@fe-web/hooks/useHeaderState';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from '../../../../app.routes.enum';
import LoadingScreen from './LoadingScreen';
import { OptionSort2, OptionSortRevised, returnProductIDModel } from './Model/InvoiceProduct';

interface ReturnsSendRequestProps {
  returnData: { [key: string]: OptionSort2 };
  handleBackButton: () => void;
  invoiceNumber: string | undefined;
  returnNumber: string | undefined;
}

const ReturnsSendRequest = ({ returnData, handleBackButton, invoiceNumber, returnNumber }: ReturnsSendRequestProps) => {
  const userState = useSelector((state: RootState) => state.user.userContext);
  const isHeaderDark = useSelector((state: RootState) => state.app.headerColorChange);
  const { dir } = useHeaderState();
  const { email, mobile } = userState;
  const { translate } = useTranslate();
  const navigate = useNavigate();
  const currency = useSelector((state: RootState) => state.app.currency);
  const [returnRequestForm, setReturnRequestForm] = useState<OptionSortRevised[]>([]);
  const [returnProductID, setReturnProductID] = useState<returnProductIDModel[]>([]);
  const { successToastMessage, errorToastMessage } = useToastMessage();
  const [sendRequestHasError, setSendRequestHasError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const { createAddDataWithResult, addData, createUpdateDataWithResult } = useReturnProduct();

  //create a useEffect that will get the returnProductID for each product in the returnRequestForm
  //take note that for we need to loop the returnRequestForm and get the tracking_number for each product
  //we call createAddDataWithResult to get the returnProductID
  //createAddDataWithResult has a parameter of return_number and tracking_number as an object
  //the return_number will be the returnNumber and the tracking_number will be the tracking_number of the product
  useEffect(() => {
    setIsLoading(true); // Set isLoading to true before the async operations
    Promise.all(
      returnRequestForm.map(product => {
        return createAddDataWithResult({
          return_number: returnNumber as string,
          tracking_number: product.tracking_number as string,
        }).then(result => {
          //push the value of return_product_id to the returnProductID state
          setReturnProductID((prevState: returnProductIDModel[]) => {
            const returnProductId = String(result.data?.omsReturnProdAdd.data?.return_product_id);
            if (!prevState.some(item => item.return_product_id === returnProductId)) {
              return [...prevState, { return_product_id: returnProductId, tracking_number: product.tracking_number as string }];
            }
            return prevState;
          });
        });
      }),
    )
      .then(() => {
        setIsLoading(false); // Set isLoading to false after all async operations are done
      })
      .catch(error => {
        console.error(error);
        setIsLoading(false); // Also set isLoading to false if an error occurs
      });
  }, [returnRequestForm]);

  //create a function that will check email and mobile if it has a value, if both has value return the phone number, if only email has value return the email, if both has value then return the mobile
  function checkEmailAndMobile() {
    if (email && mobile) {
      return (
        <div dir="ltr" className="rtl:text-right text-fs-body text-secondary not-italic font-regular leading-6">
          {mobile}
        </div>
      );
    } else if (email) {
      return <div className="text-fs-body text-secondary not-italic font-regular leading-6">{email}</div>;
    } else {
      return (
        <div dir="ltr" className="rtl:text-right text-fs-body text-secondary not-italic font-regular leading-6">
          {mobile}
        </div>
      );
    }
  }

  //create a function that will check email and mobile if it has a value, if both has value return translate('shop.cart.returns_or_exchange.your_phone_number'), if only email has value return translate('shop.cart.returns_or_exchange.your_email'), if both has value then return translate('shop.cart.returns_or_exchange.your_phone_number')
  function checkEmailAndMobileTranslate() {
    if (email && mobile) {
      return translate('shop.cart.returns_or_exchange.your_phone_number');
    } else if (email) {
      return translate('shop.cart.returns_or_exchange.your_email');
    } else {
      return translate('shop.cart.returns_or_exchange.your_phone_number');
    }
  }

  //create a function that will return the total refund base from the isSelected is true and loop based on the quantity in the return data
  function getTotalRefund() {
    let total = 0;
    Object.values(returnData).map(
      product =>
        product.isSelected &&
        [...Array(product.quantity)].map((_, index) => {
          total += product.price;
        }),
    );
    return total;
  }

  async function handleSendRequest() {
    try {
      // map returns an array of promises
      const promises = returnRequestForm.map(product => {
        return createUpdateDataWithResult({
          return_product_id: Number(product.return_product_id),
          reason_code: product.id as string,
          img_urls: product.imageURLs as [string],
        }).then(result => {
          if (result.data?.omsReturnProdUpdate.error_msg) {
            if (result.data?.omsReturnProdUpdate.error_msg === 'Missing or Invalid return product id') {
              errorToastMessage({
                message: <ToastMessage type="error"> {translate('error.return_request_err1')}</ToastMessage>,
              });
            } else {
              errorToastMessage({
                message: <ToastMessage type="error"> {result.data?.omsReturnProdUpdate.error_msg}</ToastMessage>,
              });
            }

            setSendRequestHasError(true);
            throw new Error(result.data?.omsReturnProdUpdate.error_msg); // throw an error to reject the promise
          }
        });
      });

      // wait for all promises to resolve
      await Promise.all(promises);

      // if there was no error, navigate
      if (!sendRequestHasError) {
        navigate(`${AppRoutes.myorders}/return/${invoiceNumber}/confirmation`);
      }
    } catch (error) {
      // handle any errors here
      console.error(error);
    }
  }

  useEffect(() => {
    const temp: OptionSortRevised[] = [];
    Object.values(returnData).map(
      product =>
        product.isSelected &&
        [...Array(product.quantity)].map((_, index) => {
          const imageURLs = product?.option[index]?.imageFiles?.filter(file => file)?.map(file => file.url) ?? [];

          temp.push({
            id: product.option[index].id,
            label: product.option[index].label,
            imageFiles: product.option[index].imageFiles,
            tracking_number: product.option[index].tracking_number,
            imageURLs: imageURLs,
          });
        }),
    );
    setReturnRequestForm(temp);
  }, [returnData]);

  //create a useEffect that will update the return_product_id in the returnRequestForm using tracking_number as the key
  useEffect(() => {
    setReturnRequestForm(prevState => {
      return prevState.map(product => {
        return {
          ...product,
          return_product_id: returnProductID.find(item => item.tracking_number === product.tracking_number)?.return_product_id,
        };
      });
    });
  }, [returnProductID]);

  useEffect(() => {
    mixpanelHelper.trackPageView(
      'ORDER DETAILS PAGE - RETURN ORDER PAGE - REFUND REQUEST PAGE',
      'my_orders_order_tab_odp_return_order_refund_request_page',
      window.location.pathname,
      document.referrer,
    );
  }, []);

  return (
    <div className="mdxs:w-full SD:w-[43rem] 2K:w-[43rem]">
      <div className="mb-[2.5rem]">
        <div className="text-fs-subtitle text-secondary not-italic font-medium leading-8 tracking-[-0.015rem]">
          {translate('shop.cart.returns_or_exchange.your_refund')}
        </div>
        <div className="text-fs-big-title text-secondary not-italic font-medium leading-[3rem] tracking-[-0.075rem]">
          {convertPriceWithSymbol(getTotalRefund(), 'commaDecimal')}
        </div>
      </div>
      <div className="mb-[1rem] XDA:hidden SD:hidden 2K:hidden">
        <span className="text-fs-caption text-secondary/60 not-italic font-regular leading-4">
          {translate('shop.cart.returns_or_exchange.returns')}
        </span>
      </div>
      {/* This is the container for the products that will be returned */}
      <div className="mb-[2.5rem]">
        {/* Container for the products to be refunded  */}
        {Object.values(returnData).map(
          product =>
            product.isSelected &&
            [...Array(product.quantity)].map((_, index) => (
              <>
                <div className="flex gap-[0.75rem]" key={index}>
                  <div className="w-[3.25rem]">
                    <div
                      className="
                    bg-secondary/[0.06]  
                    py-[1rem] px-[1rem]
                    w-full
                    h-full
                "
                    >
                      <img
                        src={product.imageURL}
                        alt={translate(IMAGE_ALT_LABELS.return_refund_product_image as TxKeyPath) || ''}
                        className="w-full h-full object-contain"
                      />
                    </div>
                  </div>

                  <div className="flex flex-col grow">
                    <div>
                      <div className="text-fs-body-small text-secondary not-italic font-medium leading-5 mb-[0.5rem]">{product.name}</div>
                      <div className="flex justify-between">
                        <div className="text-fs-caption text-secondary not-italic font-regular leading-4">
                          1 x {convertPriceWithSymbol(product?.price, 'commaDecimal')}
                        </div>
                        <div className="text-fs-caption text-secondary not-italic font-medium leading-4">
                          {convertPriceWithSymbol(product?.price, 'commaDecimal')}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="w-full text-secondary/10 bg-secondary/10 border-[none] my-[1.5rem]" />
              </>
            )),
        )}
        {/* Total for refund */}

        <div className="flex items-center justify-between mb-[2.5rem]">
          <div className="text-secondary text-fs-body-small not-italic font-medium leading-5">
            {translate('shop.cart.returns_or_exchange.total_for')}
          </div>
          <div className="text-secondary text-fs-body-large not-italic font-medium leading-6">
            {convertPriceWithSymbol(getTotalRefund(), 'commaDecimal')}
          </div>
        </div>
        <hr className="w-full text-secondary/10 bg-secondary/10 border-[none] my-[2.5rem]" />
      </div>
      {/* This is the container for the Request approval section */}
      <div className="mb-[2.5rem]">
        <h1 className="mb-[1.5rem] text-fs-title text-secondary not-italic font-medium leading-8 tracking-[-0.015rem]">
          {translate('shop.cart.returns_or_exchange.request_approval')}
        </h1>
        <p className="mb-[0.75rem] mdxs:w-full SD:w-full 2K:w-[43rem] text-fs-body text-secondary/70 not-italic font-regular leading-6">
          {translate('shop.cart.returns_or_exchange.we_are_checking')}
        </p>
        <span className="text-fs-body text-secondary/70 not-italic font-regular leading-6">{checkEmailAndMobileTranslate()}</span>
        {checkEmailAndMobile()}
      </div>
      <div className="mdxs:w-full SD:w-full 2K:w-[43rem] flex mdxs:flex-col gap-[1.25rem] justify-end mt-[2.5rem] mb-[20rem]">
        {/* Back Button Here */}
        <Button
          text={translate('shop.cart.returns_or_exchange.back')}
          style={`
                    mdxs:order-2 mdxs:w-full mdMaxxS:w-full SD:w-[7.75rem] text-btn-primary text-fs-body not-italic font-medium leading-6 px-[1.5rem] py-[0.5rem] rounded border border-[color:var(--brand-sunset,#E95F2A)] border-solid gap-[8px] z-1
                                  text-white100 text-sunsetText mb-[0px]
                                  xsMax:mb-[0px] items-end
                                  rounded-sm ${
                                    isHeaderDark
                                      ? dir === 'ltr'
                                        ? `after:bg-primary before:bg-sunset`
                                        : `before:bg-white100 after:bg-sunset`
                                      : `${dir === 'ltr' ? `after:bg-white100 before:bg-sunset` : `before:bg-white100 after:bg-sunset`}`
                                  }
                                  hover:text-white100 rounded-sm`}
          disabled={false}
          onClick={() => handleBackButton()}
        />

        <Button
          text={translate('shop.cart.returns_or_exchange.send_request')}
          style={`flex items-center justify-center mdxs:w-full mdMaxxS:w-full text-fs-body not-italic font-medium leading-6 px-[1.5rem] py-[0.5rem] text-white100 border-[1px] border-transparent rounded-sm ${
            isHeaderDark
              ? dir === 'ltr'
                ? `after:bg-sunset before:bg-white100`
                : `before:bg-sunset after:bg-white100`
              : `${dir === 'ltr' ? `after:bg-sunset before:bg-white100` : `before:bg-sunset after:bg-white100`}
hover:-border[1px] hover:border-sunset`
          } hover:text-sunsetText rounded-sm`}
          disabled={false}
          onClick={() => {
            handleSendRequest().catch(error => {
              // handle error here
              console.error(error);
            });
          }}
        />
      </div>
      <LoadingScreen isLoading={isLoading} />
    </div>
  );
};

export default ReturnsSendRequest;
