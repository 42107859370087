import { IconNames, MixPanelEvents, convertPriceWithSymbol } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import { AddressInput, DeliveryAddressInput, DeliveryAddressModel, InvoiceProduct } from '@fe-monorepo/models';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import YellowMessage from '@fe-web/Molecules/YellowMessage';
import Products from '@fe-web/Organisms/OrderProduct/OrderProduct';
import { AppRoutes } from 'apps/fe-web/src/app/app.routes.enum';
import mixpanelHelper from 'apps/fe-web/src/app/helpers/mixpanelHelper';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Modal from '../../../../../components/Templates/Modal/Modal';
import ShopMainAddAddress from '../../../ShopAddAddress/ShopMainAddAddress';
import { StyledH2 } from '../../CartPage.styled';
import DigitalDelivery from '../DigitalDelivery';
import PaymentMethod from '../PaymentMethod';
import RecipientsDetails from '../RecipientsDetails/RecipientsDetails';

interface CheckoutProps {
  addresses: DeliveryAddressModel[];
  selectedAddress?: DeliveryAddressModel;
  selectAddress: (address: DeliveryAddressModel) => void;
  onClickNewAddress: () => void;
  onClickDeleteAddress: (address: AddressInput) => void;
  onClickEditAddress: (id: number) => void;
  // isReceiveInvoice: boolean;
  // receiveInvoice: (value: boolean) => void;
  products: InvoiceProduct[];
  language: 'en' | 'ar';
  isRTL?: boolean;
  openModal?: boolean;
  setModalClose?: () => void;
  isMobile?: boolean;
  isAddNewAddress?: boolean;
  addressForUpdate?: DeliveryAddressModel;
  onClickExistingAddressButton: (id: number) => void;
  onClickAddAddressNameButton: () => void;
  onClickSaveAddressAction: (addresInput?: DeliveryAddressInput) => void;
  user?: string;
  updatePaymentMethod?: (option: string) => void;
  paymentMethod?: string;
  setPaymentMobileNumber?: (mobile: string) => void;
  setPaymentEmail?: (email: string) => void;
  paymentEmail?: string;
  setPaymentMobileCodeISO?: (code: string) => void;
  paymentMethodDisabled?: boolean;
  isApplePaySupported?: boolean;
  selectedAddressError?: string;
  subtotalPrice: number;
  totalPrice: number;
  cartItems: InvoiceProduct[];
  currencyCode: string;
}

const Checkout = (props: CheckoutProps) => {
  const {
    addresses,
    selectedAddress,
    selectAddress,
    onClickNewAddress,
    onClickDeleteAddress,
    onClickEditAddress,
    // isReceiveInvoice,
    // receiveInvoice,
    products,
    language,
    isRTL,
    openModal,
    setModalClose,
    isMobile,
    isAddNewAddress,
    addressForUpdate,
    onClickExistingAddressButton,
    onClickAddAddressNameButton,
    onClickSaveAddressAction,
    user,
    paymentMethod,
    updatePaymentMethod,
    setPaymentMobileNumber,
    setPaymentEmail,
    paymentEmail,
    setPaymentMobileCodeISO,
    paymentMethodDisabled,
    isApplePaySupported,
    selectedAddressError,
    subtotalPrice,
    totalPrice,
    cartItems,
    currencyCode,
  } = props;
  const { translate } = useTranslate();
  // const { deliveryAddress, getDeliveryAddress } = useDeliveryAddress();

  const location = useLocation();

  const digital_products = products.filter(prod => prod.is_digital === 1);
  // useEffect(() => {
  //     getDeliveryAddress();
  // }, [openModal]);

  useEffect(() => {
    const productList = cartItems.map(item => item.product_name);

    const formattedSubTotalPrice = convertPriceWithSymbol(subtotalPrice, 'commaDecimal', true);
    const formattedTotalPrice = convertPriceWithSymbol(totalPrice, 'commaDecimal', true);

    if (location.pathname === AppRoutes.cart) {
      mixpanelHelper.trackPageView('SHOP - CHECKOUT PAGE', 'shop_checkout_page', window.location.pathname, document.referrer);

      mixpanelHelper.trackEvent(MixPanelEvents.deliveryAndPayment, {
        Products: productList,
        'Cart Sub Total': formattedSubTotalPrice,
        Address: addresses.length > 0,
        'Cart Total': formattedTotalPrice,
        Currency: currencyCode,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (openModal) {
      mixpanelHelper.trackPageView(
        'SHOP - CREATE AND UPDATE ADDRESS PAGE',
        'shop_create_update_address',
        window.location.pathname,
        document.referrer,
      );
    }
  }, [openModal]);

  return (
    <>
      <div className="">
        <StyledH2>{translate('shop.cart.checkout_recipients_details')}</StyledH2>
        {selectedAddressError && (
          <YellowMessage
            message={selectedAddressError}
            icon={
              <div className="relative mr-[0.5rem] top-[0.15rem]">
                <ResponsiveIcon
                  name={IconNames.warningOutlined}
                  baseWidth={22}
                  baseHeight={19}
                  className="!fill-sunset"
                  iconClasses="fill-sunset"
                />
              </div>
            }
            containerClassName="mt-[1.5rem]"
          />
        )}
        <RecipientsDetails
          addresses={addresses}
          selectedAddress={selectedAddress}
          selectAddress={selectAddress}
          editLabel={translate('action_edit') || ''}
          deleteLabel={translate('action_delete') || ''}
          onClickNewAddress={onClickNewAddress}
          onClickDeleteAddress={onClickDeleteAddress}
          onClickEditAddress={onClickEditAddress}
        />
      </div>
      {/* <div className="pt-[2rem]">
                <label className="flex gap-x-[0.75rem] items-start">
                    <div>
                        <input
                            type="checkbox"
                            checked={isReceiveInvoice}
                            onChange={e => receiveInvoice(e?.target?.checked)}
                            className="absolute invisible"
                        />
                        {isReceiveInvoice ? (
                            <ResponsiveIcon name={IconNames.checkboxChecked} baseWidth={24} baseHeight={24} className="fill-sunset" />
                        ) : (
                            <ResponsiveIcon
                                name={IconNames.checkboxUnchecked}
                                baseWidth={24}
                                baseHeight={24}
                                className="fill-secondary/[0.5]"
                            />
                        )}
                    </div>
                    <div className="flex flex-col gap-[0.25rem]">
                        <span className="font-medium text-fs-body-small">{translate('shop.cart.checkout_receive_invoice_label')}</span>
                        <p className="text-fs-caption font-regular text-secondary/[0.5]">
                            {translate('shop.cart.checkout_receive_invoice_description')}
                        </p>
                    </div>
                </label>
            </div> */}
      <div className="pt-[2rem]">
        <StyledH2>{translate('shop_cart')}</StyledH2>
        {user !== '' && (
          <p className="block text-fs-body font-regular my-[1rem]">
            {translate('shop.cart.cart_items_type.from')} <span className="text-sunset">{user}</span>
          </p>
        )}
        <Products
          products={products}
          language={language}
          showCaption={true}
          showDigitalProductTag={true}
          isRTL={isRTL}
          isMobile={isMobile}
          showCaptionForUnavailableProduct={true}
        />
      </div>
      <div className="pt-[2rem]">
        <StyledH2>{translate('shop.cart.digital_delivery_title')}</StyledH2>
        <DigitalDelivery content={translate('shop.cart.digital_delivery_description') || ''} />
      </div>
      <div className="pt-[2rem]">
        <StyledH2>{translate('shop.cart.payment_method_title')}</StyledH2>
        <PaymentMethod
          updatePaymentMethod={updatePaymentMethod}
          paymentMethod={paymentMethodDisabled ? '' : paymentMethod}
          setPaymentMobileNumber={setPaymentMobileNumber}
          setPaymentEmail={setPaymentEmail}
          paymentEmail={paymentEmail}
          setPaymentMobileCodeISO={setPaymentMobileCodeISO}
          isMobile={isMobile}
          hasDigitalProducts={digital_products.length > 0}
          paymentMethodDisabled={paymentMethodDisabled}
          isApplePaySupported={isApplePaySupported}
        />
      </div>
      <Modal open={openModal}>
        <ShopMainAddAddress
          setModalClose={() => {
            if (setModalClose) setModalClose();
          }}
          addressList={addresses}
          isAddNewAddress={isAddNewAddress}
          addressForUpdate={addressForUpdate}
          onClickExistingAddressButton={onClickExistingAddressButton}
          onClickAddAddressNameButton={onClickAddAddressNameButton}
          onClickSaveAddressAction={onClickSaveAddressAction}
        />
      </Modal>
    </>
  );
};

export default Checkout;
