import { useCreateReturns, useGetProducts, useGetRetailInfo, useInvoice, useOrder, useTranslate } from '@fe-monorepo/hooks';
import Container from '@fe-web/Templates/Container';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { AppRoutes } from '../../../app.routes.enum';
import LoadingScreen from './components/LoadingScreen';
import { OptionSort2, ReturnProducts } from './components/Model/InvoiceProduct';
import ProgressBar from './components/ProgressBar';
import ReturnHeader from './components/ReturnHeader';
import ReturnsOverview from './components/ReturnsOverview';
import ReturnsPhoto from './components/ReturnsPhoto';
import ReturnsSendRequest from './components/ReturnsSendRequest';
import TotalRefund from './components/TotalRefund';

const Return = () => {
  const { translate } = useTranslate();
  const [steps, setSteps] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const { resend, getAllOrdersFiltered, ordersfiltered } = useOrder();
  const { getInvoice, invoiceInfoData, downloadInvoice } = useInvoice();
  const { invoiceNumber } = useParams<{ invoiceNumber: string }>();
  const [returnData, setReturnData] = useState<{ [key: string]: OptionSort2 }>({});
  const [isTotalRefundVisible, setIsTotalRefundVisible] = useState<boolean>(false);
  const [returnNumber, setReturnNumber] = useState<string>('');
  const [eligibleProductForReturn, setEligibleProductForReturn] = useState<ReturnProducts[]>([]);

  const { createReturnNumber, return_number } = useCreateReturns();
  const { getProduct, getProductData } = useGetProducts();
  const { getRetailInfo, getRetailInfoData } = useGetRetailInfo();
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    createReturnNumber({
      invoice_number: invoiceNumber as string,
    });
    getProduct({
      invoice_number: invoiceNumber as string,
    });
  }, [invoiceNumber]);

  useEffect(() => {
    setReturnNumber(return_number);
    if (getProductData) {
      setEligibleProductForReturn(getProductData?.data?.list);
      setIsLoading(false); // set isLoading to false when getProductData has data
    } else {
      setIsLoading(true); // set isLoading to true when getProductData is null, undefined or empty
    }
  }, [return_number, getProductData]);

  useEffect(() => {
    getAllOrdersFiltered({
      details: {
        invoice_number: invoiceNumber,
      },
    });
    getInvoice({ details: { invoice_number: invoiceNumber as string } });
  }, [invoiceNumber]);

  function handleContinue(data: { [key: string]: OptionSort2 }) {
    setPage(page + 1);
    setSteps(steps + 1);
    setReturnData(data);
  }

  function handleBackButton() {
    setPage(page - 1);
    setSteps(steps - 1);
  }

  useEffect(() => {
    const isGreaterThanSevenDays = invoiceInfoData?.data?.info?.shipments?.some(shipment => {
      if (shipment?.delivered_at) {
        const deliveryDate = new Date(shipment?.delivered_at);
        const sevenDaysPeriod = new Date(deliveryDate.setDate(deliveryDate.getDate() + 7));
        const today = new Date();
        if (today > sevenDaysPeriod) {
          return true;
        } else {
          return false;
        }
      }
    });

    if (isGreaterThanSevenDays) {
      navigate(AppRoutes.myorders);
    }
  }, [invoiceInfoData]);

  useEffect(() => {
    mixpanelHelper.trackPageView(
      'ORDER DETAILS PAGE - RETURN ORDER PAGE',
      'my_orders_order_tab_odp_return_order_page',
      window.location.pathname,
      document.referrer,
    );
  }, []);

  return (
    <Container className="flex flex-col w-full h-full bg-transparent gap-0 pl-[20rem] lg:pl-[7.5rem] md:pl-[4rem] sm:pl-[2rem] xsMax:pl-[1.25rem] xs:pl-[1.3rem]">
      <ReturnHeader />
      <ProgressBar steps={steps} />
      <div className="mdMaxM:flex mdMaxM:justify-between mdxs:flex-col mdMaxxSD:flex-col mdMaxM:flex-col grid grid-cols-3 gap-[1.5rem]">
        {invoiceInfoData && page === 0 && (
          <ReturnsOverview
            products={invoiceInfoData?.data?.info?.products.filter(product => product.is_digital === 0)}
            returnProducts={eligibleProductForReturn}
            handleContinue={handleContinue}
            setIsTotalRefundVisible={setIsTotalRefundVisible}
            setReturnData={setReturnData}
          >
            {isTotalRefundVisible && (
              <TotalRefund
                products={invoiceInfoData?.data?.info?.products.filter(product => product.is_digital === 0)}
                order_id={String(invoiceInfoData?.data?.info?.invoice_number)}
                order_date={invoiceInfoData?.data.info.created_at}
                returnData={returnData}
              />
            )}
          </ReturnsOverview>
        )}
        {page === 1 && (
          <ReturnsPhoto
            returnData={returnData}
            setReturnData={setReturnData}
            handleBackButton={handleBackButton}
            handleContinue={handleContinue}
            returnNumber={returnNumber}
          >
            <TotalRefund
              products={invoiceInfoData?.data?.info?.products.filter(product => product.is_digital === 0)}
              order_id={invoiceInfoData?.data?.info?.invoice_number}
              order_date={invoiceInfoData?.data.info.created_at}
              returnData={returnData}
            />
          </ReturnsPhoto>
        )}
        {page === 2 && (
          <ReturnsSendRequest
            returnData={returnData}
            handleBackButton={handleBackButton}
            invoiceNumber={invoiceNumber}
            returnNumber={returnNumber}
          />
        )}
        <div className="mdxs:hidden mdMaxxSD:hidden col-span-1">
          {isTotalRefundVisible && page !== 2 && (
            <TotalRefund
              products={invoiceInfoData?.data?.info?.products}
              order_id={String(invoiceInfoData?.data?.info?.invoice_number)}
              order_date={invoiceInfoData?.data?.info?.created_at}
              returnData={returnData}
            />
          )}
        </div>
      </div>
      <LoadingScreen isLoading={isLoading} />
    </Container>
  );
};

export default Return;
