import { IconNames, convertPriceWithSymbol } from '@fe-monorepo/helper';
import HoverText from '@fe-web/Atoms/Text/HoverText';
import { AppRoutes } from 'apps/fe-web/src/app/app.routes.enum';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ResponsiveIcon from '../../../../../../components/Atoms/Icon/ResponsiveIcon';

// Installemnts Delivery Buyer protection
interface StaticDetailProps {
  icon: IconNames;
  title: string;
  subtitle?: {
    value: string;
    showTabby?: boolean;
    learnMoreClick: () => void;
  };
}

const StaticDetail = (props: StaticDetailProps) => {
  const { t } = useTranslation();
  const { icon, title, subtitle } = props;

  return (
    <div
      className={`
          w-full
          flex SD:items-center
          flex-col SD:flex-row
          gap-4 SD:gap-0
          responsive-text-bodySmall
        `}
    >
      <div className="flex items-center">
        <ResponsiveIcon
          className={`
                      fill-secondary
                      me-12 2K:me-[21.33px] 4K:me-32 8K:me-64
                    `}
          name={icon}
          baseHeight={24}
          baseWidth={24}
        />

        <span className="font-medium whitespace-nowrap text-secondary">{title}</span>
      </div>

      {subtitle && (
        <div
          className={`
                flex flex-col SD:flex-row SD:justify-between
                w-full

                gap-8 SD:gap-0
                SD:ms-8
              `}
        >
          <div
            className={`
                          flex items-center
                          gap-8 2K:gap-[14.22px] 4K:gap-[21.33px] 8K:gap-[42.66px]
                        `}
          >
            <span className="font-medium text-secondary hidden SD:flex">•</span>

            <span className="font-regular text-secondary/[0.7] whitespace-nowrap">{subtitle.value}</span>

            {subtitle.showTabby && <ResponsiveIcon name={IconNames.tabby} baseHeight={20} baseWidth={58} />}
          </div>
          <HoverText
            className={`
                            font-regular
                            text-sunset
                            text-fs-body-small whitespace-nowrap hidden sm:flex
                            tracking-[-0.0125rem] leading-[1.25rem]
                            `}
            hover={{ color: 'text-sunset' }}
            alwaysShowUnderline={false}
            underlineStyle="bg-sunset
                        h-[1px] 2K:h-[1.77px] 4K:h-[2.66px] 8K:h-[5.33px] bottom-2"
            text={t('common.learnMore') ?? ''}
            onClick={subtitle.learnMoreClick}
          />
        </div>
      )}
    </div>
  );
};

const StaticDetails = (props: { price: number; isPhysical: boolean }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const formattedPrice = convertPriceWithSymbol(props.price / 4, 'commaDecimal');

  return (
    <div
      className={`
              w-full flex flex-col
              gap-24 SD:gap-[0.75rem]
            `}
    >
      {props.isPhysical && (
        <StaticDetail
          icon={IconNames.localATM}
          title={t('shop.productPage.installments.value')}
          subtitle={{
            value: t(`shop.productPage.installments.message`, { cost: formattedPrice, currency: '' }),
            showTabby: true,
            learnMoreClick: () => window.open(`https://tabby.ai/`, '_blank')?.focus(),
          }}
        />
      )}

      <StaticDetail
        icon={IconNames.shipping}
        title={t('shop.productPage.delivery.value')}
        subtitle={{
          value: props.isPhysical
            ? t('shop.productPage.delivery.estimation_time', { start: 2, end: 7 })
            : t('shop.productPage.delivery.digital_delivery'),
          //
          learnMoreClick: () => navigate(AppRoutes.helpShoppingDelivery),
        }}
      />

      <StaticDetail
        icon={IconNames.undo}
        title={t('shop.cart.order_return.header_exchange')}
        subtitle={{
          value: props.isPhysical ? t('shop.productPage.returnPolicyDays', { days: 7 }) : t('shop.productPage.returnPolicy_digital'),
          learnMoreClick: () => navigate(AppRoutes.helpExchangeReturns),
        }}
      />
    </div>
  );
};

export default StaticDetails;
