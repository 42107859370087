import { motion } from 'framer-motion';
import { ReactElement, useEffect, useState } from 'react';

interface PropType {
  className?: string;
  textClassName?: string;
  alwaysShowUnderline?: boolean;
  text: string | ReactElement;
  underlineStyle: string;
  hover?: {
    color: string;
    onHoverEnter?: () => void;
    onHoverLeave?: () => void;
  };
  onClick?: () => void;
}

interface UnderlineProps {
  initialRenderDone: boolean;
  isHover: boolean;
  alwaysShow?: boolean;
  style: string;
}

const Underline = (props: UnderlineProps) => {
  const { initialRenderDone, isHover } = props;
  const [showUnderlineGap, setUnderlineGap] = useState<boolean>(false);

  return (
    <div className="absolute bottom-0 flex w-full ">
      <motion.div
        className={`relative ${props.style} `}
        initial={{ width: 0 }}
        animate={{ width: isHover ? '100%' : 0 }}
        transition={{ duration: 0.25 }}
        onAnimationStart={() => setUnderlineGap(true)}
        onAnimationComplete={() => setUnderlineGap(false)}
      />

      {props?.alwaysShow && (
        <>
          <motion.div
            className="relative h-full bg-transparent "
            initial={{ width: 0 }}
            animate={{ width: initialRenderDone && showUnderlineGap ? 10 : 0 }}
            transition={{ duration: 0.2 }}
          />

          <motion.div
            className={`relative ${props.style}  `}
            initial={{ width: '100%' }}
            animate={{ width: isHover ? 0 : '100%' }}
            transition={{
              duration: 0.25,
            }}
          />
        </>
      )}
    </div>
  );
};

const HoverText = (props: PropType) => {
  const [isHover, setHover] = useState<boolean>(false);

  const [initialRenderDone, setInitialRender] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setInitialRender(true);
    }, 500);
  }, []);

  const onHoverEnter = () => {
    setHover(true);

    if (props.hover?.onHoverEnter) {
      props.hover.onHoverEnter();
    }
  };

  const onHoverLeave = () => {
    setHover(false);

    if (props?.hover?.onHoverLeave) {
      props?.hover.onHoverLeave();
    }
  };

  return (
    <div
      onMouseEnter={onHoverEnter}
      onMouseLeave={onHoverLeave}
      className={`relative h-fit w-fit flex flex-col ${props.hover && `hover:cursor-pointer`} ${props?.className}`}
    >
      <span
        className={`
            w-fit
            select-none
            ${props?.textClassName}

            hover:${props?.hover?.color}
        `}
        onClick={props?.onClick}
      >
        {props.text}
      </span>

      {props.hover && (
        <Underline
          initialRenderDone={initialRenderDone}
          isHover={isHover}
          alwaysShow={props.alwaysShowUnderline}
          style={props.underlineStyle}
        />
      )}
    </div>
  );
};

export default HoverText;
