import { useLazyQuery } from '@apollo/client';
import { RETURN_PRODUCT_QUERY } from '@fe-monorepo/data-access';
import { GetInfoDataParamsModel, GetInfoDataResponse } from './type';
import { useEffect, useState } from 'react';



export function useGetInfo() {
    const [isSuccessful, setStatus] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    

    const [getInfoGql, { data: infoDataResult }] = useLazyQuery<GetInfoDataResponse>(RETURN_PRODUCT_QUERY.omsReturnProductGetInfo, {
        errorPolicy: 'all',
    });

    const getInfo = async (getInfoResult: GetInfoDataParamsModel) => {
        const { data } = await getInfoGql({
            variables: {
                details: getInfoResult,
            },
        });
        return data?.omsReturnProductGetInfo;
    };

    useEffect(() => {
        if (infoDataResult) {
            if (infoDataResult?.omsReturnProductGetInfo?.is_successful) {
                setStatus(infoDataResult?.omsReturnProductGetInfo?.is_successful);
            }
            if (infoDataResult?.omsReturnProductGetInfo?.error_msg) {
                setErrorMessage(infoDataResult?.omsReturnProductGetInfo?.error_msg);
            }
            setStatus(infoDataResult?.omsReturnProductGetInfo?.is_successful);
        }
        setIsLoading(false);
    }, [infoDataResult]);

    return { getInfo, getInfoData: infoDataResult?.omsReturnProductGetInfo, isSuccessful, isLoading, errorMessage };
}