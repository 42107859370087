/*Patterned from SearchableDropDown.tsx */
import { IconNames } from '@fe-monorepo/helper';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import ToolTip from '@fe-web/Atoms/ToolTip';
import useOutsideClick from '@fe-web/hooks/useOutsideClick';
import { MutableRefObject, useEffect, useState } from 'react';

import CustomDropDownList from './CustomDropDownList';

interface UnderlineProps {
  isFocused: boolean;
  isError: boolean;
  underlineStyle?: string;
}

const Underline = (props: UnderlineProps) => {
  return (
    <div
      className={`w-full ${props?.isError ? 'bg-red' : props.isFocused ? 'bg-sunset' : 'bg-gray-200-zinc-700'} ${props?.underlineStyle}`}
    />
  );
};

interface InputProps {
  parentRef: MutableRefObject<HTMLDivElement | null | undefined>;
  className?: string;

  filteredList: any[];
  currentValue?: string;
  value: string;
  isFocused: boolean;

  getStringValue: (dropDownElement: any) => string;
  setValue: (value: string) => void;
  setFocused: (val: boolean) => void;
  selectOption: (dropDownElement: any) => void;
  retrieveValue: (dropDownElement: any) => void;

  onClickEnter?: () => void;
}

const Input = (props: InputProps) => {
  const { filteredList, getStringValue } = props;

  const [localFocus, setLocalFocus] = useState<boolean>(false);
  const [isToolTipOn, setToolTipOn] = useState<boolean>(false);

  const toggleToolTip = () => {
    if (props.isFocused) {
      props.setFocused(false);
      setToolTipOn(false);
    } else {
      props.setFocused(true);
      setToolTipOn(value => !value);
    }
  };

  const clickClearButton = () => {
    setLocalFocus(false);
    props.setFocused(false);
    props.setValue('');
    props.retrieveValue({});
  };

  const selectValue = (dropDownElement: any) => {
    props.selectOption(dropDownElement);

    setTimeout(() => {
      setLocalFocus(false);
      setToolTipOn(false);
      props.setFocused(false);
    }, 10);
  };

  const elementRef = useOutsideClick(() => {
    setLocalFocus(false);
    props.setFocused(false);
    setToolTipOn(false);
  });

  elementRef.current = props.parentRef.current;

  const isToolTipShown = isToolTipOn || localFocus;

  return (
    <div className="relative grid items-center w-full h-fit">
      <input
        className={`z-1 w-full outline-0 bg-transparent ${props?.className}`}
        type={'text'}
        value={props.value}
        placeholder="Select"
        onChange={e => props.setValue(e.target.value)}
        onFocus={() => {
          setLocalFocus(true);
          props.setFocused(true);
        }}
        onKeyDown={event => {
          if (event.key === 'Enter' && props.onClickEnter) {
            props.onClickEnter();
          }
        }}
      />

      <div className="absolute flex flex-row-reverse items-center cursor-pointer justify-self-end h-fit w-fit">
        <ResponsiveIcon
          className={`defaultTheme:fill-black100 dark:fill-white100 z-[1] ${isToolTipShown && 'rotate-180 fill-sunset'}`}
          name={IconNames.chevron}
          baseHeight={24}
          baseWidth={24}
          onClick={toggleToolTip}
        />
        {props.value.length > 0 && (
          <ResponsiveIcon
            className={`defaultTheme:fill-black100 dark:fill-white100 z-[1] ${isToolTipShown && 'rotate-180 fill-sunset'}`}
            name={IconNames.close_xbutton}
            baseHeight={14}
            baseWidth={14}
            onClick={clickClearButton}
          />
        )}
      </div>

      {isToolTipShown && filteredList?.length > 0 && (
        <ToolTip
          className="absolute mt-4 4xl:mt-8 5xl:mt-12 8xl:mt-20"
          content={
            <CustomDropDownList
              currentValue={props?.currentValue}
              options={filteredList}
              selectedIconPosition="start"
              selectValue={selectValue}
              getStringValue={getStringValue}
            />
          }
        />
      )}
    </div>
  );
};

export interface Props {
  style?: {
    containerStyle?: string;
    inputStyle?: string;
    underlineStyle?: string;
  };
  parentRef: MutableRefObject<HTMLDivElement | null | undefined>;
  isError: boolean;
  isFocused: boolean;
  currentValue?: string;
  list: string[];
  search: (searchKey: string) => string[];
  getStringValue: (dropDownElement: any) => string;
  getSearchValue: (dropDownElement: any) => string;
  retrieveValue: (dropDownElement: any) => void;
  setFocused: (value: boolean) => void;
  onClickEnter?: () => void;
}

const CustomSearchableDropDown = (props: Props) => {
  const {
    style,
    list,
    isFocused,

    setFocused,
    getStringValue,
    getSearchValue,
    onClickEnter,
    retrieveValue,
  } = props;

  const [value, setValue] = useState<string>(props?.currentValue ? getSearchValue(props?.currentValue) : '');
  const [filteredList, setFilteredList] = useState<string[]>(list);

  const filterList = (value: string) => {
    const newFilterList = props.search(value);

    setFilteredList(newFilterList);
  };

  const selectOption = (dropDownElement: any) => {
    setValue(getSearchValue(dropDownElement));
    props.retrieveValue(dropDownElement);
  };

  useEffect(() => {
    setValue(getSearchValue(props?.currentValue));
  }, [props.currentValue]);

  useEffect(() => setFilteredList(list), [list]);

  useEffect(() => filterList(value), [value]);

  useEffect(() => {
    !isFocused && setValue(getSearchValue(props?.currentValue));
  }, [isFocused]);

  return (
    <div className={style?.containerStyle ?? ''}>
      <div className="flex w-full">
        <Input
          parentRef={props.parentRef}
          className={style?.inputStyle}
          value={value}
          currentValue={props?.currentValue}
          filteredList={filteredList}
          isFocused={isFocused}
          getStringValue={getStringValue}
          setValue={setValue}
          setFocused={setFocused}
          selectOption={selectOption}
          onClickEnter={onClickEnter}
          retrieveValue={retrieveValue}
        />
      </div>

      <Underline underlineStyle={style?.underlineStyle} isFocused={isFocused} isError={props.isError} />
    </div>
  );
};

export default CustomSearchableDropDown;
