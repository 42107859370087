import { convertPriceWithSymbol } from '@fe-monorepo/helper';

interface Props {
  className?: string;
  currency: number;
  hideSymbol?: boolean;
}

const CurrencyText = (props: Props) => {
  const formattedCurrency = convertPriceWithSymbol(props.currency, 'commaDecimal', props.hideSymbol ?? false);

  return (
    <span dir={'rtl'} className={props.className}>
      {formattedCurrency}
    </span>
  );
};

export default CurrencyText;
