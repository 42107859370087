import { APP_STORE_LINKS, IMAGE_ALT_LABELS, IconNames, TxKeyPath } from '@fe-monorepo/helper';
import { useAccount, useCommunityNew, useToastMessage, useTranslate } from '@fe-monorepo/hooks';
import { ChatType } from '@fe-monorepo/models';
import { RootState } from '@fe-monorepo/store';
import Button from '@fe-web/Atoms/Buttons/Button';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import ToastMessage from '@fe-web/Atoms/ToastMessage';
import PlayerAvatar from '@fe-web/Molecules/PlayerAvatar';
import { useConnectProvider } from '@fe-web/hooks/useConnect';
import useMobileDetect from '@fe-web/hooks/useMobileDetect';
import usePageLayout from '@fe-web/hooks/usePageLayout';
import { AppRoutes } from 'apps/fe-web/src/app/app.routes.enum';
import { GetInfoDataModel } from 'libs/hooks/src/useGetFollowInfo/types';
import { setFollowersFollowing } from 'libs/store/src/lib/connectSlice/connect';
import { useEffect, useRef, useState } from 'react';
import { isIOS } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

interface Props {
  isFollowers?: boolean;
  list: GetInfoDataModel[] | undefined;
  isRTL: boolean;
  toggleNewCall: (prevCursor: string) => void;
  setLastCursor: () => void;
  lastCursor: string;
  isLoading?: boolean;
  handleClose?: () => void;
}

const FollowList = (props: Props) => {
  const isMobile = useMobileDetect();
  const themes = useSelector((state: RootState) => state.app.themes);
  const { btnClass, btnClass2 } = usePageLayout();
  const { translate } = useTranslate();
  const { successToastMessage, errorToastMessage } = useToastMessage();
  const navigate = useNavigate();
  const sentinelRef = useRef(null);
  const [showLoadingText, setShowLoadingText] = useState<boolean>(true);
  // const [lastCursor, setLastCursor] = useState<string | undefined>();
  const dispatch = useDispatch();
  // const openChats = useSelector((state: RootState) => state.connect?.openChats);
  const { openChats, openChat } = useConnectProvider();

  const { userFollow, userUnfollow } = useCommunityNew();
  const { getUserProfile } = useAccount();

  // code for follow/unfollow
  const toggleFollow = (username: string, following: boolean, last_cursor: string) => {
    // checks if the user is a follower and you are not following back
    if (props.isFollowers && !following) {
      const req = userFollow.mutate({ username });
      req.then(res => {
        if (res?.data?.followUser?.is_successful === true) {
          successToastMessage({
            message: <ToastMessage type="success">{translate('msg_success_follow', { username: username })}</ToastMessage>,
          });
          getUserProfile();
          props.toggleNewCall(last_cursor.toString());
        } else
          errorToastMessage({
            message: <ToastMessage type="error">{res?.data?.followUser?.error_msg || translate('error_generic')}</ToastMessage>,
          });
      });
    } else {
      const req = userUnfollow.mutate({ username });
      req.then(res => {
        if (res?.data?.unfollowUser?.is_successful === true) {
          successToastMessage({
            message: <ToastMessage type="success">{translate('msg_success_unfollow', { username: username })}</ToastMessage>,
          });
          getUserProfile();
          props.toggleNewCall(last_cursor.toString());
        } else
          errorToastMessage({
            message: <ToastMessage type="error">{res?.data?.followUser?.error_msg || translate('error_generic')}</ToastMessage>,
          });
      });
    }
  };

  // code that opens the chat window or opens the app store if user is on mobile
  const chatUser = (username: string) => {
    if (isMobile) {
      if (isIOS) {
        window.open(APP_STORE_LINKS.iosXplay);
      } else window.open(APP_STORE_LINKS.androidXplay);
    } else if (!location.pathname.includes(AppRoutes.messages) && location.pathname !== AppRoutes.messages + '/1') {
      //CHECKING IF THERE IS ALREADY OPENED FLOATING NEW CHAT
      if (openChat && !openChats?.some(item => item.conversationID === username)) {
        openChat({ type: ChatType.EXISTING, conversationID: username, conversationType: 0 });
      }
    } else {
      navigate(AppRoutes.messages + `/${username}/${0}`);
      dispatch(
        setFollowersFollowing({
          isFollowersFollowingOpen: false,
          initialTab: 'following',
        }),
      );
    }
  };

  // code for pagination
  useEffect(() => {
    // loads new data if reach ref element
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setShowLoadingText(true);
            if (props.lastCursor && props.list && props.lastCursor === props.list[props.list.length - 1]?.last_cursor) {
              //stop load when end
              setShowLoadingText(false);
            }
            if (props.list && props.list.length > 0) {
              //sets last cursor for pagination then load more
              props.setLastCursor();
            }
          }
        });
      });
      observer.observe(sentinelRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [props.list]);

  return (
    <>
      {props.list && props.list.length > 0 ? (
        props.list.map(data => (
          <div key={data.last_cursor} className="flex flex-row justify-between py-[0.5rem]">
            <PlayerAvatar
              avatarCTA={() => {
                props.handleClose?.();
                navigate(AppRoutes.users + `/${data.username}`);
              }}
              altLabel={
                props.isFollowers
                  ? translate(IMAGE_ALT_LABELS.follower_user_avatar as TxKeyPath) ?? ''
                  : translate(IMAGE_ALT_LABELS.following_user_avatar as TxKeyPath) ?? ''
              }
              avatarUrl={data?.avatar_url ?? ''}
              avatarSize={2.5}
              dir="row"
              hasLabels
              displayName={data.display_name}
              userId={`${data.username}`}
              avatarStyle={`${!props.isRTL ? 'mr-[1rem]' : 'ml-[1rem]'}`}
              labelContainerStyle="w-[7rem] SD:w-[9rem] inline-flex flex-col !justify-start !items-start"
              displayNameStyle="font-regular text-fs-body-small text-secondary"
              userIdStyle="font-regular text-fs-caption text-neutral-400-zinc-500"
            />
            <div className="flex flex-row gap-[0.5rem]">
              {data.can_send_message === '1' && !isMobile && (
                <button
                  className={`${btnClass2} relative border-[0.0625rem] rounded-[0.125rem] border-sunset group
                                        hover:cursor-pointer button-animation-${props.isRTL ? 'rtl' : 'ltr'}`}
                  onClick={() => chatUser(data.username)}
                >
                  <ResponsiveIcon
                    name={IconNames.chatIcon}
                    baseHeight={20}
                    baseWidth={20}
                    iconClasses="fill-sunset group-hover:fill-white100"
                    className="p-[0.625rem]"
                  />
                </button>
              )}
              <Button
                text={
                  props.isFollowers && data.viewer_is_following_user === '0' ? translate('action.follow') : translate('action_unfollow')
                }
                style={`text-base not-italic max-h-[2.5rem] border-[0.063rem] border-sunset font-medium
                                ${isMobile ? 'w-[6.6114rem] px-[1rem] py-[0.5rem]' : 'w-[6.6114rem] py-[0.5rem]'}  gap-[0.5rem] z-1
                                mb-[0px]
                                xsMax:mb-[0px]  items-end
                                rounded-[0.188rem] `}
                normalStyle={` ${
                  props.isFollowers && data.viewer_is_following_user === '0'
                    ? `${btnClass}
                                    text-white100 hover:text-sunset`
                    : `${btnClass2}
                                    text-sunset hover:text-white100`
                }
                                `}
                // disabled={props.isButtonDisabled}
                disabledStyle={`${themes === 'dark' ? 'border-[#5D5C5E] text-[#5D5C5E]' : 'border-black30'} `}
                onClick={() => toggleFollow(data.username, !!parseInt(data.viewer_is_following_user), data.last_cursor)}
              />
            </div>
          </div>
        ))
      ) : (
        <div className="flex justify-center text-secondary font-regular text-fs-body"></div>
      )}
      <div className="text-secondary w-full flex flex-col col-span-4 justify-center items-center" ref={sentinelRef}>
        {showLoadingText && (
          <span className="text-fs-body-large font-regular">
            {props.isLoading
              ? `${translate('common_loading')}...`
              : !props.list || props.list.length === 0
              ? `${translate('validation_no_results_found')}`
              : null}
          </span>
        )}
      </div>
    </>
  );
};

export default FollowList;
