import { TxKeyPath } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import { TabListProps } from '@fe-monorepo/models';
import { RootState } from '@fe-monorepo/store';
import useMobileDetect from '@fe-web/hooks/useMobileDetect';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import Container from '../../components/Templates/Container';
import { SearchTab } from '../Search';

interface SearchHeaderProps {
  title: string;
  totalResult: number;
  selectedTab: string;
  tabs: TabListProps[];
  isLoading: boolean;
  setSelectedTab: (value: SearchTab) => void;
}

const SearchHeader = (props: SearchHeaderProps) => {
  const { translate } = useTranslate();
  const prefs = useSelector((state: RootState) => state.app);
  const dir = prefs?.language === 'ar' ? 'rtl' : 'ltr';
  const isMobile = useMobileDetect();
  const isNotEmpty = useMemo(() => {
    if (!props.tabs || props.tabs.length <= 0) return false;
    for (let i = 0; props.tabs.length > i; i++) {
      if (props.tabs[i]?.count! > 0) {
        return true;
      }
    }
    return false;
  }, [props.tabs]);

  return (
    <header
      className={`
        font-regular
        text-secondary
        border-secondary/20
        ${isNotEmpty ? 'border-b-2 4xl:border-b-[3.55px] 5xl:border-b-4 8xl:border-b-[11px]' : ''}
      `}
    >
      <Container className="bg-primary">
        <div
          className={`
                    flex
                    font-medium
                    text-title 4xl:text-mobileDynamic 5xl:text-LPTitle 8xl:text-fiveKDynamic
                    py-48 4xl:py-[85.33px] 5xl:py-[128px] 8xl:py-[256px]
              `}
        >
          <h1 className="max-w-[95%] truncate">
            <span className="font-italic">"</span>
            <span>{props?.title}</span>
          </h1>
          <span className="font-italic">"</span>
        </div>

        {/* Implementing horizontal scrolling causes problems with the underline */}
        <div className={`overflow-x-scroll `}>
          <div className={`flex flex-row gap-[1rem] z-[10] h-fit w-full ${isMobile ? 'mb-[.25rem]' : 'mb-[1rem]'}`}>
            {props?.tabs?.map((item, index) => {
              return (
                item &&
                item.resultCount! > 0 && (
                  <span
                    key={index}
                    className={`
                                    ${props?.selectedTab === item?.key ? `text-secondary` : `text-secondary/40`}
                                    cursor-pointer
                                    text-fs-body font-regular
                                    underline-${dir}-animation duration-[0.5s]
                                    hover:text-secondary`}
                    onClick={() => props?.selectedTab !== item?.key && props?.setSelectedTab(item?.key as SearchTab)}
                  >
                    <p>
                      {translate(item.translate as TxKeyPath)}{' '}
                      {item.resultCount !== 0 && item.resultCount && !isMobile && '(' + item.count + ')'}
                    </p>

                    {props?.selectedTab === item?.key && (
                      <p
                        className={`w-full bg-secondary absolute border border-b-[.1rem] h-[.1rem] z-[50] duration-[0.5s] ${
                          isMobile ? 'bottom-[-.25rem]' : 'bottom-[-1rem] 4K:bottom-[-1rem]'
                        }`}
                      />
                    )}
                  </span>
                )
              );
            })}
          </div>
        </div>
      </Container>
    </header>
  );
};

export default SearchHeader;
