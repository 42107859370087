import { IconNames } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import HoverText from '@fe-web/Atoms/Text/HoverText';
import Footer from '@fe-web/Organisms/ShopFooter/Footer';

import ErrorPageLayout from './ErrorPageLayout';

export function ServerDownPage() {
  const { translate } = useTranslate();
  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <ErrorPageLayout list={[{ label: translate('home') as string }, { label: '521' }]} footer={<Footer />}>
      <div className="flex-1 flex flex-col gap-[5rem] items-center text-center SD:justify-center">
        <ResponsiveIcon
          name={IconNames.serverDownLight}
          baseWidth={642.93}
          baseHeight={176}
          className="dark:hidden"
          iconClasses="max-w-screen max-h-screen hidden md:block w-[40rem] h-[11]"
        />
        <ResponsiveIcon
          name={IconNames.serverDownDark}
          baseWidth={642.93}
          baseHeight={176}
          className=" hidden dark:block"
          iconClasses="max-w-screen max-h-screen hidden md:block w-[40rem] h-[11]"
        />
        <ResponsiveIcon
          name={IconNames.serverDownMobile}
          baseWidth={82}
          baseHeight={147}
          iconClasses="max-w-screen max-h-screen md:hidden w-[5.125rem] h-[9.2rem] stroke-secondary fill-primary"
        />
        <div className="flex flex-col gap-[2.5rem]">
          <div className="flex flex-col gap-[0.75rem] items-center">
            <p className="SD:font-medium font-regular SD:text-fs-title text-fs-subtitle text-black-white">
              {translate('error_network_something_went_wrong')}
            </p>
            <p className="text-zinc-600-400 max-w-[17.5rem] font-regular text-fs-body-small">
              {translate('error_reload_page_description')}
            </p>
          </div>
          <div className="flex flex-col gap-[1rem] md:gap-[2.5rem]">
            <HoverText
              className={`font-regular text-sunset text-fs-body whitespace-nowrap flex m-auto`}
              hover={{ color: 'text-sunset' }}
              alwaysShowUnderline={false}
              underlineStyle="bg-sunset h-[0.0625rem] bottom-2"
              text={translate('action_refresh') || 'Refresh'}
              onClick={refreshPage}
            />
          </div>
        </div>
      </div>
    </ErrorPageLayout>
  );
}

export default ServerDownPage;
