import { Tooltip } from 'react-tooltip';

interface ClassNameData {
  container?: string;
  decrementButton?: string;
  incrementButton?: string;
}

interface QuantityFieldProps {
  value: number;
  increment?: () => void;
  isIncrementDisabled?: boolean;
  classNames?: ClassNameData;
  decrementButton?: JSX.Element;
}

const QuantityField = (props: QuantityFieldProps) => {
  const { value, increment, isIncrementDisabled, classNames } = props;

  return (
    <div className={`flex ${classNames?.container}`}>
      <div
        className="bg-secondary/5 flex items-center justify-around gap-[0.5rem] h-full aspect-[38/11]
                rounded-[2px] 2K:rounded-[3.55px] 4K:rounded-[5.33px] 8K:rounded-[10.66px] font-regular responsive-text-body py-[0.25rem] px-[0.375rem]"
      >
        {props.decrementButton}

        <div className="flex justify-center items-center bg-white100 select-none rounded-[0.125rem] text-fs-body-small min-w-[2rem] px-[0.125rem] py-[0.125rem]">
          <p>{value}</p>
        </div>

        <button
          className={`${
            isIncrementDisabled ? 'cursor-not-allowed text-secondary/[30%]' : 'text-secondary'
          } inline-flex items-center justify-center ${classNames?.incrementButton} w-[1rem] h-[1rem]`}
          disabled={isIncrementDisabled}
          onClick={increment}
          data-tooltip-id={isIncrementDisabled && value === 1 ? 'tooltip-max-quantity' : ''}
          data-tooltip-content={isIncrementDisabled && value === 1 ? `Currently, there's only ${value} remaining copy of this product` : ''}
        >
          +
        </button>
        <Tooltip id="tooltip-max-quantity" className="max-w-[204px] bg-secondary p-16 font-regular text-fs-body-small" place="right" />
      </div>
    </div>
  );
};

export default QuantityField;
