import { useTranslate } from '@fe-monorepo/hooks';

import Hashtag from '../components/HashtagRail/Hashtag';
import Bottom from '../components/Layouts/Bottom';
import LoginModal from '../components/Login-UI/LoginModal';
import { useLoginModalContext } from '../contexts/LoginModal';

const HashtagDetail = () => {
  const defaultLanguage = localStorage.getItem('i18nextLng');
  const { showLoginModal, setShowLoginModal } = useLoginModalContext();
  const { translate } = useTranslate();
  const goBack = () => {
    window.history.back();
  };

  return (
    <>
      <div className="full-sec">
        <div className="px-20 md:px-64 lg:px-120 2K:px-160 4K:px-320 8K:px-655">
          <Hashtag />
        </div>
      </div>
      <Bottom />

      <LoginModal show={showLoginModal} handleClose={() => setShowLoginModal(false)} />
    </>
  );
};

export default HashtagDetail;
