import { useEffect, useState, useRef, Children } from 'react';
import { useParams } from 'react-router-dom';
import { useProductDetails } from '@fe-monorepo/hooks';
import { IMAGES } from '@fe-monorepo/assets';
import { ReactNode } from 'react';

interface ModalProps {
    contactDetails?: {};
    backdropClassName?: string;
    children?: ReactNode;
    open?: boolean;
}

const Modal = (props: ModalProps) => {
    return props.open === true ? (
        <div
            className={`${props.backdropClassName} h-full w-full fixed top-[0] left-[0] z-[9999]
                flex justify-center items-start bg-black70`}
        >
            {props.children}
        </div>
    ) : null;
};

export default Modal;
