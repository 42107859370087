import { IconNames } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import Button from '@fe-web/Atoms/Buttons/Button';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import InputField from '@fe-web/Molecules/InputFields/InputField/InputField';
import Radio from '@fe-web/Molecules/InputFields/Radio/Radio';
import Modal from '@fe-web/Templates/Modal/Modal';
import useHeaderState from '@fe-web/hooks/useHeaderState';
import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { authInputFieldStyleObj } from './commonStyles';

interface ReportContentProps {
    setIsReportModalOpen: (value: boolean) => void;
    handleSubmitReportButton: (code: string, content: string) => void;
    title: string;
    isOpen: boolean;
    subtitle: string;
}

const ReportModal = ({ setIsReportModalOpen, handleSubmitReportButton, title, isOpen, subtitle }: ReportContentProps) => {
    const [selectedReport, setSelectedReport] = useState<string>('');
    const { translate } = useTranslate();
    const { dir } = useHeaderState();
    const isHeaderDark = useSelector((state: RootState) => state.app.headerColorChange);
    const [otherInput, setOtherInput] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [selectedOption, setSelectedOption] = useState<string>('');

    const otherText = translate('tournament_status_bar.whatconcern') || 'What concerns you?';

    const REPORT_LIST_OPTIONS = [
        {
            value: 'gambling',
            label: translate('tournament_status_bar.gambling'),
        },
        {
            value: 'spam',
            label: translate('tournament_status_bar.spam'),
        },
        {
            value: 'harmful_acts',
            label: translate('tournament_status_bar.abusive'),
        },
        {
            value: 'copy_rights',
            label: translate('tournament_status_bar.copyrights'),
        },
        // {
        //     value: 'self_harm',
        //     label: translate('tournament_status_bar.selfharm'),
        // },
        {
            value: 'report_grievances',
            label: translate('tournament_status_bar.grievances'),
        },
        {
            value: 'inappropriate_content',
            label: translate('tournament_status_bar.inappropriate_content'),
        },
        // {
        //     value: 'cheating',
        //     label: translate('tournament_status_bar.cheating'),
        // },
    ];

    function handleOptionChange(value: string) {
        setSelectedOption(value);
    }

    //create a useEffect that will set the error to empty after 3 seconds
    useEffect(() => {
        if (error.length > 0) {
            setTimeout(() => {
                setError('');
            }, 3000);
        }
    }, [error]);

    function handleCloseButton() {
        setIsReportModalOpen(false);
        setSelectedReport('');
        setSelectedOption('');
    }

    const errorText2 = translate('tournament_status_bar.error1') || 'Please input a response';
    const errorText3 = translate('tournament_status_bar.error2') || 'You have exceeded the character limit';

    function handleSubmitButton() {
        if (selectedOption === 'other' && otherInput.length === 0) {
            setError(errorText2);
            return;
        }
        if (selectedOption === 'other' && otherInput.length <= 500) {
            handleSubmitReportButton(selectedOption, otherInput);
        }
        if (selectedOption !== 'other') {
            handleSubmitReportButton(selectedOption, '');
        }
        setSelectedOption('');
        setSelectedReport('');
        setIsReportModalOpen(false);
    }

    function handleBackButton() {
        setSelectedReport('');
        setSelectedOption('');
    }

    //create a useEffect that will monitor the change of otherInput, if length is greater than 500, set error
    useEffect(() => {
        if (otherInput.length > 500) {
            setError(errorText3);
        }
    }, [otherInput]);

    return (
        <Modal backdropClassName="bg-black/[0.25] items-center" open={isOpen}>
            <div className="fixed overflow-y-scroll inset-0 flex items-center justify-center z-[999999]">
                {/* w-11/12 md:max-w-md mx-auto*/}
                <div className="overflow-y-scroll w-[27rem] border-solid border-secondary/[.20] border-[.06rem] modal-container bg-primary rounded-[.5rem] shadow-lg z-50 SD:border-[.1rem]">
                    <div
                        className={`${
                            selectedOption === 'other' ? '' : 'mdxs:mt-[6rem] mdMaxxSD:mt-[6rem]'
                        } modal-content overflow-y-scroll mdxs:py-[2rem] mdxs:px-[2rem] mdMaxxS:py-[3rem] mdMaxxS:px-[3rem] mdMaxxSD:py-[3rem] mdMaxxSD:px-[3rem] SD:py-[3rem] text-left mdMaxxS:px-[0.9rem] SD:px-[3rem]`}
                    >
                        <div className={`flex flex-row justify-between items-start`}>
                            {selectedReport.length === 0 && (
                                <div className={`w-full ${dir === 'rtl' ? 'text-right' : 'mr-[2rem]'}`}>
                                    <p className="text-secondary text-fs-subtitle not-italic font-medium tracking-[-0.075rem]">{title}</p>
                                </div>
                            )}
                            {selectedReport.length > 0 && selectedReport === 'other' && (
                                <div className={`w-full ${dir === 'rtl' && 'text-right'}`}>
                                    <h1 className="text-secondary text-fs-subtitle not-italic font-medium leading-8 tracking-[-0.015rem] mb-[1.5rem] whitespace-nowrap">
                                        {translate('tournament_status_bar.otherissue')}
                                    </h1>
                                </div>
                            )}

                            <div onClick={() => handleCloseButton()} className="modal-close cursor-pointer mt-[.3rem]">
                                <svg className="w-[1.5rem] h-[1.5rem]" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                                        fill="#171619"
                                        className="fill-secondary"
                                    />
                                </svg>
                            </div>
                        </div>
                        {selectedReport.length === 0 && (
                            <p
                                className={`text-secondary/60 text-fs-body-small not-italic font-regular leading-5 mb-[1.5rem] mt-[0.8rem] ${
                                    dir === 'rtl' && 'text-right'
                                }`}
                            >
                                {subtitle}
                            </p>
                        )}
                        {selectedReport.length > 0 && selectedReport === 'other' && (
                            <div className={`${dir === 'rtl' && 'text-right'}`}>
                                <p className="text-secondary text-fs-body font-regular mb-[1.5rem]">
                                    {translate('tournament_status_bar.ifyoufeel')}
                                </p>
                                {/* <p className="text-secondary text-fs-body not-italic font-regular leading-6">
                                    {translate('tournament_status_bar.ifyoufeel2')}
                                </p>
                                <p className="text-secondary text-fs-body not-italic font-regular leading-6 mb-[1.5rem]">
                                    {translate('tournament_status_bar.ifyoufeel3')}
                                </p> */}
                            </div>
                        )}
                        {selectedReport.length === 0 && (
                            <div>
                                {REPORT_LIST_OPTIONS.map((item, index) => {
                                    return (
                                        <Fragment key={index}>
                                            {index !== 0 && (
                                                <hr className="w-full h-px text-secondary/10 bg-secondary/10 border-[none] my-[0.75rem]" />
                                            )}
                                            <label className="flex gap-[0.5rem] items-center">
                                                <Radio
                                                    checked={selectedOption === item.value}
                                                    onChange={e => handleOptionChange(e.target.value)}
                                                    name="type"
                                                    value={item.value}
                                                />
                                                <div className="flex items-center justify-between text-secondary text-fs-body not-italic font-regular leading-6">
                                                    {item.label}
                                                </div>
                                            </label>
                                        </Fragment>
                                    );
                                })}
                                <hr className="w-full h-px text-secondary/10 bg-secondary/10 border-[none] my-[0.75rem]" />
                                {/* Other */}
                                <div
                                    onClick={() => {
                                        setSelectedReport('other');
                                        setSelectedOption('other');
                                    }}
                                    className="flex items-center justify-between text-secondary text-fs-body not-italic font-medium leading-6 cursor-pointer"
                                >
                                    {translate('tournament_status_bar.other')}
                                    <ResponsiveIcon
                                        name={IconNames.chevron}
                                        baseWidth={30}
                                        baseHeight={30}
                                        className="-rotate-90"
                                        iconClasses="fill-secondary"
                                    />
                                </div>
                            </div>
                        )}
                        {selectedReport.length > 0 && selectedReport === 'other' && (
                            <div>
                                <div className={`text-secondary/60 text-fs-body-small font-regular ${dir === 'rtl' && 'text-right'}`}>
                                    {translate('pageTitle_playground_description')}
                                </div>
                                <InputField
                                    label={otherText}
                                    isSecret={false}
                                    hasFocused={false}
                                    errorMessage={''}
                                    isUserAllowedToType={(value: string) => true}
                                    retrieveValue={(value: any) => {
                                        setOtherInput(value);
                                    }}
                                    style={authInputFieldStyleObj}
                                />
                                <div className="-mt-[1rem] flex justify-end w-full text-secondary/70 text-fs-body-small not-italic font-regular leading-5">
                                    <p className="ml-auto">
                                        {translate('chat.report_user.issue_character_limit', {
                                            current_count: otherInput.length,
                                            limit: '500',
                                        })}
                                    </p>
                                </div>
                            </div>
                        )}
                        {/* ADDED THE BUTTON HERE OF THE CHANGES  */}
                        {selectedReport !== 'other' && selectedOption && (
                            <Button
                                text={translate('tournament_status_bar.submitreport')}
                                style={`mt-[1.25rem] w-full SD:w-full text-fs-body not-italic font-medium leading-6 px-[1rem] py-[0.5rem] text-white100 rounded-sm border-[1px] border-sunset ${
                                    isHeaderDark
                                        ? dir === 'ltr'
                                            ? `after:bg-sunset before:bg-white100`
                                            : `before:bg-sunset after:bg-white100`
                                        : `${dir === 'ltr' ? `after:bg-sunset before:bg-white100` : `before:bg-sunset after:bg-white100`}
                                `
                                } hover:text-sunsetText rounded-sm`}
                                disabled={false}
                                onClick={() => handleSubmitButton()}
                            />
                        )}
                        {/* Error Container here */}
                        {error.length > 0 && <div className="mt-[1rem] w-full text-fs-body text-red font-regular">{error}</div>}
                        {selectedReport.length > 0 && (
                            <div className="w-full flex justify-center gap-[1.25rem] items-center mt-[1.25rem]">
                                <Button
                                    text={translate('action_back')}
                                    style={`
                                SD:order-none w-full SD:w-full text-btn-primary text-fs-body not-italic font-medium leading-6 px-[1.5rem] py-[0.5rem] rounded border border-[color:var(--brand-sunset,#E95F2A)] border-solid gap-[8px] z-1
                                  text-white100 text-sunsetText mb-[0px]
                                  xsMax:mb-[0px] items-end w-full
                                  rounded-sm ${
                                      isHeaderDark
                                          ? dir === 'ltr'
                                              ? `after:bg-primary before:bg-sunset`
                                              : `before:bg-white100 after:bg-sunset`
                                          : `${dir === 'ltr' ? `after:bg-white100 before:bg-sunset` : `before:bg-white100 after:bg-sunset`}`
                                  }
                                  hover:text-white100 rounded-sm`}
                                    disabled={false}
                                    onClick={() => handleBackButton()}
                                />
                                <Button
                                    text={translate('tournament_status_bar.submitreport')}
                                    style={`w-full SD:w-full text-fs-body not-italic font-medium leading-6 px-[1rem] py-[0.5rem] text-white100 border-[1px] border-sunset rounded-sm ${
                                        isHeaderDark
                                            ? dir === 'ltr'
                                                ? `after:bg-sunset before:bg-white100`
                                                : `before:bg-sunset after:bg-white100`
                                            : `${
                                                  dir === 'ltr'
                                                      ? `after:bg-sunset before:bg-white100`
                                                      : `before:bg-sunset after:bg-white100`
                                              }
                                hover:-border[1px] hover:border-sunset`
                                    } hover:text-sunsetText rounded-sm`}
                                    disabled={false}
                                    onClick={() => handleSubmitButton()}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ReportModal;
