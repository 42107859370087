import { ReactEventHandler, SyntheticEvent, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface LazyImageProps {
  img: any;
  divStyle: string;
  imgStyle: string;
  children?: JSX.Element;
  onClick?: () => void;
  fallback?: JSX.Element;
  alt?: string;
  title?: string;
  onError?: ReactEventHandler<HTMLImageElement> | undefined;
}

const LazyImage = (props: LazyImageProps) => {
  const { fallback = null, ...imageProps } = props;
  const [isBroken, setIsBroken] = useState(false);
  const src = props?.img?.name ? URL.createObjectURL(props?.img) : props?.img;

  function handleError(e: SyntheticEvent<HTMLImageElement, Event>) {
    setIsBroken(true);
    imageProps.onError?.(e);
  }

  if (!!fallback && (isBroken || !src)) {
    return fallback;
  }

  return (
    <div className={`${props?.divStyle}`}>
      {props?.children}
      <LazyLoadImage
        src={src}
        className={`${props?.imgStyle}`}
        onClick={() => {
          if (props.onClick) {
            props.onClick();
          }
        }}
        onError={handleError}
        alt={props.alt ?? ''}
        title={props.title ?? ''}
      />
    </div>
  );
};

export default LazyImage;
