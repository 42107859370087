import { useCurrencyWeb } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import VerifyModal from '@fe-web/Organisms/VerifyModal/VerifyModal';
import useRecentSearch from '@fe-web/hooks/useRecentSearch';
import useVerifyDialogToggle from 'libs/hooks/src/useVerifyDialogToggle';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';

import { AppRoutes } from '../../app.routes.enum';
import Header from '../../components/Organisms/Header/Header';
import usePageLayout from '../../hooks/usePageLayout';
import ShopSettingsMenu from './ShopSettingsMenu';
import TermsAndConditionsModal from './TermsAndConditionsModal';

//create an interface for the DashboardScreenLayout which will receive a Footer as a component
interface DashboardScreenLayoutProps {
  footer?: JSX.Element | null;
  className?: string;
  isFullScreen?: boolean;
}

const DashboardScreenLayout = ({ footer, className, isFullScreen }: DashboardScreenLayoutProps) => {
  //get the state of the isUserSettingsOpen from the rootState
  const isUserSettingsOpen = useSelector((state: RootState) => state.app.isUserSettingsOpen);
  // useDispatch to dispatch the action to close the user settings menu
  const currencyWeb = useCurrencyWeb();
  const { direction } = usePageLayout();
  const { isOpen } = useVerifyDialogToggle();
  const { isOpen: isRecentSearchOpen } = useRecentSearch();
  const location = useLocation();
  const isOnFAQs = location.pathname.includes(AppRoutes.help);

  useEffect(() => {
    const HTMLTAG = document.getElementsByTagName('html')[0];
    const attr = document.createAttribute('dir');
    attr.value = direction;
    HTMLTAG.setAttributeNode(attr);
  }, [direction]);

  return (
    <div className="min-h-screen h-full fixed top-0 z-[500] w-full">
      <main
        className={`mx-auto h-full w-full 
            ${isRecentSearchOpen ? 'fixed overscroll-none' : 'overflow-scroll'}
            ${isOnFAQs ? 'overscroll-none' : ''}
            `}
      >
        {isUserSettingsOpen && <ShopSettingsMenu currencyWeb={currencyWeb} />}
        {isOpen && <VerifyModal />}
        <TermsAndConditionsModal />
        <Header />
        <div className={`${className} flex flex-col ${isFullScreen ? '' : 'min-h-screen'} justify-between bg-primary w-full `}>
          <section className="mb-auto h-full flex-1 overflow-x-clip w-full">
            <Outlet />
          </section>
          {footer}
        </div>
      </main>
    </div>
  );
};

export default DashboardScreenLayout;
