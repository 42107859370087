/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/ban-ts-comment */

/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Validator } from '@fe-monorepo/helper';
import { CourseItem, GenGCoursesResponse, GenGQuestionsResponse, GenGUserFormInput, QuestionItem } from '@fe-monorepo/models';
import { RootState } from '@fe-monorepo/store';
import { createContext, useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';

import { useTranslate } from '../useTranslate';
import { useGenG } from './useGenG';

const GenGContext = createContext<any>(null);

interface genGRegistrationStateType {
  first_name: string;
  last_name: string;
  email: string;
  phone_no: string;
  user_id: string;
  ign: string;
  discord_id: string;
  dob: string;
  eng_lvl: number;
  course_id: number | null | string;
  sessions: string[];
  qAnswers: quesAns[];
}

type quesAns = {
  questionId: number;
  answer: string;
};

type ResponseMode = 'success' | 'failure' | 'init';

export const GenGProvider = ({
  children,
  showToastMessage,
}: {
  children: JSX.Element;
  showToastMessage: (message: string, type: 'success' | 'error') => void;
}) => {
  const { translate } = useTranslate();
  const { email, username, firstName, lastName, mobile } = useSelector((state: RootState) => state.user.userContext);
  const { getAllGenGCourses, getAllGenGQuestions, checkUserGenG, submitFormGeng } = useGenG();
  const [courses, setCourses] = useState<CourseItem[] | []>([]);
  const [questions, setQuestions] = useState<QuestionItem[] | []>([]);
  const [schedules, setSchedules] = useState<string[]>([]);
  const [regResponseMode, setRegResponseMode] = useState<ResponseMode>('init');
  const [loading, setLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [genGRegistrationState, setGenGRegistrationState] = useState<genGRegistrationStateType>({
    first_name: firstName ?? '',
    last_name: lastName ?? '',
    email: email ?? '',
    phone_no: mobile ?? '',
    user_id: username ?? '',
    ign: '',
    discord_id: '',
    dob: '',
    eng_lvl: 1,
    course_id: null,
    sessions: [],
    qAnswers: [],
  });
  const [questionsLoading, setQuestionsLoading] = useState(false);
  const [disableCourse, setDisableCourse] = useState(false);
  const errorDefaultState: any = {
    first_name: null,
    last_name: null,
    email: null,
    phone_no: null,
    user_id: null,
    ign: null,
    discord_id: null,
    dob: null,
    eng_lvl: null,
    course_id: null,
    sessions: null,
    qAnswers: null,
  };

  const [genGRegistrationStateError, setGenGRegistrationStateError] = useState<any>({
    first_name: null,
    last_name: null,
    email: null,
    phone_no: null,
    user_id: null,
    ign: null,
    discord_id: null,
    dob: null,
    eng_lvl: null,
    course_id: null,
    sessions: null,
    qAnswers: null,
  });

  const SESSIONS = {
    '1': [
      'Cohort 1 - July 21 ~ August 1 (Mon, Tues, Thurs, Friday) - 6:00 pm ~ 8:00 pm KSA',
      'Cohort 2 - July 21 ~ August 1 (Mon, Tues, Thurs, Friday) - 8:30 pm ~ 10:30 pm KSA',
    ],
    '2': [
      'Cohort 1-August 4~ August 6 - 6:00 pm ~ 8:00 pm KSA',
      'Cohort 2-August 8 ~ August 10 - 6:00 pm ~ 8:00 pm KSA',
      'Cohort 3 - August 11 ~ August 13 - 6:00 pm ~ 8:00 pm KSA',
      'Cohort 4 - August 15 ~ August 17 - 6:00 pm ~ 8:00 pm KSA',
    ],
    '3': [
      'Cohort 1-August 4-August 6 - 8:30 pm - 10:30 pm KSA',
      'Cohort 2 - August 8 ~ August 10 - 8:30 pm ~ 10:30 pm KSA',
      'Cohort 3 - August 11 ~ August 13 - 8:30 pm ~ 10:30 pm KSA',
      'Cohort 4 - August 15 ~ August 17 - 8:30 pm ~ 10:30 pm KSA',
    ],
  };

  const updateUserFields = (key: string, value: any) => {
    setGenGRegistrationState(prevState => {
      const updatedState = {
        ...prevState,
        [key]: value,
      };
      if (key === 'course_id') {
        updatedState.sessions = [];
      }
      return updatedState;
    });
  };

  const updateQuestionFields = (value: string, questionID: number) => {
    const formState: genGRegistrationStateType = { ...genGRegistrationState };
    const index = formState.qAnswers.findIndex(q => q.questionId === questionID);
    if (index !== -1) {
      formState.qAnswers[index].answer = value;
    }
    setGenGRegistrationState(formState);
  };

  const updateSessionFields = (value: string) => {
    const formState: genGRegistrationStateType = { ...genGRegistrationState };
    const isItemPresent = genGRegistrationState.sessions?.includes(value);
    if (isItemPresent) {
      formState['sessions'] = formState.sessions.filter(item => item !== value);
    } else {
      const prevArr = formState['sessions'];
      formState['sessions'] = [...prevArr, value];
    }
    setGenGRegistrationState(formState);
  };

  const getAllCoursesGenG = async () => {
    try {
      const data: GenGCoursesResponse = await getAllGenGCourses();
      if (data?.is_successful) {
        getCourseWiseQuestions(data?.response[0].course_id);
        setCourses(data?.response ?? []);
        updateUserFields('course_id', data?.response[0].course_id);
        updateUserFields('session', []);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getCourseWiseQuestions = async (courseID: number | string) => {
    try {
      setQuestions([]);
      setQuestionsLoading(true);
      const data: GenGQuestionsResponse = await getAllGenGQuestions({
        course_id: Number(courseID),
      });
      if (data?.is_successful) {
        setQuestions(data?.response ?? []);
        const updatedQuestionsArray = data?.response.map(el => {
          return { questionId: el.question_id, answer: '' };
        });
        updateUserFields('qAnswers', updatedQuestionsArray);
      }
      setQuestionsLoading(false);
    } catch (err) {
      console.error(err);
      setQuestionsLoading(false);
    }
  };

  const checkUserSelectedCourse = async (course_id: number) => {
    try {
      const data = await checkUserGenG({
        course_id: course_id,
      });
      if (data?.is_successful) {
        setDisableCourse(false);
      } else if (data.error_code === '5095') {
        setDisableCourse(true);
        if (isLoaded) {
          showToastMessage(data.error_msg ?? translate('error.something_went_wrong_title'), 'error');
        }
      }
    } catch (err) {
      console.error(err);
      showToastMessage(translate('error.something_went_wrong_title') ?? '', 'error');
      setDisableCourse(true);
    }
  };
  const submitGengForm = async (body: GenGUserFormInput, logMixpanelEvent?: (body: GenGUserFormInput) => void) => {
    try {
      setLoading(true);
      const data = await submitFormGeng(body);
      if (data?.is_successful) {
        if (!isMobile && logMixpanelEvent instanceof Function) {
          logMixpanelEvent(body);
        }
        setRegResponseMode('success');
      } else {
        setRegResponseMode('failure');
      }
      setLoading(false);
    } catch (err) {
      console.error(err, 'ERROR IS COMING FROM');
      setLoading(false);
      setRegResponseMode('failure');
    }
  };

  const validateFields = () => {
    setGenGRegistrationStateError(errorDefaultState);
    const errorClone = { ...errorDefaultState };
    let isError = false;

    if (!genGRegistrationState.first_name) {
      errorClone.first_name = 'First name is required';
      isError = true;
    } else if (!Validator.validateText(genGRegistrationState.first_name)) {
      errorClone.first_name = 'The first name should contain only text and not include numbers or symbols.';
      isError = true;
    }

    if (!genGRegistrationState.last_name) {
      errorClone.last_name = 'Last name is required';
      isError = true;
    } else if (!Validator.validateText(genGRegistrationState.last_name)) {
      errorClone.last_name = 'The last name should contain only text and not include numbers or symbols.';
      isError = true;
    }

    if (!genGRegistrationState.email) {
      showToastMessage(translate('ewc.geng.phone_number_missing') ?? '', 'error');
      errorClone.email = translate('ewc.geng.phone_number_missing') ?? '';
      isError = true;
    } else if (!Validator.isValidEmail(genGRegistrationState.email)) {
      errorClone.email = 'Email is invalid';
      isError = true;
    }

    if (!genGRegistrationState.phone_no.trim()) {
      errorClone.phone_no = 'Phone Number is required';
      isError = true;
    } else if (genGRegistrationState?.phone_no.trim().length !== 10 && genGRegistrationState?.phone_no.trim().length !== 9) {
      errorClone.phone_no = 'Phone number should contain 9 or 10 digits';
      isError = true;
    }

    if (!genGRegistrationState.user_id) {
      errorClone.user_id = 'User ID is required';
      isError = true;
    }
    if (!genGRegistrationState.ign) {
      errorClone.ign = 'IGN is required';
      isError = true;
    }
    if (!genGRegistrationState.discord_id) {
      errorClone.discord_id = 'Discord ID is required';
      isError = true;
    }
    if (!genGRegistrationState.dob) {
      errorClone.dob = 'Date of birth is required';
      isError = true;
    }
    if (!genGRegistrationState.eng_lvl) {
      errorClone.eng_lvl = 'English level is required';
      isError = true;
    }
    if (!genGRegistrationState.course_id) {
      errorClone.course_id = 'Please select a course';
      isError = true;
    }
    if (genGRegistrationState.sessions.length === 0) {
      errorClone.sessions = 'Please select the session(s)';
      isError = true;
    }
    if (!Array.isArray(genGRegistrationState.qAnswers) || genGRegistrationState.qAnswers.length !== questions.length) {
      errorClone.qAnswers = 'All question answers are required';
      isError = true;
    }

    if (genGRegistrationState.qAnswers?.some(item => item.answer === '')) {
      errorClone.qAnswers = "Answers Can't be Empty";
      isError = true;
    }

    if (genGRegistrationState.sessions?.length === 0) {
      errorClone.sessions = 'Please select atleast one session';
      isError = true;
    }

    setGenGRegistrationStateError(errorClone);
    return isError === false;
  };

  const submitForm = (logMixpanelEvent?: (body: GenGUserFormInput) => void) => {
    if (validateFields()) {
      const formFields = genGRegistrationState;
      const sessions = formFields['sessions'];
      const appendedSessions = sessions.map(value => `session:'${value}'`);
      const sessionString = `{${appendedSessions}}`;

      const regInput: GenGUserFormInput = {
        user_id: formFields['user_id'],
        course_id: Number(formFields['course_id']),
        discord_id: formFields['discord_id'],
        dob: formFields['dob'],
        email: formFields['email'],
        eng_lvl: Number(formFields['eng_lvl']),
        first_name: formFields['first_name'],
        ign: formFields['ign'],
        last_name: formFields['last_name'],
        phone_no: `966${formFields['phone_no']}`,
        qAnswers: formFields['qAnswers'],
        sessions: sessionString,
      };
      submitGengForm(regInput, logMixpanelEvent);
    }
  };

  useEffect(() => {
    if (genGRegistrationState.course_id) {
      //@ts-ignore
      setSchedules(SESSIONS[genGRegistrationState.course_id]);
    } else {
      setSchedules([]);
    }
    updateUserFields('sessions', []);
  }, [genGRegistrationState.course_id]);

  return (
    <GenGContext.Provider
      value={{
        genGRegistrationState,
        updateUserFields,
        genGRegistrationStateError,
        submitForm,
        getAllCoursesGenG,
        getCourseWiseQuestions,
        submitGengForm,
        courses,
        questions,
        SESSIONS,
        checkUserSelectedCourse,
        questionsLoading,
        updateQuestionFields,
        updateSessionFields,
        disableCourse,
        regResponseMode,
        setRegResponseMode,
        loading,
        schedules,
        setIsLoaded,
      }}
    >
      {children}
    </GenGContext.Provider>
  );
};

export const useGenGContext = () => useContext(GenGContext);
