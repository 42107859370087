import { useShopContent } from '@fe-monorepo/hooks';
import { BannerInfoDataModel } from '@fe-monorepo/models';
import { useEffect, useState } from 'react';

interface UseHome {
  loading: boolean;
  slider?: BannerInfoDataModel;
}

export const useHome = (): UseHome => {
  const [loading, setLoading] = useState<boolean>(false);
  const [slider, setSlider] = useState<BannerInfoDataModel>();
  const { getAllContent } = useShopContent();

  const handleGetSlider = async () => {
    try {
      setLoading(true);
      const data = await getAllContent();
      // TODO: need API integration
      setSlider(data?.data?.find(item => item.section_type === 'slider')?.banner_info ?? undefined);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      // showToastMessage(translate('error.something_went_wrong_title') ?? '', 'error');
    }
  };

  useEffect(() => {
    handleGetSlider();
  }, []);

  return { loading, slider };
};
