import { useTranslate } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import { toastWrapper } from '@fe-web/Atoms/Toast';
import { Menu, Transition } from '@headlessui/react';
import quickplay from 'quickplay-shorts-js';
import { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import DeleteIcon from '../../../../assets/icons/DeleteIcon';
import EditIcon from '../../../../assets/icons/EditIcon';
import { useLoginModalContext } from '../../../../contexts/LoginModal';
import { isLoggedin } from '../../../Feed/Topic/helpers';
import LoaderDark from '../../../common/LoaderDark/LoaderDark';
import CopyIcon from '../../Icons/CopyIcon';
import OptionsDark from '../../Icons/OptionsDark';
import ReplyIcon from '../../Icons/ReplyIcon';
import ReportIcon from '../../Icons/Report';
import { getTimeAgo } from '../../helpers';

export default function Comment({
  comment,
  onDelete,
  onEdit,
  handleReplyComment,
  setShowCommentReportModal,
  setCommentPostId,
  showReply,
  onReplyEdit,
  onReplyDelete,
  setShowHighlightedComment,
}: any) {
  const userData = JSON.parse(localStorage.getItem('userData') || '{}');
  const { setShowLoginModal } = useLoginModalContext();
  const [showReportModal, setShowReportModal] = useState(false);
  const [commentReplies, setCommentReplies] = useState([]);
  const [replylimit, setReplyLimit]: any = useState(1);
  const [hideReplies, setHideReplies] = useState(showReply);
  const [showLoader, setShowLoader] = useState(false);
  const { translate } = useTranslate();
  const language = useSelector((state: RootState) => state.app.language);

  const { postId, commentRefId, userId, profileImageUrl, fullName, description, createdAt, userName } = comment ?? {};

  const [showFullDescription, setShowFullDescription] = useState({
    comment: false,
    reply: false,
    show: description.length > 100,
  });

  const displayedDescription = showFullDescription.show
    ? showFullDescription.comment
      ? description
      : `${description.slice(0, 100)}...`
    : description;

  const isActionEnabled = userData?.userId === userId;

  const toggleReportModal = () => {
    // setShowReportModal((state) => !state);
    setCommentPostId(postId);
    setShowCommentReportModal((prevState: any) => !prevState);
  };

  async function fetchReplies() {
    try {
      setShowLoader(true);
      const activityTimelineModuleObj = new quickplay.QuickPlayActivityTimeline();
      const response = await activityTimelineModuleObj.getActivityTimelineCommentReplyList({
        limit: 3,
        offset: replylimit || 0,
        postId: postId,
      });

      if (response?.status === 200) {
        if (comment['replys']) {
          comment['replys'] = comment['replys'].concat(response.data.result);
          setHideReplies((prevState: any) => prevState);
        } else {
          comment['replys'] = response.data.result;
          setHideReplies((prevState: any) => !prevState);
        }
        // comment['replys'] = removeDuplicates(comment['replys']);
        comment.replyCount = comment.replyCount - 3;
        if (response.data.result.length !== 0) {
          const page = replylimit + 1;
          setReplyLimit(page);
        }
      }
      setShowLoader(false);
    } catch (error) {
      console.error(error);
    }
  }

  //   function removeDuplicates(array: any) {
  //     const uniqueMap: any = {};
  //     const uniqueArray = array.filter((item: any) => {
  //       if (!uniqueMap.hasOwnProperty(item.description)) {
  //         uniqueMap[item.description] = true;
  //         return true;
  //       }
  //       return false;
  //     });

  //     return uniqueArray;
  //   }

  const toggleRepliesHandler = () => {
    setHideReplies((prevState: any) => !prevState);
    fetchReplies();
  };

  const handleCopyCommentToClipboard = () => {
    const textToCopy = displayedDescription;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        toastWrapper('success', '' + `${translate('quickplay.label-text-copy-success')}`);
      })
      .catch(err => {
        toastWrapper('error', '' + `${translate('quickplay.label-text-copy-failed')}`);
      });
  };

  const handleCopyReplyToClipboard = (reply: any) => {
    const textToCopy = reply?.description;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        toastWrapper('success', '' + `${translate('quickplay.label-text-copy-success')}`);
      })
      .catch(err => {
        toastWrapper('error', '' + `${translate('quickplay.label-text-copy-failed')}`);
      });
  };

  const menuItems = [
    {
      show: userData.userId !== userId,
      onClick: async () => {
        const isUserLoggedIn = await isLoggedin();
        if (isUserLoggedIn) {
          toggleReportModal();
        } else {
          setShowLoginModal(true);
        }
      },
      icon: <ReportIcon />,
      label: translate('quickplay.report-comment'),
    },
    {
      show: true,
      onClick: async () => {
        const isUserLoggedIn = await isLoggedin();
        if (isUserLoggedIn) {
          handleReplyComment({ id: postId, refId: commentRefId });
          setHideReplies(true);
        } else {
          setShowLoginModal(true);
        }
      },
      icon: <ReplyIcon />,
      label: `${translate('quickplay.reply-btn')}`,
    },
    // {
    //   show: !hideReplies && comment?.replyCount < 0,
    //   onClick: toggleRepliesHandler,
    //   icon: <EyeOpenIcon />,
    //   label: `${translate('quickplay.show')} ${translate('quickplay.replies')}`,
    // },
    // {
    //   show: hideReplies && comment?.replyCount <= 0,
    //   onClick: toggleRepliesHandler,
    //   icon: <EyeCloseIcon />,
    //   label: `${translate('quickplay.hide')} ${translate('quickplay.replies')}`,
    // },
    {
      show: isActionEnabled,
      className: 'edit_btn',
      onClick: () => onEdit({ id: postId, refId: commentRefId }),
      icon: <EditIcon />,
      label: `${translate('quickplay.label-edit-comment')}`,
    },
    {
      show: isActionEnabled,
      className: 'delete_btn',
      onClick: () => onDelete({ id: postId, refId: commentRefId }),
      icon: <DeleteIcon />,
      label: `${translate('quickplay.label-delete-comment')}`,
    },
    {
      show: true,
      className: 'copy_btn',
      onClick: () => handleCopyCommentToClipboard(),
      icon: <CopyIcon />,
      label: `${translate('quickplay.label-copy')}`,
    },
  ];

  return (
    <>
      <div className={`comment_info relative ${setShowHighlightedComment ? 'bg-light' : ''}`}>
        <div className="left">
          <Link to={`/users/${userId}`} className="img">
            <img src={profileImageUrl ? profileImageUrl : 'https://bits-assets.stag.stcplay.gg/Profile.png'} alt={`${fullName}-profile`} />
          </Link>
          <div className="content">
            <div className="content_info">
              <Link to={`/users/${userId}`}>{userName ? userName : userId}</Link>
              <span>{getTimeAgo(createdAt, language)}</span>
            </div>

            <p>
              {displayedDescription}{' '}
              {showFullDescription.show && (
                <button
                  className="hover:underline show-more-toggle"
                  onClick={() =>
                    setShowFullDescription(prev => ({
                      ...prev,
                      comment: !prev.comment,
                    }))
                  }
                >
                  {showFullDescription.comment ? 'Show Less' : 'Show More'}
                </button>
              )}
            </p>
            <div className="reply_content">
              <button
                className="reply_btn"
                onClick={() => {
                  handleReplyComment({ id: postId, refId: commentRefId });
                  setHideReplies(true);
                }}
              >
                {translate('quickplay.reply-btn')}
              </button>

              {showLoader && <LoaderDark />}

              {hideReplies && comment?.replys?.length > 0 && (
                <button className="view_replies" onClick={toggleRepliesHandler}>
                  {translate('quickplay.label-collapse')} {translate('quickplay.replies')}
                </button>
              )}

              <div className={hideReplies ? 'show-reply' : 'hide-reply'}>
                {comment?.replys &&
                  comment?.replys.map((reply: any) => (
                    <div key={reply?.commentRefId} className="comment_info mb_0">
                      <div className="left">
                        <Link to={`/users/${reply?.userId}`} className="img">
                          <img
                            src={reply?.profileImageUrl ? reply.profileImageUrl : 'https://bits-assets.stag.stcplay.gg/Profile.png'}
                            alt=""
                          />
                        </Link>
                        <div className="content">
                          <Link to={`/users/${reply?.userId}`}>
                            <span>{reply?.userName ? reply?.userName : reply?.userId}</span>
                          </Link>
                          <p>{reply?.description}</p>
                          <button
                            className="reply_btn"
                            onClick={() => {
                              handleReplyComment({
                                id: postId,
                                refId: commentRefId,
                              });
                              setHideReplies(true);
                            }}
                          >
                            {translate('quickplay.reply-btn')}
                          </button>
                        </div>
                      </div>
                      {/* Reply Context Menu Toggle */}
                      <div className="comment-menu-toggle right absolute right-[-18px] top-0">
                        <Menu as="div" className="inline-block text-left">
                          <Menu.Button>
                            <button className="misc_icon" title="More Options">
                              <OptionsDark />
                            </button>
                          </Menu.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="contextMenu contextMenuArabic absolute right-0 z-10 mt-2 origin-top-right rounded-md focus:outline-none shadow-[0_0_32px_#00000014] cursor-pointer p-[0.5rem] directionRtl">
                              {(userData.userId !== userId || userData.userId !== reply?.userId) && (
                                <Menu.Item>
                                  <div
                                    className={`reply_btn reply_actions`}
                                    onClick={async () => {
                                      const isUserLoggedIn = await isLoggedin();
                                      if (isUserLoggedIn) {
                                        toggleReportModal();
                                      } else {
                                        setShowLoginModal(true);
                                      }
                                    }}
                                  >
                                    <div className="btn-wrap">
                                      <span>
                                        <ReportIcon />
                                      </span>
                                      <p>{translate('quickplay.report-comment')}</p>
                                    </div>
                                  </div>
                                </Menu.Item>
                              )}

                              <Menu.Item>
                                <div className={`reply_btn reply_actions`} onClick={() => handleCopyReplyToClipboard(reply)}>
                                  <div className="btn-wrap">
                                    <span>
                                      <CopyIcon />
                                    </span>
                                    <p>{translate('quickplay.label-copy')}</p>
                                  </div>
                                </div>
                              </Menu.Item>

                              <Menu.Item>
                                <div
                                  className={`reply_btn reply_actions`}
                                  onClick={() => {
                                    handleReplyComment({
                                      id: postId,
                                      refId: commentRefId,
                                    });
                                    setHideReplies(true);
                                  }}
                                >
                                  <div className="btn-wrap">
                                    <span>
                                      <ReplyIcon />
                                    </span>
                                    <p>{translate('quickplay.reply-btn')}</p>
                                  </div>
                                </div>
                              </Menu.Item>

                              {userData.userId === reply?.userId && (
                                <>
                                  <Menu.Item>
                                    <div
                                      className={`reply_btn reply_actions edit_btn`}
                                      onClick={() =>
                                        onReplyEdit({
                                          commentId: postId,
                                          commentRef: commentRefId,
                                          replyId: reply.postId,
                                          replyRefId: reply.commentRefId,
                                        })
                                      }
                                    >
                                      <div className="btn-wrap">
                                        <span>
                                          <EditIcon />
                                        </span>
                                        <p>
                                          {translate('quickplay.btn-edit')} {translate('quickplay.reply-btn')}
                                        </p>
                                      </div>
                                    </div>
                                  </Menu.Item>
                                  <Menu.Item>
                                    <div
                                      className={`reply_btn reply_actions delete_btn`}
                                      onClick={() =>
                                        onReplyDelete({
                                          commentId: postId,
                                          commentRef: commentRefId,
                                          replyId: reply.postId,
                                          replyRefId: reply.commentRefId,
                                        })
                                      }
                                    >
                                      <div className="btn-wrap">
                                        <span>
                                          <DeleteIcon />
                                        </span>
                                        <p>
                                          {translate('quickplay.btn-delete')} {translate('quickplay.reply-btn')}
                                        </p>
                                      </div>
                                    </div>
                                  </Menu.Item>
                                </>
                              )}
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    </div>
                  ))}
              </div>

              {comment.replyCount > 0 && (
                <button className="view_replies" onClick={() => fetchReplies()}>
                  {translate('quickplay.label-expand')} {comment.replyCount} {translate('quickplay.replies')}
                </button>
              )}

              {!hideReplies && (comment?.replyCount < 0 || comment?.replys?.length > 0) && (
                <button className="view_replies" onClick={toggleRepliesHandler}>
                  {translate('quickplay.label-expand')} {comment?.replys?.length} {translate('quickplay.replies')}
                </button>
              )}
            </div>
          </div>
        </div>

        {/* Comment Context Menu Toggle */}
        <div className="comment-menu-toggle right absolute right-0 top-0">
          <Menu as="div" className="inline-block text-left">
            <Menu.Button>
              <button className="misc_icon" title="More Options">
                <OptionsDark />
              </button>
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="contextMenu contextMenuArabic absolute right-0 origin-top-left rounded-md cursor-pointer directionRtl">
                {menuItems
                  .filter(({ show }) => show)
                  .map((menuItem, index) => {
                    const { onClick, label, className = '', icon } = menuItem ?? {};

                    return <MenuItem className={className} onClick={onClick} icon={icon} label={label} key={label} />;
                  })}
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
      {/* {commentReplies.length ? (
        <div style={{ marginLeft: '4rem' }}>
          {commentReplies.map((reply: any) => (
            <Comment key={reply.commentRefId} video={video} comment={reply} onDelete={onDelete} onEdit={onEdit} />
          ))}
        </div>
      ) : null} */}

      {/* {showReportModal && (
        <Report
          show={showReportModal}
          onHide={toggleReportModal}
          type="comment"
          payloadData={{ userId, assetId: video.videoId, postId }}
          handleClose={toggleReportModal}
          classes={"comment-report-modal"}
        />
      )} */}
    </>
  );
}

function MenuItem({ label, icon, className = '', onClick }: any) {
  return (
    <Menu.Item>
      <div className={`reply_btn reply_actions${className ? ` ${className}` : ''}`} onClick={onClick}>
        <div className="btn-wrap">
          <span>{icon}</span>
          <p>{label}</p>
        </div>
      </div>
    </Menu.Item>
  );
}
