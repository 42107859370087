import { FormValue, OptionsInputFieldDefinition } from '@fe-monorepo/forms';
import { t } from 'i18next';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

const RadioButton = (props: { value: string; isSelected: boolean; isLast: boolean; onClick: () => void }) => {
  const { value, isLast, isSelected, onClick } = props;

  return (
    <div className="flex items-center gap-[1rem]">
      <div
        className={`flex items-center justify-center grow-0 shrink-0 aspect-square w-[1.25rem] border-[0.125rem] ${
          isSelected ? 'border-sunset' : 'border-secondary/50'
        } rounded-full  cursor-pointer`}
        onClick={onClick}
      >
        {isSelected && <div className="rounded-full aspect-square grow-0 shrink-0 bg-sunset w-[0.625rem]" />}
      </div>

      <p className={`text-fs-body-small font-regular ${isSelected ? 'text-secondary' : 'text-zinc-600-400'}`} onClick={onClick}>
        {value}
      </p>
    </div>
  );
};

interface RadioProps {
  className?: string;

  fieldDefinition: OptionsInputFieldDefinition<string>;
  initialSelection?: string;

  initializeForm: (key: string, value: string) => void;
  onChange: (key: string, value: FormValue) => void;
}

interface RadioButtonsRef {
  setSelection: (value: string) => void;
}

const RadioButtonsV2 = forwardRef<RadioButtonsRef, RadioProps>((props, ref) => {
  const { className, fieldDefinition, initialSelection, onChange } = props;

  const [selectionID, setSelection] = useState<string>(fieldDefinition.defaultOptionID);

  useImperativeHandle(
    ref,
    () => ({
      setSelection: (value: string) => {
        setSelection(value);
      },
    }),
    [],
  );

  useEffect(() => {
    const optionIDs = fieldDefinition.options.map(option => option.id);

    props.initializeForm(fieldDefinition.key, fieldDefinition.defaultOptionID);

    if (initialSelection && optionIDs.includes(initialSelection)) {
      props.initializeForm(fieldDefinition.key, initialSelection);

      setSelection(initialSelection);
    }
  }, [initialSelection, fieldDefinition.options]);

  useEffect(() => {
    onChange(fieldDefinition.key, { value: selectionID, isValid: true });
  }, [selectionID]);

  return (
    <div className={`w-full h-fit flex flex-col gap-[1rem] ${className}`}>
      {fieldDefinition.options.map((option, index) => {
        return (
          <RadioButton
            key={option.id}
            isSelected={option.id === selectionID}
            isLast={index === fieldDefinition.options.length - 1}
            value={t(option.value)}
            onClick={() => setSelection(option.id)}
          />
        );
      })}
    </div>
  );
});

export default RadioButtonsV2;
