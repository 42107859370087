import { gql } from '@apollo/client';

export const TOURNAMENT_SECTIONS_QUERY = {
    getAllTournamentSections: gql`
        query fetchTournamentSections {
            getAllTournamentSections {
                error_msg
                error_code
                is_successful
                data {
                    section_code
                    name
                    list {
                        tournament_id
                        section_id
                        is_team_based
                        name
                        is_paid
                        status_code
                        registration_start_date
                        registration_end_date
                        tournament_start_date
                        max_number_of_participants
                        total_number_of_participants
                        banner_url
                        reference_bracket_code
                        hosted_by
                        team_size
                        registration_fee
                        platforms
                        prize
                        orgnizer_avatar_url
                    }
                    sort_id
                }
            }
        }
    `,

    getTournamentSectionInfo: gql`
        query fetchTournamentSectionInfo($details: TmsSectionGetSectionDataInfoInput) {
            tmsSectionGetTournamentSectionInfo(details: $details) {
                is_successful
                error_code
                error_msg
                data {
                    tournament_id
                    reference_created_by_user_id
                    is_team_based
                    name
                    status_code
                    registration_start_date
                    registration_end_date
                    tournament_start_date
                    max_number_of_participants
                    total_number_of_participants
                    reference_bracket_code
                    banner_url
                    game_name_ar
                    game_name_en
                    last_cursor
                    platform_en
                    platforms
                    platform_ar
                    orgnizer_info {
                        orgnizer_username
                        orgnizer_avatar_url
                        orgnizer_display_name
                        orgnizer_is_official_account
                    }
                }
            }
        }
    `,
};
