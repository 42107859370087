const DropDownText = (props: { isSelected: boolean; text: string; selectedIconPosition: 'start' | 'end'; className?: string }) => {
  return (
    <p
      className={`${props.className || ''}
        px-16 2K:px-[28px] 4K:px-40 8K:px-81
        whitespace-nowrap
        responsive-text-body
        py-8 2K:py-12 4K:py-20 8K:py-44

        ps-16 2K:ps-[14.22px] 4K:ps-[37.16px] 8K:ps-[42.66px]
        ${
          props.isSelected && props.selectedIconPosition === 'start'
            ? `ps-16 2K:ps-[14.22px] 4K:ps-[37.16px] 8K:ps-[42.66px]`
            : `ps-16 2K:ps-[28px] 4K:ps-40 8K:ps-81`
        }

        pe-16 2K:pe-[28px] 4K:pe-40 8K:pe-81
        font-regular
      `}
    >
      {props.text}
    </p>
  );
};

// TODO: change 'any' to T
interface Props<T> {
  currentValue?: T;
  options: T[];
  selectedIcon: JSX.Element;
  selectedIconPosition: 'start' | 'end';
  containerClass?: string;
  dropdownClass?: string;
  optionClassName?: string;
  setToolTipOn?: (value: boolean) => void;

  selectValue: (option: T) => void;
  getStringValue: (option: T) => string;
}

const DropDownList = <T extends { id: string | number }>(props: Props<T>) => {
  const {
    currentValue,
    options,
    selectValue,
    getStringValue,
    selectedIcon,
    selectedIconPosition,
    containerClass,
    dropdownClass,
    optionClassName,
    setToolTipOn,
  } = props;

  const onItemClick = (option: T) => {
    setToolTipOn && setToolTipOn(false);
    selectValue(option);
  };

  return (
    <div
      className={`
          ${containerClass || ''}
          bg-primary
          overflow-y-scroll
          max-h-[190px] 2K:max-h-[256px] 4K:max-h-[384px] 8K:max-h-[768px]
          border border-secondary/20
          border-[1px] 2K:border-[1.77px] 4K:border-[2.66px] 8K:border-[5.33px]
          rounded-[4px] 2K:rounded-[7.11px] 4K:rounded-[10.66px] 8K:rounded-[21.33px]
        `}
    >
      {options?.map((option, index) => {
        const isSelected = option.id === currentValue?.id;

        if (isSelected) {
          return (
            <div
              className={`
                relative
                flex items-center
                text-sunset
                pointer-normal cursor-default
                w-full
                ${dropdownClass}
              `}
              onClick={() => onItemClick(option)}
            >
              {selectedIconPosition === 'start' && selectedIcon}

              <DropDownText isSelected={true} selectedIconPosition={selectedIconPosition} text={getStringValue(currentValue)} />

              {selectedIconPosition === 'end' && selectedIcon}
            </div>
          );
        } else {
          return (
            <div
              key={index}
              className="text-secondary flex items-center hover:bg-sunset/[0.1] hover:text-sunset cursor-pointer w-full"
              onClick={() => onItemClick(option)}
            >
              <DropDownText
                isSelected={false}
                selectedIconPosition={selectedIconPosition}
                text={getStringValue(option)}
                className={optionClassName}
              />
            </div>
          );
        }
      })}
    </div>
  );
};

export default DropDownList;
