import { useMutation } from '@apollo/client';
import { MATCHMAKING_COMMUNITY_MUTATION } from '@fe-monorepo/data-access';
import {
    TmsMatchmakingCommunityBlockUserInput,
    TmsMatchmakingCommunityFollowInput,
    TmsMatchmakingCommunityUnfollowInput,
    TmsMatchmakingCommunityMutationResponse,
} from './types';
import { useState } from 'react';

export const useTmsMatchmakingCommunity = () => {
    const [followGql, { data: followData }] = useMutation<TmsMatchmakingCommunityMutationResponse>(
        MATCHMAKING_COMMUNITY_MUTATION.tmsMatchmakingCommunityFollow,
        {
            errorPolicy: 'all',
        },
    );
    const [unfollowGql, { data: unfollowData }] = useMutation<TmsMatchmakingCommunityMutationResponse>(
        MATCHMAKING_COMMUNITY_MUTATION.tmsMatchmakingCommunityUnfollow,
        {
            errorPolicy: 'all',
        },
    );
    const [blockUserGql, { data: blockUserData }] = useMutation<TmsMatchmakingCommunityMutationResponse>(
        MATCHMAKING_COMMUNITY_MUTATION.tmsMatchmakingCommunityBlockUser,
        {
            errorPolicy: 'all',
        },
    );
    const [isFollowLoading, setIsFollowLoading] = useState<boolean>(false);
    const [isUnfollowLoading, setIsUnfollowLoading] = useState<boolean>(false);
    const [isBlockUserLoading, setIsBlockUserLoading] = useState<boolean>(false);

    const follow = async (details: TmsMatchmakingCommunityFollowInput) => {
        setIsFollowLoading(true);
        const { data } = await followGql({ variables: { details } });
        setIsFollowLoading(false);
        return data;
    };
    const unfollow = async (details: TmsMatchmakingCommunityUnfollowInput) => {
        setIsUnfollowLoading(true);
        const { data } = await unfollowGql({ variables: { details } });
        setIsUnfollowLoading(false);
        return data;
    };
    const blockUser = async (details: TmsMatchmakingCommunityBlockUserInput) => {
        setIsBlockUserLoading(true);
        const { data } = await blockUserGql({ variables: { details } });
        setIsBlockUserLoading(false);
        return data;
    };

    return {
        followData: followData?.tmsMatchmakingCommunityFollow,
        unfollowData: unfollowData?.tmsMatchmakingCommunityUnfollow,
        blockUserData: blockUserData?.tmsMatchmakingCommunityBlockUser,
        isFollowLoading,
        isUnfollowLoading,
        isBlockUserLoading,
        follow,
        unfollow,
        blockUser,
    };
};
