interface Props
{
  className?: string,
  text?: string | null,
}

const ProductTag = (props: Props) =>
{
  return (
    <p
      className=
      {`
        bg-secondary

        py-4 2K:py-[7.11px] 4K:py-[10.66px] 8K:py-[21.33px]
        px-8 2K:px-[14.22px] 4K:px-[21.33px] 8K:px-[42.66px]

        rounded-[2px] 2K:rounded-[3.55px] 4K:rounded-[5.33px] 8K:rounded-[10.66px]

        font-medium
        responsive-text-bodySmall
        text-primary

        ${props.className}
      `}
    >
      {props.text}
    </p>
  )
}

export default ProductTag;
