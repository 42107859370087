import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import { useEffect } from 'react';

import PlayerSelection from '../PlayerSelection';

interface MatchmakingResultsProps {
  goBack: (reset: boolean) => void;
}

const MatchmakingResults = ({ goBack }: MatchmakingResultsProps) => {
  useEffect(() => {
    mixpanelHelper.trackPageView(
      'MATCHMAKING - MATCHMAKING RESULT PAGE',
      'matchmaking_result_page',
      window.location.pathname,
      document.referrer,
    );
  }, []);

  return <PlayerSelection goBack={goBack} />;
};
export default MatchmakingResults;
