import { gql } from "@apollo/client";

export const STAGE_QUERY = {
    
    getAllStage: gql`
    query getAllStage {
        getAllStage {
            error_msg
            is_successful
            error_code
            data {
                name_en
                name_ar
                bracket_code
            }
        }
    }
    `,
};