import { ImageSourcePropType } from 'react-native';

export * from './fonts';

type IImages = {
  [index: string]: ImageSourcePropType;
};

type SOCIALMEDIALINKTYPE = {
  [index: string]: string;
};

type LottieIconAnimationsType = {
  [index: string]: string;
};

export const IMAGES: IImages = {
  AppleStore: require('./images/applestore.png'),
  Avatar: require('./images/avatar.png'),
  BannerPlaceholder: require('./images/Banner Placeholder.png'),
  ChatGamer: require('./images/ChatGamer.png'),
  Compete: require('./images/placeholder/Compete.png'),
  ContentCreater: require('./images/ContentCreater.png'),
  Discover: require('./images/placeholder/Discover.png'),
  FirstChallenger: require('./images/FirstChallenger.png'),
  GamerCard: require('./images/GamerCard.png'),
  GamingController: require('./images/GamingController.png'),
  GamingCup: require('./images/GamigCup.png'),
  GamingHeadphones: require('./images/GamingHeadphones.png'),
  GamingKeyboard: require('./images/GamingKeyboard.png'),
  GamingKeyboard2: require('./images/GamingKeyboard2.png'),
  GamingMouse: require('./images/GamingMouse.png'),
  Home: require('./images/placeholder/Home.png'),
  HomePageBits: require('./images/placeholder/placeHolder_homePage_bits.png'),
  HomePageCompete: require('./images/placeholder/placeHolder_homePage_compete.png'),
  HomePageShop: require('./images/placeholder/placeHolder_homePage_shop.png'),
  HomeMenuOutline: require('./icons/outline/icon_home_outline.svg'),
  HomePageDashboard: require('./images/HomePage.png'),
  IosScreenSTCPlay: require('./images/IosScreenSTCPlay.png'),
  IosScreenSTCPlayAr: require('./images/IosScreenSTCPlayAr.png'),
  ItunesCard: require('./images/ItunesCard.png'),
  MobileGamer: require('./images/MobileGamer.png'),
  MohammedAdnann: require('./images/MohammedAdnann.png'),
  MysteryChallenger: require('./images/MysteryChallenger.png'),
  PlayStore: require('./images/playstore.png'),
  Ps5Console: require('./images/Ps5Console.png'),
  Ps5Controller: require('./images/Ps5Controller.png'),
  QrCode: require('./images/footerQrCode.png'),
  SecondChallenger: require('./images/SecondChallenger.png'),
  SekiroGif: require('./images/SekiroGif.gif'),
  Shop: require('./images/placeholder/Shop.png'),
  SignUpBackground: require('./images/SignUpBackground.png'),
  SignInBackground: require('./images/SignInBackground.png'),
  SmileFace: require('./images/SmileFace.png'),
  VRGamer: require('./images/VRGamer.png'),
  XboxBlack: require('./images/XboxBlack.png'),
  DefaultPlaceholder: require('./images/placeholder/DefaultPlaceholder.png'),
  Banner_Hero_Placeholder: require('./images/placeholder/Shophome/Banner_Hero.png'),
  Banner_Medium_Placeholder: require('./images/placeholder/Shophome/Banner_Medium.png'),
  Carousel_Header_Placeholder: require('./images/placeholder/Shophome/Carousel_Header.png'),
  Carousel_Hotstuff_Placeholder: require('./images/placeholder/Shophome/Carousel_Hotstuff.png'),
  Carousel_QRCode_Placeholder: require('./images/placeholder/Shophome/Carousel_QRcode.png'),
  Carousel_Timed_Placeholder: require('./images/placeholder/Shophome/Carousel_Timed.png'),
  Text_Brand_Placeholder: require('./images/placeholder/Shophome/Text_Brand.png'),
  Text_Tailored_Placeholder: require('./images/placeholder/Shophome/Text_Tailored.png'),
  PlayStation: require('./images/PlayStation.png'),
  Product_Placeholder: require('./images/placeholder/Shophome/Product_Placeholder.png'),
  WalletNoise: require('./images/WalletNoise.png'),
  UserProfile_Header_Placeholder: require('./images/placeholder/user_profile_header_bg.png'),
  internet_Connection: require('./images/internet_connection.png'),

  //Carousel Card Header
  Card_Chest: require('./images/chest.png'),
  Card_Diamond: require('./images/diamond.png'),
  Card_Controller: require('./images/controller.png'),
  Card_QRCode: require('./images/qrcode.png'),
  arrow_xup_yright_black: require('./images/arrow_upper_right_black.png'),
  arrow_xup_yright_white: require('./images/arrow_upper_right_white.png'),
  //Carousel Card Header Placeholder
  CCard_deals_en: require('./images/placeholder/CCard_1.png'),
  CCard_new_en: require('./images/placeholder/CCard_2.png'),
  CCard_treasure_en: require('./images/placeholder/CCard_3.png'),
  CCard_qrcode_en: require('./images/placeholder/CCard_4.png'),
  CCard_compete_en: require('./images/placeholder/CCard_5.png'),

  CCard_deals_ar: require('./images/placeholder/CCard_1_ar.png'),
  CCard_new_ar: require('./images/placeholder/CCard_2_ar.png'),
  CCard_treasure_ar: require('./images/placeholder/CCard_3_ar.png'),
  CCard_qrcode_ar: require('./images/placeholder/CCard_4_ar.png'),
  CCard_compete_ar: require('./images/placeholder/CCard_5_ar.png'),
  Component_1: require('./images/placeholder/Component_1.png'),
  Component_2: require('./images/placeholder/Component_2.png'),
  Component_3: require('./images/placeholder/Component_3.png'),

  //Footer
  FooterGreet: require('./images/footer/subscription_confirmation.png'),

  //Tailored Section
  Icon_Bill: require('./icons/placeholder/bill.png'),
  Icon_Box: require('./icons/placeholder/box.png'),
  Icon_PriceTag: require('./icons/placeholder/pricetag.png'),
  Icon_Shipping: require('./icons/placeholder/shipping.png'),
  Icon_Bill_v2: require('./icons/placeholder/bill_v2.png'),

  //Brands Section
  Brand_Nintendo: require('./images/placeholder/Brand_Nintendo.png'),
  Brand_PS4: require('./images/placeholder/Brand_PS4.png'),
  Brand_PS5: require('./images/placeholder/Brand_PS5.png'),
  Brand_Xbox_1: require('./images/placeholder/Brand_XBox_One.png'),
  Brand_Xbox: require('./images/placeholder/Brand_Xbox.png'),
  Brand_Razer: require('./images/placeholder/Brand_Razer.png'),

  //Medium Banner Dummy Images
  StaticMediumBannerReferNowImage: require('./images/refer-female.png'),
  StaticMediumBannerPreOrderNowBGImage: require(`./images/Dummy_MediumBanner_BG_OrderNow.png`),
  StaticMediumBannerPreOrderNowImage: require(`./images/mass-effect-3.png`),
  ProductPlaceHolderImage: require('./images/robot.png'),

  //Payments
  CreditCard: require('./images/Payments_CreditCard.png'),
  Tabby: require('./images/Payments_TabbyPay.png'),
  ApplePay: require('./images/Payments_ApplePay.png'),
  STCPay: require('./images/Payments_STCPay_v2.png'),

  //Cart
  Confirmation: require('./images/shop/order.png'),
  ConfirmationStar1: require('./images/placeholder/Confirmation_star1.png'),
  ConfirmationStar2: require('./images/placeholder/Confirmation_star2.png'),
  ConfirmationStar3: require('./images/placeholder/Confirmation_star3.png'),
  CartNoItem: require('./images/shop/empty.png'),
  CartNoItemHD: require('./images/placeholder/CartNoItem_HD.png'),
  ReturnConfirmation: require('./images/shop/return.png'),

  //Create Tournament
  CreateTournament: require('./images/placeholder/CreateTournament.png'),
  TournamentGreenCircleCheck: require('./images/tournament_check_circle_green.png'),
  TournamentTooltip: require('./images/tournament_tooltip.png'),

  //Matchmaking
  MatchmakingGuestImage: require('./images/matchmaking/matchmaking_guest.png'),
  MatchmakingLoggedInImage: require('./images/matchmaking/matchmaking_creation.png'),
  MatchmakingLoggedInEllipse: require('./images/placeholder/matchmaking_logged_in_ellipse.png'),

  //Brackets
  MedalGold: require('./images/medal_gold.png'),
  MedalSilver: require('./images/medal_silver.png'),
  MedalBronze: require('./images/medal_bronze.png'),
  //Medals
  GoldMedalImage: require('./images/gold-medal.png'),
  SilverMedalImage: require('./images/silver-medal.png'),
  BronzeMedalImage: require('./images/bronze-medal.png'),

  // Profile
  ProfileTournamentImage: require('./images/placeholder/placeholder_tournament.png'),
  DontBeAStrangerImage: require('./images/login/signup_popup.png'),

  //Contact Us
  MailUsImage: require('./images/Mail_Us.png'),
  ChatWithUsImage: require('./images/Chat_With_Us.png'),
  CallUsImage: require('./images/Call_Us.png'),
  VisitUsImage: require('./images/Visit_Us.png'),
  MailUsMobileImage: require('./images/Mail_Us_Mobile.png'),
  ChatWithUsMobileImage: require('./images/Chat_With_Us_Mobile.png'),
  CallUsMobileImage: require('./images/Call_Us_Mobile.png'),
  VisitUsMobileImage: require('./images/Visit_Us_Mobile.png'),

  //Connect
  GroupAvatar: require('./images/img_group_avatar.png'),

  //Blocked
  BlockedEmpty: require('./images/settings/blocked_empty_state.png'),

  //Mini Games
  HighlightPlaceHolder: require('./images/placeholder/MiniGameBanner.png'),
  MiniGameIconPlaceHolder: require('./images/placeholder/MiniGameIcon.png'),
  MiniGameDetailsBanner: require('./images/placeholder/MiniGameDetailBannerPlaceholder.png'),
  MiniGameBannerIcon: require('./images/placeholder/MiniGameBannerIcon.png'),
  MiniGameCardFallBack: require('./images/minigame/mini-game-card-fallback.png'),
  MiniGameStaticLayer1: require('./images/minigame/mini-game-static-layer1.png'),
  MiniGameStaticLayer2: require('./images/minigame/mini-game-static-layer2.png'),
  MiniGameMobileStaticLayer1: require('./images/minigame/mini-game-mobile-static-layer1.png'),
  MiniGameMobileStaticLayer2: require('./images/minigame/mini-game-mobile-static-layer2.png'),
  MiniGameBannerGlow: require('./images/minigame/banner-glow.png'),

  //Notifications
  NotificationUserAvatar: require('./images/placeholder/notification/avatarGroupPlaceholder.png'),
  NotificationVs: require('./images/NotificationVs.png'),

  // Home
  HomeBannerLight: require('./images/home/banner_light.png'),
  HomeBannerDark: require('./images/home/banner_dark.png'),
  HomeBannerLightMobile: require('./images/home/banner_light_mobile.png'),
  HomeBannerDarkMobile: require('./images/home/banner_dark_mobile.png'),
  HomeMatchmakingCard1: require('./images/homepage_matchmaking_card_1.png'),
  HomeMatchmakingCard2: require('./images/homepage_matchmaking_card_2.png'),
  HomeMatchmakingCard3: require('./images/homepage_matchmaking_card_3.png'),

  //My Wallet
  WalletTreasure: require('./images/settings/wallet_treasure.png'),
  WalletNoTransaction: require('./images/settings/wallet_no_transactions.png'),
  WalletCoin: require('./images/settings/wallet_coin.png'),

  //Search
  GlobalSearchEmptyStage: require('./images/search/empty_state.png'),

  // Ewc
  TbdAvatarDark: require('./images/ewc/tbd_dark.png'),
  TbdAvatarLight: require('./images/ewc/tbd_light.png'),
  TimerBombImage: require('./images/ewc/bomb.png'),
  EwcStandingsRank1: require('./images/ewc/rank_1.png'),
  EwcStandingsRank2: require('./images/ewc/rank_2.png'),
  EwcStandingsRank3: require('./images/ewc/rank_3.png'),
  EwcDontBeAStrangerImage: require('./images/ewc/stranger_image.png'),
  EsportsWorldCupBannerLight: require('./images/ewc/esports-wc-banner-light.png'),
  EsportsWorldCupBanner: require('./images/ewc/esports-wc-banner.png'),
  EsportsWorldCupPattern: require('./images/ewc/esports-wc-pattern.png'),
  EsportsWorldCupBGLive: require('./images/ewc/ewc_bg.png'),
  StcTVBGLive: require('./images/ewc/stc_tv_bg.png'),

  //Common
  EmptyStates: require('./images/common/empty_states.png'),

  NoContent: require('./images/bits/no-content.png'),
  NoFavouritesBG: require('./images/bits/no-fav-bg.png'),

  //Verify Motivation
  VerifyPhone: require('./images/illustration/verify_phone.png'),

  // Gen G
  GenG_GuestUser_Banner: require('./images/GenGuestBanner.png'),
  GenG_Success: require('./images/ewc/ActionSuccess.png'),
  GenG_Error: require('./images/ewc/ActionFailure.png'),
};

export const SOCIALMEDIALINK: SOCIALMEDIALINKTYPE = {
  facebook: 'https://www.facebook.com/stcplay.hub/',
  instagram: 'https://www.instagram.com/stcplay/?hl=en',
  linkedin: 'https://www.linkedin.com/company/stc-play/',
  twitter: 'https://twitter.com/stcplay?lang=en',
  youtube: 'https://www.youtube.com/channel/UCiTN5fHOYfUyl2yalDvZHmA?app=desktop',
};

export const LottieIconAnimations: LottieIconAnimationsType = {
  Icon_Cart: require('./icons/lottie/icon_Cart.json'),
  Icon_Checked: require('./icons/lottie/icon_Checked.json'),
  Icon_Van: require('./icons/lottie/icon_Van.json'),
  Icon_Envelope: require('./icons/lottie/icon_Envelope.json'),
  Icon_Wallet: require('./icons/lottie/icon_Wallet.json'),
  Icon_Loader: require('./icons/lottie/icon_Loader.json'),
  Icon_Approved: require('./icons/lottie/approved.svg'),
  Icon_HourGlass: require('./icons/lottie/icon_HourGlass.json'),
  Icon_Email: require('./icons/lottie/icon_Email.json'),
  Icon_Cash: require('./icons/lottie/icon_Cash.json'),
  Icon_Rejected: require('./icons/lottie/icon_Rejected.json'),
  Icon_approved: require('./icons/lottie/icon_Approved.json'),
  Icon_VanReturn: require('./icons/lottie/icon_VanReturn.json'),
  Mini_Games_Cover: require('./icons/lottie/minigame/mini_games_cover.json'),
  Mini_Games_Mobile_Cover: require('./icons/lottie/minigame/minigames_bg_mobile.json'),
};
