import { gql } from '@apollo/client';

const TMS_MATCHES_MUTATION = {
    submitScore: gql`
        mutation tmsMatchesSubmitScore($details: TmsMatchesSubmitScoreInput, $file: Upload) {
            tmsMatchesSubmitScore(details: $details, file: $file) {
                is_successful
                error_code
                error_msg
            }
        }
    `,

    reportScore: gql`
        mutation tmsMatchesReportScore($details: TmsMatchesReportScoreInput) {
            tmsMatchesReportScore(details: $details) {
                is_successful
                error_code
                error_msg
                app_code
            }
        }
    `,

    resetMatch: gql`
        mutation tmsMatchesResetMatch($details: TmsMatchesResetMatchInput) {
            tmsMatchesResetMatch(details: $details) {
                is_successful
                error_code
                error_msg
            }
        }
    `,

    removeReferee: gql`
        mutation tmsMatchesRemoveReferee($details: TmsMatchesRemoveRefereeInput) {
            tmsMatchesRemoveReferee(details: $details) {
                is_successful
                error_code
                error_msg
            }
        }
    `,

    resolveConflict: gql`
        mutation tmsMatchesResolveConflict($details: TmsMatchesResolveConflictInput) {
            tmsMatchesResolveConflict(details: $details) {
                is_successful
                error_code
                error_msg
            }
        }
    `,

    advance: gql`
        mutation tmsMatchesAdvance($details: TmsMatchesAdvanceInput) {
            tmsMatchesAdvance(details: $details) {
                is_successful
                error_code
                error_msg
            }
        }
    `,
};

export { TMS_MATCHES_MUTATION };
