import { useLazyQuery, useMutation } from '@apollo/client';
import { ORDER_MUTATION, ORDER_QUERY } from '@fe-monorepo/data-access';
import {
    CheckoutInput,
    OrderMutationResponse,
    OrderQueryResponse,
    ResendCodeInput,
    ResendCodeResponse,
    OrderShipmentInput,
    OrderShipmentResponse,
    OrderFilteredInput,
    CancelOrderResponse,
} from '@fe-monorepo/models';
import { useEffect, useState } from 'react';
//import { useInvoice } from './useInvoice';

const useOrder = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isOrderFilterLoading, setIsOrderFilterLoading] = useState(false);
    const [isCheckoutLoading, setIsCheckoutLoading] = useState(false);
    const [isResendLoading, setIsResendLoading] = useState(false);
    //  const { getInvoice, invoiceInfo } = useInvoice(true);
    const [getInfo, { data: info }] = useLazyQuery<OrderQueryResponse>(ORDER_QUERY.getAllOrders, {
        errorPolicy: 'all',
    });
    const [getInfoFiltered, { data: infoFiltered }] = useLazyQuery<OrderQueryResponse, OrderFilteredInput>(
        ORDER_QUERY.getAllFilteredOrderData,
        {
            errorPolicy: 'all',
        },
    );
    const [checkoutGql, { data: checkoutData }] = useMutation<OrderMutationResponse>(ORDER_MUTATION.checkout, {
        errorPolicy: 'all',
    });

    const [cancelOrderGql] = useMutation<CancelOrderResponse>(ORDER_MUTATION.cancelOrder, {
        errorPolicy: 'all',
    });

    const [resendCodeGql, { data: resendCodeData }] = useMutation<ResendCodeResponse>(ORDER_MUTATION.resendCode, {
        errorPolicy: 'all',
    });
    const [createPhysicalGql, { data: physicalData }] = useMutation<OrderShipmentResponse>(ORDER_MUTATION.createPhysical, {
        errorPolicy: 'all',
    });

    const [createDigitalGql, { data: digitalData }] = useMutation<OrderShipmentResponse>(ORDER_MUTATION.createDigital, {
        errorPolicy: 'all',
    });
    const getAllOrders = async () => {
        setIsLoading(true);
        try {
            const { data } = await getInfo();
            return data?.getAllOrders;
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const getAllOrdersFiltered = async (orderFilterInput: OrderFilteredInput) => {
        setIsOrderFilterLoading(true);
        const { data } = await getInfoFiltered({ variables: { ...orderFilterInput } });
        setIsOrderFilterLoading(false);
        return data;
    };

    const checkout = async (checkoutInput: CheckoutInput) => {
        setIsCheckoutLoading(true);
        const { data } = await checkoutGql({ variables: { details: checkoutInput } });
        return data?.checkout;
    };

    const resend = async (resendInput: ResendCodeInput) => {
        setIsResendLoading(true);
        await resendCodeGql({ variables: { details: resendInput } });
        setIsResendLoading(false);
    };
    const createPhysical = async (physicalInput: OrderShipmentInput) => {
        const { data } = await createPhysicalGql({ variables: { details: physicalInput } });
        return data?.createPhysical;
    };

    const createDigital = async (digitalInput: OrderShipmentInput) => {
        const { data } = await createDigitalGql({ variables: { details: digitalInput } });
        return data?.createDigital;
    };

    const cancelOrder = async (checkoutInput: CheckoutInput) => {
        const { data } = await cancelOrderGql({ variables: { details: checkoutInput } });
        return data?.cancelOrder;
    };

    // useEffect(() => {
    //     if ((physicalData?.createPhysical.is_successful || digitalData?.createDigital.is_successful) && invoiceInfo?.invoice_number) {
    //         getInvoice({
    //             details: {
    //                 invoice_number: invoiceInfo?.invoice_number
    //             }
    //         })
    //     }
    // }, [physicalData, digitalData]);

    return {
        isLoading,
        isOrderFilterLoading,
        isCheckoutLoading,
        orders: info?.getAllOrders,
        ordersfiltered: infoFiltered?.getFilteredOrders,
        checkoutData: checkoutData?.checkout,
        getAllOrders,
        checkout,
        resend,
        createPhysical,
        createDigital,
        cancelOrder,
        physicalData,
        digitalData,
        getAllOrdersFiltered,
    };
};

export { useOrder };
