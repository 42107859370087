import { useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import { MATCHMAKING_DATA_MUTATION, MATCHMAKING_DATA_QUERY } from '@fe-monorepo/data-access';
import { MatchmakingGetTypesResponse } from '@fe-monorepo/models';
import {
    TmsMatchmakingDataCreateInput,
    TmsMatchmakingDataMutationResponse,
    TmsMatchmakingDataQueryResponse,
    TmsMatchmakingDataQuitInput,
    TmsMatchmakingDataSearchingInput,
} from './types';

export const useTmsMatchmakingData = () => {
    const [getTypesGql, { data: typesData }] = useLazyQuery<MatchmakingGetTypesResponse>(MATCHMAKING_DATA_QUERY.getTypes, {
        errorPolicy: 'all',
    });
    const [searchingGql, { data: searchingData }] = useLazyQuery<TmsMatchmakingDataQueryResponse>(
        MATCHMAKING_DATA_QUERY.tmsMatchmakingDataSearching,
        {
            errorPolicy: 'all',
        },
    );
    const [createGql, { data: createData }] = useMutation<TmsMatchmakingDataMutationResponse>(
        MATCHMAKING_DATA_MUTATION.tmsMatchmakingDataCreate,
        {
            errorPolicy: 'all',
        },
    );
    const [quitGql, { data: quitData }] = useMutation<TmsMatchmakingDataMutationResponse>(
        MATCHMAKING_DATA_MUTATION.tmsMatchmakingDataQuit,
        {
            errorPolicy: 'all',
        },
    );

    const [isGetTypesLoading, setIsGetTypesLoading] = useState<boolean>(false);
    const [isSearchingLoading, setIsSearchingLoading] = useState<boolean>(false);
    const [isCreateLoading, setIsCreateLoading] = useState<boolean>(false);
    const [isQuitLoading, setIsQuitLoading] = useState<boolean>(false);

    const getTypes = async () => {
        setIsGetTypesLoading(true);
        const { data } = await getTypesGql();
        setIsGetTypesLoading(false);
        return data;
    };

    const searching = async (details: TmsMatchmakingDataSearchingInput) => {
        setIsSearchingLoading(true);
        const { data } = await searchingGql({ variables: { details } });
        setIsSearchingLoading(false);
        return data;
    };

    const create = async (details: TmsMatchmakingDataCreateInput) => {
        setIsCreateLoading(true);
        const { data } = await createGql({ variables: { details } });
        setIsCreateLoading(false);
        return data;
    };

    const quit = async (details: TmsMatchmakingDataQuitInput) => {
        setIsQuitLoading(true);
        const { data } = await quitGql({ variables: { details } });
        setIsQuitLoading(false);
        return data;
    };

    return {
        types: typesData,
        searchingData: searchingData?.tmsMatchmakingDataSearching,
        createData: createData?.tmsMatchmakingDataCreate,
        quitData: quitData?.tmsMatchmakingDataQuit,
        isGetTypesLoading,
        isSearchingLoading,
        isCreateLoading,
        isQuitLoading,
        getTypes,
        searching,
        create,
        quit,
    };
};
