import { matchPath } from 'react-router-dom';

export enum PageType {
  CORPORATE = 'Corporate',
  HOME = 'Home',
  DISCOVER = 'Discover',

  SHOP = 'Shop',
  CART = 'Cart',
  WISHLIST = 'Wishlist',
  CHECKOUT = 'Checkout',
  ORDERS = 'Orders',

  PLAY = 'Play',
  VISIT = 'Visit',
  STREAM = 'Stream',

  SEARCH = 'Search',
  CHAT = 'Chat',
  NOTIFICATIONS = 'Notifications',

  USERS = 'Users',
  PROFILE = 'Profile',
  SETTINGS = 'Settings',

  LOGIN = 'Login',
  SIGNUP = 'Signup',
  PRESIGNUP = 'Presignup',
  PRELOGIN = 'Prelogin',

  FORGET_PASSWORD = 'Forget Password',

  WALLET = 'Wallet',

  CALLBACK = 'Callback',
  ERROR = 'ERROR',
}

export enum AppRoutes {
  // Main pages
  home = '/home',

  // Shop
  shop = '/shop',
  returnsPage = '/profile/settings/my-returns',
  shopSubCategory = '/shop',
  shopCategory = '/shop/:collectionCode',
  product = '/shop/:collectionCode/:productID',
  shopSubCategories = '/shop/brandlist',
  shopNewlyCreated = '/shop/newly_created',
  shopGeneric = '/shop/stcplay',
  shopLanding = '/shop/landing',

  // Cart
  cart = '/shop/cart',

  // Wishlist
  wishList = '/shop/wishlist',

  // Profile
  userProfile = '/profile',
  users = '/users',
  profile = '/users/:username',
  bitsDrafts = '/users/:username/bits/drafts',

  // Settings
  settings = '/profile/settings',
  useraccount = '/profile/settings/account',
  myorders = '/settings/my-orders',
  chat_preferences = '/settings/chat-preferences',
  walletSettings = '/settings/wallet',
  savedaddress = '/settings/saved-addresses',
  savedaddressRoot = 'saved-addresses',
  myprofile = '/settings/my-profile',
  settingsRoot = '/settings',
  myreturns = '/settings/my-returns',
  settingsLanguage = '/settings/language',
  settingsCountry = '/settings/country',
  settingsTimezone = '/settings/timezone',
  settingsCurrency = '/settings/currency',
  settingsAccountPrivacy = '/settings/accountprivacy',
  settings2fa = '/settings/2fa',
  savedaddressEdit = '/settings/saved-addresses/edit',
  savedaddressAdd = '/settings/saved-addresses/add',

  // Quickplay
  bits = '/bits',
  bitsPlay = '/bits-play',
  bitsPlayer = '/bits-play/bits',

  // CHAT
  messages = '/connect',
  newMessage = '/connect/new',
  chat = '/chat',

  // Checkout
  cartCallback = '/payment/:paymentMethod/:status/:invoice',

  // Stream
  streams = '/streams',

  // Authentication Pages
  auth = '/auth/:type',

  // Login
  authSignIn = '/auth/sign-in',

  // Register
  authSignUp = '/auth/sign-up',

  // Forget Password
  forgetpasswordRoute = '/auth/forget-password',

  callback = '/callback/:auth/:version/:type/:provider',

  // wallet = '/wallet',
  myOrders = '/my-orders',

  // Search
  search = '/search',

  // Error
  page404 = 'page404',
  noInternet = 'no-internet',

  // Discover
  discover = '/discover',

  // Corporate
  landingPage = '/',
  ourStory = '/ourStory',
  partnership = '/partnership',
  news = '/news',

  privacy = '/privacy',
  terms = '/terms',
  legal = '/legal',
  accessibility = '/accessibility',

  // eWC
  ewc = '/esports-world-cup',

  // play
  play = '/play',
  tournaments = '/play/tournaments',
  tournament = '/play/tournaments/:tournamentID',
  createTournament = '/play/tournaments/create',
  matchmaking = '/play/matchmaking',
  games = '/play/games',
  visit = 'visit',
  teamUp = 'team-up',
  compete = '/compete',

  helpAndSupport = '/help-and-support',
  help = '/help',
  helpShoppingDelivery = '/help/shopping-delivery',
  helpExchangeReturns = '/help/exchange-returns',
  helpTemrsCondition = '/help/terms-and-conditions',
  helpPrivacyPolicy = '/help/privacy-policy',
  helpReturns = 'help/returns',

  FAQs = '/FAQs',
  contactUS = '/help/contact-us',
  returns = '/my-returns',

  //Notification
  notifications = '/notifications',

  //Video
  video = '/video',

  //Registration
  registrationForm = '/registration-form',

  // Gen G
  genGLandingPage = '/gen-g',
}

enum RoutePatterns {
  landingPage = '/',
  home = '/home',
  search = '/search/*',

  cart = '/shop/cart',
  wishList = '/shop/wishlist',
  shop = '/shop/*',

  myorders = '/settings/my-orders',
  wallet = '/settings/wallet',
  settings = '/settings/*',
  profile = '/users/:username',
  users = '/users',
  notifications = '/notifications',

  play = '/play/*',
  compete = '/compete/*',
  messages = '/connect/*',
  discover = '/discover/*',

  signin = '/auth/sign-in/*',
  signup = '/auth/sign-up/*',
  preSignIn = '/auth/prelogin/*',
  preSignup = '/auth/presignup/*',

  callback = '/callback/*',

  forgetpassword = '/auth/forget-password/*',

  ourStory = '/ourStory',
  partnership = '/partnership',
  news = '/news',
  privacy = '/privacy',
  terms = '/terms',
  legal = '/legal',
  accessibility = '/accessibility',
  helpAndSupport = '/help-and-support',
  FAQs = '/FAQs',
  contactUS = '/contact-us',
  returns = '/settings/my-returns',

  payment = '/payment/',
}

// { [index in AppRoutes]: PageType }
const PagesObj: { [index in RoutePatterns]: PageType } = {
  [RoutePatterns.landingPage]: PageType.CORPORATE,
  [RoutePatterns.ourStory]: PageType.CORPORATE,
  [RoutePatterns.partnership]: PageType.CORPORATE,
  [RoutePatterns.news]: PageType.CORPORATE,
  [RoutePatterns.privacy]: PageType.CORPORATE,
  [RoutePatterns.terms]: PageType.CORPORATE,
  [RoutePatterns.legal]: PageType.CORPORATE,
  [RoutePatterns.accessibility]: PageType.CORPORATE,
  [RoutePatterns.helpAndSupport]: PageType.CORPORATE,
  [RoutePatterns.FAQs]: PageType.CORPORATE,
  [RoutePatterns.contactUS]: PageType.CORPORATE,
  [RoutePatterns.returns]: PageType.CORPORATE,

  [RoutePatterns.search]: PageType.SEARCH,

  [RoutePatterns.home]: PageType.HOME,
  [RoutePatterns.shop]: PageType.SHOP,
  [RoutePatterns.cart]: PageType.CART,
  [RoutePatterns.wishList]: PageType.WISHLIST,
  [RoutePatterns.payment]: PageType.CHECKOUT,

  [RoutePatterns.myorders]: PageType.ORDERS,
  [RoutePatterns.wallet]: PageType.WALLET,
  [RoutePatterns.settings]: PageType.SETTINGS,

  [RoutePatterns.profile]: PageType.PROFILE,
  [RoutePatterns.users]: PageType.USERS,

  [RoutePatterns.notifications]: PageType.NOTIFICATIONS,

  [RoutePatterns.play]: PageType.PLAY,
  [RoutePatterns.compete]: PageType.PLAY,
  [RoutePatterns.messages]: PageType.CHAT,
  [RoutePatterns.discover]: PageType.DISCOVER,

  [RoutePatterns.signin]: PageType.LOGIN,
  [RoutePatterns.signup]: PageType.SIGNUP,
  [RoutePatterns.forgetpassword]: PageType.FORGET_PASSWORD,

  [RoutePatterns.preSignIn]: PageType.PRELOGIN,
  [RoutePatterns.preSignup]: PageType.PRESIGNUP,
  [RoutePatterns.callback]: PageType.CALLBACK,
};

export const getPageType = (pathname: string): PageType => {
  const pathes = Object.values(RoutePatterns);

  const pathObject = pathes.find(path => matchPath({ path }, pathname));

  if (pathObject) {
    return PagesObj[pathObject] ?? PageType.ERROR;
  } else {
    return PageType.ERROR;
  }
};
