import { APP_DEEP_LINKS, APP_STORE_LINKS, IconNames } from '@fe-monorepo/helper';
import { BGType, useAccount, useThemedBackground, useTranslate, useUserProfile } from '@fe-monorepo/hooks';
import { RootState, clearDeliveryAddress, useAppDispatch } from '@fe-monorepo/store';
import Icon from '@fe-web/Atoms/Icon/Icon';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import CurrencyText from '@fe-web/Atoms/Text/CurrencyText';
import PlayerAvatar from '@fe-web/Molecules/PlayerAvatar';
import convivaHelper from '@fe-web/helpers/convivaHelper';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import { useBitsProvider } from '@fe-web/hooks/useBits/BitsProvider';
import useMobileDetect from '@fe-web/hooks/useMobileDetect';
import { useNotificationProvider } from '@fe-web/hooks/useNotification';
import { t } from 'i18next';
import { getEnvironment } from 'libs/data-access/src/graphql/apiEnvironments';
import { isIOS } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { AppRoutes } from '../../../app.routes.enum';
import useDrawerToggle from '../../../hooks/useDrawerToggle';
import usePageLayout from '../../../hooks/usePageLayout';
import Button from '../../Atoms/Buttons/Button';
import Drawer from '../../Atoms/Drawer';
import { navlinks } from './NavLinks';
import ThemeToggle from './ThemeToggle';
import UserProfile from './UserProfile';

interface IMenuItems {
  label: string;
  path: string;
  icon: IconNames;
  isGuestViewable: boolean;
  isGuestOnlyViewable?: boolean;
}

const MenuItems: Array<IMenuItems> = [
  {
    label: 'pageTitle_wallet',
    path: AppRoutes.walletSettings,
    icon: IconNames.walletNew,
    isGuestViewable: false,
  },
  {
    label: 'pageTitle_myOrders',
    path: AppRoutes.myorders,
    icon: IconNames.orders,
    isGuestViewable: false,
  },
  // {
  //     label: 'chat.sidebar.chats',
  //     path: AppRoutes.settings,
  //     icon: IconNames.icon_header_chat,
  // },
  {
    label: 'pageTitle_NotificationCenter',
    path: AppRoutes.notifications,
    icon: IconNames.bellNotification,
    isGuestViewable: false,
  },
  {
    label: 'settings.changeLanguage',
    path: AppRoutes.settingsLanguage,
    icon: IconNames.language,
    isGuestViewable: true,
    isGuestOnlyViewable: true,
  },
  {
    label: 'profile.settings',
    path: AppRoutes.settings,
    icon: IconNames.gearIcon,
    isGuestViewable: true,
  },
  {
    label: 'settingPage_help',
    path: AppRoutes.help,
    icon: IconNames.support,
    isGuestViewable: true,
  },
];

const MobileDrawer = () => {
  const navigate = useNavigate();
  const { direction, btnClass } = usePageLayout();
  const { translate } = useTranslate();
  const { logoutFromQP } = useBitsProvider();
  const { user, isLoggedIn } = useUserProfile();
  const { notificationLength } = useNotificationProvider();
  const profile = user;
  const { logout } = useAccount();
  const total_balance = useSelector((state: RootState) => state.wallet.userWallet.total_balance);
  const language = useSelector((state: RootState) => state.app.selectedLanguage);
  const { themeConfig } = useSelector((state: RootState) => state.app);
  const { isOpen: open, toggleDrawer } = useDrawerToggle();
  const { pathname } = useLocation();
  const { envCode } = getEnvironment();
  const { removeDeviceNotification } = useNotificationProvider();
  const dispatch = useAppDispatch();
  const isMobile = useMobileDetect();
  const { backgroundImage } = useThemedBackground(BGType.landing, isMobile);

  const isVerifyMotivationNeeded = !user?.email || !user?.is_email_verified || !user?.mobile || !user?.is_mobile_verified;

  const onChatClick = () => {
    const url = APP_DEEP_LINKS.xPlayChat_Android
      .replace('APP_HOST', envCode + APP_DEEP_LINKS.xPlayChat_Android_APP_HOST)
      .replace('APP_PACKAGE', APP_DEEP_LINKS.xPlayChat_Android_APP_PACKAGE)
      .replace('APP_SCHEME', APP_DEEP_LINKS.xPlayChat_Android_APP_SCHEME);
    window.location.replace(isIOS ? APP_DEEP_LINKS.xPlayChat : url); //Opens the APP

    setTimeout(() => {
      window.location.replace(isIOS ? APP_STORE_LINKS.iosXplay : APP_STORE_LINKS.androidXplay); //Opens store on timeout
    }, 1000);

    //USING INTENT
  };

  const verifyNotificationCount = !user?.email || !user?.is_email_verified || !user?.mobile || !user?.is_mobile_verified ? 1 : 0;

  return (
    <Drawer open={open} className="border-t border-secondary/20 mt-[1.875rem]">
      <section
        className={`h-[calc(100vh-35px)] px-[1.25rem] ${
          !user?.username ? 'my-[3.75rem] mt-[1.25rem]' : 'pt-[1.25rem] mb-[3.75rem]'
        }  pb-[3.75rem] flex flex-col overflow-y-auto`}
        style={
          themeConfig && backgroundImage
            ? {
                backgroundImage: `url(${backgroundImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'top 10rem',
              }
            : {}
        }
      >
        {user?.username && <UserProfile />}
        <div>
          {user?.username && (
            <div className="w-full px-[1rem] py-[0.75rem] bg-zinc-100-neutral-800 rounded-lg items-center inline-flex">
              <PlayerAvatar
                avatarUrl={profile?.avatar_url ?? ''}
                avatarSize={3.5}
                dir="row"
                displayName={profile?.display_name}
                userId={`@${profile?.username}`}
                displayNameStyle="webkit-truncate-2 text-secondary text-fs-body-large font-medium"
                userIdStyle="py-[0.125rem] text-zinc-600-400 text-fs-caption font-regular"
                containerStyle="gap-[1rem] w-full"
                labelContainerStyle="gap-[0.5rem]"
                hasVerified={true}
                hasGoToProfile
              />
            </div>
          )}

          <div className={`flex flex-col items-start gap-[2rem] ${isLoggedIn ? 'mb-[2rem]' : 'my-[2rem]'}`}>
            {navlinks.map(({ label, link }, index) => (
              <Link
                onClick={() => toggleDrawer(!open)}
                to={link}
                key={label}
                className={`font-regular flex items-center text-secondary 
                  h-full hover:cursor-pointer not-italic text-fs-subtitle 
                  w-full ${user?.username && index === 0 ? 'mt-[2rem]' : ''}`}
              >
                {t(label)}
              </Link>
            ))}
          </div>
        </div>
        {/* MENU ITEMS */}
        <div className="w-full h-[0px] border-[0.063rem] border-gray-200-zinc-700"></div>
        <div className="flex-col justify-start items-start gap-[2rem] inline-flex my-[2rem]">
          {MenuItems.map(menu => {
            const isChat = menu.label === 'chat.sidebar.chats'; //(isChat && !isLoggedIn)
            return (
              <>
                {(isLoggedIn ? !menu?.isGuestOnlyViewable : menu?.isGuestViewable) && (
                  <div
                    className="w-full cursor-pointer"
                    onClick={
                      isChat
                        ? () => onChatClick()
                        : () => {
                            navigate(menu.path);
                            toggleDrawer(!open);
                          }
                    }
                  >
                    <div className="w-full self-stretch justify-start items-center gap-[0.5rem] inline-flex">
                      <div className="relative">
                        {(isLoggedIn ? true : menu.label === 'settings.changeLanguage') && (
                          <ResponsiveIcon
                            className="fill-secondary"
                            name={menu.icon}
                            baseHeight={isChat ? 30 : 20}
                            baseWidth={isChat ? 30 : 20}
                          />
                        )}
                        {menu.label === 'pageTitle_NotificationCenter' &&
                          notificationLength !== undefined &&
                          (notificationLength > 0 || verifyNotificationCount > 0) && (
                            <span
                              className="
                                absolute
                                bottom-[0.5rem] left-[0.5rem]
                                flex justify-center hover:cursor-pointer
                                px-[0.5rem]
                                rounded-[1.875rem] text-caption
                              bg-red text-white100 font-regular"
                            >
                              {notificationLength > 9 ? '9+' : notificationLength + verifyNotificationCount}
                            </span>
                          )}
                      </div>
                      <div className="grow shrink basis-0 text-secondary text-fs-body font-regular">{t(menu.label)}</div>
                      {menu.label === 'pageTitle_wallet' && (
                        <div className="grow shrink basis-0 ltr:text-right rtl:text-left text-zinc-600-400 text-fs-body font-regular">
                          <CurrencyText currency={Number(total_balance)} />
                        </div>
                      )}

                      {menu.label === 'settings.changeLanguage' && (
                        <div className="grow shrink basis-0 ltr:text-right rtl:text-left text-zinc-600-400 text-fs-body font-regular">
                          {language}
                        </div>
                      )}
                      {isVerifyMotivationNeeded && menu.label === 'profile.settings' && (
                        <Icon
                          className="
                            cursor-pointer
                            fill-secondary
                            mr-[1rem]"
                          name={IconNames.alert}
                          height={20}
                          width={20}
                        />
                      )}
                      <ResponsiveIcon
                        className="
                          fill-secondary cursor-pointer ltr:rotate-[270deg] rtl:rotate-90
                          animate__animated animate__fadeIn"
                        name={IconNames.chevron}
                        baseHeight={20}
                        baseWidth={20}
                      />
                    </div>
                  </div>
                )}
                {!isLoggedIn && menu.label === 'settings.changeLanguage' && (
                  <>
                    <ThemeToggle iconSize={20} text={t('dark_mode')} />
                    <div className="w-full h-[0px] border-[0.063rem] border-gray-200-zinc-700"></div>
                  </>
                )}
              </>
            );
          })}
        </div>
        {/* THEME AND LOGOUT */}
        <div className="flex-col justify-start items-center gap-[2rem] inline-flex">
          {isLoggedIn && (
            <>
              <div className="w-full h-[0px] border-[0.063rem] border-gray-200-zinc-700"></div>
              <ThemeToggle iconSize={20} text={t('dark_mode')} />
            </>
          )}
          {user?.username && (
            <div className="self-stretch h-[0.375rem] mb-[5.5rem] flex-col justify-start items-start flex">
              <div
                onClick={() => {
                  removeDeviceNotification();
                  dispatch(clearDeliveryAddress());
                  logout();
                  mixpanelHelper.reset();
                  convivaHelper.setIdentity('GUEST');
                  logoutFromQP();
                  localStorage.removeItem('selectedTimezone');
                  localStorage.removeItem('selectedCurrency');
                  localStorage.removeItem('selectedCountry');
                }}
                className="cursor-pointer w-[14.4375rem] justify-start items-center gap-[0.5rem] inline-flex"
              >
                <ResponsiveIcon className="fill-secondary rtl:rotate-[180deg]" name={IconNames.logoutNew} baseHeight={20} baseWidth={20} />
                <div className="grow shrink basis-0 text-error text-fs-body font-regular">{translate('common_sign_out')}</div>
              </div>
            </div>
          )}
        </div>
        {!user?.username && (
          <div key={direction} className="mt-[2rem] mb-[5.5rem]">
            <Button
              text={t('action_signin')}
              style="px-24 py-8 w-full text-body font-medium text-white100"
              normalStyle={btnClass}
              onClick={() => {
                window.location.href = `${AppRoutes.authSignIn}${pathname ? '?redirect_url=' + pathname : ''}`;
              }}
            />
          </div>
        )}
      </section>
    </Drawer>
  );
};

export default MobileDrawer;
