import { OTP_TYPES_SCREENS } from '@fe-monorepo/helper';
import { useAccount, useOtp, useTranslate } from '@fe-monorepo/hooks';
import { OtpModel } from '@fe-monorepo/models';
import { RootState } from '@fe-monorepo/store';
import { useEffect, useRef, useState } from 'react';
import { Oval } from 'react-loader-spinner';
import { PatternFormat } from 'react-number-format';
import OtpInput from 'react-otp-input';
import { useSelector } from 'react-redux';

interface IOTP {
  setIsSuccess: (value: boolean) => void;
  setIsOTPSent: (value: boolean) => void;
  setIsOTPPhase: (value: boolean) => void;
  setIsPhoneNumberChange: (value: boolean) => void;
  setMessage: (value: string) => void;
  setIsHome: (value: boolean) => void;
  mobileData: {
    mobile: string;
    mobile_code: string;
  };
  title: string;
}

const PhoneNumberOTP = ({
  title,
  setIsSuccess,
  setIsOTPSent,
  setIsOTPPhase,
  setIsPhoneNumberChange,
  setMessage,
  setIsHome,
  mobileData,
}: IOTP) => {
  const [code, setCode] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState<number>(60);
  const [otpCount, setOtpCount] = useState<number>(0); // Store otpCount in state
  const language = useSelector((state: RootState) => state.app.language);
  const [isshowResend, setIsShowResend] = useState(false);
  const handleCodeOtp = (code: string) => setCode(code);
  const { requestOTP, verifyOTP, deleteOTPError, isOTPLocked, isVerifiedStatus } = useOtp();
  const { translate } = useTranslate();
  const isTimerRef = useRef<any>(null);
  const isOtpLockedRef = useRef<any>(null);

  const { getUserProfile } = useAccount();

  useEffect(() => {
    //reset and request new otp upon page load
    reset();
    requestNewOTP();
  }, []);

  // API request OTP
  const requestNewOTP = async () => {
    if (!isOTPLocked) {
      setIsLoading(true);
      setIsOTPSent(true);
      await requestOTP(
        {
          type: OTP_TYPES_SCREENS.CHANGE_MOBILE,
          mobile: mobileData.mobile_code + mobileData.mobile, // changes 04-22-24: mobile is now a combination of mobile_code and mobile
          mobile_code: mobileData.mobile_code,
        } as OtpModel,
        true,
      )
        .then(response => {
          if (!response.data?.requestOTP.is_successful) {
            if (response.data?.requestOTP.error_code === '2015') {
              const msg = translate('settings.error.mobile_already_used');
              setMessage(msg ? msg : '');
              setIsSuccess(false);
              setIsPhoneNumberChange(false);
              setIsOTPPhase(false);
              setIsOTPSent(false);
              setIsHome(true);
              setIsLoading(false);
            } else {
              close(response.data?.requestOTP.error_msg);
            }
          } else {
            setIsOTPSent(false);
            setIsLoading(false);
          }
        })
        .catch(err => {
          close();
        });
    }
  };

  const verifyCode = async () => {
    await verifyOTP(
      {
        type: OTP_TYPES_SCREENS.CHANGE_MOBILE,
        otp: code,
      } as OtpModel,
      true,
    )
      .then(response => {
        if (response.data?.verifyOTP.is_successful) {
          getUserProfile();
          const msg = translate('settings.phone_number_settings.has_phone_number.phone_number_updated');
          setMessage(msg ? msg : '');
          setIsSuccess(true);
          setIsHome(true);
          setIsOTPPhase(false);
          setIsOTPSent(false);
          setIsPhoneNumberChange(false);
          setIsLoading(false);
        } else if (response.data?.verifyOTP.error_code === '2012') {
          const msg = translate('settings.error.invalid_otp');
          setMessage(msg ? msg : '');
          errorClose();
        } else if (response.data?.verifyOTP.error_code === '429') {
          const msg = translate('form.validation.exceeded_attempts');
          setMessage(msg ? msg : '');
          errorClose();
        } else {
          close(response.data?.verifyOTP.error_msg);
        }
      })
      .catch(err => {
        close();
      });
  };

  const reset = () => {
    if (isOTPLocked) {
      isOtpLockedRef.current = true;
      setTimer(900);
    } else {
      setTimer(60);
    }
    setTimeout(() => {
      isTimerRef.current = true;
    }, 500);
    setCode('');
    setMessage('');
    setIsShowResend(false);
    setIsSuccess(false);
    setIsOTPSent(false);
  };

  const resend = () => {
    reset();
    requestNewOTP();
  };

  const errorClose = () => {
    setIsSuccess(false);
    setIsLoading(false);
    setIsOTPSent(false);
    setOtpCount(prev => prev + 1); // Increment otpCount
    if (otpCount < 2) setCode('');
  };

  const close = (errorMsg?: string) => {
    const msg = errorMsg ?? translate('settings.phone_number_settings.has_phone_number.phone_number_failed_update');
    setMessage(msg ? msg.charAt(0).toUpperCase() + msg.slice(1) : '');
    setIsSuccess(false);
    setIsOTPPhase(false);
    setIsPhoneNumberChange(false);
    setIsHome(true);
    setIsOTPSent(false);
    setIsLoading(false);
  };

  const formatTimer = (timerParam: number) => {
    const zero = language === 'en' ? '0' : (0).toLocaleString('ar-u-nu-arab');
    if (timerParam === 0) {
      return `${zero}${zero}:${zero}${zero}`;
    }
    const rawMinutes = Math.floor(timerParam / 60);
    let minutes = language === 'en' ? rawMinutes : rawMinutes.toLocaleString('ar-u-nu-arab');
    minutes = minutes.toString().padStart(2, zero);
    const rawSeconds = timerParam % 60;
    let seconds = language === 'en' ? rawSeconds : rawSeconds.toLocaleString('ar-u-nu-arab');
    seconds = seconds.toString().padStart(2, zero);

    return `${minutes}:${seconds}`;
  };

  useEffect(() => {
    // check if verified, locked and/or user remaining atttempts
    if (isOTPLocked) {
      isOtpLockedRef.current = true;
      setOtpCount(0);
      setTimer(900); //  900=15 minutes
      isTimerRef.current = true;
    } else if (isVerifiedStatus === true) {
      setIsOTPSent(true);
      setIsSuccess(true);
      setIsOTPPhase(false);
      setIsPhoneNumberChange(false);
      setIsLoading(false);
      setIsShowResend(true);
    }
  }, [isVerifiedStatus, isOTPLocked]);

  useEffect(() => {
    if (code.length === 6) {
      deleteOTPError();
      setIsLoading(true);
      setIsOTPSent(true);
      setMessage('');
      verifyCode();
    }
  }, [code]);

  const handleTimerEnd = () => {
    isTimerRef.current = false;
    setOtpCount(0);
    if (isOtpLockedRef.current) {
      resend();
      isOtpLockedRef.current = false;
    } else {
      setIsShowResend(true);
      setCode('');
    }
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (isTimerRef?.current) {
      intervalId = setInterval(() => {
        setTimer(prevTimer => {
          if (prevTimer === 0) {
            handleTimerEnd();
            clearInterval(intervalId);
          }
          return prevTimer - 1;
        });
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isTimerRef?.current]);

  return (
    <div className={`flex flex-col gap-[1rem] relative ${isLoading ? 'opacity-30' : ''}`}>
      <p className="font-medium text-fs-subtitle text-secondary">{title}</p>
      <p className="font-medium text-fs-body text-secondary">
        {translate('settings.phone_number_settings.has_phone_number.verify_mobile_number')}
      </p>
      <div className="flex flex-col gap-[.25rem] mb-[1.5rem] text-fs-body">
        <p className="text-secondary/70">{translate('settings.phone_number_settings.has_phone_number.weve_sent_otp')}</p>
        <p className="font-medium">
          <PatternFormat
            dir="ltr"
            value={mobileData.mobile_code + mobileData.mobile}
            format="+### ## ### ####"
            className={`${language === 'ar' ? 'relative right-[-3rem]' : ''} text-secondary bg-primary`}
          />
        </p>
      </div>
      <OtpInput
        value={code}
        onChange={handleCodeOtp}
        numInputs={6}
        containerStyle={`w-[20rem] grid gap-8 2K:gap-12 4K:gap-20 8K:gap-44 ${isOTPLocked ? 'disabled' : ''}`}
        renderInput={props => {
          const newProps = { ...props, style: {} };
          return (
            <div className="flex justify-center w-full">
              <input
                {...newProps}
                className={`text-fs-huge
                  font-medium
                  border-secondary
                  ${isOTPLocked ? 'text-red' : 'text-secondary'}
                  text-center w-full
                  p-0 border-b-2 
                  outline-none leading-3
                  bg-transparent
                  focus:border-sunset`}
                disabled={code.length === 6 || isOTPLocked}
              />
            </div>
          );
        }}
        shouldAutoFocus={true}
      />
      <div className="mt-[3.12rem]">
        {isshowResend && !isOTPLocked ? (
          <p className="text-fs-body text-sunset" onClick={resend}>
            {translate('settings.phone_number_settings.has_phone_number.resend_code')}
          </p>
        ) : (
          <span className="not-italic leading-6 text-secondary text-fs-body-large font-regular">
            {translate('common_otp_resend_in')}
            {formatTimer(timer)}
          </span>
        )}
      </div>
      {isLoading && (
        <div className="absolute top-[40%] left-[40%] origin-[-50%_-50%] ml-auto mr-auto z-[9999]">
          <div className="fixed inset-0 modal-overlay bg-primary opacity-30"></div>
          <Oval
            height={80}
            width={80}
            color="#E95F2A"
            secondaryColor="#E95F2A"
            visible={true}
            ariaLabel="oval-loading"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}
    </div>
  );
};

export default PhoneNumberOTP;
