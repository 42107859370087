import { gql } from '@apollo/client';

export const PASSWORD_MUTATION = {
    updatePassword: gql`
        mutation updatePassword($details: AccountInput) {
            updatePassword(details: $details) {
                is_successful
                error_msg
                error_code
            }
        }
    `,
};
