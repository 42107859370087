import { EditFormFunction, ValidationRule } from '@fe-monorepo/forms';

import { ValidationParameters, useInputValidation } from '@fe-monorepo/forms';
import { useTranslation } from 'react-i18next';
import CustomDropdownField from './Components/CustomDropdownField';
import { useEffect, useState } from 'react';
import { authInputFieldStyleObj } from '../../../../../app/pages/AuthenticationScreen/commonStyles';
import { RootState } from '@fe-monorepo/store';
import { useSelector } from 'react-redux';

const CITY_MOCKDATA = [
    {
      city_id: 1,
      name: 'Riyadh'
    },
    {
      city_id: 2,
      name: 'Jeddah'
    },
    {
      city_id: 3,
      name: 'Dammam'
    },
    {
      city_id: 4,
      name: 'Taif'
    },
    {
      city_id: 5,
      name: 'Tabuk'
    },
    {
      city_id: 6,
      name: 'Al Jubail'
    },
    {
      city_id: 7,
      name: 'Al Khobar'
    }
];

type Props =
{
  apiErrorMessage?: string;
  label?: string;
  containerStyle?: string;

  onCityDropdownChange: (e: any) => void;
  resetAPIError?: () => void;
  onClickEnter?: () => void,
}

const CityDropdownField = (props: Props) =>
{
    const { t } = useTranslation();

    const prefs = useSelector((state: RootState) => state.app);
    const [cityObj, setCityObj] = useState<any>({})
    const [validationRules, setValidationRules] = useState<ValidationRule[]>([])
    const [defaultCountry, setDefaultCountry] = useState({})
    const [cityList, setCityList] = useState<any[]>([])
    const cityData = CITY_MOCKDATA;

    // const validationParameters: ValidationParameters =
    // {
    //   inputFieldKey: "cityName",
    //   isOptional: true,
    //   validationRules: validationRules,
    //   apiErrorMessage: props.apiErrorMessage,
    //   resetAPIError: props.resetAPIError,
    //   onChange: props.onCityChange,
    //   dependencies: [cityObj?.dropDownValue?.phone_code_iso, validationRules]
    // }

    // const { errorMessage, setValue } = useInputValidation(validationParameters);

    const search = (searchKey: string) =>
    {
        return cityList?.filter(city =>
        {
          if (!(
                  "name" in city
              ))
          {
            return false;
          }

          return city["name"]?.toLowerCase().includes(searchKey.toLocaleLowerCase());
        })
    }

    const getStringValue = (arrayElement: any) =>
    {
      if (arrayElement)
      {

        return (`${arrayElement["name"]}`).trim();
      }
      else
      {
        return ""
      }
    }

    const getSearchValue = (arrayElement: any) =>
    {
      if (arrayElement)
      {
        return ""+(arrayElement?.name? arrayElement?.name: "").replace(/(\r\n|\n|\r)/gm," ")
      }
      else
      {
        return ""
      }
    }

    useEffect(() =>
    {
      if (cityData && cityData?.length > 0)
      {
        const filterKeys = ["name"];

        const filteredList = cityData?.filter(city =>
        {
            for (let i = 0; i < filterKeys.length; i++) {
              const key = filterKeys[i];

              if (!(key in city))
              {
                return false;
              }
            }

          return true;
        }).map(city =>
        {
          const newCountry =
          {
            id:  city.city_id,
            ...city
          }

          return newCountry
        })


        const defaultCount = "";
        setDefaultCountry(defaultCount)
        setCityList(filteredList)
      }
    }, [cityData])

    useEffect(() =>
    {
      const cityCode = cityObj?.dropDownValue?.name as string;

      if (cityCode !== "")
      {
        props.onCityDropdownChange(cityObj?.dropDownValue);
      }
    }, [cityObj, prefs.language])

    return (
      <CustomDropdownField
          dir="ltr"

          style={{
                ...authInputFieldStyleObj,
                containerStyle: props.containerStyle,
            }}

          label={props.label? props.label:t("formPlaceholder_mobile")}
          inputType="text"
          // errorMessage={errorMessage}
          errorMessage=''
          dropDown=
          {{
            default: defaultCountry,
            list: cityList,

            search: search,
            getSearchValue: getSearchValue,
            getStringValue: getStringValue
          }}

          retrieveValue={setCityObj}
          onClickEnter={props.onClickEnter}
      />
    );
};

export default CityDropdownField;
