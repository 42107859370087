import { useLazyQuery } from '@apollo/client';
import { RETURN_PRODUCT_QUERY } from '@fe-monorepo/data-access';
import { useEffect, useState } from 'react';
import { GetProductsDataParamsModel, GetProductsDataResponse } from './type';


export function useGetProducts() {
    const [isSuccessful, setStatus] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const [getProductGql, { data: productDataResult }] = useLazyQuery<GetProductsDataResponse>(RETURN_PRODUCT_QUERY.omsReturnProdGetProducts, {
        errorPolicy: 'all',
    });

    const getProduct = async (getProductResult: GetProductsDataParamsModel) => {
        const { data } = await getProductGql({
            variables: {
                details: getProductResult,
            },
        });
        return data?.omsReturnProdGetProducts;
    };

    
    useEffect(() => {
        if (productDataResult) {
            if (productDataResult?.omsReturnProdGetProducts?.is_successful) {
                setStatus(productDataResult?.omsReturnProdGetProducts?.is_successful);
            }
            if (productDataResult?.omsReturnProdGetProducts?.error_msg) {
                setErrorMessage(productDataResult?.omsReturnProdGetProducts?.error_msg);
            }
            setStatus(productDataResult?.omsReturnProdGetProducts?.is_successful);
        }
        setIsLoading(false);
    }, [productDataResult]);



    return { getProduct, getProductData: productDataResult?.omsReturnProdGetProducts, isSuccessful, isLoading, errorMessage };
}