import React, { ReactNode, useState } from 'react';

interface OrderSummaryProps {
    items: [string | ReactNode, string | ReactNode][][];
    discountCodeDisclaimer?: JSX.Element | null;
}

const OrderSummary: React.FC<OrderSummaryProps> = ({ items, discountCodeDisclaimer }) => {
    return (
        <div 
            className={`
                items-start self-stretch
                rounded-md border border-secondary/[0.2]
                w-full py-[1.25rem] px-[1rem]
            `}
        >
            <div
                className={
                    `
                    flex flex-col gap-[1.5rem] self-stretch divide-y divide-black10 8K:divide-y-[.1rem]
                    `
                }
            >
                {items?.map(item => (
                    <div className="flex flex-col gap-[1rem] w-full pt-[1.5rem] first:pt-0">
                        {item?.map(
                            ([label, value]) =>
                                value && (
                                    <div className="flex justify-between items-start self-stretch font-regular text-fs-body-small text-secondary">
                                        <span>{label}</span>
                                        <span>{value}</span>
                                    </div>
                                ),
                        )}
                    </div>
                ))}
            </div>
            {discountCodeDisclaimer}
        </div>
    );
};

export default OrderSummary;
