import { useAccount, usePages, useTranslate } from '@fe-monorepo/hooks';
import { UserModel } from '@fe-monorepo/models';
import { RootState, clearCart, clearDeliveryAddress, clearInvoice, setUser, useAppDispatch } from '@fe-monorepo/store';
import Button from '@fe-web/Atoms/Buttons/Button';
import Checkbox from '@fe-web/Molecules/InputFields/Checkbox';
import Modal from '@fe-web/Templates/Modal/Modal';
import convivaHelper from '@fe-web/helpers/convivaHelper';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import { useBitsProvider } from '@fe-web/hooks/useBits/BitsProvider';
import useHeaderState from '@fe-web/hooks/useHeaderState';
import useMobileDetect from '@fe-web/hooks/useMobileDetect';
import { useNotificationProvider } from '@fe-web/hooks/useNotification/useNotificationProvider';
import parse from 'html-react-parser';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const TermsAndConditionsModal = () => {
  const { translate } = useTranslate();
  const { dir } = useHeaderState();
  const themes = useSelector((state: RootState) => state.app.themes);
  const isMobile = useMobileDetect();
  const { getStaticPages, pageData } = usePages();
  const [isAccept, setIsAccept] = useState<boolean>(false);
  const lang = useSelector((state: RootState) => state.app.language);
  const dispatch = useAppDispatch();
  const { acceptUserConsent, logout } = useAccount();
  const { removeDeviceNotification } = useNotificationProvider();
  const { logoutFromQP } = useBitsProvider();

  useEffect(() => {
    getStaticPages('privacy');
  }, []);

  const handleAccept = async () => {
    try {
      const consentResponse = await acceptUserConsent({ page_code: 'privacy' });
      if (consentResponse?.data?.acceptConsent?.is_successful) {
        dispatch(setUser({ privacy_consent: true } as UserModel));
      }
    } catch (error: any) {
      // error
    }
  };

  const handleReject = async () => {
    try {
      removeDeviceNotification();
      dispatch(clearCart());
      dispatch(clearInvoice());
      dispatch(clearDeliveryAddress());
      logout();
      mixpanelHelper.reset();
      convivaHelper.setIdentity('GUEST');
      logoutFromQP();
      localStorage.removeItem('selectedTimezone');
      localStorage.removeItem('selectedCurrency');
      localStorage.removeItem('selectedCountry');
    } catch (error: any) {
      // error
    }
  };

  const content = lang === 'ar' ? pageData?.content_ar : pageData?.content_en;

  const StyledStaticPage = () => {
    const html =
      `<html>
            <head>
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
            </head>
            <style type="text/css">
                h2 {
                    margin-top: 2.625rem; 
                    margin-bottom: 1rem;    
                    font-size: 1rem;
                    font-weight: 800;
                }
                ul {
                    list-style-type: square;
                    list-style-position: inside;
                    margin-top: 1rem;
                    margin-bottom: 1rem;
                    padding-left: 1rem;
                }
                ol {
                    list-style-type: number;
                    list-style-position: inside;
                    margin-top: 1rem;
                    margin-bottom: 1rem;
                    padding-left: 1rem;
                }
            </style>
            <body className="h-full text-justify font-regular text-fs-body text-secondary/70" dir=` +
      dir +
      `>` +
      content +
      `</body>
        </html>`;
    return html;
  };

  return (
    <Modal open={true} backdropClassName="backdrop-blur w-[100%] h-[100%] p-[1.25rem]">
      <div
        className={`relative flex flex-col items-center border-[1px] border-solid border-white10
          w-[100%] md:w-[49.5rem] rounded-[0.5rem]
          bg-primary self-center p-[1.25rem] md:p-[3rem]`}
      >
        <div className="flex flex-col w-full gap-[1.5rem]">
          <h4 className="font-medium text-secondary text-fs-subtitle">
            {translate('terms_and_conditions.terms_and_conditions_and_privacy_policy')}
          </h4>
          <div className="max-h-[20rem] md:max-h-[30rem] overflow-scroll">
            <div className={`flex flex-col text-justify text-secondary/70 mb-[1rem] gap-[.5rem]`}>
              {content ? parse(StyledStaticPage()) : ''}
            </div>
            <div className="flex gap-x-[0.75rem] items-center">
              <div className="flex w-[1.5rem] h-[1.5rem] items-center justify-center">
                <Checkbox
                  isChecked={isAccept}
                  onClick={() => setIsAccept(!isAccept)}
                  classNames="w-[1.125rem] h-[1.125rem] items-center justify-center"
                  width={1.125}
                  height={1.125}
                />
              </div>
              <p className="text-fs-body-small font-regular text-secondary/70">
                {translate(`terms_and_conditions.yes_i_understand_and_agree`)}
              </p>
            </div>
          </div>
          <div className="flex flex-col SD:flex-row w-full justify-center items-center gap-y-[1rem] gap-x-[1.25rem]">
            <Button
              text={translate('terms_and_conditions.accept_and_continue')}
              style={`text-base not-italic max-h-[2.5rem]  font-medium
                      ${isMobile ? 'px-[1rem]' : 'px-[1.5rem]'} w-full py-[0.5rem] gap-[0.5rem] z-1
                  text-white border-sunset border-[0.063rem] mb-[0px]
                  items-end
                  rounded-[0.188rem] rounded-[0.188rem]`}
              normalStyle={`
                  ${dir === 'ltr' ? `after:bg-sunset before:bg-primary` : `before:bg-sunset after:bg-primary`}
                  hover:border-sunset hover:text-sunset`}
              disabledStyle={`${themes === 'dark' ? `bg-[#2E2D30] border-[#2E2D30]` : `bg-secondary/[10%] border-secondary/[10%]`} `}
              disabled={!isAccept}
              onClick={() => {
                handleAccept();
              }}
            />
            <Button
              text={translate('terms_and_conditions.reject_and_sign_out')}
              style={`text-base not-italic max-h-[2.5rem] border-[0.063rem] border-sunset font-medium
                      ${isMobile ? 'px-[1rem]' : 'px-[1.5rem]'} w-full py-[0.5rem] gap-[0.5rem] z-1
                      text-sunset mb-[0px]
                       items-end
                      rounded-[0.188rem] 
                      rounded-[0.188rem]`}
              normalStyle={`
                      ${dir === 'ltr' ? `after:bg-primary before:bg-sunset` : `before:bg-primary after:bg-sunset`}
                      hover:border-sunset hover:text-primary`}
              onClick={() => {
                handleReject();
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

const TermsAndConditions = () => {
  const user = useSelector((state: RootState) => state.user.userContext);
  const isOpen = Boolean(user && user.token && !user.privacy_consent);

  if (isOpen) return <TermsAndConditionsModal />;
  return null;
};

export default TermsAndConditions;
