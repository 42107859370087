import { IconNames } from '@fe-monorepo/helper';
import useGetCurrentBreakPoint, { BreakPoints } from '@fe-web/hooks/useGetCurrentBreakPoint/useGetCurrentBreakPoint';
import { forwardRef, useEffect, useState } from 'react';

import Icon from './Icon';

export interface IAppProps {
  srAltText?: string;
  name: IconNames;
  className?: string;
  iconClasses?: string;
  baseWidth: number;
  baseHeight: number;

  mobileSize?: {
    width: number;
    height: number;
  };

  XGASize?: {
    width: number;
    height: number;
  };

  fill?: string;
  onClick?: () => void;
  onTouchStart?: (e: React.TouchEvent<HTMLDivElement>) => void;
}

type SizesObj = {
  [B in BreakPoints]: {
    height: number;
    width: number;
  };
};

const ResponsiveIcon = forwardRef<HTMLDivElement, IAppProps>((props, ref) => {
  const { baseHeight: height, baseWidth: width, mobileSize, XGASize, srAltText } = props;

  const { currentBreakPoint } = useGetCurrentBreakPoint();

  const [sizes, setSizes] = useState<SizesObj>({
    mobile: mobileSize ?? {
      height: height,
      width: width,
    },
    XGA: {
      height: height ?? 10,
      width: width ?? 10,
    },
    normal: {
      height: height ?? 10,
      width: width ?? 10,
    },
    Ll: {
      height: height ?? 10,
      width: width ?? 10,
    },
    FHD: {
      height: height ?? 10,
      width: width ?? 10,
    },

    '2k': {
      height: height ?? 10,
      width: width ?? 10,
    },

    '4k': {
      height: height ?? 10,
      width: width ?? 10,
    },

    '8k': {
      height: height ?? 10,
      width: width ?? 10,
    },
  });

  useEffect(() => {
    if (props?.baseHeight && props?.baseWidth) {
      const heightPercentage = props.baseHeight / 1440;
      const widthPercentage = props.baseWidth / 1440;

      const normalObj = {
        height: props.baseHeight,
        width: props.baseWidth,
      };

      const sizes = {
        mobile: mobileSize ? mobileSize : XGASize ?? normalObj,
        XGA: XGASize ?? normalObj,
        normal: normalObj,
        Ll: normalObj,
        FHD: normalObj,

        '2k': {
          height: heightPercentage * 2560,
          width: widthPercentage * 2560,
        },

        '4k': {
          height: heightPercentage * 3840,
          width: widthPercentage * 3840,
        },

        '8k': {
          height: heightPercentage * 7680,
          width: widthPercentage * 7680,
        },
      };

      setSizes(sizes);
    }
  }, [props?.baseHeight, props?.baseWidth, mobileSize]);

  return (
    <>
      <Icon
        ref={ref}
        className={props.className}
        name={props.name}
        width={sizes[currentBreakPoint].width}
        height={sizes[currentBreakPoint].height}
        onClick={props?.onClick}
        iconClasses={props?.iconClasses}
        onTouchStart={props?.onTouchStart}
        srAltText={srAltText}
      />
    </>
  );
});

export default ResponsiveIcon;
