import quickplay from 'quickplay-shorts-js';
import { deviceDetect } from 'react-device-detect';

import { EVENTS } from '../constants';

const userAgent = navigator.userAgent;

async function getUserFollowing(userId: string) {
  try {
    const userModule = new quickplay.QuickPlayUserModule();
    const {
      status,
      data: { result: followingData },
    } = await userModule.getUserFollowersStatus(userId);
    if (status === 200) {
      return followingData;
    }
  } catch (error) {
    console.error(error);
  }
}

async function getCategoriesData() {
  try {
    const feedModule = new quickplay.QuickPlayFeedModule();
    const {
      status,
      data: { result: categories },
    } = await feedModule.getCategoryList({
      search: '',
      limit: 100,
      offset: 1,
    });
    if (status === 200) {
      return categories;
    }
  } catch (error) {
    console.error(error);
  }
}

function getDeviceId() {
  const deviceId = localStorage.getItem('deviceId');
  if (!deviceId) {
    const uniqueID = crypto.randomUUID();
    localStorage.setItem('deviceId', uniqueID);
    return uniqueID;
  } else {
    return deviceId;
  }
}

function getDeviceData() {
  const deviceDetectData = deviceDetect(userAgent);
  const { isBrowser, browserName } = deviceDetectData ?? {};
  const deviceId = getDeviceId();

  return {
    device_ID: deviceId,
    platform_name: isBrowser ? "web" : 'N/A',
  };
}

function getUserData() {
  const userData = JSON.parse(localStorage.getItem('userData')!);
  const { fullName, userId, email } = userData ?? {};

  return {
    user_email: email,
    user_ID: userId,
    user_name: fullName,
  };
}

function getPageData() {
  const getTabName = () => {
    const pathname = window.location.pathname;
    const tabs: Record<string, string> = {
      '/bits-play/bits': 'Bits',
      '/bits-play/content': 'Sub Feed'
    };

    for (const key in tabs) {
      if (pathname.startsWith(key)) {
        return tabs[key];
      }
    }

    return 'N/A';
  };

  const getPageName = () => {
    const pathname = window.location.pathname;
    const pages: Record<string, string> = {
      '/bits-play/bits': 'Bits',
      '/bits-play/content': 'Sub Feed'
    };

    for (const key in pages) {
      if (pathname.startsWith(key)) {
        return pages[key];
      }
    }

    return 'N/A';
  };

  return {
    tab_name: getTabName(),
    page_name: getPageName(),
  };
}

async function getEventPayload(data: any) {
  const { event, content, button_type } = data ?? {};
  const { videoId: contentId, user: creator, hashtags, contentType, categoryId } = content ?? {};
  const { userId: creatorUserId, userName: creatorUserName, followersCount } = creator ?? {};

  let payload = {
    eventName: event,
    ...getDeviceData(),
    ...getUserData(),
    ...getPageData(),
    ...(contentId && { content_id: contentId }),
  };

  const categories = await getCategoriesData();
  const categoryData = categories.find((category: any) => category.categoryId === categoryId);
  const { categoryName = '' } = categoryData ?? {};

  switch (event) {
    case EVENTS.VIEW_IMPRESSION:
      payload = {
        ...payload,
        content_type: contentType,
        creator_userid: creatorUserId,
        creator_username: creatorUserName,
        hashtag: hashtags,
      };
      break;

    case EVENTS.CONTENT_SWIPE:
      payload = {
        ...payload,
      };
      break;

    case EVENTS.CONTENT_PLAY:
      payload = {
        ...payload,
      };
      break;

    case EVENTS.CONTENT_PAUSE:
      payload = {
        ...payload,
      };
      break;

    case EVENTS.CONTENT_RESUME:
      payload = {
        ...payload,
      };
      break;

    case EVENTS.CONTENT_REPLAY:
      payload = {
        ...payload,
      };
      break;

    case EVENTS.CONTENT_STOP_PLAY:
      payload = {
        ...payload,
        content_played_duration: content.timeElapsed,
      };
      break;

    case EVENTS.CONTENT_LIKE:
    case EVENTS.CONTENT_UNLIKE:
      // eslint-disable-next-line no-case-declarations
      const { isFollowing: followingCount } = await getUserFollowing(creatorUserId);
      payload = {
        ...payload,
        element: "button",
        button_type: event === EVENTS.CONTENT_LIKE ? "like" : "unlike",
        content_type: contentType,
        creator_userid: creatorUserId,
        creator_username: creatorUserName,
        failure_reason: 'N/A',
        hashtag: hashtags,
        success: true,
        user_follower_count: followersCount,
        user_following_count: followingCount,
        user_isFollow: content.isFollow,
      };
      break;

    case EVENTS.CONTENT_SHARE:
      payload = {
        ...payload,
        content_type: contentType,
        content_genre: categoryName,
        creator_userid: creatorUserId,
        creator_username: creatorUserName,
        hashtag: hashtags,
        button_type: button_type,
        element: "button",
        success: true,
        failure_reason: "none",
      };
      break;

    case EVENTS.THUMBNAIL_CLICK:
      payload = {
        ...payload,
        content_type: contentType,
        content_genre: categoryName,
        vertical_index: content.verticalIndex,
        horizontal_index: content.horizontalIndex,
      };
      break;

    case EVENTS.USER_FOLLOW:
    case EVENTS.USER_UNFOLLOW:
      // eslint-disable-next-line no-case-declarations
      const { isFollowing: userfollowingCount } = await getUserFollowing(creatorUserId);
      payload = {
        ...payload,
        success: true,
        element: "button",
        button_type: event === EVENTS.USER_FOLLOW ? "follow" : "unfollow",
        user_follower_count: followersCount,
        user_following_count: userfollowingCount,
        user_isFollow: content.isFollow,
        failure_reason: "N/A",
      };
      break;

    case EVENTS.LOGIN_CLICK:
      payload = {
        ...payload,
        user_follower_count: followersCount,
        user_following_count: followingCount,
        user_isFollow: content.isFollow,
      };
      break;

    default:
      break;
  }

  return payload;
}

export default function useAnalytics() {

  const trackEvent = async (data: any) => {
    try {

      const eventData = await getEventPayload(data);
      const activityTimelineModule = new quickplay.QuickPlayActivityTimeline();
      activityTimelineModule.activityTimelineAnalytics(eventData).then(res => {

      }).catch(err => {

      })
    } catch (error) {
      console.error(error);
    }
  };

  return { trackEvent };
}
