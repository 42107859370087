import { ShopProductsModel } from "@fe-monorepo/models";

import CurrencyText from "@fe-web/Atoms/Text/CurrencyText";

export type ProductPrice =
{
  // This is what's shown
  highest_selling_price: number,
  lowest_selling_price: number,

  // Show this by default, if selling is available, show this is crossed
  highest_actual_cost: number,
  lowest_actual_cost: number,
}

interface Props
{
  dir: "ltr" | "rtl"
  price: ShopProductsModel,
  classNames?: string,
}

const PriceInfo = (props: Props) =>
{
    const { price , classNames} = props;

    const discountExists = price?.lowest_actual_cost && price?.lowest_actual_cost > price?.lowest_selling_price;

    return (
      <p
        className=
        {`
          flex
          whitespace-nowrap
          gap-12 2K:gap-[21.33px] 4K:gap-32 8K:gap-64

          ${classNames} 2K:text-title 4K:text-fourKSubtitle 8K:text-eightKSubtitle
        `}
      >
        <CurrencyText 
          className={`${discountExists? `text-red`:`text-secondary`} font-medium`}
          currency={price?.lowest_selling_price}
        />

        <CurrencyText 
          className={`${discountExists? ` `:`hidden `} line-through font-regular text-secondary/50`}
          currency={price?.lowest_actual_cost}
        />
      </p>
    )
}


export default PriceInfo;
