import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_INVOICE_INFO_QUERY, INVOICE_MUTATION, ORDER_MUTATION } from '@fe-monorepo/data-access';
import {
  AddProductInput,
  ClearInvoiceInput,
  InvoiceInfoModel,
  InvoiceMutationResponse,
  InvoiceProduct,
  OrderMutationResponse,
  RemoveProductInput,
  SelectProductsInput,
} from '@fe-monorepo/models';
import {
  AppDispatch,
  RootState,
  clearCart,
  clearInvoice,
  decreaseQuanity,
  increaseQuantity,
  removeCartItem,
  setCart,
  setInvoice,
  updateCartItemStock,
} from '@fe-monorepo/store';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//import { useCheckProductAvailability } from './useCheckProductAvailability';
import { useAppState } from '../useAppState/useAppState';
import { useUserProfile } from '../useUserProfile';
import { useWallet } from '../useWallet/useWallet';
import { ProductAvailabilityResponse, useCheckProductAvailability } from './useCheckProductAvailability';
import { useOrder } from './useOrder';

interface GetInvoiceInfoResponse {
  getInvoiceInfo: {
    is_successful: boolean;
    error_code: string;
    error_msg: string;
    data: InvoiceInfoModel;
  };
}

interface GetInvoiceInfoInput {
  details: { invoice_number: string };
}

type ExtendedProductType = InvoiceProduct & Partial<ProductAvailabilityResponse>;
export const useProductCart = () => {
  const dispatch = useDispatch<AppDispatch>();

  // ProductAvailabilityResponse
  const [unAvailableProducts, setNotAvailableProducts] = useState<ExtendedProductType[]>();

  // Hooks
  const { userWallet, removeWalletBalance } = useWallet();
  const { isLoggedIn } = useUserProfile();
  const { checkProductAvailability } = useCheckProductAvailability();
  const { cancelOrder } = useOrder();

  // Store
  const invoiceInfo = useSelector((state: RootState) => state.cart.invoice);
  const itemsInCart = useSelector((state: RootState) => state.cart.items);
  const lmd_code = useSelector((state: RootState) => state.app.lmdCode);
  const appState = useSelector((state: RootState) => state.app);

  const { isGuestLoginUser } = appState;

  const { changeLoadingState } = useAppState();

  // GQL
  const [createInvoiceGql] = useMutation<InvoiceMutationResponse>(INVOICE_MUTATION.createInvoice, {
    errorPolicy: 'all',
  });
  const [clearInvoiceGql] = useMutation<InvoiceMutationResponse>(INVOICE_MUTATION.clearInvoice, {
    errorPolicy: 'all',
  });

  const [getInvoiceInfo, { data: invoiceInfoResponse, error: invoiceInfoError }] = useLazyQuery<
    GetInvoiceInfoResponse,
    GetInvoiceInfoInput
  >(GET_INVOICE_INFO_QUERY.getInvoiceInfo, {
    errorPolicy: 'all',
  });

  const [addProductGql] = useMutation<OrderMutationResponse>(ORDER_MUTATION.addProduct, {
    errorPolicy: 'all',
  });
  const [removeProductGql] = useMutation<OrderMutationResponse>(ORDER_MUTATION.removeProduct, {
    errorPolicy: 'all',
  });
  const [selectProductsGql] = useMutation<OrderMutationResponse>(ORDER_MUTATION.selectProducts, {
    errorPolicy: 'all',
  });

  // updated
  const createInvoice = async (lmdCode: string) => {
    const { data } = await createInvoiceGql({ variables: { details: { lmd_code: lmdCode } } });

    if (data?.createInvoice?.data.invoice_number) {
      fetchInvoiceInfo(data?.createInvoice?.data.invoice_number);
    }
  };

  const fetchInvoiceInfo = async (invoiceNumber: string) => {
    //  changeLoadingState(true);
    const { data } = await getInvoiceInfo({
      variables: {
        details: { invoice_number: invoiceNumber },
      },
    })
    dispatch(setInvoice(data?.getInvoiceInfo?.data?.info));
    dispatch(setCart(data?.getInvoiceInfo?.data?.info.products));
    //  changeLoadingState(false);
    return data?.getInvoiceInfo?.data?.info
  };

  const clearInvoiceData = async (clearInvoiceInput: ClearInvoiceInput) => {
    changeLoadingState(true);
    const { data } = await clearInvoiceGql({ variables: { details: clearInvoiceInput } });
    // createInvoice();
    if (data?.clearInvoice?.is_successful) {
      dispatch(clearInvoice());
    }
    changeLoadingState(false);
    return data?.clearInvoice;
  };

  const addProduct = async (addProductInput: AddProductInput) => {
    changeLoadingState(true);
    const { data } = await addProductGql({ variables: { details: addProductInput } });
    fetchInvoiceInfo(addProductInput?.invoice_number);
    changeLoadingState(false);
    return data?.addProduct;
  };

  const removeProduct = async (removeProductInput: RemoveProductInput) => {
    changeLoadingState(true);
    const { data } = await removeProductGql({ variables: { details: removeProductInput } });
    fetchInvoiceInfo(removeProductInput.invoice_number);
    changeLoadingState(false);
    return data?.removeProduct;
  };

  const selectProducts = async (selectProductsInput: SelectProductsInput) => {
    const { data } = await selectProductsGql({ variables: { details: selectProductsInput } });
    return data?.selectProducts;
  };

  const addToCart = (item: AddProductInput) => {
    addProduct(item);
  };

  const dispatchQty = (invoiceProduct: InvoiceProduct) => {
    // dispatch(updateInvoice(invoiceProduct));
  };

  const removeToCart = async (productCode: string, variantId: number, qty: number) => {
    // isGuestLoginUser added in condition, to fix bug (guest user delete item in cart, but after pull to refresh appears again)
    if (isLoggedIn || isGuestLoginUser) {
      const invoiceNumber = invoiceInfo?.invoice_number;
      if (typeof invoiceNumber !== 'string') return;
      await removeProduct({
        invoice_number: invoiceNumber,
        variant_id: variantId,
        product_code: productCode,
        qty,
      });
      dispatch(removeCartItem({ product_code: productCode, variant_id: variantId }));
    } else {
      dispatch(removeCartItem({ product_code: productCode, variant_id: variantId }));
    }
  };

  const clearItems = () => {
    //  changeLoadingState(true);
    const invoiceNumber = invoiceInfo?.invoice_number;
    const lmdCode = invoiceInfo?.lmd_code;
    dispatch(clearCart());
    if (invoiceNumber) {
      clearInvoiceData({ invoice_number: invoiceNumber, lmd_code: lmdCode }).then(response => {
        fetchInvoiceInfo(invoiceNumber).then(() => {
          cancelOrder({
            invoice_number: invoiceNumber,
          });
          // changeLoadingState(false);
        });
      });
    } else {
      //  changeLoadingState(false);
    }
  };

  const clearCartItems = () => {
    dispatch(clearCart());
  };

  const increase = (productCode: string, variantId: number, qty?: number, showToastMessage?: (message: string, type: 'success' | 'error') => void) => {
    if (isLoggedIn || invoiceInfo?.invoice_number) {
      const payload = {
        invoice_number: invoiceInfo ? invoiceInfo.invoice_number : '',
        variant_id: variantId,
        product_code: productCode,
        qty: 1,
      };

      checkProductAvailability({
        product_code: productCode,
        variant_id: String(variantId),
        lmd_code: lmd_code.lmd_code,
        qty: qty || 1,
      }).then(response => {
        if (response?.isProductAvailable.is_successful) {
          if (invoiceInfo?.is_checkout === 1 && invoiceInfo?.status_code === 'pending') {
            cancelOrder({
              invoice_number: invoiceInfo?.invoice_number,
            }).then(response => {
              if (response) {
                addProduct(payload);
              }
            });
          } else {
            addProduct(payload);
          }
        } else {
          dispatch(updateCartItemStock({ product_code: productCode, in_stock: 0, variant_id: variantId }));
          if (showToastMessage && response?.isProductAvailable.error_msg) {
            showToastMessage(response?.isProductAvailable?.error_msg, 'error')
          }
        }
      });
    } else {
      const cartItem = itemsInCart.find(item => item.product_code === productCode);

      if (cartItem) {
        const itemQty = cartItem.qty + 1;
        if (lmd_code.lmd_code !== '') {
          checkProductAvailability({
            product_code: productCode,
            variant_id: cartItem.variant_id.toString(),
            lmd_code: lmd_code.lmd_code,
            qty: itemQty,
          }).then(response => {
            if (response?.isProductAvailable.is_successful) {
              dispatch(increaseQuantity({ product_code: productCode, variant_id: variantId }));
            } else {
              dispatch(updateCartItemStock({ product_code: productCode, in_stock: 0, variant_id: variantId }));
              if (showToastMessage && response?.isProductAvailable.error_msg) {
                showToastMessage(response?.isProductAvailable?.error_msg, 'error')
              }
            }
          });
        }
      }
    }
  };

  const decrease = (productCode: string, variantId: number, qty?: number) => {
    if (isLoggedIn || invoiceInfo?.invoice_number) {
      const payload = {
        invoice_number: invoiceInfo ? invoiceInfo?.invoice_number : '',
        variant_id: variantId,
        product_code: productCode,
        qty: qty || 1,
      };
      if (invoiceInfo?.is_checkout === 1 && invoiceInfo?.status_code === 'pending') {
        cancelOrder({
          invoice_number: invoiceInfo?.invoice_number,
        }).then(response => {
          if (response) {
            removeProduct(payload);
          }
        });
      } else {
        removeProduct(payload);
      }
    } else {
      const cartItem = itemsInCart.find(item => item.product_code === productCode);
      if (cartItem) {
        if (cartItem.qty > 1) {
          const qty = cartItem.qty - 1;
          checkProductAvailability({
            product_code: productCode,
            variant_id: cartItem.variant_id.toString(),
            lmd_code: lmd_code.lmd_code,
            qty: qty,
          }).then(response => {
            if (response?.isProductAvailable.is_successful) {
              dispatch(decreaseQuanity({ product_code: productCode, variant_id: variantId }));
              dispatch(updateCartItemStock({ product_code: productCode, in_stock: 1, variant_id: variantId }));
            } else {
              dispatch(updateCartItemStock({ product_code: productCode, in_stock: 0, variant_id: variantId }));
            }
          });
        } else {
          dispatch(removeCartItem({ product_code: productCode, variant_id: variantId }));
        }
      }
    }
  };

  const remove = async (productCode: string, variantId: number, qty: number) => {
    const invoiceNumber = invoiceInfo?.invoice_number;
    if (!invoiceNumber) return;
    await removeProduct({
      invoice_number: invoiceNumber,
      variant_id: variantId,
      product_code: productCode,
      qty,
    });
  };

  const selectCartProducts = async (orderProductIds: number[]) => {
    const invoiceNumber = invoiceInfo?.invoice_number;
    if (typeof invoiceNumber !== 'string') return;

    if (invoiceInfo?.is_checkout === 1 && invoiceInfo?.status_code === 'pending') {
      const cancelResponse = await cancelOrder({
        invoice_number: invoiceInfo?.invoice_number,
      });

      if (cancelResponse?.is_successful) {
        return selectProducts({ invoice_number: invoiceNumber, order_product_ids: orderProductIds });
      } else {
        return null; // Or handle the failure as needed
      }
    } else {
      return selectProducts({ invoice_number: invoiceNumber, order_product_ids: orderProductIds });
    }
  };

  const checkAllProductsAvailability = (counter: number, itemsInCart: InvoiceProduct[], notAvailableProducts: InvoiceProduct[]) => {
    const item = itemsInCart[counter];

    if (counter >= itemsInCart.length) {
      setNotAvailableProducts(notAvailableProducts);
      return;
    } else {
      checkProductAvailability({
        product_code: item.product_code,
        variant_id: item.variant_id.toString(),
        lmd_code: lmd_code.lmd_code,
        qty: item.qty,
      })
        .then(response => {
          if (!response?.isProductAvailable.is_successful) {
            dispatch(updateCartItemStock({ product_code: item.product_code, in_stock: 0, variant_id: item.variant_id }));
            notAvailableProducts.push({ ...item, ...response });
          } else {
            dispatch(updateCartItemStock({ product_code: item.product_code, in_stock: 1, variant_id: item.variant_id }));
          }
          checkAllProductsAvailability(counter + 1, itemsInCart, notAvailableProducts);
        })
        .catch((e) => {
          setNotAvailableProducts(notAvailableProducts);
          return;
        });
    }
  };

  const getTotalProductQty = () => {
    const totalProductQty =
      isLoggedIn || invoiceInfo?.invoice_number
        ? invoiceInfo?.products
          ? invoiceInfo?.products.reduce((count, item) => {
            return item?.qty + count;
          }, 0)
          : 0
        : itemsInCart
          ? itemsInCart.reduce((count, item) => {
            return item?.qty + count;
          }, 0)
          : 0;

    return totalProductQty;
  };

  return {
    getTotalProductQty,
    addToCart,
    removeToCart,
    selectCartProducts,
    clearItems,
    clearCartItems,
    increase,
    decrease,
    remove,
    dispatchQty,
    createInvoice,
    addProduct,
    invoiceInfo,
    fetchInvoiceInfo,
    invoiceInfoResponse,
    checkAllProductsAvailability,
    unAvailableProducts,
  };
};
