import { useTranslate, useUserProfile } from '@fe-monorepo/hooks';
import { RootState, clearCart, clearInvoice, useAppDispatch } from '@fe-monorepo/store';
import Button from '@fe-web/Atoms/Buttons/ButtonFooter';
import Image from '@fe-web/Atoms/Media/Image';
import useHeaderState from '@fe-web/hooks/useHeaderState';
import usePageLayout from '@fe-web/hooks/usePageLayout';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { AppRoutes } from '../../../../../app.routes.enum';

//import { trackSuccessfulPurchase } from 'apps/fe-web/helper/webAnalytics';

interface ConfirmationProps {
  header: string | null;
  message: string | null;
  button: string | null;
  img: string;
  order_id?: string;
  isReturn?: boolean;
  showId?: boolean;
  showSign?: boolean;
  imageClass?: string;
}

const Confirmation = (props: ConfirmationProps) => {
  const { translate } = useTranslate();
  const pageLayout = usePageLayout();
  const isHeaderDark = useSelector((state: RootState) => state.app.headerColorChange);
  const { dir } = useHeaderState();
  const navigate = useNavigate();
  const { isLoggedIn } = useUserProfile();
  const location = useLocation().pathname;
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const handleButtonEvent = () => {
    if (isLoggedIn) {
      navigate(AppRoutes.myorders);
    } else {
      dispatch(clearCart());
      dispatch(clearInvoice());
      navigate(AppRoutes.authSignUp);
    }
  };

  return (
    <div className="col-span-4 justify-self-center ">
      <div
        className="
            SD:max-w-[22.1875rem]
            text-center flex flex-col items-center
            gap-[2rem]"
      >
        {/* <Lottie
                    animationData={props.img}
                    loop={true}
                    style={{ height: '8.125rem', width: '7.688rem', flexShrink: 0, marginLeft: '1rem' }}
                /> */}
        <Image
          img={props.img}
          divStyle={`${props.imageClass ? props.imageClass : 'w-[6.78rem] h-[8.86rem]'}`}
          imgStyle="object-contain transition transition-all duration-300 w-full h-full"
        />
        <div>
          <h2 className="text-fs-body-large font-medium text-secondary text-center mb-[0.625rem]">{props.header}</h2>
          <p className="text-secondary/50 font-regular text-fs-body-small">{props.message}</p>
        </div>
        {props.showId && (
          <div className="flex flex-col gap-y-8">
            <p className="text-fs-caption font-regular text-secondary/[0.7]">{translate('shop.cart.order_id')}</p>
            <p className="text-fs-body-small font-regular text-secondary">{props.order_id}</p>
          </div>
        )}
        <div className="flex flex-col gap-y-12 w-full items-center">
          <Button
            text={props.button}
            normalStyle={pageLayout.btnClass}
            disabled={false}
            style={`text-base not-italic font-medium text-fs-body max-h-[2.75rem]
                    min-w-[8.6875rem] px-[1.5rem] py-[0.5rem] w-full SD:w-fit gap-[0.5rem] z-1
                    text-white100 border-[0.0625rem] rounded-sm
                    ${
                      isHeaderDark
                        ? dir === 'ltr'
                          ? `after:bg-sunset before:bg-white100`
                          : `before:bg-sunset after:bg-white100`
                        : `${dir === 'ltr' ? `after:bg-sunset before:bg-white100` : `before:bg-sunset after:bg-white100`}
                        hover:border-[1px] hover:border-sunset`
                    }
                    hover:text-sunsetText rounded-sm`}
            onClick={handleButtonEvent}
          />
          {!isLoggedIn && props.showSign && (
            <p className={`text-fs-body font-regular text-secondary/[0.7]`}>
              {translate('common_have_an_account')}
              <span
                className={`text-fs-body font-regular text-sunset hover:underline pl-4 hover:cursor-pointer`}
                onClick={() => {
                  dispatch(clearCart());
                  dispatch(clearInvoice());
                  navigate(`${AppRoutes.authSignIn}${pathname ? `?redirect_url=${pathname}` : ''}`);
                }}
              >
                {translate('common_signin')}
              </span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
