import { IMAGES } from '@fe-monorepo/assets';
import { useForm } from '@fe-monorepo/forms';
import { IMAGE_ALT_LABELS, TxKeyPath } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import Image from '@fe-web/Atoms/Media/Image';
import InputField from '@fe-web/Molecules/InputFields/InputField/InputField';
import PhoneNumberField from '@fe-web/Molecules/InputFields/PhoneNumberField';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { authInputFieldStyleObj } from '../../ShopAddAddress/commonStyles';

interface paymentMethodProps {
  paymentMethod?: string;
  updatePaymentMethod?: (paymentOption: string) => void;
  setPaymentMobileNumber?: (mobile: string) => void;
  setPaymentEmail?: (email: string) => void;
  paymentEmail?: string;
  setPaymentMobileCodeISO?: (code: string) => void;
  isMobile?: boolean;
  hasDigitalProducts?: boolean;
  paymentMethodDisabled?: boolean;
  isApplePaySupported?: boolean;
}

const PaymentMethod = ({
  updatePaymentMethod,
  paymentMethod,
  setPaymentEmail,
  setPaymentMobileNumber,
  paymentEmail,
  setPaymentMobileCodeISO,
  isMobile,
  hasDigitalProducts,
  paymentMethodDisabled,
  isApplePaySupported,
}: paymentMethodProps) => {
  const [phoneNumberIsoCode, setPhoneNumberIsoCode] = useState<string>('');
  const [isPhoneNumberTouched, setIsPhoneNumberTouched] = useState(false);
  const { form, isFormValid, editForm } = useForm();
  const [phoneNumberError, setPhoneNumberError] = useState<string>('');
  const [isEmailTouched, setIsEmailTouched] = useState(false);
  const [emailError, setEmailError] = useState<string>('');
  const { translate } = useTranslate();
  const show_apple_pay = useSelector((state: RootState) => state.app.show_apple_pay);

  const setPaymentMethod = (paymentoption: string) => {
    // dispatch(setPaymentOption(paymentoption));
    // updatePaymentMethod({
    //     invoice_number:  invoice_number ? invoice_number : '',
    //     code: paymentoption
    // })
    if (updatePaymentMethod) {
      if (setPaymentMobileNumber) setPaymentMobileNumber('');
      if (setPaymentEmail) setPaymentEmail('');
      updatePaymentMethod(paymentoption);
    }
  };

  useEffect(() => {
    if (setPaymentMobileNumber) {
      setPaymentMobileNumber(form.phoneNumber?.value);
    }
  }, [form.phoneNumber?.value]);

  useEffect(() => {
    if (setPaymentMobileCodeISO && form.phone_code_iso?.value) {
      setPaymentMobileCodeISO(form.phone_code_iso.value);
    }
  }, [form.phone_code_iso?.value]);

  const paymentFields = (payment: string) => {
    return (
      <div className={`flex ${isMobile && 'flex-col'} justify-center gap-[2rem] SD:gap-[4rem] self-stretch text-black/50 mt-[1.5rem]`}>
        {payment === 'tabby' && (
          <InputField
            label={translate('shop.cart.shop_add_address_modal_page.placeholder_email') ?? ''}
            isSecret={false}
            hasFocused={false}
            errorMessage={isEmailTouched ? emailError : ''}
            isUserAllowedToType={(value: string) => true}
            retrieveValue={(value: any) => setPaymentEmail && setPaymentEmail(value)}
            retrieveFocus={(value: boolean) => {
              if (value && !isEmailTouched) {
                setIsEmailTouched(true);
              }
            }}
            style={authInputFieldStyleObj}
            existingValue={paymentEmail}
          />
        )}
        <PhoneNumberField
          label={`${translate(`settings.phone_number`)}`}
          apiErrorMessage={isPhoneNumberTouched ? phoneNumberError : ''}
          onCountryCode={e => {
            if (e?.country_code_iso_2) {
              editForm('country_code_iso', { value: e.country_code_iso_2, isValid: true });
              setPhoneNumberIsoCode(e.country_code_iso_2);
            }
            if (e?.phone_code_iso) {
              editForm('phone_code_iso', { value: e.phone_code_iso, isValid: true });
              setPhoneNumberIsoCode(e.phone_code_iso);
            }
          }}
          retrieveFocus={(value: boolean) => {
            if (value && !isPhoneNumberTouched) {
              setIsPhoneNumberTouched(true);
            }
          }}
          onPhoneNumberChange={editForm}
          customPlaceholder={`${translate(`shop.cart.shop_add_address_modal_page.placeholder_mobile_number`)}`}
        />
      </div>
    );
  };

  return (
    <div className="flex flex-col items-start gap-[1.5rem] self-stretch mt-[1.5rem]">
      <div
        className={
          'items-center gap-[1rem] self-stretch border-[1px] rounded-[.25rem] p-[1.25rem] ' +
          (paymentMethod === 'stcpay' ? 'border-sunset' : 'border-secondary/20 hover:border-secondary') +
          (paymentMethodDisabled ? ' disabled pointer-events-none' : '')
        }
      >
        <div className="flex item-center gap-[1rem] self-stretch w-full justify-between">
          <div className="flex item-center gap-[1rem]">
            <input
              id="radio-btn"
              type="radio"
              checked={paymentMethod === 'stcpay'}
              className="accent-sunset w-[1.25rem] h-[1.25rem]"
              onClick={() => {
                setPaymentMethod('stcpay');
              }}
            />
            <Image
              img={IMAGES.STCPay}
              alt={translate(IMAGE_ALT_LABELS.stc_pay_image as TxKeyPath) ?? ''}
              divStyle="flex items-center w-[5.25rem] h-[1.5rem] flex-[1_0_0%]"
              imgStyle="w-[5.25rem] h-[1.5rem]"
            />
          </div>
          <p className={`text-fs-body-large ${paymentMethod === 'stcpay' ? 'font-medium' : 'font-regular'} text-secondary text-right`}>
            {translate('shop.cart.payment_method.payment_header_stcpay') ?? ''}
          </p>
        </div>
        {paymentMethod === 'stcpay' && paymentFields('stcpay')}
      </div>
      <div
        className={
          'flex items-center gap-[1rem] self-stretch border-[1px] rounded-[.25rem] p-[1.25rem] ' +
          (paymentMethod === 'payfort_redirection' ? 'border-sunset' : 'border-secondary/20 hover:border-secondary') +
          (paymentMethodDisabled ? ' disabled pointer-events-none' : '')
        }
      >
        <div className="flex item-center gap-[1rem] self-stretch w-full justify-between">
          <div className="flex item-center gap-[1rem]">
            <input
              id="radio-btn"
              type="radio"
              name="radio"
              checked={paymentMethod === 'payfort_redirection'}
              className=" accent-sunset w-[1.25rem] h-[1.25rem]"
              onClick={() => {
                setPaymentMethod('payfort_redirection');
              }}
            />
            <Image
              img={IMAGES.CreditCard}
              alt={translate(IMAGE_ALT_LABELS.visa_master_card_image as TxKeyPath) ?? ''}
              divStyle="flex items-center flex-[1_0_0%]"
              imgStyle="w-[8rem] h-[1.625rem]"
            />
          </div>
          <p
            className={`text-fs-body-large ${
              paymentMethod === 'payfort_redirection' ? 'font-medium' : 'font-regular'
            } text-secondary text-right`}
          >
            {translate('shop.cart.payment_method.payment_header_creditcard') ?? ''}
          </p>
        </div>
      </div>
      <div
        className={
          'items-center gap-[1rem] self-stretch border-[1px] rounded-[.25rem] p-[1.25rem] ' +
          (paymentMethod === 'tabby' ? 'border-sunset' : 'border-secondary/20 hover:border-secondary') +
          (hasDigitalProducts || paymentMethodDisabled ? ' disabled pointer-events-none' : '')
        }
      >
        <div className="flex item-center justify-between gap-[1rem] self-stretch w-full">
          <div className="flex item-center gap-[1rem]">
            <input
              id="radio-btn"
              type="radio"
              checked={paymentMethod === 'tabby'}
              className="accent-sunset w-[1.25rem] h-[1.25rem]"
              onClick={() => {
                if (!hasDigitalProducts) setPaymentMethod('tabby');
              }}
            />
            <Image
              img={IMAGES.Tabby}
              alt={translate(IMAGE_ALT_LABELS.tabby_image as TxKeyPath) ?? ''}
              divStyle="flex items-center flex-[1_0_0%]"
              imgStyle="w-[5rem] h-[2rem]"
            />
          </div>
          {hasDigitalProducts && !isMobile && (
            <p className="text-fs-body font-regular text-red">{translate('shop.cart.payment_method.tabby_does_not_support')}</p>
          )}
          <p className={`text-fs-body-large ${paymentMethod === 'tabby' ? 'font-medium' : 'font-regular'} text-secondary text-right`}>
            {translate('shop.cart.payment_method.payment_header_tabby') ?? ''}
          </p>
        </div>
        {hasDigitalProducts && isMobile && (
          <div className="flex justify-center">
            <p className="text-fs-body font-regular text-red">{translate('shop.cart.payment_method.tabby_does_not_support')}</p>
          </div>
        )}
        {paymentMethod === 'tabby' && paymentFields('tabby')}
      </div>
      {isApplePaySupported && show_apple_pay && (
        <div
          className={
            'flex items-center gap-[1rem] self-stretch border-[1px] rounded-[.25rem] p-[1.25rem] ' +
            (paymentMethod === 'apple_pay' ? 'border-sunset' : 'border-secondary/20 hover:border-secondary') +
            (paymentMethodDisabled ? ' disabled pointer-events-none' : '')
          }
        >
          <div className="flex item-center gap-[1rem] self-stretch w-full justify-between">
            <div className="flex item-center gap-[1rem]">
              <input
                id="radio-btn"
                type="radio"
                checked={paymentMethod === 'apple_pay'}
                className="accent-sunset w-[1.25rem] h-[1.25rem]"
                onClick={() => {
                  setPaymentMethod('apple_pay');
                }}
              />
              <Image
                img={IMAGES.ApplePay}
                alt={translate(IMAGE_ALT_LABELS.apple_pay_image as TxKeyPath) ?? ''}
                divStyle="flex items-center flex-[1_0_0%]"
                imgStyle="w-[3.75rem] h-[1.5rem]"
              />
            </div>
            <p className={`text-fs-body-large ${paymentMethod === 'apple_pay' ? 'font-medium' : 'font-regular'} text-secondary text-right`}>
              {translate('shop.cart.payment_method.payment_header_applepay') ?? ''}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentMethod;
