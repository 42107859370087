import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { RETURN_DATA_MUTATION } from '@fe-monorepo/data-access';
import { CreateReturnNumberParamsModel } from './type';

export const useCreateReturns = () => {
    const [isSuccessful, setStatus] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');

    const [omsCreateReturn, { data: createReturnResponse, error: errorCreateReturn }] = useMutation(RETURN_DATA_MUTATION.omsCreateReturn, {
        errorPolicy: 'all',
    });

    useEffect(() => {
        if (createReturnResponse) {
            if (createReturnResponse.omsCreateReturn.is_successful) {
                setStatus(createReturnResponse.omsCreateReturn.is_successful);
            }
            if (createReturnResponse.omsCreateReturn.error_msg) {
                setErrorMessage(createReturnResponse.omsCreateReturn.error_msg);
            }
            setStatus(createReturnResponse.omsCreateReturn.is_successful);
        }
        setIsLoading(false);
    }, [createReturnResponse]);

    useEffect(() => {
        if (errorCreateReturn) {
            setError(errorCreateReturn.message);
        }
        setIsLoading(false);
    }, [errorCreateReturn]);

    const createReturnNumber = (params: CreateReturnNumberParamsModel) => {
        omsCreateReturn({ variables: { details: params } });
        setIsLoading(true);
    };

    return {
        createReturnNumber,
        returnNumberData: createReturnResponse?.omsCreateReturn,
        return_number: createReturnResponse?.omsCreateReturn?.data?.return_number,
        isSuccessful,
        isLoading,
        error,
        errorMessage,
    };
};
