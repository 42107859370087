import { motion } from 'framer-motion';

interface Props {
  style: { container?: string; line?: string };
}

const HorizontalLine = (props: Props) => {
  const { style } = props;

  return (
    <div className={style.container}>
      <motion.hr className={style.line} initial={{ width: 0 }} animate={{ width: '100%' }} transition={{ duration: 0.75 }} />
    </div>
  );
};

export default HorizontalLine;
