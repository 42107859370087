import HoverText from '../../../Atoms/Text/HoverText';

const BrandName = (props: { text: string; onClick: () => void; brandNameFontSize?: string }) => {
  const { brandNameFontSize = 'text-fs-body-small' } = props;

  return (
    <HoverText
      className={`
        text-sunset font-regular cursor-pointer
        ${brandNameFontSize}
      `}
      textClassName={`line-clamp-1 py-2`}
      hover={{ color: 'sunset' }}
      alwaysShowUnderline={false}
      underlineStyle={`
        z-1 static
        bg-sunset

        h-[1px] 2K:h-[1.77px] 4K:h-[2.66px] 8K:h-[5.33px]
      `}
      text={props.text}
      onClick={props.onClick}
    />
    // <span
    //   className=
    //   {`
    //     line-clamp-1
    //     text-sunset
    //     font-regular
    //     text-bodySmall sm:text-body 2K:text-subtitle 4K:text-fourKSubtitle 8K:text-eightKSubtitle
    //   `}
    // >
    //   {props.text}
    // </span>
  );
};

export default BrandName;
