/*Patterned from CombinedInputField.tsx */

import { useState, useEffect, useRef } from 'react';
import CustomSearchableDropDown from './CustomSearchableDropdown';
import useHeaderState from '@fe-web/hooks/useHeaderState';
import { motion } from 'framer-motion';
export type InputFieldStyle =
{
  containerStyle?: string;
  labelStyle?: string;
  inputStyle?: string;
  underlineStyle?: string;
};

const Label = (props: { text: string; isFocused: boolean; valueLength: number }) =>
{
  const { innerWidth } = useHeaderState();

  // TODO: this needs to change. I only did this for Framer Motion
  const fontSizes =
  {
    "normal":
    {
      initial: { size: '18px', height: '24px' },

      small: { size: '14px', height: '20px' }
    },

    "2k":
    {
      initial: { size: '32px', height: '42.66px' },

      small: { size: '25px', height: '36px' }
    },

    "4k":
    {
      initial: { size: '48px', height: '64px' },

      small: { size: '37.33px', height: '53px' }
    },

    "8k":
    {
      initial: { size: '96px', height: '128px' },

      small: { size: '75px', height: '106px' }
    }
  }

  let mode:  "normal" | "2k" | "4k" | "8k" = "normal";

  if (innerWidth >= 7680)
  {
    mode = "8k";
  }
  else if (innerWidth >= 3840)
  {
    mode = "4k";
  }
  else if (innerWidth >= 2560)
  {
    mode = "2k"
  }

  return (
    <motion.p
      className={`absolute ${props.isFocused ? 'text-sunset' : 'text-zinc-600-400'} font-regular z-0`}
      initial={{
        y: '-75%',
        fontSize: fontSizes[mode].initial.size,
        lineHeight:  fontSizes[mode].initial.height
      }}
      animate={{
        y: props.isFocused || props.valueLength > 0 ? '-75%' : '-75%',
        fontSize: props.isFocused || props.valueLength > 0 ? fontSizes[mode].small.size:  fontSizes[mode].small.size,
        lineHeight: props.isFocused || props.valueLength > 0 ? fontSizes[mode].small.height :  fontSizes[mode].small.height
      }}
      transition={{ duration: 0.3 }}
    >
      {props.text}
    </motion.p>
  );
};

interface CombinedInputProps
{
  dir?: "ltr"| "rtl",
  style?: InputFieldStyle,

  label: string,

  inputType?: string,
  errorMessage: string,

  hasFocused?: boolean,
  dropDown:
  {
    default: any,
    list: any[],

    search: (searchKey: string) => any[],
    getStringValue: (arrayElement: any) => string,
    getSearchValue: (arrayElement: any) => string
  },

  retrieveValue?: (value: any) => void;
  onClickEnter?: () => void,
}


const CustomDropdownField = (props: CombinedInputProps) =>
{
  const { style, label, dropDown, errorMessage } = props;

  const elementRef = useRef<HTMLDivElement | null>();

  const [dropDownValue, setDropDownValue] = useState<any>(dropDown.default? dropDown.default: {});
  const [isFocused, setFocused] = useState<boolean>(false);

  const shouldShowError = !!errorMessage && !isFocused

  useEffect(() =>
  {
    setDropDownValue(dropDown.default)
  }, [dropDown.default])

  useEffect(() => props?.retrieveValue && props.retrieveValue({ dropDownValue}), [dropDownValue])

  useEffect(() =>
  {
    if (props?.hasFocused !== undefined)
    {
      return;
    }
  }, [isFocused]);

  let currentDropdownValueNameLength = dropDownValue?.name?.length;
  if(dropDownValue?.name_en){
    currentDropdownValueNameLength = dropDownValue?.name_en?.length;
  }else if(dropDownValue?.name_ar){
    currentDropdownValueNameLength = dropDownValue?.name_ar?.length;
  }

  return (
    <div ref={ref=> elementRef.current = ref} className={`relative ${style?.containerStyle}`}>
      <div className="w-full flex flex-col">
        <Label
          text={props.label}
          valueLength={currentDropdownValueNameLength? currentDropdownValueNameLength:0}
          isFocused={isFocused}
        />

        <div dir={props?.dir} className='flex gap-16 4xl:gap-[28px] 5xl:gap-[42px] 8xl:gap-81'>
          <CustomSearchableDropDown
            style= {{ containerStyle: `${style?.containerStyle}`, inputStyle: `${style?.inputStyle}`, underlineStyle: style?.underlineStyle }}

            parentRef={elementRef}
            isFocused={isFocused}
            isError={shouldShowError}
            currentValue={dropDownValue}
            list={dropDown.list}

            setFocused={setFocused}
            search={dropDown.search}
            getSearchValue={dropDown.getSearchValue}
            getStringValue={dropDown.getStringValue}
            retrieveValue={setDropDownValue}

            onClickEnter={props.onClickEnter}
          />

        </div>

        <div className={`
              mt-4 4xl:mt-8 5xl:mt-12 8xl:mt-20
              h-20 4xl:h-36 5xl:h-54 8xl:h-100`
        }>
          {
            shouldShowError
          &&
            <p className="
                text-bodySmall 4xl:text-subtitle 5xl:text-bigTitle 8xl:text-huge
                font-regular text-red">{errorMessage}</p>
          }
        </div>
      </div>
    </div>
  );
}

export default CustomDropdownField;