import { MixPanelEvents } from '@fe-monorepo/helper';
import { useUserProfile } from '@fe-monorepo/hooks';
import { BannerInfoDataModel } from '@fe-monorepo/models';
import { RootState } from '@fe-monorepo/store';
import LazyImage from '@fe-web/Atoms/Media/LazyImage';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import useMobileDetect from '@fe-web/hooks/useMobileDetect';
import { getURLForRedirection } from 'apps/fe-web/src/lib/helper';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

interface ShopMediumBannerProps {
  name: string;
  img: string;
  language: string;
  banner_info?: BannerInfoDataModel;
}

const Banner = (props: ShopMediumBannerProps) => {
  const isMobile = useMobileDetect();
  const navigate = useNavigate();
  const [onImgError, setOnImageError] = useState<boolean>(false);
  const { user, isLoggedIn } = useUserProfile();

  const { isRTL } = useSelector((state: RootState) => state.app);

  const handleRedirection = () => {
    if (props.banner_info?.reference_type && props.banner_info.reference_value) {
      const { url, options } = getURLForRedirection(
        props.banner_info?.reference_type,
        props.banner_info.reference_value,
        isLoggedIn,
        user,
        '',
        isRTL ? props.banner_info.name_ar : props.banner_info.name_en,
      );

      mixpanelHelper.trackEvent(MixPanelEvents.bannersButtonCTA, {
        'CTA Name': `Secondary Banner - ${props.banner_info.name_en}`,
        Referrer: window.location.href,
        'CTA Source': 'Shop Page',
        Username: isLoggedIn ? user?.username : 'Guest',
      });

      if (url) {
        navigate(url, options);
      }
    }
  };

  return (
    <>
      {!onImgError && (
        <div
          className={` 
                    overflow-hidden justify-center items-center
                    h-full md:mx-[1.25rem] xsMax:h-[calc(100%-1.25rem)] xsMax:h-[25.625rem]
                    mx-0 md:w-auto w-full
                    ${props.banner_info?.reference_type && props.banner_info?.reference_value ? 'hover:cursor-pointer' : ''}
                `}
          onClick={props.banner_info?.reference_type ? handleRedirection : undefined}
        >
          <LazyImage
            imgStyle={` mt-[0] justify-center min-w-[17.75rem]
                        h-[19.25rem] w-full ${isMobile ? 'object-cover' : ''}
                        xsMax:flex-shrink-1 z-10 relative
                    `}
            divStyle=""
            img={props.img}
            title={props.name}
            alt={props.name}
            onError={() => setOnImageError(true)}
          />
        </div>
      )}
    </>
  );
};

export default Banner;
