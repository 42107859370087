import styled from 'styled-components';
import tw from 'twin.macro';

const StyledProductCard2Container = styled.div`
  ${tw`
    block
  `}
`;

const StyledProductCard2Brand = styled.span<{ $rtl?: boolean }>`
  ${tw`
      inline-block
      relative
      mb-6
      font-regular
      text-sunset
      text-fs-body-small
      pb-2
    `}

  &:hover {
    &::after {
      ${tw`
        scale-x-100
      `}
    }
  }

  &::after {
    ${tw`
      content-['']
      absolute
      w-full
      scale-x-0
      h-[1px]
      bottom-0
      left-0
      bg-sunset
      transition
      transition-transform
      duration-300
    `}
    transform-origin: ${props => (props.$rtl ? 'bottom right' : 'bottom left')}
  }
`;

export { StyledProductCard2Brand, StyledProductCard2Container };
